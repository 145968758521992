import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Card } from "react-bootstrap";

import AddToEnterpriseElement from "../../AddSingleSite/AddToEnterpriseElement";

// import _ from "lodash";
import conxtdOut from "apis/conxtdOut";

import "../AssetEditor.js";
import SingularAssociation from "./SingularAssociation";

function EnterpriseAssociations({ 
  companyId,
  siteRef,
  invalidEnterprise,
  setInvalidEnterprise,
  setAddToEnterpriseData,
  sudoSiteId,
  initialiseFormDataTrigger,
  setInitialiseFormDataTrigger,
}) {

  const darkMode = useSelector((state) => state.app.darkMode)
  const associations = useSelector((state) => state.serviceDesk.enterpriseAssociations);

  const [enterpriseAssociations, setEnterpriseAssociations] = useState([]);
  const [addToEnterprise, setAddToEnterprise] = useState(true);

  const [name, setName] = useState("");
  const [custom_site_ref, setCustomSiteRef] = useState("");
  const [enterprise, setEnterprise] = useState("");
  const [billing_status, setBillingStatus] = useState("");
  const [enterprise_groups, setEnterpriseGroups] = useState("");
  const [enterpriseCustomFieldsData, setEnterpriseCustomFieldsData] = useState([]);

  // populate form state
  const [formBillingStatus, setFormBillingStatus] = useState([]);
  const [formEnterprises, setFormEnterprises] = useState([]);
  const [formEnterpriseGroups, setFormEnterpriseGroups] = useState([]);

  const initialiseFormData = () => {
    setName("");
    setCustomSiteRef("");
    setEnterprise(null);
    setBillingStatus("");
    setEnterpriseGroups({});
    setEnterpriseCustomFieldsData([]);
  }

  const mapCustomFields = (state, setState) => {
    const groupedFields = [];
    const entries = Object.entries(state);
    
    for (let i = 0; i < entries.length; i += 2) {
      groupedFields.push(entries.slice(i, i + 2));
    }

    const render = groupedFields.map((group, index) => (
      <div className="row" key={index}>
        {group.map(([key, value]) => (
          <Form.Group className="col-6" key={key}>
            <Form.Label>{value}</Form.Label>
            <Form.Control
              name={key}
              className='form-input'
              onChange={(e) => {
                const newVal = e.target.value;
                const fieldName = e.target.name;
                setState((prev) => {
                  const updatedFields = { field_id: fieldName, value: newVal };
                  const existingIndex = prev.findIndex(field => field.field_id === fieldName);
  
                  if (existingIndex !== -1) {
                    const updatedState = [...prev];
                    updatedState[existingIndex] = updatedFields;
                    return updatedState;
                  } else {
                    return [...prev, updatedFields];
                  }
                });
              }}
            />
          </Form.Group>
        ))}
      </div>
    ));

    return render;
  }

// USE EFFECT HOOKS --
useEffect(() => {
  if (initialiseFormDataTrigger) {
    initialiseFormData();
    setInitialiseFormDataTrigger(false);
  }
}, [initialiseFormDataTrigger, setInitialiseFormDataTrigger])

useEffect(() => {
  if (associations) {
    setEnterpriseAssociations(associations);
  }
}, [associations])

useEffect(() => {
  if (companyId && sudoSiteId) {
    const getFrontendFormData = conxtdOut.get(`/ServiceDesk/getFrontendFormData/${companyId}`);
    getFrontendFormData.then((response) => {
      const data = response.data?.[0];
      setFormBillingStatus(Object.entries(data['billingStatus']).map(([key, value]) => {
        return {label: value, value: key}
      }))
      setFormEnterprises(
        Object.entries(data['enterprises'])
          .filter(([key, value]) => 
            enterpriseAssociations.length > 0
              ? enterpriseAssociations[0].enterprise.id !== value
              : true
          )
          .map(([key, value]) => ({ label: key, value: value }))
      );
      
    }).catch((error) => {
      console.log(error);
    })
  }
}, [companyId, sudoSiteId, setFormEnterprises, setFormBillingStatus, enterpriseAssociations])

useEffect(() => {
  setAddToEnterpriseData({
    name,
    enterprise,
    custom_site_ref,
    billing_status,
    groups: enterprise_groups,
    enterprise_custom_fields: enterpriseCustomFieldsData,
  })
}, [
  name, 
  enterprise, 
  custom_site_ref, 
  billing_status, 
  enterprise_groups, 
  enterpriseCustomFieldsData, 
  setAddToEnterpriseData
])

  return (
    <>
      <div className='w-100' id='enterprise-associations-container' data-intercom-target={"enterprise-associations-container"}>
        <div className={`d-flex justify-content-center ${darkMode ? "darkMode" : ""} w-100 card-body p-0`}>
          <Row className="w-100">
            <Col className="p-0">
              <div>
                {enterpriseAssociations.length > 0 ?
                  <div id="enterprise-associations-card">
                    <Card className="enterprise-associations-header m-0 pt-2 pb-2">
                      <div className='d-flex justify-content-between flex-nowrap'>
                        <Form.Label className="col label-title-ent-name justify-content-start mb-0">Enterprise Name</Form.Label>
                        <Form.Label className="col label-title justify-content-start mb-0">Site Name</Form.Label>
                        <Form.Label className="col label-title justify-content-start mb-0">Custom Ref</Form.Label>
                        <Form.Label className="col label-title justify-content-start mb-0">Billing Status</Form.Label>
                        <Form.Label className="col d-flex label-title justify-content-center mb-0">Actions</Form.Label>
                      </div>
                    </Card>
                    {enterpriseAssociations &&
                    enterpriseAssociations.map((association, index) => (
                      <SingularAssociation
                        key={index}
                        enterpriseName={association.enterprise.name}
                        enterprise_id={association.enterprise.id}
                        group_id={association.enterprise_group.id}
                        siteName={association.name}
                        customRef={association.custom_site_id}
                        billingStatus={association.billing_status}
                        sudoSiteId={sudoSiteId}
                        siteRef={siteRef}
                      />
                    ))
                    }
                  </div>
                :
                  <div id="enterprise-associations-card">
                    <Card className="enterprise-associations-header m-0 p-2">
                      <h6>No enterprise associations detected.</h6>
                    </Card>
                  </div>
                }
              </div>
            </Col>
          </Row>
        </div>
        <div className="m-0 pb-3">
          <AddToEnterpriseElement
            accordionStyle={false}
            enterprise={enterprise}
            setEnterprise={setEnterprise}
            name={name}
            setName={setName}
            custom_site_ref={custom_site_ref}
            setCustomSiteRef={setCustomSiteRef}
            billingStatus={billing_status}
            setBillingStatus={setBillingStatus}
            enterprise_groups={enterprise_groups}
            setEnterpriseGroups={setEnterpriseGroups}
            enterpriseCustomFieldsData={enterpriseCustomFieldsData}
            setEnterpriseCustomFieldsData={setEnterpriseCustomFieldsData}
            invalidEnterprise={invalidEnterprise}
            setInvalidEnterprise={setInvalidEnterprise}
            formBillingStatus={formBillingStatus}
            formEnterprises={formEnterprises}
            addToEnterprise={addToEnterprise}
            setAddToEnterprise={setAddToEnterprise}
            mapCustomFields={mapCustomFields}
            formEnterpriseGroups={formEnterpriseGroups}
            setFormEnterpriseGroups={setFormEnterpriseGroups}
            initialiseFormDataTrigger={initialiseFormDataTrigger}
            setInitialiseFormDataTrigger={setInitialiseFormDataTrigger}
          />
        </div>
      </div>
    </>
  )
}

export default EnterpriseAssociations
