import React, { useState } from "react";

// import { Col, Row, Card } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";

import SiteView from "components/Site/SiteView/";

// import PieChart from "components/Enterprise/Reports/Charts/PieChart";

import Table from "./Table";

export default function OutLocks() {
  const [site, setSite] = useState(null);
  const [bundle, setBundle] = useState(null);

  const selectSite = selectedSite => {
    // New site selected
    if (selectedSite && selectedSite !== site) {
      document.body.classList.add("sidepane-active");

      setSite(selectedSite);

      // Scroll type column into view (doesn't work very well)
      // document
      //   .querySelector(".header-type")
      //   .scrollIntoView({ behavior: "smooth", inline: "start" });

      // Only set bundle id if selecting for the first time
      // const bundle_id =
      //   selectedSite === null ? this.props.router.params.bundle_id || "" : "";

      // this.props.history.push(
      //   `/enterprise/${enterprise_id}/${group_id}/${site.id}/${bundle_id}`
      // );
    } else {
      document.body.classList.remove("sidepane-active");

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        // this.props.history.push(`/enterprise/${enterprise_id}/${group_id}`);

        setSite(null);
      }, 600);
    }
  };

  return (
    <>
      <Row>
        <Col className="header-title">Pending Access Requests</Col>
      </Row>
      {/* <Row>
        <Col md="6">
          <Card className="py-4">fd</Card>
        </Col>
        <Col md="6">
          <Card className="py-4">
            <PieChart
              pie={{
                data: [
                  { name: "Open", value: 1 },
                  { name: "Accepted", value: 1 },
                  { name: "Denied", value: 1 },
                  { name: "Expired", value: 1 }
                ],
                colors: ["#e67e22", "#27ae60", "#c0392b", "#777777"]
              }}
            />
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Table selectSite={selectSite} />
        </Col>
      </Row>
      <SiteView
        site={site}
        selectSite={selectSite}
        // Using controlled state for bundle_id
        bundle_id={bundle}
        onSelectBundle={bundle_id => {
          // Bundle selected
          if (bundle_id) {
            setBundle(bundle_id);
          }
          // Invalid bundle id, deselect
          else {
            setBundle(null);
          }
        }}
      />
    </>
  );
}
