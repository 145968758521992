import React from "react";
import { connect } from "react-redux";
// import { Field, reduxForm } from "redux-form";
import { reduxForm } from "redux-form";

import { Link, Redirect, withRouter } from "react-router-dom";

import { Form } from "react-bootstrap";

import { withT } from "services/translation/";

import get from "lodash/get";

import {
  loginSuccess,
  loginFailed,
  loginStart,
  // logoutRequest
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

import LoadingButton from "../LoadingButton";

import { Button } from "react-bootstrap";

import ErrorBadge from "../Layout/ErrorBadge";

import "./LoginForm.scss";

class LoginForm extends React.Component {
  state = {
    error: {},
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let credentials = {};

    document.querySelectorAll("#LoginForm input").forEach((item) => {
      if (item.name === "confirm_password") return;
      credentials[item.name] = item.value;
    });
    credentials.persist = document.getElementById("persist").checked;

    // Dispatch login event
    this.dispatchLogin(credentials);
  };
  dispatchLogin = async (credentials) => {
    const { t } = this.props;

    this.props.dispatch(loginStart());

    // If no env if specified in url path, fallback to default
    const environment = this.props.match.params.env || conxtdOut.defaultEnv;

    try {
      const { user, enterprises } = await conxtdOut.login(
        credentials,
        environment
      );

      this.props.dispatch(loginSuccess({ user, enterprises }));
    } catch (error) {
      const status = get(error.response, "status");
      // 422 unprocessable
      if (status === 422) {
        // this.props.dispatch(
        //   loginFailed({
        //     message: t("errors.incorrect_credentials"),
        //   })
        // );
        if (error.response.data.message.includes("You have tried to login too many times. You can try again in")) {
          const secondsRemaining = parseInt(error.response.data.message.substring(61));

          if (!Number.isNaN(secondsRemaining)) {
            this.setState({
              error: {
                message: error.response.data.message.substring(0, 57),
                timeout: secondsRemaining,
              }
            });
          } else {
            this.setState({
              error: {
                message: t("errors.incorrect_credentials"),
              }
            });
          }
        } else {
          this.setState({
            error: {
              message: error.response.data.message,
            }
          });
        }
        // Destroy session
        conxtdOut.logout();
        // this.props.dispatch(logoutRequest());
      } else {
        // this.props.dispatch(
        //   loginFailed({
        //     message: t("errors.login_failed_x", error.message),
        //   })
        // );
        this.setState({
          error: {
            message: t("errors.login_failed_x", error.message),
          }
        });
      }
      this.props.dispatch(
        loginFailed({}) // now just used to indicate that loading has stopped
      );
    }
  };
  render() {
    const { handleFocus, handleBlur, t, user } = this.props;

    // const error = user.error;
    const { error } = this.state;

    const session = conxtdOut.getSession();
    const loggedIn = session && session.sessionId;
    const loading = user.loading;

    // If no env is specified in url path, fallback to default
    const environment = this.props.match.params.env || conxtdOut.defaultEnv;

    // User is already logged in
    if (loggedIn && !loading) {
      return (
        <Redirect
          to={{
            pathname: "/enterprise",
            state: { from: this.props.location },
          }}
        />
      );
    }

    let environmentMessage = <>Error: invalid environment</>;
    if (environment && conxtdOut.BASE_URLS[environment]) {
      environmentMessage = (
        <>
          Logging in to <b>{environment}</b>
          {environment === "staging" || environment === "mars" || environment === "dockerbackend" ? (
            <Link to="/login/live" className="waves-link">
              <Button size="sm" className="mx-2">
                Switch to Live
              </Button>
            </Link>
          ) : (
            <Link to="/login/staging" className="waves-link">
              <Button size="sm" className="mx-2">
                Switch to Staging
              </Button>
            </Link>
          )}
        </>
      );
    }
    let showEnvironment =
      this.props.match.params.env ||
      environment === "staging" ||
      environment === "mars" ||
      environment === "dockerbackend" ? (
        <div className="alert alert-primary" role="alert">
          {environmentMessage}
        </div>
      ) : null;

    return (
      <>
        {showEnvironment}
        {error.message && <ErrorBadge message={error.message} timeout={error.timeout} />}
        <Form
          onSubmit={this.handleSubmit}
          onFocus={handleFocus}
          onBlur={handleBlur}
          id="LoginForm"
          className="form-horizontal"
        >
          <div className="form-group ">
            <Form.Control
              name="email"
              component="input"
              type="email"
              placeholder={t("email_address_placeholder")}
            />

            <Form.Control
              name="password"
              component="input"
              type="password"
              placeholder={t("password_placeholder")}
            />
          </div>
          <div className="form-group row">
            <div className="col-6">
              <Form.Check
                type="checkbox"
                name="persist"
                id="persist"
                component="input"
                label={t("remember_me")}
              />
            </div>
            <Link className="text-right col-6" to="/reset-password">
              {t("lost_password")}
            </Link>
          </div>
          <LoadingButton
            type="submit"
            className="btn btn-primary btn-block waves-effect"
            loading={loading}
          >
            {t("log_in")}
          </LoadingButton>
          {/*
          <div className="form-group mt-3 mb-0 row">
            <div className="col-12">
              <div className="float-right">
                <Link to="#" className="text-muted">
                  {t("request_invite")}
                </Link>
              </div>
            </div>
          </div> */}
        </Form>
      </>
    );
  }
}

LoginForm = reduxForm({
  form: "login",
})(LoginForm);

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default withRouter(
  withT(connect(mapStateToProps)(LoginForm), "login_form")
);
