/**
 * 09/21 - open close by area
 */

import { createSelector } from "reselect";

import { getReports } from "../common";

import { getSites, getSiteById } from "services/redux/selectors/sites";
import { getGroups, getGroupPathById } from "services/redux/selectors/groups";
import { getReport } from "services/redux/selectors/reports/common/index";
import { getReportType } from "services/redux/selectors/reports/router";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import produce from "immer";

export const getOpenCloseByAreaIndex = createSelector(
  [getReports],
  reports => reports.openclosebyarea.index
);

export const getOpenCloseByAreaReport = createSelector(
  [getReportType, (state) => state.reports],
  (reportType, reports) => {
    const report = reports.openclosebyarea[reportType];
    return report;
  }
);

export const getOpenCloseByAreaReportTitle = createSelector(
  [getReport],
  report => report.config.title
);

export const getOpenCloseByAreaReportList = createSelector(
  [getReports],
  (reports) => {
    const reportList = Object.keys(reports.openclosebyarea)
                        .filter((reportKey) => {
                          if (reportKey === "index") {
                            return false;
                          } else {
                            return true;
                          }
                        })
                        .map((reportKey) => {
                          return {
                            report: reportKey,
                            title: reports.openclosebyarea[reportKey].config.title,
                            iconIds: reports.openclosebyarea[reportKey].config.iconIds,
                          }
                        });
    return reportList;
  }
);

export const getOpenCloseByAreaReportExpanded = createSelector(
  [getReport],
  (report) => {
    return report.expanded;
  }
);

export const getSudoStates = createSelector(
  // Call the function with these selectors
  [getReport, getSites, getGroups, getOpenCloseByAreaReportExpanded, createGetEventMetaById],
  (report, sites, groups, expanded, getEventMetaById) => {
    // Build site data into each sudoState item
    let resultingSudoStates = report.data.sudoStates.map((sudoState) => {
      // Find the site for this sudoState item
      let filteredSite = getSiteById(sites, sudoState.enterprise_site_id);

      // Make sure sites have loaded before building them into
      //   sudoState data
      if (filteredSite !== null && filteredSite.enterprise_groups) {
        filteredSite = produce(filteredSite, (filteredSite) => {
          // Add path property to each group
          filteredSite.enterprise_groups = filteredSite.enterprise_groups.map((group) => {
            return {
              ...group,
              path: getGroupPathById(groups, group.id),
            };
          });
        });
      }

      // get the event metadata for the area states
      sudoState = produce(sudoState, (sudoState) => {
        sudoState.area_states = sudoState.area_states.map((area_state) => {
          return {
            ...area_state,
            area_state_event_meta: area_state.state === "O" 
                                    ? getEventMetaById(area_state.open_alarm_id)
                                    : area_state.state === "C"
                                      ? getEventMetaById(area_state.close_alarm_id)
                                      : null,
          }
        })
      });

      // get the event metatdata for the perimeter state
      const perimeterEventMeta = sudoState.open_close_state === "O" 
                                  ? getEventMetaById(sudoState.open_alarm_id)
                                  : sudoState.open_close_state === "C"
                                    ? getEventMetaById(sudoState.close_alarm_id)
                                    : null;

      return {
        ...sudoState,
        perimeter_event_meta: { ...perimeterEventMeta },
        ...filteredSite,
        expanded: typeof expanded[sudoState.enterprise_site_id] === "undefined" ? null : expanded[sudoState.enterprise_site_id],
      };
    });

    return resultingSudoStates;
  }
);

export const getExpanded = (site) => {
  if (site && site.expanded === false) return false;

  return site && site.area_states.length > 1 && site.expanded;
};

export const getExpandedRowsCount = (expanded) =>
  Object.keys(expanded).filter((enterprise_site_id) => expanded[enterprise_site_id]).length;