import React from "react";
import { connect } from "react-redux";

import { Row, Col } from "react-bootstrap";

import Details from "./Details";
import CustomFields from "./CustomFields";
import Communication from "./Communication";

import "./Settings.scss";

const optionNames = [
  "Details",
  // "Custom Fields",
  // "Communication",
]

/**
 * Enterprise Management Settings Tab
 *
 **/
class Settings extends React.Component {
  state = {
    selectedOption: "Details",
  }

  render() {
    const { selectedOption } = this.state;

    let optionContent; 
    if (selectedOption === "Details") { optionContent = <Details /> }
    if (selectedOption === "Custom Fields") { optionContent = <CustomFields /> }
    if (selectedOption === "Communication") { optionContent = <Communication /> }

    return (
      <Row className="settings-content">
          <Col 
            className="col-3 col-md-2"
            // style={{borderRight: "1px solid grey"}}
          >
            <ul className="nav flex-column">
              {optionNames.map((optionName) => {
                return (
                  <li 
                    className={`nav-item ${optionName===this.state.selectedOption && "active"}`} 
                    key={optionName}
                  >
                    <span 
                      className="nav-link settings-option"
                      onClick={() => {
                        this.setState({
                          selectedOption: optionName
                        })
                      }}
                    >{optionName}</span>
                  </li>
                )
              })}
            </ul>
        </Col>
        <Col>
          {optionContent}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
};
export default connect(mapStateToProps)(Settings);