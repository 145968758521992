//alarm-response

import { createSelector } from "reselect";

import { getSites, getSiteById } from "services/redux/selectors/sites";

export const getInAlarm = (state) => state.alarmResponse.data.inAlarm;

export const getInAlarmExpanded = (state) => state.alarmResponse.expanded;

export const getInAlarmSites = createSelector(
  [getInAlarm, getSites, getInAlarmExpanded],
  (inAlarm, sites, expanded) => {
    // Build site data into each alarmResponse item
    let resultingInAlarm = inAlarm.map((inAlarmItem) => {
      // Find the site for this inAlarm item
      let inAlarmSite = getSiteById(sites, inAlarmItem.enterprise_site_id);

      return {
        ...inAlarmItem,
        site: { ...inAlarmSite },
        expanded: typeof expanded[inAlarmItem.enterprise_site_id] === "undefined" ? null : expanded[inAlarmItem.enterprise_site_id],
      };
    });

    return resultingInAlarm;
  }
);

export const getExpanded = (site) => {
  if (site && site.expanded === false) return false;

  return site && site.sudo_state.length > 1 && site.expanded;
};

export const getExpandedRowsCount = (expanded) =>
  Object.keys(expanded).filter((enterprise_site_id) => expanded[enterprise_site_id]).length;