import React from "react";

import countryList from "react-select-country-list";

import moment from "services/locale/momentInit.js";
import { formatMstoMS } from "services/date/date";

const formatManualStepLogs = (step, stepIndex, contactStatus) => {
  return <div key={stepIndex} style={{marginBottom: "30px"}}>
    {/* step title */}
    <h6 className="pt-1 avoid-page-break">{`${step.stepNumber}: ${(step.code === "C1") ? step.custom_desc : step.step}`}</h6>

    {/* status logs */}
    {step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0 &&
      <div>
        <div className="mt-2 avoid-page-break" style={{ fontWeight: "bold" }}>Logs</div>
        {
          step.sop_action.sop_actioned_logs.map((logItem, logItemIndex) => {

            const logContactStatusKey = logItem.status_key; // e.g. 'default'
            const logContactStatuses = contactStatus[logContactStatusKey];  // the contact status object associated with the contact key,
                                                                                // e.g. { {IP: 'In Progress}, {CO : 'Complete'} }

            const logContactStatus = logItem.status; // e.g. 'IP'

            const created = new moment(logItem.created);

            return (
              <div key={logItemIndex}>
                {/* status log */}
                <div 
                  className="d-flex justify-content-between avoid-page-break"
                  style={{borderBottom: "1px solid lightgray"}}
                >
                  {/* show the text associated with the contact status of the log, e.g. 'In Progress' */}
                  <div>{logContactStatuses[logContactStatus]}</div>
                  <div 
                    style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                  >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                </div>

                {/* associated comments */}
                {logItem.sop_actioned_comments 
                  && logItem.sop_actioned_comments.length > 0 
                  && logItem.sop_actioned_comments.map((comment, commentIndex) => {

                    const created = new moment(comment.created);

                    return (
                      <div 
                        key={logItemIndex + "-" + commentIndex}
                        className="ml-2 d-flex justify-content-between avoid-page-break"
                        style={{borderBottom: "1px solid lightgray"}}
                      >
                        <div>{decodeURIComponent(comment.comment)}</div>
                        <div 
                          style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                        >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                      </div>
                    )
                  })}
              </div>
            )
          })
        }
      </div>
    }
  </div>
}

const formatKeyholderStepLogs = (step, stepIndex, contactStatus) => {
  return <div key={stepIndex} style={{marginBottom: "30px"}}>
    {/* step title */}
    <h6 className="pt-1 avoid-page-break">{`${step.stepNumber}: ${(step.code === "C1") ? step.custom_desc : step.step}`}</h6> 

    {/* status logs */}
    {step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0 &&
      <div>
        <div className="mt-2 avoid-page-break" style={{ fontWeight: "bold" }}>Logs</div>
        {
          step.sop_action.sop_actioned_logs.map((actionedLog, actionedLogIndex) => {

            const logContactStatusKey = actionedLog.status_key; // e.g. 'default'
            const logContactStatuses = contactStatus[logContactStatusKey];  // the contact status object associated with the contact key,
                                                                                // e.g. { {IP: 'In Progress}, {CO : 'Complete'} }

            const logText = (actionedLog.system_data) // if there is system data, then it's relating to a keyholder
                              ? `${actionedLog.system_data.name} - ${actionedLog.system_data.phone}`
                              : logContactStatuses[actionedLog.status] // if no system data, it's probably the Escalate status

            const created = new moment(actionedLog.created);

            return (
              <div key={actionedLogIndex}>
                {/* status log */}
                <div 
                  className="d-flex justify-content-between avoid-page-break"
                  style={{borderBottom: "1px solid lightgray"}}
                >
                  {/* show the text associated with the log - either 'In Progress' or the name of the Keyholder */}
                  <div>{logText}</div>
                  <div 
                    style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                  >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                </div>

                {!actionedLog.system_data // no system data, so probably just the Escalate status
                  ?
                    // show the associated comments
                    actionedLog.sop_actioned_comments 
                    && actionedLog.sop_actioned_comments.length > 0 
                    && actionedLog.sop_actioned_comments.map((comment, commentIndex) => {

                      const created = new moment(comment.created);

                      return (
                        <div 
                          key={actionedLogIndex + "-" + commentIndex}
                          className="ml-2 d-flex justify-content-between avoid-page-break"
                          style={{borderBottom: "1px solid lightgray"}}
                        >
                          <div>{decodeURIComponent(comment.comment)}</div>
                          <div 
                            style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                          >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                        </div>
                      )
                    })
                  :
                    // system data, so this is relating to a keyholder.
                    // show the contact log status changes
                    actionedLog.sop_contact_logs.map((contactLog, contactLogIndex) => {
                      const created = new moment(contactLog.created);

                      return (
                        <div key={contactLogIndex}>
                          {/* contact status log */}
                          <div 
                            className="ml-2 d-flex justify-content-between avoid-page-break"
                            style={{borderBottom: "1px solid lightgray"}}
                          >
                            {/* show the text associated with the contact status of the log, e.g. 'Making Contact' */}
                            <div>{logContactStatuses && logContactStatuses[contactLog.status]}</div>
                            <div 
                              style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                            >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                          </div>

                          {/* associated comments */}
                          {contactLog.sop_contact_comments 
                            && contactLog.sop_contact_comments.length > 0 
                            && contactLog.sop_contact_comments.map((comment, commentIndex) => {

                              const created = new moment(comment.created);

                              if (comment.comment) {
                                return (
                                  <div 
                                    key={contactLogIndex + "-" + commentIndex}
                                    className="ml-4 d-flex justify-content-between avoid-page-break"
                                    style={{borderBottom: "1px solid lightgray"}}
                                  >
                                    <div>{decodeURIComponent(comment.comment)}</div>
                                    <div 
                                      style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                                    >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                                  </div>
                                )
                              } else {
                                return null;
                              }
                            })}
                        </div>
                      )
                    })  
                }
              </div>
            )
          })
        }
      </div>
    }
  </div>
}

export const buildSOPViewCompletedActionJSX = (
  row,
  assignedUser,
  eventIcon,
  label,
  colour,
  time,
  area,
  zone,
  contactStatus,
  steps
) => {
  if (steps.length > 0) {

    let country = row.site.country_code;
    if (country) {
      country = countryList().getLabel(row.site.country_code);
    }

    return <div style={{color: "#000"}}>
      {/* Site Details */}
      <h4 className="mb-4 avoid-page-break">{`Site name: ${row.site_name}`}</h4>

      <div className="mb-4 d-flex justify-content-between">
        <div>
          <h6 className="mb-2 avoid-page-break">{`Site Ref: ${row.site_ref}`}</h6>
          <h6 className="mb-4 avoid-page-break">{`Custom Ref: ${row.site.custom_ref}`}</h6>
        </div>
        <div>
          <div className="d-flex justify-content-start avoid-page-break">
            <div style={{ width: "100px", fontWeight: "bold" }}>Address 1</div>
            <div>{`${row.site.address1}`}</div>
          </div>
          <div className="d-flex justify-content-start avoid-page-break">
            <div style={{ width: "100px", fontWeight: "bold" }}>Address 2</div>
            <div>{`${row.site.address2}`}</div>
          </div>
          <div className="d-flex justify-content-start avoid-page-break">
            <div style={{ width: "100px", fontWeight: "bold" }}>Town</div>
            <div>{`${row.site.town}`}</div>
          </div>
          <div className="d-flex justify-content-start avoid-page-break">
            <div style={{ width: "100px", fontWeight: "bold" }}>Country</div>
            <div>{`${country}`}</div>
          </div>
          <div className="d-flex justify-content-start avoid-page-break">
            <div style={{ width: "100px", fontWeight: "bold" }}>Postcode</div>
            <div>{`${row.site.postcode}`}</div>
          </div>
        </div>
      </div>

      {/* Event details */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div
          style={{
            backgroundColor: colour,
            padding: "0.3rem 1rem 0.3rem 1rem",
            borderRadius: "16px",
            height: "32px",
            paddingTop: "6px",
            fontWeight: "bold"
          }}
          className="d-flex justify-content-between flex-grow-1 text-light avoid-page-break"
        >
          {
            <div>
              {eventIcon}
              <span>{label}</span>
            </div>
          }
          <div>{time.format("HH:mm")}</div>
          <div>{time.format("DD MMM YYYY")}</div>
          <div>{area}</div>
          <div>{zone}</div>
        </div>
      </div>

      {/* Closed Action Details */}
      <div style={{marginBottom: "30px"}}>
        <h6 className="pt-1 avoid-page-break">{`Assigned to: ${assignedUser.first_name} ${assignedUser.last_name}`}</h6>
        <div
          className="mt-2 d-flex justify-content-between avoid-page-break"
          style={{borderBottom: "1px solid lightgray"}}
        >
          <div style={{ fontWeight: "bold" }}>Assigned</div>
          <div
            style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
          >{(new moment(assignedUser.assigned)).format("HH:mm DD/MM/YYYY")}</div>
        </div>
        <div
          className="d-flex justify-content-between avoid-page-break"
          style={{borderBottom: "1px solid lightgray"}}
        >
          <div style={{ fontWeight: "bold" }}>Response</div>
          <div
            style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
          >{formatMstoMS(row.response)}</div>
        </div>
      </div>

      {/* Steps */}
      <div>
        {steps.map((step, stepIndex) => {
          if (!step.closeDownStep) { // one of the actual steps - not the item with the close down details
            if (step.system_data === "keyholder") {
              return formatKeyholderStepLogs(step, stepIndex, contactStatus);
            } else {
              return formatManualStepLogs(step, stepIndex, contactStatus);
            }
          } else {
            // close down details
            const created = new moment(step.closeDownTime);
                    
            return (
              <div key={"closedItemDetails"} style={{marginBottom: "20px"}}>
                <h6 className="pt-1 avoid-page-break">{`Closed down: ${
                  row.closeDownOption.type === "R"
                    ? "Real Alarm"
                    : row.closeDownOption.type === "F" 
                      ? "False Alarm"
                      : "Other"
                }`}</h6>
                <div
                  className="mt-2 d-flex justify-content-between avoid-page-break"
                  style={{borderBottom: "1px solid lightgray"}}
                >
                  <div style={{ fontWeight: "bold" }}>
                    {row.closeDownOption.option}
                  </div>
                  <div 
                    style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                  >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                </div>
                <div>
                  {
                    row.sop_close_down_log
                    && row.sop_close_down_log.sop_close_down_comment
                    && row.sop_close_down_log.sop_close_down_comment.comment
                    && (row.sop_close_down_log.sop_close_down_comment.comment.length > 0)
                    &&
                      <div 
                        className="ml-2 d-flex justify-content-between avoid-page-break"
                        style={{borderBottom: "1px solid lightgray"}}
                      >
                        <div>{decodeURIComponent(row.sop_close_down_log.sop_close_down_comment.comment)}</div>
                        <div 
                          style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                        >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                      </div>
                  }
                </div>
                <div
                  className="d-flex justify-content-between avoid-page-break"
                  style={{borderBottom: "1px solid lightgray"}}
                >
                  <div style={{ fontWeight: "bold" }}>SOP Duration</div>
                  <div 
                    style={{minWidth: "130px", textAlign: "right", color: "#6c757d"}}
                  >{formatMstoMS(row.duration)}</div>
                </div>
              </div>
            )
          }
        })}
      </div>

    </div>
  } else {
    return null;
  }
    
}
