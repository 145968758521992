import React from "react";
import { connect } from "react-redux";

import { getReportType } from "services/redux/selectors/reports/router";

import OutstandingIndexReportPage from "./Index/OutstandingIndexReportPage";
import OutstandingBundleReportPage from "./Bundle/OutstandingBundleReportPage";

class OutstandingReports extends React.Component {
  render() {
    const { reportType } = this.props;

    switch (reportType) {
      case "index":
        return <OutstandingIndexReportPage />;
      case "bundle":
        return <OutstandingBundleReportPage />;
      default:
        return null;
    }
  }
}
const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props)
  };
};
export default connect(mapStateToProps)(OutstandingReports);
