/**
 * Edit camera
 */
import { call } from "redux-saga/effects";

import {
    requestEditSiteCameraStart,
    requestEditSiteCameraFailed,
    requestEditSiteCameraSuccess,
    requestEditSiteCameraCancel,
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* requestEditCamera({
    camera_sudo_site_id,
    site_id,
    body,
}) {
    try {
        const result = yield call(conxtdOut.post, `/CameraSudoSites/edit/${camera_sudo_site_id}`, body);

        return result;
    } catch (e) {
        throw new Error(e);
    }
}

export function* requestEditCameraWatcher() {
    yield actionWatcher({
        actions: {
            start: requestEditSiteCameraStart,
            failed: requestEditSiteCameraFailed,
            success: requestEditSiteCameraSuccess,
            cancel: requestEditSiteCameraCancel
        },
        saga: requestEditCamera
    })
}