export const initiateVoiceCall = (params) => {
  return { type: "INITIATE_VOICE_CALL", ...params };
}

export const changeVoiceCallContactDetails = (params) => {
  return { type: "CHANGE_VOICE_CALL_CONTACT_DETAILS", ...params };
}

export const setVoiceCallStatus = (params) => {
  return { type: "SET_VOICE_CALL_STATUS", ...params };
}

export const setVoiceCallMuted = (params) => {
  return { type: "SET_VOICE_CALL_MUTED", ...params };
}

export const loadCallLogsStart = (params) => {
  return { type: "LOAD_CALL_LOGS_START", ...params };
};

export const loadCallLogsSuccess = (params) => {
  return { type: "LOAD_CALL_LOGS_SUCCESS", ...params, };
};

export const loadCallLogsFailed = (params) => {
  return { type: "LOAD_CALL_LOGS_FAILED", ...params };
};

export const loadCallLogsCancel = (params) => {
  return { type: "LOAD_CALL_LOGS_CANCEL", ...params };
};

export const getTranscriptionStart = (params) => {
  return { type: "GET_TRANSCRIPTION_START", ...params };
};

export const getTranscriptionSuccess = (params) => {
  return { type: "GET_TRANSCRIPTION_SUCCESS", ...params, };
};

export const getTranscriptionFailed = (params) => {
  return { type: "GET_TRANSCRIPTION_FAILED", ...params };
};

export const getTranscriptionCancel = (params) => {
  return { type: "GET_TRANSCRIPTION_CANCEL", ...params };
};