import React from "react";
import { connect } from "react-redux";
import { Button, Row } from "react-bootstrap";

import { withT } from "services/translation/";

import moment from "services/locale/momentInit.js";

import get from "lodash/get";

import Loading from "components/Loading";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import CircleIcon from "components/Icons/CircleIcon";
import Icon from "components/Icons/Icon";
import { Tooltip } from "components/Common/Tooltip";

import TimezoneDate from "components/Common/TimezoneDate";

import "./LatestAlarm.scss";

// ID in the database for the latest events bundle
const LATEST_EVENTS_BUNDLE_ID = 2;

const SITE_STATES = {
  C: {
    colour: "#28498A",
    icon: "lock",
  },
  O: {
    colour: "#3397DB",
    icon: "unlock",
  },
  U: {
    colour: "#2B3E4E",
    icon: "dash",
  },
};

class LatestAlarm extends React.Component {
  render() {
    const { site, viewOpenClose, getEventMetaById, t } = this.props;

    const bundle = site.alarmBundles.find(
      (bundle) => bundle.id === LATEST_EVENTS_BUNDLE_ID
    );

    if (!bundle) return null;

    const { alarmCount } = site;

    const alarm = get(bundle, "events.0");

    let event, acked;
    if (!alarm) {
      event = { icon: "none", colour: "#707070" };

      acked = (
        <Tooltip placement="bottom" description={t("no_alarms_in_timeframe")}>
          <span>{t("not_available")}</span>
        </Tooltip>
      );
    } else {
      event = getEventMetaById(alarm.conxtd_alarm_id);
      acked = (
        <TimezoneDate
          date={alarm.acked}
          timezone={site.timezone}
          format="HH:mm"
          hideTimezone={true}
          placement="bottom"
        />
      );
    }

    // Get this data from site.open_close_state

    const { icon, colour } = SITE_STATES[site.open_close_state] || {
      colour: "#707070",
      icon: "none",
    };

    const statusIcon = (
      <CircleIcon
        size="64"
        type={icon}
        foregroundColor={colour}
        color="transparent"
      />
    );


    let eventPairs = site.event_pairs.data.partial.length > 0;

    let openCloseToolTip = null;
    if (site.event_pairs.data !== undefined && site.event_pairs.data.full.length >= 1) {

      const status = site.event_pairs.data.full[0].state === 'A' ? 'Closed' : 'Open';
      // const alarmTimeStamp = new moment(site.event_pairs.data.full[0].recvd);
      // const alarmDate = alarmTimeStamp.format("HH:mm DD/MM/YYYY")
      const timezone = site.timezone;
      const ackedTimezoneTimestamp = timezone && (new moment.utc(site.event_pairs.data.full[0].recvd).tz(timezone)); 
      const alarmTimezoneDate = timezone && ackedTimezoneTimestamp.format("HH:mm DD/MM/YYYY")
      

      openCloseToolTip = {
        'title': status,
        // 'description': alarmDate,
        'description': alarmTimezoneDate,
      };

    } else {

      const status = site.open_close_state === 'O' ? 'Open' : 'Close';

      openCloseToolTip = {
        'title': null,
        'description': status
      };
    }

    const openCloseElement = (
      <>
        {eventPairs === true ? (
          <>
            <Row>
              <div className="set-state-ep">
                {site.open_close_state === "U" ? (
                  <Tooltip
                    placement="bottom"
                    description={t("unknown_site_status")}
                  >
                    <div className="m-auto view-open-close">
                      <span>{statusIcon}</span>
                    </div>
                  </Tooltip>
                ) : (
                    <Tooltip
                      placement="top"
                      title={openCloseToolTip.title}
                      description={openCloseToolTip.description}
                    >
                      <div className="m-auto view-open-close">
                        <span>{statusIcon}</span>
                      </div>
                    </Tooltip>
                  )}
              </div>
            </Row>
            <Row>
              <span className="area-button">
                <Button
                  size="md"
                  onClick={viewOpenClose}
                >Areas</Button></span>
            </Row>
          </>
        ) : (
            <div className="set-state d-flex h-100">
              {site.event_pairs.loading === true && site.event_pairs.init_load !== true ? (<div className="m-auto"> <Loading /> </div>) : (
                <>
                  {site.open_close_state === "U" ? (
                    <Tooltip
                      placement="bottom"
                      description={t("unknown_site_status")}
                    >
                      <div className="m-auto view-open-close">
                        <span>{statusIcon}</span>
                      </div>
                    </Tooltip>
                  ) : (
                      <Tooltip
                        placement="top"
                        title={openCloseToolTip.title}
                        description={openCloseToolTip.description}>
                        <div className="m-auto view-open-close">
                          <span>{statusIcon}</span>
                        </div>
                      </Tooltip>
                    )}
                </>
              )}
            </div>
          )
        }
      </>
    );

    return (
      <>
        <div className="col-2 latest-alarm pr-0">
          {openCloseElement}
        </div>
        <div className="col-8 latest-alarm mt-4">
          <div className="latest-alarm-icon d-flex">
            <CircleIcon size="96" type={event.icon} color={event.colour} />
          </div>
          <div className="latest-alarm-caption text-center my-4">
            <div className="caption">{event.caption}</div>
            <div className="acked">{acked}</div>
          </div>
        </div>

        <div className="col-2 alarm-count d-flex text-center pl-0">
          <span className="m-auto d-inline" style={{ fontSize: "24px" }}>
            <div className="d-inline pr-3">
              <Icon className="far fa-bell" />
            </div>
            {alarmCount}
          </span>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default withT(connect(mapStateToProps)(LatestAlarm), "site_overview");
