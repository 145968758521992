import React from "react";

import { withT } from "services/translation/";
import RemoveFilter from "../ActiveFilters/RemoveFilter";

export default withT(({ filters, t, onRemove }) => {
  return filters.map((filter, index) => {
    const {
      color = "#707070",
      value = "",
      name = "",
      filterGroup,
      type
    } = filter;

    const textClassName =
      color === "white" ? " text-muted" : " text-white-important";
    return (
      <span
        key={index}
        className={"p-2 filter-tag " + type + textClassName}
        style={{ background: color }}>
        {/* group_overview.filter.nonZero|contains.text|stats.openClosed|stats.alarmColours */}
        {t(type + "." + filterGroup, name || value)}
        <RemoveFilter
          onClick={() => {
            onRemove({ ...filter, value: "" });
          }}
          style={{ position: "relative", top: "1px", padding: "0 0.25rem" }}
        />
      </span>
    );
  });
}, "group_overview.filter");
