/**
 * 08/21
 * Data table custom row component to add expanded site hourly data
 * (sub rows)
 */

import React from "react";

import get from "lodash/get";

import {
  getExpandedHourly
} from "services/redux/selectors/reports/scheduled";

import ChildRowColumnsHourly from "./ChildRowColumnsHourly";

function TrGroupComponentHourly(component) {
  const {
    site,
    columns,
    groups,
  } = component;

  const expandedContent = getExpandedHourly(site)
    ? site.hourly.map((hour, index) => {
        let rowsShown = -1;

        const getStyle = index =>
          get(component, `children.0.props.children.${index}.props.style`);

        return (
          <div
            className="rt-tr sub-row"
            role="row"
            key={site.id + " " + index}>
            {ChildRowColumnsHourly.map(cell => {
              const showCell =
                // Show by default
                cell.key === null ? true : columns[cell.key].show;

              if (!showCell) return null;

              rowsShown++;

              const style = cell.style;
              const content =
                (cell.content &&
                  cell.content({
                    site,
                    hour,
                    groups
                  })) ||
                null;

              return (
                <div
                  className={`rt-td ${cell.className || ""}`}
                  role="gridcell"
                  style={{
                    ...getStyle(rowsShown),
                    ...style
                  }}
                  key={site.id + " " + index + " " + cell.key}>
                  {content}
                </div>
              );
            })}
          </div>
        );
      })
    : null;

  const expandedClass = component.expanded === 1 ? "expanded" : "";

  return (
    <>
      <div
        className={`rt-tr-group ${expandedClass}`}
        role="rowgroup"
        key={site && site.id}>
        {component.children}
        {expandedContent}
      </div>
    </>
  );
}

export default TrGroupComponentHourly;
