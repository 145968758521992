/**
 * SiteView shows a site view based on prop site.id, also
 * optionally shows bundle corresponding to prop bundle_id
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getRouterParams } from "services/redux/selectors/app/";

import { withT } from "services/translation/";

import SidePane from "components/Common/SidePane/SidePane";

import { SiteOverview } from "./SiteOverview/";

import Loading from "components/Loading";

import { Events } from "./SiteOverview/Tabs/Bundles/Events/";

// import siteMock from "./__mocks__/SiteView.mock";

import {
  loadSiteViewStart,
  loadSiteViewCancel, 
  loadSiteViewLiveRefreshStart, 
  loadSiteViewLiveRefreshCancel,
  setSiteViewRefreshCount,
  clearNotificationHistories,
} from "services/redux/actions";

import { getCurrentSite, getBundleId } from "services/redux/selectors/sites";
import { getCurrentGroup } from "services/redux/selectors/groups";

class SiteView extends React.Component {
  componentWillUnmount() {
    const { muted, muteOnCloseSlider, toggleMuted } = this.props;

    this.props.dispatch(
      clearNotificationHistories()
    );

    if (document.body.classList.contains("sidepane-active")) {
      // if the side pane has been opened from the priority event page, it will have been passed toggleMuted,
      // so it can reset the muted value if the user leaves the page that contains the side pane while the side pane is still open
      if (toggleMuted && !muteOnCloseSlider && muted) {
        toggleMuted(); // toggles the muted value, so in this case it will unmute
      }
    }

    document.body.classList.remove("sidepane-active");
    this.props.dispatch(
      loadSiteViewLiveRefreshCancel()
    );
  }
  
  loadSite = (props, newSite = true) => {
    const { site, origin } = props;

    if (!site.id) {
      return;
    }

    if (origin === "SiteList") {
      // Cancel previous
      this.props.dispatch(loadSiteViewCancel());

      this.props.dispatch(
        loadSiteViewStart({
          id: site.id,
          timeframe: site.timeframe
        })
      );

    } else {
      // Cancel previous
      this.props.dispatch(
        loadSiteViewLiveRefreshCancel()
      );

      this.props.dispatch(
        setSiteViewRefreshCount({
          refreshCount: 0
        })
      );

      this.props.dispatch(
        loadSiteViewLiveRefreshStart({
          id: site.id,
          timeframe: site.timeframe
        })
      );
    }

    // site.id
  };

  componentDidMount() {
    if (this.props.site) {
      this.loadSite(this.props);
    }
  }

  // // Group refreshes, site flags as stale
  // // and on completion sets stale to null
  // UNSAFE_componentWillReceiveProps = props => {
  //   const newSite = props.site.id !== this.props.site.id;
  //   const newTimeframe = props.site.timeframe !== this.props.site.timeframe;
  //   const loading = props.site.loading === true;
  //   const shouldUpdate =
  //     // New site/timeframe incoming
  //     (newSite || newTimeframe) &&
  //     // Not currently loading
  //     !loading;

  //   // console.log(shouldUpdate, props.site.shouldUpdate);

  //   // Load site if new props are incoming and
  //   // not already loading
  //   if (shouldUpdate) {
  //     this.loadSite(props, newSite);
  //   }
  // };

  componentDidUpdate = prevProps => {
    const { site, currentGroup } = this.props;

    const newSite = prevProps.site.id !== site.id;
    const newTimeframe = prevProps.currentGroup.timeframe !== currentGroup.timeframe;

    const shouldUpdate =
      // New site/timeframe incoming
      (newSite || newTimeframe)

    // Load site if new props are incoming and
    if (shouldUpdate) {
      this.loadSite(this.props, newSite);
    }
  };

  render() {
    const { site, bundle_id, onSelectBundle, t, muted, muteOnCloseSlider, muteClick } = this.props;

    if (!site) {
      return <Loading center />;
    }

    const title = site.name || "";

    return (
      <SidePane
        // SiteView > SidePane > View1, View2
        title={title}
        onHide={() => {
          if (document.body.classList.contains("sidepane-active")) {
            // Deselect any currently visible site
          this.props.selectSite(null);

          // if the side pane has been opened from the priority event page, it will have been passed muteClick so it can unmute if necessary when it's closed
          if (muteClick && !muteOnCloseSlider && muted) {
            muteClick(); // muteClick toggles the muted value (amongst other things), so in this case it will unmute
          }

          this.props.dispatch(
            clearNotificationHistories()
          );
          }
        }}
        componentProps={{
          site,
          bundle_id,
          onSelectBundle
        }}
        // If bundle_id prop is available,
        // set to view child page (timeline)
        currentPage={bundle_id ? 1 : 0}
        onChangePage={pageKey => {
          // Clicked "back", deselect bundle
          if (pageKey === 0) {
            onSelectBundle();
          }
        }}
        Pages={[
          {
            Component: SiteOverview
          },
          {
            backButtonText: t("back_to_overview"),
            Component: Events
          }
        ]}
      />
      // <Modal size="lg" show={site && show} onHide={onHide}>
      //   <Modal.Header closeButton>
      //     <Modal.Title>{Title}</Modal.Title>
      //   </Modal.Header>
      //   <Modal.Body>{Body}</Modal.Body>
      // </Modal>
    );
  }
}
SiteView.propTypes = {
  /**
   * @param site (object) site.id is required to load and display site data
   * site is also merged with the downloaded site
   */
  site: PropTypes.object.isRequired,
  /**
   * @param bundle_id (int or string) optional bundle id to take user straight to
   * a bundle passing control/management of state to parent component
   */
  bundle_id: PropTypes.any,
  /**
   * @param onSelectBundle (func) event callback to set bundle, passes
   * bundle_id
   */
  onSelectBundle: PropTypes.func,
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    site: getCurrentSite(state, props),
    router: {
      params: getRouterParams(state, props)
    },
    currentGroup: getCurrentGroup(state),
    bundle_id: getBundleId(state, props),
    muted: state.alarmResponse.muted,
    muteOnCloseSlider: state.alarmResponse.muteOnCloseSlider,
  };
};

SiteView = connect(mapStateToProps)(withT(SiteView, "site_overview.side_pane"));
export { SiteView };
export default withRouter(SiteView);
