import React from "react";
import { connect } from "react-redux";

// import { withT } from "services/translation/";

import ToggleButton from "components/Common/Buttons/Toggle";

import Icon from "components/Icons/Icon";

import { toggleSnowMode } from "services/redux/actions";

class SnowMode extends React.Component {
  state = {
    activeInitiated: false,
    activeInitialState: null,
  }
  componentDidMount() {
    this.setState({
      activeInitiated: true,
      activeInitialState: this.props.app.snowMode,
    })
  }
  
  render() {
    const { snowMode } = this.props.app;
    const { activeInitiated, activeInitialState } = this.state;
    // const { t } = this.props;

    return (
      <label
        style={{ fontSize: "13px"}}
        className="btn dropdown-item waves-effect"
      >
        <Icon
          className="fas fa-snowflake text-muted"
          style={{ marginTop: "-2px", fontSize: "14px" }}
        />{" "}
        Snow Mode
        <ToggleButton
          onClick={() => {
            document.body.classList.toggle("snowmode");
            this.props.dispatch(toggleSnowMode());
          }}
          active={activeInitiated ? activeInitialState : snowMode}
          size="xs"
          style={{ marginTop: "-2px" }}
        />    
      </label>

    );
  }
}

const mapStateToProps = state => {
  return { app: state.app };
};
export default connect(mapStateToProps)(SnowMode);