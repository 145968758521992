import React from "react";
import { connect } from "react-redux";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file 

import { Button } from "react-bootstrap";

import { loadSitesBackgroundStart } from "services/redux/actions/sites";
import { loadSOPCloseDownOptionsStart } from "services/redux/actions/sop";
import {
  loadSOPCompletedReportStart,
  setSOPCompletedReportDisplayPageIndex,
} from "services/redux/actions/sopReports";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getGroupOptions, getSiteOptions, createGetSitesOptionsByGroup } from "services/redux/selectors/customReports";
import { getSOPCloseDownOptions } from "services/redux/selectors/sopReports";

// import ToggleButton from "components/Common/Buttons/Toggle";
// import { Tooltip } from "components/Common/Tooltip/";

import DropdownTreeSelectCustom from "components/Common/DropdownTreeSelect/DropdownTreeSelectCustom";

import Loading from "components/Loading";

import DataTable from "./DataTable";
import DataTableClientSide from "./DataTableClientSide";

import moment from "services/locale/momentInit.js";

import { weekStart } from "services/locale/constants";

import _ from "lodash";

import "./CompletedActionReport.scss";

const WEEK_START = weekStart();

class CompletedActionReport extends React.Component {
  state = {
    selectionRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    ],
    // group options come in directly on the props
    siteOptions: [], // site options need to be stored in local state as they change according to group selection and when clearing selections
    selectedGroupOption: null,
    selectedSiteOptions: null,
    closeDownOptions: [], // close down options need to be stored in local state as they change when clearing selections
    selectedCloseDownOptions: null,
    requestParams: {},
    serverSidePagination: true,
  }

  componentDidMount() {
    const { enterprise, group, siteOptions, getSiteOptionsByGroup, closeDownOptions } = this.props;

    const groupId = (group && group.id) || enterprise.root_group_id;

    this.props.dispatch(
      loadSitesBackgroundStart({
        enterprise_id: enterprise.id,
      })
    );

    this.props.dispatch(
      loadSOPCloseDownOptionsStart({
        enterprise_id: enterprise.id,
        group_id: groupId,
      })
    );

    const subGroupId = group ? group.id : null;
    if (subGroupId) {
      this.setState({ siteOptions: [ ...getSiteOptionsByGroup(subGroupId) ] });
    } else {
      this.setState({ siteOptions: [ ...siteOptions ] });
    }

    this.setState({ closeDownOptions: [ ...closeDownOptions ] });
  }

  componentDidUpdate(prevProps) {
    const { siteOptions, getSiteOptionsByGroup, closeDownOptions } = this.props;

    if (!_.isEqual(siteOptions, prevProps.siteOptions)) {
      const groupId = this.state.selectedGroupOption;
      if (groupId) {
        this.setState({ siteOptions: [ ...getSiteOptionsByGroup(groupId) ] });
      } else {
        this.setState({ siteOptions: [ ...siteOptions ] });
      }
    }

    if (!_.isEqual(closeDownOptions, prevProps.closeDownOptions)) {
      this.setState({ closeDownOptions: [ ...closeDownOptions ] });
    }
  }

  // Group Selection
  handleGroupSelectionChange = (selectedNodes) => {
    let groupId = selectedNodes[0] ? selectedNodes[0].value : null;
    this.setState({ selectedGroupOption: groupId });
    
    // filter the sites offered according to the selected group
    if (groupId === this.props.enterprise.root_group_id) {
      groupId = null;
    }
    this.setState({ siteOptions: this.props.getSiteOptionsByGroup(groupId) });
    this.setState({ selectedSiteOptions: null });
  };

  //Site Selection
  handleSiteSelectionChange = (selectedNodes) => {
    this.setState({ selectedSiteOptions: selectedNodes ? selectedNodes : null });
  };

  handleSiteSelectionClearAll = () => {
    this.setState({ selectedSiteOptions: null });
  }

  // Close Down Option Selection
  handleCloseDownOptionSelectionChange = (selectedNodes) => {
    this.setState({ selectedCloseDownOptions: selectedNodes ? selectedNodes : null });
  };

  handleCloseDownOptionSelectionClearAll = () => {
    this.setState({ selectedCloseDownOptions: null });
  }

  handleGetReportClick = () => {
    const { enterprise, group, closeDownOptionsData, pageSize, sortMapField, sortDirection, displayPageIndex } = this.props;
    const { selectionRange, selectedGroupOption, selectedSiteOptions, selectedCloseDownOptions, serverSidePagination } = this.state;

    let group_id = selectedGroupOption || (group && group.id) || enterprise.root_group_id;
    if (group_id === "root") {
      group_id = enterprise && enterprise.root_group_id;
    }

    // const startDate = new moment(selectionRange[0].startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // const endDate = new moment(selectionRange[0].endDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    // const dateMillisecondsDiff = endDate.valueOf() - startDate.valueOf(); 
    // const dateDaysDiff = dateMillisecondsDiff / (1000 * 60 * 60 * 24); 
    // const dateRangeNumberOfDays = dateDaysDiff + 1;

    const selectedCloseDownOptionIds = [];
    selectedCloseDownOptions && selectedCloseDownOptions.forEach((selectedCloseDownOption) => {
      // if you've selected one of the top level items in the tree, i.e. "F", "R", or "U", add all the sub item ids to the list 
      // (get the sub items from the original close down options data, not the options as reformulated for the drop down)
      // (confusing terminology! - "options" is used in 2 different ways)
      if (selectedCloseDownOption.value === "F") {
        closeDownOptionsData
          .filter(closeDownOption => closeDownOption.type === "F")
          .forEach(closeDownOption => selectedCloseDownOptionIds.push(closeDownOption.id))
      } else if (selectedCloseDownOption.value === "R") {
        closeDownOptionsData
          .filter(closeDownOption => closeDownOption.type === "R")
          .forEach(closeDownOption => selectedCloseDownOptionIds.push(closeDownOption.id))
      } else if (selectedCloseDownOption.value === "U") {
        closeDownOptionsData
          .filter(closeDownOption => closeDownOption.type === "U")
          .forEach(closeDownOption => selectedCloseDownOptionIds.push(closeDownOption.id))
      } else {
        // if it's not a top level item, then the value will be the option id
        selectedCloseDownOptionIds.push(selectedCloseDownOption.value)
      }
    });
    
    const requestParams = {
      "start": new moment(selectionRange[0].startDate).set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
      "end": new moment(selectionRange[0].endDate).set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
      "sites": selectedSiteOptions ? selectedSiteOptions.map(siteOption => siteOption.value) : [],
      "close_down_options": selectedCloseDownOptionIds,
    };
    
    if (serverSidePagination) {
      this.props.dispatch(
        loadSOPCompletedReportStart({ 
          enterprise_id: enterprise.id,
          group_id, 
          params: requestParams,
          requestPageNumber: 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
          pageSize: pageSize,
          sortMapField,
          sortDirection,
        })
      );
  
      if (displayPageIndex !== 0) {
        this.props.dispatch(
          setSOPCompletedReportDisplayPageIndex({
            displayPageIndex: 0, // controls the page index that the table component displays (0-indexed)
          })
        )
      }
  
      this.setState({
        requestParams: requestParams
      });
    } else {
      this.props.dispatch(
        loadSOPCompletedReportStart({ 
          enterprise_id: enterprise.id,
          group_id, 
          params: requestParams,
          requestPageNumber: 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
          pageSize: 100,
        })
      );
    }
  }

  render() {
    const { enterprise, loading, groupOptions } = this.props;
    const { selectionRange, siteOptions, closeDownOptions, serverSidePagination } = this.state;

    // No enterprises loaded yet
    if (!enterprise) {
      return null;
    };

    let firstDayOfWeek = WEEK_START;
    if (enterprise.calendar_config && (enterprise.calendar_config === "advanced") && (enterprise.start_day || (enterprise.start_day === 0))){
      firstDayOfWeek = enterprise.start_day;
    }
    if (enterprise.calendar_config && (enterprise.calendar_config === "custom") && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))){
      firstDayOfWeek = enterprise.first_day_of_year;
    }

    let minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 24);
    minDate.setDate(1);
    const maxDate = new Date(); //today

    return (
      <>
        {/* <div className="d-flex justify-content-start mb-3 ml-1">
          <div style={{ color: "rgb(132, 144, 149)" }}>
            <Tooltip 
              description="Server-side pagination and sorting. Filtering is not implemented."
              placement="left"
            ><span>Server</span></Tooltip>
          </div>
          <ToggleButton
            id="toggle-pagination-type"
            size="xs"
            style={{ backgroundColor: "#DEE2E6", marginTop: "1px" }}
            active={false}
            onClick={(e) => {
              this.setState({ serverSidePagination: !this.state.serverSidePagination });
            }}
          />
          <div style={{ color: "rgb(132, 144, 149)" }}>
            <Tooltip 
              description={<div><span>Pagination, sorting and filtering is managed in the browser.</span><br /><span>NOTE: It does NOT get the full dataset when clicking 'Generate' - it requests a maximum of 100 results.</span></div>}
              placement="right"
            ><span>Browser</span></Tooltip>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-12">
            <div className="card m-b-30 visual-data">
              <div className="card-body">
                <label className="d-block">Date Range</label>
                <DateRangePicker
                  editableDateInputs={true}
                  onChange={dateRange => {
                    this.setState({ selectionRange: [dateRange.selection] })}
                  }
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  ranges={selectionRange}
                  months={2}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  calendarFocus="backwards"
                  weekStartsOn={firstDayOfWeek}
                  // weekdayDisplayFormat="EEEEEE"
                  minDate={minDate}
                  maxDate={maxDate}
                />
                <label className="d-block pt-3">Group</label>
                <DropdownTreeSelectCustom 
                  options={groupOptions} 
                  onChange={this.handleGroupSelectionChange} 
                  mode="radioSelect"
                />
                <label className="d-block pt-3">Sites</label>
                <div className="d-flex with-clear-all">
                  <DropdownTreeSelectCustom 
                    options={siteOptions} 
                    onChange={this.handleSiteSelectionChange}
                    clearable
                    onClearAll={this.handleSiteSelectionClearAll}
                    mode="multiSelect"
                  />
                </div>
                <label className="d-block pt-3">Closure Reasons</label>
                <div className="d-flex with-clear-all">
                  <DropdownTreeSelectCustom
                    options={closeDownOptions} 
                    onChange={this.handleCloseDownOptionSelectionChange} 
                    clearable
                    onClearAll={this.handleCloseDownOptionSelectionClearAll}
                    mode="multiSelect"
                  />
                </div>
                <div className="pt-4">
                  <Button
                    variant="primary"
                    onClick={this.handleGetReportClick}
                  >Generate</Button>
                  <span>&nbsp;&nbsp;{loading && <Loading left />}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {
              serverSidePagination 
                ? <DataTable 
                    selectedGroupOption={this.state.selectedGroupOption}
                    requestParams={this.state.requestParams}
                  />
                : <DataTableClientSide />
            }
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groupOptions: getGroupOptions(state, props),
    siteOptions: getSiteOptions(state, props),
    getSiteOptionsByGroup: createGetSitesOptionsByGroup(state, props),
    closeDownOptions: getSOPCloseDownOptions(state, props),
    closeDownOptionsData: state.sop.closeDownOptions,
    pageSize: state.sopReports.completedReport.pageSize,
    sortMapField: state.sopReports.completedReport.sortMapField,
    sortDirection: state.sopReports.completedReport.sortDirection,
    loading: state.sopReports.completedReport.loading,
  };
};
export default connect(mapStateToProps)(CompletedActionReport);