/**
 * Reports focused on type of event
 *
 * open, close and fire
 */

import * as yup from "yup";
import mapValues from "lodash/mapValues";

const config = yup
  .object()
  .shape({
    title: yup.string().optional()
  })
  .optional();

const occurrences = yup.array().of(
  yup.object().shape({
    acked: yup.mixed().optional(),
    // Moved to site level
    // ideal_event_hour: yup.string().required(),
    open_type: yup.string().required(),
    text: yup
      .string()
      .optional()
      .nullable(),
    is_suspicious: yup.mixed().optional(),
    not_expected: yup.mixed().optional(),
    use_system_defaults: yup.mixed().optional()
  })
);

const sites = yup.lazy(obj =>
  yup.object(
    mapValues(obj, (value, key) =>
      yup.object().shape({
        status: yup.string().required(),
        // #35 - uncomment!
        // ideal_event_hour: yup.string().required(),
        occurrences
      })
    )
  )
);

const totals = yup
  .array()
  .of(
    yup.object().shape({
      name: yup.string().required(),
      type: yup.string().required(),
      value: yup.number().required(),
      colour: yup.string().required()
    })
  )
  .required();

export default yup.object().shape({
  data: yup
    .object()
    .shape({
      totals,
      sites,
      config
    })
    .required()
});
