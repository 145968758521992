import React from "react";
import { connect } from "react-redux";

import { Row, Col, Button, Modal } from "react-bootstrap";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getEventPairMaps } from "services/redux/selectors/enterpriseManager";

import "./Common.scss";

class ViewExampleModal extends React.Component {
  state={
    openCloseType: "open",
    sopMapType: "default_sop_maps", // when viewing the example, it's always the default sop map
    tabList: [],
    workflowDetails: {
      all: {},
      open: {},
      close: {},
    },
    workflowSteps: {
      all: [],
      open: [],
      close: [],
    },
  }
  
  componentDidUpdate(prevProps) {
    const { sopMapType } = this.state;
    const { show, eventPairMaps, currentEventPairMap } = this.props;

    if (
      (!prevProps.show && show) // 'show' changing from false to true
      || (show && (prevProps.currentEventPairMap !== currentEventPairMap)) // modal is showing and the current event pair map has changed
    ) {
      const { workflowDetails, workflowSteps } = this.state;
      let sopMaps = {};
      if (eventPairMaps && eventPairMaps[currentEventPairMap.type]) {
        const eventPairMap = eventPairMaps[currentEventPairMap.type].find((eventPairMap) => eventPairMap.id === currentEventPairMap.eventPairMapId);
        if (eventPairMap) {
          sopMaps = eventPairMap.sop_maps[sopMapType] || {}
        }
      }

      let openCloseType = "open"; // default
      if (!sopMaps.open) {
        if (sopMaps.close) {
          openCloseType = "close";
        } else if (sopMaps.all) {
          openCloseType = "all";
        }
      }

      const tabList = [];
      if (sopMaps && sopMaps.open) {
        tabList.push("open");
        if (sopMaps.close) {
          tabList.push("close");
        } else if (sopMaps.all) {
          tabList.push("all");
        }
      } else if (sopMaps && sopMaps.close) {
        tabList.push("close");
        if (sopMaps.all) {
          tabList.push("all");
        }
      }

      const sopMapAll = sopMaps.all || { sop_action_libraries: [] };
      const sopMapOpen = sopMaps.open || { sop_action_libraries: [] };
      const sopMapClose = sopMaps.close || { sop_action_libraries: [] };
  
      this.setState({
        openCloseType: openCloseType,
        tabList: tabList,
        workflowDetails: {
          ...workflowDetails,
          all: {
            id: sopMapAll.id || null,
            name: sopMapAll.name || "",
            description: sopMapAll.description || "",
          },
          open: {
            id: sopMapOpen.id || null,
            name: sopMapOpen.name || "",
            description: sopMapOpen.description || "",
          },
          close: {
            id: sopMapClose.id || null,
            name: sopMapClose.name || "",
            description: sopMapClose.description || "",
          }
        },
        workflowSteps: {
          ...workflowSteps,
          all: sopMapAll.sop_action_libraries.map((step, index) => {
            return {
              ...step,
              id: index + 1,
              position: index + 1,
              stepTypeOption: { label: step.step, value: step.sop_action_library_id },
            }
          }),
          open: sopMapOpen.sop_action_libraries.map((step, index) => {
            return {
              ...step,
              id: index + 1,
              position: index + 1,
              stepTypeOption: { label: step.step, value: step.sop_action_library_id },
            }
          }),
          close: sopMapClose.sop_action_libraries.map((step, index) => {
            return {
              ...step,
              id: index + 1,
              position: index + 1,
              stepTypeOption: { label: step.step, value: step.sop_action_library_id },
            }
          }),
        },
      })
    }
  }

  render() {
    const {
      openCloseType,
      tabList,
      workflowDetails,
      workflowSteps,
    } = this.state;

    const { currentEventPairMap, show, onHide } = this.props;

    const currentWorkflowDetails = workflowDetails[openCloseType] || {};
    
    const currentWorkflowSteps = workflowSteps[openCloseType].sort((a, b) => {
      return a.position - b.position;
    })

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Default Event Workflow${currentEventPairMap.name ? " - " + currentEventPairMap.name : ""}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            "height": "500px"
          }}
        >
          <>
            <Row>
              <Col>
                {
                  /* Tabs */
                  (tabList && tabList.length > 0) &&
                    <div className="d-flex justify-content-end">
                      {
                        tabList.map((tab, index) => {
                          return <Button
                            key={index}
                            className={`open-close-button ml-1${index === tabList.length - 1 && " mr-3"}`}
                            variant={ tab === openCloseType && "active" }
                            onClick={() => {
                              this.setState({
                                openCloseType: tab,
                              })
                            }}
                          >
                            {tab === "open" && "Open"}
                            {tab === "close" && "Close"}
                            {tab === "all" && "Default"}
                          </Button>
                        })
                      }
                    </div>
                }

                {/* Workflow Details */}
                <div
                  className={tabList && tabList.length > 0 ? "workflow-container" : ""}
                >
                  <div
                    className={tabList && tabList.length > 0 ? "p-3" : ""}
                  >
                    <label>Workflow Name</label>
                    <div className="mb-3 d-flex align-items-center form-control height-fix">{currentWorkflowDetails.name}</div>

                    <label>Description</label>
                    <div className="mb-3 d-flex align-items-center form-control height-fix">{currentWorkflowDetails.description}</div>

                    {/* Workflow Steps */}
                    {
                      currentWorkflowSteps.map((step) => {
                        return <div
                          key={step.position}
                          className="step-container mb-4"
                        >

                          {/* Step Heading - Number */}
                          <h6>Step {step.position}</h6>

                          {/* Step Details */}
                          <div className="ml-2">
                            <label>Type</label>
                            <div className="mb-3 d-flex align-items-center form-control height-fix">{step.stepTypeOption.label}</div>

                            <label>Description</label>
                            <div className="mb-3 d-flex align-items-center form-control height-fix">{step.custom_description}</div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="container">
            <div className="d-flex justify-content-end">
              <Button
                className="p-2"
                variant="success"
                onClick={onHide}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    eventPairMaps: getEventPairMaps(state, props),
    currentEventPairMap: state.enterpriseManager.priorityEventsManagement.currentEventPairMap,
  };
};

export default connect(mapStateToProps)(ViewExampleModal);