// import { call, take, race, put, takeLatest, select } from "redux-saga/effects";
import { call, take, race, takeLatest } from "redux-saga/effects";
import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

import {
  loadEmSitesListSuccess,
  loadEmSitesListFailed,
  loadEmSitesListRestore,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

export function* watchGetSites() {
  // Automatically cancels any previous sagas started
  // from here
  yield takeLatest("LOAD_EM_SITES_LIST_START", function* (action) {
    yield race({
      task: call(loadEmSitesListStartRe, action),
      cancel: take([
        "LOAD_EM_SITES_LIST_CANCEL",
        "LOAD_EM_SITES_LIST_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

export function* loadEmSitesListStartRe(action) {
  const { enterprise_group_id, group_id } = action;
  if (!action.enterprise_group_id)
    throw new Error(" enterprise_group_id is required for this api call.");

  const params = {
    successActionParams: {
      enterprise_group_id,
      group_id
    },
    reducerKey: "enterpriseManager",
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseGroups/getSites/${action.enterprise_group_id}${group_id ? "?group_id=" + group_id : ""}`
    ),
    successAction: loadEmSitesListSuccess,
    failedAction: loadEmSitesListFailed,
    restoreAction: loadEmSitesListRestore,
    extraParams: { cacheSubKey: action.enterprise_group_id },
  };

  yield cacheIndexes.restoreOrLoad(params);
}
