export const loadCustomReportStart = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_START", ...params };
};

export const loadCustomReportSuccess = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_SUCCESS", ...params, };
};

export const loadCustomReportFailed = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_FAILED", ...params };
};

export const loadCustomReportCancel = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_CANCEL", ...params };
};

export const clearCustomReport = (params) => {
  return { type: "CLEAR_CUSTOM_REPORT", ...params };
};

export const loadCustomReportBundlesStart = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_BUNDLES_START", ...params };
};

export const loadCustomReportBundlesSuccess = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_BUNDLES_SUCCESS", ...params, };
};

export const loadCustomReportBundlesFailed = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_BUNDLES_FAILED", ...params };
};

export const loadCustomReportBundlesCancel = (params) => {
  return { type: "LOAD_CUSTOM_REPORT_BUNDLES_CANCEL", ...params };
};

export const addCustomReportExternalTaskId = (params) => {
  return { type: "ADD_CUSTOM_REPORT_EXTERNAL_TASK_ID", ...params };
}

export const loadUserGeneratedReportStart = (params) => {
  return { type: "LOAD_USER_GENERATED_REPORT_START", ...params };
};

export const loadUserGeneratedReportSuccess = (params) => {
  return { type: "LOAD_USER_GENERATED_REPORT_SUCCESS", ...params, };
};

export const loadUserGeneratedReportFailed = (params) => {
  return { type: "LOAD_USER_GENERATED_REPORT_FAILED", ...params };
};

export const loadUserGeneratedReportCancel = (params) => {
  return { type: "LOAD_USER_GENERATED_REPORT_CANCEL", ...params };
};

export const clearUserGeneratedReport = (params) => {
  return { type: "CLEAR_USER_GENERATED_REPORT", ...params };
};

export const setUserGeneratedReportExpandSite = (params) => {
  return { type: "SET_USER_GENERATED_REPORT_EXPAND_SITE", ...params };
};

export const resetUserGeneratedReportExpanded = (params) => {
  return { type: "RESET_USER_GENERATED_REPORT_EXPANDED", ...params };
};