import { watchLoadDeveloperApps } from "./developers";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadDeveloperApps(),
    ],
  );
}