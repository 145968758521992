import React from "react";

import WeekSelect from "./WeekSelect";
import MonthSelect from "./MonthSelect";

class WeekOrMonthSelect extends React.Component {
  render() {
    const {
      selectBy,
      changeWeek,
      selectedWeek,
      changeMonth,
      selectedMonth,
      // changeSelectBy,
      selectedYear
    } = this.props;

    // const selector = (
    //   <div className="form-group">
    //     <label htmlFor="WeekOrMonthSelect">Select by...</label>
    //     <select
    //       className="form-control"
    //       id="WeekOrMonthSelect"
    //       onChange={changeSelectBy}>
    //       <option value="month">Month</option>
    //       <option value="week">Week</option>
    //     </select>
    //   </div>
    // );

    let monthOrWeek =
      selectBy === "month" ? (
        <MonthSelect
          changeMonth={changeMonth}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      ) : (
        <WeekSelect changeWeek={changeWeek} selectedWeek={selectedWeek} />
      );

    return (
      <>
        {/* {selector} */}
        {monthOrWeek}
      </>
    );
  }
}
export default WeekOrMonthSelect;
