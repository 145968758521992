import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class CONXTDLogo extends React.Component {
  render() {
    const suffix = this.props.dark ? "_dark" : "";
    const linkClass = this.props.className || "";
    const { snowMode } = this.props;

    return (
      <Link
        to="/"
        className={`logo ${linkClass}`}
        style={this.props.wrapperStyle}>
        <img
          src={`/assets/images/logo${snowMode ? "snowMode" : ""}${suffix}.png`}
          alt="CONXTD Logo"
          className="d-inline-block align-top conxtd-logo"
          style={{
            height: "60px",
            marginTop: "10px",
            ...this.props.style
          }}
        />
      </Link>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    snowMode: state.app.snowMode,
  };
};
export default connect(mapStateToProps)(CONXTDLogo);