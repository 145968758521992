import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select, { components } from 'react-select';

import AddToEnterpriseElement from "./AddToEnterpriseElement";
import SaveSiteSuccess from "./SaveSiteSuccess";

import Alert from "components/Common/Alert";
import moment from "../../../../../node_modules/moment-timezone/index";
import countryList from "react-select-country-list";
import { loadCompanyStart } from "services/redux/actions/company";
import {
  selectCompanyFields 
} from "services/redux/selectors/serviceDesk/addSite";
import {
  loadCompanyFieldsStart,
  loadCompanyFieldsReset,
 } from "services/redux/actions/serviceDesk";
import { Tooltip } from "components/Common/Tooltip/Tooltip";
import _ from "lodash";
import Icon from "components/Icons/Icon";
import Loading from "components/Loading";
import { darkModeSelectStyles } from "components/Common/CustomStyles/darkModeSelectStyles";

import "./AddSingleSite.scss";
import conxtdOut from "apis/conxtdOut";

/**
 * Service Desk Add Site
 *
 * Allows user to add single site with/without enterprise association to service desk
 *
 **/
const AddSingleSite = ({
  // props
  showModal, 
  handleClose, 
  handleShow,
  companyType, 
  companyId, 
  previousDataTableState,
}) => {

  const dispatch = useDispatch();
  // REDUX SELECTORS ------
  const { darkMode } = useSelector((state) => state.app)
  const { getCompanyFieldsData } = useSelector(selectCompanyFields);
  // -------------------
  
  // STATE MANAGEMENT ------
  // toggle states
  const [addToEnterprise, setAddToEnterprise] = useState(false);
  const [showTimezones, setShowTimezones] = useState(false);
  const [disabledButton, setDisabledButton] = useState("");
  const [clearRender, setClearRender] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false)

  // form data to be submitted
  const [site_ref, setSiteRef] = useState();
  const [transmitter_ref, setTransmitterRef] = useState();
  const [mctcluster_id, setMctClusterId] = useState();
  const [arcmap_id, setArcMapId] = useState();
  const [mobile_app_id, setMobileAppId] = useState();
  const [contract_number, setContractNumber] = useState();
  const [product_description, setProductDescription] = useState();
  const [siteName, setSiteName] = useState();
  const [companyName, setCompanyName] = useState();
  const [company_id, setCompanyId] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState("");
  const [town, setTown] = useState("");
  const [postcode, setPostcode] = useState();
  const [country_code, setCountryCode] = useState();
  const [timezone, setTimezone] = useState();
  const [showCompanyCustomFields, setShowCompanyCustomFields] = useState(false);
  const [companyCustomFields, setCompanyCustomFields] = useState([]);
  const [name, setName] = useState(null);
  const [custom_site_ref, setCustomSiteRef] = useState(null);
  const [enterprise, setEnterprise] = useState(null);
  const [billing_status, setBillingStatus] = useState(null);
  const [enterprise_groups, setEnterpriseGroups] = useState(null);
  const [enterpriseCustomFieldsData, setEnterpriseCustomFieldsData] = useState([]);
  const [companyCustomFieldsData, setCompanyCustomFieldsData] = useState([]);
  const [slug, setSlug] = useState("");
  const [associatedAlarmConnections, setAssociatedAlarmConnections] = useState([]);
  const [noAlarmAssociation, setNoAlarmAssociation] = useState(false);

  // populate form state
  const [formArcInstaller, setFormArcInstaller] = useState([]);
  const [formMobileApps, setFormMobileApps] = useState([]);
  const [formBillingStatus, setFormBillingStatus] = useState([]);
  const [formCountryCodes, setFormCountryCodes] = useState([]);
  const [formTimezones, setFormTimezones] = useState([]);
  // const [formMctClusters, setFormMctClusters] = useState([]);
  const [formEnterprises, setFormEnterprises] = useState([]);
  const [formEnterpriseGroups, setFormEnterpriseGroups] = useState([]);

  // form validation
  const [existingSites, setExistingSites] = useState(false);
  const [invalid, setInvalid] = useState(true);
  let initInvalidComponent = useMemo(() => ({
    site_ref: {null: true},
    transmitter_ref: {null: true},
    mobile_app_id: {null: true},
    arcmap_id: {null: true},
    companyId: {null: true},
    siteName: {null: true},
    address1: {null: true},
    postcode: {null: true},
    country_code: {null: true},
  }), [])
  const [invalidComponent, setInvalidComponent] = useState(initInvalidComponent);
  const [invalidSite, setInvalidSite] = useState(true);
  const [invalidEnterprise, setInvalidEnterprise] = useState(true);
  const [invalidSiteRef, setInvalidSiteRef] = useState(false);
  const [siteRefShort, setSiteRefShort] = useState(true);
  const [siteRefLong, setSiteRefLong] = useState(false);
  const [signallingTypeFeedback, setSignallingTypeFeedback] = useState(false);
  const [siteRefWithoutPrefix, setSiteRefWithoutPrefix] = useState("");
  const [siteRefTitle, setSiteRefTitle] = useState("");
  const [transmitterRefTitle, setTransmitterRefTitle] = useState("");
  const [formSiteRefPrefix, setFormSiteRefPrefix] = useState("");

  const [signallingTypePrefixes] = useState({
    csl_pro: { siteRefTitle: "CSL Connection ID", transmitterRefTitle: "ARC Connection ID", siteRefPrefix: "21" },
  })

  // objects to contain submit data
  const enterpriseFields = addToEnterprise ? {
    name,
    enterprise,
    custom_site_ref,
    billing_status,
    groups: enterprise_groups,
    enterprise_custom_fields: enterpriseCustomFieldsData,
  } : {};

  const formData = {
    mobile_app_id,
    site_ref,
    transmitter_ref,
    company_name: siteName,
    address1,
    address2,
    town,
    country_code,
    postcode,
    timezone,
    arcmap_id,
    mctcluster_id,
    contract_number,
    product_description,
    company_custom_fields: companyCustomFieldsData,
    ...enterpriseFields,
  }
  // -------------------

  // FUNCTIONS ----------

  // amends <Select /> for darkmode compatibility - 
  // refer to src/components/Enterprise/EnterpriseManager/GroupLayout/ViewGroup/FormElement.js

  // Custom Select Component and Options -----
  const CustomOption = (props) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className='d-flex align-items-center pl-1 pr-1'>
          <span>{data.label}</span>
          {data.associated_icon && <span className='ml-3'>{data.associated_icon}</span>}
        </div>
      </components.Option>
    );
  };
  // -----------

  const handleNullChange = (name, value) => {
    setInvalidComponent((prevState) => ({
      ...prevState,
      [name]: { null: value ? false : true},
    }));
  };

  const setValidity = useCallback(() => {
    setInvalidSiteRef(siteRefShort || siteRefLong || signallingTypeFeedback || existingSites);
    Object.values(invalidComponent).some(value => value.null) ? setInvalidSite(true) : setInvalidSite(false);
    (invalidSite || invalidSiteRef || noAlarmAssociation) ? setInvalid(true) : setInvalid(false);
  }, [
    invalidComponent,
    invalidSite,
    invalidSiteRef,
    siteRefShort,
    siteRefLong,
    existingSites,
    signallingTypeFeedback,
    noAlarmAssociation,
  ]);

  const getTimezoneByCountry = useCallback((country) => {
    const timezone = moment.tz.zonesForCountry(country);
    if (country_code === 'US' && timezone.length > 1) {
      const zones = timezone.map(zone => ({label: zone.split('America/'), value: zone}))
      setFormTimezones(zones)
      setShowTimezones(true);
    } else {
      setTimezone(timezone[0])
      setShowTimezones(false);
    }
    return timezone;
  }, [country_code])

  const checkExistingSites = useCallback((sudoSiteRef) => {
    if (sudoSiteRef && companyId && mctcluster_id) {
      const getExistingSites = conxtdOut.get(`/ServiceDesk/getExistingSites/${String(sudoSiteRef)}/${companyId}/${mctcluster_id}`)
      getExistingSites.then((response) => {
        setExistingSites(response.data?.['sudoSites'])
      }).catch((error) => console.log(error));
    }
  }, [companyId, mctcluster_id]);

  useEffect(() => {
    if (!existingSites) {
      setInvalidSiteRef(false);
    } else {
      setInvalidSiteRef(true);
    }
  }, [existingSites])

  const debouncedCheckExistingSites = useMemo(
    () => _.debounce(checkExistingSites, 300),
    [checkExistingSites]
  );

  const initialiseState = useCallback(() => {
      setSiteName("");
      setSiteRef("");
      setSiteRefWithoutPrefix("");
      setTransmitterRef("");
      setMctClusterId("");
      setArcMapId();
      setMobileAppId();
      setContractNumber("");
      setProductDescription("");
      setAddress1("");
      setAddress2("");
      setTown("");
      setPostcode("");
      setCountryCode("");
      setTimezone("");
      setSlug("");
      setSignallingTypeFeedback(false);
      setNoAlarmAssociation(false);
      setAddToEnterprise(false);
      setInvalidComponent(initInvalidComponent);
      setEnterpriseCustomFieldsData([]);
      setFormSiteRefPrefix("");
      setExistingSites(false);
  }, [
    setFormSiteRefPrefix,
    setMobileAppId,
    setSlug,
    setSignallingTypeFeedback,
    setNoAlarmAssociation,
    initInvalidComponent,
    setInvalidComponent,
  ])

  const checkClose = useCallback(() => {
    if (Object.values(invalidComponent).some(value => !(value.null))) {
      Alert({
        text: `Close this window? Any unsaved changes will be lost.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Close",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value) {
          handleClose();
          initialiseState();
        } else {
          return
        }
      })
    } else {
      handleClose();
      initialiseState();
    }
  }, [invalidComponent, handleClose, initialiseState])

  const submitForm = () => {
    Alert({
      text: `Are you sure you want to add this site?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Add site",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.value && company_id) {
        const submit = conxtdOut.post(`/ServiceDesk/addSites/${company_id}`, formData);
        setSubmitLoading(true);
        try {
          submit.then(() => {  
            handleClose();
            initialiseState();
            setShowAlert(true);
            setSubmitLoading(false);
      
            // reset state on successful submission
            dispatch(loadCompanyStart({ 
              company_id: companyId, 
              pageSize: previousDataTableState.pageSize,
              requestPageNumber: previousDataTableState.page + 1,
            }))
            initialiseState();
            dispatch(loadCompanyFieldsReset());
        });
        } catch (error) {
          setSubmitLoading(false);
          console.log("error: ", error)
            Alert({
              text: "Add site failed, please try again.",
              icon: "error",
              timerProgressBar: true,
              timer: 5000,
            });
        }
      } else {
        return
      }
    })
  }

  const mapCustomFields = (state, setState) => {
    const groupedFields = [];
    const entries = Object.entries(state);
    
    for (let i = 0; i < entries.length; i += 2) {
      groupedFields.push(entries.slice(i, i + 2));
    }

    const render = groupedFields.map((group, index) => (
      <div className="row" key={index}>
        {group.map(([key, value]) => (
          <Form.Group className="col-6" key={key}>
            <Form.Label>{value}</Form.Label>
            <Form.Control
              name={key}
              onChange={(e) => {
                const newVal = e.target.value;
                const fieldName = e.target.name;
                setState((prev) => {
                  const updatedFields = { field_id: fieldName, value: newVal };
                  const existingIndex = prev.findIndex(field => field.field_id === fieldName);
  
                  if (existingIndex !== -1) {
                    const updatedState = [...prev];
                    updatedState[existingIndex] = updatedFields;
                    return updatedState;
                  } else {
                    return [...prev, updatedFields];
                  }
                });
              }}
            />
          </Form.Group>
        ))}
      </div>
    ));

    return render;
  }

  // -------------------

  // USEEFFECT HOOKS --------

  useEffect(() => {
    if (!showModal) {
      setAddToEnterprise(false);
    }
  }, [showModal])

  useEffect(() => {
    const countryOptions = countryList().getData();
    setFormCountryCodes(countryOptions);
  }, []);

  useEffect(() => {
    setValidity();
    invalid ? setDisabledButton("disabled") : setDisabledButton("");
  }, [invalid, invalidSite, invalidSiteRef, invalidEnterprise, invalidComponent, setValidity])

  useEffect(() => {
    showModal || (setInvalidComponent(initInvalidComponent) && setValidity())
  }, [showModal, initInvalidComponent, setValidity])

  useEffect(() => {
    if (site_ref) {
      debouncedCheckExistingSites(site_ref);
    } else {
      setInvalidSiteRef(false);
    }

    return () => {
      debouncedCheckExistingSites.cancel();
    };
  }, [site_ref, debouncedCheckExistingSites]);

  useEffect(() => {
    if (country_code) {
      getTimezoneByCountry(country_code === 'UK' ? 'GB' : country_code);
    } else {
      setShowTimezones(false);
    }
  }, [country_code, getTimezoneByCountry])

  useEffect(() => {
    if (companyId) {
      const getFormData = conxtdOut.get(`/ServiceDesk/getFrontendFormData/${companyId}`);
        getFormData.then((response) => {
          const data = response?.data?.[0];
          const mappedArcInstaller = Object.entries(data['arcInstaller']).map(([key, value]) => ({
            label: value,
            value: key,
          }))
          setFormArcInstaller(mappedArcInstaller);
          mappedArcInstaller?.length === 1 && setArcMapId(mappedArcInstaller?.[0].value);
          setFormMobileApps(
            Object.entries(data['mobileApps'])?.map(([key, value]) => ({
              label: key,
              value: {
                mobile_app_id: value.mobile_app_id,
                mct_cluster_id: value?.mct_cluster_id ?? null,
                slug: value?.slug ?? null,
              },
              associated_icon: (
                <Icon
                  className={`far ${associatedAlarmConnections.includes(value.mobile_app_id) 
                    ? 'fa-check-circle signalling-icon associated' : 'fa-times-circle signalling-icon not-associated'}`}
                />
              ),
            }))
          )
          setFormBillingStatus(Object.entries(data['billingStatus']).map(([key, value]) => {
            return {label: value, value: key}
          }))
          setFormEnterprises(Object.entries(data['enterprises']).map(([key, value]) => {
            return {label: key, value: value}
          }))
        }).catch((error) => {
          console.log(error);
        });
    }
  }, [companyId, associatedAlarmConnections])

  useEffect(() => {
    if (associatedAlarmConnections) {
      setNoAlarmAssociation(!(associatedAlarmConnections.includes(mobile_app_id)));
    };
  }, [associatedAlarmConnections, mobile_app_id])

  useEffect(() => {
    if (companyId) {
      const getCompanyDetails = conxtdOut.get(`/Authake/company/${companyId}`);
      getCompanyDetails.then((response) => {
        setCompanyName(response.data.company?.name)
      }).catch((error) => {
        console.log(error);
      })
    }
  }, [companyId])

  useEffect(() => {
    if (companyId) {
      dispatch(loadCompanyFieldsStart({ companyId }))
    }
  }, [companyId, dispatch])

  useEffect(() => {
    if (companyId) {
      getCompanyFieldsData ? setCompanyCustomFields(getCompanyFieldsData) : setCompanyCustomFields({});
    } else {
      setCompanyCustomFields({});
    }
  }, [companyId, getCompanyFieldsData, dispatch])

  useEffect(() => {
    companyCustomFields && Object.entries(companyCustomFields).length > 0 ? setShowCompanyCustomFields(true) : setShowCompanyCustomFields(false);
  }, [companyCustomFields])

  useEffect(() => {
      switch (slug) {
        // csl pro
        case 'csl-pro':
          setSiteRefTitle(signallingTypePrefixes.csl_pro.siteRefTitle)
          setTransmitterRefTitle(signallingTypePrefixes.csl_pro.transmitterRefTitle)
          setFormSiteRefPrefix(signallingTypePrefixes.csl_pro.siteRefPrefix)
          break;
      
        default:
          setSiteRefTitle("Site Ref")
          setTransmitterRefTitle("Transmitter Ref")
          setFormSiteRefPrefix("")
          setSiteRefShort(false);
          setSiteRefLong(false);
          break;
      }
  }, [
    slug, 
    signallingTypePrefixes,
  ])

  useEffect(() => {
    if (arcmap_id) {
      const getAssociatedAlarmConnections = conxtdOut.get(`/ServiceDesk/getCompaniesAlarmConnections/${arcmap_id}/?addSite=true`)
      getAssociatedAlarmConnections.then((response) => {
        const data = response.data?.['associatedAlarmConnections'];
        setAssociatedAlarmConnections(
          Object.values(data)?.map(value => value.mobile_app_id)
        );
      }).catch((error) => {
        console.log(error);
      })
    }
  }, [arcmap_id])

  useEffect(() => {
    companyType === "A"
    ? (() => {
      setArcMapId(companyId)
    })()
    : (() => {
      setCompanyId(companyId)
    })()
  }, [companyType, companyId]);

  // resets form fields to initialised state
  useEffect(() => {
    if (showModal) {
      setClearRender(false)
      debouncedCheckExistingSites()
    } else {
      setTimeout(() => {
        setClearRender(true);
        setSiteRef("");
     }, 500)
    }
  }, [showModal, debouncedCheckExistingSites])

  useEffect(() => {
    !arcmap_id && setMobileAppId(null);
  }, [arcmap_id])
  // -------------------

  // VIEW -------
  return (
    <>
      {!clearRender &&
      <Modal
        backdrop="static"    
        centered
        keyboard={false}
        onHide={checkClose}
        show={showModal}
        size="lg"
      >
        <Form className="edit-group-form" onSubmit={submitForm}>
          <Modal.Header
            closeButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Modal.Title>
              Add Single Site
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={`${darkMode ? "darkMode" : "background-modal-wrapper"}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={`container d-flex justify-content-center ${darkMode ? "darkMode" : "background-modal-wrapper"}`}>
              <Row className="w-100">
                <Col>

                {/* Row One Items */}
                <div className="custom-card" data-intercom-target={"signalling-information"}>
                  <Form.Text className="custom-card-header">Signalling Information</Form.Text>
                  <Row>
                    <Form.Group className="col">
                      {companyType && <div>
                        <label className="company-filter-label">{companyType === "A" ? "Installer" : "ARC:"} *</label>
                        <Select
                          data-intercom-target={"add-site-arc-select"}
                          options={formArcInstaller.sort((a, b) => a.label.localeCompare(b.label))}
                          required
                          isClearable={formArcInstaller.length === 1 ? false : true}
                          maxMenuHeight={413}
                          value={
                            arcmap_id
                              ? formArcInstaller?.find(app => app.value === arcmap_id)
                              : ""
                          }
                          onChange={(e) => {
                            handleNullChange("companyId", e ? e.value : null)
                            handleNullChange("arcmap_id", e ? e.value : null)
                            companyType === "A"
                              ? (() => {
                                setArcMapId(companyId)
                                setCompanyId(e ? e.value : null)
                              })()
                              : (() => {
                                setArcMapId(e ? e.value : null)
                                setCompanyId(companyId)
                              })()
                          }}
                          styles={darkMode ? darkModeSelectStyles : ""}
                        />
                      </div>}
                    </Form.Group>
                  </Row>
                  <Form.Group className="col d-flex flex-column">
                    <Row>
                      <div className="d-flex flex-column col p-0">
                        <Form.Label className="form-input-labels">Signalling Type</Form.Label>
                        <Select
                          data-intercom-target={"add-site-mobile-app-select"}
                          name="mobile_app_id"
                          id="mobile_app_id"
                          className={arcmap_id ? "" : "no-click"}
                          options={formMobileApps?.sort((a, b) => a.label.localeCompare(b.label))}
                          components={{ Option: CustomOption }}
                          isClearable="true"
                          value={
                            mobile_app_id
                              ? formMobileApps?.find(app => app.value.mobile_app_id === mobile_app_id)
                              : ""
                          }
                          maxMenuHeight={400}
                          required
                          onChange={(e) => {
                            setMobileAppId(e ? e.value.mobile_app_id : null)
                            setMctClusterId(e ? e.value.mct_cluster_id : null)
                            setSlug(e ? e.value.slug : null)
                            handleNullChange("mobile_app_id", e ? e.value.mobile_app_id : null)
                            setSiteRef("")
                            setSiteRefWithoutPrefix("")
                          }}
                          styles={{
                            ...(darkMode ? darkModeSelectStyles : {}),
                            option: (provided, state) => ({
                              ...provided, 
                            }),
                          }}
                        />
                      </div>
                    </Row>
                    <Row>
                      <Form.Control.Feedback type="warning" className="trigger-feedback-invalid col p-0">
                        <div className={`feedback-container-${noAlarmAssociation && mobile_app_id ? "after" : "before"}`}>
                            <div className="d-flex align-items-center flex-wrap justify-content-between">
                              <div className="feedback-text-stack col-9">
                                This Signalling Type is not associated with <span className="font-weight-bold">{companyName}.</span>
                                {/* This Signalling Type is not associated with <span className="font-weight-bold">Test Feedback</span> */}
                                <div className="mt-1 p-0">Contact the team to learn more.</div>
                              </div>
                              <Button className="feedback-button col-3" variant="primary" data-intercom-target={"open-chat-button-add-site"}>Open Chat</Button>
                            </div>
                        </div>
                      </Form.Control.Feedback>
                    </Row>
                  </Form.Group>
                  
                  <Row>
                    <Form.Group
                      id="site_ref"
                      className="col-6 site-ref-container d-flex flex-column detail-field"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>{siteRefTitle} *</Form.Label>
                      </div>
                      <div className={`d-flex align-items-center flex-wrap`}>
                        {siteRefTitle !== "Site Ref" &&
                          <div id="siteref-prefix" className="col-2">
                            <div className="d-flex align-items-center justify-content-center font-weight-bold">{formSiteRefPrefix}</div>
                          </div>
                        }
                        <Form.Control
                          className={`col ${siteRefTitle !== "Site Ref" && "siteref-field"} ${signallingTypeFeedback && "site-ref-warning"} ${mobile_app_id ? "" : "no-click"}`}
                          type="text"
                          name="site_ref"
                          value={siteRefWithoutPrefix}
                          maxLength={`${siteRefTitle !== "Site Ref" ? 6 : 50}`}
                          required
                          onChange={(e) => {
                            setSiteRefWithoutPrefix(String(e.target.value))
                            setSiteRef(formSiteRefPrefix ? String(formSiteRefPrefix + e.target.value) : String(e.target.value))
                            let length = e.target.value.length;
                            if (formSiteRefPrefix === "21") {
                              if (length < 6) {
                                setSiteRefShort(true)
                              } else if (length > 6) {
                                setSiteRefLong(true);
                              } else {
                                setSiteRefShort(false);
                                setSiteRefLong(false);
                              }
                            } else {
                              setSiteRefShort(false);
                              setSiteRefLong(false);
                            }
                            handleNullChange("site_ref", e.target.value)
                          }}
                        />
                        </div>
                        <Form.Control.Feedback type="warning" className="trigger-feedback-invalid">
                        <div className={`feedback-container-${existingSites || signallingTypeFeedback ? "after" : "before"}`}>
                            {(() => {
                              if (existingSites === 'company') {
                                return <div className="feedback-text-stack">This {siteRefTitle} is already taken. Please try again.</div>
                              } else  if (existingSites === 'all') {
                                  return (
                                    <div className="d-flex align-items-center flex-wrap justify-content-between col-12 m-0 p-0">
                                      <div className="feedback-text-stack">
                                        <div>Something doesn't seem right. Need help? Contact the team now.</div>
                                        {/* <div>Need help? Contact the team now.</div> */}
                                      </div>
                                      <Button className="feedback-button" variant="primary" data-intercom-target={"open-chat-button-add-site-2"}>Open Chat</Button>
                                    </div>
                                  )
                              } else if (!existingSites && signallingTypeFeedback) {
                                return <div className="feedback-text-stack">Update your {siteRefTitle} before continuing.</div>
                              } else {
                                return
                              }
                            })()}
                          </div>
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      id="transmitter_ref"
                      className="col-6 detail-field"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>{transmitterRefTitle} *</Form.Label>
                      </div>
                      <Form.Control
                        name="transmitter_ref"
                        value={transmitter_ref ? transmitter_ref : ""}
                        required
                        onChange={(e) => {
                          setTransmitterRef(e.target.value)
                          handleNullChange("transmitter_ref", e.target.value)
                        }}
                      />
                    </Form.Group>
                  </Row>
                  </div>

                  {/* Row Two Items */}
                  <div className="custom-card" data-intercom-target={"site-address"}>
                  <Form.Text className="custom-card-header">Site Address</Form.Text>
                    <Form.Group controlId="row-2">
                      <Row>
                        <Form.Group
                          controlId="siteName"
                          className="col-6"
                        >
                          <Form.Label>Site Name *</Form.Label>
                          <Form.Control
                            type="text"
                            name="siteName"
                            onChange={(e) => {
                              setSiteName(e.target.value)
                              handleNullChange("siteName", e.target.value)
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="custom_group_id"
                          className="col-6"
                        >
                          <Form.Label>Address 1 *</Form.Label>
                          <Form.Control
                            type="text"
                            name="address1"
                            required
                            onChange={(e) => {
                              setAddress1(e.target.value)
                              handleNullChange("address1", e.target.value)
                            }}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          controlId="custom_group_id"
                          className="col-6"
                        >
                          <Form.Label>Address 2</Form.Label>
                          <Form.Control
                            type="text"
                            name="Custom Group Ref"
                            onChange={(e) =>
                              setAddress2(e.target.value)
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="custom_group_id"
                          className="col-6"
                        >
                          <Form.Label>Town</Form.Label>
                          <Form.Control
                            type="text"
                            name="Custom Group Ref"
                            onChange={(e) =>
                              setTown(e.target.value)
                            }
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          controlId="custom_group_id"
                          className="col-6"
                        >
                          <Form.Label>Postcode *</Form.Label>
                          <Form.Control
                            type="text"
                            name="postcode"
                            required
                            onChange={(e) => {
                              setPostcode(e.target.value)
                              handleNullChange("postcode", e.target.value)
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-6"
                          controlId="site_type">
                          <Form.Label>Country *</Form.Label>
                          <Select
                            data-intercom-target={"add-site-country-select"}
                            name="country_code"
                            options={formCountryCodes}
                            maxMenuHeight={400}
                            required
                            isClearable
                            onChange={(e) => {
                              setCountryCode(e ? e.value : null);
                              handleNullChange("country_code", e ? e.value : null)
                            }}
                            styles={darkMode ? darkModeSelectStyles : ""}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        {showTimezones && <Form.Group 
                          className="col-6"
                          controlId="site_type">
                          <Form.Label>Zone</Form.Label>
                          <Select
                            data-intercom-target={"add-site-timezone-select"}
                            name="site_type"
                            options={formTimezones}
                            isClearable
                            maxMenuHeight={400}
                            onChange={(e) =>
                              setTimezone(e ? e.value : null)
                            }
                            styles={darkMode ? darkModeSelectStyles : ""}
                          />
                        </Form.Group>}
                      </Row>
                    </Form.Group>
                  </div>

                  {/* Company Fields */}
                  <div className="custom-card" data-intercom-target={"company-fields"}>
                    <Form.Text className="custom-card-header">Company Fields</Form.Text>
                    <Row>
                      <Form.Group
                        controlId="custom_group_id"
                        className="col-6"
                      >
                        <Form.Label>Contract Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="contract_number"
                          onChange={(e) =>
                            setContractNumber(e.target.value)
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="description"
                        className="col-6"
                      >
                        <Form.Label>Product Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="1"
                          name="Description"
                          placeholder="Description"
                          onChange={(e) =>
                            setProductDescription(e.target.value)
                          }
                        />
                      </Form.Group>
                    </Row>
                    {/* {!showCompanyCustomFields && <Form.Text className="font-italic">This company has no custom fields</Form.Text>} */}
                    {showCompanyCustomFields && mapCustomFields(companyCustomFields, setCompanyCustomFieldsData)}
                  </div>

                  <Form.Group id="add-to-ent-container">
                      <AddToEnterpriseElement
                        accordionStyle={true}
                        enterprise={enterprise}
                        setEnterprise={setEnterprise}
                        setName={setName}
                        setCustomSiteRef={setCustomSiteRef}
                        setBillingStatus={setBillingStatus}
                        invalidEnterprise={invalidEnterprise}
                        setInvalidEnterprise={setInvalidEnterprise}
                        formBillingStatus={formBillingStatus}
                        formEnterprises={formEnterprises}
                        addToEnterprise={addToEnterprise}
                        setAddToEnterprise={setAddToEnterprise}
                        enterpriseCustomFieldsData={enterpriseCustomFieldsData}
                        setEnterpriseCustomFieldsData={setEnterpriseCustomFieldsData}
                        mapCustomFields={mapCustomFields}
                        formEnterpriseGroups={formEnterpriseGroups}
                        setFormEnterpriseGroups={setFormEnterpriseGroups}
                        setEnterpriseGroups={setEnterpriseGroups}
                      />
                    {/* </div> */}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <div>* = required field</div>
                <div>
                  <Button
                    data-intercom-target={"close-modal-button-add-site"}
                    variant="primary"
                    className="mr-2"
                    onClick={() => {
                      checkClose();
                    }}
                  >
                    Close
                  </Button>
                  {invalid
                    ?
                      <Tooltip
                        placement="top"
                        description={
                          <ul className="m-0 pl-3 text-left">
                            {invalidSiteRef || siteRefShort ? <li key={1}><span className="font-italic">{siteRefTitle}</span> is invalid</li> : null}
                            {invalidSite || invalidEnterprise ? <li key={2}>required field(s) are empty</li> : null}
                            {mobile_app_id && noAlarmAssociation ? <li key={3}><span className="font-italic">Signalling Type</span> not associated with site</li> : null}
                          </ul>
                        }
                      >
                        <Button variant="success" className={invalid ? `${disabledButton}` : ""} data-intercom-target={"submit-button-add-site"}>Submit</Button>
                      </Tooltip>
                    :
                      <Button variant="success" onClick={submitForm} data-intercom-target={"submit-button-add-site"}>
                        {submitLoading ? <Loading /> : "Submit"}
                      </Button>
                  }
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>}

      <SaveSiteSuccess
        handleAddShow={handleShow}
        setShowAlert={setShowAlert}
        showAlert={showAlert}
      />

    </>
  );
  // -------------------
}

export default AddSingleSite;
