import React from "react";

import Icon from "components/Icons/Icon";

import { Button } from "react-bootstrap";

// import { default as T } from "services/i18n/Translate";

import GroupList from "./GroupList";

// import WaveLink from "components/Layout/WaveLink";

import NavIcon from "components/Layout/Nav/NavIcon";
import EditGroupModal from "./EditGroupModal";

import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import { loadEmSitesListStart } from "services/redux/actions/enterpriseManager";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { Link } from "react-router-dom";

import "./GroupItem.scss";

/**
 * Enterprise Management Group Item
 *
 * This component contains everything in the Wavelink
 *
 **/

class GroupItem extends React.Component {
  state = {
    open: null,
    // parentOfSelected: null,
    ancestorOfSelected: null,
    editModalShow: false,
    root_group_id: null,
  };

  isAncestorOfSelected(groupArray, selectedGroupId) {
    // recursively check the groups and their children to see if any of them match the selected group
    let containsSelected = false;
    for (let i=0; i<groupArray.length; i++) {
      containsSelected = (groupArray[i].id === selectedGroupId);
      if (containsSelected) {
        break;
      } else {
        if (groupArray[i].children && groupArray[i].children.length > 0) {
          containsSelected = this.isAncestorOfSelected(groupArray[i].children, selectedGroupId);
          if (containsSelected) break;
        }
      }
    }
    return containsSelected;
  }

  componentDidMount() {
    const { selectedGroupId } = this.props;
    const { root, children } = this.props.group;

    // const parentOfSelected =
    //   children.filter((g) => g.id === selectedGroupId).length > 0;

    // this.setState({
    //   open: root || parentOfSelected,
    //   parentOfSelected,
    // });

    const ancestorOfSelected = this.isAncestorOfSelected(children, selectedGroupId);

    this.setState({
      open: root || ancestorOfSelected,
      ancestorOfSelected,
    });
  }

  render() {
    let editModalClose = () => this.setState({ editModalShow: false });
    const spinner = (
      <Spinner animation="border" size="sm" style={{ color: "#0275d8" }} />
    );

    const { onSelect, selectedGroupId, enterprise } = this.props;

    const enterpriseId = enterprise.id;
    // const { parentOfSelected } = this.state;
    const { ancestorOfSelected } = this.state;

    const {
      // root,
      name,
      id,
      children,
      role_name,
      custom_group_id,
      custom_field_name_1,
      custom_field_name_2,
      custom_field_name_3,
      custom_field_value_1,
      custom_field_value_2,
      custom_field_value_3,
      description,
      parent_id,
    } = this.props.group;

    const listClasses = [];
    const linkClasses = [];

    const childrenCount = children.length || 0;

    let childGroupList = null;

    const active =
      this.state.open &&
      // Has children
      childrenCount > 0;
    const selected = selectedGroupId === id;

    if (childrenCount > 0) {
      listClasses.push("has_sub");
      childGroupList = (
        <GroupList
          style={{}}
          groups={children}
          onSelect={onSelect}
          selectedGroupId={selectedGroupId}
        />
      );
    }
    // Active means it's been opened and is also blue
    if (active) {
      listClasses.push("active nav-active");
    }
    // Selected means the group is the currently selected one
    if (selected) {
      listClasses.push("selected");
    }
    // if (parentOfSelected) {
    if (ancestorOfSelected) {
      listClasses.push("parent-of-selected");
    }

    const subArrow =
      childrenCount === 0 ? null : (
        <span className="menu-arrow">
          <Icon className="fas fa-chevron-right" />
        </span>
      );
    
      /** Future feature
    const setup = !root ? null : (
      <>
        <Button
          variant="outline-primary"
          size="sm"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            console.warn("Setup button in progress.");
          }}
        >
          {" "}
          <T>{"Setup"}</T>
        </Button>
 <EditSetup show={this.state.addModalShow} onHide={addModalClose} />
      </>
    );
        **/
    

    return (
      <li className={listClasses.join(" ")}>
        {/* <WaveLink
          style={{
            display: "flex",
          }}
          to={"#"}
          onClick={({ target }) => {
            // Short circuit for button
            if (target.className.includes("select-group")) return;

            this.setState({ open: !this.state.open });
          }}
          className={linkClasses.join(" ")}
        > */}
        <div
          style={{
            display: "flex",
          }}
          onClick={({ target }) => {
            // Short circuit for button
            if (target.className.includes("select-group")) return;

            this.setState({ open: !this.state.open });
          }}
          // className="group-item waves-effect"
          className={linkClasses.join(" ") + "group-item waves-effect"}
        >
          {this.props.icon && <NavIcon className={this.props.icon} />}
          <span>{name}</span>
          {subArrow}
          {/* {setup} */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: "1",
              alignItems: "center",
              fontSize: "12px",
              marginLeft: "20px",
            }}
          >
            <span
              className="d-none d-sm-none d-md-block"
              style={{
                marginRight: "70px",
              }}
            >
              {this.props.loading === true
                ? spinner
                : this.props.counts
                    .filter((x) => x.enterprise_group_id === id)
                    .map((x) => {
                      return (
                        <p key={x.enterprise_group_id}>
                          {x.site_count.live_sites}
                        </p>
                      );
                    })}
            </span>

            {/* <span
              className="d-none d-sm-none d-md-block"
              style={{
                marginRight: "80px",
              }}
            >
              {this.props.loading === true
                ? spinner
                : this.props.counts
                    .filter((x) => x.enterprise_group_id === id)
                    .map((x) => {
                      return (
                        <p key={x.enterprise_group_id}>
                          {x.site_count.total_sites}
                        </p>
                      );
                    })}
            </span> */}

            <span
              className="d-none d-sm-none d-md-block"
              style={{
                marginRight: "70px",
              }}
            >
              {this.props.loading === true
                ? spinner
                : this.props.counts
                    .filter((x) => x.enterprise_group_id === id)
                    .map((x) => {
                      return (
                        <p key={x.enterprise_group_id}>{x.report_count}</p>
                      );
                    })}
            </span>

            {(role_name && (role_name === "Admin")) 
              ? <>
                  <Link
                    to={{
                      pathname: `/enterprise/${enterpriseId}/${id}/view-group`,
                      state: {
                        root_group_id: this.props.enterprise.root_group_id,
                        selectedGroupId: selectedGroupId,
                        group: this.props.group,
                      },
                    }}
                  >
                    <div>
                      <Button
                        variant={`${selected ? "" : "outline-"}primary`}
                        style={{
                          margin: "0 8px",
                          padding: "0px",
                          width: "50px",
                          height: "29px",
                        }}
                        onClick={() => {
                          onSelect(id);

                          this.props.dispatch(
                            loadEmSitesListStart({
                              enterprise_group_id: id,
                            })
                          );
                        }}
                      >
                        <Icon className="fas fa-eye" title="View" />
                      </Button>
                    </div>
                  </Link>
                  <Button
                    variant={`${selected ? "" : "outline-"}primary`}
                    style={{
                      margin: "0 8px",
                      padding: "0px",
                      width: "50px",
                      height: "29px",
                    }}
                    onClick={(e) => {
                      onSelect(id);
                      this.setState({
                        editModalShow: true,
                        parent_id: parent_id,
                        custom_group_id: custom_group_id,
                        custom_field_name_1: custom_field_name_1,
                        custom_field_name_2: custom_field_name_2,
                        custom_field_name_3: custom_field_name_3,
                        custom_field_value_1: custom_field_value_1,
                        custom_field_value_2: custom_field_value_2,
                        custom_field_value_3: custom_field_value_3,
                        name: name,
                        description: description,
                      });
                    }}
                  >
                    <Icon className="fas fa-edit" title="Edit" />
                  </Button>
                  {/* <Button
                    variant={`${selected ? "" : "outline-"}primary`}
                    style={{
                      margin: "0 8px",
                      padding: "0px",
                      width: "50px",
                      height: "29px",
                    }}
                  >
                    <Icon className="fas fa-chart-pie" title="Reports" />
                  </Button> */}

                  <EditGroupModal
                    show={this.state.editModalShow}
                    onHide={editModalClose}
                    parent_id={this.state.parent_id}
                    custom_group_id={this.state.custom_group_id}
                    custom_field_name_1={this.state.custom_field_name_1}
                    custom_field_name_2={this.state.custom_field_name_2}
                    custom_field_name_3={this.state.custom_field_name_3}
                    custom_field_value_1={this.state.custom_field_value_1}
                    custom_field_value_2={this.state.custom_field_value_2}
                    custom_field_value_3={this.state.custom_field_value_3}
                    description={this.state.description}
                    name={this.state.name}
                  />
                </>
              : <div style={{width: "198px"}}></div>
            }
          </div>
        {/* </WaveLink> */}
        </div>

        {childGroupList}
      </li>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    counts: state.enterpriseManager.counts,
    loading: state.enterpriseManager.loading,
  };
};

export default connect(mapStateToProps, null)(GroupItem);
