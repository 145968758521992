import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import WeekOrMonthSelect from "./WeekOrMonthSelect";
import DataTable from "./DataTable";
import Pie from "../Scheduled/Pie";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import _ from "lodash";

// import { getExceptionReportTitle } from "services/redux/selectors";

import { getConfig } from "services/redux/selectors/reports/common/";

import { default as T } from "services/i18n/Translate";

class PageLayout extends React.Component {
  render() {
    const { reportType, config } = this.props;

    const loading = _.get(this.props.data, "loading") ? " loading" : "";

    const pie = <Pie reportType={reportType} changeFilter={() => {}} />;

    if (_.get(this.props, "index")) {
      return pie;
    }

    return (
      <>
        <div className={"row" + loading}>
          <div className="col-md-12">
            <ErrorBoundary>
              <div className="card m-b-30 visual-data">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-5 col-md-12">
                      <div className="col-md-12">
                        <h4 className="mt-0 header-title">
                          <T>{config.title}</T>
                        </h4>
                        <p className="text-muted m-b-30 d-inline-block text-truncate w-100">
                          <T>{config.description}</T>
                        </p>
                      </div>

                      <div
                        className="col-xl-5 col-md-12 m-auto"
                        style={{ maxWidth: "325px" }}>
                        <WeekOrMonthSelect
                          changeWeek={this.props.changeWeek}
                          changeMonth={this.props.changeMonth}
                          changeSelectBy={this.props.changeSelectBy}
                          selectBy={this.props.selectBy}
                          selectedWeek={this.props.selectedWeek}
                          selectedYear={this.props.selectedYear}
                          selectedMonth={this.props.selectedMonth}
                        />
                      </div>
                    </div>

                    <div className="col-xl-7 col-md-12 my-auto">{pie}</div>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </div>
        </div>
        <div className={"row" + loading}>
          <div className="col-md-12">
            <ErrorBoundary>
              <div className="card m-b-30 visual-data">
                <DataTable reportType={reportType} reportTitle={""} />
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </>
    );
  }
}
PageLayout.propTypes = {
  reportType: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state, props)
  };
};
export default connect(mapStateToProps)(PageLayout);
