import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Card, Row, Col, Button } from 'react-bootstrap';
import {
  getCompanyIdFromRoute,
  getSiteRefFromRoute,
 } from 'services/redux/selectors/company';
 import { 
  loadPinMapsReset, 
  loadSudoSiteIdStart,
  loadEnterpriseAssociationsStart, 
  loadPinMapsStart, 
} from 'services/redux/actions/serviceDesk';
import Icon from 'components/Icons/Icon';
import conxtdOut from 'apis/conxtdOut';

import moment from 'moment-timezone';

import SiteDetails from './SiteDetails';

import { Tooltip } from 'components/Common/Tooltip/Tooltip';

import './AssetEditor.scss';
import AssetStoreFooter from './AssetStoreFooter';
import PinMapping from './PinMapping/PinMapping';
import EnterpriseAssociations from './EnterpriseAssociations/EnterpriseAssociations';

import Alert from 'components/Common/Alert';
import Loading from "components/Loading";

function AssetEditor({ pinMapsData, sudoSiteIdStore }) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const companyId = useSelector(getCompanyIdFromRoute);
  const siteRef = useSelector(getSiteRefFromRoute);
  const associations = useSelector((state) => state.serviceDesk.enterpriseAssociations);
  const darkMode = useSelector((state) => state.app.darkMode)

  const queryParams = new URLSearchParams(location.search);
  const enterprise_id = queryParams.get('enterprise_id');

  const [companyName, setCompanyName] = useState("");
  const [selectedOption, setSelectedOption] = useState("Site Details");
  const [siteDetails, setSiteDetails] = useState(null);
  const [createdDay, setCreatedDay] = useState(null);
  const [createdMonth, setCreatedMonth] = useState(null);
  const [createdYear, setCreatedYear] = useState(null);
  const [createdTime, setCreatedTime] = useState(null);
  const [modifiedDay, setModifiedDay] = useState(null);
  const [modifiedMonth, setModifiedMonth] = useState(null);
  const [modifiedYear, setModifiedYear] = useState(null);
  const [modifiedTime, setModifiedTime] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [disabledButton, setDisabledButton] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [invalidSite, setInvalidSite] = useState(false);
  const [invalidSiteRef, setInvalidSiteRef] = useState(false);
  const [signallingTypeFeedback, setSignallingTypeFeedback] = useState(false);
  const [invalidEnterprise, setInvalidEnterprise] = useState(true);
  const [existingSites, setExistingSites] = useState(false);
  const [siteRefShort, setSiteRefShort] = useState(false);
  const [siteRefLong, setSiteRefLong] = useState(false);
  const [editData, setEditData] = useState({});
  const [addToEnterpriseData, setAddToEnterpriseData] = useState({});
  const [mobile_app_id, setMobileAppId] = useState("");
  const [slug, setSlug] = useState("");
  const [renderOnLoad, setRenderOnLoad] = useState(true);
  const [noAlarmAssociation, setNoAlarmAssociation] = useState(false);
  const [sudoSiteId, setSudoSiteId] = useState(null);
  const [pinsLoaded, setPinsLoaded] = useState(false);
  const [loadingSiteDetails, setLoadingSiteDetails] = useState(false);
  const [loadingAssociationDetails, setLoadingAssociationDetails] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  
  // determines appropriate titles and prefixes for Signalling Type --
  const [siteRefTitle, setSiteRefTitle] = useState("");
  const [transmitterRefTitle, setTransmitterRefTitle] = useState("");
  const [formSiteRefPrefix, setFormSiteRefPrefix] = useState("");
  const [initSiteRefTitle, setInitSiteRefTitle] = useState(null);

  const [signallingTypePrefixes] = useState({
    csl_pro: { siteRefTitle: "CSL Connection ID", transmitterRefTitle: "ARC Connection ID", siteRefPrefix: "21" },
  })

  useEffect(() => {
    if (sudoSiteIdStore !== sudoSiteId) {
      setSudoSiteId(sudoSiteIdStore);
    }
  }, [sudoSiteIdStore, sudoSiteId])

  useEffect(() => {
    if (siteRefTitle && initSiteRefTitle === null) {
      setInitSiteRefTitle(siteRefTitle)
    }
  }, [siteRefTitle, initSiteRefTitle])
  
  useEffect(() => {
    if (siteDetails?.mobile_app_id) {
      switch (slug) {
        // csl pro
        case 'csl-pro':
          setSiteRefTitle(signallingTypePrefixes.csl_pro.siteRefTitle)
          setTransmitterRefTitle(signallingTypePrefixes.csl_pro.transmitterRefTitle)
          setFormSiteRefPrefix(signallingTypePrefixes.csl_pro.siteRefPrefix)
          break;
      
        default:
          setSiteRefTitle("Site Ref")
          setTransmitterRefTitle("Transmitter Ref")
          setFormSiteRefPrefix("")
          setSiteRefShort(false);
          setSiteRefLong(false);
          break;
      }
    }
  }, [siteDetails, siteDetails?.slug, siteDetails?.mobile_app_id, slug, signallingTypePrefixes])
  //  -------
  
  const [initInvalidSiteDetails, setInitInvalidSiteDetails] = useState({});
  const [invalidComponent, setInvalidComponent] = useState();
  const [changeDetected, setChangeDetected] = useState(false);
  const [initialiseFormDataTrigger, setInitialiseFormDataTrigger] = useState(false);

  useEffect(() => {
    if (siteDetails && initInvalidSiteDetails) {setInvalidComponent(initInvalidSiteDetails)}
  }, [siteDetails, initInvalidSiteDetails])

  // date formatting
  const formatDateTime = useCallback((type) => {
    const dateTime = siteDetails?.[type];
    const timezone = siteDetails?.timezone;
  
    if (!dateTime || !timezone) {
      return;
    }
  
    const converted = moment.utc(dateTime).tz(timezone);
  
    const getDayWithSuffix = (day) => {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = day % 100;
      return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };
  
    const formattedDay = getDayWithSuffix(converted.date());
    const formattedMonth = converted.format('MMM');
    const formattedYear = `${converted.year()}`;
    const formattedTime = converted.format('HH:mm');
  
    if (type === 'created') {
      setCreatedDay(formattedDay);
      setCreatedMonth(formattedMonth);
      setCreatedYear(formattedYear);
      setCreatedTime(formattedTime);
    } else if (type === 'modified') {
      setModifiedDay(formattedDay);
      setModifiedMonth(formattedMonth);
      setModifiedYear(formattedYear);
      setModifiedTime(formattedTime);
    }
  }, [siteDetails]);

  useEffect(() => {
    if (siteRef && companyId) {
      dispatch(loadSudoSiteIdStart({
        sudoSiteRef: siteRef,
        companyId,
      }));
    }
  }, [siteRef, companyId, dispatch])

  useEffect(() => {
    if (sudoSiteId) {
      dispatch(loadEnterpriseAssociationsStart({
        sudoSiteId: sudoSiteId,
      }))
    }
  }, [sudoSiteId, dispatch])

  useEffect(() => {
    if (sudoSiteId && Object.values(pinMapsData).length <= 0) {
      dispatch(loadPinMapsStart({
        sudo_site_id: sudoSiteId,
      }));
    }
  }, [sudoSiteId, pinMapsData, dispatch])

  useEffect(() => {
    if (siteDetails) {
      formatDateTime('created');
      formatDateTime('modified');
    }
  }, [siteDetails, formatDateTime])  

  const getSiteDetails = useCallback(() => {
    if (sudoSiteId && companyId) {
      setLoadingSiteDetails(true);
      const getDetails = conxtdOut.get(`/ServiceDesk/getSiteDetails/${sudoSiteId}/${companyId}`);
        getDetails.then((response) => {
          renderOnLoad && setRenderOnLoad(false);
          const data = response.data?.siteData;
          setLoadingSiteDetails(false);
          data && setSiteDetails(data);
        }).catch((error) => {
          Alert({
            text: "An issue occurred. Please try again later.",
            icon: "error",
          });
          setLoadingSiteDetails(false);
        })
      };
  }, [companyId, renderOnLoad, sudoSiteId])

  const setValidity = useCallback(() => {
    if (selectedOption === 'Site Details') {
      setInvalidSiteRef(siteRefShort || siteRefLong || signallingTypeFeedback || existingSites);
      Object.values(invalidComponent).some(value => value.null) ? setInvalidSite(true) : setInvalidSite(false);
      (invalidSite || invalidSiteRef || noAlarmAssociation || changeDetected === false) ? setInvalid(true) : setInvalid(false);
      // (invalidSite || invalidSiteRef || changeDetected === false) ? setInvalid(true) : setInvalid(false);
    } else if (selectedOption === 'Enterprise Associations') {
      invalidEnterprise ? setInvalid(true) : setInvalid(false);
    }
  }, [
    invalidComponent,
    invalidSite,
    invalidSiteRef,
    invalidEnterprise,
    siteRefShort,
    siteRefLong,
    changeDetected,
    selectedOption,
    existingSites,
    signallingTypeFeedback,
    noAlarmAssociation,
  ]);

  useEffect(() => {
    getSiteDetails();
  }, [companyId, sudoSiteId, getSiteDetails])
  
  useEffect(() => {
    if (companyId) {
      const getCompanyDetails = conxtdOut.get(`/Authake/company/${companyId}`);
      getCompanyDetails.then((response) => {
        setCompanyName(response.data.company?.name)
      }).catch((error) => {
        console.log(error);
      })
    }
  }, [companyId])

  useEffect(() => {
    if (invalidComponent) {
      setValidity();
      invalid ? setDisabledButton("disabled") : setDisabledButton("");
    }
  }, [invalid, invalidSite, invalidSiteRef, invalidEnterprise, invalidComponent, setValidity, disabledButton])

  const updateUrlSiteRef = () => {
    history.push(`/companies/company/${companyId}/${editData?.site_ref}`);
  }

  const handleSubmitEdit = () => {
    Alert({
      text: "Are you sure you want to save these changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.value) {
        // site details submission
        if (selectedOption === 'Site Details') {
          if (sudoSiteId) {
            const submit = conxtdOut.post(`/ServiceDesk/editSiteDetails/${sudoSiteId}`, editData);
            setLoadingSubmit(true);
              submit.then((response) => {
                setLoadingSubmit(false);
                Alert({
                  text: "Changes saved successfully",
                  icon: "success",
                  timerProgressBar: true,
                  timer: 5000,
                });
                setEditMode(false);
                companyId && getSiteDetails();
                updateUrlSiteRef();
              }).catch((error) => {
                setLoadingSubmit(false);
                console.log(error);
                Alert({
                  text: "An issue occurred. Please try again later.",
                  icon: "error",
                });
            })
          }
        // enterprise site submission
        } else if (selectedOption === 'Enterprise Associations') {
          const finalData = {
            ...addToEnterpriseData,
            timezone: siteDetails?.timezone,
            address1: siteDetails?.address1,
          }
          if (sudoSiteId && editData) {
            const submit = conxtdOut.post(`/ServiceDesk/addEnterpriseSite/${sudoSiteId}`, finalData);
            setLoadingAssociationDetails(true);
              submit.then(() => {
                setLoadingAssociationDetails(false);
                Alert({
                  text: "Enterprise association added successfully",
                  icon: "success",
                  timerProgressBar: true,
                  timer: 5000,
                });
                if (sudoSiteId) {
                  dispatch(loadEnterpriseAssociationsStart({
                    sudoSiteId: sudoSiteId,
                  }))
                }
                setInitialiseFormDataTrigger(true);
                setInvalidEnterprise(true);
              }).catch((error) => {
                setLoadingAssociationDetails(false);
                console.log(error);
                Alert({
                  text: "An issue occurred. Please try again later.",
                  icon: "error",
                });
            })
          }
        }
      } else {
        return
      }
    })
  }

  const exitEditMode = () => {
    if (editMode && changeDetected) {
      Alert({
        text: `Exit edit mode? Any unsaved changes will be lost. `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Exit",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value) {
          setEditMode(false);
          setInitialiseFormDataTrigger(true);
        } else {
          return
        }
      })
    } else {
      setEditMode(!editMode);
    }
  }

  const resetPinMapData = () => {
    dispatch(loadPinMapsReset());
  }

  const optionNames = [
    {name: "Site Details", icon: <Icon className="fas fa-info-circle menu-icon"/> },
    {name: "Pin Mapping", icon: <Icon className="fas fa-thumbtack menu-icon" /> },
    {name: "Enterprise Associations", icon: <Icon className="fas fa-link menu-icon" /> },
  ]

  let optionContent; 
  switch (selectedOption) {
    case "Site Details":
      optionContent = <SiteDetails
        siteRef={siteRef}
        initSiteRefTitle={initSiteRefTitle}
        siteDetails={siteDetails}
        editMode={editMode}
        companyId={companyId}
        formSiteRefPrefix={formSiteRefPrefix}
        transmitterRefTitle={transmitterRefTitle}
        siteRefTitle={siteRefTitle}
        setEditMode={setEditMode}
        setDisabledButton={setDisabledButton}
        setEditData={setEditData}
        changeDetected={changeDetected}
        setChangeDetected={setChangeDetected}
        setSiteDetails={setSiteDetails}
        invalidSiteRef={invalidSiteRef}
        setInvalidSiteRef={setInvalidSiteRef}
        setSiteRefShort={setSiteRefShort}
        setSiteRefLong={setSiteRefLong}
        setInvalidComponent={setInvalidComponent}
        signallingTypeFeedback={signallingTypeFeedback}
        setSignallingTypeFeedback={setSignallingTypeFeedback}
        invalidSite={invalidSite}
        existingSites={existingSites}
        setExistingSites={setExistingSites}
        initialiseFormDataTrigger={initialiseFormDataTrigger}
        setInitialiseFormDataTrigger={setInitialiseFormDataTrigger}
        getSiteDetails={getSiteDetails}
        mobile_app_id={mobile_app_id}
        setMobileAppId={setMobileAppId}
        slug={slug}
        setSlug={setSlug}
        loadingSiteDetails={loadingSiteDetails}
        renderOnLoad={renderOnLoad}
        noAlarmAssociation={noAlarmAssociation}
        setNoAlarmAssociation={setNoAlarmAssociation}
        setInitInvalidSiteDetails={setInitInvalidSiteDetails}
        initInvalidSiteDetails={initInvalidSiteDetails}
        companyName={companyName}
        />
      break;

      case "Pin Mapping":
        optionContent = <PinMapping
          siteName={siteDetails?.site_name}
          enterprise_id={enterprise_id} 
          sudoSite_id={sudoSiteId} 
          selectedOption={selectedOption} 
          renderOnLoad={renderOnLoad}
          pinsLoaded={pinsLoaded}
          setPinsLoaded={setPinsLoaded}
        />
      break;
      
      case "Enterprise Associations":
        optionContent = <EnterpriseAssociations
          companyId={companyId}
          enterprise_id={enterprise_id}
          setEditData={setEditData} 
          setInvalidComponent={setInvalidComponent}
          sudoSiteId={sudoSiteId}
          invalidEnterprise={invalidEnterprise} 
          setInvalidEnterprise={setInvalidEnterprise} 
          setAddToEnterpriseData={setAddToEnterpriseData} 
          initialiseFormDataTrigger={initialiseFormDataTrigger}
          setInitialiseFormDataTrigger={setInitialiseFormDataTrigger}
          siteRef={siteRef}
        />
      break;

    default:
      optionContent = null;
  }

  return (
    <div className={`${darkMode ? "darkMode" : ""}`}>
      <Card className="card-wrapper">
        <header className='asset-store-header'>
          <Link to={`/companies/company/${companyId}`} onClick={resetPinMapData}>
            <span className='back-to-sd'>
            <Icon className="fas fa-chevron-left mr-2" />
              Back to Service Desk
            </span>
          </Link>
          <span id='editor-site-name'>{siteDetails?.['site_name']}</span>
          <div className='date-time-wrapper'>
            <div className='d-flex justify-content-between align-items-center pl-1 pr-1 timezone-header'>
              <span className='font-weight-bold mr-2'>Timezone:</span>
              <span>{siteDetails?.timezone}</span>
            </div>
            <div className='date-time'>
              <Tooltip
                placement='left'
                description='Created'
              >
                <div className='date-time-section' id='created-date-time'>
                  <span className='date-time-icon'><Icon className="fas fa-cloud-upload-alt" /></span>
                  <div className='date-time-contents'>
                    <span className='date-chunk'>{createdDay}</span>
                    <span className='date-chunk'>{createdMonth}</span>
                    <span className='date-chunk'>{createdYear}</span>
                  </div>
                  <span className='ml-3'>{createdTime}</span>
                </div>
              </Tooltip>
              <Tooltip
                placement='left'
                description='Last Modified'
              >
                <div className='date-time-section' id='modified-date-time'>
                  <span className='date-time-icon'><Icon className="fas fa-clock" /></span>
                  <div className='date-time-contents'>
                    <span className='date-chunk'>{modifiedDay}</span>
                    <span className='date-chunk'>{modifiedMonth}</span>
                    <span className='date-chunk'>{modifiedYear}</span>
                  </div>
                  <span className='ml-3'>{modifiedTime}</span>
                </div>
              </Tooltip>
            </div>
          </div>
        </header>
        <Row className="settings-content">
          <Col className="col-3 col-md-2 asset-store-menu">
            <ul className="nav flex-column">
              {optionNames.map((optionName) => {
                return (
                  <li 
                    className={`nav-item ${optionName.name===selectedOption && "active"}`} 
                    key={optionName.name}
                  >
                    <span 
                      className="nav-link settings-option"
                      onClick={(e) => {
                        optionName.name === 'Site Details' && setEditMode(false);
                        setSelectedOption(optionName.name)
                      }}
                    >
                      <div className="option-name">
                        <span className="option-name-icon">{optionName.icon}</span>
                        <span>{optionName.name}</span>
                      </div>
                    </span>
                  </li>
                )
              })}
            </ul>
        </Col>
        <Col>
          <div className="asset-store-container">
            <main className='main-wrapper'>
              <header className="d-flex justify-content-between page-title align-items-center flex-row">
                <div className='d-flex justify-content-between flex-row align-items-center'>
                  <span className="d-flex custom-card-header p-0 mt-3 mb-3 align-items-center flex-row">
                    {selectedOption}
                  </span>
                  {selectedOption === 'Site Details' && 
                  <div id="" className='d-flex justify-content-between flex-row align-items-center'>
                    <span className='ml-2'>|</span>
                    <Tooltip
                      placement='right'
                      description={`Click to enter ${editMode ? 'View' : 'Edit'} mode`}
                    >
                      <div className='d-flex align-items-center edit-button-container'>
                        <span className='ml-2 mr-1'><Icon className={`fas fa-lg ${editMode ? 'fa-unlock' : 'fa-lock'} ${editMode ? 'fa-unlock' : 'fa-lock'}`} /></span>
                        <Button variant="primary" id="edit-button-container" onClick={exitEditMode} data-intercom-target={"edit-view-button"}>
                          <span id="edit-switch-label" className={editMode ? "edit-text" : "read-only-text"}>{editMode ? "View" : "Edit"}</span>
                        </Button>
                      </div>
                    </Tooltip>
                    </div>
                  }
                  {(selectedOption === 'Site Details' && loadingSiteDetails) &&
                    <span className="custom-card-header p-0 mt-3 mb-3 ml-2 align-items-center">
                    <Loading />
                  </span>}
                </div>
              </header>
              <div id="content-child">
                <div className={`${selectedOption.replace(/ /g, '-').toLowerCase()}-wrapper ${signallingTypeFeedback ? 'feedback-active' : ''} ${renderOnLoad ? 'loading-data' : ''}`}>
                  {optionContent}
                </div>
              </div>
            </main>
          </div>
        </Col>
      </Row>
      <footer id="footer-wrapper" className="d-flex col">
        <AssetStoreFooter
          associations={associations}
          companyId={companyId}
          changeDetected={changeDetected}
          editMode={editMode}
          setEditMode={setEditMode}
          exitEditMode={exitEditMode}
          disabledButton={disabledButton}
          siteName={siteDetails?.site_name}
          siteRefShort={siteRefShort}
          siteRefLong={siteRefLong}
          noAlarmAssociation={noAlarmAssociation}
          siteRefTitle={siteRefTitle}
          invalidEnterprise={invalidEnterprise}
          invalidSiteRef={invalidSiteRef}
          invalidSite={invalidSite}
          invalid={invalid}
          handleSubmitEdit={handleSubmitEdit}
          selectedOption={selectedOption}
          sudoSiteId={sudoSiteId}
          loadingAssociationDetails={loadingAssociationDetails}
          loadingSubmit={loadingSubmit}
        />
      </footer>
      </Card>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    sudoSiteIdStore: state.serviceDesk.sudoSiteId?.sudoSiteId,
    pinMapsData: state.serviceDesk.pinMaps,
  };
};

export default connect(mapStateToProps)(AssetEditor);
