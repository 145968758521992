import React, { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Initialised in bulk at higher level
 */
function TetherEmbedded({
  integration: { auth_token },
  event: { recording_id }
}) {
  useEffect(() => {
    window.$(".tetherx-recording-view").each(function(_, iframe) {
      // Set ready event
      window.$(iframe).on("load", function() {
        const message = {
          name: "tetherx-user-token",
          token: auth_token
        };
        // console.log("posting message to iframe:", message);
        // Post message to iframe. This is supposed to happen from
        // Tether's initialse function, but they don't do it right
        iframe.contentWindow.postMessage(message, "*");
      });
    });
  }, [auth_token]);
  // const postMessage = () => { };

  return (
    <iframe
      style={{ width: "90%", height: "240px", margin: "0 auto", marginTop: "10px", marginBottom: "10px"}}
      frameBorder="0"
      data-testid="clip-view-embedded"
      title="tetherx-embedded-view"
      className="tetherx-recording-view"
      src={`https://my.timeline.is/recordings/${recording_id}/preview`}></iframe>
  );
}
TetherEmbedded.propTypes = {
  event: PropTypes.shape({
    recording_id: PropTypes.string.isRequired
  }).isRequired,
  integration: PropTypes.shape({
    auth_token: PropTypes.string.isRequired
  }).isRequired
};

export default TetherEmbedded;
