import get from "lodash/get";
import find from "lodash/find";

const peoplecount = {
  RESULT: { show: false },
  EXPECTED: { show: false },
  ACTUAL: {
    Header: "Recorded Time",
  },
  DIFFERENCE: { show: false },

  EVENT_TEXT: {
    width: 160,
  },
  buildExportArray: ({
    data,
    getActualFromOccurrence,
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Recorded Time",
      "Event Text",
      "Occurrences",
    ];

    // Add headers
    result.push(headers);

    data.forEach((row) => {
      // Push site data
      result.push([
        row["custom-id"] || "",
        row["site-name"],
        row.group || "",
        row["site-ref"] || "",
        getActualFromOccurrence(get(row["actual-time"], 0)),
        row["event-text"],
        row.occurrences,
      ]);
    });

    return result;
  },
  buildExportArrayFull: ({
    data,
    // getActualFromOccurrence,
    getActualWithTimezone,
    sites
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Recorded Time",
      "Event Text",
      "Occurrences",
    ];

    // Add headers
    result.push(headers);

    data.forEach((row) => {
      // Push site data first
      result.push([
        row["custom-id"] || "",
        row["site-name"],
        row.group || "",
        row["site-ref"] || "",
        "",
        "",
        row.occurrences,
      ]);

      // Rows with each individual occurrence
      let siteData = find(sites, ["site_ref", row["site-ref"]]);
      if (siteData && siteData.occurrences) {
        siteData.occurrences.forEach((occurrence, index) => {
          result.push([
            row["custom-id"] || "",
            row["site-name"],
            row.group || "",
            row["site-ref"] || "",
            // getActualFromOccurrence(occurrence),
            getActualWithTimezone(occurrence),
            occurrence.text,
            "#" + (index+1),
          ]);
        });
      }

    });

    return result;
  },
};

export default peoplecount;