import React from "react";
import { connect } from "react-redux";

import moment from "services/locale/momentInit.js";

import { NA } from "services/redux/selectors";

import { getTimeFormat } from "services/redux/selectors/reports/scheduled";

import TimezoneDate from "components/Common/TimezoneDate";

class ActualTimezoneDate extends React.Component {
  render() {
    const { occurrence } = this.props;

    const localTimezoneText = moment.tz.guess();

    if (occurrence.open_type === "FAILED") {
      return NA();
    } else {
      return <TimezoneDate 
        date={occurrence.acked} 
        timezone={occurrence.timezone} 
        format={getTimeFormat()} 
        hideTimezone={occurrence.timezone === localTimezoneText}
      />;
    }
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(ActualTimezoneDate);