import React from "react";
import { connect } from "react-redux";

import { loadFailedToCloseReportStart, loadFailedToCloseReportCancel } from "services/redux/actions";

import { getEnterpriseFromRoute } from "services/redux/selectors";
import { getRouterParams } from "services/redux/selectors/app/";

import PageLayout from "./PageLayout";

import _ from "lodash";

class FailedToCloseReportPage extends React.Component {

  componentDidMount() {
    // only allow loading on mount when loading state is anything other than true
    if (this.props.loading !== true) {
      this.loadReport();
    }
  }

  componentDidUpdate() {
    // only allow loading on update when loading state is inital
    if (this.props.loading === null) {
      this.loadReport();
    }
  }

  componentWillUnmount() {
    // cancel any live report auto refresh, and reset filters and expanded
    this.props.dispatch(loadFailedToCloseReportCancel());
  }

  loadReport = () => {
    let { group_id } = this.props.router.params;

    const { enterprise } = this.props;

    // the root group was specified - we need to wait on loading enterprises
    // to get the root group id before we can begin our request
    if (group_id === "root" || !group_id) {
      group_id = _.get(enterprise, "root_group_id");
      // no enterprises loaded yet
      if (!group_id) return;
    }

    const params = {
      enterprise_id: enterprise.id,
      interval: 60 * 1000,
      refresh: true,
    }

    // dispatch request for report data
    this.props.dispatch(loadFailedToCloseReportStart(params));
  };

  render() {
    return (
      <PageLayout />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    loading: state.reports.failedtoclose.index.loading,
    router: { params: getRouterParams(state, props) }
  };
};

export default connect(mapStateToProps)(FailedToCloseReportPage);