import React from "react";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";

import _ from "lodash";

class ChartJSDoughnut extends React.Component {
  // Remove legend on smaller screens
  constructor(props) {
    super(props);

    this.state = {
      showLegend: false
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  // Responsive
  updatePredicate() {
    const screenWidth = window.innerWidth;
    const largeDown = screenWidth < 1200;
    // Show legend on xs, sm, md, lg devices
    this.setState({ showLegend: largeDown });
  }

  // Component stuff
  label(label) {
    if (_.get(this.props, "showPct")) {
      return label.split("% ")[1];
    }
    return label;
  }
  componentDidUpdate() {
    const total = this.props.pie.data
      .map(tot => tot.value)
      .reduce((accumulator, cur) => accumulator + cur, 0);
    if (total === 0) return;
    //console.log(this.refs);

    if (_.get(this.props, "filter")) {
      this.updateFilter();
    }
  }
  updateFilter = () => {
    // Change chart visibilty based on filter props
    // this.refs.chart.chartInstance.getDatasetMeta
    const chart = this.refs.chart.chartInstance;
    let i, ilen, meta;
    const filter = this.props.filter;

    for (i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
      meta = chart.getDatasetMeta(i);
      // eslint-disable-next-line no-loop-func
      _.each(meta.data, (item, index) => {
        const label = this.label(meta.data[index]._model.label);

        meta.data[index].hidden = false;
        if (Object.keys(filter).length > 0) {
          // Set visibility
          meta.data[index].hidden = !filter[label]; //!meta.data[index].hidden;
        }
      });
    }

    chart.update();
  };
  legendClick = (e, legendItem) => {
    // this.refs.chartRef.chartInstance.getDatasetMeta
    const chart = this.refs.chart.chartInstance;
    let i, ilen, meta;

    // Loop through datasets
    for (i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
      meta = chart.getDatasetMeta(i);
      // Looping through data portions/labels
      // eslint-disable-next-line no-loop-func
      _.each(meta.data, (item, index) => {
        // Selected label
        if (index === legendItem.index) {
          const label = this.label(meta.data[index]._model.label);

          this.props.changeFilter(label);
        }
      });
    }

    chart.update();
  };
  hover = () => {
    // console.log("fdsf");
  };
  render() {
    const total = this.props.pie.data
      .map(tot => tot.value)
      .reduce((accumulator, cur) => accumulator + cur, 0);
    // Chart totals add up to 0, it won't render correctly
    if (total === 0) {
      return null;
    }
    // console.log(this.props.pie.data, "value");

    let labels;
    // Calculate percentage
    if (_.get(this.props, "showPct")) {
      let total = 0;
      _.each(this.props.pie.data, item => {
        total = total + item.value;
      });
      labels = _.map(this.props.pie.data, (item, index) => {
        return (
          ((this.props.pie.data[index].value / total) * 100).toFixed(0) +
          "% " +
          item.name
        );
      });
    } else {
      labels = _.map(this.props.pie.data, "name");
    }

    const data = {
      // Extract 'name' property as a list of labels
      labels,
      datasets: [
        {
          //data: [300, 50, 100],
          data: _.map(this.props.pie.data, "value"),
          backgroundColor: this.props.pie.colors,
          // Remove hover effect
          hoverBackgroundColor: this.props.pie.colors,
          borderColor: this.props.darkMode ? "#eeeeee" : "#F5F5F5"
        }
      ]
    };

    const filter = _.get(this.props, "filter");

    const legend = filter
      ? {
          onClick: this.legendClick
        }
      : {};
    legend.display = !this.props.hideLegend && !this.state.showLegend;
    legend.labels = {};
    legend.labels.fontColor = this.props.darkMode ? "#eeeeee" : "#333333";
    legend.labels.fontFamily = "Open Sans";
    const onClick = filter
      ? (evt, elements) => {
          if (elements.length === 0) return;
          const label = this.label(elements[0]._view.label);

          this.props.changeFilter(label);
        }
      : null;

    const options = { onClick, legend };

    let className = "";

    if (this.props.pieType === "half") {
      options.rotation = 1 * Math.PI;
      options.circumference = 1 * Math.PI;
      className = " report-half-pie";
    }

    return (
      <div className={"chartjs-doughnut-container" + className}>
        <Doughnut ref="chart" data={data} options={options} />
      </div>
    );
  }
}
ChartJSDoughnut.propTypes = {
  pie: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number
      }).isRequired
    ),
    colors: PropTypes.array.isRequired
  }).isRequired,
  changeFilter: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return { darkMode: state.app.darkMode };
};
export default connect(mapStateToProps)(ChartJSDoughnut);
