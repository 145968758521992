import React from "react";

import EventCell from "components/Common/DataTable/EventCell/";

import {
  getRepresentativeOccurrence,
  labelContainsFilter
} from "services/redux/selectors/reports/scheduled";

export function ResultColumn({
  columns,
  eventTextFilter,
  getEventMeta,
  config,
  sites,
  changeFilter
}) {
  const column = columns["RESULT"];

  return {
    ...column,
    Cell: row => {
      // const occurrence = get(row.original, "occurrences.0");

      const occurrence = getRepresentativeOccurrence({
        occurrences: row.original.occurrences,
        eventTextFilter,
        getEventMeta
      });

      const event = getEventMeta(occurrence);

      return (
        <EventCell
          event={event}
          focus={row.original.expanded}
          onClick={() => {
            config.dataTable.clickableEventFilters && changeFilter(event.label);
          }}
        />
      );
    },
    // getTheadFilterProps: props => ({ ...props, sites, getEventMeta }),
    // Add required props for filter component
    sites,
    getEventMeta,
    filterMethod: (filter, row) => {
      const occurrences = row[filter.id];

      for (let occurrence of occurrences) {
        const { label } = getEventMeta(occurrence);
        // Keep this entry if any occurrence matches
        //  search string
        if (labelContainsFilter(label, filter, columns["RESULT"].filterType)) {
          return true;
        }
      }

      return false;
    }
  };
}
