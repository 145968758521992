// Camera sudo sites
export const loadSiteCamerasStart = params => {
  return { type: "LOAD_SITE_CAMERAS_START", ...params };
};
export const loadSiteCamerasFailed = error => {
  return { type: "LOAD_SITE_CAMERAS_FAILED", ...error };
};
export const loadSiteCamerasSuccess = response => {
  return {
    type: "LOAD_SITE_CAMERAS_SUCCESS",
    ...response
  };
};
export const loadSiteCamerasCancel = response => {
  return {
    type: "LOAD_SITE_CAMERAS_CANCEL",
    ...response
  };
};

// Add camera to site
export const requestAddSiteCameraStart = params => {
  return { type: "REQUEST_ADD_SITE_CAMERA_START", ...params };
};
export const requestAddSiteCameraFailed = error => {
  return { type: "REQUEST_ADD_SITE_CAMERA_FAILED", ...error };
};
export const requestAddSiteCameraSuccess = response => {
  return {
    type: "REQUEST_ADD_SITE_CAMERA_SUCCESS",
    ...response
  };
};
export const requestAddSiteCameraCancel = response => {
  return {
    type: "REQUEST_ADD_SITE_CAMERA_CANCEL",
    ...response
  };
};

// Edit camera on site
export const requestEditSiteCameraStart = params => {
  return {
    type: "REQUEST_EDIT_SITE_CAMERA_START",
    ...params
  };
}
export const requestEditSiteCameraFailed = error => {
  return {
    type: "REQUEST_EDIT_SITE_CAMERA_FAILED",
    ...error
  };
}
export const requestEditSiteCameraSuccess = response => {
  return {
    type: "REQUEST_EDIT_SITE_CAMERA_SUCCESS",
    ...response
  };
}
export const requestEditSiteCameraCancel = response => {
  return {
    type: "REQUEST_EDIT_SITE_CAMERA_CANCEL",
    ...response
  };
}

// Remove camera to site
export const requestRemoveSiteCameraStart = params => {
  return { 
    type: "REQUEST_REMOVE_SITE_CAMERA_START",
    ...params
  };
};
export const requestRemoveSiteCameraFailed = error => {
  return {
    type: "REQUEST_REMOVE_SITE_CAMERA_FAILED",
    ...error
  };
};
export const requestRemoveSiteCameraSuccess = response => {
  return {
    type: "REQUEST_REMOVE_SITE_CAMERA_SUCCESS",
    ...response
  };
};
export const requestRemoveSiteCameraCancel = response => {
  return {
    type: "REQUEST_REMOVE_SITE_CAMERA_CANCEL",
    ...response
  };
};
