import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  loadDeveloperAppsStart,
  loadDeveloperAppsSuccess,
  loadDeveloperAppsFailed,
  loadDeveloperAppsCancel,
} from "services/redux/actions/developers";
  
import conxtdOut from "apis/conxtdOut";

// one-off saga for loading list of developer apps

export function* watchLoadDeveloperApps() {

  yield actionWatcher({
    actions: {
      start: loadDeveloperAppsStart,
      failed: loadDeveloperAppsFailed,
      success: loadDeveloperAppsSuccess,
      cancel: loadDeveloperAppsCancel,
    },
    saga: loadDeveloperAppsRe,
  });
}

// https://mars.conxtd.com/out_api/Oauth/listApps 
export function* loadDeveloperAppsRe(action) {
  try {
    const url = "/Oauth/listApps";

    const result = yield call(conxtdOut.get, url);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}