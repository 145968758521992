import React, { useState } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import Icon from "components/Icons/Icon";
import { SiteStatusOpenClose } from "components/Common/SiteStatus/SiteStatusOpenClose";
import { LatestAlarmStatus } from "components/Common/LatestAlarmStatus/LatestAlarmStatus";
import moment from "services/locale/momentInit.js";

const MarkerCarrierIcon = ({
  site,
  color,
  icon,
  onClick,
  siteName,
  zoneState,
  alarmCount,
  alarmLastIcon,
  alarmLastColour,
  alarmLastTime,
  alarmLastCaption,
}) => {
  const [infoWindow, setInfoWindow] = useState(false);
  const handleMouseOver = () => {
    setInfoWindow(true);
  };

  const handleMouseExit = () => {
    setInfoWindow(false);
  };
  moment.locale("en-GB");
  const ackedMoment = alarmLastTime && new moment(alarmLastTime);
  const monthYear = alarmLastTime && ackedMoment.format("DD/MM/YYYY");
  const time = alarmLastTime && ackedMoment.format("HH:mm");
  return (
    <Marker
      icon={icon}
      position={site}
      onClick={onClick}
      style={{
        color: color,
        fontSize: "32px",
        padding: "16px",
      }}
      siteName={siteName}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseExit}
    >
      {infoWindow && (
        <InfoWindow position={site}>
          <div style={{ width: "250px" }}>
            <div className="d-flex justify-content-center">
              <h5 className="d-inline-block">{siteName} </h5>
            </div>

            <div
              className="d-flex justify-content-center text-center"
              style={{
                alignItems: "center",
              }}
            >
              <SiteStatusOpenClose zoneState={zoneState} />
              <hr
                style={{
                  borderLeft: "1px solid black",
                  height: "40px",
                  color: "#29498b",
                }}
              ></hr>
              <div className="px-2">
                <LatestAlarmStatus
                  alarmLastIcon={alarmLastIcon}
                  alarmLastColour={alarmLastColour}
                />
              </div>
              <hr
                style={{
                  borderLeft: "1px solid black",
                  height: "40px",
                  color: "#29498b",
                }}
              ></hr>
              <div
                style={{
                  fontSize: "21px",
                }}
              >
                <Icon className="far fa-bell px-2" />
                {alarmCount}
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center text-center">
              <div
                className="text-uppercase"
                style={{ fontSize: "18px", fontWeight: "20px" }}
              >
                {alarmLastCaption}
              </div>
              <div
                className="font-weight-normal"
                style={{
                  fontSize: "13px",
                  fontWeight: "20px",
                }}
              >
                {" "}
                {time}{" "}
              </div>
              <div
                className="font-weight-light"
                style={{
                  fontSize: "13px",
                  color: "#8a909d",
                  fontWeight: "0.98rem",
                }}
              >
                {monthYear}{" "}
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MarkerCarrierIcon;
