const initialState = {
  callOptions: {
    phoneNumber: "",
    recordChannel: "",
    transcribe: false,
    mctAlarmLogId: "",
    keyholderUuid: "",
    keyholderName: "",
    siteName: "",
    siteId: "",
    customRef: "",
    origin: "site",
    sop: { // only used if origin = "sop"
      enterpriseId: null,
      groupId: null,
      sopActionedEventId: null,
      sudoStateId: null,
      keyholderId: null,
    }
  },
  callStatus: "idle", // idle, initiate, initiated, accepted, ended
  callMuted: false,
  loadingCallLogs: null,
  callLogs: [],
  // gettingTranscription: null,
};

export default function voiceCalls(state = initialState, action) {
  switch (action.type) {

    case "INITIATE_VOICE_CALL":
      return {
        ...state, 
        callOptions: {
          phoneNumber: action.phoneNumber,
          recordChannel: action.recordChannel ? action.recordChannel : "",
          transcribe: action.transcribe ? action.transcribe : false,
          mctAlarmLogId: action.mctAlarmLogId ? action.mctAlarmLogId : "",
          keyholderUuid: action.keyholderUuid ? action.keyholderUuid : "",
          keyholderName: action.keyholderName ? action.keyholderName : "",
          siteName: action.siteName ? action.siteName : "",
          siteId: action.siteId ? action.siteId : "",
          customRef: action.customRef ? action.customRef: "",
          origin: action.origin ? action.origin : "site",
          sop: {
            enterpriseId: action.enterpriseId ? action.enterpriseId : null,
            groupId: action.groupId ? action.groupId : null,
            sopActionedEventId: action.sopActionedEventId ? action.sopActionedEventId : null,
            sudoStateId: action.sudoStateId ? action.sudoStateId : null,
            keyholderId: action.keyholderId ? action.keyholderId : null,
            makingContactOption: action.makingContactOption ? action.makingContactOption : null,
          }
        },
        callStatus: "initiate",
        callMuted: false,
      };

    case "CHANGE_VOICE_CALL_CONTACT_DETAILS":
      return {
        ...state, 
        callOptions: {
          phoneNumber: action.phoneNumber,
          recordChannel: action.recordChannel ? action.recordChannel : "",
          transcribe: action.transcribe ? action.transcribe : false,
          mctAlarmLogId: action.mctAlarmLogId ? action.mctAlarmLogId : "",
          keyholderUuid: action.keyholderUuid ? action.keyholderUuid : "",
          keyholderName: action.keyholderName ? action.keyholderName : "",
          siteName: action.siteName ? action.siteName : "",
          siteId: action.siteId ? action.siteId : "",
          customRef: action.customRef ? action.customRef: "",
          origin: action.origin ? action.origin : "site",
          sop: {
            enterpriseId: action.enterpriseId ? action.enterpriseId : null,
            groupId: action.groupId ? action.groupId : null,
            sopActionedEventId: action.sopActionedEventId ? action.sopActionedEventId : null,
            sudoStateId: action.sudoStateId ? action.sudoStateId : null,
            keyholderId: action.keyholderId ? action.keyholderId : null,
          }
        },
        callStatus: "ended",
        callMuted: false,
      };

    case "SET_VOICE_CALL_STATUS":
      return {
        ...state, 
        callStatus: action.callStatus,
      };

    case "SET_VOICE_CALL_MUTED":
      return {
        ...state, 
        callMuted: action.callMuted,
      };

    case "LOAD_CALL_LOGS_START":
      return {
        ...state,
        loadingCallLogs: true,
      };

    case "LOAD_CALL_LOGS_SUCCESS":
      return {
        ...state,
        callLogs: action.data,
        loadingCallLogs: false,
      }

    case "LOAD_CALL_LOGS_FAILED":
      return {
        ...state,
        loadingCallLogs: false,
      };

    case "GET_TRANSCRIPTION_START":
      return {
        ...state,
        // gettingTranscription: true,
      };

    case "GET_TRANSCRIPTION_SUCCESS":
      return {
        ...state,
        callLogs: [
          ...state.callLogs.map((callLog) => {
            if (callLog.recording_sid === action.recording_sid) {
              return {
                ...callLog,
                transcription: action.data.transcription ? action.data.transcription : "",
              }
            } else {
              return callLog
            }
          })
        ],
        // gettingTranscription: false,
      }

    case "GET_TRANSCRIPTION_FAILED":
      return {
        ...state,
        callLogs: [
          ...state.callLogs.map((callLog) => {
            if (callLog.recording_sid === action.startAction.recording_sid) {
              return {
                ...callLog,
                transcription: "Transcription failed, please try again later.",
              }
            } else {
              return callLog
            }
          })
        ],
        // gettingTranscription: false,
      };

    case "GET_TRANSCRIPTION_CANCEL":
      return {
        ...state,
        // gettingTranscription: false,
      }

    default:
      return state;
  }
}