import React from "react";
import { connect } from "react-redux";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

import moment from "services/locale/momentInit.js";

import SiteView from "components/Site/SiteView/";

import { loadSiteViewLiveRefreshCancel } from "services/redux/actions";

import {
  clearUserGeneratedReport,
} from "services/redux/actions/customReports";

import {
  getUserGeneratedReportType,
  getUserGeneratedReportEvents,
  getUserGeneratedReportColumns,
  getUserGeneratedReportFilter,
  getUserGeneratedReportFiltered,
  getUserGeneratedReportExpanded,
  getExpanded,
  getExpandedRowsCount,
  createGetEventMeta,
} from "services/redux/selectors/reports/scheduled/userGenerated";

import { getConfig } from "services/redux/selectors/reports/common/";

import {
  // resetExpanded,
  // changeDataTableFiltered,
  changeGroupTimeframe,
  // setScheduledReportUserTimezoneUTCOffset,
} from "services/redux/actions/";

import { getGroups } from "services/redux/selectors";

// import Toolbar from "../../Reports/Types/Scheduled/DataTable/Toolbar";

import TrGroupComponent from "../../Reports/Types/Scheduled/DataTable/TrGroupComponent";

import {
  CustomIdColumn,
  GroupColumn,
  SiteRefColumn,
  TypeColumn,
  ResultColumn,
  ExpectedColumn,
  ActualColumn,
  DifferenceColumn,
  EventTextColumn,
  OccurrencesColumn,
} from "../../Reports/Types/Scheduled/DataTable/Columns";

import { NameColumn, ExpandColumn } from "./Columns";

class DataTable extends React.Component {
  state = {
    selectedSite: null,
    bundle_id: 2
  };

  // componentDidMount = () => {
  //   const timezoneUTCOffset = (new Date()).getTimezoneOffset();
  //   const timezoneUTCOffsetHours = Math.floor((Math.abs(timezoneUTCOffset)/60));
  //   const timezoneUTCOffsetMinutes = Math.abs(timezoneUTCOffset) % 60;
  //   const timezoneUTCOffsetSign = (timezoneUTCOffset >= 0) ? 1 : -1;

  //   this.props.dispatch(
  //     setScheduledReportUserTimezoneUTCOffset({
  //       reportType: this.props.reportType,
  //       userTimezoneUTCOffset: {
  //         hours: timezoneUTCOffsetHours,
  //         minutes: timezoneUTCOffsetMinutes,
  //         sign: timezoneUTCOffsetSign,
  //       }
  //     })
  //   );
  // }

  componentWillUnmount() {
    this.props.dispatch(
      clearUserGeneratedReport({})
    );
  }

  onSelectSite = site => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");

      const acked = new moment(site.occurrences[0].acked);

      // Timeframe is from 6 hours before the time of the event until now
      let timeframe = new moment().diff(acked, "hours") + 6;

      // Limit timeframe to maximum of 4 weeks
      if (timeframe > 672) timeframe = 672;

      this.props.dispatch(
        changeGroupTimeframe({
          timeframe
        })
      );

      this.setState({
        selectedSite: site,
        bundle_id: 2
      });

    } else {
      document.body.classList.remove("sidepane-active");

      this.props.dispatch(
        loadSiteViewLiveRefreshCancel()
      );

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.setState({
          selectedSite: null
        });
      }, 600);
    }
  };

  changeFilter = () => {}; //ResultColumn/EventFilter expects a function to be passed

  // onFilteredChangeCustom = (value, accessor) => {
  //   let filtered = [...this.props.filtered];
  //   let insertNewFilter = 1;
  //   let shouldResetExpanded = false;

  //   // Filter already exists
  //   if (filtered.length) {
  //     filtered = filtered
  //       .map((filter, i) => {
  //         if (filter["id"] === accessor) {
  //           insertNewFilter = 0;

  //           // Filter text value is empty
  //           if (value === "" || !value.length) {
  //             // filtered = filtered.slice(i, 1);
  //             // To be removed (see remove falsey below)
  //             return false;
  //           } else {
  //             return {
  //               ...filter,
  //               value
  //             };
  //             // filter["value"] = value;
  //           }
  //         }
  //         return filter;
  //       })
  //       // Remove falsey values
  //       .filter(falsey => falsey);
  //     // Filter existed but will be removed - reset
  //     //  expanded
  //     if (filtered.length === 0) {
  //       shouldResetExpanded = true;
  //     }
  //   }

  //   // Filter doesn't exist - add
  //   if (insertNewFilter) {
  //     // filtered.push({ id: accessor, value: value });
  //     filtered = [...filtered, { id: accessor, value: value }];
  //     // Introducing a new filter - reset expanded
  //     shouldResetExpanded = true;
  //   }

  //   if (shouldResetExpanded) {
  //     this.props.dispatch(
  //       resetExpanded({
  //         reportType: this.props.reportType
  //       })
  //     );
  //   }

  //   this.props.dispatch(
  //     changeDataTableFiltered({
  //       filtered,
  //       reportType: this.props.reportType
  //     })
  //   );
  // };

  render() {
    let {
      sites,
      // filter,
      reportType,
      columns,
      config,
      groups,
      getEventMeta,
      expanded,
      filtered,
      // getReportTitle,
      dispatch,
      // changeFilter
    } = this.props;

    const { selectedSite } = this.state;

    // Apply more filtering just for the DataTable
    // sites = [
    //   ...sites.filter(
    //     site =>
    //       intervalFilter(site, filter.interval) &&
    //       eventCountFilter(site, filter.eventCount, config.charts.pie.filter, reportType)
    //   )
    // ];

    const expandedRowsCount = getExpandedRowsCount(expanded);

    const defaultPageSize = 20;

    const eventTextFilter = filtered.find(({ id }) => id === "result");

    const noDataMsg = "No data available...";

    return (
      <div className="card visual-data">
        {/* <Toolbar
          reportTitle={getReportTitle()}
          reportType={reportType} 
          sites={sites}
          dataResolver={() => {
            const data = this.reactTable.getResolvedState().sortedData;

            return columns.buildExportArray({
              data,
              getEventMeta: getEventMeta,
              // getActualFromOccurrence,
              getActualWithTimezone,
              getDiffFromOccurrence,
            });
          }}
          dataResolverFull={() => {
            const data = this.reactTable.getResolvedState().sortedData;

            return columns.buildExportArrayFull({
              data,
              getEventMeta: getEventMeta,
              // getActualFromOccurrence,
              getActualWithTimezone,
              getDiffFromOccurrence,
              sites,
            });
          }}
        /> */}
        <ReactTable
          ref={r => (this.reactTable = r)}
          data={sites}
          className={`${expandedRowsCount > 0 ? "focus-expanded" : ""} ${
            eventTextFilter ? " event-text-filter-active" : ""
          }`}
          filterable
          // filtered={this.props.filtered}
          // onFilteredChange={(filtered, column, value) => {
          //   this.onFilteredChangeCustom(value, column.id || column.accessor);
          // }}
          defaultPageSize={20}
          noDataText={noDataMsg}
          // Sort by "Actual" time header by default
          defaultSorted={
            reportType === "overnight" 
              ? [{
                  id: "occurrences",
                  desc: true
                }]
              : [{
                  id: "actual-time",
                  desc: true
                }]
          }
          defaultFilterMethod={(filter, row) => {
            return (
              String(row[filter.id])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            );
          }}
          TrGroupComponent={TrGroupComponent}
          // Override row group props for sub rows
          getTrGroupProps={(state, rowInfo, column) => {
            const props = {
              filtered,
              getEventMeta,
              eventTextFilter,
              columns,
              groups
            };
            if (rowInfo !== undefined) {
              return {
                ...props,
                expanded: getExpanded(rowInfo.original, eventTextFilter) ? 1 : 0,
                site: rowInfo.original
              };
            }
            return {
              ...props,
              expanded: 0,
              className: ""
            };
          }}
          columns={[
            CustomIdColumn({ columns }),
            NameColumn({ columns, onSelectSite: this.onSelectSite }),
            GroupColumn({ columns, groups }),
            SiteRefColumn({ columns }),
            TypeColumn({ columns }),
            ResultColumn({
              columns,
              eventTextFilter,
              getEventMeta,
              config,
              sites,
              changeFilter: this.changeFilter,
            }),
            ExpectedColumn({ columns, eventTextFilter, getEventMeta }),
            ActualColumn({ columns, eventTextFilter, getEventMeta }),
            DifferenceColumn({ columns }),
            EventTextColumn({ columns, eventTextFilter, getEventMeta }),
            OccurrencesColumn({ columns }),
            ExpandColumn({ columns, eventTextFilter, reportType, dispatch })
          ]}
        />
        <SiteView
          site={selectedSite}
          selectSite={this.onSelectSite}
          // Using controlled state for bundle_id
          bundle_id={this.state.bundle_id}
          onSelectBundle={bundle_id => {
            // Bundle selected
            if (bundle_id) {
              this.setState({ bundle_id });
            }
            // Invalid bundle id, deselect
            else {
              this.setState({ bundle_id: null });
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    sites: getUserGeneratedReportEvents(state, props),
    filter: getUserGeneratedReportFilter(state, props),
    getEventMeta: occurrence => createGetEventMeta(state, props)(occurrence),
    columns: getUserGeneratedReportColumns(state, props),
    reportType: getUserGeneratedReportType(state, props),
    expanded: getUserGeneratedReportExpanded(state, props),
    groups: getGroups(state),
    config: getConfig(state, props),
    filtered: getUserGeneratedReportFiltered(state, props),
  };
};
export default connect(mapStateToProps)(DataTable);
