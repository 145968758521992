import {
  call,
  take,
  race,
  put,
  takeLatest,
  delay,
  select,
} from "redux-saga/effects";

import {
  requestChangeSiteSetSuccess,
  requestChangeSiteSetFailed,
  loadGroupOverviewStart,
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";
import { getEnterpriseId } from "services/redux/selectors/reports/router";
import { getGroupId } from "services/redux/selectors/reports/router";

export function* watchSiteSet() {
  // Automatically cancels any previous sagas started
  // from here
  yield takeLatest("REQUEST_CHANGE_SITE_SET_START", function* (action) {
    yield race({
      task: call(requestChangeSiteSet, action),
      cancel: take([
        "REQUEST_CHANGE_SITE_SET_CANCEL",
        "REQUEST_CHANGE_SITE_SET_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

function* requestChangeSiteSet(action) {
  const { site_ref, set, id } = action;
  const enterpriseId = yield select(getEnterpriseId);
  const groupId = yield select(getGroupId);

  const params = {
    enterprise_id: enterpriseId,
    group_id: groupId,
    refresh: true,
    timeframe: 24,
    interval: 30000,
  };

  try {
    const response = yield call(conxtdOut.post, "/actions/openClose", {
      site_ref,
      // Note: we send current state rather
      // than the state we would like
      site_state: set ? "Close" : "Open",
    });

    if (response.data !== null) {
      throw new Error("Couldn't change site set state.");
    }

    yield delay(35000);
    //   console.log("delay is up");
    yield put(loadGroupOverviewStart(params));
    yield put(requestChangeSiteSetSuccess({ site_ref, set, id }));
  } catch (error) {
    yield put(
      requestChangeSiteSetFailed({ error: error.message, site_ref, set, id })
    );
  }
}
