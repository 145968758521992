import React from "react";

import Loading from "components/Loading";

import { connect } from "react-redux";

import {
  loadSiteKeyholdersStart,
  loadSiteKeyholdersCancel,
} from "services/redux/actions";

import { getCustomRef } from "services/redux/selectors/groups";

import KeyholderCard from "./KeyholderCard.js";

import { withT } from "services/translation/";

class Keyholders extends React.Component {
  state = {
    // This prevents multiple dispatches
    dispatched: false,
  };
  loadKeyholders = (props) => {
    const { site } = props;

    this.setState({ dispatched: true }, () => {
      if (!site.id) {
        return;
      }

      this.props.dispatch(
        loadSiteKeyholdersStart({
          id: site.id,
        })
      );
    });
  };
  componentWillUnmount() {
    this.props.dispatch(loadSiteKeyholdersCancel());
  }
  componentDidMount() {
    this.loadKeyholders(this.props);
  }
  componentDidUpdate(prevProps) {
    if (this.props.site.id !== prevProps.site.id) {
      this.loadKeyholders(this.props);
    }
  }
  render() {
    const { site, initiateCall, t } = this.props;

    const loading = site.keyholders.loading !== false;

    return (
      <>
        <div className="row no-gutters">
          <div className="col-12 d-flex">
            {loading && <Loading />}
          </div>
        </div>
        <div className="row mt-2 no-gutters" style={{ marginBottom: "90px" }}>
          <div className="col-12">
            {
              (site.keyholders.data && site.keyholders.data.length > 0) 
              ? site.keyholders.data
                  .map((keyholder) => (
                    <KeyholderCard
                      key={keyholder.uuid}
                      {...keyholder}
                      initiateCall={initiateCall}
                      siteName={site.name}
                      siteId={site.id}
                      customRef={site.id ? getCustomRef(site) : ""}
                    />
                  ))
              : t("no_keyholders")
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default withT(
  connect(mapStateToProps)(Keyholders),
  "site_overview.side_pane.voice_calls"
);