import React from "react";

import EventOverviewWidget from "./EventOverviewWidget";

// import "./EventOverviewWidget.scss";

import { default as T } from "services/i18n/Translate";

import { CardDeck } from "react-bootstrap";

const COLUMNS = [
  "",
  "col-md-4",
  "col-lg-8 col-md-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12",
  "col-lg-12"
];

class EventOverviewWidgetGroup extends React.Component {
  render() {
    const { bundle } = this.props;

    let totalEventsForGroup = 0;
    bundle.event_pairs && bundle.event_pairs.forEach(eventPair => {
      totalEventsForGroup = totalEventsForGroup + eventPair.total_count;
    });

    if (totalEventsForGroup === 0) return null;

    const event_pairs = bundle.event_pairs.filter(eventPair => {
      // Filter out pairs with 0 events
      return eventPair.total_count !== 0;
    });

    // Column class based on number of pairs in bundle
    const column = COLUMNS[event_pairs.length];

    const widgets = event_pairs.map(eventPair => {
      return (
        <EventOverviewWidget
          key={eventPair.id}
          eventPair={eventPair}
          //total={bundle.event_pairs.length}
        />
      );
    });
    return (
      <div className={`${column} pl-4 my-3`}>
        <h4 className="page-title mb-4 mt-0 px-2">
          <T>{bundle.title}</T>
        </h4>
        <CardDeck className="col-md-12 px-2">{widgets}</CardDeck>
      </div>
    );
  }
}

export default EventOverviewWidgetGroup;
