import React from "react";
import CircleIcon from "components/Icons/CircleIcon";

const COLORS = {
  setBlue: "#28498A",
  unsetLightblue: "#3397DB",
  noStateMidBlue: "#2B3E4E",
};

const SiteStatusOpenClose = ({ zoneState }) => {
  const value = zoneState.state;
  const color = COLORS[zoneState.colour] || "#666666";

  const iconType =
    value === "Locked" ? "lock" : value === "Unlocked" ? "unlock" : "dash";
  const Icon = (
    <span className="status-value">
      <CircleIcon
        type={iconType}
        color="transparent"
        size={48}
        foregroundColor={color}
      />
    </span>
  );

  return <div className="site-status-container">{Icon}</div>;
};

export { SiteStatusOpenClose };
