import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import "./AccountPage.scss";

import SupportPlugin from "../Layout/SupportPlugin";

import { getPathname } from "services/redux/selectors/reports/router";

import { routerNavigate } from "services/redux/actions";

class AccountPage extends React.Component {
  UNSAFE_componentWillReceiveProps = props => {
    if (props.pathname === this.props.pathname) {
      return;
    }
    this.props.dispatch(
      routerNavigate({
        params: props.match.params,
        location: props.location
      })
    );
  };
  componentDidMount = () => {
    this.props.dispatch(
      routerNavigate({
        params: this.props.match.params,
        location: this.props.location
      })
    );
  };
  render() {
    return (
      <div
        id={this.props.id || ""}
        className={(this.props.className || "") + " account-page-wrapper"}>
        <div className="account-pages mx-4">
          <div className="container">{this.props.children}</div>
        </div>

        <SupportPlugin loginPage={1} />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    pathname: getPathname(props)
  };
};
export default withRouter(connect(mapStateToProps)(AccountPage));
