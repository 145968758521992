import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip } from 'components/Common/Tooltip/Tooltip';
import DeleteSite from './DeleteSite';
import Loading from 'components/Loading';

function AssetStoreFooter({
  companyId,
  changeDetected,
  editMode,
  exitEditMode,
  disabledButton,
  invalidEnterprise,
  invalidSiteRef,
  invalidSite,
  invalid,
  handleSubmitEdit,
  selectedOption,
  setEditMode,
  siteRefTitle,
  siteName,
  sudoSiteId,
  noAlarmAssociation,
  loadingAssociationDetails,
  loadingSubmit,
}) {

  useEffect(() => {
    if (selectedOption !== 'Site Details') {
      setEditMode(false);
      if (selectedOption === 'Enterprise Associations') {
        setEditMode(true);
      }
    }
  }, [selectedOption, setEditMode])

  return (
    <>
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div className='d-flex flex-row align-items-center'>
          <DeleteSite companyId={companyId} sudoSiteId={sudoSiteId} siteName={siteName} />
        </div>
        {editMode &&
          <div className="d-flex align-items-center">
            {selectedOption === 'Site Details' &&
              <Button variant="danger" onClick={exitEditMode} data-intercom-target={"asset-editor-cancel-submit-button"}>
                Cancel
              </Button>
            }
            <div className='ml-2'>
              {invalid
                ?
                selectedOption === 'Site Details' ?
                  <Tooltip
                      placement="top"
                      description={
                        <ul className="m-0 pl-3 text-left">
                          {invalidSiteRef ? <li key={1}><span className="font-italic">{siteRefTitle}</span> is invalid</li> : null}
                          {invalidSite ? <li key={2}>required field(s) are empty</li> : null}
                          {!changeDetected ? <li key={3}>no changes have been made</li> : null}
                          {noAlarmAssociation ? <li key={4}><span className="font-italic">Signalling Type</span> not associated with site</li> : null}
                        </ul>
                      }
                    >
                      <Button 
                        variant="success" 
                        className={invalid ? `${disabledButton}` : ""} 
                        data-intercom-target={"asset-editor-submit-button"}
                      >
                        Submit
                      </Button>
                    </Tooltip>
                  :
                    selectedOption === 'Enterprise Associations' &&
                    <Tooltip
                      placement="top"
                      description={
                        <ul className="m-0 pl-3 text-left">
                          {invalidEnterprise ? <li key={4}>required field(s) are empty</li> : null}
                        </ul>
                      }
                    >
                      <Button 
                        variant="success" 
                        className={invalid ? `${disabledButton}` : ""}
                        data-intercom-target={"asset-editor-submit-button"}
                      >
                        Submit
                      </Button>
                    </Tooltip>
                :
                  <Button 
                    variant="success"
                    onClick={handleSubmitEdit}
                    data-intercom-target={"asset-editor-submit-button"}
                  >
                    {loadingAssociationDetails || loadingSubmit ? <Loading /> : "Submit"}
                  </Button>
              }
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default AssetStoreFooter
