import { call, takeLatest, put } from "redux-saga/effects";

import {
  loadEnterprisesSuccess,
  loadEnterprisesStart,
  loadEnterprisesFailed,
  loadEnterprisesRestore,
  loadEnterprisesCancel,
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

import cacheIndexes from "../cacheIndexes";

export function* rootSaga() {
  yield takeLatest("LOAD_ENTERPRISES_START", loadStart);

  yield takeLatest("REFRESH_ENTERPRISES_START", refreshStart);

  // yield takeLeading("LOGIN_SUCCESS", function* (action) {
  yield takeLatest("LOGIN_SUCCESS", function* (action) {
    // Enterprises came with the login
    if (typeof action.enterprises !== "undefined") {
      yield takeLatest("LOAD_ENTERPRISES_START", loadStart);
      yield put(
        loadEnterprisesSuccess({
          data: {
            enterprises: action.enterprises,
          },
        })
      );
    }
    // We are restoring a login, and there are no
    //   enterprises attached
    else {
      //   console.log("no enterprises", action);
      yield put(loadEnterprisesStart());
    }
  });
}

/**
 * ENTERPRISES - loadStart
 *
 * @param {*} action
 */
export function* loadStart(action) {
  const params = {
    action,
    reducerKey: "enterprises",
    apiCall: call(conxtdOut.get, "/EnterpriseStructure/enterprises"),
    successAction: loadEnterprisesSuccess,
    failedAction: loadEnterprisesFailed,
    restoreAction: loadEnterprisesRestore,
    // extraParams: { cacheSubKey: action.enterprise_id }
  };

  // Prevent trying to call when there is no session id
  const session = conxtdOut.getSession();
  if (!session.sessionId) {
    put(loadEnterprisesCancel());
    return;
  }

  yield cacheIndexes.restoreOrLoad(params);
}

/**
 * ENTERPRISES - refreshStart
 *
 * @param {*} action
 */
 export function* refreshStart() {

  const apiCall = call(conxtdOut.get, "/EnterpriseStructure/enterprises");
  const successAction = loadEnterprisesSuccess;
  const failedAction = loadEnterprisesFailed;

  try {
    const response = yield apiCall;

    yield put(successAction({ data: response.data }));
  } catch (error) {
    console.log(error);
    yield put(failedAction({ error: error.message }));
  }
}

// To be removed
/**
 * Initial restore - restores enterprises from localStorage
 */
// export function* restore() {
//   // Restore enterprises (on direct url or refresh, we still get enterprises)
//   const enterprises = localStorage.getItem("LOAD_ENTERPRISES_SUCCESS/");
//   if (enterprises !== null) {
//     // Got enterprises in local storage - are they stale?
//     const cacheIndexes = yield select(getCacheIndexes);
//     const stale = caching.stale(cacheIndexes, "LOAD_ENTERPRISES_SUCCESS/");
//     if (!stale) {
//       // console.log("not stale - restoring from local");
//       yield put(actions.restoreEnterprises(JSON.parse(enterprises)));
//       return;
//     }
//     // console.log("stale");
//   }

//   // console.log("enterprises is null");
//   // localStorage is null or data is stale
//   yield put(actions.loadEnterprisesStart());
// }
