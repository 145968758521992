// Tether
export const requestTetherConnectStart = params => {
  return {
    type: "REQUEST_TETHER_CONNECT_START",
    ...params
  };
};
export const requestTetherConnectFailed = params => {
  return {
    type: "REQUEST_TETHER_CONNECT_FAILED",
    ...params
  };
};
export const requestTetherConnectSuccess = params => {
  return {
    type: "REQUEST_TETHER_CONNECT_SUCCESS",
    ...params
  };
};
