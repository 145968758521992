import React from "react";
import { connect } from "react-redux";

import { globalSearchToggle } from "services/redux/actions";

class SearchToggle extends React.Component {
  render() {
    const { globalSearchToggle } = this.props;
    return (
      <li className="list-inline-item dropdown notification-list">
        <div
          className="btn nav-link toggle-search"
          onClick={() => globalSearchToggle()}
          onBlur={() => globalSearchToggle()}>
          <i className="fas fa-search" />
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = {
  globalSearchToggle
};
export default connect(null, mapDispatchToProps)(SearchToggle);
