import React from "react";
import { connect } from "react-redux";

/**
 * Enterprise Management CustomFields options
 *
 **/
class CustomFields extends React.Component {

  render() {

    return (
      <>
        Custom Fields
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
};
export default connect(mapStateToProps)(CustomFields);