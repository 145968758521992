import React from "react";

import Icon from "components/Icons/Icon";
import { Button } from "react-bootstrap";

import "./ExpandColumn.scss";

import { getExpanded } from "services/redux/selectors/reports/scheduled/userGenerated";

import { setUserGeneratedReportExpandSite } from "services/redux/actions/customReports";

export function ExpandColumn({
  columns,
  eventTextFilter,
  dispatch
}) {
  const column = columns["EXPAND"];
  return {
    ...column,
    Cell: ({ original }) => {
      const { id } = original;
      let expanded;
      const { occurrences } = original;

      expanded = getExpanded(original, eventTextFilter);

      const expandable = occurrences.length > 1;

      if (!expandable) {
        return null;
      }

      return (
        <Button
          className="expandIconButton"
          variant={`${expanded ? "" : "outline-"}primary`}
          size="sm"
          onClick={() => {
            dispatch(
              setUserGeneratedReportExpandSite({
                id,
                expanded: !expanded
              })
            );
          }}>
          {expanded ? (
            <Icon className="fas fa-chevron-up" />
          ) : (
            <Icon className="fas fa-chevron-down" />
          )}
        </Button>
      );
    }
  };
}
