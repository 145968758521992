import {
  watchLoadCustomReport,
  watchLoadCustomReportBundles,
  watchLoadUserGeneratedReport,
} from "./customReports";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadCustomReport(),
      watchLoadCustomReportBundles(),
      watchLoadUserGeneratedReport(),
    ],
  );
}