// REQUEST NOTIFICATIONS ADD RULE
export const requestNotificationsAddRuleStart = params => {
  return { type: "REQUEST_NOTIFICATIONS_ADD_RULE_START", ...params };
};
export const requestNotificationsAddRuleSuccess = params => {
  return { type: "REQUEST_NOTIFICATIONS_ADD_RULE_SUCCESS", ...params };
};
export const requestNotificationsAddRuleFailed = params => {
  return { type: "REQUEST_NOTIFICATIONS_ADD_RULE_FAILED", ...params };
};
export const requestNotificationsAddRuleCancel = params => {
  return { type: "REQUEST_NOTIFICATIONS_ADD_RULE_CANCEL", ...params };
};
export const requestNotificationsAddRuleNotified = params => {
  return { type: "REQUEST_NOTIFICATIONS_ADD_RULE_NOTIFIED", ...params };
};
