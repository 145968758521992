import React from "react";

import Icon from "components/Icons/Icon";

const RemoveFilter = ({ onClick, ...rest }) => (
  <span className="remove-filter" onClick={onClick} {...rest}>
    <Icon className="fas fa-times-circle" />
  </span>
);

export default RemoveFilter;