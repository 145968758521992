/**
 * A div that displays the date for the specified timezone
 * with a hover tooltip that displays the same date but in the current
 * user's timezone
 */
import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "components/Common/Tooltip/";

import moment from "services/locale/momentInit.js";

function TimezoneDate({
  date,
  timezone,
  format = "HH:mm DD/MM/YYYY",
  localFormat = null,
  placement = "top",
  hideTimezone = false
}) {
  const localDate = new moment(date);
  const timezoneDate = new moment.utc(localDate).tz(timezone);

  if (!date) return null;

  // Fall back to displaying local time if no timezone is available
  if (!timezone) return <div>{localDate.format(localFormat || format)}</div>;

  const localTimezoneText = moment.tz.guess();
  const timezoneText = hideTimezone ? "" : timezone;

  return (
    <Tooltip
      placement={placement}
      description={`${localDate.format(
        localFormat || format
      )} ${localTimezoneText}`}>
      <span>{`${timezoneDate.format(format)} ${timezoneText}`}</span>
    </Tooltip>
  );
}
TimezoneDate.propTypes = {
  /**
   * @param date the date to display (will be passed to momentjs)
   */
  date: PropTypes.any,

  /**
   * @param timezone the timezone to display (E.g. Europe/London)
   */
  timezone: PropTypes.string.isRequired,

  /**
   * @param format optional - the momentjs format to use. Defaults to
   * HH:mm DD/MM/YYYY
   */
  format: PropTypes.string,

  /**
   * @param localFormat optional - the format to use for the local (user's) date
   */
  localFormat: PropTypes.string,

  /**
   * @param placement optional - customise tooltip placement
   */
  placement: PropTypes.string,

  /**
   * @param hideTimezone optional - hide the timezone text: (Europe/London)
   */
  hideTimezone: PropTypes.bool
};
export default TimezoneDate;
