import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import Icon from "components/Icons/Icon";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import {
  setEmIntegrationTabContent,
  getBrivoSitesStart,
  getBrivoLinkedSitesStart,
} from "services/redux/actions/enterpriseManager";

import DataTable from "./DataTable";

class BrivoSites extends React.Component {

  componentDidMount() {
    const { enterprise, integrationId } = this.props;

    this.props.dispatch(
      getBrivoSitesStart({
        integration_id: integrationId,
        enterprise_id: enterprise.id,
      })
    );

    this.props.dispatch(
      getBrivoLinkedSitesStart({
        enterprise_id: enterprise.id,
      })
    );
  }

  render() {
    return (
      <div>
        <div className="row px-2 mt-2">
          <div className="btn btn-default border-none side-pane-back text-dark">
            <Link
              to="?tab=integrations"
              onClick={() => {
                this.props.dispatch(
                  setEmIntegrationTabContent({
                    integrationTabContent: "integrations"
                  })
                )
              }}
            >
              <span className="back-to-overview"><Icon className="fas fa-chevron-left mr-2" /> Back to integrations</span>
            </Link>
          </div>
        </div>
        <div className="mt-4">
          <DataTable />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    integrationId: state.enterpriseManager.brivo.integrationId,
  };
};
export default connect(mapStateToProps)(BrivoSites);