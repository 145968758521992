import React from "react";
import { connect } from "react-redux";

import { Card } from "react-bootstrap";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";

// import Navigation from "../Navigation/Navigation";

/**
 * Enterprise Management Create Sites
 *
 **/
class CreateSites extends React.Component {

  render() {
    const { t } = this.props;

    return (
      <div>
        <h4 className="mt-0 mb-2 header-title">{t("header_title")}</h4>{" "}
        {/* <Navigation /> */}
        <Card className="p-3">
          <button
            type="button"
            className="button-menu-mobile open-left waves-effect"
            style={{ backgroundColor: "transparent" }}
            onClick={this.props.history.goBack}
          >
            <Icon
              style={{ color: "darkslategray" }}
              className="fas fa-chevron-left font-20"
            />
          </button>
          Create Sites
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
};
export default withT(
  connect(mapStateToProps)(CreateSites),
  "enterprise_manager"
);