import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

import { withRouter } from "react-router-dom";
import { withT } from "services/translation/";

import { requestIntegrationConnectStart, setIntegrationConnectStatus } from "services/redux/actions/enterpriseManager";

class ConnectingBrivo extends React.Component {

  componentDidMount() {
    const { code, integration_id, enterprise_id } = this.props;

    this.props.dispatch(
      requestIntegrationConnectStart({
        params: {
          code: code,
          integration_id: integration_id,
          enterprise_id: enterprise_id,
        }
      })
    );
  }

  componentDidUpdate(prevProps) {
    const { connectStatus } = this.props;

    if (connectStatus !== prevProps.connectStatus) { 
      if (connectStatus === "connected") {
        this.props.dispatch(
          setIntegrationConnectStatus({
            status: null,
          })
        );

        const { originalPathname, history } = this.props;

        // Redirect to original page
        history.push(originalPathname);
      }
    }
  }

  render() {
    const { t, connectStatus, originalPathname } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <h4 className="mt-0 pb-2 header-title">{(connectStatus === "failed") ? t("connecting_brivo_failed") : t("connecting_brivo")}</h4>
            {(connectStatus === "failed") && <div><a href={originalPathname}>back</a></div>}
          </div>
        </div>
        {(connectStatus !== "failed") && <div className="row">
          <div className="col-12">
            <Loading />
          </div>
        </div>}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  let integration_id, enterprise_id, originalPathname, jsonError = false;
  try {
    ({ integration_id, enterprise_id, originalPathname } = JSON.parse(
      sessionStorage.getItem("integrationConnect")
    ));
  } catch (e) {
    jsonError = true;
  }

  return {
    code: state.app.router.params.code,
    connectStatus: state.enterpriseManager.connectIntegration ? state.enterpriseManager.connectIntegration.status : null,
    integration_id,
    enterprise_id,
    originalPathname,
    jsonError
  };
};

export default withRouter(
  withT(
    connect(mapStateToProps)(ConnectingBrivo),
    "site_overview.side_pane.manage_integrations.providers.brivo"
  )
);