import React from "react";

import { Card, Row, Col } from "react-bootstrap";

import EagleEyeConnect from "./EagleEyeConnect";
import CameraCardList from "./CameraCardList";
import AddCamera from "./AddCamera/";
import AddLocation from "./AddLocation/";

export default function EagleEye({ integration }) {
    // camera_integration_site is null if not yet connected
    const eagleEyeConnected = !!integration.camera_integration_site;
    const location = eagleEyeConnected === true ? integration.camera_integration_site.camera_integration_location_name : null;

    return (
        <Card className="channel-card bg-light">
            <Card.Body>
                <Row>
                    <Col className="text-center mb-4">
                        <img alt={integration.integration_name}
                            src={integration.logo}
                            style={{
                                maxWidth: "80%"
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <Card.Subtitle
                            style={{
                                lineHeight: "35px",
                                position: "absolute",
                                bottom: "0"
                            }}>
                            Eagle Eye {location === null ? null : '- ' + location}
                        </Card.Subtitle>
                    </Col>
                    <Col md="6" className="text-right">
                        <EagleEyeConnect {...integration} />
                    </Col>
                </Row>
                {eagleEyeConnected && (
                    <>
                        <Row className="mt-2">
                            {location && (
                                <Col>
                                    <AddCamera
                                    integration={integration}
                                        auth_token={integration.auth_token}
                                        camera_integration_site_id={
                                            integration.camera_integration_site
                                                .camera_integration_site_id
                                        }
                                        subdomain={integration.subdomain}
                                        account_id={integration.account_id}
                                        auth_expired={integration.camera_integration_site.auth_expired}
                                    />
                                </Col>
                            )}
                            {!location && (
                                <Col>
                                    <AddLocation
                                        integration={integration}
                                        auth_token={integration.auth_token}
                                        camera_integration_site_id={
                                            integration.camera_integration_site.camera_integration_site_id
                                        }
                                        auth_expired={integration.camera_integration_site.auth_expired}
                                    />
                                </Col>
                            )}
                        </Row>
                        <hr />
                        <Row>
                            <CameraCardList integration={integration} />
                        </Row>
                    </>
                )}
            </Card.Body>
        </Card>
    );
}