/**
 * Users management reducers
 *
 * Manage/modify other users in the system;
 * - Invite a user to the system or group
 * - Check a user exists
 * - Manage/edit access/features for a user
 */

const initialState = {
  loading: null,
  actions: {
    invite: {
      loading: null,
      user: null,
      error: {},
      success: null
    },
    search: {
      loading: null,
      error: {}
    },
    removeAccess: {
      success: null,
      loading: null,
      error: {},
      user_id: null
    },
    edit: {
      success: null,
      loading: null,
      error: {},
      user_id: null
    }
  },
  current: {
    users: [],
    options: {
      roles: {},
      features: [],
      domains: []
    }
  }
};
/**
 * Users list
 */
const users = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_USER_MANAGEMENT_USER_LIST_START":
      return {
        ...state,
        // Reset users list to blank
        current: {
          ...state.current,
          users: initialState.current.users
        },
        loading: true
      };
    case "LOAD_USER_MANAGEMENT_USER_LIST_FAILED":
      return {
        ...state,
        loading: false
      };
    case "LOAD_USER_MANAGEMENT_USER_LIST_CANCEL":
      return initialState;
    case "LOAD_USER_MANAGEMENT_USER_LIST_SUCCESS":
      return {
        ...state,
        current: action.current,
        loading: false
      };
    // SEARCH
    case "REQUEST_USER_MANAGEMENT_SEARCH_START":
      return {
        ...state,

        actions: {
          ...state.actions,
          search: {
            ...initialState.actions.search,
            loading: true
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_SEARCH_SUCCESS":
      return {
        ...state,

        actions: {
          ...state.actions,
          // Update invite user object
          invite: {
            ...state.actions.invite,
            user: {
              ...state.actions.invite.user,
              ...action.user
            }
          },
          search: {
            ...initialState.actions.search,
            loading: false
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_SEARCH_FAILED":
      return {
        ...state,

        actions: {
          ...state.actions,
          invite: {
            ...state.actions.invite,
            user: {
              email: action.email
            },
            loading: false
          },
          search: {
            ...initialState.actions.search,
            error: action.error,
            loading: false
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_SEARCH_RESET":
      return {
        ...state,
        actions: {
          ...state.actions,
          // Reset invite action too
          invite: initialState.actions.invite,
          search: initialState.actions.search
        }
      };
    // INVITE
    case "REQUEST_USER_MANAGEMENT_INVITE_START":
      return {
        ...state,

        actions: {
          ...state.actions,
          invite: {
            ...state.actions.invite,
            loading: true
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_INVITE_SUCCESS":
      return {
        ...state,

        actions: {
          ...state.actions,
          invite: {
            ...state.actions.invite,
            loading: false,
            success: true
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_INVITE_FAILED":
      return {
        ...state,

        actions: {
          ...state.actions,
          invite: {
            ...state.actions.invite,
            error: action.error,
            loading: false,
            success: false
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_INVITE_NOTIFIED":
      return {
        ...state,

        actions: {
          ...state.actions,
          invite: {
            ...state.actions.invite,
            error: {},
            success: null
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_REMOVE_START":
      return {
        ...state,

        actions: {
          ...state.actions,
          removeAccess: {
            ...initialState.actions.removeAccess,
            user_id: action.user_id,
            loading: true
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_REMOVE_FAILED":
      return {
        ...state,

        actions: {
          ...state.actions,
          removeAccess: {
            ...initialState.actions.removeAccess,
            error: action.error,
            loading: false
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_REMOVE_SUCCESS":
      return {
        ...state,

        actions: {
          ...state.actions,
          removeAccess: {
            ...state.actions.removeAccess,
            loading: false,
            success: true
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_REMOVE_NOTIFIED":
      return {
        ...state,

        actions: {
          ...state.actions,
          removeAccess: initialState.actions.removeAccess
        }
      };
    case "REQUEST_USER_MANAGEMENT_EDIT_START":
      return {
        ...state,

        actions: {
          ...state.actions,
          edit: {
            ...initialState.actions.edit,
            user_id: action.user_id,
            loading: true
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_EDIT_SUCCESS":
      return {
        ...state,

        actions: {
          ...state.actions,
          edit: {
            ...state.actions.edit,
            success: true,
            loading: false
          }
        }
      };
    case "REQUEST_USER_MANAGEMENT_EDIT_FAILED":
      return {
        ...state,

        actions: {
          ...state.actions,
          edit: {
            ...initialState.actions.edit,
            error: action.error,
            loading: false
          }
        }
      };
    // Reset
    case "REQUEST_USER_MANAGEMENT_EDIT_NOTIFIED":
    case "REQUEST_USER_MANAGEMENT_EDIT_CANCEL":
      return {
        ...state,

        actions: {
          ...state.actions,
          edit: initialState.actions.edit
        }
      };
    default:
      return state;
  }
};

export default users;