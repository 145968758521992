import React from "react";

const door = props => (
  <svg
    id="Capa_1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 494.239 494.238">
    <g>
      <g>
        <path
          d="M199.725,0v36.025H85.211v421.66l114.514,0.094v36.459l209.085-37.555l0.216-418.867L199.725,0z M234.404,230.574   c7.022,0,12.715,7.408,12.715,16.545c0,9.139-5.692,16.545-12.715,16.545s-12.715-7.406-12.715-16.545   C221.688,237.982,227.382,230.574,234.404,230.574z M119.211,423.713V70.025h80.514v353.753L119.211,423.713z"
          data-original="#000000"
          className="active-path"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
);

export default door;