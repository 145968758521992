import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { default as T } from "services/i18n/Translate";

import { getTemplate } from "components/Templates/Dashboards/selectors";

import { getReportsOverview } from "services/redux/selectors/reports/common/index";

/**
 * ViewDashboard
 *
 * Views the report dashboard specified by url. If none is specified
 *  it falls back to default.
 */
class ViewDashboard extends React.Component {
  render() {
    // How to show overlay for loading?
    // - "Default" loading placeholder for entire report overview template on null.
    // - Overlay on subsequent

    // No reports state is:
    // Report finished loading
    // No reports

    const { overview } = this.props;
    console.log("overview", overview);

    // No reports available yet
    if (overview.all.reportPaths.length === 0) {
      // Finished loading and there's still no reports
      if (this.props.overview.all.loading === false) {
        return (
          <div>
            <T>No reports available for this group.</T>
          </div>
        );
      }
    }

    const Template = getTemplate(overview.all.templateId);

    return <Template overview={overview} />;
  }
}

ViewDashboard.propTypes = {
  overview: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    // report: getReport,
    overview: getReportsOverview(state, props),
  };
};
export default connect(mapStateToProps)(ViewDashboard);
