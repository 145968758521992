import { call, take, race, takeEvery, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import * as actions from "services/redux/actions";

import { liveRefreshFor } from "../common";

import conxtdOut from "apis/conxtdOut";

import { getUrlSuffix } from "services/redux/selectors/reports/scheduled/";

import * as links from "services/redux/sagas/links";

/**
 * Scheduled Report Live Refresh watcher
 */
export function* watchLiveRefresh() {
  // Link scheduled report loading to event meta
  yield takeLatest(
    ["LOAD_SCHEDULED_REPORT_START"],
    links.toEventMetaBackgroundLoader
  );

  // Link scheduled report loading to site data
  yield takeLatest(
    ["LOAD_SCHEDULED_REPORT_START"],
    links.toSitesBackgroundLoader
  );

  yield takeEvery("LOAD_SCHEDULED_REPORT_START", function*(action) {
    // const action =
    yield race({
      task: call(loadStart, action),
      cancel: take([
        "LOAD_SCHEDULED_REPORT_CANCEL",
        "LOAD_SCHEDULED_REPORT_FAILED", //#failedRemoved
        "CANCEL_ALL_LIVE_REFRESH",
        "CANCEL_ALL"
      ])
    });
  });
}

/**
 * Live Open/close report
 * const {
    startAction,
    successAction,
    failedAction,
    apiCall,
    interval,
    key
  } = params;
 *
 * @action {enterprise_id: (int), [day: Date()], group_id, type...}
 */
export function* loadStart(action) {
  const { interval, reportType, refresh, live } = action;
  const apiParams = _.omit(action, [
    "type",
    "interval",
    "reportType",
    "refresh",
    "live",
    "refreshing"
  ]);

  // e.g. live/open or open
  const urlSuffix = getUrlSuffix(reportType, live || interval);

  const params = {
    startAction: actions.loadScheduledReportStart,
    startActionParams: action,
    successAction: actions.loadScheduledReportSuccess,
    failedAction: actions.loadScheduledReportFailed,
    apiCall: call(conxtdOut.get, "/analyticx/reports/daily/" + urlSuffix, {
      params: apiParams
    }),
    interval,
    refresh,
    key: reportType
  };

  yield liveRefreshFor(params);
}
