import reports from "services/redux/transformers/reports";
import _ from "lodash";
import produce from "immer";
import { setHours, setMinutes, setSeconds, setMilliseconds} from 'date-fns'

import REPORTS_CONFIG from "constants/Reports/config/";

import merge from "deepmerge";
// const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

const scheduledData = {
  totals: [],
  combined_hourly: [], // 08/21 hourly report combined data for all sites in group
  sites: {},
};
// 08/21 allow selection of an individual site to display a chart
const selectedChartSite = {
  id: null,
  name: null,
  hourly: []
};
const timeRange = {
  startTime: null,
  endTime: null,
  selectedTimeRange: []
}
const scheduledInitial = {
  loading: null,
  filter: {
    interval: {
      roundingMethod: "1 hour",
      timeSegment: null,
      userTimezoneUTCOffset: {
        hours: 0,
        minutes: 0,
        sign: 1
      }
    },
    eventType: {
      show: {},
    },
    eventCount: {
      show: {},
    },
  },
  data: scheduledData,
  selectedChartSite: selectedChartSite, // 08/21 select site to display chart
  timeRange: timeRange, // allow to filter a time range
  DataTable: {
    filtered: [],
  },
  // data: {
  //   alarms: {
  //     OK: {},
  //     FAILED: {}
  //   }
  // },
  expanded: {},
  config: {},
};

const reportTypes = [
  "open",
  "close",
  "fire",
  "overnight",
  "suspicious",
  "panic",
  "peoplecount",
  "peoplecounthourly",
];

const now = new Date();
const getTodayAtSpecificHour = (hour = 12) => {
  // set(now, { hours: hour, minutes: 0, seconds: 0, milliseconds: 0 })
  let theDate = setHours(now, hour);
  theDate = setMinutes(theDate, 0);
  theDate = setSeconds(theDate, 0);
  theDate = setMilliseconds(theDate, 0);
  return theDate;
};

const getYesterdayAtSpecificHour = (hour = 12) => {
  // set(now, { hours: hour, minutes: 0, seconds: 0, milliseconds: 0 })
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  let theDate = setHours(yesterday, hour);
  theDate = setMinutes(theDate, 0);
  theDate = setSeconds(theDate, 0);
  theDate = setMilliseconds(theDate, 0);
  return theDate;
};

const initialState = {
  default: {
    ...scheduledInitial,
    config: REPORTS_CONFIG["default"]["default"],
  },
};
// Build initial state for each report
reportTypes.forEach((reportType) => {
  initialState[reportType] = {
    ...scheduledInitial,
    config: merge(
      REPORTS_CONFIG["default"]["default"],
      REPORTS_CONFIG["scheduled"][reportType] || {}
    ),
  };
});

const scheduled = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_SCHEDULED_REPORT_START":
      return produce(state, (draftState) => {
        _.set(draftState, action.reportType + ".loading", true);
      });
    case "LOAD_SCHEDULED_REPORT_SUCCESS":
      const scheduledReport = _.get(action, action.key);

      // Transform report
      const { config, data } = reports.transformScheduled(
        scheduledReport,
        action.key
      );

      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          // TODO: configure this for appending occurrences (live refresh)
          data,
          // Deep merge ensures we don't wipe out
          //  old data. Useful for when we are appending
          //  data
          // data: merge(
          //   state[action.key].data,
          //   data,
          //   // Ensure existing arrays are overwritten
          //   //  and not appended to
          //   { arrayMerge: overwriteMerge }
          // ),
          timeRange: { 
            selectedTimeRange: [], //start each report without a selected time range
            startTime: (
              scheduledReport.data.config
              ? scheduledReport.data.config.start_time
                ? Date.parse(scheduledReport.data.config.start_time)
                : getYesterdayAtSpecificHour(22)
              : null
            ),
            endTime: (
              scheduledReport.data.config
              ? scheduledReport.data.config.end_time
                ? Date.parse(scheduledReport.data.config.end_time)
                : getTodayAtSpecificHour(5)
              : null
            ),
          },
          config: merge(state[action.key].config, config),
          loading: false,
          lastRefresh: action.lastRefresh,
          nextRefresh: action.nextRefresh,
        },
      };
    case "LOAD_SCHEDULED_REPORT_FAILED":
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
        },
      };
    case "LOAD_SCHEDULED_REPORT_CANCEL":
      // Reset filters/expanded
      return produce(state, (draftState) => {
        if (action.reportType) {
          draftState[action.reportType].filter = scheduledInitial.filter;
          draftState[action.reportType].expanded = scheduledInitial.expanded;
        }
        // _.set(draftState, action.reportType + ".loading", true);
      });

    case "CHANGE_SCHEDULED_REPORT_EVENT_TYPE_FILTER":
      return produce(state, (draftState) => {
        const show = state[action.reportType].filter.eventType.show;
        const visible = _.get(show, action.show);

        // Get visible filters
        const visibleFilters = Object.keys(show).filter((f) => show[f]).length;

        // Reset when all filters are true
        if (visibleFilters === 4) {
          draftState[action.reportType].filter.eventType.show = {};
          return;
        }

        // Toggle visibility
        if (visible === true) {
          delete draftState[action.reportType].filter.eventType.show[
            action.show
          ];
        } else {
          draftState[action.reportType].filter.eventType.show[
            action.show
          ] = true;
        }
      });
    case "CHANGE_SCHEDULED_REPORT_ROUNDING_METHOD":
      return produce(state, (draftState) => {
        draftState[action.reportType].filter.interval.roundingMethod =
          action.roundingMethod;
      });
    case "CHANGE_SCHEDULED_REPORT_TIME_SEGMENT":
      return produce(state, (draftState) => {
        draftState[action.reportType].filter.interval.timeSegment =
          action.timeSegment;
      });
    case "SET_SCHEDULED_REPORT_USER_TIMEZONE_UTC_OFFSET":
      return produce(state, (draftState) => {
        draftState[action.reportType].filter.interval.userTimezoneUTCOffset =
          action.userTimezoneUTCOffset;
      });
    // 08/21 select site to show the chart on hourly report
    case "SET_SCHEDULED_REPORT_HOURLY_SELECTED_CHART_SITE":
        return produce(state, (draftState) => {
          draftState[action.reportType].selectedChartSite.id =
            action.id; 
        });
    // 08/21 remove selected site on hourly report
    case "REMOVE_SCHEDULED_REPORT_HOURLY_SELECTED_CHART_SITE":
        return produce(state, (draftState) => {
          draftState[action.reportType].selectedChartSite.id = null; 
        });
    case "SET_SCHEDULED_REPORT_SELECTED_TIME_RANGE":
      return produce(state, (draftState) => {
        draftState[action.reportType].timeRange.selectedTimeRange =
          action.selectedTimeRange; 
      });
    case "TOGGLE_SCHEDULED_REPORT_EXPAND_SITE":
      return produce(state, (draftState) => {
        // Select by site id
        const expanded = state[action.reportType].expanded[action.id] || null;

        if (expanded === null) {
          draftState[action.reportType].expanded[action.id] = true;
        } else {
          delete draftState[action.reportType].expanded[action.id];
        }

        // const expanded =
        //   draftState[action.reportType].data.alarms.OK[action.id].expanded || false;
      });
    case "SET_SCHEDULED_REPORT_EXPAND_SITE":
      return produce(state, (draftState) => {
        // Select by site id
        const { expanded } = action;

        draftState[action.reportType].expanded[action.id] = expanded;
      });
    case "RESET_SCHEDULED_REPORT_EXPANDED":
      return produce(state, (draftState) => {
        draftState[action.reportType].expanded = {};
      });

    case "LOAD_REPORTS_OVERVIEW_FAILED":
      // Reset reports when overview fails
      return initialState;

    case "LOGOUT_REQUEST":
      // Reset to initial state when user logs out
      return initialState;
    default:
      return state;
  }
};

export default scheduled;