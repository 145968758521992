import React from "react";
// import axios from "axios";

import './EagleEyeLiveView.scss';

// import Loading from "components/Loading";
import _ from "lodash";

class EagleEyeLiveView extends React.Component {

    state = {
        socketState: '',
        error: false,
        imgSrcLoad: true
    };

    // componentWillMount() {
    componentDidMount() {

        let camera_id = this.props.camera_ref;
        let subdomain = this.props.subdomain;
        let account_id = this.props.account_id;
        let auth_token = this.props.auth_token;

        //if auth_expired is false
        if (!this.props.auth_expired) {
            // axios.get('https://streamer.een.cloud').then(data => {
                let img = document.getElementById(camera_id);
                // Build the subscription string
                let data = { "cameras": {} };
                data.cameras[camera_id] = { "resource": ["image"] }

                // Build the WebSocket connection URL
                let connection_url = [
                    "wss://",
                    subdomain,
                    "/api/v2/Device/",
                    account_id,
                    "/Events?A=",
                    auth_token
                ].join('');

                // make the WebSocket connection
                let socket = new WebSocket(connection_url);

                this.setState({ socketState: socket });

                // when the socket opens, register the subscriptions
                socket.addEventListener('open', function (event) {
                    console.log('socket opened');
                    socket.send(JSON.stringify(data));
                });

                // for every message received, parse the data into an object and update the image on the page
                // send an acknowledgement that you are ready for the next image
                socket.addEventListener('message', (event) => {
                    //console.log(event.data);
                    let json = JSON.parse(event.data);
                    if (img) {
                        if (_.get(json.data[camera_id], 'error') === undefined) {
                            if (_.get(json.data[camera_id], 'image') !== undefined) {
                                img.setAttribute('src', 'data:image/jpeg;base64,' + json.data[camera_id].image.image);
                                img.setAttribute('alt', camera_id);
                                this.setState({ imgSrcLoad: false });
                            }
                        }

                        if (_.get(json.data[camera_id], 'error') !== undefined) {
                            this.state.socketState.close();
                            this.setState({ error: true, imgSrcLoad: false, socketState: '' });
                        }
                    }

                    if (_.get(json.data[camera_id], 'image') !== undefined) {
                        socket.send('{"ack": ' + json.data[camera_id].image.epoch + '}');
                    }

                    this.setState({ imgSrcLoad: false });
                });

                // handle the close event
                socket.addEventListener('close', function (event) {
                    console.log('socket closed')
                });

                // handle any errors
                socket.addEventListener('error', function (event) {
                    console.log('socket error ', event);
                });

            // });
        }
    };

    componentWillUnmount() {
        if (this.state.socketState !== '') {
            this.state.socketState.close();
        }
        this.setState({ imgSrcLoad: true });
        // this.state.socketState.addEventListener('close', function(event) {
        //     console.log('socket closed')
        // });
    }

    render() {
        const source = this.props.src === 'buttonview' ? '' : 'eagle-eye-live-view-button';
        return (
            <>
                {this.state.imgSrcLoad === true &&
                    <div className={source}>
                    </div>
                }
                {this.state.error === false ? (
                    <>
                        <div className={source}>
                            <img
                                id={this.props.camera_ref}
                                //alt={this.props.camera_ref}
                                alt=''
                                style={{ width: '100%', height: "215px" }}
                            >
                            </img>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={source}> Error </div>
                    </>
                )}
            </>
        )
    }
}

export default EagleEyeLiveView;