export const darkModeSelectStyles = {
  option: (provided) => ({
    ...provided,
    color: 'white',
    borderColor: 'white',
    backgroundColor: '#13274d',
    '&:hover': {
      backgroundColor: '#b9bfca',
    },
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#13274d',
    borderColor: 'white',
    '&:focus-within': {
      borderColor: 'white',
      boxShadow: 'none'
    },
    '&:hover': {
      borderColor: 'white',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#b9bfca',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#13274d',
  }),
};