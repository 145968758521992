/**
 * Optional autocomplete event filter for data table
 */

import React from "react";

import FilterSelect from "components/Common/Dropdown/FilterSelect";

const EventFilter = ({ filter, onChange, column }) => {
  const { sites, getEventMeta } = column;

  let collectedIds = {};
  const options = sites.reduce((state, site) => {
    if (!site.occurrences || site.occurrences.length === 0) return state;

    return [
      ...state,
      ...site.occurrences
        .map(occurrence => {
          const event = getEventMeta(occurrence);

          const id = event.id;

          if (collectedIds.hasOwnProperty(id)) {
            return null;
          }
          collectedIds[id] = true;
          return {
            name: event.caption,
            color: event.colour,
            id,
            icon: event.icon
          };
        })
        .filter(i => i)
    ];
  }, []);

  return (
    <FilterSelect
      onChange={onChange}
      value={filter ? filter.value : ""}
      options={options}
    />
  );
};

export default EventFilter;