const initialState = {
  data : {
    sudoSites: [],
    pagination: {
      count: 0,
      pageCount: 1,
    },
  },
  filterData: [],
  loadingCompanyFilterData: null,
  displayPageIndex: 0,
  pageSize: 20,
  sortMapField: null,
  sortDirection: "desc",
  params: {},
  loadingCompany: null,
};

export default function company(state = initialState, action) {
  switch (action.type) {

    case "LOAD_COMPANY_FILTER_DATA_START":
      return {
        ...state,
        loadingCompanyFilterData: true,
      };

    case "LOAD_COMPANY_FILTER_DATA_SUCCESS":
      return {
        ...state,
        filterData: action.data ? action.data : [],
        loadingCompanyFilterData: false,
      }

    case "LOAD_COMPANY_FILTER_DATA_FAILED":
      return {
        ...state,
        loadingCompanyFilterData: false,
      };

    case "LOAD_COMPANY_FILTER_DATA_CANCEL":
      return {
        ...state,
        loadingCompanyFilterData: false,
      };

    case "LOAD_COMPANY_START":
      return {
        ...state,
        loadingCompany: true,
      };

    case "LOAD_COMPANY_SUCCESS":
      return {
        ...state,
        data: action.data ? action.data : {
          sudoSites: [],
          pagination: {
            count: 0,
            pageCount: 1,
          },
        },
        displayPageIndex: action.displayPageIndex || state.displayPageIndex,
        pageSize: action.startAction.pageSize || 20,
        sortMapField: action.startAction.sortMapField || null,
        sortDirection: action.startAction.sortDirection || "desc",
        params: action.startAction.params || {},
        loadingCompany: false,
      }

    case "LOAD_COMPANY_FAILED":
      return {
        ...state,
        loadingCompany: false,
      };

    case "LOAD_COMPANY_CANCEL":
      return {
        ...state,
        loadingCompany: false,
      };

    case "CLEAR_COMPANY":
      return {
        ...state,
        data : {
          sudoSites: [],
          pagination: {
            count: 0,
            pageCount: 1,
          },
        },
        filterData: [],
        loadingCompanyFilterData: null,
        displayPageIndex: 0,
        pageSize: 20,
        sortMapField: null,
        sortDirection: "desc",
        params: {},
        loadingCompany: null,
      };

    case "SET_COMPANY_DISPLAY_PAGE_INDEX":
      return {
        ...state,
        displayPageIndex: action.displayPageIndex,
      };

    default:
      return state;
  }
}