import React from "react";
import PropTypes from "prop-types";

import ExternalRecordingView from "./ExternalRecordingView";
import LiveViewToolbar from "./LiveViewToolbar";
import PresetToolbar from "./PresetToolbar";

function CameraList({ integrations, time, camera_events = [], timeline = false }) {

  return integrations.map((integration, index) =>
    !integration.camera_integration_site ? null : (
      <div className="col" key={index}>
        {timeline ? (
          <>
            <ExternalRecordingView integration={integration} camera_events={camera_events} time={time} />
          </>
        ) : (
            <>
              <LiveViewToolbar integration={integration} />
              {integration.integration_name === 'Xanview' ? (
                <>
                  <PresetToolbar integration={integration} />
                </>
              ) : null}
            </>
          )
        }
      </div>
    )
  );
}
CameraList.propTypes = {
  /**
   * @param integrations camera integrations for site
   */
  integrations: PropTypes.array.isRequired
};
export default CameraList;
