import { call } from "redux-saga/effects";

import {
    loadSiteModeSessionStart,
    loadSiteModeSessionFailed,
    loadSiteModeSessionSuccess,
    loadSiteModeSessionCancel,
    loadSiteModeEventsStart,
    loadSiteModeEventsFailed,
    loadSiteModeEventsSuccess,
    loadSiteModeEventsCancel,
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* loadModes({ id, mode_type }) {
    try {
        const result = yield call(conxtdOut.get, `/EventPairs/modeSessions/${id}/${mode_type}`);

        // const typeProp = mode_type === 'E' ? 'engineer_sessions' : 'test_sessions';

        //to be used when i also start using test filter
        // console.log({[typeProp]: result.data[0]});
        return result;
        // return result;
    } catch (e) {
        throw new Error(e);
    }
}

export function* loadModeSessionsWatcher() {
    yield actionWatcher({
        actions: {
            start: loadSiteModeSessionStart,
            failed: loadSiteModeSessionFailed,
            success: loadSiteModeSessionSuccess,
            cancel: loadSiteModeSessionCancel,
        },
        saga: loadModes
    });
}

export function* loadModeEvents({ id }) {
    try {
        const result = yield call(conxtdOut.get, `/EventPairs/modeEvents/${id}`);

        return result;
    } catch (e) {
        throw new Error(e);
    }
}

export function* loadModeEventsWatcher() {
    yield actionWatcher({
        actions: {
            start: loadSiteModeEventsStart,
            failed: loadSiteModeEventsFailed,
            success: loadSiteModeEventsSuccess,
            cancel: loadSiteModeEventsCancel,
        },
        saga: loadModeEvents
    });
}