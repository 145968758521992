import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "react-table/react-table.css";

import Loading from "components/Loading";

import { SelectTimeframe } from "components/Common/Timeframe/SelectTimeframe";

import SiteView from "components/Site/SiteView/";

import Icon from "components/Icons/Icon";

import { ButtonToolbar } from "react-bootstrap";
import ViewAs from "components/Common/ViewAs/";

import Table from "./Table";
import MapView from "./MapView/";

import { withT } from "services/translation/";

import { getCurrentGroupFiltered } from "services/redux/selectors/groups";

import {
  getRouterParams,
  getRouterLocation
} from "services/redux/selectors/app/";

import {
  changeGroupOverviewFilter,
  changeGroupTimeframe,
} from "services/redux/actions";

import Filter from "../Filter";

class SiteList extends React.Component {
  state = {
    // "table" or "map"
    viewAs: "table",
    // viewAs: "map",
    showSidePane: false,
    selectedSite: null
  };

  // onFilteredChangeCustom = (value, accessor) => {
  //   let filtered = [...this.state.filtered];
  //   let insertNewFilter = 1;

  //   // Filter already exists
  //   if (filtered.length) {
  //     filtered = filtered
  //       .map((filter, i) => {
  //         if (filter["id"] === accessor) {
  //           insertNewFilter = 0;

  //           // Filter text value is empty
  //           if (value === "" || !value.length) {
  //             // filtered = filtered.slice(i, 1);
  //             // To be removed (see remove falsey below)
  //             return false;
  //           } else {
  //             return {
  //               ...filter,
  //               value
  //             };
  //             // filter["value"] = value;
  //           }
  //         }
  //         return filter;
  //       })
  //       // Remove falsey values
  //       .filter(falsey => falsey);
  //   }

  //   // Filter doesn't exist - add
  //   if (insertNewFilter) {
  //     // filtered.push({ id: accessor, value: value });
  //     filtered = [...filtered, { id: accessor, value: value }];
  //   }

  //   this.setState({ filtered });
  // };
  selectSite = site => {
    const { selectedSite } = this.state;
    const { enterprise_id, group_id } = this.props.router.params;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");
      this.setState({
        showSidePane: true,
        selectedSite: site
      });

      // Only set bundle id if selecting for the first time
      const bundle_id =
        selectedSite === null ? this.props.router.params.bundle_id || "" : "";

      this.props.history.push(
        `/enterprise/${enterprise_id}/${group_id}/${site.id}/${bundle_id}`
      );
    }
    // Clicked a site that's already being viewed, close
    else {
      document.body.classList.remove("sidepane-active");

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.props.history.push(`/enterprise/${enterprise_id}/${group_id}`);

        this.setState({
          showSidePane: false,
          selectedSite: null
        });
      }, 600);
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    const { selectedSite } = this.state;
    const { params } = nextProps.router;
    const { currentGroup } = nextProps;

    if (params.site_id && currentGroup.sites) {
      const site = currentGroup.sites.find(
        ({ id }) => id === parseInt(params.site_id)
      );

      if ((selectedSite && selectedSite.id) !== (site && site.id))
        this.selectSite(site);
    }
  };
  render() {
    const { currentGroup, dispatch } = this.props;
    const { viewAs, selectedSite } = this.state;

    if (!currentGroup.sites) return null;

    if (currentGroup.loading === null) return <Loading center />;

    return (
      <>
        <Filter
          className="mb-3"
          filters={currentGroup.filters}
          enableTextFilter={false}
          changeFilter={filter => {
            this.props.dispatch(changeGroupOverviewFilter(filter));
          }}
        />
        <div className="row mb-2">
          <div className="col-12 d-flex">
            <ButtonToolbar
              style={{
                margin: "auto 0 auto auto"
              }}>
              <SelectTimeframe
                className="mr-2"
                timeframe={currentGroup.timeframe}
                changeTimeframe={timeframe => {
                  dispatch(changeGroupTimeframe({ timeframe }));
                }}
              />
              <ViewAs
                options={[
                  {
                    key: "table",
                    description: "View as list",
                    Icon: <Icon className="fas fa-list-ul" />
                  },
                  {
                    key: "map",
                    description: "View as map",
                    Icon: <Icon className="fas fa-map-marked-alt" />
                  }
                ]}
                viewAs={viewAs}
                changeViewAs={viewAs => this.setState({ viewAs })}
              />
            </ButtonToolbar>
          </div>
        </div>

        {viewAs === "table" ? (
          <Table selectedSite={selectedSite} selectSite={this.selectSite} />
        ) : (
          <div style={{ minHeight: "70vh" }}>
            <MapView selectedSite={selectedSite} selectSite={this.selectSite} />
          </div>
        )}
        <SiteView
          site={selectedSite}
          selectSite={this.selectSite}
          origin="SiteList"
          // Using router's state for bundle_id
          bundle_id={this.props.router.params.bundle_id}
          onSelectBundle={bundle_id => {
            const {
              enterprise_id,
              group_id,
              site_id
            } = this.props.router.params;
            // Bundle selected
            if (bundle_id) {
              this.props.history.push(
                `/enterprise/${enterprise_id}/${group_id}/${site_id}/${bundle_id}`
              );
            }
            // Invalid bundle id, deselect
            else {
              this.props.history.push(
                `/enterprise/${enterprise_id}/${group_id}/${site_id}`
              );
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    // groups: getGroups(state, props),
    currentGroup: getCurrentGroupFiltered(state),
    // loading: getGroupsLoading(state, props),
    router: {
      params: getRouterParams(state, props),
      location: getRouterLocation(state, props)
    }
  };
};
export default withRouter(
  withT(
    connect(mapStateToProps)(SiteList),

    "site_list.data_table"
  )
);
