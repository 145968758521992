/**
 * 09/21 - open close by area
 */

import { put } from "redux-saga/effects";

import * as actions from "services/redux/actions";

/**
 * Link outstanding reports action to sites background loader
 * @param {action} action
 */
export function* toSitesBackgroundLoader(action) {
  const { enterprise_id, group_id } = action;
  yield put(actions.loadSitesBackgroundStart({ enterprise_id, group_id }));
}
/**
 * Link outstanding reports to event meta background preloader
 */
export function* toEventMetaBackgroundLoader() {
  yield put(actions.loadEventMetaBackgroundStart());
}