import React from "react";

const engineer = props => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 364.09 364.09"
    width={props.width}
    height="100%"
    className="">
    <g>
      <g>
        <path
          d="M182.045,197.203c24.125,0,80.846-29.033,80.846-98.602c0-9.704-0.236-19.078-1.036-27.935   c-1.313-17.432-4.883-32.88-13.773-44.72C236.681,9.836,216.732,0,182.045,0c-34.68,0-54.628,9.833-66.03,25.937   c-8.896,11.841-12.467,27.295-13.78,44.732c-0.799,8.856-1.036,18.229-1.036,27.932C101.199,168.17,157.92,197.203,182.045,197.203   z M208.434,36.123c-2.354,5.951-3.534,8.926-5.89,14.877c-15.209-4.297-25.791-4.297-41,0c-2.356-5.951-3.534-8.926-5.891-14.877   C174.674,30.365,189.416,30.365,208.434,36.123z M113.227,83.517c0.931,2.038,4.888,9.87,11.013,10.604   c7.041,0.844,18.728-24.936,57.805-24.998c39.076,0.063,50.764,25.842,57.805,24.998c6.125-0.735,10.082-8.567,11.013-10.604   c0.19,4.992,0.259,10.045,0.259,15.084c0,34.006-15.015,55.076-27.612,66.763c-15.871,14.726-33.493,20.071-41.465,20.071   c-7.972,0-25.594-5.345-41.465-20.071c-12.597-11.687-27.612-32.757-27.612-66.763C112.968,93.562,113.037,88.509,113.227,83.517z"
          data-original="#000000"
          className="active-path"
          fill={props.color}
        />
        <path
          d="M332.138,298.327c-4.127-25.664-12.624-58.723-29.668-70.473c-11.634-8.021-52.229-29.71-69.563-38.971l-0.318-0.169   c-1.982-1.059-4.402-0.846-6.17,0.541c-9.084,7.131-19.034,11.937-29.574,14.283c-1.862,0.415-3.39,1.738-4.067,3.521   l-10.733,28.291l-10.733-28.291c-0.677-1.783-2.205-3.107-4.067-3.521c-10.54-2.346-20.49-7.152-29.574-14.283   c-1.768-1.388-4.188-1.601-6.17-0.541c-17.134,9.156-58.238,31.294-69.831,39.106c-19.62,13.219-28.198,61.053-29.718,70.508   c-0.151,0.938-0.064,1.897,0.253,2.793c0.477,1.346,8.942,23.232,55.339,37.154v-23.897c0.002-10.257,8.348-18.602,18.604-18.602   h75.877h0.003h0.001c10.255,0.001,18.599,8.346,18.599,18.604v1.918h31.722l20.368-5.362c0.833-0.219,1.689-0.33,2.546-0.33   c0.595,0,1.191,0.053,1.779,0.16l32.28,5.836c4.759,0.86,8.221,5.004,8.221,9.84v3.966c28.114-13.07,33.946-28.177,34.339-29.288   C332.202,300.224,332.289,299.264,332.138,298.327z"
          data-original="#000000"
          className="active-path"
          fill={props.color}
        />
        <path
          d="M233.645,326.297h-43.016v-11.918c0-4.751-3.849-8.603-8.599-8.604c-0.001,0-0.002,0-0.003,0h-75.877   c-4.751,0-8.603,3.852-8.604,8.604v41.107c0,4.75,3.852,8.604,8.601,8.604c0.001,0,0.002,0,0.003,0h75.876   c4.753,0,8.604-3.852,8.604-8.604v-11.92l43.018,0.001l21.618,5.69l32.28-5.836v-16.98l-32.28-5.836L233.645,326.297z    M168.428,350.841l-48.68-0.001c-2.703,0-4.896-2.19-4.896-4.893c0-2.704,2.193-4.896,4.896-4.896h48.68   c2.701,0,4.895,2.191,4.895,4.896C173.322,348.649,171.129,350.841,168.428,350.841z M168.427,328.814l-48.678,0.001   c-2.704,0-4.897-2.19-4.897-4.896c0-2.703,2.191-4.895,4.897-4.893l48.679-0.001c2.701-0.001,4.895,2.191,4.895,4.895   C173.322,326.623,171.131,328.814,168.427,328.814z"
          data-original="#000000"
          className="active-path"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
);

export default engineer;