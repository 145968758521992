/**
 * Multi progress bar with tooltip used on group statistics component
 */

import React from "react";
import PropTypes from "prop-types";

import PieChart from "components/Enterprise/Reports/Charts/PieChart";

import { Tooltip } from "components/Common/Tooltip";

import { withT } from "services/translation/";

import { ProgressBar } from "./";

import BarChart from "components/Enterprise/Overview/Statistics/BarChart";

import "./MultiProgressBar.scss";

const MultiProgressBar = withT(
  ({ height = "8px", values, total, onClick, filters, title, t }) => {
    return (
      <div
        className="multi-progress progress mt-3"
        style={{
          height
          // marginTop: "7px"
        }}>
        {values
          // Remove '0" values
          .filter(({ value }) => value !== 0)
          .map(({ value, color, filterGroup, key, tooltip, name }, index) => {
            const percentage = (value / total) * 100 || 0;

            const active =
              filters.filter(f => f.filterGroup + f.key === filterGroup + key)
                .length > 0;

            const ProgressDiv = (
              <div
                className={`progress-bar progress-bar-animated ${
                  active ? "active" : ""
                }`}
                role="progressbar"
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                  width: percentage + "%",
                  height,
                  backgroundColor: color,
                  cursor: "pointer"
                }}
                onClick={() => {
                  onClick({
                    color,
                    name,
                    filterGroup,
                    key,
                    // Show only non-zero values
                    type: "nonZero",
                    // This is a toggle filter
                    toggle: true
                  });
                }}
              />
            );

            if (tooltip) {
              return (
                <Tooltip
                  key={index}
                  title={
                    <span className="text-uppercase font-16">{title}</span>
                  }
                  white={true}
                  description={
                    <>
                      <span
                        style={{ color }}
                        className="font-14 font-weight-bold">
                        {t(
                          "group_overview.multi_progress_bar.tooltip.title",
                          Math.ceil(percentage).toFixed(0),
                          name,
                          value,
                          total
                        )}
                      </span>
                      <ProgressBar
                        className="my-2"
                        color={color}
                        percentage={percentage}
                      />
                      <BarChart values={values} total={total} />

                      {/* <Bar
                      // labels={values.map(v => v.name)}
                      data={{
                        datasets: values.map(v => {
                          return {
                            data: [v.value],
                            label: v.name,
                            backgroundColor: v.color,
                            type: "bar",
                            fill: true
                          };
                        })
                      }}
                      options={{
                        responsive: true,
                        legend: { display: false }
                      }}
                    /> */}
                      <PieChart
                        pie={{ data: values, colors: values.map(i => i.color) }}
                        showPct
                        hideLegend={true}
                        changeFilter={() => {}}
                      />
                    </>
                  }
                  placement="bottom">
                  {ProgressDiv}
                </Tooltip>
              );
            }

            return ProgressDiv;
          })}
      </div>
    );
  }
);
MultiProgressBar.propTypes = {
  values: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired
};

export { MultiProgressBar };
