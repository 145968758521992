/**
 * Add/link Camera (aka sudo sites) to enterprise sites
 *
 * This is also where editing cameras is done (/CameraSudoSites/edit/),
 * which is currently just for assigning bundle ids
 */

import { call } from "redux-saga/effects";

import {
  requestAddSiteCameraSuccess,
  requestAddSiteCameraFailed,
  requestAddSiteCameraStart,
  requestAddSiteCameraCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import { mockEnabled } from "services/redux/selectors/";

import conxtdOut from "apis/conxtdOut";

function* requestAddCamera({
  camera_integration_site_id,
  camera_sudo_site_id,
  camera,
  body,
  edit
}) {
  const endpointAction = edit ? "edit" : "add";
  const apiFunction = edit ? conxtdOut.post : conxtdOut.post;

  const id = edit ? camera_sudo_site_id : camera_integration_site_id;

  const apiBody = edit ? body : [camera];

  try {
    const result = yield mockEnabled()
      ? call(() => {
          return {
            data: null
          };
        })
      : call(apiFunction, `/CameraSudoSites/${endpointAction}/${id}`, apiBody);

    return result;
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Integration requestAdd watcher saga
 */
export function* requestAddCameraWatcher() {
  yield actionWatcher({
    actions: {
      start: requestAddSiteCameraStart,
      failed: requestAddSiteCameraFailed,
      success: requestAddSiteCameraSuccess,
      cancel: requestAddSiteCameraCancel
    },
    saga: requestAddCamera
  });
}
