import React from "react";

import SettingsCard from "./SettingsCard";

const Settings = props => {
  return (
    <div className="row">
      <div className="col-md-6">
        <SettingsCard />
      </div>
    </div>
  );
};

export default Settings;
