import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, Card } from "react-bootstrap";

import { loadEnterpriseAssociationsStart } from "services/redux/actions/serviceDesk";

import Icon from "components/Icons/Icon";
import { Tooltip } from "components/Common/Tooltip/Tooltip";
import Alert from "components/Common/Alert";
import conxtdOut from "apis/conxtdOut";

import "../AssetEditor.js";

function SingularAssociation({
  enterpriseName,
  enterprise_id,
  group_id,
  siteName,
  customRef,
  billingStatus,
  sudoSiteId,
  siteRef,
}) {
  
  const dispatch = useDispatch();

  const handleUnlinkAssociation = () => {
    Alert({
      html: `<div>Are you sure you want to unlink "<span class="font-weight-bolder">${siteName}</span>" from "<span class="font-weight-bolder">${enterpriseName}</span>"?</div>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.value) {
        const unlinkEnterpriseAssociation = conxtdOut.post(`/ServiceDesk/unlinkEnterpriseAssociation/${sudoSiteId}`);
          unlinkEnterpriseAssociation.then(() => {
            Alert({
              text: "Site unlinked successfully",
              icon: "success",
              timerProgressBar: true,
              timer: 5000,
            });
            if (sudoSiteId) {
              dispatch(loadEnterpriseAssociationsStart({
                sudoSiteId: sudoSiteId,
              }))
            }
          }).catch((error) => {
            Alert({
              text: "An issue occurred. Please try again later.",
              icon: "error",
            });
          })
      } else {
        return
      }
    })
  }

  return (
    <>
      <Card className="singular-association m-0" data-intercom-target={"individual-enterprise-association-container"}>
        <div className='d-flex justify-content-between flex-nowrap' id='enterprise-associations-groups'>
          <Form.Group className="col enterprise-associations-group">
            <Form.Control
              data-intercom-target={"enterprise-association-ent-name"}
              name="ent_associations_header"
              type="text"
              value={enterpriseName ?? ""}
              className="enterprise-associations-text"
              onChange={() => {return}}
            />
          </Form.Group>
          <Form.Group className="col enterprise-associations-group">
            <Form.Control
              data-intercom-target={"enterprise-association-site-name"}
              name="site_name"
              type="text"
              value={siteName ?? ""}
              className="enterprise-associations-text"
              onChange={() => {return}}
            />
          </Form.Group>
          <Form.Group className="col enterprise-associations-group">
            <Form.Control
              data-intercom-target={"enterprise-association-custom-ref"}
              name="custom_ref"
              type="text"
              value={customRef ?? ""}
              className="enterprise-associations-text"
              onChange={() => {return}}
            />
          </Form.Group>
          <Form.Group className="col enterprise-associations-group">
            <Form.Control
              data-intercom-target={"enterprise-association-billing-status"}
              name="billing_status"
              type="text"
              value={billingStatus ?? ""}
              className="enterprise-associations-text"
              onChange={() => {return}}
            />
          </Form.Group>
          <Form.Group className="col d-flex justify-content-center align-items-center flex-row">
          <Tooltip description="edit enterprise site" placement="bottom">
              <Link
                to={`/enterprise/${enterprise_id && enterprise_id}/${group_id && group_id}/view-group?site-ref=${siteRef ?? null}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button id="edit-ent-button" className="mr-1" data-intercom-target={"enterprise-association-edit-button"}>
                  <Icon className="fas fa-edit" />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip description="remove association" placement="bottom">
              <Button id="remove-ent-button" className="ml-1" onClick={handleUnlinkAssociation} data-intercom-target={"enterprise-association-delete-button"}>
                <Icon className="fas fa-unlink" />
              </Button>
            </Tooltip>
          </Form.Group>
        </div>
      </Card>      
    </>
  )
}

export default SingularAssociation
