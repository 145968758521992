import { call, takeLeading, put } from "redux-saga/effects";

import conxtdOut from "apis/conxtdOut";

import {
  requestAddKeyholderStart,
  requestAddKeyholderFailed,
  requestAddKeyholderSuccess,
  requestAddKeyholderCancel,
  loadSiteKeyholdersStart
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

function* watchAddKeyholder() {
  // Reload keyholders on successful add
  yield takeLeading("REQUEST_ADD_KEYHOLDER_SUCCESS", function*(action) {
    yield put(
      loadSiteKeyholdersStart({
        id: action.startAction.params.enterprise_site_id
      })
    );
  });

  yield actionWatcher({
    actions: {
      start: requestAddKeyholderStart,
      failed: requestAddKeyholderFailed,
      success: requestAddKeyholderSuccess,
      cancel: requestAddKeyholderCancel
    },
    saga: function*(action) {
      yield call(conxtdOut.post, "/EnterpriseKeyholders/add", action.params);
    }
  });
}

export { watchAddKeyholder };
