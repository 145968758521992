import React from "react";
import { connect } from "react-redux";

import { Row, Col, Card, Button } from "react-bootstrap";

import {
  getIntegrationsStart,
  setEmIntegrationTabContent,
} from "services/redux/actions/enterpriseManager";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getEnabledIntegrations, getAvailableIntegrations } from "services/redux/selectors/enterpriseManager";

import AddBrivoApiKeyModal from "./Brivo/AddBrivoApiKeyModal";
import AddUptickAuthModal from "./Uptick/AddUptickAuthModal";

import conxtdOut from "apis/conxtdOut";

import "./Integrations.scss";

import Icon from "components/Icons/Icon";

import { Tooltip } from "components/Common/Tooltip/";

class Integrations extends React.Component {
  state = {
    currentIntegrationId: null,
    addBrivoApiKeyModalShow: false,
    addUptickAuthModalShow: false,
  }

  componentDidMount() {
    this.props.dispatch(
      getIntegrationsStart({
        enterprise_id: this.props.enterprise.id,
      })
    );
  }

  render() {
    const { enterprise, enabledIntegrations, availableIntegrations } = this.props;

    let addBrivoApiKeyModalClose = () => this.setState({ addBrivoApiKeyModalShow: false });
    let addUptickAuthModalClose = () => this.setState({ addUptickAuthModalShow: false });

        return (
      <div className="integrations-container">
        {
          enabledIntegrations && enabledIntegrations.length > 0 && <div>
            <Row className="mt-2 mb-4 card-subtitle h6">
              <Col className="font-weight-bold">Enabled</Col>
            </Row>
            <Row>
              {enabledIntegrations.map(integration => (
                <Col className="mb-4" xs={12} sm={6} md={4} key={integration.id}>
                  <Card className="integration-card button-toggle">
                  <Card.Title className="d-flex flex-row flex-nowrap card-header align-items-center card-title-container p-4"><div className="card-name">{integration.name}</div>
                    <div className="d-flex flex-row flex-nowrap justify-content-between m-0 p-0">
                      <div className="flex-nowrap flex-row button-container m-0 p-0">

                        {/* Learn More button */}
                        {integration.intercom_guide_id
                        ? <Tooltip
                            description="Learn more"
                            placement="top"
                          >
                            <Button 
                              type="button" 
                              className="btn btn-primary btn-sm mr-1" 
                              data-toggle="tooltip" 
                              data-placement="top" 
                              data-title="Tooltip on top"
                              onClick={() => {
                                window.Intercom('showArticle', integration.intercom_guide_id);
                              }}
                            >
                              <Icon 
                                className="fas fa-info-circle" 
                              />
                            </Button>
                          </Tooltip>
                        :
                          <></>
                        }
                        
                        {/* Talk to sales button */}
                        {integration.talk_to_sales
                        ? <Tooltip
                            description="Talk to Sales"
                            placement="top"
                          >
                            <Button 
                              type="button" 
                              className="btn btn-primary btn-sm mr-1"
                              onClick={() => {
                                window.Intercom('showNewMessage', `I'm interested in ${integration.name}, please tell me more...`);
                              }}
                            > 
                              <Icon 
                                className="far fa-comment-dots" 
                              />
                            </Button>
                          </Tooltip>
                        : 
                          <></>
                        }
                        
                        {/* Go to website button */}
                        {integration.details_url
                          ? <Tooltip
                              description="Go to website"
                              placement="top"
                            >
                              <Button 
                                type="button" 
                                className="btn btn-primary btn-sm mr-1"
                                onClick={() => {
                                  window.open(
                                    integration.details_url,
                                    '_blank'
                                  );
                                }}
                              > 
                                <Icon 
                                  className="fas fa-external-link-alt"
                                />
                              </Button>
                            </Tooltip>
                          : 
                            <div className="mt-2 mx-4 mb-4" style={{ height: "35px" }}></div> // space for when there is no button
                        }
                        </div>
                        {/* <div className="ellipsis-placeholder">
                          <Icon className="fas fa-ellipsis-h" />
                        </div> */}
                      </div>
                    </Card.Title>
                    <Card.Body className="p-0 d-flex flex-column justify-content-between">
                      <div>
                        <div className="integration-image-container d-flex justify-content-center align-items-center">
                          {integration.logo && <Card.Img className="p-4 integration-image" variant="top" src={integration.logo} />}
                        </div>
                        {integration.description && <p className="m-4 text-center card-description">{integration.description}</p>}
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        {
                          integration.class_name === "Brivo" && (
                            integration.api_key && integration.api_key === "valid" ? ( // api key is valid
                              <div className="d-flex flex-column justify-content-between">
                                {
                                  integration.events ? ( // events have been enabled
                                    <Button
                                      className="my-2 mx-4"
                                      disabled
                                    >
                                      Events enabled
                                    </Button>
                                  ) : ( // events have not yet been enabled
                                    <Button
                                      className="my-2 mx-4"
                                      variant="outline-primary"
                                      onClick={() => {
                                        conxtdOut.post(`/Brivo/subscribe/${integration.id}/${enterprise.id}`, {})
                                          .then(() => {
                                            this.props.dispatch(
                                              getIntegrationsStart({
                                                enterprise_id: enterprise.id,
                                              })
                                            );
                                          }).catch((error) => {
                                            console.log("Error on enabling events: ", error);
                                          })
                                      }}
                                    >
                                      Enable events
                                    </Button>
                                  )
                                }
                                {
                                  <Button
                                    className="my-2 mx-4"
                                    variant="outline-primary"
                                    onClick={() => {
                                      this.props.dispatch(
                                        setEmIntegrationTabContent({
                                          integrationTabContent: "brivo-sites"
                                        })
                                      )
                                    }}
                                  >
                                    Link sites
                                  </Button>
                                }
                              </div>
                            ) : ( // api key either hasn't yet been entered or is invalid
                              <div className="d-flex flex-column justify-content-between">
                                <div className="my-2 mx-4" style={{ height: "35px" }}></div>
                                <Button
                                  className="my-2 mx-4"
                                  variant="outline-primary"
                                  onClick={() => {
                                    this.setState({
                                      currentIntegrationId: integration.id,
                                      addBrivoApiKeyModalShow: true
                                    });
                                  }}
                                >
                                  Enter API Key
                                </Button>
                              </div>
                            )
                          )
                        }
                        {
                          integration.class_name === "Uptick" && (
                            <div className="d-flex flex-column justify-content-between">
                              <div className="my-2 mx-4" style={{ height: "35px" }}></div>
                              <Button
                                className="my-2 mx-4"
                                variant="outline-primary"
                                onClick={() => {
                                  this.props.dispatch(
                                    setEmIntegrationTabContent({
                                      integrationTabContent: "uptick-sites"
                                    })
                                  )
                                }}
                              >
                                Link sites
                              </Button>
                            </div>
                          )
                        }
                        { 
                          integration.class_name !== "Brivo" && integration.class_name !== "Uptick" && ( // space for when there are no buttons
                            <div className="d-flex flex-column justify-content-between">
                              <div className="my-2 mx-4" style={{ height: "35px" }}></div>
                              <div className="my-2 mx-4" style={{ height: "35px" }}></div>
                            </div>
                          )
                        }
                        {integration.details_url
                          ? <Button
                              className="mt-2 mx-4 mb-4"
                              variant="outline-primary"
                              onClick={() => {
                                window.open(
                                  integration.details_url,
                                  '_blank'
                                );
                              }}
                            >
                              Go to website
                            </Button>
                          : <></>
                        }
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        }
        {
          availableIntegrations && availableIntegrations.length > 0 && <div>
            <Row className="mt-2 mb-4 card-subtitle h6">
              <Col className="font-weight-bold">Available</Col>
            </Row>
            <Row>
              {availableIntegrations.map(integration => (
                <Col className="mb-4" xs={12} sm={6} md={4} key={integration.id}>
                  <Card className="integration-card button-toggle">
                    <Card.Title className="d-flex flex-row flex-nowrap card-header align-items-center card-title-container p-4"><div className="card-name">{integration.name}</div>
                    <div className="d-flex flex-row flex-nowrap justify-content-between m-0 p-0">
                      <div className="flex-nowrap flex-row button-container m-0 p-0">

                        {/* Learn More button */}
                        {integration.intercom_guide_id
                          ? <Tooltip
                              description="Learn more"
                              placement="top"
                            >
                              <Button 
                                type="button" 
                                className="btn btn-primary btn-sm mr-1" 
                                data-toggle="tooltip" 
                                data-placement="top" 
                                data-title="Tooltip on top"
                                onClick={() => {
                                  window.Intercom('showArticle', integration.intercom_guide_id);
                                }}
                              >
                                <Icon 
                                  className="fas fa-info-circle" 
                                />
                              </Button>
                            </Tooltip>
                          :
                            <></>
                        }
                        
                        {/* Talk to sales button */}
                        {integration.talk_to_sales
                          ? <Tooltip
                              description="Talk to Sales"
                              placement="top"
                            >
                              <Button 
                                type="button" 
                                className="btn btn-primary btn-sm mr-1"
                                onClick={() => {
                                  window.Intercom('showNewMessage', `I'm interested in ${integration.name}, please tell me more...`);
                                }}
                              > 
                                <Icon 
                                  className="far fa-comment-dots" 
                                />
                              </Button>
                            </Tooltip>
                          : 
                            <></>
                        }
                        
                        {/* Go to website button */}
                        {integration.details_url
                          ? <Tooltip
                              description="Go to website"
                              placement="top"
                            >
                              <Button 
                                type="button" 
                                className="btn btn-primary btn-sm mr-1"
                                onClick={() => {
                                  window.open(
                                    integration.details_url,
                                    '_blank'
                                  );
                                }}
                              > 
                                <Icon 
                                  className="fas fa-external-link-alt"    
                                />
                              </Button>
                            </Tooltip>
                          : 
                            <></>
                        }
                        </div>
                        {/* <div className="ellipsis-placeholder">
                          <Icon className="fas fa-ellipsis-h" />
                        </div> */}
                      </div>
                    </Card.Title>
                    <Card.Body className="p-0 d-flex flex-column justify-content-between">
                      <div>
                        <div className="integration-image-container d-flex justify-content-center align-items-center">
                          {integration.logo && <Card.Img className="p-4 integration-image" variant="top" src={integration.logo} />}
                        </div>
                          {integration.description && <p className="m-4 text-center card-description">{integration.description}</p>}
                      </div>

                      { // show the connect button to set up integration autorisation
                        ((integration.client_id && integration.class_name === "Brivo") || integration.class_name === "Uptick")
                          ?
                            <Button
                              className="my-2 mx-4"
                              variant="outline-primary"
                              onClick={() => {
                                const integrationConnect = {
                                  integration_id: integration.id,
                                  enterprise_id: enterprise.id,
                                  originalPathname: window.location.pathname
                                };
                              
                                // Reset integration oauth session storage if it exists - written to be generic so can be used for other integrations in the future
                                if (sessionStorage.getItem("integrationConnect") !== null) {
                                  sessionStorage.removeItem("integrationConnect");
                                }
  
                                sessionStorage.setItem("integrationConnect", JSON.stringify(integrationConnect));
                                if (integration.class_name === "Brivo") {
                                  // redirect to brivo oauth
                                  window.location = `https://auth.brivo.com/oauth/authorize?response_type=code&client_id=${integration.client_id}&redirect_uri=${integration.redirect_uri}`;
                                } else if (integration.class_name === "Uptick") {
                                  // show modal to enter auth details
                                  this.setState({
                                    currentIntegrationId: integration.id,
                                    addUptickAuthModalShow: true
                                  });  
                                }
                              }}
                            >
                              Connect
                            </Button>
                          : 
                            <div className="my-2 mx-4" style={{ height: "35px" }}></div> // space for when there is no button
                      }
                        
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        }
        <AddBrivoApiKeyModal 
          show={this.state.addBrivoApiKeyModalShow}
          onHide={addBrivoApiKeyModalClose}
          enterpriseId={enterprise.id}
          integrationId={this.state.currentIntegrationId}
        />
        <AddUptickAuthModal
          show={this.state.addUptickAuthModalShow}
          onHide={addUptickAuthModalClose}
          enterpriseId={enterprise.id}
          integrationId={this.state.currentIntegrationId}
        />
      </div>
    )
    
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    enabledIntegrations: getEnabledIntegrations(state, props),
    availableIntegrations: getAvailableIntegrations(state, props),
  };
};
export default connect(mapStateToProps)(Integrations);