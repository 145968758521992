const initialState = {
  apps: [],
};

export default function developers(state = initialState, action) {
  switch (action.type) {

    case "LOAD_DEVELOPER_APPS_START":
      return {
        ...state,
      };

    case "LOAD_DEVELOPER_APPS_SUCCESS":
      return {
        ...state,
        apps: action.data ? action.data : [],
      }

    case "LOAD_DEVELOPER_APPS_FAILED":
      return {
        ...state,
      };

    case "LOAD_DEVELOPER_APPS_CANCEL":
      return {
        ...state,
      };

    default:
      return state;
  }
}