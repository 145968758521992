import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ButtonToolbar } from "react-bootstrap";

import SiteView from "components/Site/SiteView/";

import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import ReactTable from "react-table";
import "react-table/react-table.css";

// import _ from "lodash";

// Bootstrap style for ReactTable
// import "components/Enterprise/Reports/Common/DataTableBootstrap.scss";

import { loadSiteViewLiveRefreshCancel } from "services/redux/actions";

import { NA } from "services/redux/selectors";
import { getExceptionReport } from "services/redux/selectors/reports/exception";

import {
  getSiteById,
  getSites,
  getGroupPathFromSite,
  getGroups
} from "services/redux/selectors";
import { getColumns } from "services/redux/selectors/reports/common/";

import SiteNameCell from "components/Common/DataTable/SiteNameCell/";

/**
 * Name / Address / Postcode / Site Ref / Transmitter Ref
 */
class DataTable extends React.Component {
  state = {
    selectedSite: null,
    bundle_id: null
  };
  onSelectSite = site => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");

      this.setState({
        selectedSite: site,
        bundle_id: null
      });

      // Scroll type column into view (doesn't work very well)
      // document
      //   .querySelector(".header-type")
      //   .scrollIntoView({ behavior: "smooth", inline: "start" });

      // Only set bundle id if selecting for the first time
      // const bundle_id =
      //   selectedSite === null ? this.props.router.params.bundle_id || "" : "";

      // this.props.history.push(
      //   `/enterprise/${enterprise_id}/${group_id}/${site.id}/${bundle_id}`
      // );
    } else {
      document.body.classList.remove("sidepane-active");

      this.props.dispatch(
        loadSiteViewLiveRefreshCancel()
      );

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        // this.props.history.push(`/enterprise/${enterprise_id}/${group_id}`);

        this.setState({
          selectedSite: null
        });
      }, 600);
    }
  };
  render() {
    const { sites, groups, columns, data } = this.props;

    const { selectedSite } = this.state;

    const tableData = data.sites; //.map(id => getSiteById(sites, id));

    // if (this.props.loading !== false) return null;

    // const pageSize = data.length > 20 ? 20 : 10;
    const defaultPageSize = 20;

    return (
      <>
        <ButtonToolbar className="p-2">
          <ExportDropdown
            disabled={sites.length === 0}
            reportTitle={"Exception Report"}
            dataResolver={() => {
              const data = this.reactTable.getResolvedState().sortedData;

              let result = data.map(row => {
                // console.log(row);
                return [
                  row["custom-id"] || "",
                  row["site-name"] || "",
                  row["group"] || "",
                  row["site-ref"] || ""
                  // row["address"],
                  // row["postcode"],
                  // row["transmitter-ref"]
                ];
              });
              result.unshift([
                "Custom ID",
                "Site Name",
                "Group",
                "Site Ref"
                // "Address",
                // "Postcode",
                // "Transmitter Ref"
              ]);

              return result;
            }}
          />

          {/* <Button
            variant="primary"
            onClick={() => {
              const data = this.reactTable.getResolvedState().sortedData;

              let csvResult = data.map(row => {
                // console.log(row);
                return [
                  row["name"],
                  row["address"],
                  row["postcode"],
                  row["site-ref"],
                  row["transmitter-ref"]
                ];
              });
              csvResult.unshift([
                "Name",
                "Address",
                "Postcode",
                "Site Ref",
                "Transmitter Ref"
              ]);
              // console.log(csvResult);
              csv.exportCSV(csvResult);
            }}>
            Export CSV
          </Button> */}
        </ButtonToolbar>
        <ReactTable
          ref={r => (this.reactTable = r)}
          data={tableData}
          filterable
          defaultPageSize={defaultPageSize}
          defaultFilterMethod={(filter, row) => {
            return (
              String(row[filter.id])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            );
          }}
          columns={[
            {
              ...columns["CUSTOM_ID"],
              id: "custom-id",
              accessor: row => {
                return row.custom_id || row.custom_site_id || NA();
              }
            },
            {
              Header: columns["NAME"].Header,
              minWidth: columns["NAME"].minWidth,
              id: "site-name",
              accessor: "name",
              Cell: row => {
                return (
                  <>
                    <SiteNameCell
                      {...row}
                      selectSite={() => {
                        this.onSelectSite(row.original);
                      }}
                    />
                  </>
                );
              }
            },

            {
              ...columns["GROUP"],
              width: 200,
              // Header: columns["GROUP"].Header,
              id: "group",
              accessor: row => {
                const site = getSiteById(sites, row.id);

                if (!site) return null;
                return getGroupPathFromSite(groups, site);
              }
            },

            {
              // ...columns["SITE_REF"],
              Header: columns["SITE_REF"].Header,
              id: "site-ref",
              accessor: row => {
                const site = getSiteById(sites, row.id);

                return site.site_ref || NA();
              }
            }

            // {
            //   Header: "Address",
            //   id: "address",
            //   accessor: "address1"
            // },
            // {
            //   Header: "Postcode",
            //   id: "postcode",
            //   accessor: "postcode"
            // },
            // {
            //   Header: "Transmitter Ref",
            //   id: "transmitter-ref",
            //   accessor: "sudo_site.transmitter_ref"
            // }
          ]}
        />
        <SiteView
          site={selectedSite}
          selectSite={this.onSelectSite}
          // Using controlled state for bundle_id
          bundle_id={this.state.bundle_id}
          onSelectBundle={bundle_id => {
            // Bundle selected
            if (bundle_id) {
              this.setState({ bundle_id });
            }
            // Invalid bundle id, deselect
            else {
              this.setState({ bundle_id: null });
            }
          }}
        />
      </>
    );
  }
}
DataTable.propTypes = {
  data: PropTypes.object.isRequired
};
const mapStateToProps = (state, props) => {
  const exceptionReport = getExceptionReport(state, props);
  return {
    loading: exceptionReport.loading,
    sites: getSites(state),
    data: exceptionReport.data,
    groups: getGroups(state),
    columns: getColumns(state, props)
  };
};
export default connect(mapStateToProps)(DataTable);
