import React from "react";
import SiteNameCell from "components/Common/DataTable/SiteNameCell/";
import AbnormalEvent from "components/Common/DataTable/AbnormalEvent/";

import { getNotExpected } from "services/redux/selectors/reports/scheduled";

import get from "lodash/get";

export function NameColumn({ columns, onSelectSite }) {
  const column = columns["NAME"];

  return {
    ...column,
    onSelectSite,
    Cell: row => {
      const abnormalEventTooltip = getNotExpected(
        get(row.original, "occurrences.0")
      ) ? (
        <AbnormalEvent />
      ) : null;

      return (
        <>
          <SiteNameCell
            {...row}
            selectSite={() => {
              onSelectSite(row.original);
            }}
          />
          <span>{abnormalEventTooltip}</span>
        </>
      );
    }
  };
}
