import React from 'react';
import { connect } from "react-redux";

import { useFlatfile, FlatfileProvider, Space, Sheet } from "@flatfile/react";
import { blueprint } from "./blueprint";

import DataTable from "./DataTable";

import "./FlatfileDataTableWrapper.scss";
import { Workbook } from '../../../../node_modules/@flatfile/react/dist/index';

const FLATFILE_PUBLISHABLE_KEY = "pk_592e2a8929df4a689976a85bc8ec3b12";

const FlatfilePortal = ({user, companyId, companyName, dataTableProps}) => {
  const { openPortal } = useFlatfile();

  return (
    <>
      <Space
        config={{
          name: companyName,
          namespace: "portal",
          metadata: {
            sidebarConfig: {
              showSidebar: false,
            },
            userInfo: {
              userId: user.id,
              name: user.firstName + ' ' + user.lastName,
              companyId: companyId,
              companyName: companyName,
            },
          },
        }}
      >
        <Workbook
          config={{
            name: "Upload Sites",
            metadata: {
            },
          }}
        >
          <Sheet
            config={blueprint}
            onSubmit={async ({ sheet }) => {
              const data = await sheet.allData();
            }}
          />
        </Workbook>
        <DataTable {...dataTableProps} openPortal={openPortal} companyId={companyId} />
      </Space>
    </>
  );
};

const FlatfileDataTableWrapper = ({user, companyId, companyName, dataTableProps}) => {
  if (user && companyId && companyName) {
    return <FlatfileProvider
      publishableKey={FLATFILE_PUBLISHABLE_KEY}
    >
      <FlatfilePortal
        user={user}
        companyId={companyId}
        companyName={companyName}
        dataTableProps={dataTableProps}/>
    </FlatfileProvider>
  } else {
    return null;
  }
};

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(FlatfileDataTableWrapper);