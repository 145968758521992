import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

// import { globalSearchToggle } from "services/redux/actions";

import AutocompleteInput from "./AutocompleteInput";

class SearchBar extends React.Component {
  render() {
    const { open, enterprise } = this.props;

    // Don't allow search when there is no enterprise
    if (!enterprise) {
      return null;
    }

    return (
      <div className={`search-wrap ${open ? "open" : ""}`} id="search-wrap">
        <div className="search-bar">
          <AutocompleteInput />
          <div
            className="btn nav-link close-search toggle-search"
            // onClick={() => this.props.dispatch(globalSearchToggle())}
          >
            <i className="fas fa-chevron-up" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    open: state.app.search.open,
    enterprise: getEnterpriseFromRoute(state, props)
  };
};
export default withRouter(connect(mapStateToProps)(SearchBar));
