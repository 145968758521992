import { watchLoadSOPCompletedReport } from "./sopReports";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadSOPCompletedReport(),
    ],
  );
}