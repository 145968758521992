import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestChangeProfileSuccess,
  requestChangeProfileFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* watchChangeProfile() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_CHANGE_PROFILE_START", function*(action) {
    yield race({
      task: call(requestChangeProfile, action),
      cancel: take([
        "REQUEST_CHANGE_PROFILE_CANCEL",
        "REQUEST_CHANGE_PROFILE_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

export function* requestChangeProfile(action) {
  const { firstName, lastName, locale, timezone } = action;
  if (!firstName) {
    throw new Error("Invalid value supplied for first name: " + firstName);
  }
  if (!lastName) {
    throw new Error("Invalid value supplied for last name: " + lastName);
  }
  if (!locale) {
    throw new Error("Invalid value supplied for password locale: " + locale);
  }
  if (!timezone) {
    throw new Error(
      "Invalid value supplied for password timezone: " + timezone
    );
  }

  try {
    const response = yield call(conxtdOut.post, "/users/edit", {
      firstName,
      lastName,
      locale,
      timezone
    });

    yield put(requestChangeProfileSuccess({ user: response.data }));
  } catch (error) {
    yield put(requestChangeProfileFailed({ error: error.message, ...action }));
  }
}
