import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";

import { ButtonToolbar, Row, Col } from "react-bootstrap";

import { loadSiteModeEventsStart } from "services/redux/actions";

import ViewAs from "components/Common/ViewAs/";

import Timeline from "./Timeline";
import DataTable from "./DataTable";

class ModeEvents extends React.Component {
  state = {
    dispatched: false,
    viewAs: "timeline"
  };

  loadModeEvents = (props) => {
    const { id } = props;
    this.setState({ dispatched: true }, () => {
      this.props.dispatch(
        loadSiteModeEventsStart({
          id: id
        })
      );
    });
  };

  componentDidMount() {
    this.loadModeEvents(this.props);
  }

  changeViewAs = viewAs => {
    this.setState({ viewAs });
  };

  render() {
    const { id, data, loading, t, site } = this.props;
    const { viewAs } = this.state;

    const ViewComponent = viewAs === "timeline" ? Timeline : DataTable;

    if (loading) {
      return (
        <>
          <Loading center />
        </>
      );
    } else if (data.length === 0) {
      return (
        <>
          <Row>
            <Col style={{ textAlign: "center", fontSize: "16px" }}>
              There are no Engineer Events for this session
            </Col>
          </Row>
        </>
      );
    }
    else {
      return (
        <>
          <Row className="my-2" style={{ paddingRight: 10 }}>
            <Col md={12} className="d-flex">
              <ButtonToolbar
                style={{
                  margin: "auto 0 auto auto",
                }}>
                <ViewAs
                  options={[
                    {
                      key: "timeline",
                      Icon: <Icon className="fas fa-bars" />,
                      description: t("timeline_button_tooltip")
                    },
                    {
                      key: "datatable",
                      Icon: <Icon className="fas fa-table" />,
                      description: t("table_button_tooltip")
                    }
                  ]}
                  viewAs={viewAs}
                  changeViewAs={this.changeViewAs}
                />
              </ButtonToolbar>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <ViewComponent id={id} data={data} loading={loading} t={t} site={site} />
            </Col>
          </Row>
        </>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    data: state.sites.current.mode_events.data,
    loading: state.sites.current.mode_events.loading,
  };
};

export default withT(
  connect(mapStateToProps)(ModeEvents),
  "site_overview.side_pane.mode_view"
)