/**
 * 09/21 - open close by area
 * Data table custom row component to add expanded area states
 * (sub rows)
 */

import React from "react";

import get from "lodash/get";

import { labelContainsFilter } from "services/redux/selectors/reports/scheduled";

import { getExpanded } from "services/redux/selectors/reports/openclosebyarea";

import ChildRowColumns from "./ChildRowColumns";

import moment from "services/locale/momentInit.js";

function TrGroupComponent(component) {
  const { 
    sudoState,
    filtered,
  } = component;

  const expandedContent = getExpanded(sudoState)
    ? sudoState.area_states.map((area_state, index) => {
      let rowsShown = -1;

      const getStyle = index =>
        get(component, `children.0.props.children.${index}.props.style`);

      return (
        <div
          className="rt-tr sub-row"
          role="row"
          key={sudoState.enterprise_site_id + " " + index}>
          {ChildRowColumns.map(cell => {

            // standard way of applying the underline/bold decoration to filtered event type column in the sub rows
            let underline = true; 
            const filter = filtered.find(({ id }) => id === "area-state");
            // Filter for this column is active
            if (filter) {
              underline = false;
              underline = labelContainsFilter(area_state.area_state_event_meta.label, filter);
            }

            // apply underline/bold decoration to other filtered columns in the sub-rows
            let areaContainsFilter = false;
            const areaFilter = filtered.find(({ id }) => id === "area");
            if (areaFilter) {
              areaContainsFilter = labelContainsFilter(area_state.area || "", areaFilter);
            }
            let areaNameContainsFilter = false;
            const areaNameFilter = filtered.find(({ id }) => id === "area-name");
            if (areaNameFilter) {
              areaNameContainsFilter = labelContainsFilter(area_state.area_name || "", areaNameFilter);
            }
            let lastStateEventContainsFilter = false;
            const lastStateEventFilter = filtered.find(({ id }) => id === "last-state-event");
            if (lastStateEventFilter) {
              lastStateEventContainsFilter = labelContainsFilter(area_state.alarm_text || "", lastStateEventFilter);
            }
            let lastStateChangeContainsFilter = false;
            const lastStateChangeFilter = filtered.find(({ id }) => id === "last-state-change");
            if (lastStateChangeFilter) {
              lastStateChangeContainsFilter = labelContainsFilter(moment(area_state.event_time).format("DD/MM/YYYY HH:mm") || "", lastStateChangeFilter);
            }

            rowsShown++;

            const content =
              (cell.content &&
                cell.content({
                  sudoState,
                  area_state,
                  index,
                  underline,
                  areaContainsFilter,
                  areaNameContainsFilter,
                  lastStateEventContainsFilter,
                  lastStateChangeContainsFilter,
                  focus: true
                })) ||
              null;

            return (
              <div
                className={`rt-td ${cell.className || ""}`}
                role="gridcell"
                style={{
                  ...getStyle(rowsShown),
                }}
                key={sudoState.enterprise_site_id + " " + index + " " + cell.key}>
                {content}
              </div>
            );
          })}
        </div>
      );
    })
  : null;

  const expandedClass = component.expanded === 1 ? "expanded" : "";

  return (
    <>
      <div
        className={`rt-tr-group ${expandedClass}`}
        role="rowgroup"
        key={sudoState && sudoState.enterprise_site_id}>
        {component.children}
        {expandedContent}
      </div>
    </>
  );
}

export default TrGroupComponent;