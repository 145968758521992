/**
 * Site integrations loader for camera events
 *
 * Loads meta data for integrations - mainly auth tokens
 */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  loadSiteIntegrationsSuccess,
  loadSiteIntegrationsFailed,
  loadSiteIntegrationsStart,
  loadSiteIntegrationsCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import { mockEnabled } from "services/redux/selectors/";

import conxtdOut from "apis/conxtdOut";

function* loadIntegrations({ site_id }) {
  try {
    const result = yield mockEnabled()
      ? call(() => {
          return {
            data: [
              {
                integration_name: "Xanview",
                logo: "86_tether_logo.png",
                integration_id: 2,
                auth_token: null,
                active_on_site: false,
                camera_integration_site: null,
                key: "6e0d66c3-f008-404c-8f7b-3eab97d82ec7"
              }
            ]
          };
        })
      : call(conxtdOut.get, `/integrations/index/${site_id}`);

    return result;
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Integration watcher saga
 */
export function* loadIntegrationsWatcher() {
  yield actionWatcher({
    actions: {
      start: loadSiteIntegrationsStart,
      failed: loadSiteIntegrationsFailed,
      success: loadSiteIntegrationsSuccess,
      cancel: loadSiteIntegrationsCancel
    },
    saga: loadIntegrations
  });
}

/**
 * Link loading of site view to integrations
 * but only if there are camera events available
 */
export function* linksToSiteView() {
  yield takeLatest("LOAD_SITE_VIEW_SUCCESS", function*(action) {
    // const hasIntegration = action.site.alarmBundles.some(bundle =>
    //   bundle.events.some(event => event.camera_events.length > 0)
    // );

    // if (hasIntegration) {
    const site_id = action.startAction.id;

    yield put(loadSiteIntegrationsStart({ site_id }));
    // }
  });
}

/**
 * Link adding a camera to a site to refresh integrations
 */
export function* linksToAddCamera() {
  yield takeLatest("REQUEST_ADD_SITE_CAMERA_SUCCESS", function*({
    startAction
  }) {
    yield put(loadSiteIntegrationsStart({ site_id: startAction.site_id }));
  });
}

/**
 * Link adding a location to a site to refresh integrations
 */
export function* linksToAddLocation() {
  yield takeLatest("REQUEST_ADD_INTEGRATION_LOCATION_SUCCESS", function*({
    startAction
  }) {
    yield put(loadSiteIntegrationsStart({ site_id: startAction.site_id }));
  });
}

/**
 * Link adding a integration to refreshing integrations
 */
export function* linksToAddEagleEyeIntegration() {
  yield takeLatest("REQUEST_EAGLE_EYE_CONNECT_SUCCESS", function*({
    startAction
  }) {
    yield put(loadSiteIntegrationsStart({ site_id: startAction.site_id }));
  });
}
