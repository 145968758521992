// import { call, take, race, put, takeLatest } from "redux-saga/effects";
import { call, take, race, takeLatest } from "redux-saga/effects";

import {
  loadUserManagementAccessStart,
  loadUserManagementAccessSuccess,
  loadUserManagementAccessFailed
} from "services/redux/actions";

import { liveRefreshFor } from "services/redux/sagas/common";

// import EnterpriseManagerAccessMock from "./EnterpriseManager.mock.json";
import conxtdOut from "apis/conxtdOut";

import { toGroupsBackgroundLoader } from "services/redux/sagas/links";

export function* watchLoadAccessBackground() {
  // while (true) {
  // const action = yield take("LOAD_GROUP_OVERVIEW_START");

  yield takeLatest(
    // Requires groups
    ["LOAD_USER_MANAGEMENT_ACCESS_START"],
    toGroupsBackgroundLoader
  );

  // Take latest action and cancel previous
  yield takeLatest("LOAD_USER_MANAGEMENT_ACCESS_START", function*(action) {
    yield race({
      task: call(loadAccessBackground, action),
      cancel: take([
        "LOAD_USER_MANAGEMENT_ACCESS_CANCEL",
        // "LOAD_GROUP_OVERVIEW_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
  // }
}

/**
 * Load user manager access
 *
 * @param {*} action requires enterprise_id
 */
export function* loadAccessBackground(action) {
  if (!action.enterprise_id)
    throw new Error("enterprise_id is required for loading user management");

  const { enterprise_id, interval, refresh, retry } = action;

  const params = {
    startAction: loadUserManagementAccessStart,
    startActionParams: action,
    successAction: loadUserManagementAccessSuccess,
    successActionParams: {
      enterprise_id
    },
    failedAction: loadUserManagementAccessFailed,
    apiCall: call(conxtdOut.get, `/Enterprises/view/${enterprise_id}`),
    // apiCall: call(() => {
    //   return { data: EnterpriseManagerAccessMock };
    // }),
    interval,
    key: "access",
    debounce: false,
    refresh: refresh || false,
    retry: retry || false
  };

  yield liveRefreshFor(params);
}
