/**
 * Curent user reducer
 *
 * - Self modifying actions; change password/profile
 * - Logged out actions; reset password, accept invite
 * - Load current user's group user management accept
 *
 */

import conxtdOut from "apis/conxtdOut";

const initialState = {
  loading: null,
  error: {},
  actions: {
    changePassword: {
      success: null,
      loading: null,
      error: {},
    },
    resetPassword: {
      success: null,
      loading: null,
      error: {},
    },
    changeProfile: {
      success: null,
      loading: null,
    },
    acceptInvite: {
      success: null,
      loading: null,
      error: {},
    },
    appIdentityCheck: {
      success: null,
      loading: null,
      error: {},
    },
  },
  // The current user's access to the current enterprise
  access: {
    loading: null,
  },
  type: "default",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_USER_ACCEPT_INVITE_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptInvite: {
            ...initialState.actions.acceptInvite,
            loading: true,
          },
        },
      };
    case "REQUEST_USER_ACCEPT_INVITE_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptInvite: {
            ...initialState.actions.acceptInvite,
            loading: false,
          },
        },
      };
    case "REQUEST_USER_ACCEPT_INVITE_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptInvite: {
            ...initialState.actions.acceptInvite,
            loading: false,
            error: action.error,
          },
        },
      };
    // App Login Check
    case "REQUEST_APP_IDENTITY_CHECK_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          appIdentityCheck: {
            ...initialState.actions.appIdentityCheck,
            loading: true,
          },
        },
      };
    case "REQUEST_APP_IDENTITY_CHECK_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          appIdentityCheck: {
            ...initialState.actions.appIdentityCheck,
            loading: false,
          },
        },
      };
    case "REQUEST_APP_IDENTITY_CHECK_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          appIdentityCheck: {
            ...initialState.actions.appIdentityCheck,
            loading: false,
            error: action.error,
          },
        },
      };
    /** App login Check end */
    case "REQUEST_USER_CREATE_ACCOUNT_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptInvite: {
            ...initialState.actions.acceptInvite,
            loading: true,
          },
        },
      };
    case "REQUEST_USER_CREATE_ACCOUNT_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptInvite: {
            ...initialState.actions.acceptInvite,
            loading: false,
            error: action.error,
          },
        },
      };
    case "REQUEST_USER_CREATE_ACCOUNT_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptInvite: {
            ...initialState.actions.acceptInvite,
            loading: false,
            success: true,
          },
        },
      };
    case "LOAD_USER_MANAGEMENT_ACCESS_RESET":
      return {
        ...state,
        access: {
          loading: null,
        },
      };
    case "LOAD_USER_MANAGEMENT_ACCESS_START":
      return {
        ...state,
        access: {
          loading: true,
        },
      };
    case "LOAD_USER_MANAGEMENT_ACCESS_FAILED":
      return {
        ...state,
        access: {
          loading: false,
        },
      };
    case "LOAD_USER_MANAGEMENT_ACCESS_CANCEL":
      return {
        ...state,
        access: {
          loading: null,
        },
      };
    case "LOAD_USER_MANAGEMENT_ACCESS_SUCCESS":
      return {
        ...state,
        access: {
          loading: false,
          ...action.access,
        },
      };
    case "REQUEST_CHANGE_PROFILE_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          changeProfile: {
            ...state.actions.changeProfile,
            loading: true,
          },
        },
      };
    case "REQUEST_CHANGE_PROFILE_SUCCESS":
      const user = {
        ...state,
        ...action.user,
        actions: {
          ...state.actions,
          changeProfile: {
            ...state.actions.changeProfile,
            loading: false,
            success: true,
          },
        },
      };
      conxtdOut.updateSession({
        ...user,
        // Reset actions
        actions: initialState.actions,
      });

      return user;
    case "REQUEST_CHANGE_PROFILE_NOTIFIED":
      return {
        ...state,
        actions: {
          ...state.actions,
          changeProfile: {
            ...state.actions.changeProfile,
            success: null,
          },
        },
      };
    // PROFILE CHANGE PASSWORD
    case "REQUEST_CHANGE_PASSWORD_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          changePassword: {
            ...state.actions.changePassword,
            loading: true,
          },
        },
      };
    case "REQUEST_CHANGE_PASSWORD_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          changePassword: {
            ...state.actions.changePassword,
            success: true,
            loading: false,
          },
        },
      };
    case "REQUEST_CHANGE_PASSWORD_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          changePassword: {
            ...state.actions.changePassword,
            error: action.error,
            loading: false,
          },
        },
      };
    case "REQUEST_CHANGE_PASSWORD_NOTIFIED":
      return {
        ...state,
        actions: {
          ...state.actions,
          changePassword: {
            ...state.actions.changePassword,
            success: null,
            error: {},
          },
        },
      };

    // RESET PASSWORD SEND EMAIL
    case "REQUEST_USER_RESET_PASSWORD_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            ...state.actions.resetPassword,
            loading: true,
          },
        },
      };
    case "REQUEST_USER_RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            ...state.actions.resetPassword,
            success: true,
            loading: false,
          },
        },
      };
    case "REQUEST_USER_RESET_PASSWORD_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            ...state.actions.resetPassword,
            error: action.error,
            loading: false,
          },
        },
      };
    case "REQUEST_USER_RESET_PASSWORD_NOTIFIED":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            ...state.actions.resetPassword,
            success: null,
            error: {},
          },
        },
      };
    // RESET PASSWORD
    case "SEND_USER_RESET_PASSWORD_EMAIL_START":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            // Reset state
            ...initialState.actions.resetPassword,
            loading: true,
          },
        },
      };
    case "SEND_USER_RESET_PASSWORD_EMAIL_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            ...state.actions.resetPassword,
            success: true,
            loading: false,
          },
        },
      };
    case "SEND_USER_RESET_PASSWORD_EMAIL_FAILED":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            ...state.actions.resetPassword,
            error: action.error,
            loading: false,
          },
        },
      };
    case "SEND_USER_RESET_PASSWORD_EMAIL_NOTIFIED":
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            ...state.actions.resetPassword,
            success: null,
            error: {},
          },
        },
      };

    case "LOGIN_START":
      return {
        ...state,
        loading: true,
        error: {},
      };
    case "LOGIN_SUCCESS":
      if (
        window.location.hostname === "app.conxtd.com" ||
        window.location.hostname === "pro-staging.conxtd.com"
      ) {
        if (!window.OneSignal.__initAlreadyCalled) {
          window.InitNotifications(action.user.sessionId);
        } else {
          window.$(".onesignal-bell-container").show();
        }
      }

      return {
        ...state,
        ...action.user,
        loading: false,
        // Restore user type from localstorage
        type: localStorage.getItem("userType") || "default",
        error: {},
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "LOGOUT_REQUEST":
      if (window.location.hostname === "app.conxtd.com") {
        // Hide onesignal bell on logout
        window.$(".onesignal-bell-container").hide();
        // Remove all player ids etc?
        // localStorage.clear();
      }
      return initialState;
    default:
      return state;
  }
};

export default user;