/**
 * 08/21 to support hourly-type report
 */
import { createSelector } from "reselect";

import { getReportType } from "services/redux/selectors/reports/router";
import { 
  getScheduledCombinedHourlyValues, 
  getScheduledSelectedChartSiteId,
  getScheduledSelectedChartSiteHourlyValues, 
  getScheduledSelectedChartSiteName 
} from "../scheduled/index";

/**
 * @returns {data: Array}
 */
 export const getCombinedData = createSelector(
  [getReportType],
  (reportType) => {
    return reportType === "peoplecounthourly"
            ? getScheduledCombinedHourlyValues
            : () => {  
                return { data: [] };
              };
  }
);

/**
 * @returns {id: string}
 */
 export const getSelectedChartSiteId = createSelector(
  [getReportType],
  (reportType) => {
    return reportType === "peoplecounthourly"
            ? getScheduledSelectedChartSiteId
            : () => {  
                return { id: null };
              };
  }
);

/**
 * @returns {data: Array}
 */
 export const getSelectedChartSiteData = createSelector(
  [getReportType],
  (reportType) => {
    return reportType === "peoplecounthourly"
            ? getScheduledSelectedChartSiteHourlyValues
            : () => {  
                return { data: [] };
              };
  }
);

/**
 * @returns {siteName: string}
 */
 export const getSelectedChartSiteName = createSelector(
  [getReportType],
  (reportType) => {
    return reportType === "peoplecounthourly"
            ? getScheduledSelectedChartSiteName
            : () => {  
                return { siteName: "" };
              };
  }
);
