import React from "react";
import { Row } from "react-bootstrap";

import _ from "lodash";

import ordinaljs from "ordinal-js";

import moment from "services/locale/momentInit.js";

import AreaView from "./AreaView";

import "./OpenClose.scss";

export default function OpenCloseCard({ data, open_close_state, area_names, timezone, site, index, type }) {

    const site_state = {
        A: {
            colour: "#28498A",
            icon: type === 'full' ? "lock" : "door",
        },
        R: {
            colour: "#3397DB",
            icon: type === 'full' ? "unlock" : "door",
        },
    }

    let opclst = false;

    if (type === 'full' && _.isEmpty(_.keys(data))) {
        opclst = open_close_state;
    }

    if (opclst !== false) {
        const { icon, colour } = site_state[opclst === 'C' ? 'A' : 'R'];

        return (
            <div>
                <Row>
                    <span className="status-title">Site Status</span>
                </Row>
                <AreaView
                    icon={icon}
                    status={open_close_state === 'C' ? 'Closed' : 'Open'}
                    colour={colour}
                    open_close_state={opclst}
                />
                <hr />
            </div>
        )
    }

    const { icon, colour } = site_state[data.state];

    const alarmTime = new moment(data.recvd);

    const day = alarmTime.format("D");
    
    let ordinal = ordinaljs.ordinalSuffix(Number(day));

    const status = data.state === 'A' ? 'Closed' : 'Open';

    const area = data.area === null ? '-' : data.area;

    if (type === 'full') {
        return (
            <>
                <div>
                    <Row>
                        <span className="status-title">Site Status</span>
                    </Row>
                    <AreaView
                        icon={icon}
                        colour={colour}
                        area={area}
                        status={status}
                        day={day}
                        ordinal={ordinal}
                        alarmDate={alarmTime.format("MMM YYYY")}
                        alarmTime={alarmTime.format("HH:mm")}
                        alarmTimeStamp={alarmTime}
                        timezone={timezone}
                        open_close_state={opclst}
                        text={data.mct_alarm_log.text}
                        area_names={area_names}
                    />
                    <hr />
                </div>
            </>
        )
    } else {
        return (
            <>
                <div>
                    <AreaView
                        icon={icon}
                        colour={colour}
                        area={area}
                        status={status}
                        day={day}
                        ordinal={ordinal}
                        alarmDate={alarmTime.format("MMM YYYY")}
                        alarmTime={alarmTime.format("HH:mm")}
                        alarmTimeStamp={alarmTime}
                        timezone={timezone}
                        partial={true}
                        open_close_state={opclst}
                        text={data.mct_alarm_log.text}
                        area_names={area_names}
                    />
                </div>
            </>
        )
    }
}