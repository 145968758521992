import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { sortNumber } from "services/redux/selectors/reports/scheduled";

import moment from "services/locale/momentInit.js";

/**
 * Enterprise Management User Actions DataTable
 *
 **/

 const columns = [
  {
    key: "USER",
    Header: "User",
    id: "user",
    width: 200,
    accessor: data => data.user ? data.user.email : "",
  },
  {
    key: "ACTION",
    Header: "Action",
    id: "action",
    accessor: "action",
  },
  {
    key: "TIME",
    Header: "Time",
    id: "time",
    width: 160,
    accessor: "created",
    Cell: ( {row} ) => {
      const time = new moment(row.time);
      return time.format("HH:mm DD/MM/YYYY");
    },
    sortMethod: (timeA, timeB) => {
      const a = moment(timeA).format("YYYYMMDDHHmm");
      const b = moment(timeB).format("YYYYMMDDHHmm");
      return sortNumber(a, b);
    },
    filterMethod: (filter, row) => {
      const time = new moment(row.time);
      return (
        time.format("HH:mm DD/MM/YYYY")
          .toLowerCase()
          .indexOf(filter.value.toLowerCase()) !== -1
      );
    },
  },
]

class DataTable extends React.Component {

  render() {

    const { userActions, loadingUserActions } = this.props;

    let noDataMsg = "No data available...";

    return (
      <>
        <ReactTable
          ref={r => (this.reactTable = r)}
          columns={columns}
          data={loadingUserActions ? [] : userActions}
          filterable
          defaultFilterMethod={(filter, row) => {
            return (
              String(row[filter.id])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            );
          }}
          defaultPageSize={20}
          noDataText={noDataMsg}
          defaultSorted={[
            {
              id: "time",
              desc: true,
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    userActions: state.enterpriseManager.userActions,
    loadingUserActions: state.enterpriseManager.loadingUserActions,
  };
};
export default connect(mapStateToProps)(DataTable);