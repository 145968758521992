import { all } from "redux-saga/effects";

import { watchAddKeyholder } from "./add";
import { watchRemoveKeyholder } from "./remove";
import { watchMoveKeyholder } from "./move";
import { watchUpdateKeyholder } from "./update";

export function* rootSaga() {
  yield all([
    watchAddKeyholder(),
    watchRemoveKeyholder(),
    watchMoveKeyholder(),
    watchUpdateKeyholder()
  ]);
}
