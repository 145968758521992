import { put } from "redux-saga/effects";

import * as actions from "services/redux/actions";

export { toEventMetaBackgroundLoader } from "./eventMeta/links";

/**
 * Link action to sites background loader
 * @param {action} action
 */
export function* toGroupsBackgroundLoader(action) {
  const { enterprise_id } = action;
  yield put(actions.loadGroupsBackgroundStart({ enterprise_id }));
}

/**
 * Link action to sites background loader
 * @param {action} action
 */
export function* toSitesBackgroundLoader(action) {
  const { enterprise_id, group_id } = action;
  // const groupId = select(getRootGroupIdFromEnterprise(enterprise_id));
  yield put(actions.loadSitesBackgroundStart({ enterprise_id, group_id }));
}
