/**
 * 09/21 - open close by area
 */

import React from "react";
import { connect } from "react-redux";

import { ButtonToolbar, Button, ProgressBar } from "react-bootstrap";

import SiteView from "components/Site/SiteView/";

import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Icon from "components/Icons/Icon";

import TrGroupComponent from "./TrGroupComponent";

import EventCell from "components/Common/DataTable/EventCell/";

import EventFilterPerimeter from "./EventFilterPerimeter";
import EventFilterArea from "./EventFilterArea";

import TimezoneDate from "components/Common/TimezoneDate";

import SiteNameCell from "components/Common/DataTable/SiteNameCell/";

import { getConfig } from "services/redux/selectors/reports/common/";

import GROUPS from "constants/GROUPS";

import "./DataTable.scss";

import _ from "lodash";

import moment from "services/locale/momentInit.js";

import {
  changeDataTableFiltered,
  setOpenCloseByAreaExpandSite,
  resetOpenCloseByAreaExpanded,
} from "services/redux/actions/";

import { loadSiteViewLiveRefreshCancel } from "services/redux/actions";

import {
  NA,
  getOpenCloseByAreaReportTitle,
  getSudoStates,
} from "services/redux/selectors";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import {
  getOpenCloseByAreaReportExpanded,
  getExpanded,
  getExpandedRowsCount,
} from "services/redux/selectors/reports/openclosebyarea";

import { getReportType } from "services/redux/selectors/reports/router";

import {
  getColumns,
  getFiltered
} from "services/redux/selectors/reports/common/";

const today = new moment();

class DataTable extends React.Component {
  state = {
    selectedSite: null,
    bundle_id: null,
  };

  onSelectSite = (site) => {
    const { selectedSite } = this.state;

    // New site selected
    if ((selectedSite === null) || (site && site.id !== selectedSite.id)) {
      document.body.classList.add("sidepane-active");

      this.setState({
        selectedSite: site,
        bundle_id: null,
      });

    } else {
      document.body.classList.remove("sidepane-active");

      this.props.dispatch(
        loadSiteViewLiveRefreshCancel()
      );

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.setState({
          selectedSite: null,
        });
      }, 600);
    }
  };

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = [...this.props.filtered];
    let insertNewFilter = 1;
    let shouldResetExpanded = false;

    // Filter already exists
    if (filtered.length) {
      filtered = filtered
        .map((filter, i) => {
          if (filter["id"] === accessor) {
            insertNewFilter = 0;

            // Filter text value is empty
            if (value === "" || !value.length) {
              // filtered = filtered.slice(i, 1);
              // To be removed (see remove falsey below)
              return false;
            } else {
              return {
                ...filter,
                value
              };
              // filter["value"] = value;
            }
          }
          return filter;
        })
        // Remove falsey values
        .filter(falsey => falsey);
      // Filter existed but will be removed - reset
      //  expanded
      if (filtered.length === 0) {
        shouldResetExpanded = true;
      }
    }

    // Filter doesn't exist - add
    if (insertNewFilter) {
      // filtered.push({ id: accessor, value: value });
      filtered = [...filtered, { id: accessor, value: value }];
      // Introducing a new filter - reset expanded
      shouldResetExpanded = true;
    }

    if (shouldResetExpanded) {
      this.props.dispatch(
        resetOpenCloseByAreaExpanded({
          reportType: this.props.reportType
        })
      );
    }

    this.props.dispatch(
      changeDataTableFiltered({
        filtered,
        reportType: this.props.reportType
      })
    );
  };

  render() {
    const { 
      reportType, 
      columns, 
      sudoStates, 
      expanded, 
      getEventMetaById,
      filtered,
      user,
      config,
    } = this.props;

    const { selectedSite } = this.state;

    if (sudoStates.length === 0) return null;

    const defaultPageSize = 20;

    // how many of the expandable rows have been expanded by the user
    const expandedRowsCount = getExpandedRowsCount(expanded);

    const areaStateTextFilter = filtered.find(({ id }) => id === "area-state");

    let areaFilterIds = [];
    if (user.environment === "mars" || user.environment === "dockerbackend") {
      areaFilterIds = config.dataTable.areaFiltersAlternates.mars;
    } else if (user.environment === "staging") {
      areaFilterIds = config.dataTable.areaFiltersAlternates.staging;
    } else {
      areaFilterIds = config.dataTable.areaFilters;
    }

    const tableColumns = [
      {
        ...columns["CUSTOM_ID"],
        id: "custom-ref",
        accessor: (row) => row.custom_ref || NA(),
      },
      {
        Header: columns["NAME"].Header,
        minWidth: columns["NAME"].minWidth,
        id: "site-name",
        accessor: (row) => row.name || NA(),
        Cell: (row) => {
          return (
            <>
              <SiteNameCell
                {...row}
                selectSite={() => {
                  this.onSelectSite({
                    ...row.original,
                    id: row.original.enterprise_site_id,
                  });
                }}
              />
            </>
          );
        },
      },
      {
        ...columns["GROUP"],
        id: "group",
        accessor: (row) => {
          const path = _.get(row, "enterprise_groups.0.path");
          return path && path.join(GROUPS.GROUP_PATH_DELIMITER);
        },
      },
      {
        ...columns["SITE_REF"],
        id: "site-ref",
        accessor: (row) => row.site_ref || NA(),
      },
      {
        key: "PERIMETER",
        Header: "Perimeter",
        id: "perimeter",
        width: 120,
        className: "alarm-type",
        headerClassName: "perimeter-header",
        headerStyle: {
          overflow: "visible",
        },
        getEventMetaById,
        accessor: (row) => row.perimeter_event_meta.caption || NA(),
        Cell: ({ original }) => {
          const { perimeter_event_meta } = original;
          return (
            perimeter_event_meta 
              ? <EventCell
                  event={perimeter_event_meta}
                  // focus={row.original.expanded}
                  // onClick={() => {
                  //   config.dataTable.clickableEventFilters && changeFilter(event.label);//!!!haven't implemented clickable event filters yet
                  // }}
                />
              : ""
          )
        },
        Filter: (reportType === "openclosebyareafull") ? EventFilterPerimeter : null,
        filterType: "exact",
      },
      {
        key: "AREA",
        Header: "Area",
        id: "area",
        width: 80, 
        accessor: ({ area_states }) => area_states 
                                        ? area_states.length === 1
                                          ? area_states[0].area
                                          : `Total: ${area_states.length}`
                                        : "Total: 0",
        Cell: ({ original }) => {
          const { area_states } = original;
          if (area_states && (area_states.length === 1)) {
            return <div>{area_states[0].area}</div>
          } else {
            return (
              <div className="d-flex justify-content-between flex-nowrap">
                <span>Total:&nbsp;</span><span>{area_states.length}</span>
              </div>
            )
          }
        },
        filterMethod: (filter, row) => {
          const { area_states } = row["_original"];

          // check if the filtered text exists in the area_states.
          // this works for the rows where there is only one area_state and the data is in the main row,
          // and also works to find if the filtered text exists in the sub-rows
          if (area_states) {
            for (let i=0; i<area_states.length; i++) {
              //make the filtering case-insensitive
              if (area_states[i].area && area_states[i].area.toLowerCase().includes(filter.value.toLowerCase())) {
                return true;
              }
            }
          }
          return false;
        },
      },
      {
        key: "AREANAME",
        Header: "Area Name",
        id: "area-name",
        width: 120,
        accessor: ({ area_states }) => area_states 
                                        ? area_states.length === 1
                                          ? area_states[0].area_name
                                          : ""
                                        : "",
        filterMethod: (filter, row) => {
          const { area_states } = row["_original"];

          // check if the filtered text exists in any of the area_states.
          // this works for the rows where there is only one area_state and the data is in the main row,
          // and also works to find if the filtered text exists in the sub-rows
          if (area_states) {
            for (let i=0; i<area_states.length; i++) {
              //make the filtering case-insensitive
              if (area_states[i].area_name && area_states[i].area_name.toLowerCase().includes(filter.value.toLowerCase())) {
                return true;
              }
            }
          }
          return false;
        },
      },
      {
        key: "STATE",
        Header: "Area State",
        id: "area-state",
        width: 120,
        className: "alarm-type area-state",
        headerClassName: "area-state-header",
        headerStyle: {
          overflow: "visible",
        },
        getEventMetaById, 
        accessor: ({ area_states }) => area_states 
                                        ? area_states.length === 1
                                          ? area_states[0].area_state_event_meta.caption
                                          : ""
                                        : "",
        Cell: ({ original }) => {
          const { area_states } = original;
          if (area_states && (area_states.length === 1)) {
            return (
              area_states[0].area_state_event_meta
                ? <EventCell
                    event={area_states[0].area_state_event_meta}
                    // focus={row.original.expanded}
                    // onClick={() => {
                    //   config.dataTable.clickableEventFilters && changeFilter(event.label);
                    // }}
                  />
                : ""
            ) 
          } else {
            // count number of areas in closed state
            const count = area_states.reduce((n, area_state) => n + (area_state.state === "C" ? 1 : 0), 0);
            const percent = Math.round((count/area_states.length)*100);
            return (
              <div className="inner" style={{padding: "0"}}>
                <ProgressBar now={percent}/> 
              </div>
            );
          }
        },
        areaFilterIds,
        Filter: (reportType === "openclosebyareafull") ? EventFilterArea : null,
        filterType: "exact",
        filterMethod: (filter, row) => {
          const { area_states } = row["_original"];

          // need to check through all the area states in all the rows
          // this works for the rows where there is only one area_state and the data is in the main row,
          // and also works to find the event type in the sub-rows
          if (area_states) {
            for (let i=0; i<area_states.length; i++) {
              if (area_states[i].area_state_event_meta && area_states[i].area_state_event_meta.caption === filter.value) {
                return true;
              }
            }
          }
          return false;
        },
      },
      {
        key: "LASTSTATEEVENT",
        Header: "Last State Event",
        id: "last-state-event",
        width: 130,
        accessor: ({ area_states }) => area_states 
                                        ? area_states.length === 1
                                          ? area_states[0].alarm_text
                                          : ""
                                        : "",
        filterMethod: (filter, row) => {
          const { area_states } = row["_original"];

          // check if the filtered text exists in the area_states.
          // this works for the rows where there is only one area_state and the data is in the main row,
          // and also works to find if the filtered text exists in the sub-rows
          if (area_states) {
            for (let i=0; i<area_states.length; i++) {
              // make the filtering case-insensitive
              // also, the alarm_text sometimes comes through with a lot of spaces between words, but html trims these for display,
              // so remove duplicate spaces so we match on the same string as displayed to the user
              if (area_states[i].alarm_text && area_states[i].alarm_text.replace(/\s+/g,' ').toLowerCase().includes(filter.value.toLowerCase())) {
                return true;
              }
            }
          }
          return false;
        },
      },
      {
        key: "LASTSTATECHANGE",
        Header: "Last State Change",
        id: "last-state-change",
        width: 135,
        accessor: ({ area_states }) => area_states, // pass the area states to the accessor so that they can be used for sorting 
        Cell: ({ original })  => {
          const { area_states, timezone } = original;

          // colour code according to how long ago last event was
          let colorClass = "";
          if (area_states && (area_states.length === 1)) {
            if (area_states[0].event_time) {
              const eventTime = new moment(area_states[0].event_time);
              const daysAgo = -eventTime.diff(today, "day");
              // more than a day but less than a week
              if (daysAgo > 0 && daysAgo <= 6) {
                colorClass = "last-state-change-orange";
              // more than a week
              } else if (daysAgo > 6) {
                colorClass = "last-state-change-red";
              }

              if (!timezone) {
                return (
                  <div className={colorClass}>
                    {eventTime.format("DD/MM/YYYY HH:mm")}
                  </div>
                );
              } else {
                return (
                  <div className={colorClass}>
                    <TimezoneDate date={area_states[0].event_time} timezone={timezone} format="DD/MM/YYYY HH:mm" hideTimezone />
                  </div>
                );
              }
            } else {
              return <div>{NA()}</div>
            }
          } else {
            return <></>
          };
        },
        filterMethod: (filter, row) => {
          const { area_states } = row["_original"];

          // check if the filtered text exists in the area_states.
          // this works for the rows where there is only one area_state and the data is in the main row,
          // and also works to find if the filtered text exists in the sub-rows
          if (area_states) {
            for (let i=0; i<area_states.length; i++) {
              if (area_states[i].event_time && moment(area_states[i].event_time).format("DD/MM/YYYY HH:mm").includes(filter.value.toLowerCase())) {
                return true;
              }
            }
          }
          return false;
        },
        sortMethod: (areaStatesA, areaStatesB) => {
          // sort the event times within the area_states so that the earliest is at index 0
          const sortedA = areaStatesA.sort((areaStateA, areaStateB) => {
            if (areaStateA.event_time > areaStateB.event_time){
              return 1;
            } else if (areaStateA.event_time < areaStateB.event_time){
              return -1;
            } else {
              return 0;
            }
          });
          const sortedB = areaStatesB.sort((areaStateA, areaStateB) => {
            if (areaStateA.event_time > areaStateB.event_time){
              return 1;
            } else if (areaStateA.event_time < areaStateB.event_time){
              return -1;
            } else {
              return 0;
            }
          });

          const earliestEventTimeA = sortedA[0].event_time;
          const earliestEventTimeB = sortedB[0].event_time;

          // sort on the earliest event time for the associated area states for each main row
          if (earliestEventTimeA > earliestEventTimeB){
            return 1;
          } else if (earliestEventTimeA < earliestEventTimeB){
            return -1;
          } else {
            return 0;
          }

        },
      },
      {
        ...columns["EXPAND"],
        Cell: ({ original }) => {
          // const { site_ref } = original;
          const { enterprise_site_id } = original;
          let colExpanded;
          const { area_states } = original;
  
          colExpanded = getExpanded(original);
  
          const expandable = area_states.length > 1;
  
          if (!expandable) {
            return null;
          }
    
          return (
            <Button
              className="expandIconButton"
              variant={`${colExpanded ? "" : "outline-"}primary`}
              size="sm"
              onClick={() => {
                this.props.dispatch(
                  setOpenCloseByAreaExpandSite({
                    enterprise_site_id,
                    reportType,
                    expanded: !colExpanded
                  })
                );
              }}
            >
              {colExpanded ? (
                <Icon className="fas fa-chevron-up" />
              ) : (
                <Icon className="fas fa-chevron-down" />
              )}
            </Button>
          );
        }
      }
    ];

    return (
      <>
        <ButtonToolbar className="p-2">
          <ExportDropdown
            disabled={sudoStates.length === 0}
            reportTitle={this.props.reportTitle}
            dataResolver={() => {
              // For the export
              const data = this.reactTable.getResolvedState().sortedData;

              let result = [];
              
              data.forEach((row) => {
                // iterate through the area_states
                row._original.area_states.forEach((area_state) => {
                  result.push([
                    row["custom-ref"] || NA(),
                    row["site-name"] || NA(),
                    row["group"] || NA(),
                    row["site-ref"] || NA(),
                    row["perimeter"] || NA(),
                    area_state.area || NA(),
                    area_state.area_name || "",
                    area_state.area_state_event_meta.caption || NA(),
                    area_state.alarm_text || "",
                    moment(area_state.event_time).format("DD/MM/YYYY HH:mm") || NA()
                  ]);
                });
              });
              // Add header row to start of array
              result.unshift([
                "Custom ID",
                "Site Name",
                "Group",
                "Site Ref",
                "Perimeter",
                "Area",
                "Area Name",
                "Area State",
                "Last State Event",
                "Last State Change",
              ]);

              return result;
            }}
          />
        </ButtonToolbar>
        <ReactTable
          ref={(r) => (this.reactTable = r)}
          data={sudoStates}
          className={`${expandedRowsCount > 0 ? "focus-expanded" : ""} ${
            areaStateTextFilter ? " event-text-filter-active" : ""
          }`}
          filterable
          filtered={this.props.filtered}
          onFilteredChange={(filtered, column, value) => {
            this.onFilteredChangeCustom(value, column.id || column.accessor);
          }}
          defaultFilterMethod={(filter, row) => {
            return (
              String(row[filter.id])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            );
          }}
          defaultPageSize={defaultPageSize}
          // Extends from scheduled/default column config
          columns={tableColumns}
          defaultSorted={[
            {
              id: "site-name",
              desc: false,
            },
          ]}
          TrGroupComponent={TrGroupComponent} 
          // Override row group props for sub rows
          getTrGroupProps={(state, rowInfo, column) => {
            const props = {
              filtered,
              // eventTextFilter,
              columns,
            };
            if (rowInfo !== undefined) {
              return {
                ...props,
                expanded: getExpanded(rowInfo.original) ? 1 : 0,
                sudoState: rowInfo.original
              };
            }
            return {
              ...props,
              expanded: 0,
              className: ""
            };
          }}
        />
        <SiteView
          site={selectedSite}
          selectSite={this.onSelectSite}
          // Using controlled state for bundle_id
          bundle_id={this.state.bundle_id}
          onSelectBundle={(bundle_id) => {
            // Bundle selected
            if (bundle_id) {
              this.setState({ bundle_id });
            }
            // Invalid bundle id, deselect
            else {
              this.setState({ bundle_id: null });
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props),
    reportTitle: getOpenCloseByAreaReportTitle(state, props),
    user: state.user,
    config: getConfig(state, props),
    sudoStates: getSudoStates(state, props),
    columns: getColumns(state, props),
    expanded: getOpenCloseByAreaReportExpanded(state, props),
    getEventMetaById: createGetEventMetaById(state, props),
    filtered: getFiltered(state, props),
  };
};
export default connect(mapStateToProps)(DataTable);