/**
 * 09/21 - open close by area
 */

import _ from "lodash";
import produce from "immer";

import merge from "deepmerge";

import REPORTS_CONFIG from "constants/Reports/config/";

const initialState = {
  index: {
    loading: null,
    config: REPORTS_CONFIG["openclosebyarea"]["index"] || {},
  },
  openclosebyareafull: {
    nextRefresh: null,
    lastRefresh: null,
    loading: null,
    filter: {
      eventType: {
        show: {},
      },
    },
    data: {
      sudoStates: [],
    },
    DataTable: {
      filtered: [],
    },
    config: REPORTS_CONFIG["openclosebyarea"]["openclosebyareafull"] || {},
    expanded: {},
  },
  openclosedareas: {
    nextRefresh: null,
    lastRefresh: null,
    loading: null,
    filter: {
      eventType: {
        show: {},
      },
    },
    data: {
      sudoStates: [],
    },
    DataTable: {
      filtered: [],
    },
    config: REPORTS_CONFIG["openclosebyarea"]["openclosedareas"] || {},
    expanded: {},
  },
  closedopenareas: {
    nextRefresh: null,
    lastRefresh: null,
    loading: null,
    filter: {
      eventType: {
        show: {},
      },
    },
    data: {
      sudoStates: [],
    },
    DataTable: {
      filtered: [],
    },
    config: REPORTS_CONFIG["openclosebyarea"]["closedopenareas"] || {},
    expanded: {},
  },
};

const openclosebyarea = (state = initialState, action) => {
  switch (action.type) {

    case "LOAD_OPEN_CLOSE_BY_AREA_REPORT_START":
      return produce(state, (draftState) => {
        _.set(draftState, action.reportType + ".loading", true);
      });

    case "LOAD_OPEN_CLOSE_BY_AREA_REPORT_SUCCESS":
      return {
        ...state,
        [action.key]: { 
          data: action[action.key],
          // No config provided by server yet
          config: merge(state[action.key].config, {}),
          loading: false,
          lastRefresh: action.lastRefresh,
          nextRefresh: action.nextRefresh,
          expanded: merge(state[action.key].expanded, {}),
          DataTable: merge(state[action.key].DataTable, {}),
        },
      };

    case "LOAD_OPEN_CLOSE_BY_AREA_REPORT_CANCEL":
      // Reset filters/expanded
      return produce(state, (draftState) => {
        if (action.reportType) {
          draftState[action.reportType].filter = initialState.openclosebyareafull.filter;
          draftState[action.reportType].expanded = initialState.openclosebyareafull.expanded;
          _.set(draftState, action.reportType + ".loading", false);
        }
      });

    case "SET_OPEN_CLOSE_BY_AREA_REPORT_EXPAND_SITE":
      return produce(state, (draftState) => {
        // Select by enterprise_site_id
        const { expanded } = action;

        draftState[action.reportType].expanded[action.enterprise_site_id] = expanded;
      });
    case "RESET_OPEN_CLOSE_BY_AREA_REPORT_EXPANDED":
      return produce(state, (draftState) => {
        draftState[action.reportType].expanded = {};
      });

    default:
      return state;
  }
};

export default openclosebyarea;