/**
 * User management selectors
 */

import { createSelector } from "reselect";

import USER_ROLE_IDS from "constants/USER_ROLE_IDS";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import {
  getGroupFromRoute,
  getGroupsWithAccess,
  getGroupById
} from "services/redux/selectors/groups";

/**
 * Gets the currently selected group
 */
export const getSelectedGroupId = createSelector(
  [getEnterpriseFromRoute, getGroupFromRoute],
  (enterprise, group) => {
    return (group && group.id) || enterprise.root_group_id;
  }
);

/**
 * Gets the logged in users role id for the currently selected group
 */
export const getRoleIdForCurrentGroup = createSelector(
  [getGroupsWithAccess, getSelectedGroupId],
  (groupsWithAccess, selectedGroupId) => {
    if (!groupsWithAccess) return null;

    const { role_id } =
      getGroupById(groupsWithAccess.groups, parseInt(selectedGroupId)) || {};

    return role_id;
  }
);

/**
 * Returns true if the user can edit or remove the current user item
 * for UserList's DataTable
 */
export const getCanEditUser = createSelector(
  [
    getRoleIdForCurrentGroup,
    // Select role from table row props
    (state, { original }) => original.role,
    (state, { original }) => original.id,
  ],
  (loggedInUserRoleId, rowUserRole, rowUserId) => {
    switch (loggedInUserRoleId) {
      // Logged in user is an admin
      case USER_ROLE_IDS.ADMIN:
        // // Only allow editing of row if user isn't admin
        // if (rowUserRole !== "Admin") {
        //   return true;
        // }

        // Allow editing of all but your own user
        if (rowUserId !== loggedInUserRoleId) {
          return true;
        }
        break;
      case USER_ROLE_IDS.MANAGER:
        // Only allow editing of row if user isn't admin or manager
        if (rowUserRole !== "Admin" && rowUserRole !== "Manager") {
          return true;
        }
        break;
      // Default to restricting modification
      default:
        return false;
    }
  }
);


/**
 * Gets the user list excluding conxtd users
 */
 export const getUsersExcludingConxtd = createSelector(
  [(state) => state.users.current.users],
  (users) => {
    let resultingUsers = users.filter((user) => {
      return user.email.slice(-11).toLowerCase() !== "@conxtd.com"
    });

    return resultingUsers;
  }
);