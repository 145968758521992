import { all } from "redux-saga/effects";

import { watchLoadResources } from "./resources";

import rulesRootSaga from "./rules/";

export function* rootSaga() {
  yield all([
    // Load resources for specified channel
    watchLoadResources(),
    rulesRootSaga()
  ]);
}
