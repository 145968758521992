/**
 * Users/Management/Edit - Edit user's access to a group
 */

import * as yup from "yup";

import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestUserManagementEditSuccess,
  requestUserManagementEditFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

const userEditSchema = yup.object().shape({
  email: yup.string().required(),
  emailConfirm: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  profile: yup.object().shape({
    position: yup
      .string()
      .nullable()
      .optional(),
    locale: yup.string().optional(),
    timezone: yup.string().required(),
    login: yup
      .mixed()
      .nullable()
      .optional(),
    company: yup
      .mixed()
      .nullable()
      .optional()
  }),
  id: yup.number().required(),
  role_id: yup.number().required()
});

export function* watchEdit() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_USER_MANAGEMENT_EDIT_START", function*(action) {
    yield race({
      task: call(requestEdit, action),
      cancel: take([
        "REQUEST_USER_MANAGEMENT_EDIT_CANCEL",
        "REQUEST_USER_MANAGEMENT_EDIT_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

function* requestEdit(action) {
  const { enterprise_id, group_id, user_id, user } = action;
  try {
    if (!user_id) {
      throw new Error("Invalid value supplied for user_id: " + user_id);
    }

    if (!enterprise_id) {
      throw new Error(
        "Invalid value supplied for enterprise_id: " + enterprise_id
      );
    }
    if (!group_id) {
      throw new Error("Invalid value supplied for group_id: " + group_id);
    }

    const requestPayload = {
      sendInvite: true,
      emailConfirm: user.email,
      ...user
    };

    userEditSchema.validateSync(requestPayload);

    const response = yield call(
      conxtdOut.post,
      `/EnterpriseUsers/edit/${user_id}/${enterprise_id}/${group_id}`,
      requestPayload
    );
    // const response = yield call(() => {
    //   return { data: {} };
    // });

    yield put(requestUserManagementEditSuccess({ ...response.data }));
  } catch (error) {
    yield put(
      requestUserManagementEditFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
