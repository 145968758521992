export * from "./notifications/";

// USER
// LOGIN
export const loginStart = (credentials) => {
  return { type: "LOGIN_START", credentials };
};
export const loginFailed = (error) => {
  return { type: "LOGIN_FAILED", error };
};
export const loginSuccess = (data) => {
  const { user, enterprises } = data;

  return { type: "LOGIN_SUCCESS", user, enterprises };
};
// LOGOUT
export const logoutRequest = () => {
  return { type: "LOGOUT_REQUEST" };
};
export const logoutFailed = (error) => {
  return { type: "LOGOUT_FAILED", error };
};
// EDIT PROFILE
export const requestChangeProfileStart = (params) => {
  return { type: "REQUEST_CHANGE_PROFILE_START", ...params };
};
export const requestChangeProfileCancel = (params) => {
  return { type: "REQUEST_CHANGE_PROFILE_CANCEL", ...params };
};
export const requestChangeProfileSuccess = (params) => {
  return { type: "REQUEST_CHANGE_PROFILE_SUCCESS", ...params };
};
export const requestChangeProfileFailed = (params) => {
  return { ...params, type: "REQUEST_CHANGE_PROFILE_FAILED" };
};
export const requestChangeProfileNotified = () => {
  return { type: "REQUEST_CHANGE_PROFILE_NOTIFIED" };
};
// CHANGE PASSWORD
export const requestChangePasswordStart = (params) => {
  return { type: "REQUEST_CHANGE_PASSWORD_START", ...params };
};
export const requestChangePasswordCancel = (params) => {
  return { type: "REQUEST_CHANGE_PASSWORD_CANCEL", ...params };
};
export const requestChangePasswordSuccess = (params) => {
  return { type: "REQUEST_CHANGE_PASSWORD_SUCCESS", ...params };
};
export const requestChangePasswordFailed = (params) => {
  return { ...params, type: "REQUEST_CHANGE_PASSWORD_FAILED" };
};
export const requestChangePasswordNotified = () => {
  return { type: "REQUEST_CHANGE_PASSWORD_NOTIFIED" };
};
export * from "./users/";

// Request accept invite, user clicked on link in email
// so we call the backend to check if the invite was
// already accepted
// Request create/confirm/activate account on invite page
export const requestUserAcceptInviteStart = (params) => {
  return { type: "REQUEST_USER_ACCEPT_INVITE_START", ...params };
};
export const requestUserAcceptInviteSuccess = (params) => {
  return { type: "REQUEST_USER_ACCEPT_INVITE_SUCCESS", ...params };
};
export const requestUserAcceptInviteFailed = (params) => {
  return { ...params, type: "REQUEST_USER_ACCEPT_INVITE_FAILED" };
};
export const requestUserAcceptInviteCancel = (params) => {
  return { ...params, type: "REQUEST_USER_ACCEPT_INVITE_CANCEL" };
};

export const requestUserCreateAccountStart = (params) => {
  return { ...params, type: "REQUEST_USER_CREATE_ACCOUNT_START" };
};
export const requestUserCreateAccountSuccess = (params) => {
  return { ...params, type: "REQUEST_USER_CREATE_ACCOUNT_SUCCESS" };
};
export const requestUserCreateAccountFailed = (params) => {
  return { ...params, type: "REQUEST_USER_CREATE_ACCOUNT_FAILED" };
};
export const requestUserCreateAccountCancel = (params) => {
  return { ...params, type: "REQUEST_USER_CREATE_ACCOUNT_CANCEL" };
};

// Request app identity check for frontend, user logged in from react native web view
// Take sessionID from webview and send to new endpoint
// sessionID and user details returned to frontend in store in cache
// user gets redirected to Enterprise page
export const requestAppIdentityCheckStart = (params) => {
  return { type: "REQUEST_APP_IDENTITY_CHECK_START", ...params };
};
export const requestAppIdentityCheckSuccess = (params) => {
  return { type: "REQUEST_APP_IDENTITY_CHECK_SUCCESS", ...params };
};
export const requestAppIdentityCheckFailed = (params) => {
  return { ...params, type: "REQUEST_APP_IDENTITY_CHECK_FAILED" };
};
export const requestAppIdentityCheckCancel = (params) => {
  return { ...params, type: "REQUEST_APP_IDENTITY_CHECK_CANCEL" };
};

// USER RESET PASSWORD
export const requestUserResetPasswordStart = (params) => {
  return { ...params, type: "REQUEST_USER_RESET_PASSWORD_START" };
};
export const requestUserResetPasswordSuccess = (params) => {
  return { ...params, type: "REQUEST_USER_RESET_PASSWORD_SUCCESS" };
};
export const requestUserResetPasswordFailed = (params) => {
  return { ...params, type: "REQUEST_USER_RESET_PASSWORD_FAILED" };
};
export const requestUserResetPasswordCancel = (params) => {
  return { ...params, type: "REQUEST_USER_RESET_PASSWORD_CANCEL" };
};
export const requestUserResetPasswordNotified = (params) => {
  return { ...params, type: "REQUEST_USER_RESET_PASSWORD_NOTIFIED" };
};
// USER RESET PASSWORD SEND EMAIL
export const sendUserResetPasswordEmailStart = (params) => {
  return { ...params, type: "SEND_USER_RESET_PASSWORD_EMAIL_START" };
};
export const sendUserResetPasswordEmailSuccess = (params) => {
  return {
    ...params,
    type: "SEND_USER_RESET_PASSWORD_EMAIL_SUCCESS",
  };
};
export const sendUserResetPasswordEmailFailed = (params) => {
  return { ...params, type: "SEND_USER_RESET_PASSWORD_EMAIL_FAILED" };
};
export const sendUserResetPasswordEmailCancel = (params) => {
  return { ...params, type: "SEND_USER_RESET_PASSWORD_EMAIL_CANCEL" };
};

// APP
export const appStartup = () => {
  return { type: "APP_STARTUP" };
};

// APP STATE
export const toggleNavCollapse = () => {
  return { type: "TOGGLE_NAV_COLLAPSE" };
};
export const restoreNavCollapse = (navCollapsed) => {
  return { type: "RESTORE_NAV_COLLAPSE", ...navCollapsed };
};
export const restoreLastFetched = () => {
  return { type: "RESTORE_LAST_FETCHED" };
};
export const toggleDarkMode = () => {
  return { type: "TOGGLE_DARK_MODE" };
};
export const toggleSnowMode = () => {
  return { type: "TOGGLE_SNOW_MODE" };
};
export const routerNavigate = (params) => {
  return { type: "ROUTER_NAVIGATE", ...params };
};
// SEARCH
export const globalSearchToggle = (params) => {
  return { type: "GLOBAL_SEARCH_TOGGLE", ...params };
};
export const globalSearchChange = (params) => {
  return { type: "GLOBAL_SEARCH_CHANGE", ...params };
};

// ENTERPRISES
export const loadEnterprisesStart = () => {
  return { type: "LOAD_ENTERPRISES_START" };
};
export const loadEnterprisesFailed = (error) => {
  return { type: "LOAD_ENTERPRISES_FAILED", ...error };
};
export const loadEnterprisesSuccess = (response) => {
  return {
    type: "LOAD_ENTERPRISES_SUCCESS",
    ...response,
  };
};
export const loadEnterprisesRestore = (response) => {
  return {
    type: "LOAD_ENTERPRISES_RESTORE",
    ...response,
  };
};
export const loadEnterprisesCancel = (response) => {
  return {
    type: "LOAD_ENTERPRISES_CANCEL",
    ...response,
  };
};
export const refreshEnterprisesStart = () => {
  return { type: "REFRESH_ENTERPRISES_START" };
};

//ENTERPRISE
export const setEnterpriseCallLimits = (params) => {
  return { type: "SET_ENTERPRISE_CALL_LIMITS", ...params };
};

// REPORTS
// OVERVIEW
export const loadReportsOverviewStart = (params) => {
  return { type: "LOAD_REPORTS_OVERVIEW_START", ...params };
};
export const loadReportsOverviewCancel = (params) => {
  return { type: "LOAD_REPORTS_OVERVIEW_CANCEL", ...params };
};
export const loadReportsOverviewFailed = (error) => {
  return { type: "LOAD_REPORTS_OVERVIEW_FAILED", ...error };
};
export const loadReportsOverviewSuccess = (response) => {
  return { type: "LOAD_REPORTS_OVERVIEW_SUCCESS", ...response };
};
export const loadReportsOverviewRestore = (response) => {
  return { type: "LOAD_REPORTS_OVERVIEW_RESTORE", ...response };
};
// EXCEPTION Panic/Fire
export const loadExceptionReportStart = (params) => {
  return { type: "LOAD_EXCEPTION_REPORT_START", ...params };
};
export const loadExceptionReportFailed = (error) => {
  return { type: "LOAD_EXCEPTION_REPORT_FAILED", ...error };
};
export const loadExceptionReportSuccess = (response) => {
  return { type: "LOAD_EXCEPTION_REPORT_SUCCESS", ...response };
};
// OPEN deprecated
// export const loadOpenReportStart = params => {
//   return { type: "LOAD_OPEN_REPORT_START", ...params };
// };
// export const loadOpenReportFailed = error => {
//   return { type: "LOAD_OPEN_REPORT_FAILED", ...error };
// };
// export const loadOpenReportSuccess = response => {
//   return { type: "LOAD_OPEN_REPORT_SUCCESS", ...response };
// };
// LIVE SCHEDULED REPORTS
export const loadScheduledReportStart = (params) => {
  return { type: "LOAD_SCHEDULED_REPORT_START", ...params };
};
export const loadScheduledReportFailed = (error) => {
  return { type: "LOAD_SCHEDULED_REPORT_FAILED", ...error };
};
export const loadScheduledReportSuccess = (response) => {
  return { type: "LOAD_SCHEDULED_REPORT_SUCCESS", ...response };
};
export const loadScheduledReportCancel = (params) => {
  return { type: "LOAD_SCHEDULED_REPORT_CANCEL", ...params };
};
// CONCURRENT SCHEDULED REPORTS (unused?)
export const loadScheduledReportForkStart = (params) => {
  return { type: "LOAD_SCHEDULED_REPORT_FORK_START", ...params };
};
// SCHEDULED REPORT INTERACTION //
// SCHEDULED REPORT FILTER
export const changeScheduledReportIntervalFilter = (params) => {
  return {
    type: "CHANGE_SCHEDULED_REPORT_INTERVAL_FILTER",
    ...params,
  };
};
export const changeScheduledReportEventTypeFilter = (params) => {
  return {
    type: "CHANGE_SCHEDULED_REPORT_EVENT_TYPE_FILTER",
    ...params,
  };
};
export const changeFailedToCloseReportFilter = (params) => {
  return {
    type: "CHANGE_FAILED_TO_CLOSE_REPORT_FILTER",
    ...params,
  };
};

export const changeScheduledReportRoundingMethod = (params) => {
  return {
    type: "CHANGE_SCHEDULED_REPORT_ROUNDING_METHOD",
    ...params,
  };
};
export const changeScheduledReportTimeSegment = (params) => {
  return {
    type: "CHANGE_SCHEDULED_REPORT_TIME_SEGMENT",
    ...params,
  };
};
export const setScheduledReportUserTimezoneUTCOffset = (params) => {
  return {
    type: "SET_SCHEDULED_REPORT_USER_TIMEZONE_UTC_OFFSET",
    ...params,
  };
};
// 08/21 hourly report - set the selected site for displaying the bar chart
export const setScheduledReportHourlySelectedChartSite = ({ reportType, id }) => {
  return {
    type: "SET_SCHEDULED_REPORT_HOURLY_SELECTED_CHART_SITE",
    reportType,
    id
  };
};
// 08/21 hourly report - remove the selected site
export const removeScheduledReportHourlySelectedChartSite = ({ reportType }) => {
  return {
    type: "REMOVE_SCHEDULED_REPORT_HOURLY_SELECTED_CHART_SITE",
    reportType
  };
};

export const setScheduledReportSelectedTimeRange = ({ reportType, selectedTimeRange }) => {
  return {
    type: "SET_SCHEDULED_REPORT_SELECTED_TIME_RANGE",
    reportType,
    selectedTimeRange,
  };
};

// SCHEDULED DATATABLE TOGGLE
export const toggleExpandSite = ({ id, reportType }) => {
  return {
    type: "TOGGLE_SCHEDULED_REPORT_EXPAND_SITE",
    reportType,
    id,
  };
};
export const setExpandSite = ({ id, reportType, expanded }) => {
  return {
    type: "SET_SCHEDULED_REPORT_EXPAND_SITE",
    reportType,
    id,
    expanded,
  };
};
export const resetExpanded = ({ reportType }) => {
  return {
    type: "RESET_SCHEDULED_REPORT_EXPANDED",
    reportType,
  };
};

// OUTSTANDING
export const loadOutstandingReportStart = (params) => {
  return { type: "LOAD_OUTSTANDING_REPORT_START", ...params };
};
export const loadOutstandingReportFailed = (error) => {
  return { type: "LOAD_OUTSTANDING_REPORT_FAILED", ...error };
};
export const loadOutstandingReportSuccess = (response) => {
  return { type: "LOAD_OUTSTANDING_REPORT_SUCCESS", ...response };
};
export const loadOutstandingReportCancel = () => {
  return { type: "LOAD_OUTSTANDING_REPORT_CANCEL" };
};
// OUTSTANDING - BUNDLE VIEW
export const loadOutstandingReportBundleStart = (params) => {
  return { type: "LOAD_OUTSTANDING_REPORT_BUNDLE_START", ...params };
};
export const loadOutstandingReportBundleFailed = (error) => {
  return { type: "LOAD_OUTSTANDING_REPORT_BUNDLE_FAILED", ...error };
};
export const loadOutstandingReportBundleSuccess = (response) => {
  return { type: "LOAD_OUTSTANDING_REPORT_BUNDLE_SUCCESS", ...response };
};
export const loadOutstandingReportBundleCancel = () => {
  return { type: "LOAD_OUTSTANDING_REPORT_BUNDLE_CANCEL" };
};
// OUTSTANDING - CONFIRMED ALARM EVENT PAIR ACKNOWLEDEMENT
export const outstandingEventPairAcknowledgementStart = (params) => {
  return { type: "OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_START", ...params };
};
export const outstandingEventPairAcknowledgementeFailed = (error) => {
  return { type: "OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_FAILED", ...error };
};
export const outstandingEventPairAcknowledgementSuccess = (response) => {
  return { type: "OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_SUCCESS", ...response };
};
// OUTSTANDING REPORT FILTER
// To be removed
// export const changeOutstandingReportFilter = show => {
//   return { type: "CHANGE_OUTSTANDING_REPORT_FILTER", show };
// };

// 09/21 - open close by area
// OPEN CLOSE BY AREA 
export const loadOpenCloseByAreaReportStart = (params) => {
  return { type: "LOAD_OPEN_CLOSE_BY_AREA_REPORT_START", ...params };
};
export const loadOpenCloseByAreaReportFailed = (error) => {
  return { type: "LOAD_OPEN_CLOSE_BY_AREA_REPORT_FAILED", ...error };
};
export const loadOpenCloseByAreaReportSuccess = (response) => {
  return { type: "LOAD_OPEN_CLOSE_BY_AREA_REPORT_SUCCESS", ...response };
};
export const loadOpenCloseByAreaReportCancel = () => {
  return { type: "LOAD_OPEN_CLOSE_BY_AREA_REPORT_CANCEL" };
};

export const setOpenCloseByAreaExpandSite = ({ enterprise_site_id, reportType, expanded }) => {
  return {
    type: "SET_OPEN_CLOSE_BY_AREA_REPORT_EXPAND_SITE",
    reportType,
    enterprise_site_id,
    expanded,
  };
};
export const resetOpenCloseByAreaExpanded = ({ reportType }) => {
  return {
    type: "RESET_OPEN_CLOSE_BY_AREA_REPORT_EXPANDED",
    reportType,
  };
};

// FAILED TO CLOSE
export const loadFailedToCloseReportStart = (params) => {
  return { type: "LOAD_FAILED_TO_CLOSE_REPORT_START", ...params };
};
export const loadFailedToCloseReportFailed = (error) => {
  return { type: "LOAD_FAILED_TO_CLOSE_REPORT_FAILED", ...error };
};
export const loadFailedToCloseReportSuccess = (response) => {
  return { type: "LOAD_FAILED_TO_CLOSE_REPORT_SUCCESS", ...response };
};
export const loadFailedToCloseReportCancel = () => {
  return { type: "LOAD_FAILED_TO_CLOSE_REPORT_CANCEL" };
};

export const setFailedToCloseExpandSite = (params) => {
  return { type: "SET_FAILED_TO_CLOSE_REPORT_EXPAND_SITE", ...params };
};
export const resetFailedToCloseExpanded = (params) => {
  return {type: "RESET_FAILED_TO_CLOSE_REPORT_EXPANDED", ...params};
};

// TRANSLATION
export const translationError = (error) => {
  return { type: "TRANSLATION_ERROR", ...error };
};

// GROUPS / BACKGROUND
export const loadGroupsBackgroundStart = (params) => {
  return { type: "LOAD_GROUPS_BACKGROUND_START", ...params };
};
export const loadGroupsBackgroundFailed = (error) => {
  return { type: "LOAD_GROUPS_BACKGROUND_FAILED", ...error };
};
export const loadGroupsBackgroundSuccess = (response) => {
  return { type: "LOAD_GROUPS_BACKGROUND_SUCCESS", ...response };
};
export const loadGroupsBackgroundRestore = (response) => {
  return { type: "LOAD_GROUPS_BACKGROUND_RESTORE", ...response };
};

export const loadGroupOverviewStart = (params) => {
  return { type: "LOAD_GROUP_OVERVIEW_START", ...params };
};
export const loadGroupOverviewFailed = (params) => {
  return { type: "LOAD_GROUP_OVERVIEW_FAILED", ...params };
};
export const loadGroupOverviewSuccess = (params) => {
  return { type: "LOAD_GROUP_OVERVIEW_SUCCESS", ...params };
};
export const loadGroupOverviewCancel = (params) => {
  return { type: "LOAD_GROUP_OVERVIEW_CANCEL", ...params };
};
export const changeGroupOverviewFilter = (params) => {
  return { type: "CHANGE_GROUP_OVERVIEW_FILTER", ...params };
};
export const resetGroupOverviewFilter = (params) => {
  return { type: "RESET_GROUP_OVERVIEW_FILTER", ...params };
};

export * from "./sites/";

// TASKS
export const cancelAll = () => {
  return { type: "CANCEL_ALL" };
};
export const cancelAllLiveRefresh = () => {
  return { type: "CANCEL_ALL_LIVE_REFRESH" };
};

// CACHING
export const cacheMe = (action) => {
  // Copy action type to cacheKey
  return { ...action, type: "CACHE_ME", cacheKey: action.type };
};

// EVENT META / BACKGROUND
export const loadEventMetaBackgroundStart = (params) => {
  return { type: "LOAD_EVENT_META_BACKGROUND_START", ...params };
};
export const loadEventMetaBackgroundFailed = (error) => {
  return { type: "LOAD_EVENT_META_BACKGROUND_FAILED", ...error };
};
export const loadEventMetaBackgroundSuccess = (response) => {
  return { type: "LOAD_EVENT_META_BACKGROUND_SUCCESS", ...response };
};
export const loadEventMetaBackgroundRestore = (response) => {
  return { type: "LOAD_EVENT_META_BACKGROUND_RESTORE", ...response };
};
export const loadEventMetaBackgroundCancel = () => {
  return { type: "LOAD_EVENT_META_BACKGROUND_CANCEL" };
};

// EVENT PAIRS
export const loadEventPairsStart = (params) => {
  return { type: "LOAD_EVENT_PAIRS_START", ...params };
};
export const loadEventPairsSuccess = (params) => {
  return { type: "LOAD_EVENT_PAIRS_SUCCESS", ...params, };
};
export const loadEventPairsFailed = (params) => {
  return { type: "LOAD_EVENT_PAIRS_FAILED", ...params };
};
export const loadEventPairsCancel = (params) => {
  return { type: "LOAD_EVENT_PAIRS_CANCEL", ...params };
};

// FACEWATCH
export const loadFacewatchStart = (params) => {
  return { type: "LOAD_FACEWATCH_START", ...params };
};
export const loadFacewatchFailed = (error) => {
  return { type: "LOAD_FACEWATCH_FAILED", ...error };
};
export const loadFacewatchSuccess = (response) => {
  return { type: "LOAD_FACEWATCH_SUCCESS", ...response };
};

export const changeCurrentReportFilter = ({
  label,
  reportType,
  filterType,
}) => {
  return {
    type: "CHANGE_CURRENT_REPORT_FILTER",
    show: label,
    filterType,
    reportType,
  };
};

// REPORTS DATATABLE
export const changeDataTableFiltered = ({ reportType, ...params }) => {
  return {
    type: "CHANGE_DATATABLE_FILTERED",
    reportType,
    ...params,
  };
};

export const toggleDataTableFiltered = ({ reportType, ...params }) => {
  return {
    type: "TOGGLE_DATATABLE_FILTERED",
    reportType,
    ...params,
  };
};
