import React from "react";

import Tether from "./Tether";
import EagleEye from "./EagleEye";
import PhilipsHue from "./PhilipsHue";
import VideoLoft from "./VideoLoft";
import ChubbView from "./ChubbView";

/**
 * We select integration components by "key" property since
 * integration_id will change between mars/staging/live
 */
const IntegrationComponents = {
  "6e0d66c3-f008-404c-8f7b-3eab97d82ec7": Tether,
  "9b0957e4-b2f8-49f9-8cca-281618bd64e6": EagleEye,
  "c0fe8278-376f-4306-90df-e6088d920892": PhilipsHue,
  "b02e43a8-f7fc-4ae8-8784-67f2b4e5ad4c": VideoLoft,
  "98a1b0e2-f5c7-4c0e-9768-50ed64d16142": ChubbView,
};

export default function Integration({ integration, admin }) {
  const Component = IntegrationComponents[integration.key];
  if (!Component) {
    console.error(
      "Missing integration component for integration key: " + integration.key
    );
    return null;
  }

  return <Component integration={integration} admin={admin} />;
}
