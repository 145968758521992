// import { call, take, race, put, takeLatest } from "redux-saga/effects";
import { call, take, race, takeLatest } from "redux-saga/effects";

import {
  loadUserManagementUserListStart,
  loadUserManagementUserListSuccess,
  loadUserManagementUserListFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

import { liveRefreshFor } from "services/redux/sagas/common";

import { toGroupsBackgroundLoader } from "services/redux/sagas/links";

// import EnterpriseManagerUsersMock from "./EnterpriseManagerUsers.mock.json";

export function* watchLoadUserListBackground() {
  // while (true) {
  // const action = yield take("LOAD_GROUP_USER_LIST_START");

  yield takeLatest(
    // Requires groups
    ["LOAD_USER_MANAGEMENT_USER_LIST_START"],
    toGroupsBackgroundLoader
  );

  // Take latest action and cancel previous
  yield takeLatest("LOAD_USER_MANAGEMENT_USER_LIST_START", function*(action) {
    yield race({
      task: call(loadUserListBackground, action),
      cancel: take([
        "LOAD_USER_MANAGEMENT_USER_LIST_CANCEL",
        // "LOAD_GROUP_USER_LIST_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
  // }
}

/**
 * Load user manager overview
 *
 * @param {*} action requires enterprise_id
 */
export function* loadUserListBackground(action) {
  if (!action.enterprise_id)
    throw new Error("enterprise_id is required for loading user management");
  if (!action.group_id)
    throw new Error("group_od is required for loading user management");

  const { enterprise_id, group_id, interval, refresh, retry } = action;

  const params = {
    startAction: loadUserManagementUserListStart,
    startActionParams: action,
    successAction: loadUserManagementUserListSuccess,
    successActionParams: {
      enterprise_id
    },
    failedAction: loadUserManagementUserListFailed,
    // apiCall: call(() => {
    //   return { data: EnterpriseManagerUsersMock };
    // }),
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseUsers/index/${enterprise_id}/${group_id}`
    ),
    interval,
    key: "current",
    debounce: false,
    refresh: refresh || false,
    retry: retry || false
  };

  yield liveRefreshFor(params);
}
