import React, { useState, useRef } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  //  TrafficLayer,
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import MarkerCarrierIcon from "./MarkerCarrierIcon";
import { useSelector } from "react-redux";

import _ from "lodash";

import { getCurrentGroupFiltered } from "services/redux/selectors/groups";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import COLOUR_CODES from "constants/COLOUR_CODES.json";

const MarkerCluster = withScriptjs(
  withGoogleMap((props) => {
    const mapRef = useRef(null);

    // const [zoom, setZoom] = useState(6);
    // const [center, setCenter] = useState({ lat: 51.400459, lng: -1.32185 });
    let zoom = null;
    let setZoom = null;
    let center = null;
    let setCenter = null;

    if (props.user.environment === "india") {
      [zoom, setZoom] = useState(5);
      [center, setCenter] = useState({ lat: 20.5937, lng: 78.9629 });
    } else {
      [zoom, setZoom] = useState(6);
      [center, setCenter] = useState({ lat: 51.400459, lng: -1.32185 });
    }
    
    const currentGroup = useSelector((state, props) =>
      getCurrentGroupFiltered(state)
    );

    // some markers are in exactly the same coordinate position as others, so you cannot separate them on the map
    // prepare to calculate if markers are in the same place, and if so, give them a small offset so they are distinguisable on the map
    const offsetArray = [];
    currentGroup.sites.forEach((site) => {
      const siteCoords = { 
        id: site.id,
        lat: typeof site.sudo_sites_profile.latitude === "number" ? String(site.sudo_sites_profile.latitude) : "",
        lng: typeof site.sudo_sites_profile.longitude === "number" ? String(site.sudo_sites_profile.longitude) : "",
        latLngString: "",
        offsetLat: "",
        offsetLng: "",
      }

      // create a string of lat/lng concatenated - to be used to tally how many occurrences of the same lat/lng
      siteCoords.latLngString = siteCoords.lat + siteCoords.lng; 

      offsetArray.push(siteCoords);
    });

    // tally how many of each lat/lng combination occur
    const tallyLatLngs = offsetArray.reduce( (tally, siteCoords) => {
      tally[siteCoords.latLngString] = (tally[siteCoords.latLngString] || 0) + 1 ;
      return tally;
    } , {});

    // sort the array so that all lat/lng points that are identical are next to each-other in the array
    offsetArray.sort((siteCoordsA, siteCoordsB) => {
      // compare as strings - all we care about is that lat/lngs that are identical are grouped together
      if (siteCoordsA.latLngString < siteCoordsB.latLngString) 
        return -1 
      if (siteCoordsA.latLngString > siteCoordsB.latLngString)
        return 1
      return 0 
    });

    // if offsets are needed for a group of markers in the same position, arrange them all in a circle around the original point
    let subCount = 0;
    offsetArray.forEach((siteCoords) => {
      if (siteCoords.latLngString !== "") {
        const tally = tallyLatLngs[siteCoords.latLngString];
        if (tally && tally > 1) {
          const a = 360.0 / tally;
          // convert degrees to radians
          // different offsets for lat and lng to make it look more circular - the map projection stretches the grid or something i think
          siteCoords.offsetLat = (parseFloat(siteCoords.lat) + -.00013 * Math.cos(((a*subCount) / 180) * Math.PI)).toFixed(6);  
          siteCoords.offsetLng = (parseFloat(siteCoords.lng) + -.0002 * Math.sin(((a*subCount) / 180) * Math.PI)).toFixed(6);  
          subCount++;
        } else {
          subCount = 0;
        }
      }
    });

    const getEventMetaById = useSelector((state, props) =>
      createGetEventMetaById(state, props)
    );

    return (
      <GoogleMap
        zoom={zoom}
        ref={mapRef}
        center={center}
        options={{
          zoomControlOptions: { position: 3 },
          streetViewControl: true,
          fullscreenControlOptions: { position: 9 },
        }}
        onDragEnd={() => setCenter(mapRef.current.getCenter())}
        onZoomChanged={() => setZoom(mapRef.current.getZoom())}
      >
        {/*  <TrafficLayer autoUpdate />  */}

        <MarkerClusterer
          averageCenter
          calculator={markerClustererCalculator}
          maxZoom={14}
          gridSize={30}
          enableRetinaIcons
          styles={[
            {
              url: `/assets/images/icons/map/markerCluster1.png`,
              textColor: "white",
              width: 33,
              height: 33,
              textAlign: center,
            },
            {
              url: `/assets/images/icons/map/markerCluster2.png`,
              textColor: "white",
              width: 36,
              height: 36,
              textAlign: center,
            },
            {
              url: `/assets/images/icons/map/markerCluster3.png`,
              textColor: "white",
              width: 46,
              height: 46,
              textAlign: center,
            },
            {
              url: `/assets/images/icons/map/markerCluster4.png`,
              textColor: "white",
              width: 59,
              height: 59,

              textAlign: center,
            },
            {
              url: `/assets/images/icons/map/markerCluster5.png`,
              textColor: "white",
              width: 71,
              height: 71,
              textAlign: center,
            },
          ]}
        >
          {currentGroup.sites.map((site, index) => {
            const { alarmLast } = site;
            if (!site.alarmLast) return null;

            const colour_name = (alarmLast && alarmLast.colour) || "unknown";
            const alarmId = (alarmLast && alarmLast.alarmId) || 0;
            const color = getEventMetaById(alarmId) || "#666";
            // const iconUrl =
            //   window.location.origin +
            //   "/assets/images/icons/map/" +
            //   colour_name +
            //   ".png";

            // rounded icon
            const svgMarker = '<svg height="43" width="43" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">'
                            + '<path d="M128.695,12.423q32.958,0,56.562,23.604t23.604,57.245q0,16.82-8.416,38.526t-20.351,40.702t-23.604,35.539t-19.807,26.313l-8.682,9.226q-3.253-3.797-8.682-10.037t-19.541-24.958t-24.692-36.361t-19.263-39.88t-8.682-39.07q0-33.641,23.604-57.245t53.683-23.604Z"'
                            +   ' style="fill:' + COLOUR_CODES[colour_name] + '; stroke-width: 4; stroke:' + COLOUR_CODES[colour_name + "MarkerOutline"] + '"/>'
                            + '<path d="M128 98m35 0a35 35 0 1 1-70 0 35 35 0 1 1 70 0z" style="fill:' + COLOUR_CODES[colour_name + "MarkerOutline"] + '"/>'
                            + '</svg>';

            // pointy icon
            // const svgMarker = '<svg height="43" width="43" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">'
            // + '<path d="M123.002 8.847c-.361.045-1.85.203-3.272.338-3.655.361-9.86 1.625-13.605 2.798-8.416 2.595-15.997 6.543-22.946 11.936-2.933 2.256-9.296 8.551-11.552 11.417-9.973 12.612-14.914 26.082-15.455 42.079-.248 7.604.745 14.778 3.023 21.886 1.67 5.234 3.542 9.251 8.032 17.373 6.047 10.92 7.491 13.289 17.644 28.88 11.214 17.238 15.049 23.645 21.028 35.152 5.392 10.379 9.476 19.9 12.928 30.053 3.069 9.002 4.964 16.065 7.671 28.767.902 4.151 1.647 7.581 1.692 7.626.068 .09.271-.835 2.256-10.221 3.046-14.372 5.753-23.713 10.153-34.882 6.904-17.576 14.621-31.678 31.136-56.948 11.462-17.531 13.289-20.622 18.93-31.926 5.392-10.853 6.836-14.688 8.123-21.886.812-4.535 1.038-7.265 1.038-12.161 0-13.447-3.384-25.338-10.424-36.687-4.851-7.784-11.687-15.094-19.02-20.329-9.228-6.566-20.803-11.078-32.603-12.703-4.106-.587-11.936-.88-14.778-.564z" style="fill:' + COLOUR_CODES[colour_name] + '; stroke-width: 3; stroke:' + COLOUR_CODES[colour_name + "MarkerOutline"] + '"/>'
            // + '<path d="M128 78m-30 0a30 30 0 1 0 60 0 30 30 0 1 0-60 0z" style="fill:' + COLOUR_CODES[colour_name + "MarkerOutline"] + '"/>'
            // + '</svg>';

            let lat = parseFloat(site.sudo_sites_profile.latitude);
            let lng = parseFloat(site.sudo_sites_profile.longitude);
            // if there is an offset value for lat/lng, use that instead 
            // - this means the original position was in exactly the same place as another marker
            const siteCoords = _.find(offsetArray, ['id', site.id]);
            if (siteCoords) {
              if (siteCoords.offsetLat !== "") {
                lat = parseFloat(siteCoords.offsetLat);
              }
              if (siteCoords.offsetLng !== "") {
                lng = parseFloat(siteCoords.offsetLng);
              }
            }

            return (
              <MarkerCarrierIcon
                key={index}
                // icon={iconUrl}
                icon={'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgMarker)}
                color={color}
                site={{
                  lat: lat,
                  lng: lng,
                }}
                onClick={() => {
                  props.selectSite(site);
                }}
                siteName={site.name}
                zoneState={site.zoneState}
                alarmCount={site.alarmCount}
                alarmLastIcon={site.alarmLast.icon}
                alarmLastColour={site.alarmLast.colour}
                alarmLastTime={site.alarmLast.acked}
                alarmLastCaption={site.alarmLast.caption}
              ></MarkerCarrierIcon>
            );
          })}
        </MarkerClusterer>
      </GoogleMap>
    );
  })
);

export default React.memo(MarkerCluster);
// https://nooshu.github.io/blog/2012/10/03/marker-cluster-calculator-for-google-maps-v3/
const markerClustererCalculator = (markers, numStyles) => {
  //create an index for icon styles
  var index = 0,
    //Count the total number of markers in this cluster
    count = markers.length,
    //Set total to loop through (starts at total number)
    total = count;

  /**
   * While we still have markers, divide by a set number and
   * increase the index. Cluster moves up to a new style.
   *
   * The bigger the index, the more markers the cluster contains,
   * so the bigger the cluster.
   */
  while (total !== 0) {
    //Create a new total by dividing by a set number
    total = parseInt(total / 5, 10);
    //Increase the index and move up to the next style
    index++;
  }

  /**
   * Make sure we always return a valid index. E.g. If we only have
   * 5 styles, but the index is 8, this will make sure we return
   * 5. Returning an index of 8 wouldn't have a marker style.
   */
  index = Math.min(index, numStyles);

  return {
    index: index,
    text: markers.length,
  };
};
