import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PageLayout from "./PageLayout";

import _ from "lodash";

import {
  loadOutstandingReportBundleStart,
  loadOutstandingReportBundleCancel
} from "services/redux/actions";
import { getBundle } from "services/redux/selectors/reports/outstanding/bundle/index";
import { getEnterprises } from "services/redux/selectors/enterprises";
import { getRouterParams } from "services/redux/selectors/app/";
import { getReportPath } from "services/redux/selectors/reports/router";
import { getConfig } from "services/redux/selectors/reports/common/";

/**
 * Page for Outstanding Report Bundle View
 */
class OutstandingBundleReportPage extends React.Component {
  loadBundle(props) {
    const { config } = props;
    let { enterprise_id, group_id, bundle_id } = props.router.params;

    // let { enterprise_id, group_id, bundle_id } = this.props.router.params;
    const enterprise = _.find(props.enterprises, {
      id: parseInt(enterprise_id)
    });

    // The root group was specified - we need to wait on loading
    //  enterprises to get the root group id before we can
    //  begin our request
    if (group_id === "root" || !group_id) {
      group_id = _.get(enterprise, "root_group_id");
      // No enterprises loaded yet
      if (!group_id) return;
    }

    const params = {
      enterprise_id,
      group_id,
      bundle_id,
      interval: 1 * config.getLiveRefreshInterval(bundle_id) * 1000,
      // Key to save/access from within this.props.reports
      // i.e. this.props.reports.bundle
      key: "bundle"
    };
    // Dispatch bundle view request
    this.props.dispatch(loadOutstandingReportBundleStart(params));
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loading } = this.props;
    const { params } = this.props.router;
    const nextParams = nextProps.router.params;

    const propsChanged =
      params.enterprise_id !== nextParams.enterprise_id ||
      params.group_id !== nextParams.group_id ||
      params.bundle_id !== nextParams.bundle_id;

    if (propsChanged) {
      // Not initial load - cancel any previous
      //   live refresh
      if (loading !== null) {
        this.props.dispatch(loadOutstandingReportBundleCancel());
      }
      this.loadBundle(nextProps);
    }
  }
  componentWillMount() {
    // Scroll to the top
    // window.scrollTo(0, 0);
    this.loadBundle(this.props);
  }
  componentWillUnmount() {
    this.props.dispatch(loadOutstandingReportBundleCancel());
  }
  render() {
    const { reportPath } = this.props;

    if (reportPath[0] === "default") return null;

    // const bundle = _.get(this.props.reports, "bundle");
    const loadingOverlay = this.props.loading !== false;
    // typeof _.get(bundle, "loading") === "undefined" ? true : bundle.loading;

    return (
      <PageLayout
        className={
          "outstanding-report-bundle-page" + (loadingOverlay ? " loading" : "")
        }
      />
    );
  }
}
OutstandingBundleReportPage.propTypes = {
  enterprises: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    reportPath: getReportPath(state, props),
    enterprises: getEnterprises(state),
    loading: getBundle(state).loading,
    router: { params: getRouterParams(state, props) },
    config: getConfig(state, props)
  };
};
export default connect(mapStateToProps)(OutstandingBundleReportPage);
