import { call, put, takeLatest } from "redux-saga/effects";
import conxtdOut from "apis/conxtdOut";

import {
  loadCompanyFieldsSuccess,
  loadCompanyFieldsFailed,
  loadEnterpriseFieldsSuccess,
  loadEnterpriseFieldsFailed,
  loadEnterpriseGroupsSuccess,
  loadEnterpriseGroupsFailed,
  loadSudoSiteIdSuccess,
  loadSudoSiteIdFailed,
  loadEnterpriseAssociationsSuccess,
  loadEnterpriseAssociationsFailed,
} from "services/redux/actions/serviceDesk";

// Add Site - get company custom fields
// https://mars.conxtd.com/out_api/ServiceDesk/getCompanyCustomFields
function* loadCompanyFieldsRe(action) {
  try {
    
    const { companyId } = action.payload;
    const url = `/ServiceDesk/getCompanyCustomFields/${companyId}`;

    const response = yield call(conxtdOut.get, url);

    yield put(loadCompanyFieldsSuccess(response));
  } catch (e) {
    yield put(loadCompanyFieldsFailed(e.message));
  }
}

export function* watchLoadCompanyFields() {
  yield takeLatest("LOAD_FORM_DATA_START", loadCompanyFieldsRe);
}

// Add Site - get enterprise custom fields
// https://mars.conxtd.com/out_api/ServiceDesk/getCompanyCustomFields
function* loadEnterpriseFieldsRe(action) {
  try {
    
    const { enterprise } = action.payload;
    const url = `/ServiceDesk/getEnterpriseCustomFields/${enterprise}`;

    const response = yield call(conxtdOut.get, url);

    yield put(loadEnterpriseFieldsSuccess(response));
  } catch (e) {
    yield put(loadEnterpriseFieldsFailed(e.message));
  }
}

export function* watchLoadEnterpriseFields() {
  yield takeLatest("LOAD_ENTERPRISE_FIELDS_START", loadEnterpriseFieldsRe);
}

// Add Site - get enterprise custom fields
// https://mars.conxtd.com/out_api/ServiceDesk/getGroups
function* loadEnterpriseGroupsRe(action) {
  try {
    
    const { enterprise } = action.payload;
    const url = `/ServiceDesk/getGroups/${enterprise}`;

    const response = yield call(conxtdOut.get, url);

    yield put(loadEnterpriseGroupsSuccess(response));
  } catch (e) {
    yield put(loadEnterpriseGroupsFailed(e.message));
  }
}

export function* watchLoadEnterpriseGroups() {
  yield takeLatest("LOAD_ENTERPRISE_GROUPS_START", loadEnterpriseGroupsRe);
}


// -- ASSET EDITOR --

// get sudo site id
// https://mars.conxtd.com/out_api/ServiceDesk/getSudoSiteId
function* loadSudoSiteIdRe(action) {
  try {
    
    const { sudoSiteRef, companyId } = action.payload;
    const url = `/ServiceDesk/getSudoSiteId/${sudoSiteRef}/${companyId}`;

    const response = yield call(conxtdOut.get, url);

    // Dispatch success action with the response
    yield put(loadSudoSiteIdSuccess(response));
  } catch (e) {
    // Dispatch failed action with the error
    yield put(loadSudoSiteIdFailed(e.message));
  }
}

export function* watchLoadSudoSiteId() {
  yield takeLatest("LOAD_SUDO_SITE_ID_START", loadSudoSiteIdRe);
}

// get sudo site id
// https://mars.conxtd.com/out_api/ServiceDesk/getEnterpriseAssociations
function* loadEnterpriseAssociationsRe(action) {
  try {
    
    const { sudoSiteId } = action.payload;
    const url = `/ServiceDesk/getEnterpriseAssociations/${sudoSiteId}`;

    const response = yield call(conxtdOut.get, url);

    // Dispatch success action with the response
    yield put(loadEnterpriseAssociationsSuccess(response));
  } catch (e) {
    // Dispatch failed action with the error
    console.log(e.message)
    yield put(loadEnterpriseAssociationsFailed(e.message));
  }
}

export function* watchLoadEnterpriseAssociations() {
  yield takeLatest("LOAD_ENTERPRISE_ASSOCIATIONS_START", loadEnterpriseAssociationsRe);
}
