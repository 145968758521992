import React from "react";
import { connect } from "react-redux";

// import { Card } from "react-bootstrap";

import RemoveFilter from "components/Analytics/Filters/ActiveFilters/RemoveFilter";

import "./FilterTags.scss";

class FilterTags extends React.Component {

  filterTag = (key, label, color, clearFilter) => {
    return <div
      key={key}
      style={{
        display: "inline-block",
        backgroundColor: color,
        color: "white",
        margin: "0 0.2rem 0.2rem 0",
      }}
    >
      <div
        className="text-light"
        style={{
          display: "inline-block",
          padding: "0.3rem 0.2rem 0.3rem 0.6rem",
        }}>
        {label}
      </div>
      <RemoveFilter
        className="company-remove-filter"
        onClick={e => {
          clearFilter();
        }}
      />
    </div>
  }

  render() {
    const selectionFilters = this.props.selectionFilters
      .map((filterTag, index) => {
        return this.filterTag(index, filterTag.label, filterTag.color, filterTag.clearFilter)
      });

    const tableFilterTags = this.props.tableFilters
      .map((filterTag, index) => {
        return this.filterTag(index, filterTag.label, filterTag.color, filterTag.clearFilter)
      });

    return (
      // <Card.Body>
        <div className="d-flex" style={{ padding: "0 1.25rem" }}>
          <div style={{ fontWeight: "600", padding: "0.3rem 0.5rem 0.3rem 0" }}>Filters:</div><div>{selectionFilters}{tableFilterTags}</div>
        </div>
      // </Card.Body>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};
export default connect(mapStateToProps)(FilterTags);