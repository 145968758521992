import React from "react";

import { Card, Col, Row } from "react-bootstrap";

import Icon from "components/Icons/Icon";

import ChannelIcons from "constants/Notifications/ChannelIcons";

import "./ChannelCard.scss";

const channels = [
  {
    code: "slack",
    name: "Slack"
  },
  {
    code: "email",
    name: "Email"
  },
  {
    code: "push",
    name: "Push"
  }
  /*
  {
    code: "hue",
    name: "Philips Hue"
  }
*/
];

function ChannelCard({ code, selected, selectChannel }) {
  const channel = channels.find(channel => channel.code === code);
  const iconClassName = ChannelIcons[code];

  return (
    <Card
      className={`channel-card bg-light ${selected ? "selected" : ""}`}
      onClick={() => selectChannel(channel.code)}
      style={{
        cursor: "pointer"
      }}
    >
      <Card.Body>
        <Row>
          <Col md="12" className="text-center">
            <Icon
              className={iconClassName}
              style={{ fontSize: "30px", height: "45px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" className="text-center">
            <Card.Subtitle>{channel.name}</Card.Subtitle>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

/**
 * Select notification channel; slack, email, hue etc.
 */
function SelectChannel({ channel, selectChannel }) {
  return (
    <Row>
      {channels.map((item, index) => (
        <Col key={index} md="4">
          <ChannelCard
            {...item}
            selected={item.code === channel}
            selectChannel={selectChannel}
          />
        </Col>
      ))}
    </Row>
  );
}
export default SelectChannel;
