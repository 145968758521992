/**
 * 09/21 - open close by area
 */

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import SVGIcon from "components/Icons/SVGIcon";

import { Card } from "react-bootstrap";

class FilterOverviewWidget extends React.Component {
  render() {
    const { getEventMetaById, title1, title2, iconId1, iconId2, total, report } = this.props;

    const eventMeta1 = getEventMetaById(Number(iconId1));
    const eventMeta2 = getEventMetaById(Number(iconId2));

    const icon1 = eventMeta1.icon || "";
    const colour1 = eventMeta1.colour || "#000";

    const icon2 = eventMeta2.icon || "";
    const colour2 = eventMeta2.colour || "#000";

    const AlarmIcon1 =
      ((eventMeta1 !== null) && (icon1 !== "")) ? <SVGIcon type={icon1} color="#ffffff" /> : null;

    const AlarmIcon2 =
      ((eventMeta2 !== null) && (icon2 !== "")) ? <SVGIcon type={icon2} color={colour2} /> : null;

    const topStyle = {
      backgroundColor: colour1,
      display: "inline-block",
      width: "60px",
      height: "60px",
      borderRadius: "50% 50%",
      padding: "10px",
    }

    const bottomStyle = {
      color: colour2,
      display: "inline-block",
      width: "60px",
      height: "60px",
      padding: "10px",
    }

    return (
      <Link
        to={`./${report}`}
        className="waves-effect card visual-data">
        <Card.Body className="m-t-10 text-center">
          <div id={icon1} className="svg-icon text-white mb-1" style={topStyle}>
            {AlarmIcon1}
          </div>
          <div >
            <h6 className="text-uppercase text-muted m-t-10 m-b-5 font-weight-normal">{title1}</h6>
            <hr className="mt-0 mb-0" style={{maxWidth: "90px"}}/>
            <h6 className="text-uppercase text-muted m-b-10 m-t-5 font-weight-normal">{title2}</h6>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="h5 d-inline text-dark">{total}</div>
            <div id={icon2} className="svg-icon mb-1 d-inline-block" style={bottomStyle}>
              {AlarmIcon2}
            </div>
          </div>
        </Card.Body>
      </Link>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default connect(mapStateToProps)(FilterOverviewWidget);
