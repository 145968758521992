import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { Button } from "react-bootstrap";

import { Tooltip } from "components/Common/Tooltip/";
import Alert from "components/Common/Alert";

import Icon from "components/Icons/Icon";
import SVGIcon from "components/Icons/SVGIcon";

import {
  getEventPairMapsStart,
  setEmPriorityEventsManagementTabContent,
  setCurrentEventPairMap,
} from "services/redux/actions/enterpriseManager";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getEventPairMaps } from "services/redux/selectors/enterpriseManager";

import ViewExampleModal from "./ViewExampleModal";

import conxtdOut from "apis/conxtdOut";

/**
 * SOP Management Enabled Events
 *
 **/

class DataTable extends React.Component {
  state={
    viewExampleModalShow: false,
  }

  componentDidUpdate(prevProps) {
    const { eventPairMaps, setForceUseEnterpriseEventPairs } = this.props;

    if (eventPairMaps.enterprise_event_pair_maps.length > 0 && prevProps.eventPairMaps.enterprise_event_pair_maps.length === 0) {
      setForceUseEnterpriseEventPairs(false);
    }
  }

  render() {
    const { enterprise, eventPairMaps, forceUseEnterpriseEventPairs } = this.props;

    const usingEnterpriseEventPairs = forceUseEnterpriseEventPairs || eventPairMaps.enterprise_event_pair_maps.length > 0;

    const showSOP = (enterprise.in_alarm === "S" || enterprise.in_alarm === "C");

    const viewExampleModalClose = () => this.setState({ viewExampleModalShow: false });

    let noDataMsg = "No data available...";

    const columns = [
      {
        key: "POSITION",
        Header: "Position",
        id: "position",
        width: 100,
        accessor: "position",
      },
      {
        key: "NAME",
        Header: "Name",
        id: "name",
        // minWidth: 300,
        accessor: "name",
        Cell: ({ original }) => {
          const { eventMeta } = original;
          return <div
            style={{
              padding: "0.5rem",
            }}
          >
            <div
              id={eventMeta.icon}
              className={`svg-icon text-white mr-2`}
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                position: "relative",
                top: "-1px",
              }}
            >
              <SVGIcon type={eventMeta.icon} color={eventMeta.colour}/>
            </div>
            {original.name}
          </div>
        }
      },
      {
        key: "ACTIONS",
        Header: "Actions",
        id: "actions",
        width: 149,
        Cell: ({ original }) => {
          return <div>

            {/* Move Up */}
            <Tooltip
              description={usingEnterpriseEventPairs ? "Move up" : "Create custom list to use this function"}
              placement="left"
            >
              <Button
                style={{ fontSize: "18px" }}
                variant="light"
                disabled={!usingEnterpriseEventPairs}
                onClick={() => {
                  const moveUp = conxtdOut.post(`/sop/event-pair-maps/move/${enterprise.id}/${original.id}/u`,);
      
                  moveUp.then(() => {
                    this.props.dispatch(
                      getEventPairMapsStart({
                        enterprise_id: enterprise.id,
                      })
                    );       
                  }).catch((error) => {
                    console.log("error", error.message);
                      Alert({
                        text: "Reposition failed, please try again later.",
                        icon: "error",
                        timerProgressBar: true,
                        timer: 5000,
                      });
                  });
                }}
              >
                <Icon className="fas fa-arrow-circle-up" />
              </Button>
            </Tooltip>
            
            {/* Move Down */}
            <Tooltip
              description={usingEnterpriseEventPairs ? "Move down" : "Create custom list to use this function"}
              placement="top"
            >
              <Button
                className="ml-1"
                style={{ fontSize: "18px" }}
                variant="light"
                disabled={!usingEnterpriseEventPairs}
                onClick={() => {
                  const moveUp = conxtdOut.post(`/sop/event-pair-maps/move/${enterprise.id}/${original.id}/d`,);
      
                  moveUp.then(() => {
                    this.props.dispatch(
                      getEventPairMapsStart({
                        enterprise_id: enterprise.id,
                      })
                    );       
                  }).catch((error) => {
                    console.log("error", error.message);
                      Alert({
                        text: "Reposition failed, please try again later.",
                        icon: "error",
                        timerProgressBar: true,
                        timer: 5000,
                      });
                  });
                }}
              >
                <Icon className="fas fa-arrow-circle-down" />
              </Button>
            </Tooltip>

            {/* Delete */}
            <Tooltip
              description={usingEnterpriseEventPairs ? "Delete" : "Create custom list to use this function"}
              placement="top"
            >
              <Button
                className="ml-1"
                style={{ fontSize: "18px" }}
                variant="light"
                disabled={!usingEnterpriseEventPairs}
                onClick={() => {
                  const deleteEventPair = conxtdOut.post(`/sop/event-pair-maps/remove/${enterprise.id}/${original.id}`,);
      
                  deleteEventPair.then(() => {
                    this.props.dispatch(
                      getEventPairMapsStart({
                        enterprise_id: enterprise.id,
                      })
                    );       
                  }).catch((error) => {
                    console.log("error", error.message);
                      Alert({
                        text: "Delete event failed, please try again later.",
                        icon: "error",
                        timerProgressBar: true,
                        timer: 5000,
                      });
                  });
                }}
              >
                <Icon className="fas fa-trash-alt" />
              </Button>
            </Tooltip>
          </div>
        },
      },
      {
        key: "WORKFLOWS",
        Header: "Workflows",
        id: "workflows",
        show: showSOP,
        minWidth: 130,
        Cell: ({ original }) => {
          const defaultWorkflowConfigured = original.sop_maps
                                            && original.sop_maps.default_sop_maps
                                            && (
                                              original.sop_maps.default_sop_maps.all
                                              || original.sop_maps.default_sop_maps.open
                                              || original.sop_maps.default_sop_maps.close
                                            );

          const enterpriseWorkflowConfigured = original.sop_maps
                                                && original.sop_maps.enterprise_sop_maps
                                                && (
                                                  original.sop_maps.enterprise_sop_maps.all
                                                  || original.sop_maps.enterprise_sop_maps.open
                                                  || original.sop_maps.enterprise_sop_maps.close
                                                );
          
          return <div>
            {/* View Example */}
            {
              !usingEnterpriseEventPairs && <Button
                className="ml-2"
                variant="primary"
                disabled={!defaultWorkflowConfigured}
                onClick={() => {
                  this.props.dispatch(
                    setCurrentEventPairMap({
                      currentEventPairMap: {
                        type: "default_event_pair_maps",
                        name: original.name,
                        eventPairId: original.event_pair_id,
                        eventPairMapId: original.id,
                      }
                    })
                  );

                  this.setState({
                    viewExampleModalShow: true,
                  });
                }}
              >
                View example
              </Button>
            }

            {/* Configure */}
            <Tooltip
              description={enterpriseWorkflowConfigured ? "View / Configure Workflow" : "Configure Workflow"}
              placement="right"
            >
              <Button
                className="ml-2"
                variant="primary"
                style={{ width: "90px" }}
                onClick={() => {
                  this.props.dispatch(
                    setCurrentEventPairMap({
                      currentEventPairMap: {
                        type: usingEnterpriseEventPairs ? "enterprise_event_pair_maps" : "default_event_pair_maps",
                        name: original.name,
                        eventPairId: original.event_pair_id,
                        eventPairMapId: original.id,
                      }
                    })
                  );

                  this.props.dispatch(
                    setEmPriorityEventsManagementTabContent({
                      tabContent: "configure-event",
                    })
                  );
                }}
              >
                {enterpriseWorkflowConfigured ? "View" : "Configure"}
              </Button>
            </Tooltip>
          </div>
        }
      }
    ]

    return (
      <>
        <div className="card visual-data">
          <ReactTable
            ref={r => (this.reactTable = r)}
            columns={columns}
            data={usingEnterpriseEventPairs ? eventPairMaps.enterprise_event_pair_maps : eventPairMaps.default_event_pair_maps}
            filterable
            defaultFilterMethod={(filter, row) => {
              return (
                String(row[filter.id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
              );
            }}
            defaultPageSize={20}
            noDataText={noDataMsg}
            defaultSorted={[
              {
                id: "position",
                desc: false,
              },
            ]}
          />
        </div>
        <ViewExampleModal 
          show={this.state.viewExampleModalShow}
          onHide={viewExampleModalClose}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    eventPairMaps: getEventPairMaps(state, props),
    usingEnterpriseEventPairs: state.enterpriseManager.priorityEventsManagement.usingEnterpriseEventPairs,
  };
};
export default connect(mapStateToProps)(DataTable);