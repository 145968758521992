import { call, takeLeading, put } from "redux-saga/effects";

import conxtdOut from "apis/conxtdOut";

import {
  requestUpdateKeyholderStart,
  requestUpdateKeyholderFailed,
  requestUpdateKeyholderSuccess,
  requestUpdateKeyholderCancel,
  loadSiteKeyholdersStart
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

function* watchUpdateKeyholder() {
  // Reload keyholders on successful update
  yield takeLeading("REQUEST_UPDATE_KEYHOLDER_SUCCESS", function*(action) {
    yield put(
      loadSiteKeyholdersStart({
        id: action.startAction.enterprise_site_id
      })
    );
  });

  yield actionWatcher({
    actions: {
      start: requestUpdateKeyholderStart,
      failed: requestUpdateKeyholderFailed,
      success: requestUpdateKeyholderSuccess,
      cancel: requestUpdateKeyholderCancel
    },
    saga: function*(action) {
      yield call(
        conxtdOut.put,
        `/EnterpriseKeyholders/edit?enterprise_site_id=${action.enterprise_site_id}&uuid=${action.uuid}`,
        action.params
      );
    }
  });
}

export { watchUpdateKeyholder };
