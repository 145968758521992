import { call, race, take, put, takeEvery } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  loadCallLogsStart,
  loadCallLogsSuccess,
  loadCallLogsFailed,
  loadCallLogsCancel,
  getTranscriptionFailed,
  getTranscriptionSuccess,
} from "services/redux/actions/voiceCalls";

import _ from "lodash";

import conxtdOut from "apis/conxtdOut";

// one-off saga to load call logs

export function* watchLoadCallLogs() {
  yield actionWatcher({
    actions: {
      start: loadCallLogsStart,
      failed: loadCallLogsFailed,
      success: loadCallLogsSuccess,
      cancel: loadCallLogsCancel,
    },
    saga: loadCallLogsRe,
  });
}

// https://mars.conxtd.com/out_api/TwilioRequest/getCallLogs/33316
export function* loadCallLogsRe(action) {
  try {
    const { enterprise_site_id } = action;

    if (!enterprise_site_id) 
      throw new Error(
        "enterprise_site_id is required for loading call logs"
      );

    const url = `/TwilioRequest/getCallLogs/${enterprise_site_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}


// one-off saga to get the transcription of a call

export function* watchGetTranscription() {
  yield takeEvery("GET_TRANSCRIPTION_START", function*(action) {
    yield race({
      task: call(function*(action) {
        try {
          const response = yield getTranscriptionRe(action);

          yield put(
            getTranscriptionSuccess({
              ..._.omit(response, ["headers", "config"]),
              startAction: action
            })
          );
        } catch (error) {
          yield put(
            getTranscriptionFailed({
              error: {
                message: error.message,
                status: error.response && error.response.status,
                statusText: error.response && error.response.statusText
              },
              startAction: action
            })
          );
        }
      }, action),
      cancel: take(["GET_TRANSCRIPTION_CANCEL", "GET_TRANSCRIPTION_FAILED"])
    });
  });
}

// https://mars.conxtd.com/out_api/TwilioRequest/getTranscription/254/REce41ebdf40b763f1ba148702aba84a49
export function* getTranscriptionRe(action) {
  const { enterprise_id, recording_sid } = action;

  if (!enterprise_id || !recording_sid) 
    throw new Error(
      "enterprise_id and recording sid are required for getting transcription"
    );

  const url = `/TwilioRequest/getTranscription/${enterprise_id}/${recording_sid}`;

  const result = yield call(conxtdOut.get, url, action.params);

  return {
    ...result,
    recording_sid: recording_sid,
  };
}