import React from "react";
import { connect } from "react-redux";

import { Device } from '@twilio/voice-sdk';

import { Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";

import { Tooltip } from "components/Common/Tooltip/";

import { setEnterpriseCallLimits } from "services/redux/actions";
import { setVoiceCallStatus, setVoiceCallMuted } from "services/redux/actions/voiceCalls";
import { loadSOPActionMapStart, setSOPCurrentContactStatus } from "services/redux/actions/sop";

import {
  getEnterpriseFromRoute,
} from "services/redux/selectors/enterprises";

import conxtdOut from "apis/conxtdOut";

import "./VoiceCallControls.scss";

class VoiceCallControls extends React.Component {
  state = {
    device: null,
    call: null,
    callerNumber: null,
    intervalId: null,
    duration: 0,
  };

  // bindVolumeIndicators = (inputVolume, outputVolume) => {
  //   var outputVolumeBar = document.getElementById('output-volume');
  //   var inputVolumeBar = document.getElementById('input-volume');

  //   var inputColor = 'red';
  //   if (inputVolume < .50) {
  //     inputColor = 'green';
  //   } else if (inputVolume < .75) {
  //     inputColor = 'yellow';
  //   }

  //   inputVolumeBar.style.width = Math.floor(inputVolume * 300) + 'px';
  //   inputVolumeBar.style.background = inputColor;

  //   var outputColor = 'red';
  //   if (outputVolume < .50) {
  //     outputColor = 'green';
  //   } else if (outputVolume < .75) {
  //     outputColor = 'yellow';
  //   }

  //   outputVolumeBar.style.width = Math.floor(outputVolume * 300) + 'px';
  //   outputVolumeBar.style.background = outputColor;
  // }

  // only used if the call is being made from the sop action, not if the call is being made from the site slider
  sopHandleAmendAction = (enterpriseId, groupId, sopActionedEventId, sudoStateId, actionUpdateType, status, systemData, comment) => {

    const encodedComment = encodeURIComponent(comment);

    // /out_api/sop/sop-action-process/update-action/:enterprise_id/:enterprise_group_id/:sop_actioned_event_id
    // Required Parameters;
    //   action_update_type - "A" for amending an action
    //   status - the contact status you wish to change the current event to.
    //   system_data - id of the contact, i.e. keyholder
    // Optional Parameters;
    //   comment

    const updateAction = conxtdOut.post(`/sop/sop-action-process/update-action/${enterpriseId}/${groupId}/${sopActionedEventId}`, {
      action_update_type: actionUpdateType,
      status: status,
      system_data: systemData,
      comment: encodedComment,
    });

    updateAction.then(() => {
      this.props.dispatch(
        loadSOPActionMapStart({
          enterprise_id: enterpriseId,
          group_id: groupId,
          sudo_state_id: sudoStateId,
        })
      );

    }).catch((error) => {
      console.log("Error on amend action: ", error);
    })
  }

  makeCall = (enterprise, callOptions) => {
    if (callOptions.phoneNumber !== "") {

      this.setState({ duration: 0 });

      const requestToken = conxtdOut.post(`/TwilioRequest/token`, {
        enterprise_id: enterprise.id,
      });

      requestToken.then((response) => {
        this.props.dispatch(
          setEnterpriseCallLimits({ 
            enterprise_id: enterprise.id,
            call_limits: response.data.call_limits,
          })
        );

        if ((response.data.call_limits !== 100) && (response.data.call_limits !== null)) {
          const device = new Device(response.data.token);

          this.setState({
            device: device,
            callerNumber: response.data.callerNumber,
          })

          const params = {
            To: callOptions.phoneNumber,
            CallerNumber: response.data.callerNumber,
            Recordchannel: callOptions.recordChannel,
            // Transcribe: callOptions.transcribe,
            key: "B1047KuSjnQBHdkahgRnDgQ==",
          };
    
          document.getElementById('call-controls-container').classList.add("show");
          
          let connectCall = device.connect({params});
    
          connectCall.then((call) => {
            // document.getElementById('volume-indicators').style.display = 'block';

            call.on('accept', call => {

              this.props.dispatch(
                setVoiceCallStatus({
                  callStatus: "accepted"
                })
              );

              this.setState({ call });

              const intervalId = setInterval(() => {
                this.setState({ duration: this.state.duration + 1 });
              }, 1000);
              this.setState({ intervalId });

              const logCall = conxtdOut.post(`/TwilioRequest/callLogs/${callOptions.siteId}`, {
                call_sid: call.parameters.CallSid,
                mct_alarm_log_id: callOptions.mctAlarmLogId,
                to: callOptions.phoneNumber,
                keyholder_uuid: callOptions.keyholderUuid,
              });

              logCall.catch((error) => {
                console.log("Error on log call: " + error.message);
              });

              if (callOptions.origin === "sop") {
                this.sopHandleAmendAction(
                  callOptions.sop.enterpriseId,
                  callOptions.sop.groupId,
                  callOptions.sop.sopActionedEventId,
                  callOptions.sop.sudoStateId,
                  "A", // action update type ("A" for amend action)
                  "P", // contact status ("P" for Making Contact)
                  callOptions.sop.keyholderId, // system data
                  "Call attempt started" // comment
                );

                this.props.dispatch(
                  setSOPCurrentContactStatus({
                    currentContactStatus: callOptions.sop.makingContactOption,
                  })
                )
              }
            });
    
            // call.on('volume', this.bindVolumeIndicators);
    
            call.on('disconnect', call => {
              this.props.dispatch(
                setVoiceCallStatus({
                  callStatus: "ended"
                })
              );

              clearInterval(this.state.intervalId);

              const updateCallLog = conxtdOut.post(`/TwilioRequest/updateCallLogs/${enterprise.id}`, {
                call_sid: call.parameters.CallSid,
              });

              updateCallLog.catch((error) => {
                console.log("Error on update call log: " + error.message);
              });

              if (callOptions.origin === "sop") {
                this.sopHandleAmendAction(
                  callOptions.sop.enterpriseId,
                  callOptions.sop.groupId,
                  callOptions.sop.sopActionedEventId,
                  callOptions.sop.sudoStateId,
                  "A", // action update type ("A" for amend action)
                  this.props.currentContactStatus.value, // contact status - use the current contact status - user might have updated it during the call
                  callOptions.sop.keyholderId, // system data
                  "Call ended" // comment
                );
              }

              // document.getElementById('volume-indicators').style.display = 'none';
              // document.getElementById('output-volume').style.background = "white";
              // document.getElementById('input-volume').style.background = "white";
            });
    
            call.on('error', (twilioError, call) => {
              console.log('Twilio error: ', twilioError);

              this.props.dispatch(
                setVoiceCallStatus({
                  callStatus: "ended"
                })
              );
            });
    
          }).catch((error) => {
            console.log("Error on connect call: " + error.message);

            this.props.dispatch(
              setVoiceCallStatus({
                callStatus: "ended"
              })
            );
          });
        } else {
          this.props.dispatch(
            setVoiceCallStatus({
              callStatus: "idle"
            })
          );
        }

      }).catch((error) => {
        console.log("Error on request token", error);
      })
    }
  }

  redial = () => {
    const { enterprise, callOptions } = this.props;
    this.makeCall(enterprise, callOptions);
  }

  hangupCall = () => {
    this.state.device.disconnectAll();

    this.props.dispatch(
      setVoiceCallMuted({
        callMuted: false
      })
    );
  }

  toggleMuted = () => {
    const isMuted = !this.props.callMuted;
    this.state.call.mute(isMuted);
    this.props.dispatch(
      setVoiceCallMuted({
        callMuted: isMuted
      })
    ); 
  }

  closeControls = () => {
    document.getElementById('call-controls-container').classList.remove("show");

    this.props.dispatch(
      setVoiceCallStatus({
        callStatus: "idle"
      })
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.callStatus !== "initiate" && this.props.callStatus === "initiate") {
      this.props.dispatch(
        setVoiceCallStatus({
          callStatus: "initiated"
        })
      );

      const { enterprise, callOptions } = this.props;
      this.makeCall(enterprise, callOptions);
    }
  }

  render() {
    const { enterprise, callOptions, callStatus, callMuted } = this.props;

    const mutedIcon = callMuted
      ? <Icon 
          className="fas fa-volume-mute" 
          style={{
            position: "relative",
            right: "4px", 
            bottom: "4px",
          }} 
        />
      : <Icon 
          className="fas fa-volume-up" 
          style={{
            position: "relative",
            right: "4px", 
            bottom: "4px",
          }} 
        />

    const redialIcon = (
      <Icon 
        className="fas fa-phone" 
        style={{
          position: "relative",
          right: "4px", 
          bottom: "4px",
        }}
      />
    )

    const hangupIcon = (
      <Icon 
        className="fas fa-phone phone-icon-hangup"
        style={{
          position: "relative",
          right: "4px", 
          bottom: "4px",
        }}
      />
    )

    const closeIcon = (
      <Icon 
        className="fas fa-times"
        style={{
          position: "relative",
          right: "1px", 
          bottom: "3px",
        }}
      />
    )

    return(
      <div id="call-controls-container">
        <div className="call-controls">
          <div className="call-custom-ref bg-white text-dark">
            <span>{callOptions.customRef}</span>
          </div>
          <div className="call-details">
            <div className="calling-site-name">{callOptions.siteName}</div>
            <div className="calling-keyholder">{`${callOptions.keyholderName} : ${callOptions.phoneNumber}`}</div>
          </div>
          <div className="call-duration">
            {`${
                this.state.duration >= 3600 ? (String(Math.floor(this.state.duration/3600)) + ":") : ""
              }${
                String(Math.floor((this.state.duration%3600)/60)).padStart(2, '0')
              }:${
                String(this.state.duration%60).padStart(2, '0')
              }`}
          </div>
          <div className="call-control-buttons">

            {
              callStatus === "accepted" 
                ? <Button
                    className={`mute-button ${!callMuted ? "call-un-muted" : ""}`}
                    variant={callMuted ? "danger" : "primary"}
                    onClick={this.toggleMuted}
                  >
                    {mutedIcon}
                  </Button> 
                : <Button
                    className="mute-button call-un-muted"
                    variant="primary"
                    disabled
                  >
                    {mutedIcon}
                  </Button>
            }

            {
              callStatus === "initiated" 
              && <Button
                    id="button-hangup"
                    className="button-call-control"
                    variant="danger"
                    disabled
                  >
                    {hangupIcon}
                  </Button>
            }
            {
              callStatus === "accepted" 
              && <Button
                    id="button-hangup"
                    className="button-call-control"
                    variant="danger"
                    onClick={this.hangupCall}
                  >
                    {hangupIcon}
                  </Button>
            }
            {
              callStatus === "ended"
              && ((enterprise.call_limits !== 100) && (enterprise.call_limits !== null))
              && <Button
                    id="button-redial"
                    className="button-call-control"
                    variant="success"
                    onClick={this.redial}
                  >
                    {redialIcon}
                  </Button>
            }
            {
              callStatus === "ended"
              && enterprise.call_limits === 100
              && <Tooltip 
                    placement="top"
                    description="Top up required"
                  >
                    <div>
                      <Button
                        id="button-redial"
                        className="button-call-control"
                        variant="success"
                        style={{pointerEvents: "none"}}
                        disabled
                      >
                        {redialIcon}
                      </Button>
                    </div>
                  </Tooltip>
            }
            {
              callStatus === "ended"
              && enterprise.call_limits === null
              && <Tooltip 
                    placement="top"
                    description="Setup required"
                  >
                    <div>
                      <Button
                        id="button-redial"
                        className="button-call-control"
                        variant="success"
                        style={{pointerEvents: "none"}}
                        disabled
                      >
                        {redialIcon}
                      </Button>
                    </div>
                  </Tooltip>
            }
            {
              callStatus === "ended"
                ? <Button
                    id="button-close-call-controls"
                    variant="primary"
                    onClick={this.closeControls}
                  >
                    {closeIcon}
                  </Button> 
                : <Button
                    id="button-close-call-controls"
                    variant="primary"
                    disabled
                  >
                    {closeIcon}
                  </Button>
            }
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    callOptions: state.voiceCalls.callOptions,
    callStatus: state.voiceCalls.callStatus,
    callMuted: state.voiceCalls.callMuted,
    currentContactStatus: state.sop.currentContactStatus,
  };
};

export default connect(mapStateToProps)(VoiceCallControls);