/**
 * Bread crumbs
 *
 * This is probably the most neglected part of the project
 * A rewrite should be required for this one day but
 * any minor edits or additions will be manageable
 */

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { withT } from "services/translation/";

import {
  getGroupPathWithIdById,
  getCurrentGroup,
  getGroups,
} from "services/redux/selectors/groups";

import { getEnterprises } from "services/redux/selectors/enterprises";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import Loading from "components/Loading";

import _ from "lodash";

import BREADCRUMBS from "constants/BREADCRUMBS.json";

class BreadCrumbs extends React.Component {
  render() {
    let paths = window.location.toString().split("/");
    paths.splice(0, 3);

    // Remove hidden paths
    paths = paths.filter((path) => {
      const hidden = _.get(BREADCRUMBS[path], "hidden");
      return hidden !== "true";
    });

    // Don't show breadcrumbs if on top level
    if (paths.length === 1) return null;

    let parent = {};
    let grandParent = {};

    let endCrumbs = false;

    let { enterprises, reports, groups, getEventMetaById, t } = this.props;

    return (
      <nav aria-label="breadcrumb" className="pt-3">
        <ol className="breadcrumb">
          {paths.map((path, index) => {
            // No more crumbs to be shown
            if (endCrumbs) {
              return null;
            }
            // Don't show any more crumbs 3 levels after enterprise
            if (
              grandParent === "enterprise" &&
              // Disable this if a crumb is reports or management
              !paths.some(
                (p) =>
                  p === "reports" ||
                  p === "user-management" ||
                  p === "group-management" ||
                  p === "enterprise-manager"
              )
            ) {
              endCrumbs = true;
            }
            // Hide group ids
            if (grandParent === "enterprise") {
              grandParent = parent;
              parent = path;
              return null;
            }
            let pathName = _.get(BREADCRUMBS[path], "key");
            pathName = pathName && t(pathName);
            const addIndex = _.get(BREADCRUMBS[path], "index") ? "/index" : "";
            const link = "/" + paths.slice(0, index + 1).join("/") + addIndex;
            let pathElement;

            // parent is outstanding and path is number, this is a bundle view
            if (parent === "outstanding" && !isNaN(path)) {
              const conxtd_event_id = _.get(
                reports,
                "outstanding.bundle.data.conxtd_event_id"
              );
              const eventMeta = getEventMetaById(conxtd_event_id);
              pathName = eventMeta && eventMeta.caption;

              if (!pathName) {
                pathElement = <Loading />;
              } else {
                pathElement = pathName;
              }
            }

            // Parent is enterprise and this
            //  path is a number - it's an enterprise id
            if (parent === "enterprise" && !isNaN(path)) {
              const enterprise = _.find(enterprises, {
                id: parseInt(path),
              });

              pathName = _.get(enterprise, "name");
              if (enterprises.length === 0) {
                pathElement = <Loading />;
              } else {
                pathElement = pathName;
              }
              // Group because it's a child of enterprise_id
              //  ie. enterprise/{enterprise_id}/{group_id}
              // Note: actual group element is hidden...
              const grandChild = paths[index + 1];
              if (grandChild) {
                // const greatGrandchild = paths[index + 2];

                // Build path
                const groupPath = getGroupPathWithIdById(
                  groups,
                  parseInt(grandChild)
                );

                // A group path is available
                if (groupPath) {
                  // Create path crumbs
                  const groupPaths = groupPath.map(
                    ({ name, id }, groupPathIndex) => {
                      // Skip root group (it's basically the enterprise)
                      // if (index === 0) return null;
                      // Last path item - no link
                      // console.log(
                      //   name,
                      //   index + groupPathIndex,
                      //   paths.length - 1 + groupPath.length - 2
                      // );
                      if (
                        index + groupPathIndex ===
                        // Minus two from groupPath length because
                        // it's more than the path
                        paths.length - 1 + groupPath.length - 2
                      ) {
                        return (
                          <li key={id} className="breadcrumb-item">
                            {name}
                          </li>
                        );
                      }

                      return (
                        <li key={id} className="breadcrumb-item">
                          {/* <Link to={link}>{group.name}</Link> */}
                          <Link to={link + "/" + id}>{name}</Link>
                        </li>
                      );
                    }
                  );

                  grandParent = parent;
                  parent = path;

                  return (
                    <React.Fragment key={index}>
                      <li key={0} className="breadcrumb-item">
                        {/* Enterprise Breadcrumb */}
                        <Link to={link}>{pathElement}</Link>
                      </li>
                      {groupPaths}
                    </React.Fragment>
                  );
                }
              }
            } else {
              pathElement = pathName;
            }

            if (typeof pathName === "undefined") {
              pathElement = <Loading />;
            }

            const active = index === paths.length - 1;

            grandParent = parent;
            parent = path;
            return (
              <li className="breadcrumb-item" key={index}>
                {active ? pathElement : <Link to={link}>{pathElement}</Link>}
              </li>
            );
          })}
        </ol>
      </nav>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprises: getEnterprises(state),
    groups: getGroups(state),
    reports: state.reports,
    currentGroup: getCurrentGroup(state),
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default withT(
  withRouter(connect(mapStateToProps)(BreadCrumbs)),
  "breadcrumbs"
);
