/**
 * 08/21 Reports focused on numbers of events per hour
 *
 * peoplecounthourly
 */

import * as yup from "yup";
import mapValues from "lodash/mapValues";

const config = yup
  .object()
  .shape({
    title: yup.string().optional()
  })
  .optional();

const hourly = yup.array().of(
  yup.object().shape({
    hour: yup.string().required(),
    value: yup.number().required() 
  })
);

const sites = yup.lazy(obj =>
  yup
    .object(
      mapValues(obj, (value, key) =>
        yup.object().shape({
          hourly
        })
      )
    )
    .required()
);

const combined_hourly = yup 
  .array()
  .of(
    yup.object().shape({
      hour: yup.string().required(),
      value: yup.number().required() 
    })
  )
  .required();

export default yup.object().shape({
  data: yup
    .object()
    .shape({
      sites,
      combined_hourly, 
      config
    })
    .required()
});
