import { call, put } from "redux-saga/effects";

import {
    requestEagleEyeConnectStart,
    requestEagleEyeConnectFailed,
    requestEagleEyeConnectSuccess,
    requestEagleEyeConnectCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* requestEagleEyeConnect(action) {
    try {
        const params = {
            'username': action.email,
            'password': action.password,
            'integration_id': action.integration_id,
            'site_id': action.site_id,
            'expired': action.expired,
        }
        const result = yield call(conxtdOut.post, "/IntegrationAuthorisations/authentication", params);

        return result;
    } catch (error) {
        yield put(
            requestEagleEyeConnectFailed({
                error: {
                    message: error.message,
                    status: error.response && error.response.status,
                    statusText: error.response && error.response.statusText,
                  },
            })
        )
    }
}

export function* loadEagleEyeConnectWatcher() {
    yield actionWatcher({
        actions: {
            start: requestEagleEyeConnectStart,
            failed: requestEagleEyeConnectFailed,
            success: requestEagleEyeConnectSuccess,
            cancel: requestEagleEyeConnectCancel
        },
        saga: requestEagleEyeConnect
    });
}