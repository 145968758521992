// import { call, take, race, put, takeLatest } from "redux-saga/effects";
import { call, take, race, takeLatest } from "redux-saga/effects";
import * as cacheIndexes from "../../cacheIndexes/cacheIndexes";
import {
  outstandingEventPairAcknowledgementSuccess,
  outstandingEventPairAcknowledgementeFailed,
} from "services/redux/actions";
import conxtdOut from "apis/conxtdOut";

export function* watchEventPairAcknowledgement() {
  // Automatically cancels any previous sagas started
  // from here
  yield takeLatest("OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_START", function* (
    action
  ) {
    yield race({
      task: call(outstandingEventPairAcknowledgement, action),
      cancel: take([
        "OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

export function* outstandingEventPairAcknowledgement(action) {
  const { sudo_state_id } = action;

  if (!action.sudo_state_id)
    throw new Error("sudo_state_id is required for this api call.");

  const params = {
    successActionParams: {
      sudo_state_id,
    },
    apiCall: call(conxtdOut.post, `/EventPairs/ackSudoState/${sudo_state_id}`),
    successAction: outstandingEventPairAcknowledgementSuccess,
    failedAction: outstandingEventPairAcknowledgementeFailed,
    extraParams: { cacheSubKey: action.sudo_state_id },
  };

  yield cacheIndexes.restoreOrLoad(params);
}
