import React from "react";
import { connect } from "react-redux";

import { Field, reduxForm } from "redux-form";

import { Redirect } from "react-router-dom";

import { Card } from "react-bootstrap";
import LoadingButton from "components/LoadingButton";

import { default as T } from "services/i18n/Translate";

import conxtdOut from "apis/conxtdOut";

import { conxtdAdmin } from "services/redux/selectors/";

import UserType from "./UserType";
import ErrorTest from "./ErrorTest";

let SettingsForm = (props) => {
  const { submitting, handleSubmit } = props;

  if (!conxtdAdmin(props)) {
    return <Redirect to="/enterprise" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Environment (CONXTD Admins Only)</label>
        <div className="input-group">
          <Field name="environment" className="form-control" component="select">
            <option value="live">Live</option>
            <option value="staging">Staging</option>
            <option value="mars">Mars</option>
            <option value="sevenbarrows">Seven Barrows</option>
            <option value="staging2">Staging Cloud</option>
            <option value="live-test">Live Test</option>
            <option value="india">India</option>
            <option value="dockerbackend">Docker Backend</option>
            <option value="localhost">Localhost</option>
          </Field>
          <LoadingButton
            type="submit"
            className="btn btn-outline-info"
            variant="outline-info"
            loading={submitting}
            disabled={submitting}
          >
            Log Out and Select Environment
          </LoadingButton>
        </div>
      </div>
    </form>
  );
};

SettingsForm = reduxForm({
  form: "userSettings", // a unique identifier for this form
})(SettingsForm);

SettingsForm = connect((state) => {
  return {
    user: state.user,
    initialValues: {
      environment: conxtdOut.environment,
    },
  };
})(SettingsForm);

class SettingsCard extends React.Component {
  async handleSubmit(event) {
    await conxtdOut.logout();
    window.location = "/login/" + event.environment;
  }
  render() {
    return (
      <Card>
        <Card.Body>
          <Card.Title>
            <T>Settings</T>
          </Card.Title>
          <SettingsForm onSubmit={this.handleSubmit} />
          <UserType />
          <ErrorTest />
        </Card.Body>
      </Card>
    );
  }
}

export default SettingsCard;
