import React from "react";
import _ from "lodash";
import "./ErrorBadge.css";

class ErrorBadge extends React.Component {
  state = {
    intervalId: null,
    secondsRemaining: 0,
  }

  componentDidMount() {
    const timeout = _.get(this.props, "timeout");
    if (timeout) {
      this.setState({ secondsRemaining: timeout });
      const intervalId = setInterval(() => {
        this.setState({ secondsRemaining: this.state.secondsRemaining - 1 });
      }, 1000);
      this.setState({ intervalId });
    }
  }

  componentDidUpdate(prevProps) {
    const timeout = _.get(this.props, "timeout");
    if (timeout !== _.get(prevProps, "timeout")) {
      if (timeout) {
        this.setState({ secondsRemaining: timeout });
        const intervalId = setInterval(() => {
          this.setState({ secondsRemaining: this.state.secondsRemaining - 1 });
        }, 1000);
        this.setState({ intervalId });
      }
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    if (intervalId) clearInterval(intervalId);
  }

  render() {
    const message = _.get(this.props, "message");
    const source = _.get(this.props, "source");
    const timeout = _.get(this.props, "timeout");

    const { secondsRemaining, intervalId } = this.state;
    if (secondsRemaining === 0 && intervalId) {
      clearInterval(intervalId);
    }

    if (!message) {
      return null;
    }

    let style = "mb-4";

    style = source === 'eec' ? ' mt-3' : style;

    return (
      <div className={`text-center ${style}`}>
        <span className="badge badge-primary" id="ErrorBadge">
          <h6 className="mt-2 px-2 py-1" style={{ fontSize: "12px" }}>
            {message}{timeout && `${secondsRemaining > 0 ? " in" : " now."}`}
            <br />
            {
              timeout &&
                secondsRemaining > 0 && `${
                  String(Math.floor((secondsRemaining%3600)/60)).padStart(1, '0')
                }:${
                  String(secondsRemaining%60).padStart(2, '0')
                }`
            }
          </h6>
        </span>
      </div>
    );
  }
}

export default ErrorBadge;
