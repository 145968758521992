import React from "react";

import moment from "services/locale/momentInit.js";

import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { default as T } from "services/i18n/Translate";

import { weekStart } from "services/locale/constants";
import { getWeekRange } from "services/locale/selectors";

// Determines the week start based on user's locale
const WEEK_START = weekStart();

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, "days")
        .toDate()
    );
  }
  return days;
}

class WeekSelect extends React.Component {
  state = {
    hoverRange: undefined,
    selectedDays: [],
    weekNo: this.props.selectedWeek
  };
  componentDidMount() {
    // Get start of week
    const from = moment()
      .week(this.state.weekNo)
      .startOf("isoWeek")
      .toDate();
    // Get selected days (for hover)
    const days = getWeekDays(from);
    this.setState(
      {
        selectedDays: days
      },
      () => {
        this.changeWeek(this.state.weekNo, this.state.selectedDays);
      }
    );
  }

  handleDayChange = (date, { disabled }) => {
    if (disabled) return;
    const days = getWeekDays(getWeekRange(date).from);

    const weekNo = moment(date).isoWeek();

    this.changeWeek(weekNo, days);
  };

  handleDayEnter = date => {
    this.setState({
      hoverRange: getWeekRange(date)
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.changeWeek(weekNumber, days);
  };

  changeWeek = (weekNo, selectedDays) => {
    this.setState({
      selectedDays,
      weekNo
    });
    this.props.changeWeek(weekNo, moment(selectedDays[0]).format("YYYY"));
  };

  render() {
    const { hoverRange, selectedDays, weekNo } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6]
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6]
    };

    const weekFormatted =
      (weekNo === moment().isoWeek() - 1 && <T>Last Week</T>) ||
      (weekNo === moment().isoWeek() && <T>This Week</T>) ||
      (weekNo === moment().isoWeek() + 1 && <T>Next Week</T>);

    return (
      <div className="SelectedWeek text-center">
        <DayPicker
          firstDayOfWeek={WEEK_START}
          selectedDays={selectedDays}
          showWeekNumbers
          modifiers={modifiers}
          onDayClick={this.handleDayChange}
          onDayMouseEnter={this.handleDayEnter}
          onDayMouseLeave={this.handleDayLeave}
          onWeekClick={this.handleWeekClick}
          disabledDays={[
            {
              after: new moment().endOf("isoWeek").toDate(),
              // No data available before July 2019
              before: new Date(2019, 6, 1)
            }
          ]}
        />
        {selectedDays.length === 7 && (
          <div>
            {weekFormatted} ({moment(selectedDays[0]).format("LL")} –{" "}
            {moment(selectedDays[6]).format("LL")})
          </div>
        )}
      </div>
    );
  }
}

export default WeekSelect;
