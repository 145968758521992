/**
 * Site integration camera loader
 *
 * Load available cameras for attaching to a site
 */
import { call } from "redux-saga/effects";

import {
  loadSiteCamerasSuccess,
  loadSiteCamerasFailed,
  loadSiteCamerasStart,
  loadSiteCamerasCancel
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import { mockEnabled } from "services/redux/selectors/";

import conxtdOut from "apis/conxtdOut";

import camerasMock from "./__mocks__/cameras.mock.json";

function* loadCameras({ camera_integration_site_id }) {
  try {
    const result = yield mockEnabled()
      ? call(() => {
          return {
            data: camerasMock
          };
        })
      : call(
          conxtdOut.get,
          `/CameraSudoSites/add/${camera_integration_site_id}`
        );

    return result;
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Integration load watcher saga
 */
export function* loadCamerasWatcher() {
  yield actionWatcher({
    actions: {
      start: loadSiteCamerasStart,
      failed: loadSiteCamerasFailed,
      success: loadSiteCamerasSuccess,
      cancel: loadSiteCamerasCancel
    },
    saga: loadCameras
  });
}
