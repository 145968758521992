import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import get from "lodash/get";

import Downshift from "downshift";

import Loading from "components/Loading";

import { withT } from "services/translation/";

import {
  globalSearchToggle,
  resetGroupOverviewFilter,
} from "services/redux/actions";

import {
  getGroups,
  getGroupsLoading,
  getGroupPath,
  flattenGroups,
} from "services/redux/selectors/groups";
import { getSites, getSitesLoading } from "services/redux/selectors/sites";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { getRouterParams } from "services/redux/selectors/app/";

import { getGroupPathFromSite } from "services/redux/selectors";

import { contains } from "services/filters/";

class AutocompleteInput extends React.Component {
  // UNSAFE_componentWillReceiveProps = nextProps => {
  //   console.log(nextProps);
  //   // New value incoming and search bar is being opened
  //   if (this.props.open !== nextProps.open && nextProps.open) {
  //     console.log("open???");
  //     // This isn't focusing because it's happening too soon
  //     console.log(this.searchInput);
  //     // Focus search bar on open
  //     this.searchInput.focus();
  //   }
  // };
  componentDidUpdate = () => {
    if (!this.props.open) return;
    this.searchInput.focus();
  };
  getStringItems = (filter) => {
    const { t } = this.props;
    const { groups, sites, enterprise } = this.props;
    let options = [];
    // Add action item for full search
    const searchEnterpriseFor = {
      type: "action",
      id: "search-all",
      name: t("search_x_for_y", enterprise.name, filter),
      className: "search-enterprise-for",
    };
    if (filter.length > 0) {
      options = [searchEnterpriseFor];
    }

    // If there are no groups or sites, don't filter
    if (groups.length === 0 || sites.length === 0) {
      return options;
    }

    // Available autocomplete options
    options = [
      ...options,
      // groups.reduce - reduce groups children until they all have their own index in the flattened array
      // ...groups.reduce((acc, group) => {}, []),
      ...flattenGroups(groups)
        .map((group) => {
          const groupPath = getGroupPath(groups, group).slice(0, -1);

          return {
            ...group,
            name: t("group_x", group.name),
            type: "group",
            suffix: groupPath.join(" > "),
          };
        })
        .sort((a, b) => {
          return a.suffix.length > b.suffix.length ? 1 : -1;
        }),
      ...sites
        // Hide ungrouped sites
        .filter(({ enterprise_groups }) => enterprise_groups.length > 0)
        .map(({ id, name, site_fields, ...rest }) => {
          const groupPath = getGroupPathFromSite(groups, rest);

          const filter_site_fields = site_fields.filter(function (field) {
            return field.enterprise_site_id === id;
          });

          let custom_field = !filter_site_fields
            ? null
            : filter_site_fields.map((element) => element.value);

          let custom_field_string = custom_field + "";

          return {
            id,
            custom_field_first_value: !filter_site_fields
              ? null
              : custom_field_string,
            type: "site",
            name: t("site_x", name),
            suffix: groupPath || "",
            ...rest,
          };
        })
        .sort((a, b) => {
          return a.suffix.length > b.suffix.length ? 1 : -1;
        }),
    ];

    return options.filter(
      (item) => {
        if (filter.length === 0) return true;
        return Object.keys(item).reduce((keep, key) => {
          // Skip non string properties
          if (typeof item[key] !== "string") return keep;
          return contains({ filter, value: item[key] }) || keep;
        }, false);
      }
      // Site name
      // contains({
      //   filter,
      //   value: item.name
      // }) ||
      // contains({
      //   filter,
      //   value: item.name
      // })
    );
  };
  onSelect = (selectedItem) => {
    const { enterprise_id } = this.props.router.params;

    if (!selectedItem) return;

    switch (selectedItem.type) {
      case "group":
        this.props.history.push(
          `/enterprise/${enterprise_id}/${selectedItem.id}`
        );
        break;
      case "site":
        const group_id = get(selectedItem, "enterprise_groups.0.id");

        this.props.history.push(
          `/enterprise/${enterprise_id}/${group_id}/${selectedItem.id}`
        );
        break;
      default:
    }
    this.props.dispatch(resetGroupOverviewFilter());
    // This will close the search
    this.searchInput.blur();
    // this.closeSearch();
  };
  closeSearch = () => {
    // Reset search input on select
    // this.props.dispatch(globalSearchChange({ value: "" }));
    this.props.dispatch(globalSearchToggle({ open: false }));
  };
  changeInput = (value) => {
    // this.props.dispatch(globalSearchChange({ value }));
  };
  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          // isOpen: state.isOpen,
          highlightedIndex: state.highlightedIndex,
          // Clear input
          inputValue: "",
        };
      case Downshift.stateChangeTypes.blurInput:
        return {
          ...changes,
          // Preserve input value from click
          inputValue: state.inputValue,
        };
      default:
        return changes;
    }
  };
  render() {
    const { open, t } = this.props;

    const loading =
      this.props.sitesLoading !== false || this.props.groupsLoading !== false;

    return (
      <Downshift
        // selectedItem={value}
        stateReducer={this.stateReducer}
        // inputValue={value}
        // onInputValueChange={this.changeInput}
        onSelect={this.onSelect}
        itemToString={(item) => (item ? item.name : "")}
      >
        {({
          // getLabelProps,
          getInputProps,
          getToggleButtonProps,
          getMenuProps,
          getItemProps,
          isOpen,
          clearSelection,
          selectedItem,
          inputValue,
          highlightedIndex,
        }) => (
          <div className="autocomplete-search">
            <input
              {...getInputProps({
                open: isOpen,
                placeholder: t("placeholder"),
                onBlur: this.closeSearch,
                onChange: this.changeInput,
              })}
              ref={(input) => {
                this.searchInput = input;
              }}
              className="search-input"
              type="search"
              // value={value}
              // onChange={e => {
              //   this.props.dispatch(
              //     globalSearchChange({ value: e.target.value })
              //   );
              // }}
            />

            <div style={{ position: "relative" }}>
              <ul
                {...getMenuProps({ open: isOpen })}
                className={
                  "bg-lightgrey " + (open && isOpen ? "show-suggestions" : "")
                }
              >
                {loading && (
                  <li className="search-item autocomplete-loading">
                    <Loading />
                  </li>
                )}
                {this.getStringItems(inputValue).map((item, index) => (
                  <li
                    className={
                      "text-dark search-item " +
                      item.type +
                      " " +
                      (item.className || "")
                    }
                    key={item.type + item.id}
                    {...getItemProps({
                      item,
                      index,
                      // active: (highlightedIndex === index).toString(),
                      // selected: selectedItem === item
                    })}
                  >
                    <span>{item.name}</span>
                    {item.suffix && (
                      <span className="text-muted float-right">
                        {item.suffix}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    value: state.app.search.value,
    open: state.app.search.open,
    groups: getGroups(state, props),
    groupsLoading: getGroupsLoading(state, props),
    sites: getSites(state),
    sitesLoading: getSitesLoading(state, props),
    router: {
      params: getRouterParams(state, props),
      // location: getRouterLocation(state, props)
    },
    enterprise: getEnterpriseFromRoute(state, props),
  };
};
export default withRouter(
  withT(connect(mapStateToProps)(AutocompleteInput), "nav.search")
);
