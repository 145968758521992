import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { showingOnlyText } from "services/redux/selectors/reports/scheduled/interval";

import Icon from "components/Icons/Icon";

import { getScheduledFilter } from "services/redux/selectors/reports/scheduled/";

class IntervalFilter extends React.Component {
  render() {
    const { filter } = this.props;

    return (
      <span
        className={`filter-hour text-light`}
        style={{
          padding: "0.3rem 0.6rem"
        }}>
        {showingOnlyText(filter.interval)}{" "}
        <span
          className="remove-filter"
          onClick={e => {
            this.props.changeIntervalFilter(null);
          }}>
          <Icon className="fas fa-times" />
        </span>
      </span>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    filter: getScheduledFilter(state, props)
  };
};
export default withRouter(connect(mapStateToProps)(IntervalFilter));
