import { all } from "redux-saga/effects";

import {
    loadOpenCloseWatcher,
    linksToSiteView
} from "./openClose";

export function* rootSaga() {
    yield all([
        loadOpenCloseWatcher(),
        linksToSiteView(),
    ]);
}