import React from "react";

import { Card, Row, Col } from "react-bootstrap";

import VideoLoftConnect from "../VideoLoft/VideoLoftConnect";
import AddCamera from "../VideoLoft/AddCamera/";
import CameraCardList from "../VideoLoft/CameraCardList";

// import CameraCardList from "./CameraCardList";

// import AddCamera from "./AddCamera/";

export default function ChubbView({ integration }) {
    const chubbViewConnected = !!integration.camera_integration_site;

    return (
        <Card className="channel-card bg-light">
            <Card.Body>
                <Row>
                    <Col className="text-center mb-4">
                        <img alt={integration.integration_name}
                            src={integration.logo}
                            style={{
                                maxWidth: "80%"
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <Card.Subtitle
                            style={{
                                lineHeight: "35px",
                                position: "absolute",
                                bottom: "0"
                            }}>
                            ChubbView
                        </Card.Subtitle>
                    </Col>
                    <Col md="6" className="text-right">
                        <VideoLoftConnect {...integration} />
                    </Col>
                </Row>
                {chubbViewConnected && (
                    <>
                        <Row className="mt-2">
                            <Col>
                                <AddCamera
                                integration={integration}
                                    auth_token={integration.auth_token}
                                    camera_integration_site_id={
                                        integration.camera_integration_site
                                            .camera_integration_site_id
                                    }
                                    subdomain={integration.subdomain}
                                    account_id={integration.account_id}
                                    auth_expired={integration.camera_integration_site.auth_expired}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <CameraCardList integration={integration} />
                        </Row>
                    </>
                )}
            </Card.Body>
        </Card>
    )
}