import { put, delay, cancelled } from "redux-saga/effects";

import moment from "services/locale/momentInit.js";

import { findCache, cacheResponse } from "./debounce";

/**
 * liveRefreshFor
 *
 * Turns your saga into a live refreshing saga!
 *
 * @params: {
 *  startAction,
 *  startActionParams,
 *  successAction,
 *  failedAction,
 *  apiCall,
 *  [interval],
 *  [successActionParams],
 *  [refresh],
 *
 *  [retry]
 * }
 *
 * @param retry If the call fails, retry the api call after delay.
 *
 * - startActionParams: params passed to the start action so we can re-invoke for auto refresh
 */
export function* liveRefreshFor(params) {
  let {
    startAction,
    startActionParams,
    successAction,
    // Extra props to add to the result
    successActionParams,
    failedAction,
    apiCall,
    interval,
    key,
    debounce = true
  } = params;

  if (
    !startAction ||
    !startActionParams ||
    !successAction ||
    !failedAction ||
    !apiCall ||
    // It's an optional parameter
    //!interval ||
    !key
  ) {
    throw new Error(
      "liveRefreshFor - missing parameter: {" +
        Object.keys(params).join(", ") +
        "} Expected: { startAction, successAction, failedAction, apiCall, key, [interval] }"
    );
  }

  // do {

  try {
    // Attempt to find a valid cache for this call,
    // to prevent unecessary repeated api calls
    let response = findCache(startActionParams, interval);
    const shouldUpdate = !debounce || !response;
    // No cache was found, refresh from the api
    if (shouldUpdate) {
      response = yield apiCall;
      // Cache this response for subsequent calls
      cacheResponse(startActionParams, response);
    }

    const lastRefresh = interval && new moment();
    const nextRefresh = interval && new moment(lastRefresh + interval);

    const result = {
      nextRefresh,
      lastRefresh,
      ...successActionParams,
      refreshing: startActionParams.refreshing || false,
      key
    };

    // result.open/result.close = response.data
    // _.set(result, key, response.data);
    result[key] = response.data;

    // console.log(result);

    // if (!response.data) throw new Error("Server returned null response");

    yield put(
      successAction({
        ...result,
        startAction: startActionParams
      })
    );

    // This should restart after a delay
    if (params.refresh || false) {
      // Delay
      yield delay(interval);

      // Auto refresh
      yield put(startAction({ ...startActionParams, refreshing: true }));
    }
  } catch (error) {
    yield put(
      failedAction({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...params
      })
    );

    if (params.refresh || params.retry || false) {
      // Retry after interval
      // Delay
      yield delay(interval);
      // Auto refresh
      yield put(startAction({ ...startActionParams, refreshing: true }));
    }
  } finally {
    // Cancels the loop
    if (yield cancelled()) {
      return false;
    }
  }
  // } while (_.get(params, "refresh"));
}
