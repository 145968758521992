import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getEnterpriseFromRoute } from "services/redux/selectors";
import { getGroupFromRoute } from "services/redux/selectors/groups";

import { withT } from "services/translation/";

import EnterpriseLogo from "components/Enterprise/Logo";

import NavLink from "components/Layout/Nav/NavLink";

import _ from "lodash";

class EnterpriseMenu extends React.Component {

  render() {
    const { enterprise, pathname, group, t, user, userAccess, selectedTab } = this.props;

    let groupId = group.id;
    if (groupId === "root" && enterprise.root_group_id) {
      groupId = enterprise.root_group_id;
    }

    // //alarm-response
    // // only enable the link to the alarm response page if the user has the Admin role for 
    // // the current group and all it's sub-groups
    // let showAlarmsLink = false;
    // if ((enterprise.id !== -1) && (enterprise.in_alarm && enterprise.in_alarm !== "N") && (userRole.loading === false) && userRole && group && groups) {
  
    //   /// get the full tree structure of all the nested sub groups
    //   let currentGroupTree = [];
    //   if ((group.id === "root") || (group.id === enterprise.root_group_id)) {
    //     currentGroupTree = groups;
    //   } else {
    //     currentGroupTree = [getGroupById(groups, group.id)]; // needs to be an array for the flattenGroups function coming up
    //   }
    
    //   // check if there is any sub group in the tree that the user doesn't have the Admin role for:
    //   // Flatten the sub groups into a single flat array
    //   // then go through them to find the index of the first one without the Admin role
    //   const nonAdminGroupIndex = _.findIndex(flattenGroups(currentGroupTree), (group) => {
    //     const userRoleGroup = _.find(userRole.groups, ['id', group.id]); // userRole.groups contains all the groups the user has access to and their role for each group
    //     if (userRoleGroup) {
    //       return userRoleGroup.role_name !== "Admin";
    //     } else {
    //       return false;
    //     }
    //   });

    //   // if all children of the current group have the Admin role
    //   // show the alarm view link
    //   if (nonAdminGroupIndex === -1) {
    //     showAlarmsLink = true;
    //   }
    // }

    // check whether the user has the Priority Events feature for the current enterprise or group
    let showAlarmsLink = false;
    if ((enterprise.id !== -1) && (enterprise.in_alarm && enterprise.in_alarm !== "N") && (userAccess.loading === false) && userAccess && group) {
      if ((group.id === "root") || (group.id === enterprise.root_group_id)) {
        if (_.find(userAccess.enterprise.features, ['id', 10])) { // id=10 for Priority Events
          showAlarmsLink = true;
        }
      } else {
        const groupAccess = _.find(userAccess.groups, ['id', group.id]);
        if (groupAccess && groupAccess.features && _.find(groupAccess.features, ['id', 10])) { // id=10 for Priority Events
          showAlarmsLink = true;
        }
      }
    }

    // // check whether the user has the Custom Reports feature for the current enterprise or group
    // let showCustomReportsLink = false;
    // if ((enterprise.id !== -1) && (userAccess.loading === false) && userAccess && group) {
    //   if ((group.id === "root") || (group.id === enterprise.root_group_id)) {
    //     if (_.find(userAccess.enterprise.features, ['id', 12])) { // id=12 for Custom Reports
    //       showCustomReportsLink = true;
    //     }
    //   } else {
    //     const groupAccess = _.find(userAccess.groups, ['id', group.id]);
    //     if (groupAccess && groupAccess.features && _.find(groupAccess.features, ['id', 12])) { // id=12 for Custom Reports
    //       showCustomReportsLink = true;
    //     }
    //   }
    // }

    return (
      <>
        <li>
          <EnterpriseLogo enterprise={enterprise} />
        </li>

        <NavLink
          to={`/enterprise/${enterprise.id}`}
          label={t("overview")}
          pathname={pathname}
          icon="fas fa-tachometer-alt"
        />
        {/*
        <NavLink
          to={`/enterprise/${enterprise.id}/root/reports`}
          label={t("reports")}
          pathname={pathname}
          // icon="fas fa-chart-pie"
          icon="fas fa-chart-pie"
        />
*/}
        {showAlarmsLink && <NavLink //alarm-response
          to={`/enterprise/${enterprise.id}/${groupId}/alarms`}
          label={t("alarm_response")}
          pathname={pathname}
          icon="fas fa-exclamation-triangle"
        />}
        <NavLink
          to={`/enterprise/${enterprise.id}/${groupId}/custom-reports`}
          label={t("custom_reports")}
          pathname={pathname}
          icon="fa fa-binoculars"
        />
        <NavLink
          to={`/enterprise/${enterprise.id}/${groupId}/user-generated-report`}
          label={t("user_generated_report")}
          pathname={pathname}
          icon="fa fa-table"
        />
        {/* <NavLink
          to={`/enterprise/${enterprise.id}/${groupId}/reports`}
          label={t("reports")}
          pathname={pathname}
          icon="fa fa-chart-pie"
        /> */}
        <NavLink
          to={`/enterprise/${enterprise.id}/${groupId}/enterprise-manager?tab=${selectedTab}`}
          label={t("enterprise_manager")}
          dataIntercomTarget="Enterprise Manager"
          pathname={pathname}
          icon="fas fa-building"
        />
        <NavLink
          to={`/enterprise/${enterprise.id}/${groupId}/user-manager`}
          label={t("user_management")}
          pathname={pathname}
          icon="fa fa-users"
        />
        <NavLink
          to={`/enterprise/${enterprise.id}/${groupId}/enterprise-manager?tab=integrations`}
          label={t("apps_manager")}
          pathname={pathname}
          icon="fas fa-th-large"
        />
        {/* <NavLink
          to={`/enterprise/${enterprise.id}/${group.id}/group-management`}
          label={t("group_management")}
          pathname={pathname}
          icon="fas fa-layer-group"
        /> */}

        {/* <NavLink
              to={`/enterprise/${enterprise.id}/setup`}
              label={t("setup")}
              pathname={pathname}
              icon="fas fa-cogs"
            /> */}
        {(user.environment !== "india") && <NavLink
          // to={`/enterprise/${enterprise.id}/notifications`}
          to={`https://launchpad.conxtd.com`}
          external={1}
          label={t("notification_manager")}
          pathname={pathname}
          icon="fas fa-bell"
        />}
      </>
    );
  }
}
EnterpriseMenu.propTypes = {
  enterprise: PropTypes.object.isRequired,
  // pathname: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props) || {
      id: -1,
      name: "",
      logo_url: null,
    },
    group: getGroupFromRoute(state, props) || {
      id: "root",
    },
    // userRole: state.user.access,//alarm-response
    // groups: getGroups(state, props),//alarm-response
    user: state.user,
    userAccess: state.user.access,
    selectedTab: state.enterpriseManagerPersist.selectedTab || "Groups",
  };
};
export default withT(connect(mapStateToProps)(EnterpriseMenu), "nav.sidebar");
