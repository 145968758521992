import React from "react";

const panic = props => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 17.818 17.818">
    <g>
      <g>
        <path
          d="M8.909,6.528c1.315,0,2.381,1.066,2.381,2.381c0,1.314-1.065,2.381-2.381,2.381c-1.315,0-2.381-1.066-2.381-2.381   C6.528,7.594,7.594,6.528,8.909,6.528z M15.209,2.611c-0.27-0.27-0.705-0.27-0.973,0c-0.271,0.268-0.271,0.703,0,0.973   c1.422,1.424,2.205,3.313,2.205,5.324c0,2.015-0.783,3.906-2.205,5.328c-0.271,0.27-0.271,0.705,0,0.975   c0.134,0.134,0.31,0.201,0.486,0.201c0.176,0,0.354-0.067,0.485-0.201c1.685-1.684,2.61-3.92,2.61-6.303   C17.818,6.531,16.893,4.292,15.209,2.611z M1.376,8.907c0-2.012,0.784-3.902,2.206-5.325c0.271-0.27,0.271-0.705,0-0.974   c-0.268-0.269-0.705-0.269-0.973,0C0.926,4.292,0,6.53,0,8.907c0,2.383,0.927,4.619,2.609,6.303   c0.135,0.135,0.31,0.201,0.485,0.201c0.177,0,0.353-0.066,0.486-0.201c0.271-0.268,0.271-0.703,0-0.975   C2.16,12.813,1.376,10.923,1.376,8.907z M13.141,4.679c-0.27-0.269-0.705-0.269-0.974,0c-0.269,0.27-0.269,0.705,0,0.975   c0.87,0.869,1.349,2.025,1.349,3.255s-0.479,2.388-1.349,3.258c-0.269,0.271-0.269,0.705,0,0.974   c0.134,0.138,0.312,0.202,0.485,0.202c0.176,0,0.354-0.064,0.486-0.202c1.131-1.131,1.754-2.632,1.754-4.231   C14.893,7.311,14.271,5.809,13.141,4.679z M5.651,4.679c-0.27-0.269-0.705-0.269-0.974,0C3.548,5.809,2.925,7.31,2.925,8.907   c0,1.601,0.623,3.104,1.753,4.231c0.134,0.138,0.312,0.203,0.486,0.203c0.176,0,0.354-0.065,0.486-0.203   c0.27-0.269,0.27-0.703,0-0.974C4.78,11.297,4.3,10.139,4.3,8.907c0-1.229,0.479-2.388,1.35-3.257   C5.921,5.382,5.921,4.948,5.651,4.679z"
          className="active-path"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
);

export default panic;