/**
 * Customisations for child rows, i.e. expansion
 * of occurrences
 */

import React from "react";

import EventCell from "components/Common/DataTable/EventCell/";
import AbnormalEvent from "components/Common/DataTable/AbnormalEvent/";

import {
  getType,
  getEventColour
} from "services/redux/selectors/reports/scheduled/suspicious";

import {
  getExpectedTime,
  getEventText,
  // getActualFromOccurrence,
  getNotExpected,
  getDiffFromOccurrence
} from "services/redux/selectors/reports/scheduled";

import { NA } from "services/redux/selectors";

import { getGroupPathFromSite } from "services/redux/selectors";

import ActualTimezoneDate from "components/Enterprise/Reports/Types/Scheduled/DataTable/ActualTimezoneDate.js";

const ChildRowColumns = [
  {
    key: "CUSTOM_ID",
    content: ({ site }) => site.custom_id || NA()
  },
  {
    key: "NAME",
    content: ({ occurrence, site }) => {
      const tooltip = getNotExpected(occurrence) ? AbnormalEvent : null;
      return (
        <span>
          {tooltip} {site.name}
        </span>
      );
    },
    className: "text-muted"
  },
  {
    key: "GROUP",
    content: ({ site, groups }) => getGroupPathFromSite(groups, site) || NA(),
    className: "text-muted"
  },
  {
    key: "SITE_REF",
    content: ({ site }) => site.site_ref || site.wwo_id,
    className: "text-muted"
  },
  {
    key: "TYPE",
    className: "text-muted",
    content: function({ occurrence }) {
      return (
        <EventCell
          event={{
            colour: getEventColour(occurrence),
            label: getType(occurrence)
          }}
          onClick={() => {}}
          focus={true}
        />
      );
    }
  },
  {
    key: "RESULT",
    className: "text-light",
    content: ({ eventMeta, focus, underline }) => {
      return (
        <EventCell
          event={eventMeta}
          focus={focus}
          underline={underline}
          onClick={() => {}}
        />
      );
    },
    style: ({ eventMeta }) => {
      return {
        padding: "0px"
        // backgroundColor: eventMeta.colour,
        // margin: "1px 1px 0px -1.6px"
      };
    }
  },
  {
    key: "EXPECTED",
    content: ({ site }) => getExpectedTime({ site })
  },
  {
    key: "ACTUAL",
    // content: ({ occurrence }) => getActualFromOccurrence(occurrence)
    content: ({ occurrence }) => <ActualTimezoneDate occurrence={occurrence} />
  },
  {
    key: "DIFFERENCE",
    content: ({ occurrence }) => getDiffFromOccurrence(occurrence)
  },
  {
    key: "EVENT_TEXT",
    content: ({ occurrence }) => getEventText(occurrence)
  },
  {
    key: "OCCURRENCES",
    className: "text-center",
    content: ({ index }) => "#" + (index + 1)
  },
  {
    key: "EXPAND",
    content: null
  }
];

export default ChildRowColumns;