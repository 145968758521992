import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import conxtdOut from "apis/conxtdOut";

// let ticking = false;

class SupportPlugin extends React.Component {
  state = {
    vertical_padding: 0,
    intercomUserInitialising: false,
    intercomUserInitialised: false,
  };

  componentDidMount() {
    const { user } = this.props;
    if ((user.environment !== "india") && window.Intercom && user && user.conxtdId) {
      this.intercomUserInitialise();
    }
  }

  componentDidUpdate(prevProps) {
    const { intercomUserInitialising, intercomUserInitialised } = this.state;
    const { user } = this.props;

    if ((user.environment !== "india") && window.Intercom && user && user.conxtdId && user !== prevProps.user && !intercomUserInitialising && !intercomUserInitialised) {
      this.intercomUserInitialise();
    } else if ((!user || !user.conxtdId) && user !== prevProps.user) {
      this.setState({ intercomUserInitialised: false });
    }
  }

  intercomUserInitialise() {
    this.setState({ intercomUserInitialising: true });

    const { production } = this.props.app;
    const app_id = production ? "kxvmqpm2" : "a2pinnho";

    const { conxtdId, email, firstName, lastName } = this.props.user;

    const { vertical_padding } = this.state;

    const user = {
      user_id: conxtdId,
      email,
      name: firstName + " " + lastName,
      vertical_padding
      // horizontal_padding: 40
    };

    const getIntercomHash = conxtdOut.post(`/intercom/get-hash`, { identifier: conxtdId });
    
    getIntercomHash.then((response) => {
      window.Intercom("boot", {
        // api_base: "https://api-iam.intercom.io",
        app_id,
        user_hash: response.data.intercom_user_hash,
        ...user,
      });
    }).catch((error) => {
      console.log("error", error.message);
      window.Intercom("boot", {
        app_id,
        // ...user,
      });
    });

    this.setState({
      intercomUserInitialising: false,
      intercomUserInitialised: true
    });
  }

  // componentDidUpdate() {
  //   this.updatePosition();
  // }
  // componentDidMount() {
  //   this.updateVerticalPadding(this.props.hasOwnProperty("loginPage") ? 0 : 45);
  //   // this.props.history.listen((location, action) => {
  //   //   console.log("on route change");
  //   //   this.updatePosition();
  //   // });
  //   const scrollingElement = document.querySelector(".page-content-wrapper");
  //   scrollingElement &&
  //     scrollingElement.addEventListener(
  //       "scroll",
  //       () => {
  //         // Performance enhancer
  //         if (!ticking) {
  //           window.requestAnimationFrame(() => {
  //             this.updatePosition();
  //             ticking = false;
  //           });

  //           ticking = true;
  //         }
  //       },
  //       false
  //     );
  // }
  // componentWillUnmount() {
  //   const scrollingElement = document.querySelector(".page-content-wrapper");
  //   scrollingElement &&
  //     scrollingElement.removeEventListener(
  //       "scroll",
  //       this.updatePosition,
  //       false
  //     );
  // }
  // // Scroll the intercom box at bottom right up with the
  // //   footer of the window
  // updatePosition = () => {
  //   const scrollingElement = document.querySelector(".page-content-wrapper");
  //   if (!scrollingElement) return;
  //   const { clientHeight, scrollHeight } = scrollingElement;
  //   // const scrollBarVisible = $(document).height() > $(window).height();
  //   // Scrollbar is visible as scrollable area is bigger than container
  //   const scrollBarVisible = scrollHeight !== clientHeight;
  //   // document.querySelector(".page-content-wrapper").height() >
  //   // window.innerHeight;
  //   if (!scrollBarVisible) {
  //     this.updateVerticalPadding(45);
  //     return;
  //   }
  //   const scrollPos = document.querySelector(".page-content-wrapper").scrollTop;

  //   const diff = clientHeight + scrollPos - scrollHeight + 45;
  //   if (diff >= 0) {
  //     this.updateVerticalPadding(diff);
  //     return;
  //   }
  //   this.updateVerticalPadding(0);
  // };
  // updateVerticalPadding = vertical_padding => {
  //   if (vertical_padding === this.state.vertical_padding) return;
  //   this.setState({ vertical_padding });
  // };
  render() {
    const { production } = this.props.app;
    const app_id = production ? "kxvmqpm2" : "a2pinnho";

    // Intercom hasn't appeared yet
    if (!window.Intercom) return null;

    if (!this.props.user || !this.props.user.conxtdId) {
      // intercom shows on the login screen even if the user is not logged in
      window.Intercom("boot", {
        app_id
      });
      // return null;
    }

    return null;
  }
}

const mapStateToProps = state => {
  return { user: state.user, app: state.app };
};
export default withRouter(connect(mapStateToProps)(SupportPlugin));
