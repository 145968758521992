// import REPORTS from "constants/REPORTS";
import moment from "services/locale/momentInit.js";

import { createSelector } from "reselect";

import { getWeekRange } from "services/locale/selectors";

import { getReports } from "../common";
import { getReportType } from "../router";

// const getReportMeta = props => {
//   return REPORTS[props.reportType];
// };

export const getExceptionReport = createSelector(
  [getReports, getReportType],
  (reports, reportType) => reports.exception[reportType]
);

export const getExceptionReportTitle = props => {
  const {
    reportType,
    selectBy,
    selectedWeek,
    selectedYear,
    selectedMonth
  } = props;

  let reportDate;
  if (selectBy === "month") {
    reportDate = new moment(
      selectedMonth + " " + selectedYear,
      "MM YYYY"
    ).format("MMMM YYYY");
  } else {
    // Get locale week range
    const weekRange = getWeekRange(
      new moment(selectedWeek + " " + selectedYear, "ww YYYY")
    );
    const from = new moment(weekRange.from).format("DD.MM.YY");
    const to = new moment(weekRange.to).format("DD.MM.YY");
    reportDate = from + " to " + to;
  }

  return reportType[0].toUpperCase() + reportType.slice(1) + " " + reportDate;
};
