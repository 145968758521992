import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  getBrivoSitesStart,
  getBrivoSitesFailed,
  getBrivoSitesSuccess,
  getBrivoSitesCancel,
  getBrivoLinkedSitesStart,
  getBrivoLinkedSitesFailed,
  getBrivoLinkedSitesSuccess,
  getBrivoLinkedSitesCancel,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

// load brivo sites
export function* watchGetBrivoSites() {
  yield actionWatcher({
    actions: {
      start: getBrivoSitesStart,
      failed: getBrivoSitesFailed,
      success: getBrivoSitesSuccess,
      cancel: getBrivoSitesCancel,
    },
    saga: getBrivoSitesRe,
  });
}
export function* getBrivoSitesRe(action) {
  try {
    const { enterprise_id, integration_id } = action;

    if (!enterprise_id || !integration_id)
      throw new Error("enterprise_id and integration_id are required for loading Brivo sites");

    const url = `/Brivo/getBrivoSites/${integration_id}/${enterprise_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

// load conxtd sites that are linked to a brivo site
export function* watchGetBrivoLinkedSites() {
  yield actionWatcher({
    actions: {
      start: getBrivoLinkedSitesStart,
      failed: getBrivoLinkedSitesFailed,
      success: getBrivoLinkedSitesSuccess,
      cancel: getBrivoLinkedSitesCancel,
    },
    saga: getBrivoLinkedSitesRe,
  });
}
export function* getBrivoLinkedSitesRe(action) {
  try {
    const { enterprise_id } = action;

    if (!enterprise_id)
      throw new Error("enterprise_id is required for loading Brivo sites");

    const url = `/Brivo/getBrivoSiteAssociateDetails/${enterprise_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}