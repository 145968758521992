export const getUserAgentLocale = () => {
  try {
    // Should return "en" for "en-GB"
    return navigator.language.split("-")[0];
  } catch (e) {
    // Fallback to en
    return "en";
  }
};

export const getUserAgentTimezone = () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  } catch (e) {
    // Fallback to Europe/London
    return "Europe/London";
  }
};
