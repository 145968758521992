import Swal from "sweetalert2";

/**
 * Middleware for alerts so SweetAlert could be
 * switched out in the future
 */
const Alert = props => {
  return Swal.fire(props);
};

export default Alert;