import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestChangePasswordSuccess,
  requestChangePasswordFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* watchChangePassword() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_CHANGE_PASSWORD_START", function*(action) {
    yield race({
      task: call(requestChangePassword, action),
      cancel: take([
        "REQUEST_CHANGE_PASSWORD_CANCEL",
        "REQUEST_CHANGE_PASSWORD_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

export function* requestChangePassword(action) {
  const { current_password, password, password_confirm } = action;
  if (!current_password) {
    throw new Error(
      "Invalid value supplied for current password: " + current_password
    );
  }
  if (!password) {
    throw new Error("Invalid value supplied for new password: " + password);
  }
  if (!password_confirm) {
    throw new Error(
      "Invalid value supplied for password confirm: " + password_confirm
    );
  }

  try {
    const response = yield call(conxtdOut.post, "/users/updatePassword", {
      current_password,
      password,
      password_confirm
    });

    yield put(requestChangePasswordSuccess({ ...response.data }));
  } catch (error) {
    yield put(
      requestChangePasswordFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
