import get from "lodash/get";

const panic = {
  // Hide event text column on panic reports
  EVENT_TEXT: { show: false },
  // Customise export
  buildExportArray: ({
    data,
    getEventMeta,
    // getActualFromOccurrence,
    getActualWithTimezone,
    getDiffFromOccurrence,
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Occurrences",

      "Result",
      "Expected Time",
      "Actual Time",
      "Difference",
      // "Event Text",
      // "Occurrence"
    ];

    // Add headers
    result.push(headers);

    data.forEach((row) => {
      const occurrence = get(row, "result.0");
      const event = getEventMeta(occurrence);
      // Push site data first
      result.push([
        row["custom-id"] || "",
        row["site-name"] || "",
        row.group || "",
        row["site-ref"] || "",
        row.occurrences || "0",

        event.label || "",
        row["expected-time"] || "",
        // getActualFromOccurrence(get(row["actual-time"], 0)) || "",
        getActualWithTimezone(get(row["actual-time"], 0)) || "",
        getDiffFromOccurrence(get(row["difference"], 0)) || "",
        // row["event-text"] || "",
      ]);

      // And show occurrences below
      // const occurrences = get(row, "result");
      // occurrences.forEach((occurrence, index) => {
      //   const event = getEventMeta(occurrence);

      //   result.push([
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",

      //     event.label || "",
      //     row["expected-time"] || "",
      //     getActualFromOccurrence(get(row["actual-time"], 0)) || "",
      //     getDiffFromOccurrence(get(row["difference"], 0)) || "",
      //     // row["event-text"] || "",
      //     "#" + (index + 1)
      //   ]);
      // });
    });

    return result;
  },
};

export default panic;