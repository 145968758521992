import { watchChangePassword } from "./changePassword";
import { watchChangeProfile } from "./changeProfile";
import * as acceptInvite from "./acceptInvite/";
import * as resetPassword from "./resetPassword/";
import * as appIdentityCheck from "./appIdentityCheck/";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all([
    watchChangePassword(),
    watchChangeProfile(),
    acceptInvite.rootSaga(),
    resetPassword.rootSaga(),
    appIdentityCheck.rootSaga(),
  ]);
}
