import { createSelector } from "reselect";
import { getReportType } from "../router";

import { getReport } from "../common/";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import ALARM_TYPES from "constants/ALARM_TYPES";

import _ from "lodash";

import moment from "services/locale/momentInit.js";

import { getFormatMethod, roundTimeToNearest, roundTimeToNearestOffset } from "./interval";

import { NA } from "services/redux/selectors";

import { getSiteById, getSites } from "services/redux/selectors";

// import PIE_KEYS from "constants/Reports/Scheduled/Pie/Legend";

// const getEvents = createSelector()

export const getScheduledReport = createSelector(
  [getReportType, (state) => state.reports],
  (reportType, reports) => {
    const report = reports.scheduled[reportType];
    return report;
  }
);

export const getScheduledReportExpanded = createSelector(
  [getScheduledReport],
  (report) => {
    return report.expanded;
  }
);

export const getAlarmTypes = createSelector(
  [getReportType],
  (reportType) => ALARM_TYPES[reportType]
);

export const getScheduledReportData = createSelector(
  [getScheduledReport],
  (report) => {
    return report.data;
  }
);

/**
 * getScheduledEvents
 *
 * Converts sites object into an array of sites that is
 * datatable friendly
 */
export const getScheduledEvents = createSelector(
  [getScheduledReportData, getScheduledReportExpanded, getSites],
  (data, expanded, sites) => {
    return Object.keys(data.sites).map((id) => {
      const site = data.sites[id];

      // Fallback for sites with no occurrences
      //  copy the site status as the event type
      const fallbackOccurrences = [
        {
          open_type: site.status,
        },
      ];

      const occurrences = site.occurrences || fallbackOccurrences;

      const siteData = getSiteById(sites, parseInt(id));

      return {
        // Try snapshot and then fallback to live site data version
        // Live, locally downloaded site data
        ...siteData,
        // Snapshot site data
        ...site,
        id,
        // Add a flag for expanding
        //  extra occurrences(DataTable)
        expanded: typeof expanded[id] === "undefined" ? null : expanded[id],
        occurrences,
      };
    });

    /*    const OK = Object.values(data.OK).map(site => {
      let occurrences = [];
      // Occurrences is already an array, no need to flatten
      if (Array.isArray(site.occurrences)) {
        occurrences = site.occurrences.map(o => {
          return { ...o, open_type: "none" };
        });
      } else {
        _.each(site.occurrences, (occ_arr, occ_key) => {
          // Actual keys are more reliable than open_type
          occurrences = [
            ...occurrences,
            // Add this occurrence to the array,
            //   setting open_type to the key
            ...occ_arr.map(o => {
              return { ...o, open_type: occ_key };
            })
          ];
        });
      }

      return {
        ...site,
        // Add a flag for expanding
        //  extra occurrences(DataTable)
        expanded: expanded[site.id] || false,
        occurrences
      };
    });
    const FAILED = Object.values(data.FAILED).map(site => {
      // Create an empty occurrence for failed sites
      const occurrences = [
        {
          enterprise_site_id: null,
          wwo_id: "",
          acked: "",
          acked_in_minutes: null,
          diff_from_ideal: null,
          open_type: "FAILED",
          diff_from_ideal_minus_offset: null,
          diff_from_ideal_plus_offset: null,
          text: "",
          recvd: "",
          mct_alarm_log_id: null,
          alarmcode: "",
          description: "",
          colour: "",
          icon: ""
        }
      ];

      return { ...site, occurrences };
    });

    return { OK, FAILED };*/
  }
);

export const getScheduledSelectedTimeRange = createSelector(
  [getScheduledReport],
  (report) => {
    return report.timeRange.selectedTimeRange;
  }
);

export const getScheduledEventsInTimeRange = createSelector(
  [getScheduledEvents, getScheduledSelectedTimeRange],
  (sites, selectedTimeRange) => {
    // check the selected time range is valid
    if ((selectedTimeRange.length === 2) 
        && (selectedTimeRange[0] instanceof Date && !isNaN(selectedTimeRange[0])) 
        && (selectedTimeRange[1] instanceof Date && !isNaN(selectedTimeRange[1])) 
    ) {
      return sites
        .filter((site) => {
          // filter out the sites that have no occurrences within the time range
          let occurrenceInTimeRange = false;
          for (let i=0; i<site.occurrences.length; i++) {
            // if ((moment(site.occurrences[i].acked) >= moment(selectedTimeRange[0])) && (moment(site.occurrences[i].acked) <= moment(selectedTimeRange[1]))) {
            //   occurrenceInTimeRange = true;
            //   break;
            // }
            let acked;
            if (site.occurrences[i].timezone) {
              const localDate = new moment(site.occurrences[i].acked);
              acked = new moment.utc(localDate).tz(site.occurrences[i].timezone);
            } else {
              acked = site.occurrences[i].acked;
            }
            if (
              moment(acked).format("YYYYMMDDHHmm") >= moment(selectedTimeRange[0]).format("YYYYMMDDHHmm") 
              && moment(acked).format("YYYYMMDDHHmm") <= moment(selectedTimeRange[1]).format("YYYYMMDDHHmm")
            ) {
              occurrenceInTimeRange = true;
              break;
            }
          }
          return occurrenceInTimeRange;
        })
        .map((site) => {
          // in the remaining sites, filter out the occurrences that are not within the time range
          const occurrences = site.occurrences.filter((occurrence) => {
            // return (moment(occurrence.acked) >= moment(selectedTimeRange[0])) && (moment(occurrence.acked) <= moment(selectedTimeRange[1]))
            let acked;
            if (occurrence.timezone) {
              const localDate = new moment(occurrence.acked);
              acked = new moment.utc(localDate).tz(occurrence.timezone);
            } else {
              acked = occurrence.acked;
            }
            return (
              moment(acked).format("YYYYMMDDHHmm") >= moment(selectedTimeRange[0]).format("YYYYMMDDHHmm")
              && moment(acked).format("YYYYMMDDHHmm") <= moment(selectedTimeRange[1]).format("YYYYMMDDHHmm")
            )
          });
          // and return the site with the filtered occurrences
          return {
            ...site,
            occurrences: occurrences,
          };
      });
    } else {
      // if the selected time range is not valid, just return the sites
      return sites;
    }
  }
);

/**
 * getScheduledOccurrences
 *
 * @returns all occurrences as a flat array
 *    without site data
 */
export const getScheduledOccurrences = createSelector(
  [getScheduledEvents],
  (sites) => {
    return sites.reduce(
      (accumulator, site, currentIndex) => [
        ...accumulator,
        ...site.occurrences,
      ],
      []
    );
  }
);

export const getScheduledOccurrencesInTimeRange = createSelector(
  [getScheduledEventsInTimeRange],
  (sites) => {
    return sites.reduce(
      (accumulator, site, currentIndex) => [
        ...accumulator,
        ...site.occurrences,
      ],
      []
    );
  }
);

export const getScheduledFilter = createSelector(
  [getScheduledReport],
  (report) => {
    return report.filter;
  }
);

export const labelContainsFilter = (label, filter, type = "contains") => {
  if (!filter) return false;

  // Exact match
  if (type === "exact") {
    return label.toLowerCase() === filter.value.toLowerCase();
  }
  // Loose, "contains" filter instead of
  // forcing the exact search string
  return label.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
};
/**
 * Gets the occurrence that represents the site. Defaults to
 * first occurrence if no eventTextFilter is specified
 */
export const getRepresentativeOccurrence = ({
  occurrences,
  eventTextFilter,
  getEventMeta,
}) => {
  // Default to first occurrence if there is no
  // filter
  if (!eventTextFilter) return occurrences[0];

  // Display filtered occurrence
  return (
    occurrences.find((occurrence) => {
      // Need event meta to match
      const event = getEventMeta(occurrence);
      return labelContainsFilter(event.label, eventTextFilter);
    }) || occurrences[0]
  );
};
// To allow for central changing of acked to acked_timezone
export const getAcked = (occurrence) =>
  occurrence.acked === occurrence.acked_timezone
    ? occurrence.acked
    : occurrence.acked_timezone;
/**
 * @param {Object} param.site - site to detect expected occurrence from
 *
 * @param {Object} [param.occurrence] (optional) the occurrence to detect
 * expected from since this can be at site level or occurrence level.
 * Defaults to first occurrence in site.occurrences
 */
export const getExpectedTime = ({ site, occurrence }) => {
  // Default to fist occurrence
  if (!occurrence) occurrence = site.occurrences[0];

  // if (occurrence.open_type === "FAILED") {
  //   console.log(site);
  //   return _.get(site, "expected_time") || NA();
  // }

  // Try site.ideal_event_hour first
  //  and fallback to the occurrence
  const ideal_event_hour =
    _.get(site, "ideal_event_hour") ||
    _.get(occurrence, "ideal_event_hour") ||
    false;

  const expected_date =
    _.get(site, "expected_date") || _.get(occurrence, "expected_date") || false;

  // One of the values is invalid, fallback
  if (!ideal_event_hour || !expected_date) {
    return ideal_event_hour || NA();
  }

  const formattedDate = new moment(
    expected_date + " " + ideal_event_hour,
    "DD-MM-YYYY HH:mm"
  ).format(getTimeFormat());

  return formattedDate;
};

export const getActualFromOccurrence = (occurrence) => {
  if (occurrence.open_type === "FAILED") {
    return NA();
  }

  return moment(getAcked(occurrence)).format(getTimeFormat());
};

export const getActualWithTimezone = (occurrence) => {
  if (occurrence.open_type === "FAILED") {
    return NA();
  }

  if (occurrence.timezone) {
    const localDate = new moment(occurrence.acked);
    const timezoneDate = new moment.utc(localDate).tz(occurrence.timezone);
    const localTimezoneText = moment.tz.guess();
    const timezoneText = occurrence.timezone === localTimezoneText ? "" : occurrence.timezone;
  
    return `${timezoneDate.format(getTimeFormat())} ${timezoneText}`
  } else {
    return moment(getAcked(occurrence)).format(getTimeFormat());
  }
};

// export const getTimeFormat = _.memoize(() => "LT");
export const getTimeFormat = _.memoize(() => "HH:mm - ddd");

// Filter each site based on the significant event (closest
//   to expected time?)
//   using a time limit filter (5:30AM-5:45AM)
export const intervalFilter = (site, filter) => {
  // Filter is inactive (null)
  if (!filter.timeSegment) return true;

  // No occurrences
  const occurrenceKeys = Object.keys(site.occurrences);
  if (occurrenceKeys.length === 0) return false;

  let keep = false;

  // The event to compare for filtering this site (first event available)
  // const significantOccurrence = site.occurrences[occurrenceKeys[0]][0];
  const significantOccurrence = site.occurrences[0];
  // Filter by interval
  // _.each(event, occurrence => {

  // No failed events
  if (significantOccurrence.open_type === "FAILED") return false;

  // Get formatting method
  const format = getFormatMethod(filter.roundingMethod, filter.userTimezoneUTCOffset);

  // Round to nearest hour or 15 mins based on interval
  let acked = null;
  if (filter.roundingMethod === "1 hour" && filter.userTimezoneUTCOffset.minutes !== 0) {
    acked = roundTimeToNearestOffset(
      new moment(getAcked(significantOccurrence)),
      filter.roundingMethod
    ).format(format);
  } else {
    acked = roundTimeToNearest(
      new moment(getAcked(significantOccurrence)),
      filter.roundingMethod
    ).format(format);
  }

  const timeSegment = filter.timeSegment.format(format);

  // If this event is in the filter, keep it
  if (acked === timeSegment) {
    keep = true;
  }

  // });

  return keep;
};
const eventTypeFilter = (site, filter, ALARM_TYPES) => {
  // Inactive filter (empty object)
  if (Object.keys(filter.show).length === 0) return true;

  // No occurrences
  // const occurrenceKeys = Object.keys(site.occurrences);
  // if (occurrenceKeys.length === 0) return false;

  // The event to compare for filtering this site (first event available)
  // const significantOccurrence = site.occurrences[occurrenceKeys[0]][0];

  // let keep = false;
  // let occurrenceTypes = [];
  // _.each(site.occurrences, (occurrence, occurrence_type) => {
  //   const label = _.get(ALARM_TYPES[occurrence.open_type], "label");
  //   if (!label) {
  //     throw new Error("Unrecognised alarm type: " + occurrence.open_type);
  //   }
  //   occurrenceTypes.push(label);
  // });

  let keep = false;
  // Loop through occurrence types
  _.each(filter.show, (show, eventType) => {
    // Deprecated: only filter against first event
    // Check each occurrence against filter
    // _.each(occurrenceTypes, occurrence_type => {

    // Convert occurrence key to label to match
    let label = _.get(ALARM_TYPES[site.occurrences[0].open_type], "label");

    // Keep this site if significant event type matches (first event)
    if (show && label === eventType) {
      keep = true;
    }

    // });
  });

  // No matches
  return keep;
};

export const eventCountFilter = (site, filter, config, reportType) => {
  const filterKeys = Object.keys(filter.show);
  // This filter is inactive, so don't apply
  // ^ previous comment, but this seems to be used for at least the overnight activity and the people count reports
  if (filterKeys.length === 0) return true;

  const eventCount = site.occurrences.length;

  if (reportType && (reportType === "peoplecount" )) {
    for (let key of filterKeys) {
      // eslint-disable-next-line default-case
      switch (key) {
        case config.keys[0]:
          if (eventCount >= 201) {
            return true;
          }
          break;
        case config.keys[1]:
          if (eventCount >= 151 && eventCount <= 200) {
            return true;
          }
          break;
        case config.keys[2]:
          if (eventCount >= 101 && eventCount <= 150) {
            return true;
          }
          break;
        case config.keys[3]:
          if (eventCount >= 51 && eventCount <= 100) {
            return true;
          }
          break;
        case config.keys[4]:
          if (eventCount <= 50) {
            return true;
          }
          break;
      }
    }
    // Reject by default
    return false;
  } else {
    for (let key of filterKeys) {
      // eslint-disable-next-line default-case
      switch (key) {
        // 16+
        case config.keys[0]:
          if (eventCount >= 16) {
            return true;
          }
          break;
        case config.keys[1]:
          if (eventCount >= 11 && eventCount <= 15) {
            return true;
          }
          break;
        // eslint-disable-next-line no-fallthrough
        case config.keys[2]:
          if (eventCount >= 6 && eventCount <= 10) {
            return true;
          }
          break;
        // eslint-disable-next-line no-fallthrough
        case config.keys[3]:
          if (eventCount >= 2 && eventCount <= 5) {
            return true;
          }
          break;
        // eslint-disable-next-line no-fallthrough
        case config.keys[4]:
          if (eventCount === 1) {
            return true;
          }
          break;
      }
    }
    // Reject by default
    return false;
  }
};

export const getScheduledEventsFiltered = createSelector(
  [getScheduledEvents, getScheduledFilter, getAlarmTypes],
  (events, filter, ALARM_TYPES) => {
    // const result = [...events.OK, ...events.FAILED];

    // Loop through sites
    // Filter sites based on their occurrences
    return events.filter((site) => {
      return (
        // intervalFilter(site, filter.interval) &&
        eventTypeFilter(site, filter.eventType, ALARM_TYPES)
      );
    });
  }
);

export const getScheduledEventsFilteredInTimeRange = createSelector(
  [getScheduledEventsInTimeRange, getScheduledFilter, getAlarmTypes],
  (events, filter, ALARM_TYPES) => {
    // same as getScheduledEventsFiltered above, but use the scheduled events in interval
    return events.filter((site) => {
      return (
        eventTypeFilter(site, filter.eventType, ALARM_TYPES)
      );
    });
  }
);

export const getUrlSuffix = (reportType, live) => {
  // const v2 = "/v2";
  // const v2 = "";
  // v2 - liveOpen
  // return "live" + reportType[0].toUpperCase() + reportType.substring(1) + v2;
  // v3 - daily/live/open

  return `${live ? "live/" : ""}${reportType}`;
};

// export const getEventColor = occurrence => {
//   return COLOUR_CODES[occurrence.colour];
// };
export const createGetEventMeta = createSelector(
  [getAlarmTypes, createGetEventMetaById],
  (alarmTypes, createGetEventMetaById) => {
    return (occurrence) => {
      const { alarm_id } = occurrence;
      if (alarm_id) {
        return createGetEventMetaById(alarm_id);
      }

      const openType = occurrence.open_type;
      // if (!openType) return "#ffffff";
      return alarmTypes[openType];
    };
  }
);

// export const getVisibleColumns = createSelector(
//   [getReportType],
//   reportType => {
//     const visibilityForReport = COLUMNS[reportType] || {};
//     return visibilityForReport;
//   }
// );

export const getEventText = (occurrence) => {
  const text = occurrence.text;
  return text ? text.replace(/\s\s+/g, " ") : NA();
};

export const getScheduledTotals = createSelector(
  [getReport, getScheduledSelectedTimeRange, getScheduledEventsInTimeRange], 
  (report, selectedTimeRange, sites) => {
  // name (description), value

  // const data = _.omit(report.data, "alarms");

  const data = report.data.totals.map(({ name, value }) => {
    if (report.config.timeRangeSelection && (selectedTimeRange.length === 2) && !_.isEmpty(report.data.sites)) {
      // we're using a time range selection, so calculate the values based on the sites within that time range
      let totalValue = 0;
      let low = 0;
      let high = 0;
      if (name === "One event") {
        low = 1;
        high = 1;
      } else if (name === "Two to five events") { 
        low = 2;
        high = 5;
      } else if (name === "Six to ten events") {
        low = 6;
        high = 10;
      } else if (name === "Eleven to fifteen events") { 
        low = 11;
        high = 15;
      } else if (name === "Sixteen events or more") {
        low = 16;
        high = -1;
      }
      sites.forEach((site) => {
        if (high !== -1) {
          if ((site.occurrences.length >= low) && (site.occurrences.length <= high)) {
            totalValue++;
          }
        } else if (site.occurrences.length >= low) {
          totalValue++;
        } 
      });
      return { 
        name, 
        value: totalValue,
      };
    } else {
      // no time range restriction, so just use the values from the api
      return { name, value };
    }
  });
  // Note colour vs color difference
  const colors = report.data.totals.map((item) => item.color || item.colour);

  return {
    data,
    colors,
  };

  // let result = [];
  // Object.keys(data).forEach(key => {
  //   const { label, color, index } = PIE_KEYS[reportType][key];
  //   result[index] = { name: label, color, value: data[key] };
  // });
  // return { data: result };
});

export const getScheduledTotal = createSelector(
  [getScheduledTotals], 
  (totals) => {
    let total = 0;
    totals.data.forEach((item) => {
      total = total + item.value;
    });
    return total;
});

// 08/21 Add new people counting hourly report, which does not fit the format of other scheduled reports. 
// Previously all scheduled reports were pie and would use the above getScheduledTotals to format their report data.
// Get combined hourly values (for the people counting report these are the averages across sites)
export const getScheduledCombinedHourlyValues = createSelector([getReport], (report) => {
  const data = report.data.combined_hourly.map(({ hour, value }) => { 
    if (Number(hour) < 12) {
      hour = Number(hour) + " am";
    } else {
      if (Number(hour) > 12) {
        hour = Number(hour) - 12;
      }
      hour = hour + " pm";
    }
    return { label: hour, value: Number(Number(value).toFixed(0)) }; //round to whole number
  });

  return {
    data
  };
});
// 08/21 get the id for the site that has been selected to show on the bar chart for the hourly report
export const getScheduledSelectedChartSiteId = createSelector([getReport], (report) => {
  return report.selectedChartSite.id;
});
// 08/21 get the hourly values for the site that has been selected to show on the bar chart for the hourly report
export const getScheduledSelectedChartSiteHourlyValues = createSelector([getReport], (report) => {
  let data = [];
  if (report.selectedChartSite.id) {
    const { combined_hourly } = report.data;
    if (report.data.sites[report.selectedChartSite.id]) {
      const { hourly } = report.data.sites[report.selectedChartSite.id];
      // Fill out any missing hours in the site specific data
      combined_hourly.forEach((combinedDataPoint) => {
        const hourlyDataPoint = _.find(hourly, ["hour", combinedDataPoint.hour]);
        hourlyDataPoint 
          ? data.push(hourlyDataPoint) 
          : data.push( { "hour": combinedDataPoint.hour, "value": 0});
      });
    }
  }

  return {
    data
  };
});
// 08/21 get the name of the site that has been selected to show on the bar chart for the hourly report
export const getScheduledSelectedChartSiteName = createSelector([getReport], (report) => { 
  let siteName = "";
  if (report.selectedChartSite.id) {
    if (report.data.sites[report.selectedChartSite.id]) {
      siteName = report.data.sites[report.selectedChartSite.id].name;
    }
  }

  return siteName;
});

export const getNotExpected = (occurrence) => {
  return _.get(occurrence, "not_expected");
};
export const getUsingSystemDefault = (site) => {
  return _.get(site, "occurrences.0.use_system_defaults");
};

export const formatTimeDifference = (event) => {
  const acked = moment(getAcked(event));
  const ideal = acked
    .clone()
    .subtract(_.get(event, "diff_from_ideal"), "minutes");
  const diff = acked.to(ideal, true);
  const earlyOrLate = acked.unix() > ideal.unix() ? "late" : "early";
  return `${diff} ${earlyOrLate}`;
};
export const getDiffFromOccurrence = (event) => {
  if (event.open_type === "FAILED") {
    return NA();
  }

  return formatTimeDifference(event);
};

// Convienience functions for sorting nested numbers
export const sortNumber = (a, b) => {
  if (a === b) return 0;
  return a > b ? 1 : -1;
};

export const getExpanded = (site) => {
  if (site && site.expanded === false) return false;

  return site && site.occurrences.length > 1 && site.expanded;
};

// 08/21 support for hourly report - shows hourly data when expanded
export const getExpandedHourly = (site) => {
  if (site && site.expanded === false) return false;

  return site && site.hourly.length > 0 && site.expanded;
};

export const getExpandedRowsCount = (expanded) =>
  Object.keys(expanded).filter((siteId) => expanded[siteId]).length;
