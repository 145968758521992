import React from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

class ReactTableWrapper extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    // The columns array prop passed from the parent DataTable gets re-created every time DataTable re-renders, 
    // because it needs to be created in render() to have access to state.
    // This causes it's child components to re-render even though the actual content of the columns array doesn't change, 
    // i.e. the columns array data is static, but the reference changes.
    // When ReactTable re-renders it can take a lot of time if it is displaying a large dataset.

    // In this current implementation, the only prop that isn't static as the user interacts with the page is the 'data' prop,
    // so when determining whether the ReactTable should re-render, check only if the 'data' prop has changed.
    return this.props.data !== nextProps.data;
  }

  getResolvedState() {
    return this.reactTable.getResolvedState();
  }

  render() {
    return (
      <ReactTable
        // {...this.props}
        ref={r => (this.reactTable = r)}
        columns={this.props.columns}
        data={this.props.data}
        filterable={this.props.filterable}
        defaultFilterMethod={this.props.defaultFilterMethod}
        defaultPageSize={this.props.defaultPageSize}
        noDataText={this.props.noDataText}
        defaultSorted={this.props.defaultSorted}
      />
    )
  }
}

export default (ReactTableWrapper);