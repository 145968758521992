import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Loading from "components/Loading";

import { LinkContainer } from "react-router-bootstrap";

import { default as T } from "services/i18n/Translate";

import moment from "services/locale/momentInit.js";

import {
  getRouterLocation,
  getRouterParams,
} from "services/redux/selectors/app/index";
import { getReportsOverview } from "services/redux/selectors/reports/common/index";

import ReportOverview from "./ReportOverview";

class ReportsOverview extends React.Component {
  render() {
    const { overview, router } = this.props;
    const mediaType = this.props.browser.mediaType;

    const { reportPaths, sectionOrder } = overview.all;

    const isEnterprise = !router.params.group_id;

    const rootGroup = isEnterprise ? "/root" : "";

    const dailyReports = reportPaths.filter(
      (path) =>
        path[0] === "scheduled" ||
        path[0] === "outstanding" ||
        path[0] === "openclosebyarea" || // 09/21 - open close by area
        path[0] === "failedtoclose" ||
        path[0] === "default"
    );
    const monthlyReports = reportPaths.filter(
      (path) => path[0] === "exception"
    );

    let visibleReports =
      sectionOrder === -1
        ? monthlyReports.slice(0, 2)
        : mediaType === "extraSmall" || mediaType === "small"
          ? (dailyReports.slice(0, 0), monthlyReports.slice(0, 0))
          : dailyReports.slice(0, 3);

    let Overviews;

    if (reportPaths.length > 0) {
      let firstOrSecondReportIsOutstanding =
        reportPaths.length === 1
          ? reportPaths[0][0] === "outstanding"
          : reportPaths.length > 1
            ? reportPaths[0][0] === "outstanding" ||
              reportPaths[1][0] === "outstanding"
            : null;

      let firstOrSecondReportIsOpenCloseByArea =
        reportPaths.length === 1
          ? reportPaths[0][0] === "openclosebyarea"
          : reportPaths.length > 1
            ? reportPaths[0][0] === "openclosebyarea" ||
              reportPaths[1][0] === "openclosebyarea"
            : null;

      let firstOrSecondReportIsOvernight =
        reportPaths.length === 1
          ? reportPaths[0][1] === "overnight"
          : reportPaths.length > 1
            ? reportPaths[0][1] === "overnight" ||
              reportPaths[1][1] === "overnight"
            : null;

      let firstOrSecondReportIsSuspicious =
        reportPaths.length === 1
          ? reportPaths[0][1] === "suspicious"
          : reportPaths.length > 1
            ? reportPaths[0][1] === "suspicious" ||
              reportPaths[1][1] === "suspicious"
            : null;

      let firstThreeOpenCloseOutstanding = reportPaths.length >= 3
                                            && (reportPaths[0][1] === "open" || reportPaths[0][1] === "close")
                                            && (reportPaths[1][1] === "close" || reportPaths[1][1] === "open")
                                            && reportPaths[2][0] === "outstanding";
      
      const limitVisibleReportsTo = (
                                      firstOrSecondReportIsOutstanding 
                                      || firstOrSecondReportIsOpenCloseByArea 
                                      || firstOrSecondReportIsOvernight
                                      || firstOrSecondReportIsSuspicious
                                    ) 
                                    ? 1 
                                    : firstThreeOpenCloseOutstanding
                                      ? 3
                                      : 2;

      const overviewColSize =
        reportPaths.length === 1 || limitVisibleReportsTo === 1
          ? "col-xl-12"
          : "col-xl-6";

      Overviews = visibleReports
        .map((path, index) => {
          const filterType = ((path[1] === "overnight" || path[1] === "suspicious") && limitVisibleReportsTo === 1) ? "legend" : "none";
          return (
            <ReportOverview
              key={index}
              linkTo={`${router.location.pathname}${rootGroup}/reports/${path[0]}/${path[1]}`}
              config={{
                overview: {
                  className: `${(path[0]==="outstanding") ? "col-xl-12" : overviewColSize} ${firstThreeOpenCloseOutstanding && "three"} widget-small report-${path[0]}-${path[1]}`,
                },
                charts: {
                  pie: {
                    filter: {
                      component: `${filterType}`,
                    },
                  },
                },
              }}
              reportPath={path}
              outstandingBundleNumber={firstThreeOpenCloseOutstanding ? 3 : 5}
            />
          );
        })
        // Limit to the correct number of reports
        .slice(0, limitVisibleReportsTo);

    } else if (reportPaths.length === 1) {
      const firstOrSecondReportIsOutstanding =
        reportPaths[0][0] === "outstanding";

      const firstOrSecondReportIsOpenCloseByArea = 
        reportPaths[0][0] === "openclosebyarea";

      const firstOrSecondReportIsOvernight = 
        reportPaths[0][1] === "overnight";

      const firstOrSecondReportIsSuspicious = 
        reportPaths[0][1] === "suspicious";

      const limitVisibleReportsTo = (firstOrSecondReportIsOutstanding 
                                      || firstOrSecondReportIsOpenCloseByArea 
                                      || firstOrSecondReportIsOvernight
                                      || firstOrSecondReportIsSuspicious
                                    ) ? 1 : 2;

      const overviewColSize =
        reportPaths.length === 1 || limitVisibleReportsTo === 1
          ? "col-xl-12"
          : "col-xl-6";

      Overviews = visibleReports
        .map((path, index) => {
          const filterType = (path[1] === "overnight" || path[1] === "suspicious") ? "legend" : "none";
          return (
            <ReportOverview
              key={index}
              linkTo={`${router.location.pathname}${rootGroup}/reports/${path[0]}/${path[1]}`}
              config={{
                overview: {
                  className: `${overviewColSize} widget-small report-${path[0]}-${path[1]}`,
                },
                charts: {
                  pie: {
                    filter: {
                      component: `${filterType}`,
                    },
                  },
                },
              }}
              reportPath={path}
            />
          );
        })
        // Limit to first two reports (or one if oustanding)
        .slice(0, limitVisibleReportsTo);
    } else {
      Overviews =
        overview.all.loading !== false ? (
          <Loading center />
        ) : (
          <div className="col-12">No reports available</div>
        );
    }

    return (
      <div className="row">
        <h4 className="mt-0 px-3 pb-2 header-title w-100">
          <span className="float-left">
            <T>Reports</T>{" "}
            <LinkContainer
              to={`${router.location.pathname}${rootGroup}/reports`}
              className="waves-effect"
            >
              <div className="btn btn-sm btn-primary">
                <T>View All</T>
              </div>
            </LinkContainer>
          </span>
          <span className="float-right">{new moment().format("Do MMMM")}</span>
        </h4>
        {Overviews}
      </div>
    );
  }
}

ReportsOverview.propTypes = {
  router: PropTypes.object.isRequired,
  overview: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    overview: getReportsOverview(state, props),
    browser: state.browser,
    router: {
      location: getRouterLocation(state, props),
      params: getRouterParams(state, props),
    },
  };
};
export default connect(mapStateToProps)(ReportsOverview);
