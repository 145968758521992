import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { vsprintf } from "sprintf-js";

import merge from "deepmerge";

import defaults from "./defaults";

import get from "lodash/get";

import { translationError } from "services/redux/actions";

const checkTranslationsMode = false;

const getTranslationDefaults = () => defaults;

const getTranslationsFromStore = state => state.translations || { GB: {} };
const getTranslations = createSelector(
  [getTranslationDefaults, getTranslationsFromStore],
  (defaults, translations) => {
    return merge(defaults, translations);
  }
);
const getLocale = state => state.user.language || "en";

export function Translate(key, translations, locale, ...args) {
  const value = get(translations, `${locale}.${key}`) || null;
  if (!value) {
    throw new Error(
      "Translation error - missing key: '" + key + "' in locale: " + locale
    );
  }
  if (args) {
    try {
      return vsprintf(value, args);
    } catch (e) {
      throw new Error("Translation error: " + e.message);
    }
  }
  return value;
}

/**
 * Provides props.t("") function as a HOC
 *
 * @param {*} WrappedComponent
 * @param {String} namespace - A namespace string which will prefix
 * all transltion keys for this component, e.g. "site_view"
 */
export function withT(WrappedComponent, namespace) {
  class HOC extends React.Component {
    state = {
      errorReported: false
    };
    render() {
      const { translations, locale, app } = this.props;

      const Component = (
        <WrappedComponent
          {...this.props}
          t={(key, ...args) => {
            const namespaceOrBlank = namespace ? namespace + "." : "";
            try {
              return Translate(
                namespaceOrBlank + key,
                translations,
                locale,
                ...args
              );
            } catch (e) {
              if (!this.state.errorReported) {
                this.setState({ errorReported: true });
                this.props.dispatch(
                  translationError({ ...e, namespace, key, locale, ...args })
                );
              }

              if (!app.production) return "Translation error";

              return "";
            }
          }}
        />
      );

      return checkTranslationsMode ? (
        // Add magenta colour as a way to see
        //  what text is translated
        <span style={{ color: "magenta" }}>{Component}</span>
      ) : (
        Component
      );
    }
  }
  const mapStateToProps = state => {
    return {
      app: state.app,
      translations: getTranslations(state),
      locale: getLocale(state)
    };
  };
  return connect(mapStateToProps)(HOC);
}
