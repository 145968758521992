import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";
import "./AddFromRoot.scss";
import checkboxHOC from "react-table/lib/hoc/selectTable";

import conxtdOut from "apis/conxtdOut";
import { 
  loadEmSitesListStart,
  getCountForMainPageStart,
} from "services/redux/actions/enterpriseManager";
import { getCurrentSite, getBundleId } from "services/redux/selectors/sites";
import { getRouterParams } from "services/redux/selectors/app/";
import {
  getGroupFromRoute,
  getGroupPathWithIdById,
  getGroupsWithAccess,
} from "services/redux/selectors/groups";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getSelectedGroupId } from "services/redux/selectors/users";
import { contains } from "services/filters/";
import { Tooltip } from "components/Common/Tooltip/";

import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import Alert from "components/Common/Alert";
import Icon from "components/Icons/Icon";
import GroupPath from "components/Enterprise/Overview/GroupPath";
import _ from "lodash";
import Export from "components/Common/EnterpriseManager/Export";

/**
 * Enterprise Management Add From Root
 *
 * Allows user to:
 * - Add sites from root group to child group
 **/

const CheckboxTable = checkboxHOC(ReactTable);

class AddFromRoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      selectAll: false,
      existing_groups_full: [],
      existing_groups: [],
      showExport: false,
    };
  }
  componentDidMount() {
    const root_group_id = this.props.location.state ? this.props.location.state.root_group_id : null;
    const { enterprise, siteCount, selectedGroupId } = this.props;

    this.props.dispatch(
      loadEmSitesListStart({
        enterprise_group_id: root_group_id,
        group_id: selectedGroupId,
      })
    );
    if (!siteCount || siteCount.length === 0) {
      this.props.dispatch(
        getCountForMainPageStart({
          enterpriseId: enterprise.id
        })
      );
    }
  }
  toggleSelection = (key, row, info) => {
    let selection = [...this.state.selection];
    let existing_groups_full = [...this.state.existing_groups_full];

    let groups = info.enterprise_groups;
    let groupsIds = groups.map((groups) => groups.id);

    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });

    if (keyIndex >= 0) { 
      existing_groups_full = [
        ...existing_groups_full.slice(0, keyIndex),
        ...existing_groups_full.slice(keyIndex + 1),
      ];
    } else {
      existing_groups_full.push(groupsIds);
    }
    this.setState({ existing_groups_full: existing_groups_full });

    // the existing_groups array is passed on the api request when the user 
    // either adds the selected sites to a group or moves the selected sites to a group.
    // the back end only uses this array to do some clearing of the cache -
    // it only needs to know the list of discrete groups that any of the selected sites are in,
    // and it does not need to know which sites are in which groups,
    // hence, we can flatten the full list of groups associated with each site, and then exclude duplicates.
    const flattenGroups = existing_groups_full.flat();
    const existing_groups = [...new Set(flattenGroups)]; //creating as a new Set makes sure duplicate values don't go into the array
    this.setState({ existing_groups: existing_groups }); 
  };

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    const existing_groups = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
        existing_groups.push(
          item._original.enterprise_groups.map((groups) => groups.id)
        );
      });
    }

    const flattenGroups = existing_groups.flat();

    this.setState({
      selectAll,
      selection,
      existing_groups: [...new Set(flattenGroups)],
    });
    //  console.log(this.state.existing_groups);
  };

  isSelected = (key) => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection.includes(key);
  };

  deselect = () => {
    this.setState({ selection: [] })
    this.setState({ selectAll: false })
  };

  render() {
    const {
      enterpriseManager,
      router,
      group,
      groupsWithAccess,
      selectedGroupId,
      siteCount,
      darkMode,
    } = this.props;
    const newGroupID = router.params.group_id;
    const root_group_id = this.props.location.state ? this.props.location.state.root_group_id : null;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const filter = siteCount.filter(
      (data) => data.enterprise_group_id === parseInt(root_group_id)
    );
    const result = filter && (filter.length > 0) && filter[0].site_count.live_sites;

    let onExportClick = () =>
      this.setState({
        showExport: true,
      });

    let onExportReset = () =>
      this.setState({
        showExport: false,
      });

    const groupPath = getGroupPathWithIdById(
      groupsWithAccess.groups,
      parseInt(selectedGroupId)
    );

    const columns = [
      {
        Header: "Site Name",
        accessor: "name",
        Placeholder: "Filter by name..",
        style: {
          whiteSpace: "unset",
          fontWeight: "bold",
        },
      },
      {
        Header: "Custom Site Ref",
        accessor: "custom_ref",
      },
      {
        id: "address",
        Header: "Address",
        style: { whiteSpace: "unset" },
        accessor: (d) =>
          `${d.address1 === null ? "" : d.address1}, ${
            d.address2 === null ? "" : d.address2
          }, ${d.town === null ? "" : d.town}, ${
            d.postcode === null ? "" : d.postcode
          }, ${d.country_code === null ? "" : d.country_code}`,
      },
      {
        Header: "Groups",
        id: "enterprise_groups",
        style: { whiteSpace: "unset" },
        accessor: (enterpriseManager) => {
          let output = [];
          _.map(enterpriseManager.enterprise_groups, (nm) => {
            output.push(nm.name);
          });
          return output.join(", ");
        },
      },
      {
        Header: "Site Ref",
        accessor: "site_ref",
      },
      {
        Header: "Transmitter Id",
        accessor: "transmitter_ref",
      },
    ];
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (s, r) => {
        // someone asked for an example of a background color change
        // here it is...
        const selected = r ? this.isSelected(r.original.id) : false; // because r might not exist
        return {
          style: {
            backgroundColor: selected 
                              ? darkMode ? "#204183" : "#d6e8fa" 
                              : "inherit",
            //  color: selected ? "white" : "inherit",
          },
        };
      },
    };
    const handleChange = async (event) => {
      const checkboxSelection = this.state.selection;
      const existing_groups = this.state.existing_groups;
      if (this.state.selection.length === 0) {
        return Alert({
          text:
            "At least one site needs to be selected to perform this action.",
          icon: "warning",
        });
      } else {
        try {
          await conxtdOut.post(`/EnterpriseStructure/copySitesToGroup/`, {
            new_group_id: newGroupID,
            old_group_id: root_group_id,
            selected_sites: checkboxSelection,
            existing_groups: existing_groups,
          });

          Alert({
            text: "Selected sites have been added.",
            icon: "success",
            timerProgressBar: true,
            timer: 1500,
          });
          // wait 15 secs before going back to view group page
          await new Promise((resolve) => setTimeout(resolve, 1000));

          this.props.history.goBack();
        } catch (error) {
          let apiError = error.response.data.message;
          let customError = error.response.data.message;

          if (
            apiError.includes("duplicate key value violates unique constraint")
          ) {
            customError = "Cannot add site if it's already in the group.";
          } else {
            return customError;
          }
          Alert({
            text: customError,
            icon: "error",
          });
        }
      }
    };

    return (
      <>
        <Card className="p-3 card">
          <div>
            <Card.Body>
              <Button
                className="float-right"
                variant="primary"
                onClick={onExportClick}
              >
                <div>
                  {<Icon className="fas fa-file-export" title="Export" />}
                  <span style={{ paddingLeft: "5px" }}>Export</span>
                </div>
              </Button>
              {this.state.showExport ? (
                <Export
                  groupId={root_group_id}
                  site_count={result}
                  enterpriseName={this.props.enterprise.name}
                  reset={onExportReset}
                />
              ) : null}

              <div style={{ display: "flex", marginRight: "10px" }}>
                <button
                  type="button"
                  className="button-menu-mobile open-left waves-effect"
                  style={{ backgroundColor: "transparent" }}
                  onClick={this.props.history.goBack}
                >
                  <Icon
                    style={{ color: "darkslategray" }}
                    className="fas fa-chevron-left font-20"
                  />
                </button>
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Card.Title className="text-uppercase">
                      {group && group.name}
                    </Card.Title>
                    <Card.Subtitle className="mb-3 mt-2 font-12">
                      <GroupPath
                        delimiter=" > "
                        path={groupPath}
                        className="text-muted"
                        asLinks={false}
                      />
                    </Card.Subtitle>
                  </div>
                </div>
              </div>
              <Card.Subtitle className="mb-2 mt-4 pb-2 text-muted">
                <span>Add sites to group by selecting one, multiple or all sites. </span>
                <span className="font-italic">Existing sites won't appear in this list.</span>
              </Card.Subtitle>
              <CheckboxTable
                ref={(r) => (this.checkboxTable = r)}
                data={enterpriseManager}
                columns={columns}
                className="-striped -highlight"
                keyField="id"
                filterable
                defaultFilterMethod={(filter, row) => {
                  return contains({
                    filter: filter.value,
                    value: String(row[filter.id]),
                    ignoreCase: true,
                  });
                }}
                {...checkboxProps}
                noDataText="No data available..."
                defaultSorted={[
                  {
                    id: "name",
                    desc: false,
                  },
                ]}
              />
              <div 
                className={`fixed-bottom bg-${darkMode ? "primary" : "light"} ${this.state.selection.length > 0 ? "d-flex" : "d-none"}`}
                id="actions-row-afr"
              >
                <div id="total-sites-afr">
                  <span id="total-sites-span-afr">{this.state.selection.length}</span>
                  <span className="total-text-afr">
                    {this.state.selection.length === 1 ? "site" : "sites"}
                  </span>
                </div>
                <div className="action-buttons-afr">
                  <Button
                    variant={`${darkMode ? "primary" : "light"}`}
                    type="submit"
                    className="action-button-afr"
                    id="add-button-afr"
                    onClick={handleChange}
                  >
                    <Icon className="fas fa-plus icons-afr" />
                    <span className="action-text-afr">Add</span>
                  </Button>
                </div>
                <div className="deselect-container-afr">
                  <Tooltip
                    placement="top"
                    description="Deselect all"
                  >
                    <Button
                      className="deselect-all-afr"
                      variant={`${darkMode ? "secondary" : "light"}`}
                      onClick={this.deselect}
                    >
                      <Icon
                        className="fas fa-times"
                      />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Card.Body>
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    sites: state.sites.all.data,
    group: getGroupFromRoute(state, props),
    groupId: state.groups.data,
    groupsWithAccess: getGroupsWithAccess(state, props),
    selectedGroupId: getSelectedGroupId(state, props),
    enterpriseManager: state.enterpriseManager.items.enterprise_sites,
    browser: state.browser,
    site: getCurrentSite(state, props),
    router: {
      params: getRouterParams(state, props),
    },
    bundle_id: getBundleId(state, props),
    siteCount: state.enterpriseManager.counts,
    darkMode: state.app.darkMode,
  };
};

export default connect(mapStateToProps, null)(AddFromRoot);
