import React from "react";

import { Button } from "react-bootstrap";

import { withT } from "services/translation/";

import { Tooltip } from "components/Common/Tooltip/";
import Icon from "components/Icons/Icon";

let PresetButton = ({ name, location_id, location_name, preset_id, t }) => {
  if (location_id !== null) {
  return (
    <a
      className="mr-2"
      // href={`https://tether.timeline.is/perspectives/${preset_id}`}
      href={`https://my.timeline.is/perspectives?location_id=${location_id}&group=1&return_url=` + window.location.href}
      target="_blank"
      rel="noopener noreferrer">
      <Tooltip title={location_name} description={t("preset_tooltip_description")}>
        <Button size="sm" variant="outline-primary">
          <Icon className="fas fa-th-large" />
        </Button>
      </Tooltip>
    </a>
  ); 
  } else {
    return (<></>);
  }
};
PresetButton = withT(
  PresetButton,
  "site_overview.side_pane.manage_integrations.providers.tether"
);

export default function PresetToolbar({ integration }) {
  return <PresetButton location_id={integration.camera_integration_site.camera_integration_location_id} location_name={integration.camera_integration_site.camera_integration_location_name} />
}
