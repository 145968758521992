//alarm-response

import _ from "lodash";

import moment from "services/locale/momentInit.js";

const initialState = {
  data: {
    inAlarm: [],
  },
  socketRoomConnected: null,
  loadingAlarms: null,
  latestEventMCT: null,
  liveRefreshAlarms: null,
  nextRefresh: null,
  lastRefresh: null,
  expanded: {},
  muted: false,
  muteOnCloseSlider: false,
  continuousSounds: false,
  // demoNotificationsType: null,
};

const getOrderedSudoStateArray = (sudoStateArray) => {
  sudoStateArray.sort((a, b) => {
    // Order the sudo_state array, first by whether it's an alarm or a restore,
    // then by position (the position field gives priority; lower position equals higher priority),
    // then by recvd with the latest one displayed.
    if ((a.state === 'A') && ((b.state === 'R') || (!b.state))) {
      return -1
    } else if (((a.state === 'R') || (!a.state)) && (b.state === 'A')) {
      return 1
    } else {
      if (a.position && !b.position) {
        return -1;
      } else if (!a.position && b.position) {
        return 1;
      } else if (a.position !== b.position) {
        return a.position - b.position;
      } else {
        if (a.recvd > b.recvd) {
          return -1;
        } else if (a.recvd < b.recvd) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  });
  return sudoStateArray;
}

const addAlarmGetReturnState = (alarmToAdd, state) => {
  // this function is to be used in reducers where the action contains a new alarm to be added to the state.
  // it returns the state after adding the alarm

  // find if the new alarm site already exists in the list of sites in alarm
  const existingSiteIndex = _.findIndex(state.data.inAlarm, ['enterprise_site_id', alarmToAdd.enterprise_site_id]);
  if (existingSiteIndex >= 0) {
    // it exists in the list
    return {
      ...state,
      latestEventMCT: alarmToAdd.sudo_state[0].mct_alarm_log_id,
      data: {
        inAlarm: [ 
          ...state.data.inAlarm.map((site, index) => {
            // go through the existing in-alarm sites
            if (index === existingSiteIndex) {
              // this is the site in the current in-alarm list that matches the new alarm
              // check if it's an open/close event
              if (alarmToAdd.sudo_state[0].alarm_event_id === 9) { // both open and close events have alarm_event_id = 9 (and restore_event_id = 10)
                // it is an open/close event, so update the open_close_state
                // but don't add the event into the list
                return {
                  ...site,
                  open_close_state: alarmToAdd.open_close_state
                }
              } else {
                // add the event to the list for the site
                return { 
                  ...site, 
                  sudo_state: getOrderedSudoStateArray( // make sure the sudo_state items are ordered correctly
                    [
                      alarmToAdd.sudo_state[0], // add the new alarm event to the site's sudo_state array
                      ...site.sudo_state.filter((sudo_state_item) => {
                        // filter out any existing alarms in the sudo_state array that have the same id as the new alarm
                        // because the new alarm replaces any that have the same id, e.g. restoring an alarm
                        if (sudo_state_item.id === alarmToAdd.sudo_state[0].id) {
                          return false;
                        } else {
                          return true;
                        }
                      }) 
                    ]
                  )
                }
              }
            } else {
              return { ...site }
            }
          }) 
        ],
      }, 
    }; 
  } else {
    // the new alarm site doesn't already exist in the list of sites in alarm, so just add it to the list
    // unless it's an open/close event, in which case ignore
    if (alarmToAdd.sudo_state[0].alarm_event_id === 9) { // both open and close events have alarm_event_id = 9 (and restore_event_id = 10)
      return {
        ...state,
        latestEventMCT: alarmToAdd.sudo_state[0].mct_alarm_log_id,
      }
    } else {
      return {
        ...state,
        latestEventMCT: alarmToAdd.sudo_state[0].mct_alarm_log_id,
        data: {
          inAlarm: [ ...state.data.inAlarm, alarmToAdd ],
        }, 
      };
    } 
  }; 
}

export default function alarmResponse(state = initialState, action) {
  switch (action.type) {
    case "SET_SOCKET_ROOM_CONNECTED":
      return {
        ...state, 
        socketRoomConnected: action.socketRoomConnected,
      };

    case "LOAD_ALARMS_START":
      return {
        ...state,
        loadingAlarms: true,
      };

    case "LOAD_ALARMS_SUCCESS":
      // socket may be connected so need to carefully merge the data coming in from the api with anything that has already come in from the socket

      // start off the return array with the inAlarm data coming in from the api
      let returnInAlarm = _.cloneDeep(action.data.inAlarm);

      // get the existing inAlarm data that's come in from the websocket
      const existingInAlarm = _.cloneDeep(state.data.inAlarm);

      // loop through the existing in-alarm sites
      existingInAlarm.forEach((existingInAlarmSite) => {
        // find if the existin in-alarm site (from the socket) matches a site in the alarms coming in from the api
        const existingSiteIndex = _.findIndex(returnInAlarm, ['enterprise_site_id', existingInAlarmSite.enterprise_site_id]); 
        if (existingSiteIndex >= 0) {
          // it matches, so merge the sudo_state array data from the existing in-alarm site into the return sudo_state array

          // go through the sudo_states of the existing in-alarm site (there's likely to only be one sudo_state item, but loop just in case)
          // and check if the mct_alarm_log_id matches anything in the corresponding site from the api. 
          // If it doesn't match anything, add it to the sudo_state of the return site
          existingInAlarmSite.sudo_state.forEach((existing_sudo_state_item) => {
            const existingSudoStateIndex = _.findIndex(returnInAlarm[existingSiteIndex].sudo_state, ['mct_alarm_log_id', existing_sudo_state_item.mct_alarm_log_id]); 
            if (existingSudoStateIndex === -1) {
              // no match, so we need to add it
              // but first check if there is an item in the sudo_state array with the same id.
              // if so, we only need to keep the latest item with that id
              let addItem = true;
              returnInAlarm[existingSiteIndex].sudo_state = [ 
                ...returnInAlarm[existingSiteIndex].sudo_state.filter((sudo_state_item) => {
                  if (sudo_state_item.id === existing_sudo_state_item.id) {
                    if (sudo_state_item.recvd > existing_sudo_state_item.recvd) {
                      addItem = false;
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }) 
              ]

              if (addItem) {
                returnInAlarm[existingSiteIndex].sudo_state = [ 
                  existing_sudo_state_item, 
                  ...returnInAlarm[existingSiteIndex].sudo_state
                ];
              }
            }
          })
        } else {
          // the site of the existing alarm item (from the socket) does not match a site coming in from the api
          // so just add the alarm item from the socket into the return array
          returnInAlarm = [ ...returnInAlarm, existingInAlarmSite ];
        }
      });
      return {
        ...state,
        data: {
          inAlarm: [ 
            ...returnInAlarm.map((inAlarmSite) => {
              inAlarmSite.sudo_state = getOrderedSudoStateArray(inAlarmSite.sudo_state); // make sure the sudo_state items are ordered correctly
              return inAlarmSite;
            })
          ]
        },
        loadingAlarms: false,
      }

    case "LOAD_ALARMS_FAILED":
      return {
        ...state,
        loadingAlarms: false,
      };

    case "ADD_ALARM_EVENT":
      return addAlarmGetReturnState(action.data, state);

    case "REMOVE_OLD_ALARMS":
      const timeNow = new moment();
      const inAlarmUpdated = state.data.inAlarm.map((site) => {
        // for every site...
        return {
          ...site,
          sudo_state: site.sudo_state.filter((sudo_state) => {
            // ... filter out any sudo_state items that are older than the 'remove after' time
            //     and that are unassigned
            const alarmRecvdTime = new moment(sudo_state.recvd);
            const minutesAgo = -alarmRecvdTime.diff(timeNow, "minute");
            if (minutesAgo >= action.removeAfter && !sudo_state.sop_action_assigned_user) {
              return false;
            } else {
              return true;
            }
          })
        }
      }).filter((site) => {
        // then filter out any sites that have no items left in their sudo_state
        return (site.sudo_state.length > 0)
      });

      let expandedUpdated = { ...state.expanded };
      Object.keys(state.expanded).forEach((enterprise_site_id) => {
        // go through the list of sites (enterprise_site_id) that are expanded to find any that no longer exist in the inAlarm array
        let enterpriseSiteIdExists = false;
        let onlyOneAlarm = false;
        for (let i=0; i<inAlarmUpdated.length; i++) {
          if (String(inAlarmUpdated[i].enterprise_site_id) === String(enterprise_site_id)) {
            enterpriseSiteIdExists = true;
            if (inAlarmUpdated[i].sudo_state.length === 1) {
              onlyOneAlarm = true;
            }
            break;
          }
        }
        if ((!enterpriseSiteIdExists) || onlyOneAlarm) {
          // if the enterprise_site_id no longer exists in inAlarm, set it to false in the expanded object
          expandedUpdated = { ...expandedUpdated, [enterprise_site_id]: false }
        }
      });

      return {
        ...state,
        data: {
          inAlarm: inAlarmUpdated,
        },
        expanded: expandedUpdated,
      };

    case "REMOVE_ALL_ALARMS":
      return {
        ...state,
        data: {
          inAlarm: [],
        },
        expanded: {}, 
      };

    case "LOAD_ALARMS_REFRESH_START":
      return {
        ...state,
        loadingAlarms: true,
        liveRefreshAlarms: true,
      };

    case "LOAD_ALARMS_REFRESH_SUCCESS":
      // socket is not connected, so just put the alarm data coming from the api straight into the state
      return {
        ...state,
        data: {
          inAlarm: [ 
            ...action.data.inAlarm.map((inAlarmSite) => {
              inAlarmSite.sudo_state = getOrderedSudoStateArray(inAlarmSite.sudo_state); // make sure the sudo_state items are ordered correctly
              return inAlarmSite;
            })
          ]
        }, 
        loadingAlarms: false,
        lastRefresh: action.lastRefresh,
        nextRefresh: action.nextRefresh,
      };

    case "LOAD_ALARMS_REFRESH_FAILED":
      return {
        ...state,
        loadingAlarms: false,
      };

    case "LOAD_ALARMS_REFRESH_CANCEL":
      return {
        ...state,
        loadingAlarms: false,
        liveRefreshAlarms: false,
      };

    case "SET_ALARMS_EXPAND_SITE":
      const { enterprise_site_id, expanded } = action;
      return {
        ...state,
        expanded: { ...state.expanded, [enterprise_site_id]: expanded }
      }

    case "RESET_ALARMS_EXPANDED":
      return {
        ...state,
        expanded: {},
      };

    case "SET_ALARMS_MUTED":
      const { muted } = action
      return {
        ...state,
        muted: muted,
      };

    case "SET_ALARMS_MUTE_ON_CLOSE_SLIDER":
      const { muteOnCloseSlider } = action
      return {
        ...state,
        muteOnCloseSlider: muteOnCloseSlider,
      }

    case "SET_ALARMS_CONTINUOUS_SOUNDS":
      const { continuousSounds } = action
      return {
        ...state,
        continuousSounds: continuousSounds,
      };

    case "ADD_DUMMY_IN_ALARM_SITE": //alarm demo
      return addAlarmGetReturnState(action.dummyInAlarmSite, state);

    case "ASSIGN_ALARM_SOP_USER":
      return {
        ...state,
        data: {
          inAlarm: [ 
            ...state.data.inAlarm.map((inAlarmSite) => {
              if (inAlarmSite.enterprise_site_id === action.enterprise_site_id) {
                return {
                  ...inAlarmSite,
                  sudo_state: inAlarmSite.sudo_state.map((sudoState) => {
                    if (sudoState.id === action.sudo_state_id) {
                      return {
                        ...sudoState,
                        sop_action_assigned_user: action.sop_action_assigned_user,
                      }
                    } else {
                      return {
                        ...sudoState,
                      }
                    }
                  })
                }
              } else {
                return {
                  ...inAlarmSite,
                }
              }
            })
          ]
        },
      };

    case "CLOSE_ALARM_SOP_ACTION":
      return {
        ...state,
        data: {
          inAlarm: [ 
            ...state.data.inAlarm.map((site) => {
              // go through the in-alarm sites to find a match for the enterprise_site_id
              if (site.enterprise_site_id === action.enterprise_site_id) {
                // the site matches the enterprise_site_id
                return { 
                  ...site,
                  sudo_state: [
                    ...site.sudo_state.map((sudo_state) => {
                      // go through the sudo_states under the site to find a match for the sudo_state_id
                      if (sudo_state.id === action.sudo_state_id) {
                        // the id matches the sudo_state_id,
                        // so replace the existing sudo_state with the new data from the action, i.e. from the websocket
                        return {
                          ...action.sudo_state,
                        }
                      } else {
                        return {
                          ...sudo_state,
                        }
                      }
                    })
                  ]
                }
              } else {
                return { ...site }
              }
            }) 
          ],
        }, 
      };

    default:
      return state;
  }
}