import React from "react";

import StatisticWidget from "./StatisticWidget";

class StatisticWidgets extends React.Component {
  render() {
    const { stats } = this.props;

    return (
      <div className="row statistic-widgets">
        <StatisticWidget
          name={stats.title}
          value={stats.total}
          icon="building"
          color="#2E3E4F"
        />

        {stats.stats.map((s, index) => (
          <StatisticWidget clickable key={index} {...s} total={stats.total} />
        ))}
      </div>
    );
  }
}
export default StatisticWidgets;
