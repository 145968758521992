import { put, select } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import { getCacheIndexes } from "../selectors";
import caching from "services/caching/index";

/**
 * Caches the action's value, invalidating after a time set in constants/CACHE_CONFIG
 * @param {REDUX_ACTION} action
 */
export function* cacheMe(action) {
  yield put(actions.cacheMe(action));
}

/**
 * stale - returns true if the cache has expired
 *
 * successActionType: The expected resulting action type (this is the caching key)
 *  */
export function* stale(successActionType) {
  const cacheIndexes = yield select(getCacheIndexes);
  // const sites = yield select(getSites);

  return yield caching.stale(cacheIndexes, successActionType);
}

/**
 * Restores, loads or does nothing (if a restore is not necessary)
 *
 * If data is stale, it will refresh from endpoint, otherwise
 *   it will restore from local storage if this is the first run,
 *   otherwise leave the redux store as is
 */
export function* restoreOrLoad(params) {
  const {
    apiCall,
    successAction,
    failedAction,
    restoreAction,
    extraParams
  } = params;

  const cacheSubKey = (extraParams && extraParams.cacheSubKey) || "";

  const successActionType = successAction().type;

  const key = successActionType + "/" + cacheSubKey;

  // Add cache sub key if provided to differentiate between same actions with different data
  const dataStale = yield stale(key);

  const cachedData = sessionStorage.getItem(key);
  // Nothing to restore from or data is stale - we need to download from the API
  if (cachedData === null || dataStale) {
    try {
      // console.log("api call");
      const response = yield apiCall;

      yield put(successAction({ data: response.data, ...extraParams }));
    } catch (error) {
      console.log(error);
      yield put(failedAction({ error: error.message }));
    }
    return;
  }

  // const getReducer = state => _.get(state, reducerKey);
  // const reducerContents = yield select(getReducer);
  // if (!reducerContents.data) {
  // console.log("restore");
  // Initial restore
  yield put(restoreAction(JSON.parse(cachedData)));
  // } else {
  // Leave data as is
  // yield put(restoreAction({ unchanged: true }));
  // console.log("leaving it be");
  // }
}
