import React from "react";

const radio = props => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    width="100%"
    height="100%">
    <g>
      <g>
        <g>
          <path
            d="M375.405,499.121l-40.474-137.969c-0.002-0.008-0.005-0.016-0.007-0.025l-14.443-49.232    c-0.001-0.005-0.003-0.01-0.004-0.014l-19.958-68.033c-0.004-0.013-0.007-0.026-0.011-0.038l-31.754-108.244    c13.978-5.193,23.965-18.664,23.965-34.427c0-20.244-16.47-36.713-36.713-36.713s-36.713,16.47-36.713,36.713    c0,15.763,9.987,29.235,23.965,34.427l-66.17,225.563c-0.002,0.008-0.005,0.016-0.007,0.025l-40.474,137.968    c-1.562,5.324,1.488,10.907,6.812,12.47c5.331,1.561,10.907-1.488,12.47-6.813l6.154-20.978l93.964-62.067l93.964,62.067    l6.154,20.978c1.285,4.381,5.292,7.222,9.638,7.222c0.936,0,1.889-0.132,2.832-0.409    C373.917,510.029,376.967,504.446,375.405,499.121z M239.387,101.137c0-9.163,7.455-16.618,16.618-16.618    s16.618,7.455,16.618,16.618s-7.455,16.618-16.618,16.618S239.387,110.301,239.387,101.137z M256.005,163.498l21.492,73.265    h-42.985L256.005,163.498z M228.617,256.858h54.776l14.071,47.968h-82.918L228.617,256.858z M170.805,453.928l21.742-74.112    l45.229,29.875L170.805,453.928z M198.465,359.642l10.186-34.721h94.709l10.186,34.721l-57.541,38.008L198.465,359.642z     M274.235,409.692l45.229-29.875l21.742,74.112L274.235,409.692z"
            data-original="#000000"
            className="active-path"
            fill={props.color}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M208.24,53.373c3.924-3.924,3.924-10.286,0-14.209c-3.924-3.924-10.285-3.924-14.21,0    c-34.169,34.172-34.169,89.775,0.001,123.947c1.962,1.962,4.533,2.943,7.105,2.943c2.571,0,5.142-0.982,7.104-2.943    c3.924-3.924,3.924-10.286,0-14.209C181.902,122.565,181.902,79.71,208.24,53.373z"
            data-original="#000000"
            className="active-path"
            fill={props.color}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M317.979,39.165c-3.924-3.924-10.284-3.924-14.21,0c-3.924,3.924-3.924,10.286,0,14.209    c26.337,26.337,26.337,69.192,0,95.529c-3.923,3.922-3.923,10.285,0.001,14.208c1.962,1.962,4.533,2.943,7.105,2.943    s5.142-0.982,7.105-2.943C352.15,128.939,352.15,73.336,317.979,39.165z"
            data-original="#000000"
            className="active-path"
            fill={props.color}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M172.02,17.152c3.924-3.924,3.924-10.286,0-14.209c-3.924-3.924-10.285-3.924-14.21,0    c-26.228,26.229-40.673,61.101-40.673,98.195c0,37.093,14.445,71.966,40.673,98.195c1.963,1.962,4.533,2.943,7.106,2.943    s5.142-0.982,7.105-2.943c3.924-3.924,3.924-10.286,0-14.209C125.709,138.813,125.709,63.462,172.02,17.152z"
            data-original="#000000"
            className="active-path"
            fill={props.color}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M374.322,28.38c-2.91-4.724-9.1-6.196-13.825-3.286c-4.723,2.911-6.194,9.1-3.285,13.824    c28.95,46.994,21.867,107.116-17.222,146.204c-3.924,3.924-3.924,10.286,0,14.21c1.962,1.962,4.533,2.943,7.105,2.943    c2.571,0,5.142-0.981,7.105-2.943c22.222-22.223,36.365-51.631,39.823-82.806C397.426,85.834,390.43,54.529,374.322,28.38z"
            data-original="#000000"
            className="active-path"
            fill={props.color}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M347.095,0c-12.93,0-12.951,20.095,0,20.095C360.025,20.095,360.046,0,347.095,0z"
            data-original="#000000"
            className="active-path"
            fill={props.color}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default radio;