/**
 * 09/21 - open close by area
 */

import { call, takeLatest, take, race, takeEvery } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import { liveRefreshFor } from "services/redux/sagas/common";

import conxtdOut from "apis/conxtdOut";

import * as links from "./links";

export function* watchLiveRefresh() {
  // Sites - When open close by area reports finish loading, start a background task to load sites
  yield takeLatest(
    [
      // Pre-loading
      "LOAD_OPEN_CLOSE_BY_AREA_REPORT_SUCCESS"
    ],
    links.toSitesBackgroundLoader
  );

  // Event meta data - when open close by area reports finish loading, start a background task to load event meta
  yield takeLatest(
    [
      // Pre-loading
      "LOAD_OPEN_CLOSE_BY_AREA_REPORT_SUCCESS"
    ],
    links.toEventMetaBackgroundLoader
  );

  yield takeEvery("LOAD_OPEN_CLOSE_BY_AREA_REPORT_START", function*(action) {
    yield race({
      task: call(loadStart, action),
      cancel: take([
        "LOAD_OPEN_CLOSE_BY_AREA_REPORT_CANCEL",
        "CANCEL_ALL_LIVE_REFRESH",
        "CANCEL_ALL"
      ])
    });
  });
}

export function* loadStart(action) {
  const { interval, reportType, enterprise_id, group_id, site_state, area_state, refresh } = action;

  let urlParams = "";
  if (site_state || area_state) {
    urlParams += "?";
    if (site_state) {
      urlParams += ("site_state=" + site_state);
    }
    if (area_state) {
    if (site_state) {urlParams += "&"};
    urlParams += ("area_state=" + area_state);
    }
  }

  const params = {
    startAction: actions.loadOpenCloseByAreaReportStart,
    startActionParams: action,
    successAction: actions.loadOpenCloseByAreaReportSuccess,
    failedAction: actions.loadOpenCloseByAreaReportFailed,
    apiCall: call(
      conxtdOut.get,
      `/EventPairs/byAreaEnterprise/${enterprise_id}/${group_id}${urlParams}`
    ),
    interval,
    key: reportType,
    successActionParams: {
      enterprise_id,
      group_id
    },
    refresh
  };

  yield liveRefreshFor(params);
}