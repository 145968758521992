import React from "react";
import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { Button, ButtonToolbar, DropdownButton, Dropdown } from "react-bootstrap";

// import countryList from "react-select-country-list";

import { Tooltip } from "components/Common/Tooltip/";

import {
  loadCompanyStart,
  setCompanyDisplayPageIndex,
} from "services/redux/actions/company";

import { loadSudoSiteIdReset } from "services/redux/actions/serviceDesk.js";

import ActualTimezoneDate from "components/Common/ActualTimezoneDate";

import Alert from "components/Common/Alert";

import Icon from "components/Icons/Icon";

import { saveAs } from "file-saver";

import Loading from "components/Loading";

import conxtdOut from "apis/conxtdOut";

import _ from "lodash";

import FILTER_TAG_COLOURS from "./FILTER_TAG_COLOURS.js";
import AddSingleSite from "./AddSingleSite/AddSingleSite.js";

class DataTable extends React.Component {
  state = {
    previousDataTableState: {
      page: 0,
      pageSize: 20,
      addSiteModalShow: false,
      // sorted: [],
    }
  };

  componentDidMount = () => {
    this.props.dispatch(loadSudoSiteIdReset());
  }

  onClickExport = () => {
    const {
      companyId,
      companyName,
      params,
    } = this.props;

    const getExport = conxtdOut.post(
      `/Authake/getSitesReportExport/${companyId}`,
      {
        ...params,
      },
      {
        responseType: "arraybuffer"
      }

    );

    getExport.then((response) => {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      if (blob.size === 70) {
        Alert({
          text:
            "You will be sent an email which will contain your export",
          icon: "success",
          timerProgressBar: true,
          timer: 5000,
        });
      } else {
        saveAs(blob, companyName + ".xlsx");
      }

    }).catch((error) => {
      console.log("error", error.message);
        Alert({
          text: "Export failed, please try again later.",
          icon: "error",
          timerProgressBar: true,
          timer: 5000,
        });
    })
  }

  applyTableFilter = (filterValue, filterColumnDetails, columns) => {
    const {
      companyId,
      pageSize,
      displayPageIndex,
      params,
      tableFilters,
      updateTableFilters,
    } = this.props;

    const newTableFilters = columns
      .map((column) => {
        const filter = _.find(tableFilters, {"id": column.id});

        if (column.id === filterColumnDetails.id) { // the column we're currently filtering on
          return {
            id: column.id,
            value: filterValue,
            label: filterColumnDetails.Header + ": " + filterValue,
            color: filterColumnDetails.filterTagColor ? filterColumnDetails.filterTagColor : "#191970",
            inputId: filterColumnDetails.filterInputId,
            clearFilter: () => {
              const filterInputElement = document.getElementById(filterColumnDetails.filterInputId);
              var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
              nativeInputValueSetter.call(filterInputElement, "");
              var event = new Event("input", { bubbles: true});
              filterInputElement.dispatchEvent(event);
            },
          };
        } else if (filter) {
          return filter;
        } else {
          return { value: "" };
        }
      })
      .filter((item) => {
        return !!item.value;
      });

    this.props.dispatch(
      loadCompanyStart({ 
        company_id: companyId, 
        pageSize: pageSize,
        requestPageNumber: 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
        // sortDirection,
        params: {
          ...params,
          [filterColumnDetails.id]: filterValue,
        }
      })
    );

    if (displayPageIndex !== 0) {
      this.props.dispatch(
        setCompanyDisplayPageIndex({
          displayPageIndex: 0, // controls the page index that the table component displays (0-indexed)
        })
      )
    }

    updateTableFilters(newTableFilters);
  }

  render() {
    const { 
      companyId,
      companyType,
      sudoSites,
      customFields,
      pagination,
      displayPageIndex,
      tableFilters,
      params,
      loadingCompany,
      openPortal,
      location,
    } = this.props;

    const addSiteModalClose = () => {
      this.setState({ addSiteModalShow: false });
    }
    
    const addSiteModalShow = () => {
      this.setState({ addSiteModalShow: true });
    }

    let noDataMsg = "No data available...";

    const columns = [
      {
        key: "ID",
        Header: "Id",
        id: "id",
        width: 60,
        show: false,
        accessor: data => data.id,
      },
      {
        key: "SITE_REF",
        Header: "Site Ref",
        id: "site_ref",
        width: 80,
        filterTagColor: FILTER_TAG_COLOURS["siteRefTagColour"],
        filterInputId: "company_site_ref_filter", //must be the same as the id of the Filter input element
        accessor: data => data.site_ref,
        Filter: ({ column }) => {
          return <input
            type="text"
            id={column.filterInputId}
            onChange={_.debounce((e) => {
              this.applyTableFilter(e.target.value, column, columns);
            }, 300)}
          />
        }
      },
      {
        key: "TRANSMITTER_REF",
        Header: "Transmitter Ref",
        id: "transmitter_ref",
        width: 120,
        filterTagColor: FILTER_TAG_COLOURS["transmitterRefTagColour"],
        filterInputId: "transmitter_ref_filter",
        accessor: data => data.transmitter_ref,
        Filter: ({ column }) => {
          return <input
            type="text"
            id={column.filterInputId}
            onChange={_.debounce((e) => {
              this.applyTableFilter(e.target.value, column, columns);
            }, 300)}
          />
        }
      },
      {
        key: "COMPANY",
        Header: "Site Name",
        id: "company",
        minWidth: 150,
        accessor: data => (
          <Link data-intercom-target={"site-editor-link-data-table"} to={`${location.pathname}/${data.site_ref}${data.enterprises?.[0]?.id ? `?enterprise_id=${data.enterprises?.[0]?.id}` : ''}`}>
            <Icon className="fas fa-external-link-square-alt mr-2"></Icon>
            {data.company}
          </Link>
        ),
        filterable: false,
      },
      {
        key: "ENTERPRISES",
        Header: "Enterprises",
        id: "enterprises",
        minWidth: 310,
        filterable: false,
        accessor: data => data.enterprises,
        Cell: ({row}) => {
          return <div>
            {
              row.enterprises.map((enterprise, index) => {
                return <span key={index} className="mx-1">
                  <Tooltip
                    placement="left"
                    description="Go to Enterprise"
                  >
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.props.history.push(`/enterprise/${enterprise.id}`);
                      }}
                    >
                      {enterprise.name}
                    </Button>
                  </Tooltip>
                </span>
              })
            }
          </div>
        }
      },
      {
        key: "ADDRESS_1",
        Header: "Address 1",
        id: "address1",
        width: 115,
        accessor: data => data.address1,
        filterable: false,
      },
      {
        key: "ADDRESS_2",
        Header: "Address 2",
        id: "address2",
        width: 115,
        accessor: data => data.address2,
        filterable: false,
      },
      {
        key: "TOWN",
        Header: "Town",
        id: "town",
        width: 115,
        accessor: data => data.town,
        filterable: false,
      },
      {
        key: "COUNTRY",
        Header: "Country",
        id: "country",
        width: 80,
        filterTagColor: FILTER_TAG_COLOURS["countryTagColour"],
        filterInputId: "country_filter",
        className: "text-center",
        // accessor: data => countryList().getLabel(data.country),
        accessor: data => data.country,
        Filter: ({ column }) => {
          return <input
            type="text"
            id={column.filterInputId}
            onChange={_.debounce((e) => {
              this.applyTableFilter(e.target.value, column, columns);
            }, 300)}
          />
        }
      },
      {
        key: "POSTCODE",
        Header: "Postcode",
        id: "postcode",
        width: 90,
        accessor: data => data.postcode,
        filterable: false,
      },
      {
        key: companyType === "A" ? "INSTALLER" : "ARC",
        Header: companyType === "A" ? "Installer" : "ARC",
        id: companyType === "A" ? "installer" : "arc",
        width: 90,
        accessor: data => companyType === "A" ? data.installer.name : data.arcmap.name,
        filterable: false,
        show: !!companyType
      },
      {
        key: "CONTRACT_NUMBER",
        Header: "Contract Number",
        id: "contract_number",
        width: 130,
        filterTagColor: FILTER_TAG_COLOURS["contractNumberTagColour"],
        filterInputId: "contract_number_filter",
        accessor: data => data.contract_number,
        Filter: ({ column }) => {
          return <input
            type="text"
            id={column.filterInputId}
            onChange={_.debounce((e) => {
              this.applyTableFilter(e.target.value, column, columns);
            }, 300)}
          />
        }
      },
      {
        key: "PRODUCT_DESCRIPTION",
        Header: "Product Description",
        id: "product_description",
        width: 150,
        filterTagColor: FILTER_TAG_COLOURS["productDescriptionTagColour"],
        filterInputId: "product_description_filter",
        accessor: data => data.product_description,
        Filter: ({ column }) => {
          return <input
            type="text"
            id={column.filterInputId}
            onChange={_.debounce((e) => {
              this.applyTableFilter(e.target.value, column, columns);
            }, 300)}
          />
        }
      },
    ];

    if (customFields) {
      const fields = Object.keys(customFields);
      if (fields.length >= 1) {
        const customTagColours = FILTER_TAG_COLOURS["customTagColours"];
        fields.forEach((field, index) => {
          let tagColorIndex = index;
          while (tagColorIndex >= customTagColours.length) {
            tagColorIndex = tagColorIndex - customTagColours.length;
          }
          columns.push({
            key: field,
            Header: customFields[field],
            id: field,
            width: 110,
            filterTagColor: customTagColours[tagColorIndex],
            filterInputId: field + "_filter",
            accessor: data => _.find(data.custom_fields, { 'text_field': field })?.value,
            Filter: ({ column }) => {
              return <input
                type="text"
                id={column.filterInputId}
                onChange={_.debounce((e) => {
                  this.applyTableFilter(e.target.value, column, columns);
                }, 300)}
              />
            }
          })
        })
      }
    }

    columns.push({
      key: "LAST_EVENT_ACKED",
      Header: "Last Event Time",
      id: "occurrence",
      minWidth: 160,
      filterable: false,
      // accessor: data => data.last_event_acked,
      accessor: data => {
        const occurrence = {
          acked: data.last_event_acked,
          timezone: data.timezone,
          format: "HH:mm ddd DD/MM/YYYY",
        }
        return occurrence;
      },
      Cell: ({ row }) => {
        return <ActualTimezoneDate occurrence={row.occurrence} /> //note: this is a slightly different <ActualTimezoneDate component to the one used in the scheduled reports
      },
    });

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    return (
      <div>
          <ButtonToolbar className="p-2">
            {
              !mobile && <DropdownButton
                id="dropdown-export"
                title="Export"
                disabled={(sudoSites.length === 0)}
              >
                <Dropdown.Item onClick={() => this.onClickExport()}>
                  <Icon className="far fa-file-excel" /> Excel
                </Dropdown.Item>
              </DropdownButton>
            }
            <Button className="ml-1">Generate <Icon className="fas fa-plus pl-1" /></Button>
            <DropdownButton
              title="Sites"
              className="ml-1"
              data-intercom-target={"sites-button-company"}
            >
              <Dropdown.Item
                data-intercom-target={"add-site-select-company"}
               onClick={() => {
                addSiteModalShow()
              }}
              >
                <span><Icon className="fas fa-plus mr-2" title="Add Single Site" />Add Site</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={openPortal}>
                <span><Icon className="fas fa-file-import mr-2" title="Import" />Import Sites</span>
              </Dropdown.Item>
            </DropdownButton>
            <div style={{ width: "55px"}}>{loadingCompany && <Loading center />}</div>
          </ButtonToolbar>
          <ReactTable
            ref={r => (this.reactTable = r)}
            columns={columns}
            data={sudoSites}
            manual
            page={displayPageIndex}
            pages={pagination.pageCount}
            onPageChange={(pageIndex) => {
              this.props.dispatch(
                setCompanyDisplayPageIndex({
                  displayPageIndex: pageIndex, // keeps track of the page index that the table component displays (0-indexed)
                })
              )
            }}
            filterable
            filtered={tableFilters}
            // sortable={sudoSites.length > 0}
            sortable={false}
            defaultPageSize={20}
            noDataText={noDataMsg}
            // onFetchData={(state, instance) => {
            onFetchData={_.debounce((state, instance) => {
              if (state.data && state.data.length > 0) {
                // to prevent hitting the api unnecessarily, first check that there has actually been a change in state
                const dataTableState = {
                  page: state.page,
                  pageSize: state.pageSize,
                  // sorted: state.sorted,
                  // filtered: state.filtered,
                }
                if (!_.isEqual(dataTableState, this.state.previousDataTableState)) {
                  this.props.dispatch(
                    loadCompanyStart({ 
                      company_id: companyId, 
                      pageSize: state.pageSize,
                      requestPageNumber: state.page + 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
                      // sortDirection,
                      params: {
                        ...params,
                      }
                    })
                  );

                  this.setState({
                    previousDataTableState: dataTableState,
                  })
                }
              }
              // }
            }, 300)} // debounce period
          />

        <AddSingleSite
          showModal={this.state.addSiteModalShow}
          handleClose={addSiteModalClose} 
          handleShow={addSiteModalShow} 
          companyName={this.props.companyName} 
          companyType={this.props.companyType} 
          installerOptions={this.props.installerOptions} 
          arcMapOptions={this.props.arcMapOptions} 
          installerOption={this.props.installerOption} 
          arcMapOption={this.props.arcMapOption} 
          companyId={companyId}
          previousDataTableState={this.state.previousDataTableState} 
        />

      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    sudoSites: state.company.data.sudoSites,
    customFields: state.company.filterData.customFields,
    pagination: state.company.data.pagination,
    pageSize: state.company.pageSize,
    displayPageIndex: state.company.displayPageIndex,
    params: state.company.params,
    loadingCompany: state.company.loadingCompany,
  };
};
export default withRouter(connect(mapStateToProps)(DataTable));