import React from "react";
import { connect } from "react-redux";

import { Button, Modal, Row, Col, Form } from "react-bootstrap";

// import Select from 'react-select';

import { 
  clearSOPActionMap,
  loadSOPCloseDownOptionsStart, 
} from "services/redux/actions/sop";
import { setVoiceCallStatus } from "services/redux/actions/voiceCalls";

import Alert from "components/Common/Alert";

import conxtdOut from "apis/conxtdOut";

import "./CloseActionModal.scss";

class CloseActionModal extends React.Component {
  state = {
    closeDownType: null,
    closeDownOption: null,
  }

  componentDidMount = () => {
    const { enterpriseId, groupId } = this.props;

    this.props.dispatch(
      loadSOPCloseDownOptionsStart({
        enterprise_id: enterpriseId,
        group_id: groupId,
      })
    );
  }

  handleCloseDownTypeChange = (closeDownType, closeDownOption) => {
    this.setState({
      closeDownType: closeDownType,
      closeDownOption: closeDownOption,
    });
  }

  handleCloseDownOptionChange = (event) => {
    this.setState({
      closeDownOption: event.currentTarget.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { enterpriseId, groupId, sopActionedEventId, onHide, muted, muteOnCloseSlider, muteClick } = this.props;

    const encodedComment = encodeURIComponent(event.target.comment.value);

    const closeButton = document.getElementById("sop-close-action-modal-button");

    // /out_api/sop/sop-action-process/update-action/:enterprise_id/:enterprise_group_id/:sop_actioned_event_id
    // Required Parameters;
    //   action_update_type - "U"
    //   close_down_option_id - Id from the Close Down Options endpoint
    // Extra Optional Parameters for Unaction
    //   close_down_comment

    const closeDownAction = conxtdOut.post(`/sop/sop-action-process/update-action/${enterpriseId}/${groupId}/${sopActionedEventId}`, {
      action_update_type: "U",
      close_down_option_id: this.state.closeDownOption,
      close_down_comment: encodedComment,
    });

    closeButton.classList.add("cursor-progress");
    closeButton.disabled = true;

    closeDownAction.then(() => {
      onHide();
      document.body.classList.remove("sop-sidepane-active");
      closeButton.classList.remove("cursor-progress");
      closeButton.disabled = false;

      this.props.dispatch(
        clearSOPActionMap()
      );

      if (!muteOnCloseSlider && muted) {
        muteClick(); // muteClick toggles the muted value (amongst other things), so in this case it will unmute
      };

      if (document.getElementById('call-controls-container').classList.contains("show")) {
        document.getElementById('call-controls-container').classList.remove("show");

        this.props.dispatch(
          setVoiceCallStatus({
            callStatus: "idle"
          })
        );
      }

    }).catch((error) => {
      console.log("Error details", error);
      Alert({
        text: "Issue closing action. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
      closeButton.classList.remove("cursor-progress");
      closeButton.disabled = false;
    })
  };

  render() {
    const { closeDownOptions } = this.props;

    const realAlarmOptions = [];
    const falseAlarmOptions = [];
    const otherAlarmOptions = [];

    closeDownOptions.forEach((option) => {
      if (option.type === "R") {
        realAlarmOptions.push({ value: option.id, label: option.option })
      } else if (option.type === "F") {
        falseAlarmOptions.push({ value: option.id, label: option.option })
      } else if (option.type === "U") {
        otherAlarmOptions.push({ value: option.id, label: option.option })
      }
    }); 

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Close Action
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Form onSubmit={this.handleSubmit}>
              {
                realAlarmOptions.length >= 1 &&
                  <Row className="align-items-center" style={{height: "32px"}}>
                    <Col className="pt-1">
                      <input
                        type="radio"
                        id="realAlarm"
                        name="closeDownType"
                        value="R"
                        required
                        onChange={(event) => {
                          this.handleCloseDownTypeChange(event.currentTarget.value, document.getElementById("realAlarmOption").value)
                        }}
                      />
                      <label htmlFor="realAlarm" className="ml-1">Real Alarm</label>
                    </Col>
                    <Col>
                      {
                        realAlarmOptions.length > 1 
                          ? <select 
                              id="realAlarmOption" 
                              name="realAlarmOption" 
                              className="close-action-select"
                              onChange={this.handleCloseDownOptionChange}
                              disabled={this.state.closeDownType !== "R"}
                            >
                              {realAlarmOptions.map((option) => {
                                return <option value={option.value} key={option.value}>{option.label}</option>
                              })}
                            </select>
                          : <div>
                              <input id="realAlarmOption" type="hidden" value={realAlarmOptions[0].value} />
                              {realAlarmOptions[0].label}
                            </div>
                      }
                    </Col>
                  </Row>
              }
              {
                falseAlarmOptions.length >= 1 &&
                  <Row className="align-items-center mt-2" style={{height: "32px"}}>
                    <Col className="pt-1">
                      <input
                        type="radio"
                        id="falseAlarm"
                        name="closeDownType"
                        value="F"
                        required
                        onChange={(event) => {
                          this.handleCloseDownTypeChange(event.currentTarget.value, document.getElementById("falseAlarmOption").value)
                        }}
                      />
                      <label htmlFor="falseAlarm" className="ml-1">False Alarm</label>
                    </Col>
                    <Col>
                      {
                        falseAlarmOptions.length > 1 
                          ? <select 
                              id="falseAlarmOption" 
                              name="falseAlarmOption" 
                              className="close-action-select"
                              onChange={this.handleCloseDownOptionChange}
                              disabled={this.state.closeDownType !== "F"}
                            >
                              {falseAlarmOptions.map((option) => {
                                return <option value={option.value} key={option.value}>{option.label}</option>
                              })}
                            </select>
                          // ? <Select
                          //     id="falseAlarmOption"
                          //     // required - not implemented for the Select react-select component
                          //     defaultValue={falseAlarmOptions[0]}
                          //     options={falseAlarmOptions}
                          //     isDisabled={this.state.closeDownType !== "F"}
                          //     onChange={this.handleCloseDownOptionChange}
                          //   />
                          : <div>
                              <input id="falseAlarmOption" type="hidden" value={falseAlarmOptions[0].value} />
                              {falseAlarmOptions[0].label}
                            </div>
                      }
                    </Col>
                  </Row>
              }
              {
                otherAlarmOptions.length >= 1 &&
                  <Row className="align-items-center mt-2" style={{height: "32px"}}>
                    <Col>
                      <input
                        type="radio"
                        id="otherAlarm"
                        name="closeDownType"
                        value="U"
                        required
                        onChange={(event) => {
                          this.handleCloseDownTypeChange(event.currentTarget.value, document.getElementById("otherAlarmOption").value)
                        }}
                      />
                      <label htmlFor="otherAlarm" className="ml-1">Other</label>
                    </Col>
                    <Col>
                      {
                        otherAlarmOptions.length > 1
                          ? <select 
                              id="otherAlarmOption" 
                              name="otherAlarmOption" 
                              className="close-action-select"
                              onChange={this.handleCloseDownOptionChange}
                              disabled={this.state.closeDownType !== "U"}
                            >
                              {otherAlarmOptions.map((option) => {
                                return <option value={option.value} key={option.value}>{option.label}</option>
                              })}
                            </select> 
                          : <div>
                              <input id="otherAlarmOption" type="hidden" value={otherAlarmOptions[0].value} />
                              {otherAlarmOptions[0].label}
                            </div>
                      }
                    </Col>
                  </Row>
              }

              <textarea
                name="comment" 
                rows="3"
                className="mt-2 mb-3 close-action-comment"
                style={{width: "100%"}}
              ></textarea>

              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2 p-2"
                  variant="primary"
                  onClick={this.props.onHide}
                >
                  Cancel
                </Button>
                <Button 
                  id="sop-close-action-modal-button"
                  className="p-2" 
                  variant="success" 
                  type="submit"
                >
                  Close Action
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    closeDownOptions: state.sop.closeDownOptions,
    muted: state.alarmResponse.muted,
    muteOnCloseSlider: state.alarmResponse.muteOnCloseSlider,
  };
};

export default connect(mapStateToProps)(CloseActionModal);