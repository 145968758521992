import React from "react";

// import { withRouter, Redirect, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";

import Icon from "components/Icons/Icon";
import BREADCRUMBS from "constants/BREADCRUMBS.json";

import _ from "lodash";

class HomeButtonMob extends React.Component {
  handleOnClick = (e) => {
    e.preventDefault();

    this.props.history.push(`/enterprise`);
  };
  render() {
    let paths = window.location.toString().split("/");
    paths.splice(0, 3);

    // Remove hidden paths
    paths = paths.filter((path) => {
      const hidden = _.get(BREADCRUMBS[path], "hidden");
      return hidden !== "true";
    });

    // Don't show this button if on top level
    if (paths.length === 1) return null;

    return (
      <button
        type="button"
        className="button-menu-mobile open-left waves-effect d-sm-none"
        onClick={this.handleOnClick}
      >
        <Icon className="fas fa-home font-16" />
      </button>
    );
  }
}

export default withRouter(HomeButtonMob);
