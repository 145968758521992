export const formatMstoMS = (ms) => {
  const minute = Math.floor(ms / 60000);
  const second = Math.floor(ms / 1000) % 60;
  return String(minute).padStart(2, '0') + ":" + String(second).padStart(2, '0');
};

export const formatMstoHMS = (ms) => {
  const hour = Math.floor(ms / 3600000);
  const minute = Math.floor(ms / 60000) % 60;
  const second = Math.floor(ms / 1000) % 60;
  return String(hour).padStart(2, '0') + ":" + String(minute).padStart(2, '0') + ":" + String(second).padStart(2, '0');
};

export const formatMstoDHMS = (ms) => {
  const day = Math.floor(ms / 86400000);
  const hour = Math.floor(ms / 3600000) % 24;
  const minute = Math.floor(ms / 60000) % 60;
  const second = Math.floor(ms / 1000) % 60;
  return String(day) + ":" + String(hour).padStart(2, '0') + ":" + String(minute).padStart(2, '0') + ":" + String(second).padStart(2, '0');
};