import React from "react";
import { connect } from "react-redux";

/**
 * Enterprise Management Notifications Tab
 *
 **/
class Notifications extends React.Component {

  render() {

    return (
      <>
        Notifications
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
};
export default connect(mapStateToProps)(Notifications);