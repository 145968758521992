import React from "react";
import { connect } from "react-redux";

import { default as T } from "services/i18n/Translate";

import { getPieChartTotals } from "services/redux/selectors/reports/common/pie";

import { getReportType, getReportPath } from "services/redux/selectors/reports/router";

import "./FilterCirclesGeneral.scss";

import _ from "lodash";

class FilterCirclesGeneral extends React.Component {
  changeFilter = show => {

    const { reportPath, reportType, disableFilter, changeReportFilter } = this.props;

    if (disableFilter) return;

    this.props.dispatch(
      changeReportFilter({
        show,
        reportType,
        reportPath,
      })
    );
  };
  
  render() {
    const { totals, filter, reportPath } = this.props;

    if (reportPath[0] === "failedtoclose" && this.props.disableFilter) return (
      <div className="d-flex flex-column align-items-center">
        <h5>{totals.sites}</h5>
        <p className="text-muted">Total sites</p>
      </div>
    );

    const numberOfItems = totals.data.length;

    return (
      <div className="row justify-content-center">
        <div className={`row filter-widgets-row ${numberOfItems <= 8 ? "filter-widgets-row-" + numberOfItems : "filter-widgets-row-9-plus"}`}>
          {totals.data.map((item, index) => {
            if (!_.get(item, "name")) return null;
            const show =
              Object.keys(filter).length === 0
                ? "show"
                : _.get(filter, item.name) === true
                ? "show"
                : "";
            return (
              <div style={{ position: "relative" }}>
                <div
                  id={item.id}
                  className={`text-center text-light`}
                  key={index}>
                  <div
                    className={`filter-event-type ${show}`}
                    onClick={() => {
                      const label = item.name;
                      this.changeFilter(label);
                    }}
                    style={{
                      backgroundColor: totals.colors[index],
                      display: "inline-block",
                      borderRadius: "50% 50%"
                    }}>
                    <h5 className="widget-title">{item.value}</h5>
                    <p>
                      <T>{item.name}</T>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props),
    reportPath: getReportPath(state, props),
    totals: getPieChartTotals(state, props)(state, props)
  };
};
export default connect(mapStateToProps)(FilterCirclesGeneral);
