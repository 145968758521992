import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, Row, Col, Form, FormGroup } from "react-bootstrap";
import Select from 'react-select';
import conxtdOut from "apis/conxtdOut";
import Alert from "components/Common/Alert";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import {
  getGroupFromRoute,
  getGroupsTree,
  getGroupsTreeOptions,
} from "services/redux/selectors/groups";
import { getGroupsDropDownListStart } from "services/redux/actions/enterpriseManager";
import _ from "lodash";

import "./EditGroupModal.scss";

/**
 * Enterprise Management Edit Group
 *
 * Allows user to edit details of a group or delete group
 *
 **/
class EditGroupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupIdForDelete: null,
      selectedParentOption: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { show, parent_id, enterprise } = this.props;

    if ((!prevProps.show) && show) { // show changing from false to true
      const parentGroupId = parent_id || enterprise.root_group_id;
      this.setState({
        selectedParentOption: _.find(this.props.groupstreeoptions, ['value', parentGroupId]),
      });
    }
  }

  handleChange = (event) => {
    const groupIdForDelete = event.target.value;
    this.setState({ groupIdForDelete });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { enterprise, group, parent_id } = this.props;

    try {
      await conxtdOut.post(
        `/EnterpriseGroups/edit/${enterprise.id}/${group.id}`,
        {
          parent_id: event.target.parent.value || parent_id,
          name: event.target.name.value,
          description: event.target.description.value,
          custom_group_id: event.target.custom_group_id.value,
          custom_field_name_1: event.target.custom_field_name_1.value,
          custom_field_value_1: event.target.custom_field_value_1.value,
          custom_field_name_2: event.target.custom_field_name_2.value,
          custom_field_value_2: event.target.custom_field_value_2.value,
          custom_field_name_3: event.target.custom_field_name_3.value,
          custom_field_value_3: event.target.custom_field_value_3.value,
        }
      );
      Alert({
        text: "Group has been edited.",
        icon: "success",
        showConfirmButton: false,
      });
      setTimeout(function () {
        window.location.reload(1);
      }, 1000);
      setTimeout(1);
    } catch (error) {
      let apiError = error.response.data.message;
      let customError = error.response.data.message;

      if (apiError.includes("Group already exists")) {
        customError = "Group already exists.";
      } else {
        return customError;
      }
      Alert({
        text: customError,
        icon: "error",
      });
    }
  };

  handleDelete = async (event) => {
    event.preventDefault();
    const { group } = this.props;

    let groupId = null;
    if (this.state.groupIdForDelete === null) {
      groupId = group.id;
    } else {
      groupId = this.state.groupIdForDelete;
    }
    try {
      await conxtdOut.delete(`/EnterpriseGroups/deleteGroup/${groupId}`);
      Alert({
        text: "Group has been deleted.",
        icon: "success",
        showConfirmButton: false,
      });
      setTimeout(function () {
        window.location.reload(1);
      }, 1000);
      setTimeout(1);
    } catch (error) {
      let apiError = error.response.data.message;
      let customError = error.response.data.message;

      if (apiError.includes("group when it has children")) {
        customError = "Cannot delete group when it has children.";
      } else if (apiError.includes("group with sites")) {
        customError = "Cannot remove a group with sites in it.";
      } else {
        return customError;
      }
      Alert({
        text: customError,
        icon: "error",
      });
    }
  };

  render() {
    const { groupstreeoptions } = this.props;
    const { selectedParentOption} = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
        <Form className="edit-group-form" onSubmit={this.handleSubmit}>
          <Modal.Header
            closeButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="container">
              <Row>
                <Col sm={7}>
                  <Form.Group style={{ width: "440px" }} controlId="NewGroup">
                    <Form.Group style={{ width: "440px" }} controlId="parent">
                      <Form.Label>Parent</Form.Label>
                      <Select
                        name="parent"
                        classNamePrefix="edit-group-modal-parent-select"
                        options={groupstreeoptions}
                        value={selectedParentOption}
                        maxMenuHeight={400}
                        onChange={(e) => {
                          this.setState({ selectedParentOption: _.find(groupstreeoptions, ['value', e.value]) })
                        }}
                      />
                      {/* <Form.Control
                        as="select"
                        name="Parent"
                        className="mb-3"
                        required
                        placeholder="Parent"
                        defaultValue={this.props.parent_id}
                        onChange={(event) => this.handleChange(event)}
                      >
                        {groupstree.map((obj) => {
                          return (
                            <option key={obj.group_id} value={obj.group_id}>
                              {obj.group_name}
                            </option>
                          );
                        })}
                      </Form.Control> */}
                    </Form.Group>
                    <FormGroup controlId="name" style={{ width: "440px" }}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="Name"
                        className="mb-3"
                        required
                        placeholder="Name"
                        defaultValue={this.props.name}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="description"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="Description"
                        className="mb-3"
                        // required
                        placeholder="Description"
                        defaultValue={this.props.description}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_group_id"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Group Ref</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Group Ref"
                        className="mb-5"
                        defaultValue={this.props.custom_group_id}
                        maxLength="4"
                        placeholder="maximum character limit: 4"
                      />
                    </FormGroup>
                    <Form.Text
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      Custom Fields
                      <hr
                        className="borderBreak"
                        style={{ border: "1px solid black" }}
                      ></hr>
                    </Form.Text>{" "}
                    <FormGroup
                      controlId="custom_field_name_1"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Name 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Name 1"
                        className="mb-3"
                        defaultValue={this.props.custom_field_name_1}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_value_1"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Value 1</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Value 1"
                        className="mb-3"
                        defaultValue={this.props.custom_field_value_1}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_name_2"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Name 2</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Name 2"
                        className="mb-3"
                        defaultValue={this.props.custom_field_name_2}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_value_2"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Value 2</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Value 2"
                        className="mb-3"
                        defaultValue={this.props.custom_field_value_2}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_name_3"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Name 3</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Name 3"
                        className="mb-3"
                        defaultValue={this.props.custom_field_name_3}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="custom_field_value_3"
                      style={{ width: "440px" }}
                    >
                      <Form.Label>Custom Field Value 3</Form.Label>
                      <Form.Control
                        type="text"
                        name="Custom Field Value 3"
                        className="mb-4"
                        defaultValue={this.props.custom_field_value_3}
                      />
                    </FormGroup>
                  </Form.Group>
                
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="container">
            <div
              style={{
                display: "flex",
                alignItems: "flexStart",
              }}
            >
              <Button
                className="mr-auto p-2"
                variant="danger"
                onClick={this.handleDelete}
              >
                Delete
              </Button>
              <Button
                variant="primary"
                className="mr-2"
                onClick={this.props.onHide}
              >
                Close
              </Button>
              <Button variant="success" type="submit">
                Update
              </Button>
            </div>
          </div>
        </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groupstree: getGroupsTree(state, props),
    groupstreeoptions: getGroupsTreeOptions(state, props),
  };
};
const mapDispatchToProps = {
  getGroupsDropDownListStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupModal);
