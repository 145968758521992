import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { Button, ButtonToolbar } from "react-bootstrap";

import TimezoneDate from "components/Common/TimezoneDate";
import EventCell from "components/Common/DataTable/EventCell/";
import SiteNameCell from "components/Common/DataTable/SiteNameCell/";
import Icon from "components/Icons/Icon";
import SiteView from "components/Site/SiteView/";
import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import {
  loadSiteViewLiveRefreshCancel,
  setFailedToCloseExpandSite,
  resetFailedToCloseExpanded,
  changeDataTableFiltered,
} from "services/redux/actions";

import { getReportPath } from "services/redux/selectors/reports/router";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import { getColumns, getFiltered } from "services/redux/selectors/reports/common/";
import {
  getExpanded,
  getExpandedRowsCount,
  getFailedToCloseDataFiltered,
  getFailedToCloseReportExpanded,
} from "services/redux/selectors/reports/failedtoclose";

import TrGroupComponent from "./TrGroupComponent";

import "./DataTable.scss";

import moment from "services/locale/momentInit.js";

const DataTable = (props) => {
  const dispatch = useDispatch();

  const dataTableRef = useRef(null);

  // state
  const [selectedSite, setSelectedSite] = useState(null);

  // selectors
  const reportPath = useSelector((state) => getReportPath(state, props));
  const data = useSelector(getFailedToCloseDataFiltered);
  const columns = useSelector((state) => getColumns(state, props));
  const expanded = useSelector((state) => getFailedToCloseReportExpanded(state, props));
  const filtered = useSelector((state) => getFiltered(state, props));
  const getEventMetaById = useSelector((state) => createGetEventMetaById(state, props));

  const onSelectSite = site => {
    // new site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");

      setSelectedSite(site);

    } else {
      document.body.classList.remove("sidepane-active");

      dispatch(
        loadSiteViewLiveRefreshCancel()
      );

      // delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        setSelectedSite(null);
      }, 600);
    }
  };

  const onFilteredChangeCustom = (value, accessor) => {
    let filteredItems = [...filtered];
    let insertNewFilter = 1;
    let shouldResetExpanded = false;

    // filter already exists
    if (filteredItems.length) {
      filteredItems = filteredItems
        .map((filter, i) => {
          if (filter["id"] === accessor) {
            insertNewFilter = 0;

            // filter text value is empty
            if (value === "" || !value.length) {
              // this will be filtered out later (see remove falsey below)
              return false;
            } else {
              return {
                ...filter,
                value
              };
            }
          }
          return filter;
        })
        // remove falsey values
        .filter(falsey => falsey);

      // filter existed but will be removed - reset expanded
      if (filtered.length === 0) {
        shouldResetExpanded = true;
      }
    }

    // filter doesn't exist - add
    if (insertNewFilter) {
      filteredItems = [...filteredItems, { id: accessor, value: value }];
      // introducing a new filter - reset expanded
      shouldResetExpanded = true;
    }

    if (shouldResetExpanded) {
      dispatch(
        resetFailedToCloseExpanded()
      );
    }

    dispatch(
      changeDataTableFiltered({
        filtered: filteredItems,
        reportPath,
      })
    );
  };

  const defaultPageSize = 20;

  // how many of the expandable rows have been expanded by the user
  const expandedRowsCount = getExpandedRowsCount(expanded);

  const areaStateTextFilter = filtered.find(({ id }) => id === "area-state");

  const tableColumns = [
    {
      ...columns["CUSTOM_ID"],
      id: "custom-id",
      accessor: row => row.custom_site_ref,
    },
    {
      ...columns["NAME"],
      id: "site-name",
      accessor: "name",
      Cell: row => {
        return (
          <>
            <SiteNameCell
              {...row}
              selectSite={() => {
                onSelectSite(row.original);
              }}
            />
          </>
        );
      }
    },
    {
      key: "STORE_TYPE",
      Header: "Store Type",
      id: "store-type",
      width: 100,
      accessor: row => row.store_type,
    },
    {
      key: "CONTRACT_NO",
      Header: "Contract No",
      id: "contract-no",
      width: 100,
      accessor: row => row.contract_no,
    },
    {
      key: "SYSTEM_TYPE",
      Header: "System Type",
      id: "system-type",
      width: 100,
      accessor: row => row.system_type,
    },
    {
      ...columns["GROUP"],
      id: "group",
      accessor: row => row.group_name,
    },
    {
      ...columns["SITE_REF"],
      id: "site-ref",
      accessor: row => row.site_ref,
    },
    {
      key: "PERIMETER",
      Header: "Perimeter",
      id: "perimeter",
      width: 120,
      className: "alarm-type",
      accessor: row => row.perimeter_event_meta.caption,
      Cell: ({ original }) => {
        const { perimeter_event_meta } = original;
        return (
          perimeter_event_meta 
            ? <EventCell
                event={perimeter_event_meta}
              />
            : ""
        )
      },
    },
    {
      key: "AREA",
      Header: "Area",
      id: "area",
      width: 80, 
      accessor: row => (row.areas && row.areas.length > 0) ? `Total: ${row.areas.length + 1}` : row.area,
      Cell: (data) => {
        const { areas } = data.original;
        if (areas && areas.length > 0) {
          return (
            <div className="d-flex justify-content-between flex-nowrap">
              <span>Total:&nbsp;</span><span>{areas.length + 1}</span>
            </div>
          )
        } else {
          return <div>{data.row.area}</div>
        }
      },
      filterMethod: (filter, row) => {
        const { areas } = row._original;

        // check if the filtered text exists in the current calculated row display (could be an area code or could be the 'Total...' text),
        // and also check if it exists in the original main site data (i.e. any data has been replaced by the 'Total...' text if there are sub-areas),
        // and also check if it exists in the sub-areas
        if (String(row.area).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (String(row._original.area).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (areas) {
          areas.forEach((area) => {
            if (area.area && area.area.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
              return true;
            }
          })
        }
        return false;
      },
    },
    {
      key: "AREA_NAME",
      Header: "Area Name",
      id: "area-name",
      width: 120,
      accessor: row => (row.areas && row.areas.length > 0) ? "" : row.area_name,
      filterMethod: (filter, row) => {
        const { areas } = row._original;

        // check if the filtered text exists in the current calculated row display (could be area name or blank),
        // and also check if it exists in the original main site data (i.e. any data that has been replaced by blank if there are sub-areas),
        // and also check if it exists in the sub-areas
        if (String(row["area-name"]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (String(row._original.area_name).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (areas) {
          areas.forEach((area) => {
            if (area.area_name && area.area_name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
              return true;
            }
          })
        }
        return false;
      },
    },
    {
      key: "AREA_STATE",
      Header: "Area State",
      id: "area-state",
      width: 120,
      className: "alarm-type",
      accessor: row => (row.areas && row.areas.length > 0) ? "" : row.state_event_meta.caption,
      Cell: ({ original }) => {
        const { areas } = original;
        if (!areas || areas.length === 0) { // no sub-areas listed
          const { state_event_meta } = original;
          return state_event_meta 
            ? <EventCell
                event={state_event_meta}
              />
            : ""
        } else {
          if (!original.expanded) {
            // the sub areas will probably always be the same partial open event, but just in case they aren't, show the proportions of each event

            // combine the main state id and the area state ids into one id array
            const stateIdsArray = [original.state].concat(original.areas.map(area => area.state));
            const total = stateIdsArray.length;

            // count the number of each different state ids
            const stateIdCounts = {};
            stateIdsArray.forEach(id => {
              if (stateIdCounts[id]) {
                stateIdCounts[id] += 1;
              } else {
                stateIdCounts[id] = 1;
              }
            });

            // show proportions of each id
            return <div className="d-flex">
              {
                Object.entries(stateIdCounts).map(([id, count]) => {
                  const eventMeta = getEventMetaById(parseInt(id));
                  const percentage = (count / total) * 100 || 0;

                  return <div
                    key={id}
                    className="inner" 
                    style={{
                      width: percentage + "%",
                      height: "1.5rem",
                      backgroundColor: eventMeta.colour,
                    }}></div>
                })
              }
            </div>
          } else { return "" };
        }
      },
      filterMethod: (filter, row) => {
        const { areas } = row._original;

        // check if the filtered text exists in the current calculated row display (could be area state or blank),
        // and also check if it exists in the original main site data (i.e. any data that has been replaced by blank if there are sub-areas),
        // and also check if it exists in the sub-areas
        if (String(row["area-state"]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (String(row._original.state_event_meta.caption).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (areas) {
          areas.forEach((area) => {
            if (area.area_state_event_meta && area.area_state_event_meta.caption.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
              return true;
            }
          })
        }
        return false;
      },
    },
    {
      key: "LAST_STATE_EVENT",
      Header: "Last State Event",
      id: "last-state-event",
      width: 130,
      accessor: row => (row.areas && row.areas.length > 0) ? "" : row.last_state_event,
      filterMethod: (filter, row) => {
        const { areas } = row._original;

        // check if the filtered text exists in the current calculated row display (could be last state event or blank),
        // and also check if it exists in the original main site data (i.e. any data that has been replaced by blank if there are sub-areas),
        // and also check if it exists in the sub-areas
        if (String(row["last-state-event"]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (String(row._original.last_state_event).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (areas) {
          areas.forEach((area) => {
            if (area.last_state_event && area.last_state_event.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
              return true;
            }
          })
        }
        return false;
      },
    },
    {
      key: "LAST_STATE_CHANGE",
      Header: "Last State Change",
      id: "last-state-change",
      width: 135,
      accessor: row => (row.areas && row.areas.length > 0) ? null : row.last_state_change,
      Cell: ({ row, original })  => {
        const last_state_change = row["last-state-change"];
        const { areas, timezone } = original;

        const today = new moment();

        // colour code according to how long ago last event was
        let colorClass = "";
        if (areas && (areas.length === 0)) {
          if (last_state_change) {
            const lastStateChange = new moment(last_state_change);
            const daysAgo = -lastStateChange.diff(today, "day");
            // more than a day but less than a week
            if (daysAgo > 0 && daysAgo <= 6) {
              colorClass = "last-state-change-orange";
            // more than a week
            } else if (daysAgo > 6) {
              colorClass = "last-state-change-red";
            }

            if (!timezone) {
              return (
                <div className={colorClass}>
                  {lastStateChange.format("DD/MM/YYYY HH:mm")}
                </div>
              );
            } else {
              return (
                <div className={colorClass}>
                  <TimezoneDate date={lastStateChange} timezone={timezone} format="DD/MM/YYYY HH:mm" hideTimezone />
                </div>
              );
            }
          } else {
            return <></>
          }
        } else {
          return <></>
        };
      },
      filterMethod: (filter, row) => {
        const { areas } = row._original;

        // check if the filtered text exists in the current calculated row display (could be last state change or blank),
        // and also check if it exists in the original main site data (i.e. any data that has been replaced by blank if there are sub-areas),
        // and also check if it exists in the sub-areas
        if (String(row["last-state-change"]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (String(row._original.last_state_change).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
          return true;
        } else if (areas) {
          areas.forEach((area) => {
            if (area.last_state_change && area.last_state_change.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) {
              return true;
            }
          })
        }
        return false;
      },
    },
    {
      ...columns["EXPAND"],
      Cell: ({ original }) => {
        const { sudo_site_id, areas } = original;
        let colExpanded;

        colExpanded = getExpanded(original);

        const expandable = areas.length > 0;

        if (!expandable) {
          return null;
        }
  
        return (
          <Button
            className="expandIconButton"
            variant={`${colExpanded ? "" : "outline-"}primary`}
            size="sm"
            onClick={() => {
              dispatch(
                setFailedToCloseExpandSite({
                  sudo_site_id,
                  expanded: !colExpanded
                })
              );
            }}
          >
            {colExpanded ? (
              <Icon className="fas fa-chevron-up" />
            ) : (
              <Icon className="fas fa-chevron-down" />
            )}
          </Button>
        );
      }
    }
  ];

  const getReportTitle = () => {
    const today = new moment().format("YYYY-MM-DD");
    return "failed to close " + today;
  };

  return (
    <>
      <ButtonToolbar className="p-2">
        <ExportDropdown
          disabled={data.length === 0}
          reportTitle={getReportTitle()}
          excludePDF={true} // the pdf export looked awful for ftc - need to fix the layout before offering it as an option
          dataResolver={() => {
            const data = dataTableRef.current.getResolvedState().sortedData;

            let result = [];

            // iterate through the rows
            data.forEach((row) => {
              // first, export the data from the root of the site row
              result.push([
                row["custom-id"] || "",
                row["site-name"] || "",
                row["store-type"] || "",
                row["contract-no"] || "",
                row["system-type"] || "",
                row["group"] || "",
                row["site-ref"] || "",
                row["perimeter"] || "",
                row._original.area || "",
                row._original.area_name || "",
                row._original.state_event_meta.caption || "",
                row._original.last_state_event || "",
                moment(row._original.last_state_change).format("DD/MM/YYYY HH:mm") || ""
              ]);

              // if there are sub areas, add those rows to the export
              if (row._original.areas && row._original.areas.length > 0) {
                // iterate through the areas
                row._original.areas.forEach((area) => {
                  result.push([
                    row["custom-id"] || "",
                    row["site-name"] || "",
                    row["store-type"] || "",
                    row["contract-no"] || "",
                    row["system-type"] || "",
                    row["group"] || "",
                    row["site-ref"] || "",
                    row["perimeter"] || "",
                    area.area || "",
                    area.area_name || "",
                    area.area_state_event_meta.caption || "",
                    area.last_state_event || "",
                    moment(area.last_state_change).format("DD/MM/YYYY HH:mm")  || "",
                  ]);
                });
              }
            });

            result.unshift([
              "Custom ID",
              "Site Name",
              "Store Type",
              "Contract No",
              "System Type",
              "Group",
              "Site Ref",
              "Perimeter",
              "Area",
              "Area Name",
              "Area State",
              "Last State Event",
              "Last State Change",
            ]);

            return result;
          }}
        />
      </ButtonToolbar>
      <ReactTable
        ref={r => (dataTableRef.current = r)}
        data={data}
        className={`${expandedRowsCount > 0 ? "focus-expanded" : ""} ${
          areaStateTextFilter ? " event-text-filter-active" : ""
        }`}
        filterable
        filtered={props.filtered}
          onFilteredChange={(filtered, column, value) => {
            onFilteredChangeCustom(value, column.id || column.accessor);
          }}
        defaultPageSize={defaultPageSize}
        defaultFilterMethod={(filter, row) => {
          return (
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        }}
        columns={tableColumns}
        TrGroupComponent={TrGroupComponent}
        // Override row group props for sub rows
        getTrGroupProps={(state, rowInfo, column) => {
          const props = {
            filtered,
            columns,
          };
          if (rowInfo !== undefined) {
            return {
              ...props,
              expanded: getExpanded(rowInfo.original) ? 1 : 0,
              site: rowInfo.original
            };
          }
          return {
            ...props,
            expanded: 0,
            className: ""
          };
        }}
      />
      <SiteView
        site={selectedSite}
        selectSite={onSelectSite}
        bundle_id={null}
      />
    </>
  );
}

export default DataTable;