import React from "react";

import { Form } from "react-bootstrap";

class TextFilter extends React.Component {
  render() {
    const {
      value,
      onChange,
      placeholder,
      formStyle,
      formClassName,
      formGroupClassName
    } = this.props;

    return (
      <Form
        className={formClassName || ""}
        style={formStyle || {}}
        onSubmit={e => e.preventDefault()}>
        <Form.Group className={formGroupClassName || ""}>
          <Form.Control
            type="search"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </Form.Group>
      </Form>
    );
  }
}
export default TextFilter;
