import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "services/locale/momentInit.js";

import { Button, Form } from "react-bootstrap";

import conxtdOut from "apis/conxtdOut";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import Alert from "components/Common/Alert";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };

    // Should load by group id from router.params
    //  if specified, otherwise root group
    /* const params = {
      enterprise_id: props.enterprise.id,
      group_id: group_id,
      key: "all",
      refresh: true,
      interval: 3,
      timeframe: props.currentGroup.timeframe,
    };
*/
    this.clearData = this.clearData.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }
  clearData() {
    this.setState({
      text: "",
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = async (e) => {
    e.preventDefault();
    const { loggedInUser, sites } = this.props;
    const firstName = loggedInUser.firstName;
    const lastName = loggedInUser.lastName;
    const fullName = firstName + " " + lastName;
    const siteId = sites.current.id;

    const comment = this.state;
    const text2 = comment.text;
    const event = "C150";
    const zone = "";
    const text = fullName + " - " + text2;
    const time = moment.utc().format();

    try {
      // const { loggedInUser, sites } = this.props;
      /*    function refreshPage() {
        window.location.reload(false);
      }
      */

      setTimeout(function () {
        window.location.reload(1);
      }, 1000);
      setTimeout(1);

      await conxtdOut.post(`/Events/add/${siteId}`, {
        event,
        zone,
        text,
        time,
      });
      this.setState({
        comment: "",
        text2: "",
        text: "",
        time: "",
      });
      //  conxtdOut.get(`/EnterpriseSites/view/${siteId}/30`);
      // await this.props.dispatch(loadSiteIntegrationsStart(params));
      //    conxtdOut.get(`/integrations/index/22302`);
      //    this.props.dispatch(loadSiteViewSuccess("212"));
      /*
      this.props.dispatch(
        routerNavigate({
          params: {
            enterprise_id: "212",
            group_id: "311",
            site_id: "22302",
            bundle_id: "30",
          },
          location: {
            pathname: "/enterprise/212/311/22302/30",
            search: "",
            hash: "",
            key: "",
          },
        })
      );
      */

      Alert({
        text: "Comment has been submitted.",
        icon: "success",
        //timer: 4000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("Error details", error);
    }
  };

  onCancel(e) {
    e.preventDefault();
    this.clearData();
  }

  render() {
    // const { text } = this.props;
    return (
      <Form onSubmit={this.submitHandler}>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <div>
            <h6>Introducing comments</h6>
            Ability to add comment events into site timeline. <br></br>
            Please note:
            <ul>
              <li>
                Anyone who has access to the site will be able to view the
                comment. This could be end user and installer.
              </li>
              <li>Users name will appear before each comment.</li>
            </ul>
            Comments can be received as notifications if users have all events
            enabled or CONXTD reminders. <br></br> <br></br>
            <p>
              <a
                href={`https://intercom.help/conxtd/en/articles/4191380-comment-events`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Learn More</Button>
              </a>
            </p>
          </div>
          <Form.Control
            as="textarea"
            rows="6"
            required
            type="text"
            name="text"
            placeholder="Add your comment here..."
            onChange={this.changeHandler}
            maxLength="300"
            value={this.state.text}
          />
        </Form.Group>
        <Button
          //  disabled={loading}
          type="submit"
          variant="success"
          className="float-right"
          style={{ height: "30%", width: "30%", marginBottom: "90px" }}
        >
          {/*    {loading && <Loading />} */}
          Submit Comment
        </Button>{" "}
        <Button
          variant="primary"
          className="float-left"
          style={{ height: "30%", width: "30%" }}
          onClick={this.onCancel}
        >
          Clear Comment
        </Button>
      </Form>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    loggedInUser: state.user,
    sites: state.sites,
    users: state.users,
    getEventMetaById: createGetEventMetaById(state, props),

    //  pathname: getPathname(props),
  };
};

export default connect(mapStateToProps, null)(Comments);
