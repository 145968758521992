import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import DarkMode from "./DarkMode";
import SnowMode from "./SnowMode";

import Icon from "components/Icons/Icon";
import CONXTDLogo from "components/Layout/CONXTDLogo";

import UserDropdown from "./UserDropdown";

import { SearchBar, SearchToggle } from "./SearchBar";

import WaveLink from "components/Layout/WaveLink";

import { conxtdAdmin, mockEnabled, devMode } from "services/redux/selectors/";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import "./NavTop.scss";

import { globalSearchToggle } from "services/redux/actions";

import ToggleButton from "components/Common/Buttons/Toggle";
import BackArrow from "./BackArrow";
import HomeButtonMob from "./HomeButtonMob";
import RefreshButtonMob from "./RefreshButtonMob";

class NavTop extends React.Component {
  state = {
    showNavUserDropdown: false,
  };
  toggleNavUserDropdown = () => {
    this.setState({ showNavUserDropdown: !this.state.showNavUserDropdown });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Close search when there is no enterprise
    if (!nextProps.enterprise && nextProps.search.open) {
      this.props.dispatch(globalSearchToggle({ open: false }));
    }
  }
  handleRefreshBtnClick = (e) => {
    e.preventDefault();

    window.location.reload();
  };

  render() {
    const { enterprise, t } = this.props;
    const navUserDropdownClass = this.state.showNavUserDropdown ? " show" : "";
    const fullName = this.props.user.firstName + " " + this.props.user.lastName;

    return (
      <div className="topbar">
        <div className="topbar-left	d-none d-lg-block">
          <div className="text-center">
            <CONXTDLogo />
          </div>
        </div>

        <nav className="navbar-custom">
          <SearchBar />

          <ul className="list-inline float-right mb-0">
            {enterprise && <SearchToggle />}

            {/*
            <li className="list-inline-item dropdown notification-list">
              <a
                className="nav-link dropdown-toggle arrow-none waves-effect"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false">
                <i className="mdi mdi-bell-outline noti-icon" />
                <span className="badge badge-danger badge-pill noti-icon-badge">
                  3
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-menu-lg dropdown-menu-animated">
                <div className="dropdown-item noti-title">
                  <h5>Notification (3)</h5>
                </div>

                <div className="slimscroll-noti">
                  <a
                    href="javascript:void(0);"
                    className="dropdown-item notify-item active">
                    <div className="notify-icon bg-success">
                      <i className="mdi mdi-cart-outline" />
                    </div>
                    <p className="notify-details">
                      <b>Your order is placed</b>
                      <span className="text-muted">
                        Dummy text of the printing and typesetting industry.
                      </span>
                    </p>
                  </a>
                </div>

                <a
                  href="javascript:void(0);"
                  className="dropdown-item notify-all">
                  View All
                </a>
              </div>
            </li>*/}

            {/* <div>{this.state}</div> */}

            <li
              className={`list-inline-item dropdown notification-list nav-user float-right ${navUserDropdownClass}`}
            >
              <WaveLink
                onClick={this.toggleNavUserDropdown}
                className="nav-link dropdown-toggle arrow-none"
                data-toggle="dropdown"
                to="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                {/*<img
                  src="/assets/images/users/avatar-6.jpg"
                  alt="user"
                  className="rounded-circle"
                />*/}
                {/** Change the screen name to initails on a small and extraSmall screens **/}
                <span className="d-inline-block ml-1 d-sm-none">
                  {fullName.split(" ").map((n) => n[0])}
                  <i
                    className="fas fa-chevron-down"
                    style={{
                      fontSize: "7px",
                      position: "relative",
                      top: "-2px",
                      left: "4px",
                    }}
                  />{" "}
                </span>
                <span className="d-none d-md-block">
                  {this.props.user.firstName + " " + this.props.user.lastName}{" "}
                  <i
                    className="fas fa-chevron-down"
                    style={{
                      fontSize: "7px",
                      position: "relative",
                      top: "-2px",
                      left: "4px",
                    }}
                  />{" "}
                </span>
                {/***********/}
              </WaveLink>
              <div
                className={`dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown`}
              >
                <WaveLink className="dropdown-item" to="/user/profile">
                  <Icon className="fas fa-user-circle text-muted" />{" "}
                  {t("profile")}
                </WaveLink>
                {/*
                <WaveLink className="dropdown-item" to="#">
                  <i className="fas fa-lock text-muted" /> Lock screen
                </WaveLink>*/}
                {conxtdAdmin(this.props) ? (
                  <WaveLink className="dropdown-item" to="/user/settings">
                    {this.props.user.environment !== "live" && (
                      <span
                        className="badge badge-success float-right"
                        style={{ marginTop: "6px" }}
                      >
                        {this.props.user.environment}
                      </span>
                    )}
                    <Icon className="fas fa-cog text-muted" /> {t("settings")}
                  </WaveLink>
                ) : null}

                {(conxtdAdmin(this.props) && enterprise) || (this.props.user.developer === "Y" && (this.props.user.environment !== "live") && enterprise) ? (
                  <div 
                    className="dropdown-item waves-effect"
                    onClick={() => {
                      document.getElementById("inject-events-container").classList.add("show");
                    }}
                  >
                    <Icon className="fas fa-paper-plane text-muted" /> {t("inject_events")}
                  </div>
                ) : null}

                {this.props.user && (this.props.user.developer === "Y") ? (
                  <WaveLink className="dropdown-item" to="/developers">
                    <Icon className="fas fa-code text-muted" />{" "}
                    {t("developers")}
                  </WaveLink>
                ) : null}

                {devMode() && (
                  <div
                    className="dropdown-item waves-effect"
                    onClick={() => {
                      if (mockEnabled()) {
                        sessionStorage.removeItem("mockEnabled");
                      } else {
                        sessionStorage.setItem("mockEnabled", !mockEnabled());
                      }
                      window.location.reload();
                    }}
                  >
                    <Icon
                      className="fas fa-vial text-muted"
                      style={{ marginTop: "-2px", fontSize: "14px" }}
                    />{" "}
                    <span className="font-12">Use Test Data</span>
                    <ToggleButton
                      active={sessionStorage.getItem("mockEnabled") !== null}
                      size="xs"
                      style={{ marginTop: "-2px" }}
                    />
                  </div>
                )}

                <DarkMode />
                <SnowMode />

                {/* <WaveLink className="dropdown-item" to="/settings"> */}
                {/* </WaveLink> */}
                <div className="dropdown-divider" />
                <WaveLink className="dropdown-item" to="/logout">
                  <Icon className="fas fa-sign-out-alt text-muted" />{" "}
                  {t("logout")}
                </WaveLink>
              </div>
            </li>

            {/* <li id="tablet-logo" className="list-inline-item">
              <CONXTDLogo />
            </li> */}
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="list-inline-item">
              <button
                type="button"
                className="button-menu-mobile open-left waves-effect"
                onClick={this.props.toggleNavCollapse}
              >
                <Icon className="fas fa-bars font-16" />
              </button>
              <BackArrow />
              <HomeButtonMob />
              <RefreshButtonMob />
            </li>
          </ul>
        </nav>
      </div>
    );
  }
  renderOld() {
    return (
      <div
        id="NavTop"
        className="navbar navbar-expand-lg navbar-light bg-white pr-4 row"
        style={{
          borderBottom: "1px solid #eee",
          boxShadow: "-2px 2px 14px rgba(0,0,0, 0.02)",
        }}
      >
        <button className="btn" onClick={this.props.toggleNavCollapse}>
          <Icon className="fas fa-bars" />
        </button>
        <UserDropdown />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.app.search,
    user: state.user,
    enterprise: getEnterpriseFromRoute(state),
  };
};
export default withT(connect(mapStateToProps)(NavTop), "nav.user_dropdown");
