import React, { Component } from "react";
import { connect } from "react-redux";

import { DropdownButton, Dropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Icon from "components/Icons/Icon";

// import { AddGroupModal } from "../GroupLayout/";
import AddGroupModal from "../GroupLayout/AddGroupModal";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import Export from "components/Common/EnterpriseManager/Export";
import Import from "components/Common/EnterpriseManager/Import";

import "./GroupOptions.scss";

/**
 * Enterprise Manager main page - right nav (Sites, Keyholders, Groups)
 *
 **/

class GroupOptions extends Component {
  constructor(props) {
    super(props);
    const { enterprise } = this.props;
    this.state = {
      addModalShow: false,
      groupId: enterprise.root_group_id,
      showExport: false,
      showImport: false,
      showKeyholdersExport: false,
      showKeyholdersImport: false,
      showGroupsExport: false,
      showGroupsImport: false,
      site_count: null,
    };
  }

  render() {
    const { enterprise } = this.props;

    // const enterpriseId = enterprise.id;

    let addModalClose = () => this.setState({ addModalShow: false });
    const handleCloseImport = () => {
      this.setState({ showImport: false });
    };
    const handleCloseKeyholdersImport = () => {
      this.setState({ showKeyholdersImport: false });
    };
    const handleCloseGroupsImport = () => {
      this.setState({ showGroupsImport: false });
    };
    const siteCount = this.props.siteCount || null;
    const filter = siteCount.filter(
      (data) => data.enterprise_group_id === this.state.groupId
    );

    let onExportClick = () => {
      this.setState({
        showExport: true,
        site_count: filter[0].site_count.live_sites,
      });
    };

    let onExportReset = () => {
      this.setState({
        showExport: false,
      });
    };

    let onImportClick = () =>
      this.setState({
        showImport: true,
      });
      
    let onKeyholdersExportClick = () => {
      this.setState({
        showKeyholdersExport: true,
      });
    };

    let onKeyholdersExportReset = () => {
      this.setState({
        showKeyholdersExport: false,
      });
    };

    let onKeyholdersImportClick = () =>
      this.setState({
        showKeyholdersImport: true,
      });

    let onGroupsExportClick = () => {
      this.setState({
        showGroupsExport: true,
      });
    };

    let onGroupsExportReset = () => {
      this.setState({
        showGroupsExport: false,
      });
    };

    let onGroupsImportClick = () =>
      this.setState({
        showGroupsImport: true,
      });

    return (
      <div className="d-flex flex-row dropdown-container">

        {/* Sites dropdown */}
        <DropdownButton 
          variant="primary mr-3 mb-2"
          title={<span><Icon className="fas fa-building mr-2" title="Sites" />Sites</span>}
        >
          {/* <Dropdown.Item as="button">
            <Link
              to={{
                pathname: `/enterprise/${enterpriseId}/create-sites`,
              }}
            >
              <span><Icon className="fas fa-plus mr-2" title="Add" />Add</span>
            </Link>
          </Dropdown.Item> */}
          <Dropdown.Item onClick={onExportClick}>
            <span><Icon className="fas fa-file-export mr-2" title="Export" />Export</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={onImportClick}>
            <span><Icon className="fas fa-file-import mr-2" title="Import" />Import</span>
          </Dropdown.Item>
        </DropdownButton>
        {this.state.showExport ? (
          <Export
            groupId={this.state.groupId}
            site_count={this.state.site_count}
            enterpriseName={this.props.enterprise.name}
            reset={onExportReset}
          />
        ) : null}
        <Import
          show={this.state.showImport}
          enterpriseId={this.props.enterprise.id}
          groupId={this.state.groupId}
          onHide={handleCloseImport}
        />

        {/* Keyholders dropdown */}
        <DropdownButton 
          variant="primary mr-3 mb-2"
          title={<span><Icon className="fas fa-address-card fa-lg mr-2" title="Contacts" />Contacts</span>}
        >
          <Dropdown.Item onClick={onKeyholdersExportClick}>
            <span><Icon className="fas fa-file-export mr-2" title="Export" />Export</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={onKeyholdersImportClick}>
            <span><Icon className="fas fa-file-import mr-2" title="Import" />Import</span>
          </Dropdown.Item>
        </DropdownButton>
        {this.state.showKeyholdersExport ? (
          <Export
            path="/EnterpriseKeyholders/export"
            enterpriseId={enterprise.id}
            groupId={this.state.groupId}
            enterpriseName={this.props.enterprise.name}
            exportName="Contact Data"
            reset={onKeyholdersExportReset}
          />
        ) : null}
        <Import
          path="/EnterpriseKeyholders/import"
          show={this.state.showKeyholdersImport}
          enterpriseId={this.props.enterprise.id}
          groupId={this.state.groupId}
          onHide={handleCloseKeyholdersImport}
          importName="Contact"
          importDescription="Contact details"
        />

        {/* Groups dropdown */}
        <DropdownButton 
          variant="primary mr-3 mb-2"
          title={<span><Icon className="fas fa-sitemap mr-2" title="Groups" />Groups</span>}
        >
          <Dropdown.Item 
            onClick={(e) => {
              e.preventDefault();
              this.setState({ addModalShow: true });
            }}
          >
            <span><Icon className="fas fa-plus mr-2" title="Add" />Add</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={onGroupsExportClick}>
            <span><Icon className="fas fa-file-export mr-2" title="Export" />Export</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={onGroupsImportClick}>
            <span><Icon className="fas fa-file-import mr-2" title="Import" />Import</span>
          </Dropdown.Item>
        </DropdownButton>
        <AddGroupModal show={this.state.addModalShow} onHide={addModalClose} />
        {this.state.showGroupsExport ? (
          <Export
            path="/EnterpriseGroups/exportGroupStructure"
            enterpriseId={enterprise.id}
            enterpriseName={this.props.enterprise.name}
            exportName="Groups Data"
            reset={onGroupsExportReset}
          />
        ) : null}
        <Import
          path="/EnterpriseGroups/importGroupStructure"
          show={this.state.showGroupsImport}
          enterpriseId={this.props.enterprise.id}
          onHide={handleCloseGroupsImport}
          importName="Group"
          importDescription="group details"
        />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    siteCount: state.enterpriseManager.counts,
  };
};
export default connect(mapStateToProps)(GroupOptions);
