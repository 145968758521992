import React from "react";
import { connect } from "react-redux";

import { Field, reduxForm } from "redux-form";

import LoadingButton from "components/LoadingButton";

import Alert from "components/Common/Alert";

class UserType extends React.Component {
  state = {
    submitting: null
  };
  handleSubmit = e => {
    e.preventDefault();

    localStorage.setItem("userType", this.userType.value);
    Alert({
      text: "Updated user type",
      icon: "success"
    });
  };
  render() {
    const { submitting } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <label>User Type</label>
          <div className="input-group">
            <Field
              name="userType"
              className="form-control"
              component="select"
              ref={r => (this.userType = r)}>
              <option value="default">Standard</option>
              <option value="fire">Fire</option>
            </Field>
            <LoadingButton
              type="submit"
              className="btn btn-outline-info"
              variant="outline-info"
              loading={submitting}
              disabled={submitting}>
              Save
            </LoadingButton>
          </div>
        </div>
      </form>
    );
  }
}

UserType = reduxForm({
  form: "userType" // a unique identifier for this form
})(UserType);

UserType = connect(state => {
  return {
    user: state.user,
    initialValues: {
      userType: localStorage.getItem("userType") || "default"
    }
  };
})(UserType);

export default UserType;
