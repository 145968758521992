import React from "react";
import { connect } from "react-redux";

import PageLayout from "./PageLayout";

import { loadExceptionReportStart } from "services/redux/actions";

import { getEnterpriseFromRoute } from "services/redux/selectors";

import moment from "services/locale/momentInit.js";

import _ from "lodash";
import { getExceptionReport } from "services/redux/selectors/reports/exception";
import { getReportType } from "services/redux/selectors/reports/router";

import { getRouterParams } from "services/redux/selectors/app/";

/*
PA
- Breadcrumbs/card title
- Set proper colours for PA
- Panic completed and panic failed labels
- Primary colour (not yellow) for week select
- Weekly select - end of month bug

PA Data is from 1st of Jan 2019
*/

class ExceptionReportPage extends React.Component {
  state = {
    // Default to last week
    selectedWeek: new moment().isoWeek() - 1,
    // Default to previous month
    // This is the previous month, Jan == 0
    // Current month - 1 = previous month (4) -1 = (3) .format("MM") = "04"
    selectedMonth: moment()
      .subtract(1, "month")
      .format("MM"),
    selectedYear: moment()
      .subtract(1, "month")
      .format("YYYY"),
    // Select dates by month/week
    selectBy: "month"
  };
  // componentWillMount() {
  //   // Load initial report
  //   this.loadReport();
  // }
  changeWeek = (selectedWeek, selectedYear) => {
    // When state is set, dispatch request for new report
    this.setState({ selectedWeek, selectedYear }, this.loadReport);
  };
  componentDidUpdate() {
    // Only allow loading on update when loading state
    // is inital
    if (this.props.loading === null) {
      this.loadReport();
    }
  }
  componentDidMount() {
    // Only allow loading on mount when loading state is
    // anything other than true
    if (this.props.loading !== true) {
      this.loadReport();
    }
  }
  loadReport = () => {
    let { reportType, group_id } = this.props.router.params;

    const { enterprise } = this.props;

    // The root group was specified - we need to wait on loading
    //  enterprises to get the root group id before we can
    //  begin our request
    if (group_id === "root" || !group_id) {
      group_id = _.get(enterprise, "root_group_id");
      // No enterprises loaded yet
      if (!group_id) return;
    }

    const params = {
      year: this.state.selectedYear,
      enterprise_id: enterprise.id,
      group_id,
      reportType
    };
    if (this.state.selectBy === "month") {
      params.month = this.state.selectedMonth;
    } else {
      params.week = this.state.selectedWeek;
    }

    // Dispatch request for report data
    this.props.dispatch(loadExceptionReportStart(params));
  };
  changeSelectBy = e => {
    const selectBy = e.target.value;
    this.setState({ selectBy: selectBy }, this.loadReport);
  };
  changeMonth = (selectedMonth, selectedYear) => {
    this.setState({ selectedMonth, selectedYear }, this.loadReport);
  };
  render() {
    // const data = _.get(this.props.reports, this.props.match.params.reportType);
    // const reportType = this.props.match.params.reportType;

    const { data, reportType } = this.props;

    return (
      <PageLayout
        data={data}
        changeWeek={this.changeWeek}
        changeMonth={this.changeMonth}
        selectBy={this.state.selectBy}
        changeSelectBy={this.changeSelectBy}
        selectedWeek={this.state.selectedWeek}
        selectedYear={this.state.selectedYear}
        selectedMonth={this.state.selectedMonth}
        reportType={reportType}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const exceptionReport = getExceptionReport(state, props);
  return {
    // reports: state.reports,
    data: exceptionReport,
    reportType: getReportType(state, props),
    enterprise: getEnterpriseFromRoute(state, props),
    loading: exceptionReport.loading,
    router: { params: getRouterParams(state, props) }
  };
};
export default connect(mapStateToProps)(ExceptionReportPage);
