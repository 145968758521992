import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";

import Loading from "components/Loading";
import Icon from "components/Icons/Icon";

import {
  setEmIntegrationTabContent,
  getUptickSitesStart,
  getUptickLinkedSitesStart,
} from "services/redux/actions/enterpriseManager";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import BulkAssociationModal from "./BulkAssociationModal";

import DataTable from "./DataTable";

class UptickSites extends React.Component {
  state = {
    bulkAssociationModalShow: false,
  };

  componentDidMount() {
    const { enterprise } = this.props;

    this.props.dispatch(
      getUptickSitesStart({
        enterprise_id: enterprise.id,
      })
    );

    this.props.dispatch(
      getUptickLinkedSitesStart({
        enterprise_id: enterprise.id,
      })
    );
  }

  bulkAssociationModalClose = () => {
    this.setState({ bulkAssociationModalShow: false })
  };

  render() {
    const { loading } = this.props;

    return (
      <div>
        <div className="row">
          <div className="btn btn-default border-none side-pane-back text-dark">
            <Link
              to="?tab=integrations"
              onClick={() => {
                this.props.dispatch(
                  setEmIntegrationTabContent({
                    integrationTabContent: "integrations"
                  })
                )
              }}
            >
              <span className="back-to-overview"><Icon className="fas fa-chevron-left mr-2" /> Back to integrations</span>
            </Link>
          </div>
        </div>
        <div className="mt-3">
          <div style={{width: "100%"}} className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center" style={{ height: "100%" }}>
              <div className="h6 font-weight-bold" style={{ marginBottom: "0" }}>Site Association</div>
            </div>
            <div className="pr-3">{loading && <Loading center />}</div>
            <div>
              <Button
                onClick={() => {
                  this.setState({
                    bulkAssociationModalShow: true 
                  });
                }}
              ><Icon className="fas fa-list mr-2" />Bulk</Button>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <DataTable />
        </div>
        <BulkAssociationModal 
          show={this.state.bulkAssociationModalShow} 
          onHide={this.bulkAssociationModalClose}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    loading: state.enterpriseManager.uptick.loading,
  };
};
export default connect(mapStateToProps)(UptickSites);