import React from "react";
import Loading from "./Loading.js";

class LoadingButton extends React.Component {
  loading() {
    if (!this.props.loading) return null;

    return <Loading style={{ position: "relative", top: "-1px" }} />;
  }

  disabled() {
    return this.props.loading ? "disabled" : "";
  }

  render() {
    return (
      <button
        disabled={this.disabled()}
        type={this.props.type}
        className={this.props.className}>
        {this.loading()} {this.props.children}
      </button>
    );
  }
}
export default LoadingButton;
