/**
 * 09/21 - open close by area
 */

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import SVGIcon from "components/Icons/SVGIcon";

import { Card } from "react-bootstrap";

class FilterOverviewWidgetFullReport extends React.Component {
  render() {
    const { getEventMetaById, title1, title2, iconId1, iconId2, total, report } = this.props;

    const eventMeta1 = getEventMetaById(Number(iconId1));
    const eventMeta2 = getEventMetaById(Number(iconId2));

    const icon1 = eventMeta1.icon || "";
    const colour1 = eventMeta1.colour || "#000";

    const icon2 = eventMeta2.icon || "";
    const colour2 = eventMeta2.colour || "#000";

    const AlarmIcon1 =
      ((eventMeta1 !== null) && (icon1 !== "")) ? <SVGIcon type={icon1} color="#ffffff" /> : null;

    const AlarmIcon2 =
      ((eventMeta2 !== null) && (icon2 !== "")) ? <SVGIcon type={icon2} color="#ffffff" /> : null;

    const style = {
      display: "inline-block",
      width: "60px",
      height: "60px",
      borderRadius: "50% 50%",
      padding: "10px",
    }

    return (
      <Link
        to={`./${report}`}
        className="waves-effect card visual-data">
        <Card.Body className="text-center text-uppercase">
          <div className="text-muted m-t-5">
            <h6 className="font-weight-normal m-b-5">{title1}</h6>
            <h6 className="font-weight-normal m-t-5">{title2}</h6>
          </div>
          <div className="m-b-20 m-t-20 d-flex justify-content-center">
            <div className="mb-2" style={{...style, backgroundColor: colour1}}>{AlarmIcon1}</div>
            <div className="mb-2" style={{...style, backgroundColor: colour2}}>{AlarmIcon2}</div>
          </div>
          <div className=" h5 my-2 text-uppercase text-center text-dark">{total}</div>
        </Card.Body>
      </Link>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default connect(mapStateToProps)(FilterOverviewWidgetFullReport);
