import { createSelector } from "reselect";

import { getReports } from "../common";

export const getOutstandingReport = createSelector(
  [getReports],
  reports => reports.outstanding.index
);

export const getPairBundles = createSelector(
  [getOutstandingReport],
  report => report.data.pair_bundles
);
