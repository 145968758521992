const initialState = {
  data: [],
  loading: null
};

const enterprisesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_ENTERPRISES_START":
      return {
        ...state,
        loading: true
      };
    // case "LOAD_ENTERPRISES_SUCCESS":
    //   return action.data.enterprises;
    // case "LOGIN_SUCCESS":
    //   // console.log(action);
    //   return action.enterprises || state;
    // return { ...action.user, loading: false };
    case "LOAD_ENTERPRISES_SUCCESS":
      return {
        ...state,
        data: action.data.enterprises,
        loading: false
      };
    case "LOAD_ENTERPRISES_RESTORE":
      return {
        ...state,
        data: action.data.enterprises,
        loading: false
      };
    case "REFRESH_ENTERPRISES_START":
      return {
        ...state,
        loading: true
      };
    case "LOGOUT_REQUEST":
      // Reset to initial state when user logs out
      return initialState;
    case "SET_ENTERPRISE_CALL_LIMITS":
      return {
        ...state,
        data: [
          ...state.data.map(enterprise => {
            if (enterprise.id === action.enterprise_id) {
              return {
                ...enterprise,
                call_limits: action.call_limits,
              }
            } else {
              return enterprise
            }
          })
        ],
      };
    default:
      return state;
  }
};

export default enterprisesReducer;