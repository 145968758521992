import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import get from "lodash/get";
import SVGIcon from "components/Icons/SVGIcon";

import Loading from "components/Loading";

import { default as T } from "services/i18n/Translate";

import COL_DIVISION from "constants/COL_DIVISION";

import { loadOutstandingReportStart } from "services/redux/actions";
import { getPairBundles } from "services/redux/selectors/reports/outstanding/";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getOutstandingReport } from "services/redux/selectors/reports/outstanding/";
import { getRouterLocation } from "services/redux/selectors/app/";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

const TotalOutstanding = function({ pair_bundles, onReportView, ...props }) {
  const getTotalOutstanding = function(pair_bundles) {
    let totals = 0;
    pair_bundles.forEach(bundle => {
      // bundleGroup.event_pairs.forEach(bundle => {
      totals = totals + bundle.total_count;
      // });
    });
    return totals;
  };

  return (
    <div {...props}>
      {onReportView ? (
        <>
          <h5>{getTotalOutstanding(pair_bundles)}</h5>
          <h5 className="text-muted">
            <T>Total</T>
          </h5>
        </>
      ) : (
        <h5 className="outstanding-overview-total">
          {getTotalOutstanding(pair_bundles)}{" "}
          <span className="text-muted">
            <T>Total</T>
          </span>
        </h5>
      )}
    </div>
  );
};

const BundleTotals = function({
  selectedBundles,
  getEventMetaById,
  onReportView
}) {
  return selectedBundles.map((bundle, index, bundles) => {
    const { conxtd_event_id, total_count, title } = bundle;

    // const eventMeta = select(this.props.eventMeta, conxtd_event_id);
    const eventMeta = getEventMetaById(conxtd_event_id);

    const { icon, colour } = eventMeta || {
      icon: "",
      colour: "#000"
    };

    const AlarmIcon =
      eventMeta !== null ? <SVGIcon type={icon} color="#ffffff" /> : null;

    const col = onReportView
      ? get(COL_DIVISION, bundles.length + 1 + "/" + index + 1) ||
        get(COL_DIVISION, bundles.length + 1)
      : "col-4";

    const style = onReportView
      ? {
          backgroundColor: colour,
          display: "inline-block",
          width: "60px",
          height: "60px",
          borderRadius: "50% 50%"
        }
      : {
          backgroundColor: colour,
          display: "inline-block",
          width: "40px",
          height: "40px",
          borderRadius: "50% 50%",
          padding: "8px"
        };

    return (
      <div
        className={`${col} text-center outstanding-overview-widget`}
        key={index}>
        <div id={icon} className={`svg-icon text-white mb-1`} style={style}>
          {AlarmIcon}
        </div>
        <div style={{ color: colour }} className="font-weight-bold">
          <div className="mb-1">{total_count}</div>
          <div
            className={`text-uppercase event-name${
              title.length > 14 ? " long" : ""
            }`}
            // style={{ minHeight: "42px" }}
          >
            {title}
          </div>
        </div>
      </div>
    );
  });
};

class OutstandingOverviewCard extends React.Component {
  // Loads report when enterprises are available so we can load root group
  //  Note: Isn't called anymore!
  loadReport(enterprise) {
    const { loading } = this.props;

    // const enterprise = getEnterpriseById(this.props.enterprises, enterprise_id);

    const group_id = get(enterprise, "root_group_id");

    // No enterprises loaded yet or we are still loading
    if (!group_id || loading === true) return;

    this.props.dispatch(
      loadOutstandingReportStart({
        enterprise_id: enterprise.id,
        group_id,
        interval: null,
        refresh: false
      })
    );
  }
  // Flatten and sort event pairs by total_count, then return first 5
  topOutstanding(pair_bundles, outstandingBundleNumber) {
    // let sortedBundles = { ...pair_bundles };
    let flattenedEvents = pair_bundles;

    // Created flattened version
    // pair_bundles.forEach(bundleGroup => {
    //   bundleGroup.event_pairs.forEach(bundle => {
    //     flattenedEvents.push(bundle);
    //   });
    // });

    let returnEvents = flattenedEvents
      .filter(item => {
        return item.total_count > 0;
      })
      .sort((a, b) => {
        if (a.total_count < b.total_count) return 1;
        if (a.total_count > b.total_count) return -1;
        return 0;
      });

    if (outstandingBundleNumber) {
      return returnEvents.slice(0, outstandingBundleNumber);
    } else {
      return returnEvents.slice(0, 5);
    }
  }
  render() {
    const {
      pair_bundles,
      onReportView,
      getEventMetaById,
      eventMetaLoading,
      outstandingBundleNumber,
    } = this.props;
    let loading = false;

    if (!pair_bundles || eventMetaLoading !== false) {
      loading = true;
    }

    let selectedBundles = !loading && this.topOutstanding(pair_bundles, outstandingBundleNumber);

    if (loading) {
      return <Loading center />;
    }

    return (
      <div className="row outstanding-index-overview-card d-flex align-items-center">
        <TotalOutstanding
          onReportView={onReportView}
          pair_bundles={pair_bundles}
          className={
            (get(COL_DIVISION, selectedBundles.length + 1 + "/" + 0) ||
              get(COL_DIVISION, selectedBundles.length + 1)) + " text-center"
          }
        />
        <BundleTotals
          onReportView={onReportView}
          getEventMetaById={getEventMetaById}
          selectedBundles={selectedBundles}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const outstandingReport = getOutstandingReport(state, props);
  const { pathname } = getRouterLocation(state, props);

  return {
    // reports: state.reports,
    enterprise: getEnterpriseFromRoute(state, props),
    getEventMetaById: createGetEventMetaById(state, props),
    eventMetaLoading: state.eventMeta.loading,
    pair_bundles: getPairBundles(state, props),
    loading: outstandingReport.loading,
    outstanding: outstandingReport,
    // Reports overview page
    onReportView: pathname.indexOf("/reports") !== -1
  };
};
export default withRouter(connect(mapStateToProps)(OutstandingOverviewCard));
