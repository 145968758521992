export const blueprint = {
  "name": "Sites",
  "slug": "Sites",
  "fields": [
    {
      "key": "site_ref",
      "label": "siteRef",
      "type": "string"
    },
    {
      "key": "transmitter_ref",
      "label": "transmitterRef",
      "type": "string"
    },
    {
      "key": "app_id",
      "label": "appId",
      "type": "string"
    },
    {
      "key": "mct_cluster_id",
      "label": "mctClusterId",
      "type": "string"
    },
    {
      "key": "company",
      "label": "company",
      "type": "string"
    },
    {
      "key": "address_1",
      "label": "address1",
      "type": "string"
    },
    {
      "key": "address_2",
      "label": "address2",
      "type": "string"
    },
    {
      "key": "town",
      "label": "town",
      "type": "string"
    },
    {
      "key": "postcode",
      "label": "postcode",
      "type": "string"
    },
    {
      "key": "country",
      "label": "country",
      "type": "string"
    },
    {
      "key": "timezone",
      "label": "timezone",
      "type": "string"
    },
    {
      "key": "arc",
      "label": "arc",
      "type": "string"
    },
    {
      "key": "installer",
      "label": "installer",
      "type": "string"
    },
    {
      "key": "contract_number",
      "label": "contract_number",
      "type": "string"
    },
    {
      "key": "product_description",
      "label": "product_description",
      "type": "string"
    },
    {
      "key": "branch_number",
      "label": "Branch Number",
      "type": "string"
    }
  ]
}