/**
 * 09/21 - open close by area
 */

import React from "react";

import FilterOverviewWidgetGroup from "./FilterOverviewWidgetGroup";

import { withT } from "services/translation/";


class PageLayout extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className={this.props.className}>
        <div className="page-title-box pt-0">
          <div className="row align-items-center">
            <div className="col-md-4 text-center text-sm-left">
              <h4 className="page-title mb-2 mt-0">{t("title")}</h4>
              <div className="text-muted font-14">{t("description")}</div>
            </div>
            <div className="col-md-8 d-none d-sm-block"> </div>
          </div>
          <div className="row align-items-center"><FilterOverviewWidgetGroup /></div>
        </div>
      </div>
    );
  }
}

export default withT(
  (PageLayout),
  "reports.openclosebyarea.index"
);
