import React from "react";
import { connect } from "react-redux";

import ExistingUser from "./ExistingUser";
import NewUser from "./NewUser";

class InviteForm extends React.Component {
  render() {
    const { users } = this.props;

    const userExistsOnSystem = users.actions.search.error.status !== 422;

    return userExistsOnSystem ? <ExistingUser /> : <NewUser />;
  }
}
const mapStateToProps = (state, props) => {
  return {
    users: state.users
  };
};
export default connect(mapStateToProps)(InviteForm);
