/**
 * Camera integrations management list
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Row, Col } from "react-bootstrap";

import { withT } from "services/translation/";

import Integration from "../Integration/";
import Loading from "components/Loading";

import { loadSiteIntegrationsCancel } from "services/redux/actions";

function IntegrationList({ loading, integrations, admin, dispatch, t }) {
  useEffect(() => {
    // Reset integration list on unmount
    return () => {
      dispatch(loadSiteIntegrationsCancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeIntegrations = integrations.filter(
    ({ active_on_site }) => active_on_site
  );

  const activeIntegrationsList = activeIntegrations.map(
    (integration, index) => (
      <div className="row" key={index}>
        <div className="col-12">
          <Integration integration={integration} admin={admin} />
        </div>
      </div>
    )
  );

  const availableIntegrations = integrations.filter(
    ({ active_on_site }) => !active_on_site
  );

  const availableIntegrationsList = availableIntegrations.map(
    (integration, index) => (
      <div className="row" key={index}>
        <div className="col-12">
          <Integration integration={integration} />
        </div>
      </div>
    )
  );

  return (
    <>
      {loading && <Loading center />}

      {integrations.length === 0 && (
        <Row>
          <Col>{t("no_integrations_available")}</Col>
        </Row>
      )}

      {activeIntegrations.length > 0 && (
        <>
          <Row>
            <Col>
              <div className="header-title">{t("active_integrations")}</div>
            </Col>
          </Row>
          <Row>
            <Col>{activeIntegrationsList}</Col>
          </Row>
        </>
      )}

      {availableIntegrations.length > 0 && (
        <>
          <Row>
            <Col>
              <div className="header-title">{t("available_integrations")}</div>
            </Col>
          </Row>
          <Row>
            <Col>{availableIntegrationsList}</Col>
          </Row>
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.sites.current.integrations.loading === null,
    integrations: state.sites.current.integrations.data,
    admin: state.sites.current.integrations.admin
  };
};
export default withT(
  connect(mapStateToProps)(IntegrationList),
  "site_overview.side_pane.manage_integrations.providers.tether"
);
