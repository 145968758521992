import React from "react";

import SVGIcon from "components/Icons/SVGIcon";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const EventCell = ({ event, alarm_text, onClick, focus, underline, overrideColor }) => {
  if (typeof event === "undefined") {
    return null;
  } else {
    const { icon, label } = event;
    const colour = !!overrideColor ? overrideColor : event.colour;
    
    const Icon = icon ? (
      <div
        id={icon}
        className={`svg-icon text-white`}
        style={{
          display: "inline-block",
          width: "18px",
          height: "18px",
          position: "relative",
          top: "-1px",
          marginRight: "6px",
        }}
      >
        <SVGIcon type={icon} color={"#ffffff"} />
      </div>
    ) : null;

    const EventComponent = (
      <div
        style={{
          backgroundColor: colour,
          // cursor: "pointer",
          padding: "0.3rem 0.3rem 0.3rem 0.5rem",
          borderRadius: "16px 0 0 16px",
          height: "32px",
          // margin: "1px 0",
          paddingTop: "6px",
        }}
        onClick={onClick}
        className={`inner text-light ${underline ? "underline" : ""} ${
          focus ? "focus" : ""
        }`}
      >
        {Icon}
        <span style={{ fontWeight: "bold" }}>{label}</span>
      </div>
    );

    const Cell = alarm_text ? (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="tooltip-left" style={{ pointerEvents: "none" }}>
            <div style={{ fontSize: "12px" }} className="py-1">
              {alarm_text}
            </div>
          </Tooltip>
        }
      >
        {EventComponent}
      </OverlayTrigger>
    ) : (
      EventComponent
    );

    return Cell;
  }
};

export { EventCell };