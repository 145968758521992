import React from "react";
import { Button, Modal, Card } from "react-bootstrap";
import Alert from "components/Common/Alert";
import TreeMenu from "react-simple-tree-menu";
// import "../../../../../../node_modules/react-simple-tree-menu/dist/main.css";
import { loadEmSitesListStart } from "services/redux/actions/enterpriseManager";
import conxtdOut from "apis/conxtdOut";
import { connect } from "react-redux";
import "./AddSite.scss";

class AddSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newGroupId: null,
      hasChildrenGroup: null,
    };
  }
  render() {
    const handleChangeAddSite = async (event) => {
      const checkboxSelection = this.props.checkboxSelection;
      const existing_groups = this.props.existing_groups;

      if (this.state.hasChildrenGroup === true) {
        Alert({
          text: "Parent group selection is not allowed.",
          icon: "warning",
        });
      } else {
        try {
          await conxtdOut.post(`/EnterpriseStructure/copySitesToGroup/`, {
            new_group_id: this.state.newGroupId,
            old_group_id: this.props.groupId,
            selected_sites: checkboxSelection,
            existing_groups: existing_groups,
          });
          this.props.dispatch(
            loadEmSitesListStart({
              enterprise_group_id: this.props.groupId,
            })
          );
          Alert({
            text: "Selected sites have been added.",
            icon: "success",
            timerProgressBar: true,
            timer: 1500,
          });
          // wait 10 secs before closing modal
          await new Promise((resolve) => setTimeout(resolve, 1000));
          this.props.onHide();
        } catch (error) {
          let apiError = error.response.data.message;
          let customError = error.response.data.message;

          if (
            apiError.includes("duplicate key value violates unique constraint")
          ) {
            customError = "Cannot add site if it's already in the group.";
          } else {
            return customError;
          }
          Alert({
            text: customError,
            icon: "error",
          });
        }
      }
    };

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        keyboard={false}
        className="modal"
        dialogClassName="add-site-modal"
      >
        <Modal.Header closeButton>
          <div>
            <Card.Title
              id="contained-modal-title-vcenter"
              style={{ marginRight: "100px" }}
            >
              Add selected sites to..{" "}
            </Card.Title>
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                flexGrow: 1,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TreeMenu
                  data={this.props.treeData}
                  onClickItem={({ key, ...props }) => {
                    // Exclude parent Ids
                    const getLastIndex = key.lastIndexOf("/");
                    const newKey = key.substring(getLastIndex + 1);

                    const hasChildrenGroup = props.hasNodes;

                    this.setState({
                      newGroupId: newKey,
                      hasChildrenGroup,
                    });
                  }}
                />
              </div>
            </div>{" "}
          </div>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="success" type="submit" onClick={handleChangeAddSite}>
            Add to
          </Button>

          <Button variant="primary" onClick={this.props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = () => {
  return {
    loadEmSitesListStart,
  };
};
export default connect(mapStateToProps)(AddSite);
