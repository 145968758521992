/**
 * Toggle button used for dark mode
 */

import React from "react";

class ToggleButton extends React.Component {
  render() {
    const { active = false, size = "xs", ...rest } = this.props;

    const activeClassName = active ? "active" : "";

    return (
      <button
        type="button"
        className={`btn btn-${size} btn-toggle mx-2 ${activeClassName}`}
        data-toggle="button"
        aria-pressed={active ? "true" : "false"}
        autoComplete="off"
        {...rest}>
        <div className="handle" />
      </button>
    );
  }
}
export default ToggleButton;
