import React, { useEffect } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Page from "components/Layout/Page";

import SupportPlugin from "../Layout/SupportPlugin";
import LoginPage from "../Auth/LoginPage";

import conxtdOut from "apis/conxtdOut";

import { useSelector } from "react-redux";

import { getReportPath } from "services/redux/selectors/reports/router";

import { setEmSelectedTab } from "services/redux/actions/enterpriseManager";

const PrivateRoute = ({
  component: Component,
  disableBreadcrumbs = false,
  ...inheritedProps
}) => {
  const session = conxtdOut.getSession();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname.search(/enterprise-manager/) !== -1) {
      const searchParams = new URLSearchParams(location.search);
      const tab = searchParams.get("tab");

      if (!tab) history.push(`${location.pathname}?tab=groups`);

      dispatch(setEmSelectedTab({
        selectedTab: tab || "groups",
      }));
    }
  });

  const reportPath = useSelector(state =>
    getReportPath(state, inheritedProps)
  ) || ["default", "default"];

  return (
    <>
      <Route
        {...inheritedProps}
        render={props => {
          // Redirect on /logout route, otherwise we log in only to be logged back out
          if (
            !(session && session.sessionId) &&
            props.location.pathname === "/logout"
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            );
          }

          const className =
            reportPath[0] === "default" ? "" : reportPath[0] + "-report-page";

          return session && session.sessionId ? (
            <Page className={className} disableBreadcrumbs={disableBreadcrumbs}>
              <Component {...props} />
              <SupportPlugin />
            </Page>
          ) : (
            <>
              <LoginPage match={{ params: { env: false } }} />
            </>
          );
        }}
      />
    </>
  );
};

export default PrivateRoute;
