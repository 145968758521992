import React from "react";
import { connect } from "react-redux";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { withT } from "services/translation/";

// import { Row, Col, Card } from "react-bootstrap";
import { Col, Card } from "react-bootstrap";

import ViewGroupTable from "./ViewGroupTable";

class ViewGroup extends React.Component {
  render() {
    // const { enterprise, t } = this.props;

    return (
      <>
        <Card className="p-3">
          <Col>
            <ViewGroupTable />
          </Col>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
  };
};
export default withT(connect(mapStateToProps)(ViewGroup), "view_group");
