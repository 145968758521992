// import XLSX from "xlsx";//!!!
import XLSX from "xlsx-js-style";
import pdf from "services/export/pdf";
import csv from "services/export/csv";

const exportFile = (state = {}, action) => {
  switch (action.type) {
    case "EXPORT_EXCEL":
      let { dataResolver, reportTitle } = action;
      let sheetName = reportTitle;

      // Sheet names can't be longer than 32 chars
      if (sheetName.length > 32) {
        sheetName = sheetName.split(" ")[0];
      }
      // If it's still longer than 32, we'll have to
      // limit it
      if (sheetName.length > 32) {
        sheetName = sheetName.substr(0, 32);
      }

      let excelData = dataResolver("EXCEL");

      const wb = XLSX.utils.book_new();

      wb.SheetNames.push(sheetName);

      const ws = XLSX.utils.aoa_to_sheet(excelData);

      // wb.Props = {
      //   Title: "Test",
      //   Subject: "Report",
      //   Author: "",
      //   CreatedDate: new Date()
      // };

      wb.Sheets[sheetName] = ws;

      const output = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx"
      });

      const blob = new Blob([output], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = reportTitle + ".xlsx";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // XLSX.writeFile(wb, reportTitle + ".xlsx", {
      //   type: "base64",
      //   bookType: "xlsx"
      // });
      return state;
    case "EXPORT_PDF":
      let pdfData = action.dataResolver();

      pdf(pdfData, action.reportTitle);

      return state;
    case "EXPORT_CSV":
      let csvData = action.dataResolver();

      csv.exportCSV(csvData);

      return state;
    default:
      return state;
  }
};

export default exportFile;