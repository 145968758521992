const initialState = {
  data: [],
  bundles: [],
  loadingCustomReport: null,
  externalTaskIdsByAlarmId: {},
  userGeneratedReport: {
    reportType: "",
    data: [],
    expanded: {},
    loadingUserGeneratedReport: null,
    filter: {
      // interval: {
      //   roundingMethod: "1 hour",
      //   timeSegment: null,
      //   userTimezoneUTCOffset: {
      //     hours: 0,
      //     minutes: 0,
      //     sign: 1
      //   }
      // },
      // eventType: {
      //   show: {},
      // },
      // eventCount: {
      //   show: {},
      // },
    },
    DataTable: {
      filtered: [],
    },
  }
};

export default function customReports(state = initialState, action) {
  switch (action.type) {

    case "LOAD_CUSTOM_REPORT_START":
      return {
        ...state,
        loadingCustomReport: true,
      };

    case "LOAD_CUSTOM_REPORT_SUCCESS":
      return {
        ...state,
        data: action.data ? action.data : [],
        loadingCustomReport: false,
        externalTaskIdsByAlarmId: {},
      }

    case "LOAD_CUSTOM_REPORT_FAILED":
      return {
        ...state,
        loadingCustomReport: false,
      };

    case "LOAD_CUSTOM_REPORT_CANCEL":
      return {
        ...state,
        loadingCustomReport: false,
      };

    case "CLEAR_CUSTOM_REPORT":
      return {
        ...state,
        data: [],
        loadingCustomReport: false,
      };

    case "LOAD_CUSTOM_REPORT_BUNDLES_START":
      return {
        ...state,
      };

    case "LOAD_CUSTOM_REPORT_BUNDLES_SUCCESS":
      return {
        ...state,
        bundles: action.data,
      }

    case "LOAD_CUSTOM_REPORT_BUNDLES_FAILED":
      return {
        ...state,
      };

    case "LOAD_CUSTOM_REPORT_BUNDLES_CANCEL":
      return {
        ...state,
      };

    case "ADD_CUSTOM_REPORT_EXTERNAL_TASK_ID":
      return{
        ...state,
        externalTaskIdsByAlarmId: {
          ...state.externalTaskIdsByAlarmId,
          [action.alarmId]: action.taskId,
        }
      }

    case "LOAD_USER_GENERATED_REPORT_START":
      return {
        ...state,
        userGeneratedReport: {
          ...state.userGeneratedReport,
          loadingUserGeneratedReport: true,
        }
      };

    case "LOAD_USER_GENERATED_REPORT_SUCCESS":
      return {
        ...state,
        userGeneratedReport: {
          ...state.userGeneratedReport,
          reportType: action.startAction.params.report,
          data: action.data ? action.data : [],
          loadingUserGeneratedReport: false,
        }
      }

    case "LOAD_USER_GENERATED_REPORT_FAILED":
      return {
        ...state,
        userGeneratedReport: {
          ...state.userGeneratedReport,
          loadingUserGeneratedReport: false,
        }
      };

    case "LOAD_USER_GENERATED_REPORT_CANCEL":
      return {
        ...state,
        userGeneratedReport: {
          ...state.userGeneratedReport,
          loadingUserGeneratedReport: false,
        }
      };

    case "CLEAR_USER_GENERATED_REPORT":
      return {
        ...state,
        userGeneratedReport: {
          ...state.userGeneratedReport,
          data: [],
          reportType: "",
          expanded: {},
          filter: {},
          DataTable: {
            filtered: [],
          },
          loadingUserGeneratedReport: false,
        }
      };

    case "SET_USER_GENERATED_REPORT_EXPAND_SITE":
      const { id, expanded } = action;
      return {
        ...state,
        userGeneratedReport: {
          ...state.userGeneratedReport,
          expanded: {
            ...state.userGeneratedReport.expanded,
            [id]: expanded,
          }
        }
      };

    case "RESET_USER_GENERATED_REPORT_EXPANDED":
      return {
        ...state,
        userGeneratedReport: {
          ...state.userGeneratedReport,
          expanded: {},
        }
      };

    default:
      return state;
  }
}