// import { hot } from "react-hot-loader/root";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ModalProvider from "react-redux-modal-provider";

import { I18nextProvider } from "react-i18next";
import i18n from "services/i18n/i18n";

import App from "./components/App";

import configureStore from "services/redux/init";

import "./polyfills";

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const { store, persistor } = configureStore();

TimeAgo.addDefaultLocale(en);

// Provide redux store to i18n to dispatch translation errors
i18n.initWithStore(store);

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <PersistGate persistor={persistor}>
          <App />
          <ModalProvider />
        </PersistGate>
      </I18nextProvider>
    </Provider>,
    document.querySelector("#wrapper")
  );

  // serviceWorkerRegistration.register();
}

// if (process.env.NODE_ENV !== "production") {
// hot("./components/App", renderApp);
// }

renderApp();
