/**
 * Main site view pane
 */

import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

// import { getRouterParams } from "services/redux/selectors/app/";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute } from "services/redux/selectors/groups";

import { withT } from "services/translation/";

import { Row, Nav, Tab, Col, Button } from "react-bootstrap";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import Icon from "components/Icons/Icon";

import { changeGroupTimeframe } from "services/redux/actions/";

import { SelectTimeframe } from "components/Common/Timeframe/SelectTimeframe";

import { Bundles, Details, Keyholders, Comments } from "./Tabs/";
import ManageNotifications from "./Notifications/";
import ManageIntegrations from "./Integrations/";
import LatestAlarm from "./LatestAlarm";
import OpenClose from "./OpenClose/";
import VoiceCalls from "./VoiceCalls/";

import CameraList from "components/Common/Cameras/CameraList/";
import Modes from "./Modes/";

import { Tooltip } from "components/Common/Tooltip/";

import _ from "lodash";

// import socketIOClient from "socket.io-client";

const TabOptions = [
  {
    key: "bundles",
    col: 3,
    // col: 3,
    Component: Bundles,
  },
  // {
  //   key: "apps",
  //   col: 3,
  //   Component: Apps
  // },
  {
    key: "details",
    col: 3,
    // col: 3,
    Component: Details,
  },
  {
    key: "keyholders",
    col: 3,
    // col: 3,
    Component: Keyholders,
  },
  {
    key: "comments",
    col: 3,
    // col: 3,
    Component: Comments,
  },
];

// const key = 'q8z6ZDD^?W,k1DyG/$XJFR0X-iZ)hq~zMili*~zUS55t%mo4{dDfVs5Zpx|aU?B';

class SiteOverview extends React.Component {
  constructor() {
    super();
    let view = "overview";

    // If on initialisation, connectTether session item exists,
    // a tether integration has just been set up so we want to show
    // that it's set up
    if (sessionStorage.getItem("tetherConnect") !== null) {
      view = "integrations";
    }

    this.state = {
      key: "bundles",
      view,
      initialLoad: false,
      socket: null,
      prevData: null,
      prevDataRoom: null
    };
  }
  onSelectBundle = (bundle_id) => {
    const { changePage, onSelectBundle } = this.props;
    // Change page on bundle select
    if (bundle_id) {
      changePage(bundle_id ? 1 : 0);
    }
    onSelectBundle(bundle_id);
  };

  componentDidMount() {
    //I have inclued: !this.state.initialLoad in the state as i was having problems with the connection initiating twice
  //  if (!this.state.initialLoad) {
  //    //the socket server url to connect to
  //    const url = "wss://socket-dev.conxtd.com";

  //    //the socket io function which sets up the connection
  //    const socket = socketIOClient(url, {
  //      auth: {
  //        token: key
  //      }
  //    });

  //    //connect event fired to the socket backend
  //    socket.on('connect', () => {
  //      console.log('Backend connect');
  //      // Connected, let's sign-up for to receive messages for this room
  //      socket.emit('create', this.props.router.params.group_id);
  //    });

  //    //This handles any connection errors, nothing there for now
  //    socket.on('connect_error', err => {
  //      console.log(err.message)
  //    });

  //    //This sets this socket instance to the state in order for it to be used in the unmount and render
  //    this.setState({
  //      socket: socket
  //    })
  //  }

  //  //Turns on initial load to stop multiple socket connections
  //  this.setState({ initialLoad: true })
 }

 //On component unmount socket will disconnect
 componentWillUnmount() {
  //  console.log('Unmount');
  //  //On mount, unmount was getting called an throwing error so I included the logic below
  //  if (this.state.socket !== null) {
  //    console.log('Socket Disconnect');
  //    this.state.socket.disconnect();
  //  }
 }

 render() {
   const { t, site, changePage, bundle_id, dispatch, enterprise, group, userAccess, user } = this.props;

  //  //Was getting an error which caused compile failures
  //  if (this.state.socket !== null) {
  //    //Socket instance is listening for event "GeneralChannel" from the socket server. Data is the data from the event
  //    this.state.socket.on("GeneralChannel", (data) => {
  //      //Was getting more errors of multiple updates to the state, unsure why, so i had to add the logic in below
  //      if (data.id !== this.state.prevData) {
  //        this.setState({ prevData: data.id });
  //        console.log(data);
  //      }
  //    });

  //    this.state.socket.on("RoomTest", (newEvent) => {
  //      if (newEvent != this.state.prevDataRoom) {
  //       this.setState({ prevDataRoom: newEvent});
  //       console.log(newEvent);
  //      }
  //    });
  //  }

    const { view } = this.state;

    const { loading } = site;

    if (loading === null) return <Loading className="py-4" center />;

    // No alarm bundles means initial load
    if (loading === true && site.alarmBundles.length === 0) {
      return <Loading className="py-4" center />;
    }

    if (!site) return null;

    if (view === "notifications") {
      return (
        <ManageNotifications
          site={site}
          onBackToOverview={() => {
            // Return to overview view
            this.setState({
              view: "overview",
            });
          }}
        />
      );
    } else if (view === "integrations") {
      return (
        <ManageIntegrations
          site={site}
          onBackToOverview={() => {
            // Return to overview view
            this.setState({
              view: "overview",
            });
          }}
        />
      );
    } else if (view === "modes") {
      return (
        <Modes
          site={site}
          onBackToOverview={() => {
            this.setState({
              view: "overview",
            });
          }}
        />
      );
    } else if (view === "openclose") {
      return (
        <OpenClose
          site={site}
          onBackToOverview={() => {
            // Return to overview view
            this.setState({
              view: "overview",
            });
          }}
          t={t}
        />
      )
    } else if (view === "phone") {
      return (
        <VoiceCalls
          site={site}
          onBackToOverview={() => {
            this.setState({
              view: "overview",
            });
          }}
        />
      );
    }

    const Tabs = (
      <Tab.Container
        activeKey={this.state.key}
        onSelect={(key) => this.setState({ key })}
      >
        <Nav variant="pills" className="row">
          {TabOptions.map(({ key, col }, index) => (
            <Nav.Item className={"col-" + col} key={index}>
              <Nav.Link eventKey={key} className="text-center">
                {t("tabs." + key)}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Row>
          <Col sm={12}>
            <Tab.Content>
              {TabOptions.map(({ key, Component }, index) => {
                const active = this.state.key === key;
                return active ? (
                  <Tab.Pane key={index} eventKey={key} className="pt-3">
                    <ErrorBoundary message="Error: couldn't load site data">
                      <Component
                        bundle_id={bundle_id}
                        site={site}
                        changePage={changePage}
                        onSelectBundle={this.onSelectBundle}
                        viewAs="cards"
                      />
                    </ErrorBoundary>
                  </Tab.Pane>
                ) : null;
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );

    let showVoiceCallsLink = false;
    if ((enterprise.id !== -1) && (enterprise.voice_calls) && (userAccess.loading === false) && userAccess && group) {
      if ((group.id === "root") || (group.id === enterprise.root_group_id)) {
        if (_.find(userAccess.enterprise.features, ['id', 11])) { // id=11 for Voice Calls
          showVoiceCallsLink = true;
        }
      } else {
        const groupAccess = _.find(userAccess.groups, ['id', group.id]);
        if (groupAccess && groupAccess.features && _.find(groupAccess.features, ['id', 11])) { // id=11 for Voice Calls
          showVoiceCallsLink = true;
        }
      }
    }

    return (
      <>
        <div className="row px-2">
          <div className="col-md-12 mt-2">
            <div className="float-left">
              <Tooltip
                description={t("manage_notifications.tooltip_description")}
                placement="bottom"
              >
                <Button
                  size="sm"
                  onClick={() => {
                    // Set view to notifications
                    this.setState({
                      view: "notifications",
                    });
                  }}
                >
                  <Icon className="fas fa-bell" />
                </Button>
              </Tooltip>
              <Tooltip
                description={t("manage_integrations.tooltip_description")}
                placement="bottom"
              >
                <Button
                  className="ml-2"
                  size="sm"
                  onClick={() => {
                    // Set view to integrations
                    this.setState({
                      view: "integrations",
                    });
                  }}
                >
                  <Icon className="fas fa-video" />
                </Button>
              </Tooltip>
              <Tooltip
                description={t("mode_view.tooltip_description")}
                placement="bottom"
              >
                <Button
                  className="ml-2"
                  size="sm"
                  onClick={() => {
                    // Set view to modes
                    this.setState({
                      view: "modes"
                    })
                  }}
                >
                  <Icon className="fas fa-wrench" />
                </Button>
              </Tooltip>
              {showVoiceCallsLink &&
                <Tooltip
                  description={t("voice_calls.tooltip_description")}
                  placement="bottom"
                >
                  <Button
                    className="ml-2"
                    size="sm"
                    onClick={() => {
                      // Set view to phone
                      this.setState({
                        view: "phone"
                      })
                    }}
                  >
                    <Icon className="fas fa-phone" />
                  </Button>
                </Tooltip>
              }
              {(user.environment === "india") &&
                <Tooltip
                  description={t("alarm_manager.tooltip_description")}
                  placement="bottom"
                >
                  <Button
                    className="ml-2"
                    size="sm"
                    onClick={() => {
                      window.open(
                        'https://www.zipalerts.io/'+String(site.site_ref || site.wwoId),
                        '_blank'
                      );
                    }}
                  >
                    <Icon className="fas fa-cogs" />
                  </Button>
                </Tooltip>
              }
              {(user.environment === "india") &&
                <Tooltip
                  description={t("work_order.tooltip_description")}
                  placement="bottom"
                >
                  <Button
                    className="ml-2"
                    size="sm"
                    onClick={() => {
                      window.open(
                        'https://lmsonl.elocks.in/'+String(site.site_ref || site.wwoId),
                        '_blank'
                      );
                    }}
                  >
                    <Icon className="fas fa-key" />
                  </Button>
                </Tooltip>
              }
            </div>
            <SelectTimeframe
              className="float-right"
              timeframe={site.timeframe}
              changeTimeframe={(timeframe) => {
                dispatch(changeGroupTimeframe({ timeframe }));
              }}
            />
          </div>
        </div>
        <div className="row px-2">
          <LatestAlarm site={site} viewOpenClose={() => {
            this.setState({
              view: 'openclose'
            });
          }} />
        </div>
        {site.integrations.data.length > 0 && (
          <div className="row px-2 mb-2">
            <CameraList integrations={site.integrations.data} />
          </div>
        )}
        <div className="row px-2">
          <div className="col-md-12">{Tabs}</div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    userAccess: state.user.access,
    user: state.user,
    // router: {
    //   params: getRouterParams(state, props),
    // },
    group: getGroupFromRoute(state, props) || {
      id: "root",
    },
  };
};
export default withT(
  connect(mapStateToProps)(SiteOverview),
  "site_overview.side_pane"
);
