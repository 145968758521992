import _ from "lodash";
import produce from "immer";
import merge from "deepmerge";

import REPORTS_CONFIG from "constants/Reports/config/";
import COLOURS from "constants/COLOURS";

const initialState = {
  index: {
    nextRefresh: null,
    lastRefresh: null,
    loading: null,
    data: {
      totals: {
        store_types: [],
        groups: [],
      }
    },
    DataTable: {
      filtered: [],
    },
    filter: {
      type: "groups", // default
      store_types: {
        show: {},
      },
      groups: {
        show: {},
      },
    },
    config: merge(
      REPORTS_CONFIG["default"]["default"],
      REPORTS_CONFIG["failedtoclose"]["index"] || {}
    ),
    expanded: {},
  },
};

const failedtoclose = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_FAILED_TO_CLOSE_REPORT_START":
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
        }
      };

    case "LOAD_FAILED_TO_CLOSE_REPORT_SUCCESS":
      return {
        ...state,
        index: {
          ...state.index,
          data: {
            sites: action.index.data.sites,
            totals: {
              ...action.index.data.totals,
              store_types: Object.entries(action.index.data.totals.store_types).map(([ name, value ], index) => {
                let colorIndex = index;
                while (colorIndex >= COLOURS.length) {
                  colorIndex = colorIndex - COLOURS.length;
                }
                return {
                  name,
                  value,
                  color: COLOURS[colorIndex],
                };
              }),
              groups: Object.entries(action.index.data.totals.groups).map(([ name, value ], index) => {
                let colorIndex = index;
                while (colorIndex >= COLOURS.length) {
                  colorIndex = colorIndex - COLOURS.length;
                }
                return {
                  name,
                  value,
                  color: COLOURS[colorIndex],
                };
              }),
              type: action.index.data.type === "store_type" ? "store_types" : "groups",
            },
          },
          filter: {
            ...state.index.filter,
            type: action.index.data.type === "store_type" ? "store_types" : "groups",
          },
          loading: false,
          lastRefresh: action.lastRefresh,
          nextRefresh: action.nextRefresh,
        },
      };

    case "LOAD_FAILED_TO_CLOSE_REPORT_CANCEL":
      return {
        ...state,
        index: {
          ...state.index,
          expanded: {},
          filter: {
            ...initialState.index.filter
          },
          loading: false,
        }
      };

    case "SET_FAILED_TO_CLOSE_REPORT_EXPAND_SITE":
      const { expanded } = action;

      return {
        ...state,
        index: {
          ...state.index,
          expanded: {
            ...state.index.expanded,
            [action.sudo_site_id]: expanded,
          }
        }
      };

    case "RESET_FAILED_TO_CLOSE_REPORT_EXPANDED":
      return {
        ...state,
        index: {
          ...state.index,
          expanded: {}
        }
      };

    case "CHANGE_FAILED_TO_CLOSE_REPORT_FILTER":
      return produce(state, (draftState) => {
        const filterType = state.index.filter.type;

        const show = state.index.filter[filterType].show;
        const visible = _.get(show, action.show) || null;

        // get visible filters
        const visibleFilters = Object.keys(show).filter((f) => show[f]).length;

        // reset when all filters are true
        if (visibleFilters === 4 && !visible) {
          draftState.index.filter[filterType].show = {};
          return;
        }

        // toggle visibility
        if (visible === true) {
          delete draftState.index.filter[filterType].show[action.show];
        } else {
          draftState.index.filter[filterType].show[action.show] = true;
        }
      });

    default:
      return state;
  }
};

export default failedtoclose;