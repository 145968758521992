import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { withT } from "services/translation/";

import { Row, Col, Card, Button, Alert } from "react-bootstrap";

import Navigation from "./Navigation/Navigation";
import Overview from "./GroupLayout/Overview";
import Billing from "./Billing";
import UserActions from "./UserActions/UserActions";
import Notifications from "./Notifications";
import Scheduler from "./Scheduler";
import Settings from "./Settings/Settings";
import Integrations from "./Integrations/Integrations";
import BrivoSites from "./Integrations/Brivo/BrivoSites";
import UptickSites from "./Integrations/Uptick/UptickSites";
import PriorityEventsManagement from "./PriorityEventsManagement/PriorityEventsManagement";
import ConfigureEventWorkflow from "./PriorityEventsManagement/ConfigureEventWorkflow";
import PriorityEventsMessagePage from "./PriorityEventsManagement/PriorityEventsMessagePage";
import { getGroupFromRoute } from "services/redux/selectors/groups";
import { loadUserManagementAccessStart } from "services/redux/actions/";
import { getCountForMainPageStart } from "services/redux/actions/enterpriseManager";

import Icon from "components/Icons/Icon";

import _ from "lodash";

/**
 * Enterprise Manager container
 *
 **/
class EnterpriseManager extends React.Component {

  componentDidMount() {
    window.caching.flush();

    const { enterprise, group } = this.props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    this.props.dispatch(
      loadUserManagementAccessStart({
        enterprise_id: enterprise.id,
        group_id,
      })
    );
    this.props.dispatch(
      getCountForMainPageStart({ enterpriseId: enterprise.id })
    );
  }

  render() {
    const { userRole, enterprise, group, selectedTab, integrationTabContent, priorityEventsManagementTabContent, t } = this.props;

    const content = enterprise ? <div /> : <Loading />;

    if (userRole.loading === true && userRole.loading === true) {
      return (
        <div>
          <Loading center />
        </div>
      );
    } else if ( 
      userRole.loading === false &&
      userRole.enterprise.role_name === "Admin"
    ) {

      const priorityEventsEnterprise = (enterprise.in_alarm && enterprise.in_alarm !== "N");

      let priorityEventsUser = false;
      if ((!group) || (group.id === enterprise.root_group_id)) {
        if (_.find(userRole.enterprise.features, ['id', 10])) { // id=10 for Priority Events
          priorityEventsUser = true;
        }
      } else {
        const groupAccess = _.find(userRole.groups, ['id', group.id]);
        if (groupAccess && groupAccess.features && _.find(groupAccess.features, ['id', 10])) { // id=10 for Priority Events
          priorityEventsUser = true;
        }
      }

      let tabContent = <Overview />; 
      if (selectedTab === "groups") { tabContent = <Overview /> }
      if (selectedTab === "billing") { tabContent = <Billing /> }
      if (selectedTab === "user-actions") { tabContent = <UserActions /> }
      if (selectedTab === "notifications") { tabContent = <Notifications /> }
      if (selectedTab === "scheduler") { tabContent = <Scheduler /> }
      if (selectedTab === "settings") { tabContent = <Settings /> }
      if (selectedTab === "integrations") {
        if (integrationTabContent === "brivo-sites") {
          tabContent = <BrivoSites />
        } else if (integrationTabContent === "uptick-sites") {
          tabContent = <UptickSites />
        } else {
          tabContent = <Integrations />
        }
      }
      if (selectedTab === "priority-events-management") {
        if (priorityEventsEnterprise) {
          if (priorityEventsUser) {
            if (priorityEventsManagementTabContent === "configure-event") {
              tabContent = <ConfigureEventWorkflow />
            } else {
              tabContent = <PriorityEventsManagement />
            }
          } else {
            tabContent = <PriorityEventsMessagePage messageType="no-user-access" />
          }
        } else {
          tabContent = <PriorityEventsMessagePage messageType="no-enterprise-access" />
        }
      }

      return (
        <div>
          <h4 className="mt-0 mb-2 header-title">{t("header_title")}</h4>{" "}
          <Navigation />
          <Card className="p-3">
            <Row>
              <Col>
                <Alert className="d-block d-md-none" variant="warning">
                  <Icon className="fas fa-exclamation-circle" /> Enterprise
                  manager works best on desktop browser, if on mobile device try
                  rotating into horizontal mode.{" "}
                </Alert>
                {/* <div className="mt-0 mb-2 float-right">
                  <GroupOptions />
                </div> */}
              </Col>
            </Row>
            {tabContent}
            {content}
          </Card>
        </div>
      );
    } 
    else {
      return (
        <div>
          <Card
            style={{
              padding: "20px",
              margin: "auto",
              marginRight: "32%",
            }}
          >
            <Card.Body>
              <Card.Title>
                Access Denied <Icon className="fas fa-user-lock	" />
              </Card.Title>
              <Card.Text>
                Enterprise Manager is for Admins at Enterprise level. Please
                contact your installer / account admin to make any changes.
              </Card.Text>
              <Button
                style={{ marginTop: "20px" }}
                variant="primary"
                onClick={this.props.history.goBack}
              >
                Go Back
              </Button>
            </Card.Body>
          </Card>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    userRole: state.user.access,
    group: getGroupFromRoute(state, props),
    // selectedTab: state.enterpriseManager.selectedTab || "Groups",
    selectedTab: state.enterpriseManagerPersist.selectedTab || "Groups",
    integrationTabContent: state.enterpriseManager.integrationTabContent || "integrations",
    priorityEventsManagementTabContent: state.enterpriseManager.priorityEventsManagement.tabContent || "pe-management",
  };
};
export default withT(
  connect(mapStateToProps)(EnterpriseManager),
  "enterprise_manager"
);
