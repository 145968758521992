// Different IDs for staging vs live
const REPORT_IDS = {
  live: {
    // Live db
    // The key here (1) refers to the id of the report
    // in the database and maps the report type
    1: "scheduled.open",
    2: "scheduled.close",
    5: "scheduled.fire",
    3: "scheduled.overnight",
    8: "scheduled.suspicious",
    4: "outstanding.index",
    7: "exception.panic",
    6: "exception.fire",
    10: "openclosebyarea.index",
    21: "scheduled.peoplecount",
    12: "scheduled.peoplecounthourly",
  },
  staging: {
    // Staging db
    1: "scheduled.open",
    2: "scheduled.close",
    6: "scheduled.fire",
    4: "scheduled.overnight",
    13: "scheduled.suspicious",
    5: "outstanding.index",
    9: "exception.panic",
    10: "exception.fire",
    14: "scheduled.panic",
    18: "openclosebyarea.index",
    21: "scheduled.peoplecount",
    17: "scheduled.peoplecounthourly",
  },
  mars: {
    // Mars (same as staging)
    1: "scheduled.open",
    2: "scheduled.close",
    6: "scheduled.fire",
    16: "scheduled.panic",
    4: "scheduled.overnight",
    15: "scheduled.suspicious",
    5: "outstanding.index",
    9: "exception.panic",
    10: "exception.fire",
    14: "scheduled.panic",
    20: "openclosebyarea.index",
    21: "scheduled.peoplecount",
    22: "scheduled.peoplecounthourly",
    23: "failedtoclose.index",
  },
  dockerbackend: {
    // same as mars
    1: "scheduled.open",
    2: "scheduled.close",
    6: "scheduled.fire",
    16: "scheduled.panic",
    4: "scheduled.overnight",
    15: "scheduled.suspicious",
    5: "outstanding.index",
    9: "exception.panic",
    10: "exception.fire",
    14: "scheduled.panic",
    20: "openclosebyarea.index",
    21: "scheduled.peoplecount",
    22: "scheduled.peoplecounthourly",
    23: "failedtoclose.index",
  },
  dockerbackend: {
    // same as mars
    1: "scheduled.open",
    2: "scheduled.close",
    6: "scheduled.fire",
    16: "scheduled.panic",
    4: "scheduled.overnight",
    15: "scheduled.suspicious",
    5: "outstanding.index",
    9: "exception.panic",
    10: "exception.fire",
    14: "scheduled.panic",
    20: "openclosebyarea.index",
    21: "scheduled.peoplecount",
    22: "scheduled.peoplecounthourly",
  },
  staging2: {
    // Staging Cloud (same as Staging)
    1: "scheduled.open",
    2: "scheduled.close",
    6: "scheduled.fire",
    4: "scheduled.overnight",
    15: "scheduled.suspicious",
    5: "outstanding.index",
    9: "exception.panic",
    10: "exception.fire",
  },
  "live-test": {
    // Live Test (same as live)
    1: "scheduled.open",
    2: "scheduled.close",
    5: "scheduled.fire",
    3: "scheduled.overnight",
    8: "scheduled.suspicious",
    4: "outstanding.index",
    7: "exception.panic",
    6: "exception.fire",
  },
  india: {
    // India (same as mars, staging)
    1: "scheduled.open",
    2: "scheduled.close",
    6: "scheduled.fire",
    16: "scheduled.panic",
    4: "scheduled.overnight",
    15: "scheduled.suspicious",
    5: "outstanding.index",
    9: "exception.panic",
    10: "exception.fire",
    21: "scheduled.peoplecount",
  },
};

export default REPORT_IDS;