// a selection of colors that can be used for things like chart colors etc.
const COLOURS = [
  "#6A9A5B",
  "#5899A9",
  "#494B89",
  "#B98849",
  "#9B3333",
  "#9747A9",
  "#59A448",
  "#5B6DCB",
  "#BBA464",
  "#DB5B39",
  "#8548A7",
  "#A7AA46",
  "#569789",
  "#B8B475",
  "#485684",
  "#949667",
  "#B8564B",
  "#86458A",
  "#A6B599",
  "#98B474",
  "#B58884",
  "#4A5768",
  "#A9B84A",
  "#B4485B",
  "#35395A",
  "#95499B",
  "#5799A8",
  "#556766",
  "#446784",
  "#6B8977",
  "#5A8B55",
  "#764AB7",
  "#47A5A9",
  "#85B459",
  "#796888",
  "#AAA464",
  "#965676",
  "#456A48",
  "#674A98",
  "#74BA97",
  "#B5AA66",
  "#4A79B5",
  "#7A688A",
  "#64A57A",
  "#494AA9",
  "#6AA46B",
  "#9959A5",
  "#679485",
  "#879B58",
  "#7B47A6",
  "#9469B7",
  "#A85984",
  "#AA7685",
  "#B4BAA6",
  "#94495B",
  "#7A7475",
  "#C4353A",
];

export default COLOURS;