//alarm-response
import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";

import TrGroupComponent from "./TrGroupComponent";

import SiteView from "components/Site/SiteView/";

import SOPSidePane from "./SOPView/SOPSidePane";
import SOPView from "./SOPView/SOPView";

import FilterSelect from "./FilterSelect";

import { Tooltip } from "components/Common/Tooltip/";

import { loadSitesBackgroundStart } from "services/redux/actions/sites";

import { getSiteById, getSites } from "services/redux/selectors";

import "./DataTable.scss";

import isEmpty from "lodash/isEmpty";

import moment from "services/locale/momentInit.js";

import { withT } from "services/translation/";

import { exact } from "services/filters/";

import {
  OtherEvent,
  SiteStatus,
  EventCell,
  InnerColourCell,
  RightColourCell,
  AssignedUser,
  SiteGroup,
} from "./Cells/";
import SiteNameCell from "components/Common/DataTable/SiteNameCell/";


import {
  changeGroupTimeframe,
  loadSiteViewLiveRefreshCancel,
  loadSiteKeyholdersStart,
} from "services/redux/actions/";
import {
  setAlarmsExpandSite,
  setAlarmsMuteOnCloseSlider,
} from "services/redux/actions/alarmResponse";
import { 
  loadSOPActionMapStart,
  clearSOPActionMap,
} from "services/redux/actions/sop";

import { getInAlarmSites } from "services/redux/selectors";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { 
  getGroupFromRoute, 
  getGroups,
  getGroupById,
  getCustomRef,
} from "services/redux/selectors/groups";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import {
  getInAlarmExpanded,
  getExpanded,
  getExpandedRowsCount,
} from "services/redux/selectors/alarmresponse";

// import TimeAgo from 'javascript-time-ago';
// import en from 'javascript-time-ago/locale/en';

import ReactTimeAgo from 'react-time-ago';

// TimeAgo.addDefaultLocale(en);
// TimeAgo.addLocale(en-GB);

import COLOUR_CODES from "constants/COLOUR_CODES.json";

/**
 * Alarm Response Data Table
 *
 **/
class DataTable extends React.Component {

  state = {
    selectedSite: null,
    bundle_id: 2,
    selectedSOPRow: null,
  };

  getSudoStateItemsCompareValue(sudo_stateItemA, sudo_stateItemB) {
    // helper function to be used within a sort function
    // compares two sudo_state items and returns either -1, 1 or 0

    // order first by whether it's new, assigned or completed,
    // with new at the top and completed at the bottom,
    // then by whether it's an alarm or a restore,
    // then by position (the position field gives priority; lower position equals higher priority),
    // then by recvd with the latest one displayed.

    if (!sudo_stateItemA.sop_action_assigned_user && !sudo_stateItemA.actioned_complete && sudo_stateItemB.sop_action_assigned_user) {
      // A is new (unassigned and uncompleted); B is assigned
      return -1; // B at bottom

    } else if (!sudo_stateItemA.sop_action_assigned_user && !sudo_stateItemA.actioned_complete && sudo_stateItemB.actioned_complete) {
      // A is new (unassigned and uncompleted); B is completed
      return -1; // B at bottom

    } else if (sudo_stateItemA.sop_action_assigned_user && !sudo_stateItemB.sop_action_assigned_user && !sudo_stateItemB.actioned_complete) {
      // A is assigned; B is new (unassigned and uncompleted)
      return 1; // B at top

    } else if (sudo_stateItemA.sop_action_assigned_user && sudo_stateItemB.actioned_complete) {
      // A is assigned; B is completed
      return -1; // B at bottom

    } else if (sudo_stateItemA.actioned_complete && !sudo_stateItemB.sop_action_assigned_user && !sudo_stateItemB.actioned_complete) {
      // A is completed; B is new (unassigned and uncompleted)
      return 1; // B at top

    } else if (sudo_stateItemA.actioned_complete && sudo_stateItemB.sop_action_assigned_user) {
      // A is completed; B is assigned
      return 1; // B at top

    } else {
      // the new/assigned/completed statuses of A and B are the same, so sort on the remaining criteria
      if ((sudo_stateItemA.state === 'A') && ((sudo_stateItemB.state === 'R') || (!sudo_stateItemB.state))) {
        return -1;
      } else if (((sudo_stateItemA.state === 'R') || (!sudo_stateItemA.state)) && (sudo_stateItemB.state === 'A')) {
        return 1;
      } else {
        if (sudo_stateItemA.position && !sudo_stateItemB.position) {
          return -1;
        } else if (!sudo_stateItemA.position && sudo_stateItemB.position) {
          return 1;
        } else if (sudo_stateItemA.position !== sudo_stateItemB.position) {
          return sudo_stateItemA.position - sudo_stateItemB.position;
        } else {
          if (sudo_stateItemA.recvd > sudo_stateItemB.recvd) {
            return -1;
          } else if (sudo_stateItemA.recvd < sudo_stateItemB.recvd) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    }
  }

  onSelectSite = (site_id, sudo_state) => {
    // site_id is the new site that's just been selected to show in the site view (or to replace any other site currently showing in the site view)

    const { selectedSite } = this.state; // any site that might have previously been selected and is currently showing in the site view
    const { muted, muteClick } = this.props;

    // The below test is different to how it's done in other onSelectSite functions within the codebase
    // because of the way the selectedSite is saved in the state here, i.e. using the spread operator and adding the mct_alarm_log_id
    // - have to test whether the ids match rather than just test the object references are the same like in other places in the code
    if (site_id !== null && ((selectedSite === null) || (site_id !== selectedSite.site_id))) {
      // Either there is no site currently showing in the site view,
      // or there is a site currently showing, but the new one that has just been clicked on is different

      document.body.classList.add("sidepane-active");
      document.body.classList.remove("sop-sidepane-active");

      const recvd = sudo_state && new moment(sudo_state[0].recvd);

      // Timeframe is from 6 hours before the time of the event until now
      let timeframe = new moment().diff(recvd, "hours") + 6;

      // Limit timeframe to maximum of 4 weeks
      if (timeframe > 672) timeframe = 672;

      this.props.dispatch(
        changeGroupTimeframe({
          timeframe
        })
      );

      this.setState({
        selectedSite: { 
          site_id: site_id,
          mct_alarm_log_id: sudo_state && sudo_state[0].mct_alarm_log_id,
        },
        bundle_id: 2
      });

      const currentMuteStatus = muted;
      this.props.dispatch(
        setAlarmsMuteOnCloseSlider({
          muteOnCloseSlider: currentMuteStatus,
        })
      );
  
      if (currentMuteStatus === false) {
        muteClick(); // muteClick toggles the muted value (amongst other things), so in this case it will mute the sounds
      }

    } else {
      // Either the user has clicked to close the side pane (onHide has been called and 'site_id' is null),
      // or the user has clicked the same site in the data table a second consecutive time.
      // In both cases we need to close the side pane

      document.body.classList.remove("sidepane-active");

      this.props.dispatch(
        loadSiteViewLiveRefreshCancel()
      );

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.setState({
          selectedSite: null
        });
      }, 600);
    }
  };

  onClickViewSOP = (row, sudo_state) => {
    const { enterprise, group, muted, muteClick } = this.props;
    const group_id = (group && group.id) || enterprise.root_group_id;

    this.setState({
      selectedSOPRow: { 
        ...row,
      },
    });

    document.body.classList.add("sop-sidepane-active");
    document.body.classList.remove("sidepane-active");

    this.props.dispatch(
      loadSOPActionMapStart({
        enterprise_id: this.props.enterprise.id,
        group_id: group_id,
        sudo_state_id: sudo_state[0].id,
      })
    );

    this.props.dispatch(
      loadSiteKeyholdersStart({
        id: row.enterprise_site_id,
      })
    );

    const currentMuteStatus = muted;
    this.props.dispatch(
      setAlarmsMuteOnCloseSlider({
        muteOnCloseSlider: currentMuteStatus,
      })
    );

    if (currentMuteStatus === false) {
      muteClick(); // muteClick toggles the muted value (amongst other things), so in this case it will mute the sounds
    }
  }

  componentWillUnmount = () => {
    const { muted, muteOnCloseSlider, toggleMuted } = this.props;
    if (document.body.classList.contains("sop-sidepane-active")) {
      if (!muteOnCloseSlider && muted) {
        toggleMuted(); // toggles the muted value, so in this case it will unmute
      }
    }

    document.body.classList.remove("sop-sidepane-active");
  }

  render() {

    const { selectedSite, selectedSOPRow } = this.state;

    // const mediaType = this.props.browser.mediaType;

    const {
      loggedInUser,
      enterprise, 
      group,
      groups,
      inAlarmSites,
      expanded,
      showSOP,
      getEventMetaById,
      playNextPrioritySound,
      muted,
      muteOnCloseSlider,
      muteClick,
      toggleMuted,
      sites,
    } = this.props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    // how many of the expandable rows have been expanded by the user
    const expandedRowsCount = getExpandedRowsCount(expanded);

    // let hideOrShowCol = null;
    // let unset = { whiteSpace: "unset" };
    // let set = {};
    // let width = null;
    // let styleResponsive = null;
    // let widthMobile = 70;
    // let widthLarge = 100;

    // if (mediaType === "extraSmall") {
    //   hideOrShowCol = false;
    //   styleResponsive = unset;
    //   width = widthMobile;
    // } else if (
    //   mediaType === "medium" ||
    //   mediaType === "large" ||
    //   mediaType === "infinity"
    // ) {
    //   hideOrShowCol = true;
    //   styleResponsive = set;
    //   width = widthLarge;
    // } else if (mediaType === "small") {
    //   hideOrShowCol = false;
    //   styleResponsive = set;
    //   width = widthLarge;
    // }


    let loadingMsg = "No data available...";

    const columns = [
      {
        key: "CUSTOM_REF",
        Header: "Ref",
        id: "custom_ref",
        className: "text-center",
        width: 60,
        // filterType: "contains",
        accessor: (row) => {
          return getCustomRef({
            custom_ref: row.custom_ref || "",
            name: row.enterprise_site_name || "",
          });
          // else { //alarm demo
          //   return data.dummy_site_custom_ref;
          // }
        },
      },
      {
        key: "SITE_NAME",
        Header: "Site Name",
        accessor: "enterprise_site_name",
        className: "site-name",
        Cell: (row) => {
          return (
            <SiteNameCell
              {...row}
              selectSite={() => {
                if (isEmpty(row.original.site)) {
                  this.props.dispatch(
                    loadSitesBackgroundStart({
                      enterprise_id: enterprise.id,
                    })
                  ); 
                }
                this.onSelectSite(row.original.enterprise_site_id, row.original.sudo_state);
              }}
            />
          )
        },
        // Cell: ({ value, original }) => {
        //   if (!isEmpty(original.site)) {
        //     const custom_ref = getCustomRef( original.site );
        //     return <SiteName custom_ref={custom_ref} value={value} />;
        //   } 
        //   //demo
        //   else {
        //     return <SiteName custom_ref={original.dummy_site_custom_ref} value={original.dummy_site_name} />;
        //   }
        //   ////demo
        // },
        //Placeholder: "filter box",
        // style: {
        //   whiteSpace: "unset",
        //   fontWeight: "bold",
        // },
      },
      {
        key: "SITE_REF",
        Header: "Site Ref",
        width: 80,
        accessor: "site_ref",
      },
      {
        key: "SITE_GROUP",
        Header: "Group",
        id: "site_group",
        className: "text-center",
        width: 65,
        accessor: ( row ) => {
          if (row.custom_group_id || row.group_name) {
            return {
              custom_group_ref: row.custom_group_id,
              name: row.group_name,
              site_group_custom_ref: getCustomRef({ custom_group_ref: row.custom_group_id, name: row.group_name}),
            }
          } else if (row.site && row.site.enterprise_groups && row.site.enterprise_groups.length > 0) {
            const siteGroup = getGroupById(groups, row.site.enterprise_groups[0].id);  // use the first group in the list, which should be the lowest in the group tree
            return {
              ...siteGroup,
              site_group_custom_ref: siteGroup ? getCustomRef({ custom_group_ref: siteGroup.custom_group_id, name: siteGroup.name}) : "",
            }
          } else {
            return null;
          }
        },
        Cell: ( {row} ) => {
          const { site_group } = row;
          if (site_group) {
            return (
              <SiteGroup
                siteGroup={site_group}
                placement="left"
              />
            )
          } else {
            return <></>
          }
        },
        filterMethod: (filter, row) => {
          // look for the filter string in both site group custom ref and name
          return (
            String(row[filter.id].site_group_custom_ref)
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
            ||
            String(row[filter.id].name)
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        },
        sortMethod: (a, b) => {
          // sort by site group custom ref
          const customRefA = String(a.site_group_custom_ref).toLowerCase();
          const customRefB = String(b.site_group_custom_ref).toLowerCase();
          const compareCustomRef = customRefA.localeCompare(customRefB);
          if (compareCustomRef !== 0) {
            return compareCustomRef;
          } else {
            // if site group custom refs are the same, sort by site group name
            const groupNameA = String(a.name).toLowerCase();
            const groupNameB = String(b.name).toLowerCase();
            return groupNameA.localeCompare(groupNameB);
          }
        },
      },
      {
        key: "SITE_STATUS",
        Header: "Site Status",
        headerClassName: "wordwrap",
        // Filter: () => {
        //   return (
        //     <SiteStatusFilterToolbar
        //       filtered={this.state.filtered}
        //       changeFilter={this.onFilteredChangeCustom}
        //     />
        //   );
        // },
        id: "site_status",
        accessor: "open_close_state",
        className: "site-status, text-center",
        width: 90,
        Cell: ( props ) => {
          return (
            <SiteStatus
              {...props} 
            />
          )
        },
        filterMethod: (filter, row) => {
          let rowValue = null;
          rowValue = row[filter.id] && String(row[filter.id]).toLowerCase();
          if (rowValue === "o") {
            return (
              exact({
                filter: filter.value,
                value: String("open"),
                ignoreCase: true,
              })
              ||
              exact({
                filter: filter.value,
                value: String("unlock"),
                ignoreCase: true,
              })
            );
          } else if (rowValue === "c") {
            return (
              exact({
                filter: filter.value,
                value: String("close"),
                ignoreCase: true,
              })
              ||
              exact({
                filter: filter.value,
                value: String("lock"),
                ignoreCase: true,
              })
            );
          } else if (!rowValue) {
            return exact({
              filter: filter.value,
              value: String("unknown"),
              ignoreCase: true,
            })
          } else {
            return false;
          }
        },
      },
      {
        key: "PRIORITY_EVENT",
        Header: "Priority Event",
        id: "priority_event",
        className: "alarm-colour-cell",
        // width: width,
        width: 190,
        accessor: ( data ) => {
          let event_id = "";
          if (data.sudo_state[0].state === 'A') {
            event_id = data.sudo_state[0].alarm_event_id;
          } else {
            event_id = data.sudo_state[0].restore_event_id;
          }
          return {
            ...data,
            alarm_text: data.sudo_state[0].alarm_text,
            event: { ...getEventMetaById(event_id) },
          };
        },
        Cell: (props) => {
          return (
            <EventCell
              event={props.row.priority_event.event}
              alarm_text={props.row.priority_event.alarm_text}
              focus={props.row._original.expanded}
              overrideColor={props.original.sudo_state[0].actioned_complete ? COLOUR_CODES["testGrey"] : null}
            />
          )
        },
        filterMethod: (filter, row) => {
          return (
            String(row[filter.id].event.label)
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        },
        sortMethod: (a, b) => {
          return this.getSudoStateItemsCompareValue(a.sudo_state[0], b.sudo_state[0]);
        },
      },
      {
        key: "EVENT_TIME",
        Header: "Time",
        id: "last_event_acked",
        className: "alarm-colour-cell",
        // width: width,
        width: 70,
        accessor: ( props ) => {
          const timezone = props.site.timezone;
          let formatTime = "";
          if (timezone) {
            const timezoneMoment = new moment.utc(props.sudo_state[0].recvd).tz(timezone);
            formatTime = timezoneMoment.format("HH:mm");
          } else {
            formatTime = new moment(props.sudo_state[0].recvd).format("HH:mm");
          }
          return formatTime;
        },
        Cell: ( props ) => {
          return (
            <InnerColourCell
              backgroundColour={
                props.original.sudo_state[0].actioned_complete
                  ? COLOUR_CODES["testGrey"]
                  : props.row.priority_event.event.colour
              }
              displayValue={props.row.last_event_acked}
              focus={props.row._original.expanded}
            />
          )
        },
        //"last_event_acked",
        //const recvd = new moment(row.recvd).format("HH:mm DD/MM/YY");
      },
      {
        key: "ELAPSED_TIME",
        Header: "Outstanding",
        id: "elapsed_time",
        className: "alarm-colour-cell",
        // width: width,
        width: 100,
        filterable: false,
        accessor: ({sudo_state}) => {
          return new moment(sudo_state[0].recvd).valueOf();
        },
        Cell: ( props ) => {
          return (
            <InnerColourCell
              backgroundColour={
                props.original.sudo_state[0].actioned_complete
                  ? COLOUR_CODES["testGrey"]
                  : props.row.priority_event.event.colour
              }
              displayValue={
                // <ReactTimeAgo date={props.row.elapsed_time} locale="en-GB" timeStyle="round-minute"/>
                <div>
                  <ReactTimeAgo 
                    date={props.row.elapsed_time}
                    locale="en-GB"
                    timeStyle="twitter"
                    tooltip={false}
                    wrapperComponent={({ verboseDate, children }) => {
                      return <Tooltip 
                        description={verboseDate}
                        placement="right"
                      >{children}</Tooltip>
                    }}
                  />
                </div>
              }
              focus={props.row._original.expanded}
            />
          )
        },
      },
      {
        key: "AREA",
        Header: "Area",
        id: "area",
        className: "alarm-colour-cell",
        // width: width,
        width: 80,
        accessor: ({sudo_state}) => sudo_state[0].area,
        Cell: ( props ) => {
          return (
            <InnerColourCell
              backgroundColour={
                props.original.sudo_state[0].actioned_complete
                  ? COLOUR_CODES["testGrey"]
                  : props.row.priority_event.event.colour
              }
              displayValue={props.row.area}
              focus={props.row._original.expanded}
            />
          )
        },
      },
      {
        key: "ZONE",
        Header: "Zone",
        id: "zone",
        className: "alarm-colour-cell",
        // width: width,
        width: 80,
        accessor: ({sudo_state}) => sudo_state[0].zone,
        Cell: ( props ) => {
          return (
            <RightColourCell
              backgroundColour={
                props.original.sudo_state[0].actioned_complete
                  ? COLOUR_CODES["testGrey"]
                  : props.row.priority_event.event.colour
              }
              displayValue={props.row.zone}
              focus={props.row._original.expanded}
            />
          )
        },
      },
      {
        key: "OTHER_EVENTS",
        Header: "Other Events",
        id: "other_events",
        headerClassName: "wordwrap",
        className: "other-events text-dark",
        width: 165,
        filterable: false,
        accessor: ({ sudo_state }) => sudo_state,
        Cell: (props) => {
          let alarm1 = null;
          let alarm2 = null;
          let alarm3 = null;
          const { sudo_state } = props.original;
          if (sudo_state[1]) {
            if (sudo_state[1].state === 'A') {
              alarm1 = getEventMetaById(sudo_state[1].alarm_event_id);
            } else {
              alarm1 = getEventMetaById(sudo_state[1].restore_event_id);
            }
          }
          if (sudo_state[2]) {
            if (sudo_state[2].state === 'A') {
              alarm2 = getEventMetaById(sudo_state[2].alarm_event_id);
            } else {
              alarm2 = getEventMetaById(sudo_state[2].restore_event_id);
            }
          }
          if (sudo_state[3]) {
            if (sudo_state[3].state === 'A') {
              alarm3 = getEventMetaById(sudo_state[3].alarm_event_id);
            } else {
              alarm3 = getEventMetaById(sudo_state[3].restore_event_id);
            }
          }
          const moreAlarmsNumber = sudo_state.length - 4;
          return (
            <div className="inner">
              <div 
                className="d-flex justify-content-start"
                style={{height: "36px"}}
              >
                {alarm1 && <OtherEvent 
                  recvd={props.original.sudo_state[1].recvd} 
                  alarm={alarm1}
                  alarm_text={props.original.sudo_state[1].alarm_text}
                  area={props.original.sudo_state[1].area}
                  zone={props.original.sudo_state[1].zone}
                  overrideColor={props.original.sudo_state[1].actioned_complete ? COLOUR_CODES["testGrey"] : null}
                />}
                {alarm2 && <OtherEvent 
                  recvd={props.original.sudo_state[2].recvd} 
                  alarm={alarm2}
                  alarm_text={props.original.sudo_state[2].alarm_text}
                  area={props.original.sudo_state[2].area}
                  zone={props.original.sudo_state[2].zone}
                  overrideColor={props.original.sudo_state[2].actioned_complete ? COLOUR_CODES["testGrey"] : null}
                />}
                {alarm3 && <OtherEvent 
                  recvd={props.original.sudo_state[3].recvd} 
                  alarm={alarm3}
                  alarm_text={props.original.sudo_state[3].alarm_text}
                  area={props.original.sudo_state[3].area}
                  zone={props.original.sudo_state[3].zone}
                  overrideColor={props.original.sudo_state[3].actioned_complete ? COLOUR_CODES["testGrey"] : null}
                />}
                {(moreAlarmsNumber > 0) && <div className="d-flex align-items-center ml-1 font-weight-bold" style={{fontSize: "large"}}>+{moreAlarmsNumber}</div>}
              </div>
            </div>
          )
        },
        sortMethod: (sudo_stateA, sudo_stateB) => {
          // order first by the number of events,
          // then by the priority of the first other event,
          // or by the priority of the priority event if there are no other events
          if (sudo_stateA.length > sudo_stateB.length) {
            return -1
          } else if (sudo_stateA.length < sudo_stateB.length) {
            return 1
          } else {
            if ((sudo_stateA.length > 1) && (sudo_stateB.length > 1)) {
              return this.getSudoStateItemsCompareValue(sudo_stateA[1], sudo_stateB[1]);
            } else {
              return this.getSudoStateItemsCompareValue(sudo_stateA[0], sudo_stateB[0]);
            }
          }
        },
      },
      {
        key: "ASSIGNED",
        Header: "Assigned",
        id: "assigned",
        show: showSOP,
        className: "assigned text-center",
        width: 80,
        accessor: ({ sudo_state }) => sudo_state[0].sop_action_assigned_user,
        Cell: ( props ) => {
          return <div className="inner">
            <AssignedUser
              assignedUser={props.original.sudo_state[0].sop_action_assigned_user}
              closed={props.original.sudo_state[0].actioned_complete}
            />
          </div>
        },
        Filter: ({ filter, onChange, column }) => {
          let collectedIds = {}; // collect the unique ids
          const options = inAlarmSites
            .map((site) => {
              if (site.sudo_state[0].sop_action_assigned_user) {
                const id = site.sudo_state[0].sop_action_assigned_user.user_id; 
    
                // only collect the id once, i.e. ignore if we've already added it to the collection
                if (collectedIds.hasOwnProperty(id) || id === loggedInUser.id) {
                  return null;
                }
            
                collectedIds[id] = true;

                return {
                  id,
                  name: site.sudo_state[0].sop_action_assigned_user.first_name + " " + site.sudo_state[0].sop_action_assigned_user.last_name,
                  first_name: site.sudo_state[0].sop_action_assigned_user.first_name,
                  last_name: site.sudo_state[0].sop_action_assigned_user.last_name,
                  assignedUser: site.sudo_state[0].sop_action_assigned_user,
                };
              } else {
                return null;
              }
              
            })
            .filter((i) => i)
            .sort((a, b) => {
              // sort by initials
              const initialsA = String(a.first_name[0] + a.last_name[0]).toLowerCase();
              const initialsB = String(b.first_name[0] + b.last_name[0]).toLowerCase();
              const compareInitials = initialsA.localeCompare(initialsB);
              if (compareInitials !== 0) {
                return compareInitials;
              } else {
                // if initials are the same, sort by full name
                const fullNameA = String(a.first_name + a.last_name).toLowerCase();
                const fullNameB = String(b.first_name + b.last_name).toLowerCase();
                return fullNameA.localeCompare(fullNameB);
              }
            });
          options.unshift({
            id: -3,
            name: "Assigned",
            underline: true,
            assignedUser: {
              first_name: "",
              last_name: "",
            },
          });
          options.unshift({
            id: -2,
            name: "Assigned to me",
            assignedUser: {
              first_name: loggedInUser.firstName,
              last_name: loggedInUser.lastName,
              user_id: loggedInUser.id,
            },
          });
          options.unshift({
            id: -1,
            name: "New",
            assignedUser: null,
          });
  
          return (
            <FilterSelect
              onChange={onChange}
              value={filter ? filter.value : ""}
              options={options}
            />
          );
        },
        filterMethod: (filter, row) => {
          const { assigned } = row;
          if (assigned) {
            if (filter.value === "Assigned") {
              return true;
            } else if (filter.value === "Assigned to me") {
              return assigned.user_id === loggedInUser.id;
            } else if (
              String(assigned.first_name + " " + assigned.last_name)
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            ) {
              return true;
            } else if (
              String(assigned.first_name[0] + assigned.last_name[0])
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return (
              String("new")
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
            );
          }
        },
        sortMethod: (a, b) => {
          // if a or b are null it means it's new and unassigned, so sort those to the top
          if (!a && b) {
            return -1;
          } else if (a && !b) {
            return 1;
          } else if (!a && !b) {
            return 0;
          } else {
            // sort by initials
            const initialsA = String(a.first_name[0] + a.last_name[0]).toLowerCase();
            const initialsB = String(b.first_name[0] + b.last_name[0]).toLowerCase();
            const compareInitials = initialsA.localeCompare(initialsB);
            if (compareInitials !== 0) {
              return compareInitials;
            } else {
              // if initials are the same, sort by full name
              const fullNameA = String(a.first_name + a.last_name).toLowerCase();
              const fullNameB = String(b.first_name + b.last_name).toLowerCase();
              return fullNameA.localeCompare(fullNameB);
            }
          }
        },
      },
      {
        key: "ACTIONS",
        Header: "Actions",
        show: showSOP,
        className: "actions",
        width: 120,
        Cell: ( row ) => {
          if (row.original.sudo_state[0].actioned_complete) {
            return (
              <Button
                className="inner"
                disabled
              >Closed</Button>
            )
          } else if (row.original.sudo_state[0].sop_action_assigned_user) {
            return (
              <Button
                className="inner"
                variant="warning"
                onClick={() => {
                  this.onClickViewSOP(row.original, row.original.sudo_state);
                }}
              >In Progress</Button>
            )
          } else {
            return (
              <Button
                className="inner"
                onClick={() => {
                  this.onClickViewSOP(row.original, row.original.sudo_state);
                }}
              >View</Button>
            )
          }
        },
      },
      {
        key: "EXPAND",
        Header: "Expand",
        minWidth: 65,
        show: true,

        filterable: false,
        className: "expander",
        id: "expander",
        accessor: "sudo_state",

        style: {
          fontSize: 25,
          padding: "0",
          userSelect: "none",
        },
        sortMethod: (a, b) => {
          if (a.length === b.length) {
            return a > b ? 1 : -1;
          }
          return a.length > b.length ? 1 : -1;
        },
        Cell: ({ original }) => {
          const { enterprise_site_id } = original;
          let colExpanded;
          const { sudo_state } = original;
  
          colExpanded = getExpanded(original);
  
          const expandable = sudo_state.length > 1;
  
          if (!expandable) {
            return null;
          }
    
          return (
            <Button
              className="expandIconButton"
              variant={`${colExpanded ? "" : "outline-"}primary`}
              size="sm"
              onClick={() => {
                this.props.dispatch(
                  setAlarmsExpandSite({
                    enterprise_site_id,
                    expanded: !colExpanded
                  })
                );
              }}
            >
              {colExpanded ? (
                <Icon className="fas fa-chevron-up" />
              ) : (
                <Icon className="fas fa-chevron-down" />
              )}
            </Button>
          );
        }
      },
    ];

    return (
      <div>
        {/* <div className="card visual-data site-list"> */}
        <div className="card visual-data in-alarm-list">
          <ReactTable
            columns={columns}
            // data={tableData}
            data={inAlarmSites}
            className={`${expandedRowsCount > 0 ? "focus-expanded" : ""}`}
            filterable
            defaultFilterMethod={(filter, row) => {
              return (
                String(row[filter.id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
              );
            }}
            // {...checkboxProps}
            defaultPageSize={50}
            noDataText={loadingMsg}
            defaultSorted={[
              {
                id: "priority_event",
                desc: false,
              },
            ]}
            TrGroupComponent={TrGroupComponent} 
            getTrGroupProps={(state, rowInfo, column) => {
              if (!rowInfo) {
                return { 
                  columns,
                  expanded: 0,
                  className: "empty-row",
                }
              } else {
                // Sets background to alarm id colour
                const colorKey = rowInfo.original.sudo_state[0].state === 'R' ? "restoreGreen" : rowInfo.original.sudo_state[0].state === 'A' ? "failRed" :  "testGrey";
                // const color = COLORS[colorKey];
                return {
                  columns,
                  expanded: getExpanded(rowInfo.original) ? 1 : 0,
                  showSOP: showSOP,
                  inAlarmSite: rowInfo.original,
                  className: [colorKey, true, "waves-effect"].join(" "),
                  // style: {
                  //   background: color,
                  //   border: "1px solid " + color,
                  //   // border: "3px solid transparent",
                  // },
                  id: "id"+rowInfo.original.id,
                  getEventMetaById,
                  onClickViewSOP: this.onClickViewSOP,
                };
              }
            }}
          />
        </div>
        <SiteView
          site={(selectedSite && selectedSite.site_id) ? getSiteById(sites, selectedSite.site_id) : null}
          selectSite={this.onSelectSite}
          // Using controlled state for bundle_id
          bundle_id={this.state.bundle_id}
          onSelectBundle={bundle_id => {
            // Bundle selected
            if (bundle_id) {
              this.setState({ bundle_id });
            }
            // Invalid bundle id, deselect
            else {
              this.setState({ bundle_id: null });
            }
          }}
          muteClick={muteClick}
          toggleMuted={toggleMuted}
        />
        {
          showSOP && <div className="sopSidePane">
            <SOPSidePane
              title={(selectedSOPRow && selectedSOPRow.enterprise_site_name) ? selectedSOPRow.enterprise_site_name : "View SOP"}
              onHide={() => {
                if (document.body.classList.contains("sop-sidepane-active")) {
                  document.body.classList.remove("sop-sidepane-active");
                  this.props.dispatch(
                    clearSOPActionMap()
                  );

                  if (!muteOnCloseSlider && muted) {
                    muteClick(); // muteClick toggles the muted value (amongst other things), so in this case it will unmute
                  }
                }
              }}
              componentProps={{
                row: selectedSOPRow,
                enterpriseId: enterprise.id,
                groupId: group_id,
                playNextPrioritySound: playNextPrioritySound,
                muteClick: muteClick,
              }}
              currentPage={0}
              Pages={[
                {
                  Component: SOPView,
                },
                {
                  backButtonText: "back",
                  Component: SOPView,
                },
              ]}
            />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    // browser: state.browser,
    loggedInUser: state.user,
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groups: getGroups(state, props),
    inAlarmSites: getInAlarmSites(state, props),
    expanded: getInAlarmExpanded(state, props),
    muted: state.alarmResponse.muted,
    muteOnCloseSlider: state.alarmResponse.muteOnCloseSlider,
    getEventMetaById: createGetEventMetaById(state, props),
    sites: getSites(state),
  };
};
export default withT(
  connect(mapStateToProps)(DataTable),
  "alarm_response"
);