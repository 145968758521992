import React from "react";
//import C3Bar from "./C3Bar";
import MorrisChart from "components/Enterprise/Reports/Charts/Libraries/MorrisChart";

class BarChart extends React.Component {
  render() {
    return <MorrisChart {...this.props} />;
    //return <C3Bar />;
  }
}
export default BarChart;
