const initialState = {
  selectedTab: "groups",
};

export default function enterpriseManagerPersist(state = initialState, action) {
  switch (action.type) {
    case "SET_EM_SELECTED_TAB":
      return {
        ...state,
        selectedTab: action.selectedTab,
      };

    default:
      return state;
  }
}