import React, { useState } from "react";

import { Link } from "react-router-dom";

import { withT } from "services/translation/";

import NotificationList from "./NotificationList/";
import AddRule from "./AddRule/";

function ManageNotifications({ site, onBackToOverview, t }) {
  const [view, setView] = useState("notification-list");

  const content =
    view === "notification-list" ? (
      <NotificationList
        site={site}
        onChangeView={() => {
          setView("add-rule");
        }}
      />
    ) : (
      <AddRule
        site={site}
        onChangeView={() => {
          setView("notification-list");
        }}
      />
    );

  return (
    <>
      <div className="row px-2 mt-4">
        <div className="col-md-12">
          <Link to="#" onClick={onBackToOverview}>
            {t("back_to_overview")}
          </Link>
        </div>
      </div>

      <div className="row px-2">
        <div className="col-md-12">{content}</div>
      </div>
    </>
  );
}
export default withT(
  ManageNotifications,
  "site_overview.side_pane.manage_notifications"
);
