import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { withT } from "services/translation/";

import Loading from "components/Loading";

import AccountPage from "components/Layout/AccountPage";

import { Form, Col } from "react-bootstrap";

import CONXTDLogo from "../Layout/CONXTDLogo";
import BackgroundAnimation from "components/Common/BackgroundAnimation";

import { UpdatePasswordAndTcForm } from "./Profile/UpdatePasswordAndTcForm";
import NameField from "./Profile/NameField";

import { getRouterParams } from "services/redux/selectors/app/";
import {
  getUserAgentLocale,
  getUserAgentTimezone,
} from "services/redux/selectors/";

import {
  requestUserAcceptInviteStart,
  requestUserCreateAccountStart,
} from "services/redux/actions";

import LoginForm from "components/Auth/LoginForm";

import "./AcceptInvite.scss";

// https://app.conxtd.com/#/accept-invite?U=e4ee0435c6d2d386955e5bd073ba3ded

class AcceptInvite extends React.Component {
  state = {
    checkbox: false,
  };

  handleCheck = () => {
    this.setState({ checkbox: !this.state.checkbox });
  };

  submitActivateAccount = () => {
    let user = {};

    document.querySelectorAll("#accept-invite input").forEach((item) => {
      if (item.name === "confirm_password") return;
      // Rename new_password to password for api
      if (item.name === "new_password") user["password"] = item.value;
      user[item.name] = item.value;
    });

    // Trys to detect browser locale/timezone
    // but fallsback to "en" and "Europe/London"
    user.locale = getUserAgentLocale();
    user.timezone = getUserAgentTimezone();

    this.props.dispatch(
      requestUserCreateAccountStart({
        user,
      })
    );
  };

  componentDidMount() {
    const { user } = this.props;

    // Logged in already, redirect
    if (user && user.sessionId) {
      return this.props.history.push("/enterprise");
    }

    const { token } = this.props.match.params;

    this.props.dispatch(
      requestUserAcceptInviteStart({
        token,
      })
    );
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {}
  render() {
    // const isChecked = this.state.Checked;
    // var msg;

    // if (this.state.checkbox) {
    //   msg = "Checked";
    // } else {
    //   msg = "Un-checked";
    // }

    const { t, user } = this.props;
    const { acceptInvite } = user.actions;

    const loading = acceptInvite.loading !== false;

    // const terms = <a href={"https://www.conxtd.com/terms"}>Terms of Use</a>;
    // const policy = (
    //   <a href={"http://www.conxtd.com/privacy-policy/"}>Privacy Policy</a>
    // );

    if (acceptInvite.success) {
      this.props.history.push("/enterprise");
    }

    const content = loading ? (
      <Loading center />
    ) : acceptInvite.error.status === 401 ? (
      <div className="row pt-4">
        <div className="col-12">
          <p>
            {t("user_accept_invite.error_message.invitation_already_accepted")}
          </p>
          <p>{t("user_accept_invite.error_message.please_login")}</p>
          <LoginForm />
        </div>
      </div>
    ) : (
      <>
        <div className="row pt-4">
          <div className="col-12">
            <p>
              {t(
                "user_accept_invite.welcome_message.hi_first_name",
                user.firstName
              )}
            </p>
            <p>
              {user.inviterName !== null
                ? t(
                    "user_accept_invite.welcome_message.invited_by_inviter",
                    user.inviterName
                  )
                : t("user_accept_invite.welcome_message.invited")}
            </p>
            <p>{t("user_accept_invite.welcome_message.check_details_below")}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <UpdatePasswordAndTcForm
              submitButtonText={t("user_accept_invite.submit_button_text")}
              showCurrentPasswordField={false}
              onSubmit={this.submitActivateAccount}
              // loading={user.actions.acceptInvite.loading}
            >
              <Form.Row>
                <Form.Group controlId="formGroupEmail" as={Col} md="6">
                  <Form.Label>
                    {t("user_accept_invite.email_address")}
                  </Form.Label>
                  <Form.Control
                    defaultValue={user.email}
                    name="email"
                    disabled
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <NameField
                  label={t("user.profile.user_details.first_name")}
                  defaultValue={user.firstName}
                  name="firstName"
                />
                <NameField
                  label={t("user.profile.user_details.last_name")}
                  defaultValue={user.lastName}
                  name="lastName"
                />
              </Form.Row>
            </UpdatePasswordAndTcForm>
            {/*  <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                inline
                type="checkbox"
                label={
                  <text>
                    I accept {terms} & {policy}.
                  </text>
                }
                onChange={this.handleCheck}
                defaultChecked={this.state.checkbox}
              />
              {!this.state.checkbox && (
                <p>
                  Warning: You cannot create an account without accepting terms
                  of use & privacy policy
                </p>
              )}
              <p>Checkbox: {msg} </p>
              </Form.Group> */}
          </div>
        </div>
      </>
    );

    return (
      <div>
        {/* <video poster="/login-background-image.png" playsInline={true} autoPlay={true} muted={true} loop={true} className="bgvid">
          <source src="/login-background-animation.mp4" type="video/mp4" />
        </video> */}
        <BackgroundAnimation />
        <AccountPage id="accept-invite">
          <div className="row align-items-center accept-invite-page px-0 px-sm-4">
            <div className="col-lg-6 offset-lg-3">
              <div className="login-header">
                <div className="row">
                  <div className="col-12 text-center">
                    <CONXTDLogo
                      style={{
                        height: "96px",
                        marginTop: "-10px",
                      }}
                      dark
                    />
                  </div>
                </div>
                {content}
              </div>
            </div>
          </div>
        </AccountPage>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    router: { params: getRouterParams(state, props) },
    user: state.user,
  };
};
export default withRouter(withT(connect(mapStateToProps)(AcceptInvite)));
