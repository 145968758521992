import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import _ from "lodash";

class DataTableManual extends React.Component {
  state = {
    previousDataTableState: {
      page: 0,
      sorted: [],
      filtered: [],
    }
  };

  render() {
    const { previousDataTableState } = this.state;

    const {
      displayPageIndex,
      setDisplayPageIndex,
      loadDataAction,
      loadDataParams,
    } = this.props;

    return (
      <>
        <ReactTable
          {...this.props}
          ref={r => (this.reactTable = r)}
          manual
          page={displayPageIndex}
          onPageChange={(pageIndex) => {
            setDisplayPageIndex(pageIndex)
          }}
          onFetchData={_.debounce((state, instance) => {
            if (state.data && state.data.length > 0) {
              // to prevent hitting the endpoint unnecessarily, first check that there has actually been a change in state
              const dataTableState = {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
              }
              if (!_.isEqual(dataTableState, previousDataTableState)) {
                let sortField = null;
                let sortDirection = null;

                if (state.sorted && state.sorted[0]) {
                  sortField = state.sorted[0].id;
                  sortDirection = state.sorted[0].desc ? "desc" : "asc";
                }

                this.props.dispatch(
                  loadDataAction({
                    ...loadDataParams,
                    pageSize: state.pageSize,
                    requestPageNumber: state.page + 1, // tells the backend which page to serve (starts at 1, like normal page numbering)
                    sortField: sortField,
                    sortDirection: sortDirection,
                    filters: state.filtered,
                  })
                );

                this.setState({
                  previousDataTableState: dataTableState,
                })
              }
            }
          }, 300)} // debounce period
        />
      </>
    )
  }

}

const mapStateToProps = (state, props) => {
  return {};
};
export default connect(mapStateToProps)(DataTableManual);