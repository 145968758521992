import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestAppIdentityCheckSuccess,
  requestAppIdentityCheckFailed,
  loginSuccess,
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* watchAppIdentityCheck() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_APP_IDENTITY_CHECK_START", function* (action) {
    yield race({
      task: call(requestAppIdentityCheck, action),
      cancel: take([
        "REQUEST_APP_IDENTITY_CHECK_CANCEL",
        "REQUEST_APP_IDENTITY_CHECK_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

function* requestAppIdentityCheck(action) {
  const { sessionId } = action;
  if (!sessionId) {
    throw new Error("Invalid value supplied for sessionId: " + sessionId);
  }

  try {
    // Create a temporary cookie for environment
    conxtdOut.updateSession({ environment: conxtdOut.defaultEnv });

    const response = yield call(conxtdOut.get, "/users/details", {
      headers: {
        authorization: sessionId,
      },
    });

    // Update the login cookie - we now have a login token
    // and some user info
    const user = { ...response.data.user, environment: conxtdOut.defaultEnv };
    conxtdOut.updateSession(user);

    console.log("userCookie", response);

    yield put(requestAppIdentityCheckSuccess({ ...response.data }));

    yield put(loginSuccess({ ...response.data }));
  } catch (error) {
    console.log("error", error);
    // Destroy the temporary cookie from aboves
    conxtdOut.logout();
    yield put(
      requestAppIdentityCheckFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText,
        },
        ...action,
      })
    );
  }
}
