import { watchLoadEventPairs } from "./eventPairs";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchLoadEventPairs(),
    ],
  );
}