import React from "react";
import { connect } from "react-redux";

import { Row, Col, Form, FormGroup, Button } from "react-bootstrap";
import Alert from "components/Common/Alert";
import { Tooltip } from "components/Common/Tooltip/";

import { refreshEnterprisesStart } from "services/redux/actions";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import Loading from "components/Loading";

import conxtdOut from "apis/conxtdOut";

/**
 * Enterprise Management Details options
 *
 **/
class Details extends React.Component {
  state = {
    updating: false,
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { enterprise } = this.props;

    const formData = new FormData();

    formData.append("name", event.target.name.value);
    formData.append("domain", event.target.domain.value);
    if (event.target.logo.files.length > 0) {
      formData.append("logo", event.target.logo.files[0], event.target.logo.files[0].name);
    }

    const updateEnterprise = conxtdOut.post(
      `/Enterprises/edit/${enterprise.id}`, 
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    this.setState({ updating: true });

    updateEnterprise.then(() => {
      this.setState({ updating: false });
      Alert({
        text: "Enterprise has been updated.",
        icon: "success",
        timerProgressBar: true,
        timer: 5000,
      });
      this.props.dispatch(refreshEnterprisesStart());
    }).catch(() => {
      this.setState({ updating: false });
      Alert({
        text: "Update failed, please try again later.",
        icon: "error",
      });
    })
  };

  render() {
    const { enterprise } = this.props;

    let domains = "";
    for (let i=0; i<enterprise.domains.length; i++) {
      domains = domains + enterprise.domains[i];
      if (i < (enterprise.domains.length - 1)) {
        domains = domains + " ";
      } 
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="d-flex flex-column justify-content-between" style={{minHeight: "450px"}}>
          <Row>
            <Col>
              <FormGroup controlId="name" style={{ width: "440px" }}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="Name"
                  className="mb-3"
                  required
                  defaultValue={enterprise.name}
                />
              </FormGroup>
              <FormGroup controlId="domain" style={{ width: "440px" }}>
                <Form.Label>Domains</Form.Label>
                <Tooltip
                  placement="right"
                  description="Enter domains separated by a space."
                >
                  <Form.Control
                    type="text"
                    name="Domain"
                    className="mb-3"
                    defaultValue={domains}
                  />
                </Tooltip>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="logo" style={{ width: "440px" }}>
                <Form.Label>Logo</Form.Label>
                <Form.Control
                  type="file"
                  name="Logo"
                  className="mb-3"
                />
                {enterprise.logo_url &&
                  <img src={enterprise.logo_url} className="thumbnail" alt="" style={{maxWidth: "250px"}}></img>
                }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="success" type="submit">
                Update
              </Button>
              <span>&nbsp;&nbsp;{this.state.updating && <Loading left />}</span>
            </Col>
          </Row>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
  };
};
export default connect(mapStateToProps)(Details);