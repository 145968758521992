import React from "react";
import { connect } from "react-redux";

// import { Link } from "react-router-dom";

// import { Button } from "react-bootstrap";

import { getCurrentGroup } from "services/redux/selectors/groups";

import { Card } from "react-bootstrap";

import GroupPath from "components/Enterprise/Overview/GroupPath";
import GroupTitle from "components/Enterprise/Overview/GroupTitle";

import Statistics from "./Statistics/Statistics";

import { SearchToggle } from "components/Layout/Nav/SearchBar/";

class OverviewCard extends React.Component {
  render() {
    const { currentGroup } = this.props;

    const isRootGroup = currentGroup.path && currentGroup.path.length < 2;

    return (
      <Card bg="primary" className="text-white-important">
        <Card.Header className="p-4">
          <span
            style={{ fontSize: "22px", minHeight: "30px", display: "block" }}
          >
            <GroupTitle />
            {/* {currentGroup.id === 310 && (
              <Button variant="outline-light" className="ml-2 text-white">
                <Link to="212/root/reports/outlocks">Access Requests</Link>
              </Button>
            )} */}
          </span>
          {!isRootGroup && (
            <>
              <hr style={{ borderColor: "#cccccc" }} />
              <span className="d-block font-16">
                <GroupPath delimiter=" > " />
              </span>
            </>
          )}
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "15px",
              display: "flex",
            }}
          >
            <SearchToggle />
          </div>
        </Card.Header>
        <Card.Body className="bg-white text-dark">
          <Statistics />
        </Card.Body>
      </Card>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    // groups: getGroups(state, props),
    currentGroup: getCurrentGroup(state),
  };
};
export default connect(mapStateToProps)(OverviewCard);
