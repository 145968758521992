const resources = function(state = {}, action) {
  switch (action.type) {
    case "LOAD_NOTIFICATIONS_RESOURCES_START":
      return {
        ...state,
        data: [],
        loading: true
      };
    case "LOAD_NOTIFICATIONS_RESOURCES_FAILED":
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case "LOAD_NOTIFICATIONS_RESOURCES_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.data.resources
      };
    default:
      return state;
  }
}

export default resources;