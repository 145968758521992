import React from "react";

const failedtoclose = {
  index: {
    overview: {
      index: 0,
      noDataAvailableText: "No site data available.",
      noDataAvailableRenderer: text => (
        <div className="row pie-row no-data-available">
          <div className="col-sm-12 text-center">
            <h6>
              <span>{text}</span>
            </h6>
          </div>
        </div>
      ),
    },

    // TODO: translate
    title: "Failed to Close",
    description: "Show whether sites failed to close on time compared to schedule.",

    /**
     * A live refresh object, describing a window for when to refresh
     * more frequently (short interval). Defaults to null (no refreshing)
     *
     * @property windowStart int 0-23, the hour of which to start short
     * interval refreshing. E.g. 4 starts at 4AM
     *
     * @property windowEnd int 0-23, same as windowStart but determines
     * the end of the window
     *
     * @property shortInterval int time in ms: the length of the "short
     * interval". E.g 5000 would mean a 5 second refresh between
     * the times of windowStart and windowEnd
     *
     * @property longInterval int time in ms: the same as shortInterval
     * but outside of the window
     */
    liveRefresh: {
      // Start at 4AM
      windowStart: 4,
      // End at 11AM
      windowEnd: 11,
      // 1 minute
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: 1000 * 60 * 15,
    },

    dataTable: {
      show: true,
    },

    charts: {
      barStack: {
        show: false,
      },
      pie: {
        filter: {
          groups: {},
          store_types: {},
          type: "groups",
        },
      },
    },

    noDataAvailableText: "No site data available.",
    noDataAvailableRenderer: text => (
      <div className="row pie-row no-data-available">
        <div className="col-sm-12 text-center">
          <h6>
            <span>{text}</span>
          </h6>
        </div>
      </div>
    ),
  }
}

export default failedtoclose;