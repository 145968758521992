import React from "react";
import { connect } from "react-redux";
import { logoutRequest } from "services/redux/actions";

import { Redirect } from "react-router-dom";

import Loading from "../Loading";

import Cookies from "js-cookie";

import _ from "lodash";

/* Protected route so no login verification needed */
class Logout extends React.Component {
  componentDidMount() {
    // Dispatch logout event
    this.props.dispatch(logoutRequest());

    if (window.Intercom) {
      window.Intercom("shutdown");
    }

    // remove intercom cookies
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach(cookieName => {
      if (cookieName.substring(0, 9) === "intercom-") {
        Cookies.remove(cookieName);
      }
    });
  }
  render() {
    const loggedIn = _.get(this.props, "user.sessionId");

    return loggedIn ? (
      <Loading />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: this.props.location }
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Logout);
