import { all, takeLeading } from "redux-saga/effects";

import * as eventMeta from "./eventMeta";

import cacheIndexes from "../cacheIndexes/";

export function* rootSaga() {
  // Enable caching
  yield takeLeading("LOAD_EVENT_META_BACKGROUND_SUCCESS", cacheIndexes.cacheMe);
  yield all([eventMeta.watchLoadBackground()]);
}
