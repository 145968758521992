import React from "react";

import { Card, Row, Col } from "react-bootstrap";

import TetherConnect from "./TetherConnect";
import CameraCardList from "./CameraCardList";
import AddCamera from "./AddCamera/";
import AddLocation from "./AddLocation/";

export default function Tether({ integration }) {
  // camera_integration_site is null if not yet connected
  const tetherConnected = !!integration.camera_integration_site;
  const location = tetherConnected === true ? integration.camera_integration_site.camera_integration_location_name : null;

  return (
    <Card className="channel-card bg-light">
      <Card.Body>
        <Row>
          <Col className="text-center mb-4">
            <img
              alt={integration.integration_name}
              src="https://tether.timeline.is/assets/brand/tether/logo-8ad756685672194f0e49015ed93785e7fd95e0643e58d611491e75da26cbb7fa.png"
              style={{
                maxWidth: "80%"
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Card.Subtitle
              style={{
                lineHeight: "35px",
                position: "absolute",
                bottom: "0"
              }}>
              {/* TODO rename Xanview to Tether on backend */}
              {/* {integration.integration_name} */}
              Tether {location === null ? null : '- ' + location}
            </Card.Subtitle>
          </Col>
          <Col md="6" className="text-right">
            <TetherConnect {...integration} />
          </Col>
        </Row>
        {tetherConnected && (
          <>
            <Row className="mt-2">
              {location && (
                <Col>
                <AddCamera
                  auth_token={integration.auth_token}
                  camera_integration_site_id={
                    integration.camera_integration_site
                      .camera_integration_site_id
                  }
                />
              </Col>
              )}
              {!location && (
                <Col>
                  <AddLocation
                  auth_token={integration.auth_token}
                  camera_integration_site_id={
                    integration.camera_integration_site.camera_integration_site_id
                  }
                  />
              </Col>
              )}
            </Row>
            <hr />
            <Row>
              <CameraCardList integration={integration} />
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
