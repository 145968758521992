import { createSelector } from "reselect";

export const getEventMetaList = state => state.eventMeta;

export const getEventMetaById = (eventMeta, conxtd_event_id) => {
  if (typeof eventMeta.data === "undefined") return null;
  const meta = eventMeta.data.find(item => {
    return item.id === conxtd_event_id;
  }) || {
    // Default
    colour: "#cccccc",
    caption: "",
    icon: null
  };
  // Copy caption to label
  return { ...meta, label: meta.caption };
};

export const createGetEventMetaById = createSelector(
  [getEventMetaList],
  eventMeta => conxtd_event_id => getEventMetaById(eventMeta, conxtd_event_id)
);