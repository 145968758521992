import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import { responsiveStoreEnhancer } from "redux-responsive";
import createSagaMiddleware from "redux-saga";

// Debugging
import invariant from "redux-immutable-state-invariant";
import withReduxEnhancer from "addon-redux/enhancer";
// import logger from "redux-logger";

import reducers from "./reducers";
import rootSaga from "./sagas";

const production = process.env.REACT_APP_PRODUCTION === window.location.origin;

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];
  if (!production) {
    middlewares.push(invariant());
  }
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [responsiveStoreEnhancer, middlewareEnhancer];
  if (!production) {
    enhancers.push(withReduxEnhancer);
  }
  const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const composedEnhancers = production
    ? compose(...enhancers)
    : devCompose(...enhancers);
  // : composeWithDevTools(...enhancers);

  const store = createStore(reducers, preloadedState, composedEnhancers);
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  // if (!production && module.hot) {
  // module.hot.accept("./reducers", () => store.replaceReducer(reducers));
  // }

  return { store, persistor };
}
