import React from "react";
import { connect } from "react-redux";

import { getCurrentGroup } from "services/redux/selectors/groups";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

class GroupTitle extends React.Component {
  render() {
    const { currentGroup, enterprise } = this.props;

    const isRootGroup = currentGroup.path && currentGroup.path.length === 1;

    return <>{isRootGroup ? enterprise.name : currentGroup.name}</>;
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    // groups: getGroups(state, props),
    currentGroup: getCurrentGroup(state)
  };
};
export default connect(mapStateToProps)(GroupTitle);
