import React from "react";
import { connect } from "react-redux";

import { Button, Form, Row, Col, Card } from "react-bootstrap";
import Select from 'react-select';

import CompanyType from "../CompanyType";

import Loading from "components/Loading";

import {
  loadCompanyFilterDataStart,
  loadCompanyStart,
  clearCompany,
  setCompanyDisplayPageIndex,
} from "services/redux/actions/company";

import {
  getCompanyIdFromRoute,
  getMobileAppOptions,
  getArcMapOptions,
  getInstallerOptions,
  getEnterpriseOptions,
} from "services/redux/selectors/company";

import FilterTags from "./FilterTags";
// import DataTable from "./DataTable";
import FlatfileDataTableWrapper from "./FlatfileDataTableWrapper";

import conxtdOut from "apis/conxtdOut";

import _ from "lodash";

import "./Company.scss";

import FILTER_TAG_COLOURS from "./FILTER_TAG_COLOURS.js";

class Company extends React.Component {
  state = {
    mobileAppOption: null,
    enterpriseOption: null,
    addressFilter: "",
    arcMapOption: null,
    installerOption: null,
    tableFilters: [],
  }

  loadCompanyData(companyId) {
    const getCompanyDetails = conxtdOut.get(`/Authake/company/${companyId}`);

    getCompanyDetails.then((response) => {
      this.setState( {
        companyName: response.data.company.name,
        companyLogoURL: response.data.company.logo_url,
        companyType:  response.data.company.type,
      })
    }).catch((error) => {
      console.log("Error on get company details", error);
    })

    this.props.dispatch(
      loadCompanyFilterDataStart({
        company_id: companyId, 
      })
    );

    this.props.dispatch(
      loadCompanyStart({ 
        company_id: companyId, 
      })
    );
  }

  componentDidMount() {
    const { companyId } = this.props;

    if (companyId) {
      this.loadCompanyData(companyId);
    }
  }

  componentDidUpdate(prevProps) {
    const { companyId } = this.props;

    if (companyId !== prevProps.companyId) {
      if (companyId) {
        this.loadCompanyData(companyId);
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(
      clearCompany()
    );
  }

  updateTableFilters = (tableFilters) => {
    this.setState({
      tableFilters,
    });
  }

  applyFilter(field, value) {
    // const { pageSize, sortDirection, displayPageIndex } = this.props;
    const { pageSize, displayPageIndex, params } = this.props;

    this.props.dispatch(
      loadCompanyStart({ 
        company_id: this.props.companyId, 
        requestPageNumber: 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
        pageSize: pageSize,
        params: {
          ...params,
          [field]: value,
        }
      })
    );

    if (displayPageIndex !== 0) {
      this.props.dispatch(
        setCompanyDisplayPageIndex({
          displayPageIndex: 0, // controls the page index that the table component displays (0-indexed)
        })
      )
    }
  }

  handleClearFiltersClick() {
    const { pageSize, displayPageIndex } = this.props;

    // clear top 4 selections/filters
    this.setState({
      mobileAppOption: null,
      arcMapOption: null,
      installerOption: null,
      enterpriseOption: null,
      addressFilter: "",
    })
    document.getElementById("address_filter").value = "";

    // clear table filters
    this.state.tableFilters.forEach((tableFilter) => {
      (document.getElementById(tableFilter.inputId)).value="";
    });
    this.updateTableFilters([]);

    // get unfiltered data
    this.props.dispatch(
      loadCompanyStart({ 
        company_id: this.props.companyId, 
        requestPageNumber: 1, // tells the back end which page to serve (starts at 1, like normal page numbering)
        pageSize: pageSize,
        params: {}
      })
    );

    // show first page of data
    if (displayPageIndex !== 0) {
      this.props.dispatch(
        setCompanyDisplayPageIndex({
          displayPageIndex: 0, // controls the page index that the table component displays (0-indexed)
        })
      )
    }
  }

  render() {
    const {
      companyName,
      companyLogoURL,
      companyType,
      mobileAppOption,
      arcMapOption,
      installerOption,
      enterpriseOption,
      addressFilter,
      tableFilters,
    } = this.state;

    const {
      companyId,
      mobileAppOptions,
      arcMapOptions,
      installerOptions,
      enterpriseOptions,
      totalSites,
    } = this.props;

    if (!companyId) return <Loading />;

    const selectionFilters = [];

    if (mobileAppOption) {
      selectionFilters.push(
        {
          label: "Signalling type: " + mobileAppOption.label,
          color: FILTER_TAG_COLOURS["mobileAppTagColour"],
          clearFilter: () => {
            this.setState({ mobileAppOption: null });
            this.applyFilter("mobile_app_id", "");
          },
        }
      )
    }

    if (enterpriseOption) {
      selectionFilters.push(
        {
          label: "Enterprise: " + enterpriseOption.label,
          color: FILTER_TAG_COLOURS["enterpriseTagColour"],
          clearFilter: () => {
            this.setState({ enterpriseOption: null });
            this.applyFilter("enterprise_id", "");
          }
        }
      )
    }

    if (addressFilter) {
      selectionFilters.push(
        {
          label: "Address: " + addressFilter,
          color: FILTER_TAG_COLOURS["addressTagColour"],
          clearFilter: () => {
            this.setState({ addressFilter: null });
            document.getElementById("address_filter").value = "";
            this.applyFilter("address", "");
          },
        }
      )
    }

    if (arcMapOption) {
      selectionFilters.push(
        {
          label: "ARC: " + arcMapOption.label,
          color: FILTER_TAG_COLOURS["arcMapTagColour"],
          clearFilter: () => {
            this.setState({ arcMapOption: null });
            this.applyFilter("arcmap_id", "");
          }
        }
      )
    }

    if (installerOption) {
      selectionFilters.push(
        {
          label: "Installer: " + installerOption.label,
          color: FILTER_TAG_COLOURS["installerTagColour"],
          clearFilter: () => {
            this.setState({ installerOption: null });
            this.applyFilter("installer_id", "");
          }
        }
      )
    }

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="card m-b-30 visual-data">
              <div className="card-body">
                <div className="d-flex justify-content-between" style={{width: "100%"}}>
                  <div>
                    {
                      (companyLogoURL) &&
                        <div className="mr-4 mb-4" style={{ maxHeight: "70px", width: "auto" }}>
                          <img
                            style={{
                              maxWidth: "200px",
                              maxHeight: "70px",
                            }}
                            src={companyLogoURL}
                            alt=""
                          />
                        </div>
                    }
                    <div className="d-flex align-items-center" style={{ height: "21px" }}><span style={{ fontSize: "13.5px" }}>{companyName}</span></div>
                  </div>
                  <CompanyType companyType={companyType} />
                </div>
                <div className="pt-4">
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <div className="company-filter-item">
                          <label className="company-filter-label">Signalling type:</label>
                          <Select
                            className="company-filter-control ml-2"
                            classNamePrefix="company-filter-select"
                            name="mobile_app_id_filter"
                            options={mobileAppOptions}
                            value={mobileAppOption}
                            isClearable={true}
                            maxMenuHeight={413}
                            onChange={(e) => {
                              const selectedMobileAppOption = e ? _.find(mobileAppOptions, ['value', e.value]) : null;
                              this.setState({ mobileAppOption: selectedMobileAppOption });
                              this.applyFilter("mobile_app_id", selectedMobileAppOption ? selectedMobileAppOption.value : "");
                            }}
                          />
                        </div>
                        <div className="company-filter-item">
                          <label className="company-filter-label">Enterprise:</label>
                          <Select
                            className="company-filter-control ml-2"
                            classNamePrefix="company-filter-select"
                            name="enterprise_id_filter"
                            options={enterpriseOptions}
                            value={enterpriseOption}
                            isClearable={true}
                            maxMenuHeight={413}
                            onChange={(e) => {
                              const selectedEnterpriseOption = e ? _.find(enterpriseOptions, ['value', e.value]) : null;
                              this.setState({ enterpriseOption: selectedEnterpriseOption });
                              this.applyFilter("enterprise_id", selectedEnterpriseOption ? selectedEnterpriseOption.value : "");
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="company-filter-item">
                          <label className="company-filter-label">Address:</label>
                          <input
                            type="text"
                            className="company-filter-control form-control ml-2"
                            id="address_filter"
                            onChange = {_.debounce((e) => {
                              this.setState({ addressFilter: e.target.value });
                              this.applyFilter("address", e.target.value);
                            }, 300)}
                          />
                        </div>
                        {companyType &&<div className="company-filter-item">
                          <label className="company-filter-label">{companyType === "A" ? "Installer" : "ARC:"}</label>
                          <Select
                            className="company-filter-control ml-2"
                            classNamePrefix="company-filter-select"
                            name={companyType === "A" ? "installer_id_filter" : "arc_map_id_filter"}
                            options={companyType === "A" ? installerOptions : arcMapOptions}
                            value={companyType === "A" ? installerOption : arcMapOption}
                            isClearable={true}
                            maxMenuHeight={413}
                            onChange={companyType === "A" ?
                              (e) => {
                                const selectedInstallerOption = e ? _.find(installerOptions, ['value', e.value]) : null;
                                this.setState({ installerOption: selectedInstallerOption });
                                this.applyFilter("installer_id", selectedInstallerOption ? selectedInstallerOption.value : "");
                              }
                            :
                              (e) => {
                                const selectedArcMapOption = e ? _.find(arcMapOptions, ['value', e.value]) : null;
                                this.setState({ arcMapOption: selectedArcMapOption });
                                this.applyFilter("arcmap_id", selectedArcMapOption ? selectedArcMapOption.value : "");
                              }
                            }
                          />
                        </div>
                          }
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 visual-data">
            <Card className="m-b-30">
              <div className="d-flex justify-content-between align-items-top pt-3" style={{ minHeight: "60px" }}>
                <FilterTags
                  selectionFilters={selectionFilters}
                  tableFilters={tableFilters}
                />
                <div>
                  <div className="d-flex" style={{ fontWeight: "600", padding: "0.3rem 1.25rem 0.4rem 0" }}>
                    <div className="text-nowrap pr-2">Total results:</div>
                    <div style={{ display: "inline-block", minWidth: "40px", textAlign: "right" }}>{totalSites}</div>
                  </div>
                  <div style={{ textAlign: "right", paddingRight: "1.25rem" }}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.handleClearFiltersClick();
                      }}
                    >Clear Filters</Button>
                  </div>
                </div>
              </div>
              {/* <DataTable 
                companyId={companyId}
                companyName={companyName}
                companyType={companyType}
                tableFilters={tableFilters}
                updateTableFilters={this.updateTableFilters}
              /> */}
              <FlatfileDataTableWrapper
                companyId={companyId}
                companyName={companyName}
                dataTableProps={{
                  companyId: companyId,
                  companyName: companyName,
                  companyType: companyType,
                  tableFilters: tableFilters,
                  installerOptions: installerOptions,
                  arcMapOptions: arcMapOptions,
                  updateTableFilters: this.updateTableFilters,
                }}
              />
            </Card>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    companyId: getCompanyIdFromRoute(state, props),
    mobileAppOptions: getMobileAppOptions(state),
    arcMapOptions: getArcMapOptions(state),
    installerOptions: getInstallerOptions(state),
    enterpriseOptions: getEnterpriseOptions(state),
    totalSites: state.company.data.pagination.count,
    pageSize: state.company.pageSize,
    displayPageIndex: state.company.displayPageIndex,
    // sortDirection: state.company.sortDirection,
    params: state.company.params,
  };
};
export default connect(mapStateToProps)(Company);