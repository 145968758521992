import React from "react";
import { withTranslation } from "react-i18next";

class Translate extends React.Component {
  render() {
    // console.log(this.props.children);
    if (typeof this.props.children === "undefined")
      throw new Error(
        "Error translating - undefined child in " +
          this._reactInternalFiber._debugOwner._debugSource.fileName +
          ":" +
          this._reactInternalFiber._debugOwner._debugSource.lineNumber
      );
    const children =
      typeof this.props.children.map !== "undefined"
        ? this.props.children.map(child => {
            return this.props.t(child);
          })
        : this.props.t(this.props.children);
    return <>{children}</>;
  }
}

export default withTranslation()(Translate);

/*

class Translate extends React.Component {
  render(){
    return <p>{t("Text")}</p>
  }
  render() {
    const { t } = this.props;
    return <>{t(this.props.children)}</>;
  }
}

Translate = withTranslation()(Translate);

Object.assign(Translate.prototype, )

export default Translate;
*/
