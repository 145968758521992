import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";

import RoleSelect from "components/Enterprise/UserManagement/Common/RoleSelect";
import Features from "components/Enterprise/UserManagement/Common/Features";

// import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Col, Card, Form, Button } from "react-bootstrap";

import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import {
  requestUserManagementInviteStart,
  requestUserManagementInviteNotified,
  requestUserManagementSearchReset,
  loadUserManagementUserListStart
} from "services/redux/actions";
import Alert from "components/Common/Alert";

import { devMode } from "services/redux/selectors/";

/**
 * UserInvite/ExistingUser
 *
 * Form for granting access to a group for the specified user.
 * Displays user details after a user has been searched
 *
 */
class ExistingUser extends React.Component {
  state = {
    role: ""
  };
  inviteUser = e => {
    e.preventDefault();

    const { enterprise, group, users } = this.props;
    const group_id = (group && group.id) || enterprise.root_group_id;

    const { role } = this.state;

    const features = [];
    document
      .querySelectorAll("#ExistingUser .feature-checkbox input")
      .forEach(checkbox => checkbox.checked && features.push(checkbox.id));

    const { user } = users.actions.invite;

    this.props.dispatch(
      requestUserManagementInviteStart({
        email: user.email,
        emailConfirm: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        profile: {
          position: user.jobTitle || "",
          company: { name: user.company || "" },
          // These are hard coded for now but will need to be an
          // autocomplete select or something at some point #196
          locale: "en",
          timezone: "Europe/London"
        },
        enterprise_id: enterprise.id,
        group_id,
        role_id: role,
        featureIds: features,
        existing: true
      })
    );
  };
  render() {
    const { users, group, enterprise, t } = this.props;
    const group_id = (group && group.id) || enterprise.root_group_id;
    const groupName = (group && group.name) || enterprise.name;

    const { loading, user, error, success } = users.actions.invite;

    if (error.message) {
      Alert({
        text: t(`errors.invite${devMode() ? "_dev" : ""}`, error.message),
        icon: "error",
        onAfterClose: () => {
          // Reset error message
          this.props.dispatch(requestUserManagementInviteNotified());
        }
      });
    }
    if (success) {
      Alert({
        text: t("invite_success", user.firstName, user.lastName, groupName),
        icon: "success",
        onAfterClose: () => {
          // Reset error message
          this.props.dispatch(requestUserManagementInviteNotified());
          // Refresh user list
          this.props.dispatch(
            loadUserManagementUserListStart({
              enterprise_id: enterprise.id,
              group_id: group_id
            })
          );
          // Reset user search
          this.props.dispatch(requestUserManagementSearchReset());
        }
      });
    }

    return (
      <>
        <p>
          <b>{t("x_is_on_conxtd", user.email)}</b>
        </p>
        <p>{t("invite_existing_user_description", groupName)}</p>
        <Form onSubmit={this.inviteUser} id="ExistingUser">
          <Card bg="light">
            <Card.Body>
              <Card.Title className="text-uppercase font-weight-bold font-16">
                <Icon className="fas fa-user-circle pr-2" />

                {t("full_name", user.firstName || "", user.lastName || "")}
              </Card.Title>
              <Form.Row>
                <Form.Group controlId="formGroupUserInvite" as={Col} sm="4">
                  <Form.Label className="text-muted">
                    {t("email_address_input_label")}
                  </Form.Label>
                  <Form.Text className="font-14">{user.email}</Form.Text>
                </Form.Group>

                {user.company && (
                  <Form.Group controlId="formGroupUserCompany" as={Col} sm="4">
                    <Form.Label className="text-muted">
                      {t("job_title_input_label")}
                    </Form.Label>

                    <Form.Text className="font-14">
                      {user.company || ""}
                    </Form.Text>
                  </Form.Group>
                )}

                <Form.Group controlId="formGroupUserRole" as={Col} sm="4">
                  <Form.Label className="text-muted">
                    {t("role_input_label")}
                  </Form.Label>

                  <RoleSelect
                    onSelect={e => {
                      this.setState({ role: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="formGroupFeatures" as={Col} sm="4">
                  <Form.Label className="text-muted">
                    {t("features_input_label")}
                  </Form.Label>

                  {/* TODO: features component */}

                  <Features />
                </Form.Group>

                <Form.Group
                  className="text-right mb-0"
                  controlId="formGroupUserActions"
                  as={Col}
                  md="12">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.props.dispatch(requestUserManagementSearchReset());
                    }}
                    disabled={loading}>
                    {t("cancel_button")}
                  </Button>

                  <Button
                    variant="success"
                    type="submit"
                    disabled={this.state.role === "" || loading}
                    className="ml-2">
                    <Icon className="fas fa-user-check pr-2" />
                    {t("button_grant_access")}
                    {loading && <Loading />}
                  </Button>
                </Form.Group>
              </Form.Row>
            </Card.Body>
          </Card>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    users: state.users
  };
};
export default withT(
  connect(mapStateToProps)(ExistingUser),
  "user_management.user_invite"
);
