import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import BANK_HOLIDAYS from "constants/bank-holidays.json";

const getBankHoliday = date => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const dateString = year + "-" + month + "-" + day;

  const englandAndWales = BANK_HOLIDAYS["england-and-wales"].events
    .filter(e => e.date === dateString)
    .map(e => {
      return { ...e, country: "England & Wales" };
    });
  const scotland = BANK_HOLIDAYS["scotland"].events
    .filter(e => e.date === dateString)
    .map(e => {
      return { ...e, country: "Scotland" };
    });
  const northernIreland = BANK_HOLIDAYS["northern-ireland"].events
    .filter(e => e.date === dateString)
    .map(e => {
      return { ...e, country: "Northern Ireland" };
    });

  return [...englandAndWales, ...scotland, ...northernIreland];
};

const Day = (date, modifiers) => {
  const day = date.getDate();

  const Component = day;

  const bankHoliday = getBankHoliday(date);

  if (bankHoliday.length > 0) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-top`} style={{ pointerEvents: "none" }}>
            <span>
              {bankHoliday.map(({ title, country }, index) => (
                <div key={index}>
                  {title} ({country})
                </div>
              ))}
            </span>
          </Tooltip>
        }>
        <div
          className="font-weight-bold"
          style={{ borderBottom: "2px solid #000" }}>
          {Component}
        </div>
      </OverlayTrigger>
    );
  }

  return Component;
};
export default Day;
