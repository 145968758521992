import React from "react";
import { connect } from "react-redux";

// import ReactTable from "react-table";
// import "react-table/react-table.css";

import { Link } from "react-router-dom";

import { Button, ButtonToolbar } from "react-bootstrap";

import { changeGroupTimeframe } from "services/redux/actions/";
import { clearCustomReport, addCustomReportExternalTaskId } from "services/redux/actions/customReports";
import { getUptickLinkedSitesStart } from "services/redux/actions/enterpriseManager";

import { getCustomReportData } from "services/redux/selectors";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute, getCustomRef } from "services/redux/selectors/groups";
import { getActualWithTimezone } from "services/redux/selectors/customReports";
import { sortNumber } from "services/redux/selectors/reports/scheduled";

import SiteNameCell from "components/Common/DataTable/SiteNameCell/";
import EventCell from "components/Common/DataTable/EventCell/";
import ExportDropdown from "components/Enterprise/Export/ExportDropdown";
import SiteView from "components/Site/SiteView/";
import { Tooltip } from "components/Common/Tooltip/";

import ActualTimezoneDate from "components/Common/ActualTimezoneDate";

import Alert from "components/Common/Alert";

import ReactTableWrapper from "./ReactTableWrapper.js";

import moment from "services/locale/momentInit.js";

import "./DataTable.scss";

import conxtdOut from "apis/conxtdOut";

import _ from "lodash";

class DataTable extends React.Component {
  state = {
    showSidePane: false,
    selectedSite: null,
    bundle_id: null,
    isUptick: false,
  };

  componentDidMount() {
    const { enterprise } = this.props;
    const uptickIntegration = _.find(enterprise.integrations, { 'class_name': 'Uptick' });
    if (uptickIntegration) {
      this.setState({
        isUptick: true,
        uptickSubdomain: uptickIntegration.subdomain,
      });

      this.props.dispatch(
        getUptickLinkedSitesStart({
          enterprise_id: enterprise.id,
        })
      );
    }
  }

  onSelectSite = (site, row) => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");

      const acked = new moment(row.acked);

      // Timeframe is from 6 hours before the time of the event until now
      let timeframe = new moment().diff(acked, "hours") + 6;

      // Limit timeframe to maximum of 4 weeks
      if (timeframe > 672) timeframe = 672;

      this.props.dispatch(
        changeGroupTimeframe({
          timeframe
        })
      );

      this.setState({
        showSidePane: true,
        selectedSite: {
          ...site,
          mct_alarm_log_id: row.id,
        },
        bundle_id: 2,
      });
    }
    // Clicked a site that's already being viewed, close
    else {
      document.body.classList.remove("sidepane-active");

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.setState({
          showSidePane: false,
          selectedSite: null,
          bundle_id: null,
        });
      }, 600);
    }
  };

  componentWillUnmount() {
    this.props.dispatch(
      clearCustomReport({})
    );
  }

  render() {
    const { selectedSite, isUptick } = this.state;

    const { enterprise, group, customReport, loadingCustomReport } = this.props;

    const enterpriseId = enterprise.id;
    const groupId = (group && group.id) || enterprise.root_group_id;

    let noDataMsg = "No data available...";

    // NOTE: if ever there is a requrement for the columns to change dynamically in response to props/state changes or user interaction with the page,
    // review the functionality in ReactTableWrapper. It currently only re-renders if the 'data' prop changes, for reasons of efficiency
    const columns = [
      {
        key: "CUSTOM_REF",
        Header: "Ref",
        id: "custom_ref",
        className: "text-center",
        width: 60,
        accessor: (data) => {
          if (!_.isEmpty(data.site)) {
            return getCustomRef(data.site);
          } 
        },
      },
      {
        key: "SITE_NAME",
        Header: "Site Name",
        id: "site_name",
        className: "site-name",
        accessor: data => data.site.name,
        Cell: (props) => {
          if (!_.isEmpty(props.original.site)) { 
            return (
              <SiteNameCell
                // {...row}
                value={props.original.site.name}
                selectSite={() => {
                  this.onSelectSite(
                    props.original.site,
                    props.original,
                  );
                }}
              />
            )
          } 
        }
      },
      {
        key: "SITE_REF",
        Header: "Site Ref",
        width: 80,
        accessor: "site_ref",
      },
      {
        key: "EVENT",
        Header: "Event",
        id: "event",
        className: "alarm-type",
        width: 190,
        accessor: data => data.event_meta.caption,
        Cell: ({ original }) => {
          return <EventCell
            event={original.event_meta}
          />
        }
      },
      {
        key: "TIME",
        Header: "Time",
        id: "occurrence",
        width: 160,
        accessor: data => {
          const occurrence = {
            acked: data.acked,
            timezone: data.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ({ row }) => {
          return <ActualTimezoneDate occurrence={row.occurrence} /> //note: this is a slightly different <ActualTimezoneDate component to the one used in the scheduled reports
        },
        sortMethod: (occurrenceA, occurrenceB) => {
          let ackedA;
          let ackedB;
          
          if (occurrenceA.timezone) {
            const localDate = new moment(occurrenceA.acked);
            ackedA = new moment.utc(localDate).tz(occurrenceA.timezone);
          } else {
            ackedA = occurrenceA.acked;
          }

          if (occurrenceB.timezone) {
            const localDate = new moment(occurrenceB.acked);
            ackedB = new moment.utc(localDate).tz(occurrenceB.timezone);
          } else {
            ackedB = occurrenceB.acked;
          }

          const a = moment(ackedA).format("YYYYMMDDHHmmss");
          const b = moment(ackedB).format("YYYYMMDDHHmmss");
          return sortNumber(a, b);
        },
        filterMethod: (filter, row) => {
          return (
            String(getActualWithTimezone(row.occurrence)) //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        },
      },
      {
        key: "TEXT",
        Header: "Text",
        minWidth: 200,
        accessor: "text",
        Cell: ({ row }) => {
          const lineBreaks = row && row.text && (row.text.search("%0A") !== -1);

          if (lineBreaks) {
            const decodedText = decodeURIComponent(row.text);
            return <Tooltip
              tooltipClassName="event-text-tooltip"
              description={<div style={{whiteSpace: "pre-wrap", textAlign: "left"}}>{decodedText}</div>}
              placement="top"
            >
              <div style={{whiteSpace: "nowrap"}}>{decodedText}</div>
            </Tooltip>
          } else {
            return row.text; 
          }
        }
      },
      {
        key: "AREA",
        Header: "Area",
        width: 80,
        accessor: "area",
      },
      {
        key: "ZONE",
        Header: "Zone",
        width: 80,
        accessor: "zone",
      },
      {
        key: "TASK_ID",
        Header: "Task Id",
        width: 80,
        accessor: "external_task_id",
        className: "text-center",
        show: isUptick, // only show this column if it's an uptick integration
      },
      {
        key: "ACTIONS",
        Header: "Actions",
        width: 140,
        id: "sudo_site_id",
        accessor: "sudo_site_id",
        className: "actions",
        show: isUptick, // only show this column if it's an uptick integration
        Cell: data => {
          if (!data.original.external_linked_site) { // there's no uptick site associated with the CONXTD site
            // show a button that takes the user to enterprise manager so they can set up a site association
            return <Link
              to={{
                pathname: `/enterprise/${enterpriseId}/${groupId}/enterprise-manager`,
                search: "?tab=integrations",
              }}
              className="mr-2 ml-2"
            >
              <Button
                style={{ width: "100%" }}
              >Setup Site</Button>
            </Link>
          } else if (data.original.external_task_id) { // an uptick task has already been created for this event
            // show a button that takes the user to the task on uptick's system
            const { uptickSubdomain } = this.state;
            if (uptickSubdomain) {
              return <a
                href={`https://${uptickSubdomain}.onuptick.com/tasks/tasks/${data.original.external_task_id}/view/`}
                className="mr-2 ml-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="success"
                  style={{ width: "100%" }}
                >View Task</Button>
              </a>
            } else {
              return <Button
                className="mr-2 ml-2 disabled"
              >Task Created</Button>
            }
          } else { // no uptick task has been created for this event yet
            // show a button that will create the task in uptick's system
            const row = data.row;
            const alarmId = data.original.id;
            return (
              <Button
                className="mr-2 ml-2 btn_uptick"
                onClick={() => {
                  const dataTableElement = document.getElementById("custom-reports-datatable-container");
                  dataTableElement.classList.add("cursor-progress");

                  const addTask = conxtdOut.post(`/uptick/addTask/${enterprise.id}/${row.sudo_site_id}/${alarmId}`, {
                    name: row.event,
                    description: row.text,
                  });
              
                  addTask.then((response) => {
                    dataTableElement.classList.remove("cursor-progress");

                    if (response.data && response.data.uptick_response && response.data.uptick_response.code) { // any type of error code passed from uptick
                      Alert({
                        text: "Send to Uptick failed: " + response.data.uptick_response.error.errors.detail,
                        icon: "error",
                        // timerProgressBar: true,
                        // timer: 3000,
                      });
                    } else {
                      Alert({
                        text: "Sent to Uptick.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: true,
                      });

                      this.props.dispatch(
                        addCustomReportExternalTaskId({
                          alarmId: alarmId,
                          taskId: response.data?.uptick_response?.data?.id,
                        })
                      );
                    }                 
                  }).catch((error) => {
                    dataTableElement.classList.remove("cursor-progress");

                    console.log("Error details", error);
                    Alert({
                      text: "Issue sending to Uptick. Please try again later.",
                      icon: "warning",
                      showConfirmButton: true,
                    });
                  })
                }}
              >Send to Uptick</Button>
            )
          }
        }
      }
    ]

    return (
      <div>
        {/* <div className="card visual-data site-list"> */}
        <div
          id="custom-reports-datatable-container"
          className="card visual-data"
        >
          <ButtonToolbar className="p-2">
            <ExportDropdown
              disabled={loadingCustomReport || (customReport.length === 0)}
              reportTitle={"Custom Event Report"}
              dataResolver={(fileFormat) => {
                const data = this.reactTable.getResolvedState().sortedData;
    
                let result = data.map(row => {
                  const occurrence = {
                    acked: row._original.acked,
                    timezone: row._original.site.timezone,
                    format: "HH:mm ddd DD/MM/YYYY",
                  }

                  return [
                    row["custom_ref"] || "",
                    row["site_name"] || "",
                    row["site_ref"] || "",
                    // row["event"] || "",
                    fileFormat === "EXCEL"
                      ? row._original.event_meta
                          ? {
                              // v: (row._original.event_meta && (row._original.event_meta.emoji ? row._original.event_meta.emoji + " " : "")) + row["event"] || "",
                              v: row["event"] || "",
                              t: "s",
                              s: {
                                fill: {
                                  fgColor: {
                                    rgb: (row._original.event_meta.colour[0] === "#")
                                            ? row._original.event_meta.colour.substring(1)
                                            : row._original.event_meta.colour
                                  }
                                },
                                font: {
                                  color: {
                                    rgb: "FFFFFF"
                                  }
                                }
                              }
                            }
                          : row["event"] || ""
                      : row["event"] || "",
                    getActualWithTimezone(occurrence), //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
                    row["text"] || "",
                    row["area"] || "",
                    row["zone"] || ""
                  ];
                });
                result.unshift([
                  "Custom Ref",
                  "Site Name",
                  "Site Ref",
                  "Event",
                  "Time",
                  "Text",
                  "Area",
                  "Zone"
                ]);
    
                return result;
              }}
    
            />
          </ButtonToolbar>
          {/* 
            NOTE: if ever there is a requrement for the value of ReactTable props to change dynamically as the user interacts with the page,
            review the functionality in ReactTableWrapper. It currently only re-renders if the 'data' prop changes, for reasons of efficiency
          */}
          <ReactTableWrapper
            ref={r => (this.reactTable = r)}
            columns={columns}
            data={loadingCustomReport ? [] : customReport}
            filterable
            defaultFilterMethod={(filter, row) => {
              return (
                String(row[filter.id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
              );
            }}
            defaultPageSize={20}
            noDataText={noDataMsg}
            defaultSorted={[
              {
                id: "occurrence",
                desc: true,
              },
            ]}
          />
          <SiteView
            site={selectedSite}
            selectSite={this.onSelectSite}
            bundle_id={this.state.bundle_id}
            onSelectBundle={bundle_id => {
              // Bundle selected
              if (bundle_id) {
                this.setState({ bundle_id });
              }
              // Invalid bundle id, deselect
              else {
                this.setState({ bundle_id: null });
              }
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    roup: getGroupFromRoute(state, props),
    customReport: getCustomReportData(state, props),
    loadingCustomReport: state.customReports.loadingCustomReport,
  };
};
export default connect(mapStateToProps)(DataTable);