import { all } from "redux-saga/effects";

import { loadCamerasWatcher } from "./load";
import { requestAddCameraWatcher } from "./add";
import { requestEditCameraWatcher } from "./edit";
import { requestRemoveCameraWatcher } from "./remove";

export default function* cameraSagas() {
  yield all(
    [
      loadCamerasWatcher(), 
      requestAddCameraWatcher(), 
      requestEditCameraWatcher(), 
      requestRemoveCameraWatcher()
    ]
  );
}
