// import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

import all from "./all";
import current from "./current/";

export default combineReducers({
  // Currently viewed site
  current,
  // All sites
  all
});
