import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { translationError } from "services/redux/actions";

// The translations
// (tip - move them in a JSON file and import them)
import resources from "./resources.json";

// const RECORD_TRANSLATION_ERRORS = false;

i18n.initWithStore = function(store, recordMissingKeys = false) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en",
      saveMissing: true,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        const error = {
          message: "Missing translation key",
          details: {
            lng,
            ns,
            key,
            fallbackValue
          }
        };
        recordMissingKeys && store.dispatch(translationError(error));
      },
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
};

export default i18n;
