import { put } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

import { isTablet } from "react-device-detect";

/**
 * APP - startup
 *
 * Miscellaneous app startup, for saga startup, take() the APP_STARTUP action
 */
export default function* startup() {
  const user = conxtdOut.getSession();
  // User session already exists
  if (user && user.sessionId) {
    yield put(actions.restoreLastFetched());

    yield put(actions.loginSuccess({ user }));
  }

  const navCollapsed = localStorage.getItem("navCollapsed");
  if (navCollapsed !== null) {
    yield put(actions.restoreNavCollapse({ navCollapsed }));
  } else {
    // Force close the nav on tablet if not opened/closed previously
    if (isTablet) {
      yield put(actions.restoreNavCollapse({ navCollapsed: true }));
    }
  }
}
