import React from "react";
import { connect } from "react-redux";

import Alert from "components/Common/Alert";

import Icon from "components/Icons/Icon";

import { withT } from "services/translation/";

import { Button } from "react-bootstrap";

import { devMode } from "services/redux/selectors/";

import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import {
  requestUserManagementRemoveStart,
  requestUserManagementRemoveNotified,
  loadUserManagementUserListStart
} from "services/redux/actions";

class Remove extends React.Component {
  render() {
    const {
      group,
      enterprise,
      users,
      disabled,
      user,
      loggedInUser,
      t
    } = this.props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    const groupName = (group && group.name) || enterprise.name;

    const user_id = user.id;
    const userEmail = user.email;

    const selectedUserId = users.actions.removeAccess.user_id;

    if (user_id === selectedUserId) {
      const { success, error } = users.actions.removeAccess;

      if (success) {
        // Refresh
        this.props.dispatch(
          loadUserManagementUserListStart({
            group_id,
            enterprise_id: enterprise.id
          })
        );
        Alert({
          text: t("success_message", groupName, userEmail),
          icon: "success",
          timerProgressBar: true,
          timer: 1500,
        });
        this.props.dispatch(requestUserManagementRemoveNotified());
      }
      if (error.message) {
        Alert({
          text: t(`errors.remove${devMode() ? "_dev" : ""}`, error.message),
          icon: "error",
          onAfterClose: () => {
            // Reset error message
            this.props.dispatch(requestUserManagementRemoveNotified());
          }
        });
      }
    }

    return (
      <Button
        disabled={disabled || loggedInUser.email === user.email}
        variant="danger"
        onClick={() => {
          Alert({
            text: t("confirm_message", groupName, userEmail),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("confirm_button"),
            cancelButtonText: t("cancel_button")
          }).then(result => {
            if (result.value) {
              this.props.dispatch(
                requestUserManagementRemoveStart({
                  user_id,
                  group_id,
                  enterprise_id: enterprise.id
                })
              );
            }
          });
        }}>
        <Icon className="fas fa-times" />
      </Button>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    group: getGroupFromRoute(state, props),
    enterprise: getEnterpriseFromRoute(state, props),
    users: state.users,
    loggedInUser: state.user
  };
};
export default connect(mapStateToProps)(
  withT(Remove, "user_management.user_list.remove_user")
);
