import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProgressBar from "components/Enterprise/Reports/Common/ProgressBar";

// import LineChart from "./LineChart";
import Timeline from "./Timeline";
import DataTable from "./DataTable";
import Pie from "../../Scheduled/Pie";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import { getReport } from "services/redux/selectors/reports/common/index";

// import { Tabs, Tab } from "react-bootstrap";

import SVGIcon from "components/Icons/SVGIcon";

import Icon from "components/Icons/Icon";
import { ButtonToolbar } from "react-bootstrap";
import ViewAs from "components/Common/ViewAs/";

import { default as T } from "services/i18n/Translate";

// import compileEventData from "./compileEventData";
import { getEventMetaForBundle, getBundle } from "services/redux/selectors";
import { getReportType } from "services/redux/selectors/reports/router";

import { getConfig } from "services/redux/selectors/reports/common/";

// import events from "./__tests__/LineChart.transform.data.json";

class PageLayout extends React.Component {
  state = {
    viewAs: "table"
  };
  render() {
    const { bundle, eventMeta, config, report } = this.props;

    const { viewAs } = this.state;

    const { nextRefresh, lastRefresh } = bundle;

    let { name } = bundle.data;

    const { icon, colour } = eventMeta || {
      icon: "",
      colour: "#000"
    };

    const AlarmIcon =
      eventMeta !== null ? <SVGIcon type={icon} color={colour} /> : null;

    const pie = (
      <ErrorBoundary message="No data available for selected bundle.">
        <Pie />
      </ErrorBoundary>
    );

    const dataAvailable = !(
      report.loading === false && report.data.events.length === 0
    );

    const showFirstSection = config.getShowFirstSection(bundle);
    const showLiveRefresh = config.getShowLiveRefresh(bundle);

    const firstSection = !dataAvailable
      ? config.noDataAvailableRenderer()
      : config.firstSection.renderer({
          pie,
          report
        });

    let Component = null;
    if (dataAvailable) {
      Component = viewAs === "table" ? <DataTable /> : <Timeline />;
    }

    return (
      <div className={this.props.className}>
        <div className="page-title-box pt-0 pb-0">
          <div className="row align-items-center">
            <div className="col-md-6 col-xl-4">
              <div
                className="page-title mb-2 mt-0"
                style={{
                  display: "flex",
                  fontSize: "26px",
                  color: colour,
                  // justifyContent: "center",
                  alignItems: "center"
                }}>
                <span
                  style={{
                    display: "inline-flex",
                    margin: "0 4px",
                    width: "38px",
                    height: "100%",
                    padding: "4px"
                  }}>
                  {AlarmIcon}
                </span>
                {/* <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    opacity: "0.08"
                  }}>
                  {AlarmIcon}
                </div> */}
                <span className="text-truncate">
                  <T>{name}</T>
                </span>
              </div>
              {/* <p><T>{eventMeta.description || ""}</T></p> */}
            </div>
            <div className="col-md-6 col-xl-4 p-4">
              {showLiveRefresh && (
                <div
                  style={{ fontSize: "12px" }}
                  className="text-center float-xl-none float-sm-right float-none">
                  <ProgressBar
                    placement="right"
                    nextRefresh={nextRefresh}
                    lastRefresh={lastRefresh}
                  />
                </div>
              )}
            </div>
            <div className="col-md-4 d-none d-xl-block"> </div>
          </div>
        </div>

        {showFirstSection && (
          <div className="row">
            <div className="col-md-12">
              <ErrorBoundary>
                <div className="card m-b-30 visual-data">
                  <div className="card-body">
                    <div className="card-title text-uppercase h5">
                      <T>Time in fail</T>
                    </div>

                    {firstSection}
                  </div>
                </div>
              </ErrorBoundary>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12 d-flex mb-2">
            <ButtonToolbar
              style={{
                margin: "auto 0 auto auto"
              }}>
              <ViewAs
                options={[
                  {
                    key: "table",
                    description: "View as table",
                    Icon: <Icon className="fas fa-table" />
                  },
                  {
                    key: "cards",
                    description: "View as timeline",
                    Icon: <Icon className="fas fa-stream" />
                  }
                ]}
                viewAs={viewAs}
                changeViewAs={viewAs => this.setState({ viewAs })}
              />
            </ButtonToolbar>
          </div>
          <div className="col-md-12">
            {/* <Tab eventKey="timeline" title="Timeline"> */}
            <ErrorBoundary>
              <div className="card m-b-30 visual-data">{Component}</div>
            </ErrorBoundary>

            {/* <ErrorBoundary>
              <div className="card m-b-30 visual-data">{dataTable}</div>
            </ErrorBoundary> */}

            {/* </Tab> */}
          </div>
        </div>
      </div>
    );
  }
}
PageLayout.propTypes = {
  eventMeta: PropTypes.object.isRequired,
  reportType: PropTypes.string.isRequired,
  bundle: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    eventMeta: getEventMetaForBundle(state),
    reportType: getReportType(state, props),
    bundle: getBundle(state),
    config: getConfig(state, props),
    report: getReport(state, props)
  };
};
export default connect(mapStateToProps)(PageLayout);
