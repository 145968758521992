import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Loading from "components/Loading";

import moment from "services/locale/momentInit.js";

import { Chart } from "react-google-charts";

import produce from "immer";

import {
  getOutstandingEventsFiltered,
  getDaysAgoLevel
} from "services/redux/selectors";

import { getReportPath } from "services/redux/selectors/reports/router";

import DAYS_AGO_COLORS from "constants/DAYS_AGO_COLORS.json";

class Timeline extends React.Component {
  transformData(data) {
    let result = [
      [
        { type: "string", id: "Site name" },
        { type: "string", id: "In alarm since" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" }
      ]
    ];
    let colors = [];

    produce(data, draftData => {
      // draftData.reverse().forEach(site => {
      draftData.forEach(site => {
        const recvd = new moment(site.recvd);
        const fromNow = recvd.fromNow();

        const daysAgoLevel = getDaysAgoLevel(site);

        result.push([
          site.name,
          "In alarm since " + fromNow,
          recvd.toDate(),
          new moment().toDate()
        ]);
        colors.push(DAYS_AGO_COLORS[daysAgoLevel]);
      });
    });

    // const l = [
    //   "Site name",
    //   "Time in alarm",
    //   new Date(1789, 3, 30),
    //   new Date(1797, 2, 4)
    // ];
    // result.push(l);

    return {
      colors,
      data: result
    };
  }

  render() {
    const data = this.transformData(this.props.events);

    return (
      <Chart
        width={"100%"}
        height={"420px"}
        style={{ padding: "0.5em" }}
        chartType="Timeline"
        loader={<Loading center />}
        data={data.data}
        options={{
          timeline: {
            colorByRowLabel: true
          },
          colors: data.colors,
          backgroundColor: this.props.darkMode ? "#13274e" : "#ffffff"
          // timeline: {
          //   rowLabelStyle: {
          //     color: "#ffffff"
          //   },
          //   barLabelStyle: {
          //     color: "#ffffff"
          //   }
          // }
        }}
      />
    );
  }
}
Timeline.propTypes = {
  sites: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    sites: state.sites,
    reportPath: getReportPath(state, props),
    events: getOutstandingEventsFiltered(state, props),
    darkMode: state.app.darkMode
  };
};
export default connect(mapStateToProps)(Timeline);
