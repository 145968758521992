import _ from "lodash";
import merge from "deepmerge";

// import { convertUTC } from "./common";

import ALARM_TYPES from "constants/ALARM_TYPES";

import * as schemaTypes from "schema/reports/";

import { NA, mockEnabled } from "services/redux/selectors";

import { getTypeKey } from "services/redux/selectors/reports/scheduled/suspicious";

import suspiciousMock from "./suspiciousMock.json";

const schema = {
  scheduled: {
    open: schemaTypes.eventType,
    close: schemaTypes.eventType,
    fire: schemaTypes.eventType,
    panic: schemaTypes.eventType,
    overnight: schemaTypes.eventCount,
    peoplecount: schemaTypes.eventCount,
    suspicious: schemaTypes.eventCount,
    // 08/21 new people count hourly report has different schema to other scheduled reports
    peoplecounthourly: schemaTypes.eventCountHourly, 
  },
  exception: {
    panic: schemaTypes.simpleEventCount,
    fire: schemaTypes.simpleEventCount,
  },
};

const convertToNewFormat = (reportType, data) => {
  const stats = _.omit(data, "alarms");

  let totals = [];
  let sites = {};

  const alarms = Array.isArray(data.alarms.FAILED)
    ? data.alarms.OK
    : merge(data.alarms.OK, data.alarms.FAILED || {});
  if (
    reportType === "open" ||
    reportType === "close" ||
    reportType === "fire" ||
    reportType === "panic"
  ) {
    totals = [
      {
        name: "Early",
        type: "EARLY",
        value: stats.total_early || 0,
        colour: "#e67e22",
      },
      {
        name: "On Time",
        type: "ON_TIME",
        value: stats.total_ontime || 0,
        colour: "#27ae60",
      },
      {
        name: "Late",
        type: "LATE",
        value: stats.total_late || 0,
        colour: "#c0392b",
      },
      {
        name: "Pending",
        type: "FAILED",
        value: stats.total_failed || 0,
        colour: "#777777",
      },
      {
        name: "Abnormal",
        type: "ABNORMAL",
        value: stats.total_abnormal || 0,
        colour: "#8e44ad",
      },
    ];
  } else if (reportType === "overnight") {
    totals = [
      {
        name: "One event",
        value: stats.one_event || 0,
        colour: "#f39c12",
      },
      {
        name: "Two to five events",
        value: stats.two_to_five_sites || 0,
        colour: "#e67e22",
      },
      {
        name: "Six to ten events",
        value: stats.six_to_ten_sites || 0,
        colour: "#d35400",
      },
      {
        name: "Eleven to fifteen events",
        value: stats.eleven_to_fifteen_sites || 0,
        colour: "#c0392b",
      },
      {
        name: "Sixteen events or more",
        value: stats.sixteen_plus_sites || 0,
        colour: "#962d22",
      },
    ];
  } else if (reportType === "peoplecount") {
    totals = [
      {
        // name: "One event",
        // value: stats.one_event || 0,
        name: "Up to 50",
        value: stats.up_to_fifty_events || 0,
        colour: "#30D5C8",
      },
      {
        // name: "Two to five events",
        // value: stats.two_to_five_sites || 0,
        name: "51 to 100",
        value: stats.fiftyone_to_onehundred_events || 0,
        colour: "#24B3A8",
      },
      {
        // name: "Six to ten events",
        // value: stats.six_to_ten_sites || 0,
        name: "101 to 150",
        value: stats.onehundredandone_to_onehundredandfifty_events || 0,
        colour: "#00AEAE",
      },
      {
        // name: "Eleven to fifteen events",
        // value: stats.eleven_to_fifteen_sites || 0,
        name: "151 to 200",
        value: stats.onehundredandfiftyone_to_twohundred_events || 0,
        colour: "#1D8D84",
      },
      {
        // name: "Sixteen events or more",
        // value: stats.sixteen_plus_sites || 0,
        name: "201 or more",
        value: stats.twohundredandone_plus_events || 0,
        colour: "#156760",
      },
    ];
  }

  // if (reportType === "open") {
  //   totals = [
  //     {
  //       name: "Early",
  //       type: "EARLY",
  //       value: stats.total_early || 0,
  //       colour: "#e67e22"
  //     },
  //     {
  //       name: "On Time",
  //       type: "ON_TIME",
  //       value: stats.total_ontime || 0,
  //       colour: "#27ae60"
  //     },
  //     {
  //       name: "Late",
  //       type: "LATE",
  //       value: stats.total_late || 0,
  //       colour: "#c0392b"
  //     },
  //     {
  //       name: "Failed",
  //       type: "FAILED",
  //       value: stats.total_failed || 0,
  //       colour: "#777777"
  //     },
  //     {
  //       name: "Abnormal",
  //       type: "ABNORMAL",
  //       value: stats.total_abnormal || 0,
  //       colour: "#8e44ad"
  //     }
  //   ];
  // }

  Object.keys(alarms).forEach((key) => {
    const item = alarms[key];
    let site = {};

    // site.id = item.id;
    // site.name = item.name;
    site.occurrences = [];

    if (!item.occurrences || item.occurrences.length === 0) {
      site.status = "FAILED";
      item.occurrences = {
        FAILED: [
          {
            acked: 0,
            ideal_event_hour:
              item.ideal_event_hour || item.expected_time || NA(),
          },
        ],
      };
    }

    // Using "snapshot" name and wwo_id from response, ref #117
    site.name = item.name;
    site.wwo_id = item.wwo_id;
    if (Array.isArray(item.occurrences)) {
      // Occurrences is already an array (overnight), no need to flatten
      site.occurrences = item.occurrences.map((o) => {
        return {
          ...o,
          open_type: "none",
        };
      });
    } else {
      site.status = "OK";
      // Adding in the open_type and flattening occurrences
      Object.keys(item.occurrences).forEach((key) => {
        // Append each occurrence type, adding the key as the open type
        site.occurrences = [
          ...site.occurrences,
          ...item.occurrences[key].map((o) => {
            // Actual keys are more reliable than open_type
            //  which is why we're overwriting each occurrence
            //  with open type
            return {
              ...o,
              open_type: key || "none",
            };
          }),
        ];
      });
    }

    sites[item.id] = site;
  });

  const result = {
    data: { totals, sites },
    config: {},
  };

  try {
    schema.scheduled[reportType].validateSync(result);
  } catch (error) {
    console.error(error);
    console.error("Could not convert data");
  } finally {
    return result;
  }
};

const reports = {
  transformOvernight: (data) => {
    if (!data.hasOwnProperty("alarms")) {
      return { alarms: { OK: {}, FAILED: {} } };
    }
    const stats = {
      one_event: 0,
      two_to_five_sites: 0,
      six_to_ten_sites: 0,
      eleven_to_fifteen_sites: 0,
      sixteen_plus_sites: 0,
    };
    Object.keys(data.alarms.OK).forEach((siteKey) => {
      const alarmCount = data.alarms.OK[siteKey].occurrences.length;

      if (alarmCount >= 16) {
        stats.sixteen_plus_sites++;
      } else if (alarmCount >= 11) {
        stats.eleven_to_fifteen_sites++;
      } else if (alarmCount >= 6) {
        stats.six_to_ten_sites++;
      } else if (alarmCount >= 2) {
        stats.two_to_five_sites++;
      } else {
        stats.one_event++;
      }
    });

    return {
      ...stats,
      alarms: {
        OK: data.alarms.OK,
        FAILED: {},
      },
    };
  },
  transformPeopleCount: (data) => {
    if (!data.hasOwnProperty("alarms")) {
      return { alarms: { OK: {}, FAILED: {} } };
    }
    const stats = {
      up_to_fifty_events: 0,
      fiftyone_to_onehundred_events: 0,
      onehundredandone_to_onehundredandfifty_events: 0,
      onehundredandfiftyone_to_twohundred_events: 0,
      twohundredandone_plus_events: 0,
    };
    Object.keys(data.alarms.OK).forEach((siteKey) => {
      const alarmCount = data.alarms.OK[siteKey].occurrences.length;

      if (alarmCount >= 201) {
        stats.twohundredandone_plus_events++;
      } else if (alarmCount >= 151) {
        stats.onehundredandfiftyone_to_twohundred_events++;
      } else if (alarmCount >= 101) {
        stats.onehundredandone_to_onehundredandfifty_events++;
      } else if (alarmCount >= 51) {
        stats.fiftyone_to_onehundred_events++;
      } else {
        stats.up_to_fifty_events++;
      }
    });

    return {
      ...stats,
      alarms: {
        OK: data.alarms.OK,
        FAILED: {},
      },
    };
  },
  suspicious: {
    transformOccurrences: function (data) {
      const alarm_id = _.get(data.data, "config.alarm_id") || 10;

      const sites = data.data.sites;

      return {
        ...data,
        data: {
          ...data.data,
          totals: data.data.totals.map((total) => {
            // Add type if not available
            const type =
              total.type ||
              (total.colour === "#e67e22"
                ? "sandwich_buffer"
                : total.colour === "#962d22"
                ? "suspicious_activity"
                : "#666666");
            return {
              ...total,
              type,
            };
          }),
          sites: Object.keys(sites).reduce((acc, site_id) => {
            return {
              ...acc,
              [site_id]: {
                ...acc[site_id],
                occurrences: acc[site_id].occurrences.map((o) => {
                  const open_type = getTypeKey(o);
                  return { ...o, alarm_id, open_type };
                }),
              },
            };
          }, sites),
        },
      };
    },
    convertToNewFormat: function (data) {
      const alarms = _.get(data.data, "alarms.OK") || {};

      let suspiciousTotal = 0;
      let acceptableTotal = 0;

      Object.keys(alarms).forEach((key) => {
        const site = alarms[key];
        const occurrence = _.get(site, "occurrences.0");

        // #257 only count first occurrence in totals for suspicious
        if (!occurrence) return;

        // site.occurrences.forEach(occurrence => {
        const open_type = getTypeKey(occurrence);
        switch (open_type) {
          case "suspicious_activity":
            suspiciousTotal++;
            break;
          case "sandwich_buffer":
            acceptableTotal++;
            break;
          default:
        }
        // });
      });

      let totals = [
        {
          name: "Suspicious activity",
          colour: "#962d22",
          value: suspiciousTotal,
          type: "suspicious_activity",
        },
        {
          name: "Acceptable activity",
          colour: "#e67e22",
          value: acceptableTotal,
          type: "sandwich_buffer",
        },
      ];

      let sites = alarms;

      return this.transformOccurrences({
        data: {
          totals,
          sites,
        },
        config: {},
      });
    },
  },
  /**
   * Transforms reports to a consistent format for the front end
   * to allow for code sharing
   *
   */
  transformScheduled: (data, reportType) => {
    if (reportType === "suspicious") {
      // Live
      if (data.data.config) {
        // Mock
        if (mockEnabled()) {
          data = suspiciousMock;
        }
        // Add type to occurrences
        data = reports.suspicious.transformOccurrences(data);
      }
      // Historic
      else {
        try {
          schema.scheduled[reportType].validateSync(data);
          // Correct schema
          return {
            data: {
              totals: data.data.totals,
              sites: data.data.sites,
            },
            config: data.data.config,
          };
        } catch (error) {
          console.warn(error);
          console.warn(
            "Deprecation warning (scheduled report), data is provided in incorrect format"
          );

          data = reports.suspicious.convertToNewFormat(data);

          if (!schema.scheduled[reportType].isValidSync(data)) {
            console.error("Failed to convert data");
            console.log(data);
          }

          return data;
        }
      }
    }

    const valid = schema.scheduled[reportType].isValidSync(data);

    try {
      schema.scheduled[reportType].validateSync(data);
      if (reportType === "peoplecounthourly") {
        // 08/21 uses hourly data
        return {
          data: {
            combined_hourly: data.data.combined_hourly, 
            sites: data.data.sites,
          },
          config: data.data.config 
                    ? data.data.config 
                    : { title: "People Counting Hourly" }
        };
      }
      return {
        data: {
          totals: data.data.totals,
          sites: data.data.sites,
        },
        config: data.data.config,
      };
    } catch (error) {
      console.warn(error);
      console.warn(
        "Deprecation warning (scheduled report), data is provided in incorrect format"
      );

      //moment.utc().tz("Europe/London").format("YYYY-MM-DD HH:mm:ss")

      if (reportType === "overnight") {
        return convertToNewFormat(
          reportType,
          reports.transformOvernight(data.data)
        );
      }

      if (reportType === "peoplecount") {
        return convertToNewFormat(
          reportType,
          reports.transformPeopleCount(data.data)
        );
      }

      if (reportType === "peoplecounthourly") {
        // wrong format or no data. return empty arrays to cause the noDataAvailableRenderer to show
        return {
          data: {
            combined_hourly: [],
            sites: []
          },
          config: data.data.config 
                    ? data.data.config 
                    : { title: "People Counting Hourly" }
        };
      }

      // Set appropriate timezones for sites
      // Note: times are UTC and should be converted to the timezone local to the site
      // using timezone flag
      let alarms = data.data.alarms || data.data;
      _.each(data.data.alarms, (alarmType, alarmTypeKey) => {
        _.each(alarmType, (site, siteKey) => {
          _.each(_.get(site, "occurrences"), (eventType, eventTypeKey) => {
            _.each(eventType, (occurrence, occurrenceKey) => {
              // Missing timezone... TODO: create a warning for this
              if (!_.get(occurrence, "timezone")) {
                console.warn("Missing timezone, can't parse time for site");
                return;
              }
              // Modifies UTC into local time for the site
              alarms[alarmTypeKey][siteKey]["occurrences"][eventTypeKey][
                occurrenceKey
              // ].acked = convertUTC(occurrence.acked, occurrence.timezone);
            ].acked = occurrence.acked; // conversion is now done with the ActualTimezoneDate component in the report
            });
          });
        });
      });

      let stats = _.omit(data.data, "alarms");
      // Live report (different data structure to non live...)
      if (!_.get(stats, "total_early") && reportType === "close") {
        if (!_.get(stats, "total_enterprise_sites")) {
          // console.warn(
          //   "Missing total_enterprise_sites from server data - expected: total_early, but seem to be getting total_close_early. Patching..."
          // );
          // console.log(stats);
          stats.total_enterprise_sites =
            stats.total_failed +
            stats.total_early +
            stats.total_late +
            stats.total_ontime +
            stats.total_abnormal;
        }
        // Copy these over just because these are the keys we are using.. (todo~)
        // stats.total_early = data.data.total_close_early;
        // stats.total_late = data.data.total_close_late;
        // stats.total_ontime = data.data.total_close_ontime;
      }

      // Server doesn't provide empty array for "OK"
      if (!_.get(alarms, ALARM_TYPES[reportType].ACCESSOR.SUCCESS)) {
        // console.warn(
        //   "Missing property from server data - expected: " +
        //     ALARM_TYPES[reportType].ACCESSOR.SUCCESS +
        //     ", patching..."
        // );
        // console.log(alarms);
        _.set(alarms, ALARM_TYPES[reportType].ACCESSOR.SUCCESS, []);
      }
      // Server doesn't provide empty array for "FAILED"
      if (!_.get(alarms, ALARM_TYPES[reportType].ACCESSOR.FAILED)) {
        // console.warn(
        //   "Missing property from server data - expected: " +
        //     ALARM_TYPES[reportType].ACCESSOR.FAILED +
        //     ", patching..."
        // );
        // console.log(alarms);
        _.set(alarms, ALARM_TYPES[reportType].ACCESSOR.FAILED, []);
      }

      let result = {
        ...stats,
        alarms,
      };

      if (
        !_.get(alarms, ALARM_TYPES[reportType].ACCESSOR.SUCCESS) &&
        JSON.stringify(data) !== JSON.stringify({ data: [] })
      ) {
        const err = "Invalid data type provided by server";
        console.warn(err);
        console.log(data);
        // throw new Error(err);
      }
      // console.log(result);

      if (!valid) {
        return convertToNewFormat(reportType, result);
      }

      return result;
    }
  },
  transformException: (report, reportType) => {
    try {
      schema.exception[reportType].validateSync(report);
      return report;
    } catch (error) {
      console.warn(error);
      console.warn(
        "Deprecation warning (exception report), data is provided in old format"
      );

      let totals = [
        {
          name: reportType === "panic" ? "Panic Completed" : "Fire Completed",
          value: report.total_sites - report.total_exceptions || 0,
          colour: "#27AE5F",
        },
        {
          name: reportType === "panic" ? "Panic Failed" : "Fire Failed",
          value: report.total_exceptions || 0,
          colour: "#C03A2B",
        },
      ];

      const result = {
        config: {},
        data: {
          totals,
          sites: report.sites, //.map(site => site.id)
        },
      };

      try {
        schema.exception[reportType].validateSync(result);
      } catch (error) {
        console.error(error);
        console.error("Could not convert data");
        console.log(result);
      }
      return result;
    }
  },
};
export default reports;
