import { put } from "redux-saga/effects";

import * as actions from "services/redux/actions";

// Link failed to close reports action to sites background loader
export function* toSitesBackgroundLoader(action) {
  const { enterprise_id, group_id } = action;
  yield put(actions.loadSitesBackgroundStart({ enterprise_id, group_id }));
}

// Link failed to close reports to event meta background preloader
export function* toEventMetaBackgroundLoader() {
  yield put(actions.loadEventMetaBackgroundStart());
}