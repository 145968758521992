import React from "react";
import { connect } from "react-redux";
import ReactDayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

import { Button, ButtonGroup } from "react-bootstrap";

import moment from "services/locale/momentInit.js";

import { weekStart } from "services/locale/constants";
import { getNow } from "services/locale/selectors";

import { default as T } from "services/i18n/Translate";

import { getConfig } from "services/redux/selectors/reports/common/";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { getTodayEnabled, getYesterdayIsLive } from "./selectors";

import Day from "./Day";
import TodayButton from "./TodayButton";
import "./DayPicker.scss";

// Determines the week start based on user's locale
const WEEK_START = weekStart();

class DayPicker extends React.Component {
  state = {
    selectedDay: this.props.selectedDay,
    customWeekNumbers: <div></div>,
  };
  componentDidMount() {
    const { selectedDay } = this.state;
    const { enterprise } = this.props;
    if (
      enterprise.calendar_config 
      && (enterprise.calendar_config === "custom") 
      && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))
      && (enterprise.first_month_of_year || (enterprise.first_month_of_year === 0))
      && enterprise.weeks_to_repeat
    ) {
      this.setCustomWeekNumbers(moment(selectedDay).date(1));
    }
  }
  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) return;
    const selectedDay = selected ? undefined : day;
    this.setState({
      selectedDay
    });
    this.props.changeDate(selectedDay);
  };
  clickToday = () => {
    const { enterprise } = this.props;
    const selectedDay = getNow().toDate();
    if ((moment(selectedDay).month() !== moment(this.state.selectedDay).month())
        || ((moment(selectedDay).month() === moment(this.state.selectedDay).month()) 
            && (moment(selectedDay).year() !== moment(this.state.selectedDay).year()))) { 
      // if today's month is different to the month of the currently selected day 
      // or the months are the same but the years are different, then the month display will change
      // so update any custom numbering accordingly
      if (
        enterprise.calendar_config 
        && (enterprise.calendar_config === "custom") 
        && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))
        && (enterprise.first_month_of_year || (enterprise.first_month_of_year === 0))
        && enterprise.weeks_to_repeat
      ) {
        this.setCustomWeekNumbers(moment(selectedDay).date(1));
      }
    }
    this.setState({
      selectedDay
    });
    this.props.changeDate(selectedDay);
  };
  clickYesterday = () => {
    const { enterprise } = this.props;
    const selectedDay = getNow()
      .subtract(1, "day")
      .toDate();
    if ((moment(selectedDay).month() !== moment(this.state.selectedDay).month())
        || ((moment(selectedDay).month() === moment(this.state.selectedDay).month()) 
            && (moment(selectedDay).year() !== moment(this.state.selectedDay).year()))) {  
        // if yesterday's month is different to the month of the currently selected day 
        // or the months are the same but the years are different, then the month display will change
        // so update any custom numbering accordingly
        if (
          enterprise.calendar_config 
          && (enterprise.calendar_config === "custom") 
          && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))
          && (enterprise.first_month_of_year || (enterprise.first_month_of_year === 0))
          && enterprise.weeks_to_repeat
        ) {
          this.setCustomWeekNumbers(moment(selectedDay).date(1));
        }
      }
    this.setState({
      selectedDay
    });
    this.props.changeDate(selectedDay);
  };
  handleMonthChange = (month) => {
    const { enterprise } = this.props;
    if (
      enterprise.calendar_config 
      && (enterprise.calendar_config === "custom") 
      && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))
      && (enterprise.first_month_of_year || (enterprise.first_month_of_year === 0))
      && enterprise.weeks_to_repeat
    ) {
      this.setCustomWeekNumbers(moment(month));
    }
  }

  setCustomWeekNumbers = (firstOfCurrentMonth) => {

    // function to get the start date of the custom year based on a given 1st of a month and the enterprise custom calendar configuration
    const getCustomYearStartDate = (firstOfMonth, enterprise) => {
      // get the date of the 1st of the first month of the custom year 
      // (this isn't the actual first day of the custom year because that is given by the first occurrence of a particular weekday)
      let firstOfFirstMonthOfCustomYear = moment(firstOfMonth);
      if ((firstOfMonth.month() === enterprise.first_month_of_year) // if the given month equals the configured first_month_of_year
        && (firstOfMonth.day() !== enterprise.first_day_of_year)    // and the 1st of the month isn't the correct type of weekday...
      ) {
        firstOfFirstMonthOfCustomYear.set("year", firstOfFirstMonthOfCustomYear.year()-1); // go back a year to get the custom year that starts before the 1st of this month
      } else {
        firstOfFirstMonthOfCustomYear.set("month", enterprise.first_month_of_year); // otherwise, set the month to the configured first_month_of_year
        if (firstOfFirstMonthOfCustomYear > firstOfMonth) {                         // and if that takes us to a date after the month passed in... 
          firstOfFirstMonthOfCustomYear = firstOfFirstMonthOfCustomYear.set("year", firstOfFirstMonthOfCustomYear.year()-1); // go back a year
        }
      }

      // now get the date of the actual first day of the custom year
      let customYearStartDate = firstOfFirstMonthOfCustomYear;
      if (firstOfFirstMonthOfCustomYear.day() !== enterprise.first_day_of_year) { // if the 1st of the first month isn't the correct type of weekday
        // calculate which date is the actual start of the custom year according to the first given weekday
        // this bit is confusing
        let dayOfMonth = 7-firstOfFirstMonthOfCustomYear.day() + enterprise.first_day_of_year+1;
        if (dayOfMonth > 7) {dayOfMonth = dayOfMonth - 7}
        customYearStartDate = customYearStartDate.set("date", dayOfMonth);
      } 
      return customYearStartDate;
    }

    // function to calculate the week number of a given date based on the custom year start date and the enterprise custom calendar configuration
    const getWeekNumber = (theDate, customYearStartDate, enterprise) => {
      if (theDate >= customYearStartDate) {
        let weekNumber;
        const weeksFromStartOfCustomYear = Math.floor((theDate.diff(customYearStartDate, "days")+1)/7);
        const remainingDays = (theDate.diff(customYearStartDate, "days")+1) % 7; 
        weekNumber = (weeksFromStartOfCustomYear % enterprise.weeks_to_repeat);
        if (weekNumber === 0) {
            weekNumber = enterprise.weeks_to_repeat;
        }
        if (remainingDays > 0) {
          if (weekNumber === enterprise.weeks_to_repeat) {
            weekNumber = 1;
          } else {
            weekNumber++;
          }
        }
        return weekNumber;
      } else {
        return -1; // the date is before the custom year start date
      }
    }

    // function to calculate the period number of a given date based on the custom year start date and the enterprise custom calendar configuration
    const getPeriodNumber = (theDate, customYearStartDate, enterprise) => {
      if (theDate >= customYearStartDate) {
        let periodNumber = Math.floor((theDate.diff(customYearStartDate, "days")+1)/(enterprise.weeks_to_repeat*7));
        const remainingDays = (theDate.diff(customYearStartDate, "days")+1) % (enterprise.weeks_to_repeat*7); 
        if (remainingDays > 0) {
          periodNumber++;
        }
        return periodNumber;
      } else {
        return -1; // the date is before the custom year start date
      }
    }

    // function to calculate the number of rows being displayed by the ReactDayPicker component for the given month.
    const getNumberOfWeeks = (firstOfMonth, enterprise) => {
      // this calculation is based on the number of days in the month and how those days fit in 
      // the rows according to the day of the week start day. 
      const daysInMonth = firstOfMonth.daysInMonth();
      let numberOfWeeks = 4; // always at least 4 full weeks, whether 28 days in month or up to 31 days in month
      const remainingDays = daysInMonth % 28; // days remaining after 4 full weeks
      const startDiff = firstOfMonth.day() - enterprise.first_day_of_year; // .day() gives weekday number (0-6)
      // how many of the remaining days are in the top row
      const topRowNumber = (startDiff < 0) 
                            ? Math.abs(startDiff)
                            : 7 - startDiff;
      if ( !( (remainingDays === 0) && (topRowNumber === 7) ) ) { // if we're NOT in the case 
                                                                  // where there are no days remaining after 4 full weeks 
                                                                  // and the top row is neatly filled with 7 days 
        if (topRowNumber >= remainingDays) {
          numberOfWeeks += 1;
        } else {
          numberOfWeeks += 2;
        }
      }
      return numberOfWeeks;
    }

    const { enterprise } = this.props;

    const customYearStartDate = getCustomYearStartDate(firstOfCurrentMonth, enterprise);
    const numberOfWeeks = getNumberOfWeeks(firstOfCurrentMonth, enterprise); // number of rows displaying in the ReactDayPicker component for the given month
    const customWeekArray = [];

    let weekNumberToDisplay = getWeekNumber(firstOfCurrentMonth, customYearStartDate, enterprise);  // get the starting week number to display
                                                                                                    // given the month, the start of the custom year
                                                                                                    // and the enterprise custom calendar configuration
    let periodNumberToDisplay = getPeriodNumber(firstOfCurrentMonth, customYearStartDate, enterprise);
    for (let i=0; i<numberOfWeeks; i++) {
      if ((firstOfCurrentMonth.month() === enterprise.first_month_of_year)  // if it's the configured first month of the year i.e. the month where the numbering restarts
        && (firstOfCurrentMonth.day() !== enterprise.first_day_of_year)     // and the 1st of the month isn't the first weekday of the type specified in the config
                                                                            // then the first weekday of the month (i.e. where the week counting resets)
                                                                            // is on the second line of the day picker
        && (i===1)                                                          // so if we are on the second line of the day picker,
      ) {
          weekNumberToDisplay = 1;                                          // start the count again at 1 for the week number
          periodNumberToDisplay = 0;                                        // and 0 for the period number because it will be incremented in the processing below
      }
      if (weekNumberToDisplay === 1) {
        if (i !== 0) {
          periodNumberToDisplay++;
        }
        customWeekArray.push(<div key={i} className="custom-week-number first-custom-period text-right">P{periodNumberToDisplay}&nbsp;W{weekNumberToDisplay}</div>);
      } else if ((weekNumberToDisplay === enterprise.weeks_to_repeat) && (i === numberOfWeeks-1)) {
        // customWeekArray.push(<div key={i} className="custom-week-number last-custom-period text-right">P{periodNumberToDisplay}&nbsp;W{weekNumberToDisplay}</div>);
        customWeekArray.push(<div key={i} className="custom-week-number last-custom-period text-right">W{weekNumberToDisplay}</div>);
      } else {
        // customWeekArray.push(<div key={i} className="custom-week-number text-right">P{periodNumberToDisplay}&nbsp;W{weekNumberToDisplay}</div>);
        customWeekArray.push(<div key={i} className="custom-week-number text-right">W{weekNumberToDisplay}</div>);
      }
      if (weekNumberToDisplay === enterprise.weeks_to_repeat) {
        weekNumberToDisplay = 1;
      } else {
        weekNumberToDisplay++;
      }
    }

    const customWeekNumbers = (
      <div className="custom-week-number-container">
        <div className="caption-spacer"></div>
        <div className="weekdays-spacer"></div>
        {customWeekArray}
      </div>
    );
    this.setState({
      customWeekNumbers
    });
  }

  render() {
    const { selectedDay, customWeekNumbers } = this.state;
    const { dayPicker } = this.props.config;
    const { enterprise } = this.props;

    const today = getNow();
    const yesterday = getNow().subtract(1, "day");
    const selectedMoment = moment(selectedDay);

    const yesterdaySelected =
      selectedMoment.get("month") === yesterday.get("month") &&
      selectedMoment.get("date") === yesterday.get("date");

    const todayEnabled = getTodayEnabled(today, dayPicker);

    const disableDaysAfter = todayEnabled ? today.toDate() : yesterday.toDate();

    const yesterdayLiveBadge = getYesterdayIsLive(today, dayPicker) ? (
      <span
        className="badge badge-danger"
        style={{
          position: "absolute",
          left: "0px",
          top: "0px",
          padding: "0.2em 0.3em",
          borderRadius: "1px"
        }}>
        <T>Live</T>
      </span>
    ) : null;

    let firstDayOfWeek = WEEK_START;
    if (enterprise.calendar_config && (enterprise.calendar_config === "advanced") && (enterprise.start_day || (enterprise.start_day === 0))){
      firstDayOfWeek = enterprise.start_day;
    }
    if (enterprise.calendar_config && (enterprise.calendar_config === "custom") && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))){
      firstDayOfWeek = enterprise.first_day_of_year;
    }

    const showWeekNumbers = enterprise.calendar_config && (enterprise.calendar_config === "advanced") && enterprise.week_numbers;

    return (
      <div>
        <div className={`ReportDayPicker d-flex justify-content-center ${
            (enterprise.calendar_config && enterprise.calendar_config === "custom") ? "custom-calendar" : ""
          }`}>
          {customWeekNumbers}
          <ReactDayPicker
            renderDay={Day}
            month={this.state.selectedDay}
            firstDayOfWeek={firstDayOfWeek}
            format="YYYY-MM-DD"
            onDayClick={this.handleDayClick}
            onMonthChange={this.handleMonthChange}
            selectedDays={this.state.selectedDay}
            showOutsideDays={true}
            showWeekNumbers={showWeekNumbers}
            disabledDays={[
              {
                after: disableDaysAfter,
                // No data available before 1st July (0-6) 2019
                before: new Date(2019, 6, 1)
              }
            ]}
            //onTodayButtonClick={(day, modifiers) => console.log(day, modifiers)}
          />
        </div>
        <ButtonGroup
          style={{ width: "246px", display: "block", margin: "0 auto" }}>
          <Button
            className="btn-yesterday"
            variant={`${!yesterdaySelected ? "outline-" : ""}primary`}
            onClick={this.clickYesterday}
            style={{ width: "50%" }}>
            <T>Yesterday</T>
            {yesterdayLiveBadge}
          </Button>
          <TodayButton
            selectedMoment={selectedMoment}
            clickToday={this.clickToday}
          />
        </ButtonGroup>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state, props),
    enterprise: getEnterpriseFromRoute(state, props),
  };
};
export default connect(mapStateToProps)(DayPicker);
