import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import ToggleButton from "components/Common/Buttons/Toggle";

import Icon from "components/Icons/Icon";

import { toggleDarkMode } from "services/redux/actions";

class DarkMode extends React.Component {
  state = {
    activeInitiated: false,
    activeInitialState: null,
  }
  componentDidMount() {
    this.setState({
      activeInitiated: true,
      activeInitialState: this.props.app.darkMode,
    })
  }

  render() {
    const { darkMode } = this.props.app;
    const { t } = this.props;
    const { activeInitiated, activeInitialState } = this.state;

    return (
      <label
        style={{ fontSize: "13px"}}
        className="btn dropdown-item waves-effect mb-0"
      >
        <Icon
          className="fas fa-moon text-muted"
          style={{ marginTop: "-2px", fontSize: "14px" }}
        />{" "}
        {t("dark_mode")}
        <ToggleButton
          onClick={() => {
            document.body.classList.toggle("darkmode");
            this.props.dispatch(toggleDarkMode());
          }}
          active={activeInitiated ? activeInitialState : darkMode}
          size="xs"
          style={{ marginTop: "-2px" }}
        />
      </label>
    );
  }
}

const mapStateToProps = state => {
  return { app: state.app };
};
export default withT(connect(mapStateToProps)(DarkMode), "nav.user_dropdown");
