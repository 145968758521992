import React from "react";

import Icon from "components/Icons/Icon";

import { Button } from "react-bootstrap";

import conxtdOut from "apis/conxtdOut";

const approveWorkOrder = async (site_id, lock_id, username) => {
  try {
    await conxtdOut.post(`/OutLocks/enable/${site_id}`, {
      lock_id,
      username,
    });
  } catch (error) {
    console.log("approveWorkOrderErro", error);
  }
};

export default function AllowAccessApiButton({
  site_id,
  lock_id,
  username,
  ...rest
}) {
  return (
    <Button
      size="sm"
      variant="success"
      className="mr-2"
      onClick={() => approveWorkOrder(site_id, lock_id, username)}
      {...rest}
    >
      <Icon className="fas fa-check" /> Allow Access
    </Button>
  );
}
