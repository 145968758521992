import _ from "lodash";

// 0-7 Sun-Sat
const WEEK_START = {
  en: 1,
  default: 0
};

export function getLocale() {
  return "en";
}

export function weekStart() {
  const result = _.get(WEEK_START, getLocale());
  return result || WEEK_START["default"];
}
