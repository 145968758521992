// get company custom fields selector
export const selectCompanyFields = (state) => {
  const { data, error } = state.serviceDesk.companyFields;
  return { getCompanyFieldsData: data, getCompanyFieldsError: error };
};

// get enterprise fields selector
export const selectEnterpriseFields = (state) => {
  const { data, error } = state.serviceDesk.enterpriseFields;
  const getEnterpriseFieldsData = data;
  return { getEnterpriseFieldsData, getEnterpriseFieldsError: error };
};

// get enterprise groups selector
export const selectEnterpriseGroups = (state) => {
  const { data, error } = state.serviceDesk.enterpriseGroups;
  // const getEnterpriseGroupsData = data;
  return { getEnterpriseGroupsData: data, getEnterpriseGroupsError: error };
};
