import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import { Redirect } from "react-router-dom";

import Loading from "components/Loading";

import EnterpriseList from "./List";

// import { loadEnterprisesStart } from "services/redux/actions";
import {
  getEnterprises,
  getEnterprisesLoading
} from "services/redux/selectors/enterprises";

import { TextFilter } from "components/Common/";

// const enterprises = [];
// for (let i = 0; i < 44; i++) {
//   const e = {
//     id: i,
//     name: "Carter & Sons Stores",
//     logo_url: null,
//     home_url: {
//       controller: "EnterpriseDashboard",
//       action: "index"
//     }
//   };
//   enterprises.push(e);
// }

class EnterprisePage extends React.Component {
  state = {
    filter: {
      text: ""
    }
  };
  onFilterChange = e => {
    this.setState({
      filter: {
        text: e.target.value
      }
    });
  };
  // Removed - unneeded now that enterprises
  // are loaded for every page
  // componentDidMount() {
  //   // Dispatch load enterprises if not already loading
  //   //  this prevents duplicate api requests being sent
  //   if (this.props.enterprisesLoading !== true) {
  //     this.props.dispatch(loadEnterprisesStart());
  //   }
  // }
  componentDidMount() {
    // Flush cache when user navigates here
    window.caching.flush();
  }
  render() {
    const { enterprises, enterprisesLoading, t } = this.props;
    const { filter } = this.state;

    if (enterprises.length === 0) {
      // Load has completed but still we have no enterprises
      if (enterprisesLoading === false) {
        return (
          <div>
            <p>Your account currently has no enterprises. </p>
            <p>
              This can happen if you have just been invited to the platform, or
              you may have had no enterprise granted to your account.
            </p>
          </div>
        );
      }
      // We are still loading enterprises
      return <Loading />;
    }

    // Redirect if it's the only enterprise
    if (enterprises.length === 1) {
      const { id } = enterprises[0];
      return <Redirect to={`/enterprise/${id}`} />;
    }

    return (
      <>
        <div className="row">
          <TextFilter
            formClassName="col-12 col-md-4"
            onChange={this.onFilterChange}
            placeholder={t("enterprises.text_filter.placeholder")}
            // formStyle={{ paddingRight: "0.5rem" }}
          />
        </div>
        <EnterpriseList
          enterprises={enterprises.filter(e =>
            e.name.toLowerCase().includes(filter.text.toLowerCase())
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    enterprises: getEnterprises(state),
    enterprisesLoading: getEnterprisesLoading(state)
  };
};
export default withT(connect(mapStateToProps)(EnterprisePage));
