/**
 * 09/21 - open close by area
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PageLayout from "./PageLayout";

// import Loading from "components/Loading";

import { getEnterpriseFromRoute } from "services/redux/selectors";

import { getRouterParams } from "services/redux/selectors/app/";

import {
  loadOpenCloseByAreaReportStart,
  loadOpenCloseByAreaReportCancel
} from "services/redux/actions";

import { getReportType } from "services/redux/selectors/reports/router";

import {
  getOpenCloseByAreaReport,
} from "services/redux/selectors/reports/openclosebyarea/";

class OpenCloseByAreaReportPage extends React.Component {
  // Loads report when enterprises are available so we can load root group
  loadReport(props) {
    let { enterprise_id, group_id } = props.router.params;
    const { enterprise, reportType } = props;

    // The root group was specified - we need to wait on loading
    //  enterprises to get the root group id before we can
    //  begin our request
    if (group_id === "root" || !group_id) {
      group_id = enterprise && enterprise.root_group_id;
      // No enterprises loaded yet
      if (!group_id) return;
    }

    let site_state = "";
    let area_state = "";
    if (reportType === "openclosedareas") {
      site_state = "O";
      area_state = "C";
    } else if (reportType === "closedopenareas") {
      site_state = "C";
      area_state = "O";
    }

    const params = {
      reportType,
      enterprise_id,
      group_id,
      site_state, 
      area_state,
      interval: 5 * 60 * 1000,
      refresh: true,
    };

    this.props.dispatch(loadOpenCloseByAreaReportStart(params));
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loading } = this.props;
    const { params } = this.props.router;
    const nextParams = nextProps.router.params;

    const propsChanged =
      params.enterprise_id !== nextParams.enterprise_id ||
      params.group_id !== nextParams.group_id;

    if (propsChanged) {
      // Not initial load - cancel any previous
      //   live refresh
      if (loading !== null) {
        this.props.dispatch(loadOpenCloseByAreaReportCancel());
      }
      this.loadReport(nextProps);
    }
  }
  componentWillMount() {
    // Scroll to the top
    // window.scrollTo(0, 0);
    this.loadReport(this.props);
  }
  componentWillUnmount() {
    // Cancel any live report auto refresh
    this.props.dispatch(loadOpenCloseByAreaReportCancel());
  }
  render() {
    // const { report, enterprise, eventMetaLoading } = this.props;
    const { report } = this.props;
    // Loading indicator on first load
    const loadingOverlay = report.loading !== false ? "loading" : "";

    // const loading =
    //   // Show page when report has finished loading
    //   report.loading === null ||
    //   // Show page when event meta has been loaded
    //   eventMetaLoading !== false ||
    //   !enterprise;

    return (
      <PageLayout
        className={loadingOverlay}
      />
    );
  }
}
OpenCloseByAreaReportPage.propTypes = {
  enterprise: PropTypes.object,
  router: PropTypes.object,
  report: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  const openclosebyareaReport = getOpenCloseByAreaReport(state, props);
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    reportType: getReportType(state, props),
    report: openclosebyareaReport,
    eventMetaLoading: state.eventMeta.loading,
    router: { params: getRouterParams(state, props) }
  };
};
export default connect(mapStateToProps)(OpenCloseByAreaReportPage);