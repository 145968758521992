/**
 * 09/21 - open close by area
 */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import SVGIcon from "components/Icons/SVGIcon";

import { toggleDataTableFiltered } from "services/redux/actions/";

import { getReportPath } from "services/redux/selectors/reports/router";
// import _ from "lodash";

class FilterButton extends React.Component {
  render() {
    const { id, caption, icon, colour } = this.props.event;
    const { eventCount } = this.props;

    const Icon = icon ? (
      <div
        id={icon}
        className={`svg-icon text-white mr-1`}
        style={{
          display: "inline-block",
          width: "14px",
          height: "14px",
          position: "relative",
          top: "-1px"
        }}>
        <SVGIcon type={icon} color={"#ffffff"} />
      </div>
    ) : null;

    return (
      <button
        className={`btn btn-lg btn-sm-block text-light mx-2 my-2 ${
          this.props.className
        } ${eventCount === 0 ? " disabled" : ""}`}
        style={{ background: colour, border: "1px solid " + colour }}
        onClick={() => {
          // Button is disabled
          if (eventCount === 0) return;

          if ((id === 9) || (id === 10)) { 
            const filter = { id: "perimeter", value: caption };
            
            this.props.dispatch(
              toggleDataTableFiltered({
                filter,
                reportType: this.props.reportPath[1]
              })
            );

            document.querySelector(".ReactTable .rt-th.perimeter-header input").focus();

          } else {
            const filter = { id: "area-state", value: caption };

            this.props.dispatch(
              toggleDataTableFiltered({
                filter,
                reportType: this.props.reportPath[1]
              })
            );

            document.querySelector(".ReactTable .rt-th.area-state-header input").focus();

          }
          
        }}>
        {Icon}
        {caption}
        <span className="badge badge-light ml-2" style={{ color: "#666666" }}>
          {eventCount}
        </span>
      </button>
    );
  }
}
FilterButton.propTypes = {
  event: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    reportPath: getReportPath(state, props),
    user: state.user,
  };
};
export default connect(mapStateToProps)(FilterButton);