import { createSelector } from "reselect";

import { getSites, getSiteById, getSiteByRef } from "services/redux/selectors";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import { getTypeKey } from "services/redux/selectors/reports/scheduled/suspicious";

import DataTableConfig from "constants/DataTable/Reports/";
import ALARM_TYPES from "constants/ALARM_TYPES";

import merge from "deepmerge";


export const getUserGeneratedReportType = (state) => state.customReports.userGeneratedReport.reportType;

export const getUserGeneratedReportData = (state) => state.customReports.userGeneratedReport.data;

export const getUserGeneratedReportExpanded = (state) => state.customReports.userGeneratedReport.expanded;

export const getUserGeneratedReportFilter = (state) => state.customReports.userGeneratedReport.filter;

export const getUserGeneratedReportFiltered = (state) => state.customReports.userGeneratedReport.DataTable.filtered;

export const getUserGeneratedReportEvents = createSelector(
  [getUserGeneratedReportType, getUserGeneratedReportData, getUserGeneratedReportExpanded, getSites],
  (reportType, reportData, expanded, sites) => {

    const formatDataScheduled = (dataObject) => {
      return Object.keys(dataObject).map((id) => {
        const reportDataForSite = dataObject[id];

        // get the occurrences per type, e.g. occurrences for each of the types 'EARLY', 'LATE' etc
        const occurrencesPerType = Object.values(reportDataForSite);

        let siteId = null;
        let siteDetails = {};
        if (occurrencesPerType && occurrencesPerType[0] && occurrencesPerType[0][0]) {
          siteId = parseInt(occurrencesPerType[0][0].id);
          siteDetails = getSiteById(sites, siteId);
        };

        let occurrences = [];
        occurrencesPerType.forEach((type) => {
          type.forEach((occurrence) => {
            occurrences.push(occurrence);
          });
        });

        return {
          id: siteId,
          ...siteDetails,
          occurrences,
          expanded: typeof expanded[siteId] === "undefined" ? null : expanded[siteId],
        };
      });
    }

    const formatDataFire = (dataObject) => {
      return Object.keys(dataObject).map((id) => {
        const itemId = parseInt(id);
        if (itemId === null || isNaN(itemId)) {
          return null
        } else {
          const reportDataForSite = dataObject[id];

          let siteDetails = {};
          if (reportDataForSite && reportDataForSite.site_ref) {
            siteDetails = getSiteByRef(sites, reportDataForSite.site_ref);
          }

          return {
            ...siteDetails,
            name: siteDetails.name || reportDataForSite.name,
            custom_ref: siteDetails.custom_ref || reportDataForSite.custom_ref,
            status: reportDataForSite.status,
            ideal_event_hour: reportDataForSite.ideal_event_hour,
            group_paths: reportDataForSite.group_paths,
            occurrences: reportDataForSite.occurrences || reportDataForSite.occurences || [ { open_type: "FAILED" } ],
            expanded: typeof expanded[siteDetails.id] === "undefined" ? null : expanded[siteDetails.id],
          };
        }
      }).filter((siteObject) => {
        return (siteObject !== null) 
      });
    }

    const formatDataOvernight = (dataObject) => {
      return Object.keys(dataObject).map((id) => {
        const reportDataForSite = dataObject[id];

        let siteId = null;
        if (reportDataForSite && reportDataForSite[0] && reportDataForSite[0].id) {
          siteId = parseInt(reportDataForSite[0].id);
        };

        let siteDetails = {};
        if (siteId === null || isNaN(siteId)) {
          return null
        } else {
          siteDetails = getSiteById(sites, siteId);

          return {
            id: siteId,
            ...siteDetails,
            occurrences: [
              ...reportDataForSite,
            ],
            expanded: typeof expanded[siteId] === "undefined" ? null : expanded[siteId],
          };
        }
      }).filter((siteObject) => {
        return (siteObject !== null) 
      });
    }

    const formatDataSuspicious = (dataObject) => {
      let allOccurrences = [];
      // possibly there'll only ever be one array of occurrences, but just in case there's more...
      Object.values(dataObject).forEach((occurrences) => {
        allOccurrences = [
          ...allOccurrences,
          ...occurrences,
        ]
      });

      let sitesList = {};
      Object.values(allOccurrences).forEach((occurrence) => {
        if (!sitesList[occurrence.id]) {
          const siteId = parseInt(occurrence.id);
          const siteDetails = getSiteById(sites, siteId);
          sitesList[occurrence.id] = {
            ...siteDetails,
            expanded: typeof expanded[siteId] === "undefined" ? null : expanded[siteId],
            occurrences: [
              {
                ...occurrence,
                open_type: getTypeKey(occurrence),
              }
            ]
          };
        } else {
          sitesList[occurrence.id] = {
            ...sitesList[occurrence.id],
            occurrences: [
              ...sitesList[occurrence.id].occurrences,
              {
                ...occurrence,
                open_type: getTypeKey(occurrence),
              }
            ]
          }
        };
      });

      return Object.values(sitesList);
    }

    if (reportType === "open" || reportType === "close") {
      const ok = reportData.ok ? formatDataScheduled(reportData.ok) : [];
      const failed = reportData.failed ? formatDataScheduled(reportData.failed) : [];

      let returnArray = ok;
      if (failed.length > 0) {
        returnArray = [
          ...returnArray,
          ...failed,
        ]
      }
  
      return returnArray;

    } else if (reportType === "overnight") {

      return formatDataOvernight(reportData);

    } else if (reportType === 'fire') {

      return formatDataFire(reportData);

    } else if (reportType === "suspicious") {
      const ok = reportData.ok ? formatDataSuspicious(reportData.ok) : [];
      const failed = reportData.failed ? formatDataSuspicious(reportData.failed) : [];

      let returnArray = ok;
      if (failed.length > 0) {
        returnArray = [
          ...returnArray,
          ...failed,
        ]
      }
  
      return returnArray;
      
    } else {
      return [];
    }
  }
);

export const getUserGeneratedReportAlarmTypes = createSelector(
  [getUserGeneratedReportType],
  (reportType) =>  ALARM_TYPES[reportType]
);

export const createGetEventMeta = createSelector(
  [getUserGeneratedReportAlarmTypes, createGetEventMetaById],
  (alarmTypes, createGetEventMetaById) => {
    return (occurrence) => {
      const { alarm_id } = occurrence;
      if (alarm_id) {
        return createGetEventMetaById(alarm_id);
      }

      const openType = occurrence.open_type;

      return alarmTypes[openType];
    };
  }
);

export const getUserGeneratedReportColumns = createSelector(
  [getUserGeneratedReportType], (reportType) => {
  const columns = DataTableConfig[reportType] || {};
  
  // Overwrite defaults with any report specific data
  return merge(DataTableConfig["default"], columns);
});

export const getExpanded = (site) => {
  if (site && site.expanded === false) return false;

  return site && site.occurrences.length > 1 && site.expanded;
};

export const getExpandedRowsCount = (expanded) =>
  Object.keys(expanded).filter((siteId) => expanded[siteId]).length;