import React from "react";

const comment = (props) => (
  <svg
    id="Capa_1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 511.63 511.63"
  >
    <g>
      <path
        d="M74.5456 481.398H38.2676L63.9199 455.746C77.7513 441.914 86.3885 423.924 88.7106 404.409C29.4791 365.538 1.14441e-05 309.227 1.14441e-05 250.193C1.14441e-05 141.281 100.1 30.6021 256.868 30.6021C422.946 30.6021 512 132.448 512 240.651C512 349.567 422.005 451.345 256.868 451.345C227.939 451.345 197.753 447.483 170.803 440.398C145.291 466.565 110.724 481.398 74.5456 481.398V481.398Z"
        // fill="white"
        className="active-path"
        fill={props.color}
      />
    </g>
  </svg>
);

export default comment;