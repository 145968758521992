import { call, takeLatest } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";
import * as links from "services/redux/sagas/links";

import {
  loadSOPCompletedReportStart,
  loadSOPCompletedReportSuccess,
  loadSOPCompletedReportFailed,
  loadSOPCompletedReportCancel,
} from "services/redux/actions/sopReports";
  
import conxtdOut from "apis/conxtdOut";

export function* watchLoadSOPCompletedReport() {
  // Link sop completed report loading to event meta
  yield takeLatest(
    ["LOAD_SOP_COMPLETED_REPORT_START"],
    links.toEventMetaBackgroundLoader
  );

  // Link sop completed report loading to site data
  yield takeLatest(
    ["LOAD_SOP_COMPLETED_REPORT_START"],
    links.toSitesBackgroundLoader
  );

  yield actionWatcher({
    actions: {
      start: loadSOPCompletedReportStart,
      failed: loadSOPCompletedReportFailed,
      success: loadSOPCompletedReportSuccess,
      cancel: loadSOPCompletedReportCancel,
    },
    saga: loadSOPCompletedReportRe,
  });
}

//{{url}}/sop/sop-actions/actions-report/{{enterprise_id}}/{{group_id}}?limit=20&page=1
export function* loadSOPCompletedReportRe(action) {
  try {
    const { enterprise_id, group_id, sortMapField, sortDirection } = action;

    if (!enterprise_id || !group_id) 
      throw new Error(
        "group_id and enterprise_id are required for loading sop completed report"
      );

    const limit = action.pageSize || 20;
    const page = action.requestPageNumber || 1;

    const url = `/sop/sop-actions/actions-report/${enterprise_id}/${group_id}?limit=${limit}&page=${page}${sortMapField ? "&sort="+sortMapField+"&direction="+sortDirection : ""}`;

    const result = yield call(conxtdOut.post, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}