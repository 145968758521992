import React, { useEffect } from "react";

import { Col } from "react-bootstrap";

import { withT } from "services/translation/";

import CameraCard from "./CameraCard";

function CameraCardList({ integration, t }) {
  useEffect(() => {
    try {
      new window.TetherX(integration.auth_token).tooltips();
    } catch (ignore) {}
  }, [integration.auth_token]);

  if (integration.camera_integration_site.camera_sudo_sites.length === 0) {
    return <Col>{t("no_cameras_added")}</Col>;
  }

  return integration.camera_integration_site.camera_sudo_sites.map(
    (site, index) => (
      <CameraCard key={index} site={site} auth_token={integration.auth_token} integration_id={integration.integration_id} integration={integration}/>
    )
  );
}
export default withT(
  CameraCardList,
  "site_overview.side_pane.manage_integrations.providers.tether"
);
