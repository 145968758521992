/**
 * Users/Management/Invite - invite a user to a group
 *
 * - If the user exists, call group/add
 * - If the user doesn't exist, call users/add, then group/add
 */

import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestUserManagementInviteSuccess,
  requestUserManagementInviteFailed,
  // requestUserManagementCreateStart,
  // requestUserManagementCreateSuccess,
  // requestUserManagementCreateFailed
} from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

export function* watchInvite() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_USER_MANAGEMENT_INVITE_START", function*(action) {
    yield race({
      task: call(requestInvite, action),
      cancel: take([
        "REQUEST_USER_MANAGEMENT_INVITE_CANCEL",
        "REQUEST_USER_MANAGEMENT_INVITE_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

// function* createUser(user) {
//   yield put(requestUserManagementCreateStart({ user }));

//   try {
//     const { firstName, lastName, email, emailConfirm, locale, timezone } = user;

//     if (
//       !firstName ||
//       !lastName ||
//       !email ||
//       !emailConfirm ||
//       !locale ||
//       !timezone
//     ) {
//       throw new Error("Missing parameter for user object when creating user");
//     }

//     // const response = yield call(conxtdOut.post, "/users/add", {
//     //   token
//     // });
//     const response = yield call(() => {
//       return { data: {} };
//     });

//     yield put(requestUserManagementCreateSuccess({ ...response.data }));
//     return true;
//   } catch (error) {
//     yield put(
//       requestUserManagementCreateFailed({
//         error: {
//           message: error.message,
//           status: error.response && error.response.status,
//           statusText: error.response && error.response.statusText
//         },
//         user
//       })
//     );
//     return false;
//   }
// }

function* requestInvite(action) {
  const {
    email,
    profile,
    enterprise_id,
    group_id,
    role_id,
    featureIds,
    first_name,
    last_name,
    // existing
  } = action;
  try {
    if (!email) {
      throw new Error("Invalid value supplied for email: " + email);
    }
    if (!profile) {
      throw new Error("Invalid value supplied for profile: " + profile);
    }
    if (!first_name) {
      throw new Error("Invalid value supplied for first_name: " + first_name);
    }
    if (!last_name) {
      throw new Error("Invalid value supplied for last_name: " + last_name);
    }
    if (!role_id) {
      throw new Error("Invalid value supplied for role_id: " + role_id);
    }
    if (!featureIds) {
      throw new Error("Invalid value supplied for features_id: " + featureIds);
    }

    if (!enterprise_id) {
      throw new Error(
        "Invalid value supplied for enterprise_id: " + enterprise_id
      );
    }
    if (!group_id) {
      throw new Error("Invalid value supplied for group_id: " + group_id);
    }

    // User doesn't exist, we must make a request to create
    // if (!action.existing) {
    //   const createdUser = yield createUser(user);

    //   if (!createdUser)
    //     throw new Error(
    //       "Couldn't invite new user to group, user creation failed"
    //     );
    // }

    // const response = yield call(() => {
    //   return { data: {} };
    // });
    const response = yield call(
      conxtdOut.post,
      `/EnterpriseUsers/add/${enterprise_id}/${group_id}`,
      {
        email: email,
        emailConfirm: email,
        profile,
        first_name: first_name,
        last_name: last_name,
        role_id,
        featureIds,
        // Send invite if user doesn't exist
        // sendInvite: !existing
        sendInvite: true
      }
    );

    yield put(requestUserManagementInviteSuccess({ ...response.data }));
  } catch (error) {
    yield put(
      requestUserManagementInviteFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
