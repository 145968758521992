//alarm-response
import React from "react";

import { withT } from "services/translation/";

import SVGIcon from "components/Icons/SVGIcon";
import { Tooltip } from "components/Common/Tooltip";

const COLORS = {
  setBlue: "#28498A",
  unsetLightblue: "#3397DB",
  noStateMidBlue: "#2B3E4E",
};

const SiteStatus = withT(({ value, t }) => {

  let status = "Unknown";
  status = value && (value === "C" ? "Locked": value === "O" ? "Unlocked" : "Unknown")

  const color = status === "Locked" ? COLORS["setBlue"] : status === "Unlocked" ? COLORS["unsetLightblue"] : COLORS["noStateMidBlue"];  

  const iconType = status === "Locked" ? "lock" : status === "Unlocked" ? "unlock" : "dash";
  const Icon = (
    <span className="status-value">
      <span
        className="svg-icon d-inline-flex"
        style={{
          background: "transparent",
          // borderRadius: parseInt(size) + 50 + "px",
          width: "30px",
          height: "30px",
          margin: "auto"
        }}>
        <span
          className={`svg-icon conxtd-icon-${iconType || "default"}`}
          style={{
            display: "inline-block",
            width: "70%",
            height: "70%",
            margin: "auto"
          }}>
          <SVGIcon
            type={iconType}
            color={color}
            style={{ display: "block" }}
          />
        </span>
      </span>
    </span>
  );

  const StatusIcon =
    status === "Unknown" ? (
      <Tooltip placement="left" description={t("unknown_site_status")}>
        {Icon}
      </Tooltip>
    ) : status === "Locked" || status === "Unlocked" ? (
      <Tooltip placement="left" description={t("padlock_site_status")}>
        {Icon}
      </Tooltip>
    ) : (
      Icon
    );

  return (
    <div className="site-status-container">
      {StatusIcon}
    </div>
  );
}, "site_list.data_table");//!!!??

export { SiteStatus };