import { put, call, takeLeading, all } from "redux-saga/effects";

import { logoutFailed } from "services/redux/actions";

import * as userActions from "./actions/";

import conxtdOut from "apis/conxtdOut";

/* Deprecated in favour of conxtdOut.logout - here for reference!
export function* loginStart(action) {
  try {
    const user = yield call(conxtdOut.login, action.credentials);

    yield put(actions.loginSuccess({ user }));
  } catch (e) {
    let error = e;

    if (_.get(e, "config")) {
      error = _.omit(e, "config");
    }

    yield put(actions.loginFailed({ error }));
  }
}*/

function* logout() {
  try {
    yield call(conxtdOut.logout);
  } catch (error) {
    yield put(logoutFailed({ error }));
  }
}

export function* rootSaga() {
  yield takeLeading("LOGOUT_REQUEST", logout);
  yield all([userActions.rootSaga()]);
}
