export const loadCompanyFilterDataStart = (params) => {
  return { type: "LOAD_COMPANY_FILTER_DATA_START", ...params };
};

export const loadCompanyFilterDataSuccess = (params) => {
  return { type: "LOAD_COMPANY_FILTER_DATA_SUCCESS", ...params, };
};

export const loadCompanyFilterDataFailed = (params) => {
  return { type: "LOAD_COMPANY_FILTER_DATA_FAILED", ...params };
};

export const loadCompanyFilterDataCancel = (params) => {
  return { type: "LOAD_COMPANY_FILTER_DATA_CANCEL", ...params };
};

export const loadCompanyStart = (params) => {
  return { type: "LOAD_COMPANY_START", ...params };
};

export const loadCompanySuccess = (params) => {
  return { type: "LOAD_COMPANY_SUCCESS", ...params, };
};

export const loadCompanyFailed = (params) => {
  return { type: "LOAD_COMPANY_FAILED", ...params };
};

export const loadCompanyCancel = (params) => {
  return { type: "LOAD_COMPANY_CANCEL", ...params };
};

export const clearCompany = (params) => {
  return { type: "CLEAR_COMPANY", ...params };
};

export const setCompanyDisplayPageIndex = (params) => {
  return { type: "SET_COMPANY_DISPLAY_PAGE_INDEX", ...params };
};