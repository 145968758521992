//alarm-response
export const setSocketRoomConnected = (params) => {
  return { type: "SET_SOCKET_ROOM_CONNECTED", ...params };
}

export const loadAlarmsStart = (params) => {
  return { type: "LOAD_ALARMS_START", ...params };
};

export const loadAlarmsSuccess = (params) => {
  return { type: "LOAD_ALARMS_SUCCESS", ...params, };
};

export const loadAlarmsFailed = (params) => {
  return { type: "LOAD_ALARMS_FAILED", ...params };
};

export const loadAlarmsCancel = (params) => {
  return { type: "LOAD_ALARMS_CANCEL", ...params };
};

export const addAlarmEvent = (params) => {
  return { type: "ADD_ALARM_EVENT", ...params };
};

export const removeOldAlarms = (params) => {
  return { type: "REMOVE_OLD_ALARMS", ...params };
}

export const removeAllAlarms = (params) => {
  return { type: "REMOVE_ALL_ALARMS", ...params };
}

export const loadAlarmsRefreshStart = (params) => {
  return { type: "LOAD_ALARMS_REFRESH_START", ...params };
}

export const loadAlarmsRefreshSuccess = (params) => {
  return { type: "LOAD_ALARMS_REFRESH_SUCCESS", ...params };
}

export const loadAlarmsRefreshFailed = (params) => {
  return { type: "LOAD_ALARMS_REFRESH_FAILED", ...params };
}

export const loadAlarmsRefreshCancel = (params) => {
  return { type: "LOAD_ALARMS_REFRESH_CANCEL", ...params };
}

export const setAlarmsExpandSite = (params) => {
  return { type: "SET_ALARMS_EXPAND_SITE", ...params };
}

export const resetAlarmsExpanded = (params) => {
  return { type: "RESET_ALARMS_EXPANDED", ...params };
};

export const setAlarmsMuted = (params) => {
  return { type: "SET_ALARMS_MUTED", ...params };
};

export const setAlarmsMuteOnCloseSlider = (params) => {
  return { type: "SET_ALARMS_MUTE_ON_CLOSE_SLIDER", ...params };
}

export const setAlarmsContinuousSounds = (params) => {
  return { type: "SET_ALARMS_CONTINUOUS_SOUNDS", ...params };
};

export const addDummyInAlarmSite = (params) => { //alarm demo
  return { type: "ADD_DUMMY_IN_ALARM_SITE", ...params };
};

export const assignAlarmSOPUser = (params) => {
  return { type: "ASSIGN_ALARM_SOP_USER", ...params };
}

export const closeAlarmSOPAction = (params) => {
  return { type: "CLOSE_ALARM_SOP_ACTION", ...params };
}