import React from "react";

import get from "lodash/get";

export function OccurrencesColumn({ columns }) {
  const column = columns["OCCURRENCES"];
  return {
    ...column,
    accessor: row => {
      const occurrences = get(row, "occurrences");

      if (occurrences.length === 1) {
        if (get(occurrences, "0.open_type") === "FAILED") {
          return "";
        }
      }

      return occurrences.length;
    },
    Cell: row => {
      const multipleOccurrences = row.value > 1;

      return (
        <span className={multipleOccurrences ? "multiple" : "single"}>
          {row.value}
        </span>
      );
    }
  };
}
