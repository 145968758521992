import React from "react";

import "./CompanyType.scss";

function CompanyType({companyType}) {
  let displayText = companyType;
  let backgroundColor = "transparent";
  let fontColor = "inherit";
  if (companyType === "I") {
    displayText = "Installer";
    backgroundColor = "#2C76BA";
    fontColor = "#fff"
  }
  else if (companyType === "A") {
    displayText = "ARC";
    backgroundColor = "#0A7100";
    fontColor = "#fff"
  }
  return <div
    className="company-type d-flex justify-content-center"
    style={{
      backgroundColor: backgroundColor,
      color: fontColor,
    }}
  >
    {displayText}
  </div>
};
export default CompanyType;