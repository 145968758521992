import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { default as T } from "services/i18n/Translate";

import { Card } from "react-bootstrap";

import {
  getConfig,
  getFilteredActive
} from "services/redux/selectors/reports/common/";

import { getFailedToCloseFilter } from "services/redux/selectors/reports/failedtoclose/";

import FailedToCloseFilter from "./FailedToCloseFilter";

import "./ActiveFilter.scss";

class ActiveFilters extends React.Component {
  filterIsActive = () => {
    const { filter } = this.props;
    const { type } = filter;
    
    return Object.keys(filter[type].show).length > 0 || this.props.filteredActive.length > 0;
  };
  
  render() {
    const { filter } = this.props;
    const { type } = filter;

    if (!this.filterIsActive()) {
      return null;
    }

    const failedToCloseFilters = Object.keys(filter[type].show).map((label, index) => {
      return <FailedToCloseFilter key={index} label={label} />
    });

    return (
      <Card.Body>
        <T>Showing only</T>: {failedToCloseFilters}
      </Card.Body>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state, props),
    filter: getFailedToCloseFilter(state, props),
    filteredActive: getFilteredActive(state, props)
  };
};
export default withRouter(connect(mapStateToProps)(ActiveFilters));
