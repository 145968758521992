import React from "react";
import axios from "axios";

import Loading from "components/Loading";

import ReactHlsPlayer from "react-hls";

import conxtdOut from "apis/conxtdOut";

import './EagleEyeLiveView.scss';

// import poster from './5.gif';

class VideoLoftLiveView extends React.Component {

    state = {
        url: null,
    }

    // componentDidMount() {
    //     this.fetchUser(this.state.username);
    //  }

    //  function fetchUser(username) {
    //     let url = `https://api.github.com/users/${username}`;
    //     this.fetchApi(url);
    //  };

    //Example response from VideoLoft 
    // {
    //     "result": {
    //         "1153349": {
    //             "devices": {
    //                 "3": {
    //                     "uidd": "1153349.3",
    //                     "phonename": "hikvision 8403 (CA Yg)",
    //                     "phoneid": "DS-2CD2086G2-IU20200704AAWRE55978403-CA",
    //                     "onvifUuid": null,
    //                     "adaptrCloudId": "Nc9LYg",
    //                     "permissions": [
    //                         "a"
    //                     ],
    //                     "invited": null,
    //                     "accepted": null,
    //                     "rejected": null,
    //                     "wowza": "euwest1-wowza-1626450006-i-0ad07539b28abe4ce.manything.com",
    //                     "logger": "euwest1-logger-1625235260-i-096f43d8454ba0b4e.manything.com",

    componentDidMount() {
        // const { camera_ref, stream_name, data_server, imaging_server, auth_token, src, subdomain, account_id } = this.props;
        const { camera_ref, stream_name, data_server, imaging_server, auth_token, subdomain, account_id } = this.props;

        this.getServers(subdomain, auth_token).then(data => {
            let accountArray = data.data.result;
            let devices = accountArray[account_id].devices;
            let strSplit = camera_ref.split('.');

            let cameraRef = strSplit[1];
            let device = devices[cameraRef];

            // data server
            let logger = device.logger;
            // imaging server
            let wowza = device.wowza;

            axios.get('https://' + logger + '/sendcameratask?uid='
                + camera_ref + '&action=livecommand&token=' + auth_token).then(data => {
                    let i = 1;
                    let getLiveStream = setInterval(() => {
                        if (i === 99 || i === 10) {
                            clearInterval(getLiveStream);
                        }
                        axios.get('https://' + wowza +
                            '/manything/' + stream_name + '/playlist.m3u8?' +
                            'userToken=' + auth_token).then(
                                response => {
                                    this.setState({
                                        url: 'https://' + wowza +
                                            '/manything/' + stream_name + '/playlist.m3u8?' +
                                            'userToken=' + auth_token
                                    });

                                    i = 99;
                                }).catch(
                                    error => {
                                        console.log(error);
                                        i++;
                                    }
                                );
                    }, 1000);
                }).catch(err => {
                    console.log(err);
                });

            if (data_server !== logger || imaging_server !== wowza) {
                conxtdOut.post(`/CameraSudoSites/updateServers/${camera_ref}`, {
                    'imaging_server': wowza,
                    'data_server': logger,
                }).catch(err => {
                    console.log(err);
                });
            }

        }).catch(err => {
            console.log(err);
        });

        // this.getInitCameraTest(data_server, camera_ref, auth_token).catch(err => {
        //         this.getUpdatedServers(subdomain, auth_token).then(data => {
        //             console.log(data);
        //         }).catch(err => {
        //             console.log(err);
        //         })
        //     });

        // axios.get('https://' + data_server + '/sendcameratask?uid='
        //     + camera_ref + '&action=livecommand&token=' + auth_token).then(data => {

        //         axios.get('https://' + data_server + '/cameras/status?uidd=' + camera_ref, {
        //             headers: {
        //                 Authorization: 'ManythingToken ' + auth_token
        //             }
        //         }).then(cameraData => {

        //             let cameraRefArray = camera_ref.split('.');

        //             let choppedCameraRefPre = cameraRefArray[0];
        //             let choppedCameraRefPost = cameraRefArray[1];

        //             let img_serv = cameraData.data.result[choppedCameraRefPre].devices[choppedCameraRefPost].wowza;

        //             let i = 1;
        //             let getLiveStream = setInterval(() => {
        //                 if (i === 99 || i === 10) {
        //                     clearInterval(getLiveStream);
        //                 }
        //                     axios.get('https://' + img_serv +
        //                         '/manything/' + stream_name + '/playlist.m3u8?' +
        //                         'userToken=' + auth_token).then(
        //                             response => {
        //                                 this.setState({
        //                                     url: 'https://' + img_serv +
        //                                         '/manything/' + stream_name + '/playlist.m3u8?' +
        //                                         'userToken=' + auth_token
        //                                 });

        //                                 i = 99;
        //                             }).catch(
        //                                 error => {
        //                                     console.log(error);
        //                                     i++;
        //                                 }
        //                             );
        //                 }, 1000);

        //         }).catch(
        //             err =>
        //                 console.log(err)
        //         )
        //     }).catch(err => {
        //         console.log(err);
        //     })
    }


    shouldComponentUpdate() {
        if (this.state.url === null) {
            return true
        } else {
            return false;
        }
    }

    getInitCameraTest(data_server, camera_ref, auth_token) {
        return axios.get('https://' + data_server + '/sendcameratask?uid='
            + camera_ref + '&action=livecommand&token=' + auth_token)
    };

    getServers(subdomain, auth_token) {
        return axios.get('https://' + subdomain + '/devices/viewerInfo', {
            headers: {
                'Authorization': 'ManythingToken ' + auth_token
            }
        })
    };

    render() {
        const source = this.props.src === 'buttonview' ? '' : 'eagle-eye-live-view-button';

        if (this.state.url === null) {
            return (
                <>
                    <div className={source}>
                        <div className="center-loading">
                            <Loading center />
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className={source}>
                        <ReactHlsPlayer
                            url={this.state.url}
                            controls={true}
                            autoplay={true}
                            muted={true}
                            width={this.props.src === 'buttonview' ? "290" : "390"}
                            // height={this.props.src === 'buttonview' ? "240" : "auto"}
                            height="215"
                        />
                    </div>
                </>

            );
        }
    }
}

export default VideoLoftLiveView;