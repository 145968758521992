// 08/21 people count hourly report
import find from "lodash/find";

const peoplecounthourly = {
  RESULT: { show: false },
  EXPECTED: { show: false },
  ACTUAL:  { show: false },
  DIFFERENCE: { show: false },
  EVENT_TEXT:  { show: false },
  OCCURRENCES: { show: false },
  PERIOD: { show: true },
  COUNT: { show: true },
  VIEW_CHART: { show: true },
  buildExportArray: ({
    data,
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Period",
      "Count",
    ];

    // Add headers
    result.push(headers);

    data.forEach((row) => {
      // Site row with total count for day
      result.push([
        row["custom-id"] || "",
        row["site-name"],
        row.group || "",
        row["site-ref"] || "",
        "Day Total",
        row["count"]
      ]);
    });

    return result;
  },
  buildExportArrayFull: ({
    data,
    sites
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Period",
      "Count",
    ];

    const formatHour = (hour) => {
      if (Number(hour) < 12) {
        hour = Number(hour) + " am";
      } else {
        if (Number(hour) > 12) {
          hour = Number(hour) - 12;
        }
        hour = hour + " pm";
      }
      return hour;
    }

    // Add headers
    result.push(headers);

    data.forEach((row) => {
      // Site row with total count for day
      result.push([
        row["custom-id"] || "",
        row["site-name"],
        row.group || "",
        row["site-ref"] || "",
        "Day Total",
        row["count"]
      ]);

      // Rows with hourly data
      let siteData = find(sites, ["site_ref", row["site-ref"]]);
      if (siteData && siteData.hourly) {
        siteData.hourly.forEach((hour) => {
          result.push([
            row["custom-id"] || "",
            row["site-name"],
            row.group || "",
            row["site-ref"] || "",
            formatHour(hour.hour),
            hour.value
          ]);
        });
      }

    });

    return result;
  },
};

export default peoplecounthourly;