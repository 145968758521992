/**
 * Watcher for loading resources for specified channel
 */

// import { call, take, select } from "redux-saga/effects";
import { call, select } from "redux-saga/effects";
// import * as actions from "services/redux/actions/";
//import { loadState } from "./localStorage";

import {
  loadNotificationsRulesStart,
  loadNotificationsRulesFailed,
  loadNotificationsRulesSuccess,
  loadNotificationsRulesCancel,
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

import conxtdOut from "apis/conxtdOut";

function* loadRules(action) {
  try {
    // get sudo id
    const selectAllState = (state) => state;

    const allState = yield select(selectAllState);
    const enterprise_site_id = allState.sites.current.id;
    const url = `/notifications/rules/alarmsNotifications?enterprise_site_id=${enterprise_site_id}`;

    const result = yield call(conxtdOut.get, url, action.params);
    /*
    console.log("result", result);

    let filter = result.data.integrations;
    console.log("filter", filter);
*/

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

export function* watchLoadRules() {
  yield actionWatcher({
    actions: {
      start: loadNotificationsRulesStart,
      failed: loadNotificationsRulesFailed,
      success: loadNotificationsRulesSuccess,
      cancel: loadNotificationsRulesCancel,
    },
    saga: loadRules,
  });
}
