export const exportExcel = params => {
  return {
    type: "EXPORT_EXCEL",
    ...params,
    // Record the reportTitle from the action
    recordEvent: { propertiesToRecord: ["reportTitle"] }
  };
};

export const exportPdf = params => {
  return {
    type: "EXPORT_PDF",
    ...params,
    // Record the reportTitle from the action
    recordEvent: { propertiesToRecord: ["reportTitle"] }
  };
};

export const exportCsv = params => {
  return {
    type: "EXPORT_CSV",
    ...params,
    // Record the reportTitle from the action
    recordEvent: { propertiesToRecord: ["reportTitle"] }
  };
};
