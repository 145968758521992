import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Button, Col, Form, Modal } from "react-bootstrap";

import { withT } from "services/translation/";

import ErrorBadge from "components/Layout/ErrorBadge";

import LoadingButton from "components/LoadingButton";

import {
    requestEagleEyeConnectStart,
} from "services/redux/actions";
// import ErrorBoundary from "components/Layout/ErrorBoundary";

function AuthorisationModal({ show, hideModal, properties, integration_type, integration_type_string, authorise, loading, error, site_id }) {
    
    const [callError, setCallError] = useState({set: false, theError: {}});

    let errorMessage = '';

    //Leaving this to remove error message at somepoint
    useEffect(() => {
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    return () => {};
  }, [])

    let onSubmit = e => {
        e.preventDefault();

        authorise({
            email: e.target.email.value,
            password: e.target.password.value,
            integration_id: properties.integration_id,
            site_id: site_id,
            expired: e.target.expired.value
        });
    }

    if (integration_type !== 'eagle_eye') {
        integration_type = properties.whitelist_class_name !== null ? 'chubbview' : 'video_loft';
        integration_type_string = properties.whitelist_class_name !== null ? 'Chubbview' : 'Videoloft';
    }

    if (Object.keys(error).length !== 0) {
        if (callError.set === false) {
            setCallError({
                set: true,
                theError: error
            });
        }
    }

    if (Object.keys(callError.theError).length > 0) {
        errorMessage = callError.theError.status === 422 ?
        'Username or password invalid. Try again' : callError.message
    }

    return (
        <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
            <Modal.Title>
                <Col sm={8} className="m-auto">
                    <img className="d-block mx-auto img-fluid w-100" src={properties.logo} alt={properties.integration_name} />
                </Col>
            </Modal.Title>
        </Modal.Header>
        {
            Object.keys(callError.theError).length > 0 &&
            <ErrorBadge message={errorMessage} source={'eec'} />
        }
        <Form
            onSubmit={onSubmit}
        >
            <Modal.Body>
                <Form.Group controlId="formVideoLoftUsername">
                    <Form.Label>{`${integration_type_string} Username`}</Form.Label>
                    <Form.Control type="email" placeholder={`Enter ${integration_type_string} Username`} name="email"></Form.Control>
                </Form.Group>
                <Form.Group controlId="formVideoLoftPassword">
                    <Form.Label>{`${integration_type_string} Password`}</Form.Label>
                    <Form.Control type="password" placeholder={`Enter ${integration_type_string} Password`} name="password"></Form.Control>
                    <Form.Control type="hidden" value={properties.active_on_site && properties.camera_integration_site.auth_expired ? 'expired' : ''} name="expired"></Form.Control>
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" className="mr-2" onClick={hideModal}>Cancel</Button>
                <LoadingButton
                    className="btn btn-primary float-right"
                    type="submit"
                    loading={loading}
                >
                    Connect
                </LoadingButton>
            </Modal.Footer>
        </Form>
    </Modal>
    );
}

const mapStateToProps = (state, props) => {
    return {
        site_id: state.app.router.params.site_id,
        loading: state.sites.current.integrations.actions.connectEagleEye.loading !== false,
        error: state.sites.current.integrations.actions.connectEagleEye.error,
        success: state.sites.current.integrations.actions.connectEagleEye.success
    };
}
const mapDispatchToProps = { authorise: requestEagleEyeConnectStart };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withT(AuthorisationModal));