import React from "react";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import CircleIcon from "components/Icons/CircleIcon";

import get from "lodash/get";

import "./FilterSelect.scss";

class FilterSelect extends React.Component {
  render() {
    const { options, value, key = "typeaheadInput", onChange } = this.props;
    return (
      <Typeahead
        ref={r => {
          this.Typeahead = r;
        }}
        clearButton
        id="typeahead"
        inputProps={{
          key,
          id: "typeahead-input",
          autoComplete: "off"
        }}
        // inputProps={{
        //   value
        // }}
        value={value}
        selected={(value && [{ name: value }]) || []}
        //onChange={event => onChange(event.value)}
        // defaultInputValue={value}
        // onInputChange={text => {
        //   onChange(text);
        // }}
        onChange={e => {
          onChange(get(e, "0.name") || "");
        }}
        // defaultSelected={options.slice(0, 5)}
        renderMenuItemChildren={(option, props, index) => {
          // console.log(props);
          if (option.name === "With conversation") {
            return (
              <div className="filter-item">
                <CircleIcon type={option.icon} color={option.color} size="24" />
                <span className="pl-2" style={{zIndex: "2000"}}>{option.name}</span>
              </div>
            );
          } else {
            return (
              <div>
                <CircleIcon type={option.icon} color={option.color} size="24" />
                <span className="pl-2">{option.name}</span>
              </div>
            );
          }
        }}
        labelKey="name"
        // multiple
        options={options}
        // placeholder="Choose a state..."
      />
    );
  }
}
export default FilterSelect;
