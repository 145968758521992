/**
 * 09/21 - open close by area
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { default as T } from "services/i18n/Translate";

import FilterButton from "./FilterButton";

import {
  getConfig,
  getFiltered,
} from "services/redux/selectors/reports/common/";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import { getSudoStates } from "services/redux/selectors";

import "components/Analytics/Filters/SuggestedFilters/SuggestedFilters.scss";

class SuggestedFilters extends React.Component {
  render() {
    const { user, filtered, config, getEventMetaById, sudoStates } = this.props;

    let suggestedFilters = [];
    if (user.environment === "mars" || user.environment === "dockerbackend") {
      suggestedFilters = config.dataTable.suggestedFiltersAlternates.mars;
    } else if (user.environment === "staging") {
      suggestedFilters = config.dataTable.suggestedFiltersAlternates.staging;
    } else {
      suggestedFilters = config.dataTable.suggestedFilters;
    }

    if (suggestedFilters.length === 0) return null;

    // Get only filters that apply to the "perimeter" and "area-state" columns
    const filters = filtered.filter((filter) => ((filter.id === "perimeter") || (filter.id === "area-state")));

    let activeFilters = 0;

    const FilterButtons = suggestedFilters.map(
      (event_id, index) => {
        const event = getEventMetaById(event_id);

        let eventCount = sudoStates.filter(
          (sudoState) => {
            return sudoState.perimeter_event_meta.id === event_id;
          }
        ).length;

        eventCount += sudoStates.filter(
          (sudoState) => {
            const area_states = sudoState.area_states;
            if (area_states && (area_states.length > 0)) {
              for (let i=0; i<area_states.length; i++) {
                if (area_states[i].area_state_event_meta && area_states[i].area_state_event_meta.id === event.id) {
                  return true;
                }
              }
            }
            return false;
          }
        ).length;

        // This filter is currently applied
        if (
          filters.length > 0 &&
          eventCount > 0 &&
          filters.filter((filter) => filter.value === event.caption).length >
            0
        ) {
          activeFilters++;
          return (
            <FilterButton key={index} event={event} eventCount={eventCount} />
          );
        }

        return (
          <FilterButton
            className="inactive"
            key={index}
            event={event}
            eventCount={eventCount}
          />
        );
      }
    );

    return (
      <div style={{padding: "0.7rem 1rem 0.7rem 0.6rem"}}>
        <h6
          className={
            "my-0 suggested-filters" +
            (activeFilters > 0 ? " active-filters" : "")
          }
        >
          <T>Suggested Filters</T>:{FilterButtons}
        </h6>
      </div>
    );
  }
}
SuggestedFilters.propTypes = {
  config: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state, props),
    user: state.user,
    getEventMetaById: createGetEventMetaById(state, props),
    filtered: getFiltered(state, props),
    sudoStates: getSudoStates(state, props),
  };
};
export default connect(mapStateToProps)(SuggestedFilters);
