// Integration Location
//Actions
export const loadIntegrationLocationsStart = params => {
    return { 
        type: "LOAD_INTEGRATION_LOCATIONS_START", 
        ...params
    };
}
export const loadIntegrationLocationsFailed = error => {
    return { 
        type: "LOAD_INTEGRATION_LOCATIONS_FAILED", 
        ...error
    };
}
export const loadIntegrationLocationsSuccess = response => {
    return { 
        type: "LOAD_INTEGRATION_LOCATIONS_SUCCESS", 
        ...response
    };
}
export const loadIntegrationLocationsCancel = response => {
    return { 
        type: "LOAD_INTEGRATION_LOCATIONS_CANCEL", 
        ...response
    };
}

// Add location to a site
export const requestAddIntegrationLocationStart = params => {
    return { 
        type: "REQUEST_ADD_INTEGRATION_LOCATION_START", 
        ...params 
    };
};
export const requestAddIntegrationLocationFailed = error => {
    return { 
        type: "REQUEST_ADD_INTEGRATION_LOCATION_FAILED", 
        ...error 
    };
};
export const requestAddIntegrationLocationSuccess = response => {
    return {
        type: "REQUEST_ADD_INTEGRATION_LOCATION_SUCCESS",
        ...response
    };
};
export const requestAddIntegrationLocationCancel = response => {
    return {
        type: "REQUEST_ADD_INTEGRATION_LOCATION_CANCEL",
        ...response
    };
};