import React, { useState } from "react";
import PropTypes from "prop-types";

import { Card, Col, Row, Button } from "react-bootstrap";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";
import CircleIcon from "components/Icons/CircleIcon";

import CustomBundleSelect from "./CustomBundleSelect";

const allEventsProps = {
  bundle_id: 2,
  class_id: -1,
  colour_id: -1,

  translationKey: "all_events",
  color: "#27ae60",
  colSize: 12,
  iconClassName: "fas fa-bars"
};
const suggestionCards = [
  {
    id: 0,
    class_id: 14,
    colour_id: -1,

    translationKey: "conxtd_reminders",
    colSize: 4,
    color: "#8e44ad",
    iconClassName: "far fa-clock"
  },
  {
    id: 0,
    class_id: 10,
    colour_id: -1,

    translationKey: "confirmed_events",
    colSize: 4,
    color: "#c0392b",
    iconClassName: "fas fa-exclamation-triangle"
  },
  {
    bundle_id: 18,
    class_id: -1,
    colour_id: -1,

    translationKey: "communication_events",
    colSize: 4,
    color: "#e67e22",
    iconClassName: "fas fa-wifi"
  },
  {
    //  bundle_id: 99,
    class_id: 11,
    colour_id: -1,

    translationKey: "faults",
    colSize: 4,
    color: "#e67e22",
    conxtdIcon: "spanner"
  },
  {
    bundle_id: 19,
    class_id: -1,
    colour_id: -1,

    translationKey: "engineer",
    colSize: 4,
    color: "#777777",
    conxtdIcon: "engineer"
  },
  {
    bundle_id: 1,
    class_id: -1,
    colour_id: -1,

    translationKey: "open_close",
    colSize: 4,
    color: "#3498db",
    conxtdIcon: "unlock"
  }
];

function BundleCard({
  selected,
  selectBundle,
  translationKey,
  color,
  colSize,
  iconClassName = null,
  conxtdIcon = null,

  bundle_id,
  class_id,
  colour_id,

  t
}) {
  const icon = conxtdIcon ? (
    <CircleIcon
      type={conxtdIcon}
      // color={color}
      foregroundColor={color}
      size="60"
    />
  ) : (
    <Icon
      className={iconClassName}
      style={{ fontSize: "30px", height: "45px", color }}
    />
  );

  return (
    <Col md={colSize}>
      <Card
        onClick={() =>
          selectBundle({
            bundle_id,
            class_id,
            colour_id,
            name: t(translationKey)
          })
        }
        className={`channel-card toggle-card bg-light d-flex ${
          selected ? "selected" : ""
        }`}
        style={{
          cursor: "pointer",
          minHeight: "111px"
        }}
      >
        <div className="my-auto px-3">
          <Row>
            <Col md="12" className="text-center">
              {icon}
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <Card.Subtitle style={{ fontSize: "14px" }}>
                {t(translationKey)}
              </Card.Subtitle>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
}

function BundleSuggestions({ bundle, selectBundle, t }) {
  const allEvents = (
    <BundleCard
      {...allEventsProps}
      selected={
        allEventsProps.bundle_id === bundle.bundle_id &&
        allEventsProps.class_id === bundle.class_id
      }
      selectBundle={selectBundle}
      t={t}
    />
  );
  const suggestions = suggestionCards.map((card, index) => (
    <BundleCard
      key={index}
      {...card}
      selected={
        card.bundle_id === bundle.bundle_id && card.class_id === bundle.class_id
      }
      selectBundle={selectBundle}
      t={t}
    />
  ));

  return (
    <>
      <Row>{allEvents}</Row>
      <Row>
        <Col className="header-title">{t("suggestions_title")}</Col>
      </Row>
      <Row>{suggestions}</Row>
    </>
  );
}
/**
 * Select notification alarm bundle
 */
function SelectBundle({ bundle, selectBundle, t }) {
  const [customBundle, setCustomBundle] = useState(false);

  return (
    <>
      <Row>
        <Col className="header-title">
          {t("notifications_title")}
          {customBundle ? (
            <Button
              size="sm"
              className="ml-2"
              onClick={() => {
                setCustomBundle(false);
              }}
            >
              {t("suggestions")}
            </Button>
          ) : (
            <Button
              size="sm"
              className="ml-2"
              onClick={() => {
                setCustomBundle(true);
              }}
            >
              {t("customise")}
            </Button>
          )}
        </Col>
      </Row>

      {customBundle ? (
        <CustomBundleSelect {...{ bundle, selectBundle }} />
      ) : (
        <BundleSuggestions {...{ bundle, selectBundle, t }} />
      )}
    </>
  );
}
SelectBundle.propTypes = {
  /**
   * @param bundle Selected bundle ID
   */
  bundle: PropTypes.any,
  /**
   * @param selectBundle bundle selector function
   */
  selectBundle: PropTypes.func.isRequired,
  /**
   * @param t translation function
   */
  t: PropTypes.func.isRequired
};
export default withT(
  SelectBundle,
  "site_overview.side_pane.manage_notifications.add_rule.select_bundle"
);
