import React from "react";
import { connect } from "react-redux";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file 

import { Button } from "react-bootstrap";

import { weekStart } from "services/locale/constants";

import { loadEventMetaBackgroundStart } from "services/redux/actions/";

import { loadSitesBackgroundStart } from "services/redux/actions/sites";

import { 
  loadUserGeneratedReportStart, 
  resetUserGeneratedReportExpanded,
} from "services/redux/actions/customReports";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getGroupOptions, getSiteOptions, createGetSitesOptionsByGroup } from "services/redux/selectors/customReports";

import DataTable from "./DataTable";

import DropdownTreeSelectCustom from "components/Common/DropdownTreeSelect/DropdownTreeSelectCustom";
import Select from 'react-select';

import Loading from "components/Loading";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

import "./UserGeneratedReports.scss";

const WEEK_START = weekStart();

// const objToString = (obj, indent = 0) => {
//   let indentString = "";
//   for (let i = 0; i < indent; i++) {
//     indentString = indentString + " ";
//   }

//   let returnString = "";
//   if (obj) {
//     Object.entries(obj).forEach(([key, value]) => {
//       if (typeof(value) === "object") {
//         returnString = returnString + `${indentString}${key}: {\n${objToString(value, indent + 2)}${indentString}}\n`;
//       } else {
//         returnString = returnString + `${indentString}${key}: ${value}\n`;
//       }
//     })
//   }

//   return returnString;
// }

class UserGeneratedReports extends React.Component {
  state = {
    reportOptions: [
      { label: "Open", value: "open" },
      { label: "Close", value: "close" },
      { label: "Fire", value: "fire" },
      { label: "Overnight", value: "overnight" },
      { label: "Suspicious", value: "suspicious" },
    ],
    selectedReportOption: { label: "Open", value: "open" },
    selectionRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    ],
    // group options come in directly on the props
    siteOptions: [], // site options need to be stored in local state as they change according to group selection and when clearing selections
    selectedGroupOption: null,
    selectedSiteOptions: null,
  }

  componentDidMount() {
    const { enterprise, group, siteOptions, getSiteOptionsByGroup } = this.props;

    this.props.dispatch(
      loadEventMetaBackgroundStart({})
    );

    this.props.dispatch(
      loadSitesBackgroundStart({
        enterprise_id: enterprise.id,
      })
    );

    const groupId = group ? group.id : null;
    if (groupId) {
      this.setState({ siteOptions: [ ...getSiteOptionsByGroup(groupId) ] });
    } else {
      this.setState({ siteOptions: [ ...siteOptions ] });
    }
  }

  componentDidUpdate(prevProps) {
    const { siteOptions, getSiteOptionsByGroup } = this.props;

    if (!_.isEqual(siteOptions, prevProps.siteOptions)) {
      const groupId = this.state.selectedGroupOption;
      if (groupId) {
        this.setState({ siteOptions: [ ...getSiteOptionsByGroup(groupId) ] });
      } else {
        this.setState({ siteOptions: [ ...siteOptions ] });
      }
    }
  }

  // Group Selection
  handleGroupSelectionChange = (selectedNodes) => {
    let groupId = selectedNodes[0] ? selectedNodes[0].value : null;
    this.setState({ selectedGroupOption: groupId });
    
    // filter the sites offered according to the selected group
    if (groupId === this.props.enterprise.root_group_id) {
      groupId = null;
    }
    this.setState({ siteOptions: this.props.getSiteOptionsByGroup(groupId) });
    this.setState({ selectedSiteOptions: null });
  };

  // Site Selection
  handleSiteSelectionChange = (selectedNodes) => {
    this.setState({ selectedSiteOptions: selectedNodes ? selectedNodes : null });
  };

  handleSiteSelectionClearAll = () => {
    this.setState({ selectedSiteOptions: null });
  }

  handleGetReportClick = () => {
    const { enterprise, group } = this.props;
    const { selectedReportOption, selectionRange, selectedGroupOption, selectedSiteOptions } = this.state;

    let group_id = selectedGroupOption || (group && group.id) || enterprise.root_group_id;
    if (group_id === "root") {
      group_id = enterprise && enterprise.root_group_id;
    }

    this.props.dispatch(
      resetUserGeneratedReportExpanded({})
    );

    this.props.dispatch(
      loadUserGeneratedReportStart({ 
        enterprise_id: enterprise.id,
        group_id, 
        params: {
          "report": selectedReportOption.value,
          "start_date": new moment(selectionRange[0].startDate).set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
          "end_date": new moment(selectionRange[0].endDate).set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
          "sites": selectedSiteOptions ? selectedSiteOptions.map(siteOption => siteOption.value) : [],
          "type": (document.getElementById("filter-type") && document.getElementById("filter-type").value !== "")
                    ? [(document.getElementById("filter-type").value).toUpperCase()]
                    : null,
        }
      })
    );
  }

  render() {
    const {
      enterprise,
      loadingUserGeneratedReport,
      groupOptions,
      // rawReportData,
    } = this.props;

    const {
      reportOptions,
      selectedReportOption,
      selectionRange,
      siteOptions,
    } = this.state;

    // No enterprises loaded yet
    if (!enterprise) {
      return null;
    };

    let firstDayOfWeek = WEEK_START;
    if (enterprise.calendar_config && (enterprise.calendar_config === "advanced") && (enterprise.start_day || (enterprise.start_day === 0))){
      firstDayOfWeek = enterprise.start_day;
    }
    if (enterprise.calendar_config && (enterprise.calendar_config === "custom") && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))){
      firstDayOfWeek = enterprise.first_day_of_year;
    }

    let minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 24);
    minDate.setDate(1);
    const maxDate = new Date(); //today

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="card m-b-30 visual-data">
              <div className="card-body">
                <label className="d-block">Date Range</label>
                <DateRangePicker
                  editableDateInputs={true}
                  onChange={dateRange => {
                    this.setState({ selectionRange: [dateRange.selection] })}
                  }
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  ranges={selectionRange}
                  months={2}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  calendarFocus="backwards"
                  weekStartsOn={firstDayOfWeek}
                  // weekdayDisplayFormat="EEEEEE"
                  minDate={minDate}
                  maxDate={maxDate}
                />
                <div className="row" style={{ maxWidth: "930px" }}>
                  <div className="col-5">
                    <label className="d-block pt-3">Report</label>
                      <Select
                        options={reportOptions}
                        value={selectedReportOption}
                        className="mb-3"
                        onChange={(e) => {
                          this.setState({ selectedReportOption: _.find(reportOptions, ['value', e.value]) })
                        }}
                      />
                  </div>
                </div>
                <div className="row" style={{ maxWidth: "930px" }}>
                  <div className="col-5">
                    <label className="d-block pt-3">Group</label>
                    <DropdownTreeSelectCustom
                      options={groupOptions} 
                      onChange={this.handleGroupSelectionChange} 
                      mode="radioSelect"
                    />
                    <label className="d-block pt-3">Sites</label>
                    <div className="d-flex with-clear-all">
                      <DropdownTreeSelectCustom
                        options={siteOptions} 
                        onChange={this.handleSiteSelectionChange} 
                        clearable
                        onClearAll={this.handleSiteSelectionClearAll}
                        mode="multiSelect"
                      />
                    </div>
                    {
                      (selectedReportOption.value === "open" || selectedReportOption.value === 'close')
                        ? <div>
                          <label className="d-block pt-3">Type</label>
                          <input className="form-control" type="text" id="filter-type"></input>
                        </div>
                        : <div style={{height: "81.5px"}}></div>
                    }
                  </div>
                </div>
                <div className="pt-4">
                  <Button
                    variant="primary"
                    onClick={this.handleGetReportClick}
                  >Generate</Button>
                  <span>&nbsp;&nbsp;{loadingUserGeneratedReport && <Loading left />}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card p-3">
          <pre>
            {objToString(rawReportData)}
          </pre>
        </div> */}
        <div className="row">
          <div className="col-md-12">
            <DataTable />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groupOptions: getGroupOptions(state, props),
    siteOptions: getSiteOptions(state, props),
    getSiteOptionsByGroup: createGetSitesOptionsByGroup(state, props),
    loadingUserGeneratedReport: state.customReports.userGeneratedReport.loadingUserGeneratedReport,
    // rawReportData: state.customReports.userGeneratedReport.data,
  };
};
export default connect(mapStateToProps)(UserGeneratedReports);