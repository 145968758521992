import React from "react";
import { connect } from "react-redux";

import Card from "./Card";

import { withT } from "services/translation/";

import { getCurrentGroup } from "services/redux/selectors/groups";

import Filter from "../Filter";

import { changeGroupOverviewFilter } from "services/redux/actions";

class GroupCards extends React.Component {
  render() {
    const { groups, currentGroup, t } = this.props;
    const { filters } = currentGroup;

    const availableGroups = currentGroup.children.length;
    // const { filter } = this.state;

    const noGroupsComponent =
      // No groups available
      groups.length > 0
        ? t(
            "group_overview.showing_x_of_y_groups",
            groups.length,
            availableGroups
          )
        : t("group_overview.no_groups_found");

    return (
      <>
        <Filter
          filters={filters}
          enableTextFilter={true}
          changeFilter={filter => {
            this.props.dispatch(changeGroupOverviewFilter(filter));
          }}
        />

        <div className="row visual-data">
          <div className="text-muted col-xs-12 col-sm-6 col-xl-4 d-flex pb-3 mb-2 group-card">
            {noGroupsComponent}
          </div>
        </div>

        <div className="row visual-data">
          {groups
            // .filter(({ name }) =>
            //   // Use "contains" filter type
            //   contains({
            //     filter: filter.value,
            //     value: name,
            //     ignoreCase: true
            //   })
            // )
            .map(g => (
              <Card
                colClassName="col-xs-12 col-sm-6 col-xl-4 d-flex pb-3 group-card"
                key={g.id}
                {...g}
              />
            ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentGroup: getCurrentGroup(state)
  };
};
export default connect(mapStateToProps)(withT(GroupCards));
