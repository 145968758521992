// import React, { useEffect, useState } from "react";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Form, Col } from "react-bootstrap";

import Loading from "components/Loading";
import { withT } from "services/translation/";

import EagleEyeButtonView from "components/Common/Cameras/CameraList/EagleEyeButtonView";

import { loadSiteCamerasStart } from "services/redux/actions";

function AddCameraForm({
    selectCamera,
    loadAvailableCameras,
    camera_integration_site_id,
    loading,
    selectedCamera,
    cameras,
    auth_token,
    subdomain,
    account_id,
    t
}) {
    useEffect(() => {
        loadAvailableCameras({ camera_integration_site_id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form.Group controlId="formGroupSelectCamera" as={Col} md="6">
                <Form.Label>{t("available_cameras")}</Form.Label>

                {loading && <Loading />}
                <Form.Control
                    as="select"
                    onChange={e => {
                        const camera_id = e.target.value;

                        selectCamera(
                            cameras.find(camera => camera.camera_id === camera_id)
                        );
                    }}>
                    <option value="-1">{t("placeholder")}</option>
                    {cameras &&
                        cameras.map(({ camera_name, camera_id }, index) => (
                            <option key={index} value={camera_id}>
                                {camera_name}
                            </option>
                        ))}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="formGroupSelectCamera" as={Col} md="6">
                {selectedCamera && (
                    <EagleEyeButtonView
                        subdomain={subdomain}
                        account_id={account_id}
                        auth_token={auth_token}
                        camera_ref={selectedCamera.camera_id}>
                        {t("live_camera_view")}
                    </EagleEyeButtonView>
                )}
            </Form.Group>
        </>
    );
}
AddCameraForm.propTypes = {
    /**
     * @param selectCamera camera selection callback
     */
    selectCamera: PropTypes.func.isRequired,

    /**
     * @param loadAvailableCameras api call to load selectable cameras
     */
    loadAvailableCameras: PropTypes.func.isRequired,

    /**
    * @param camera_integration_site_id sudo site id
    */
    camera_integration_site_id: PropTypes.number.isRequired,

    /**
     * @param cameras list of available cameras from api
     */
    cameras: PropTypes.array.isRequired,

    /**
     * @param selectedCamera the currently selected camera for live view
     */
    selectedCamera: PropTypes.any,

    /**
     * @param loading api loading status
     */
    loading: PropTypes.any,

    /**
     * @param auth_token integration auth token
     */
    auth_token: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        cameras: state.sites.current.integrations.cameras.data,
        loading: state.sites.current.integrations.cameras.loading !== false
    };
};
const mapDispatchToProps = {
    loadAvailableCameras: loadSiteCamerasStart
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withT(
        AddCameraForm,
        "site_overview.side_pane.manage_integrations.providers.eagle_eye.select_camera"
    )
);