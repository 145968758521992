/**
 * Notification rule remove button
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";
import Alert from "components/Common/Alert";

import {
  requestNotificationsRemoveRuleStart,
  requestNotificationsRemoveRuleNotified
} from "services/redux/actions";

import { withT } from "services/translation/";

function RemoveRule({
  id,
  removeRuleSuccess,
  removeRule,
  removingRule,
  userNotified,
  t
}) {
  if (removeRuleSuccess === id) {
    // Reset success status
    userNotified();
    Alert({
      text: t("remove_rule_success"),
      icon: "success"
    });
  }

  return (
    <Button
      data-testid="notifications-remove-rule"
      onClick={() => {
        Alert({
          text: t("confirm_message"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: t("confirm_button"),
          cancelButtonText: t("cancel_button")
        }).then(result => {
          if (result.value) {
            removeRule({ params: { id } });
          }
        });
      }}
      size="sm"
      variant="danger"
      disabled={removingRule}>
      <Icon data-testid="remove-icon" className="fas fa-times" />
    </Button>
  );
}
RemoveRule.propTypes = {
  /**
   * @param id the id of the rule to be deleted
   */
  id: PropTypes.number.isRequired,

  /**
   * @param removingRule rule removal loading status
   */
  removingRule: PropTypes.any,

  /**
   * @param removeRuleError error status for rule removal
   */
  removeRuleError: PropTypes.object.isRequired,

  /**
   * @param removeRuleSuccess success status for rule removal
   */
  removeRuleSuccess: PropTypes.any,

  /**
   * @param removeRule rule removal api action
   */
  removeRule: PropTypes.func.isRequired,

  /**
   * @param t translation function
   */
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  removeRule: requestNotificationsRemoveRuleStart,
  userNotified: requestNotificationsRemoveRuleNotified
};
const mapStateToProps = state => {
  return {
    removingRule: state.notifications.rules.actions.remove.loading === true,
    removeRuleError: state.notifications.rules.actions.remove.error,
    removeRuleSuccess: state.notifications.rules.actions.remove.success
  };
};

export default withT(
  connect(mapStateToProps, mapDispatchToProps)(RemoveRule),
  "site_overview.side_pane.manage_notifications.remove_rule"
);
