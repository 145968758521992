import { call, takeLeading, put } from "redux-saga/effects";

import conxtdOut from "apis/conxtdOut";

import {
  requestRemoveKeyholderStart,
  requestRemoveKeyholderFailed,
  requestRemoveKeyholderSuccess,
  requestRemoveKeyholderCancel,
  loadSiteKeyholdersStart
} from "services/redux/actions";

import { actionWatcher } from "services/redux/sagas/common/";

function* watchRemoveKeyholder() {
  // Reload keyholders on successful remove
  yield takeLeading("REQUEST_REMOVE_KEYHOLDER_SUCCESS", function*(action) {
    yield put(
      loadSiteKeyholdersStart({
        id: action.startAction.params.enterprise_site_id
      })
    );
  });

  yield actionWatcher({
    actions: {
      start: requestRemoveKeyholderStart,
      failed: requestRemoveKeyholderFailed,
      success: requestRemoveKeyholderSuccess,
      cancel: requestRemoveKeyholderCancel
    },
    saga: function*(action) {
      yield call(
        conxtdOut.post,
        "/EnterpriseKeyholders/remove",
        action.params
      );
    }
  });
}

export { watchRemoveKeyholder };
