import { call, take, race, put, takeLeading } from "redux-saga/effects";

import {
  requestUserAcceptInviteSuccess,
  requestUserAcceptInviteFailed,
  loginSuccess
} from "services/redux/actions";

// import acceptInviteMock from "./acceptInvite.mock.json";

import conxtdOut from "apis/conxtdOut";

export function* watchAcceptInvite() {
  // Only takes the first action and blocks until
  // it is done
  yield takeLeading("REQUEST_USER_ACCEPT_INVITE_START", function*(action) {
    yield race({
      task: call(requestAcceptInvite, action),
      cancel: take([
        "REQUEST_USER_ACCEPT_INVITE_CANCEL",
        "REQUEST_USER_ACCEPT_INVITE_FAILED",
        "CANCEL_ALL"
      ])
    });
  });
}

function* requestAcceptInvite(action) {
  const { token } = action;
  if (!token) {
    throw new Error("Invalid value supplied for token: " + token);
  }

  try {
    // Create a temporary cookie for environment
    conxtdOut.updateSession({ environment: conxtdOut.defaultEnv });

    // const response = yield call(() => {
    //   return { data: acceptInviteMock };
    // });
    const response = yield call(conxtdOut.post, "/users/accept", {
      token
    });

    // Update the login cookie - we now have a login token
    // and some user info
    const user = { ...response.data, environment: conxtdOut.defaultEnv };
    conxtdOut.updateSession(user);

    yield put(requestUserAcceptInviteSuccess({ ...response.data }));

    yield put(loginSuccess({ user }));
  } catch (error) {
    // Destroy the temporary cookie from above
    conxtdOut.logout();
    yield put(
      requestUserAcceptInviteFailed({
        error: {
          message: error.message,
          status: error.response && error.response.status,
          statusText: error.response && error.response.statusText
        },
        ...action
      })
    );
  }
}
