import moment from "services/locale/momentInit.js";

export function getWeekRange(date) {
  return {
    from: moment(date)
      .startOf("isoWeek")
      .toDate(),
    to: moment(date)
      .endOf("isoWeek")
      .toDate()
  };
}

export const getNow = () => new moment();
