export const Types = {
  CREATE_COMMENT: "comments/create_comment",
};

export const createComment = ({ text }) => ({
  type: Types.CREATE_COMMENT,
  payload: {
    text,
  },
});
