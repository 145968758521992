export const requestEagleEyeConnectStart = params => {
    return {
        type: "REQUEST_EAGLE_EYE_CONNECT_START",
        ...params
    };
};

export const requestEagleEyeConnectFailed = params => {
    return {
        type: "REQUEST_EAGLE_EYE_CONNECT_FAILED",
        ...params
    };
};

export const requestEagleEyeConnectSuccess = params => {
    return {
        type: "REQUEST_EAGLE_EYE_CONNECT_SUCCESS",
        ...params
    };
};

export const requestEagleEyeConnectCancel = params => {
    return {
        type: "REQUEST_EAGLE_EYE_CONNECT_CANCEL",
        ...params
    };
};