/**
 * Returns type text for occurrence based on bool values:
 *
 * is_suspicious, is_sandwich_buffer, not_expected
 *
 * param * occurrence a report occurrence
 */
export const getType = occurrence => {
  const { open_type } = occurrence;

  switch (open_type) {
    case "sandwich_buffer":
      return "Acceptable activity";
    case "suspicious_activity":
      return "Suspicious activity";
    case "not_expected":
      return "Not expected";
    default:
      return "";
  }
};
/**
 * Returns event colour for occurrence based on open_type:
 *
 * suspicious, sandwich_buffer, not_expected
 *
 * param * occurrence a suspicious activity report occurrence
 */
export const getEventColour = occurrence => {
  const { open_type } = occurrence;

  switch (open_type) {
    case "sandwich_buffer":
      return "#E67F22";
    case "suspicious_activity":
      return "#962D23";
    // case "not_expected":
    //   return "Not expected"
    default:
      return "#666666";
  }
};

/**
 * Returns type key for occurrence based on bool values:
 *
 * is_suspicious, is_sandwich_buffer, not_expected
 *
 * param * occurrence a report occurrence
 */
export const getTypeKey = occurrence => {
  const { is_suspicious, is_sandwich_buffer, not_expected } = occurrence;

  return is_sandwich_buffer
    ? "sandwich_buffer"
    : is_suspicious
    ? "suspicious_activity"
    : not_expected
    ? "not_expected"
    : "";
};
