import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import EventOverviewWidgetGroup from "./EventOverviewWidgetGroup";

import { withT } from "services/translation/";

import ProgressBar from "components/Enterprise/Reports/Common/ProgressBar";

// import REPORTS from "constants/REPORTS";
import {
  getPairBundles,
  getOutstandingReport
} from "services/redux/selectors/reports/outstanding/index";

import { getConfig } from "services/redux/selectors/reports/common/";

/**
 * Outstanding Reports / PageLayout
 */
class PageLayout extends React.Component {
  render() {
    const { config, pair_bundles, t } = this.props;
    const { nextRefresh, lastRefresh, loading } = this.props.outstanding;

    const noDataAvailable = loading === false && pair_bundles.length === 0;

    const bundles = noDataAvailable
      ? config.noDataAvailableRenderer()
      : pair_bundles.map(bundle => {
          return <EventOverviewWidgetGroup key={bundle.id} bundle={bundle} />;
        });

    return (
      <div className={this.props.className}>
        <div className="page-title-box pt-0">
          <div className="row align-items-center">
            <div className="col-md-4 text-center text-sm-left">
              <h4 className="page-title mb-2 mt-0">{t("title")}</h4>
              <div className="text-muted font-14">{t("description")}</div>
            </div>

            <div style={{ fontSize: "12px" }} className="col-md-4 p-4">
              <div className="text-center">
                <ProgressBar
                  placement="top"
                  nextRefresh={nextRefresh}
                  lastRefresh={lastRefresh}
                />
              </div>
            </div>
            <div className="col-md-4 d-none d-sm-block"> </div>
            {bundles}
          </div>
        </div>
      </div>
    );
  }
}

PageLayout.propTypes = {
  pair_bundles: PropTypes.array.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    pair_bundles: getPairBundles(state, props),
    outstanding: getOutstandingReport(state, props),
    config: getConfig(state, props)
  };
};
export default withT(
  connect(mapStateToProps)(PageLayout),
  "reports.outstanding.index"
);
