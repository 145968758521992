import { devMode } from "services/redux/selectors/";

const API_KEY = devMode()
  ? "2dc80ffe21197d6c7f8652486bd71f67a2c520ba4cf31b6db183c0d22e745ca5"
  : "3dbf9e4734068cb4dcdf10fbdc6fb7a3794932596f0b2dc50d6e8b0b0fb4fd3f";

const CALLBACK_URL = devMode()
  ? encodeURIComponent("https://pro-staging.conxtd.com/oauth/tether")
  : encodeURIComponent("https://app.conxtd.com/oauth/tether");

export const TETHER_AUTHORISE_URI = `https://my.timeline.is/oauth/authorize?client_id=${API_KEY}&redirect_uri=${CALLBACK_URL}&response_type=code`;
