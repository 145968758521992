import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  loadEventPairsStart,
  loadEventPairsSuccess,
  loadEventPairsFailed,
  loadEventPairsCancel,
} from "services/redux/actions/";

import conxtdOut from "apis/conxtdOut";

export function* watchLoadEventPairs() {

  yield actionWatcher({
    actions: {
      start: loadEventPairsStart,
      failed: loadEventPairsFailed,
      success: loadEventPairsSuccess,
      cancel: loadEventPairsCancel,
    },
    saga: loadEventPairsRe,
  });
}

export function* loadEventPairsRe(action) {
  try {

    const url = `/SudoPins/getEventPairs`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;

  } catch (e) {
    console.error(e);
  }
}