import React from "react";

class Icon extends React.Component {
  render() {
    let className = this.props.className || "";
    if (this.props.icon === "info")
      className = className + " fas fa-info-circle m-1";
    return <i {...this.props} className={className} />;
  }
}
export default Icon;
