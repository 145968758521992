import React from "react";
import { connect } from "react-redux";
import { getRouterParams } from "services/redux/selectors/app/";
import { ListGroup } from "react-bootstrap";
import { default as T } from "services/i18n/Translate";
import Icon from "components/Icons/Icon";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "components/Common/Tooltip/";
import countryList from "react-select-country-list";
import CopyToClipboard from "./CopyToClipboard";

import "./Details.scss";

const SiteInfoCard = ({ title, fields = null, text }) => {
  // const clipboardCell = (id, cellText) => {
  //   return (
  //     <div className="d-flex justify-content-start">
  //       <div
  //         style={{ overflow: "hidden"}}
  //         className="pr-2"
  //       >{cellText}</div>
  //       <div
  //         className="px-1 detail-clipboard"
  //         style={{cursor: "pointer"}}
  //         onClick={() => {
  //           navigator.clipboard.writeText(cellText);
  
  //           const clipboardIcon = document.getElementById(`clipboard-${id}`);
  
  //           clipboardIcon.classList.remove("fa-clipboard");
  //           clipboardIcon.classList.remove("far");
  //           clipboardIcon.classList.add("fa-clipboard-check");
  //           clipboardIcon.classList.add("fas");
            
  //           setTimeout(() => {
  //             clipboardIcon.classList.remove("fa-clipboard-check");
  //             clipboardIcon.classList.remove("fas");
  //             clipboardIcon.classList.add("fa-clipboard");
  //             clipboardIcon.classList.add("far");
  //           }, 200);
  //         }}
  //       >
  //         <Icon
  //           id={`clipboard-${id}`}
  //           className="far fa-clipboard fa-lg text-primary"
  //           title="Copy to clipboard"
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  const content = !fields ? (
    <p>{text}</p>
  ) : (
    <ListGroup variant="flush">
      {fields.map((f, index) => {
        const clipboardFieldId = title.replace(/\s/g, "-").toLowerCase() + "-" + index;
        
        return <ListGroup.Item key={index} className="px-0 detail-field">
          <span className="float-left col-4 px-0">
            <T>{f.name}</T>&#8203;
          </span>
          <span className="float-right col-8 px-0"><CopyToClipboard id={clipboardFieldId} cellText={f.value} /></span>
        </ListGroup.Item>
      })}
    </ListGroup>
  );

  return (
    <>
      {/* <div className="card" style={{ border: "1px solid #ccc" }}> */}
      {/* <div className="card-header"> */}
      <h5>{title}</h5>
      {/* </div> */}
      {content}
    </>
  );
};

class SiteInfo extends React.Component {  
  render() {
    const { site, selectedTab } = this.props;
    let { enterprise_id, group_id } = this.props.router.params;
    // display GB as United Kingdom
    let country = site.country;
    // country = "GB" ? "United Kingdom" : country;
    if (site.country) {
      country = countryList().getLabel(site.country);
    }

    const addressFields = [
      { name: "Company", value: site.company },
      { name: "Address 1", value: site.address1 },
      { name: "Address 2", value: site.address2 },
      { name: "Town", value: site.town },
      { name: "Country", value: country },
      { name: "Postcode", value: site.postcode },
    ];
    const idFields = [
      { name: "Site Ref", value: site.site_ref || site.wwoId },
      {
        name: "Transmitter ID",
        value: site.transmitterId || site.transmitter_ref,
      },
    ];

    return (
      <div id="site-view">
        <div className="col-12">
          <div className="float-right">
            <Link
              to={`/enterprise/${enterprise_id}/${group_id}/enterprise-manager?tab=${selectedTab}`}
              className="waves-link"
            >
              <Tooltip description="Edit in Enterprise Manager">
                <Button size="sm">
                  <Icon className="fas fa-edit" />
                </Button>
              </Tooltip>
            </Link>
          </div>
          <SiteInfoCard title="Address" fields={addressFields} />
        </div>
        <div className="col-12">
          <SiteInfoCard title="IDs" fields={idFields} />
        </div>

        <div className="col-12">
          {site && site.site_fields && site.site_fields.length > 0 ? (
            <SiteInfoCard
              title="Custom Fields"
              fields={site.site_fields.map((details) => ({
                name: details.enterprise_user_field.name,
                value: details.value,
              }))}
            />
          ) : (
            <SiteInfoCard
              title="Custom Fields"
              text="There are no custom fields for this site."
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    router: { params: getRouterParams(state, props) },
    selectedTab: state.enterpriseManagerPersist.selectedTab || "Groups",
  };
};
export default connect(mapStateToProps)(SiteInfo);
