// LOAD SITE KEYHOLDERS
export const loadSiteKeyholdersStart = params => {
  return {
    type: "LOAD_SITE_KEYHOLDERS_START",
    ...params
  };
};
export const loadSiteKeyholdersSuccess = params => {
  return {
    type: "LOAD_SITE_KEYHOLDERS_SUCCESS",
    ...params
  };
};
export const loadSiteKeyholdersFailed = params => {
  return {
    type: "LOAD_SITE_KEYHOLDERS_FAILED",
    ...params
  };
};
export const loadSiteKeyholdersCancel = params => {
  return {
    type: "LOAD_SITE_KEYHOLDERS_CANCEL",
    ...params
  };
};

// EDIT KEYHOLDER
export const requestUpdateKeyholderStart = params => {
  return {
    ...params,
    type: "REQUEST_UPDATE_KEYHOLDER_START"
  };
};
export const requestUpdateKeyholderSuccess = params => {
  return {
    ...params,
    type: "REQUEST_UPDATE_KEYHOLDER_SUCCESS"
  };
};
export const requestUpdateKeyholderFailed = params => {
  return {
    ...params,
    type: "REQUEST_UPDATE_KEYHOLDER_FAILED"
  };
};
export const requestUpdateKeyholderCancel = params => {
  return {
    ...params,
    type: "REQUEST_UPDATE_KEYHOLDER_CANCEL"
  };
};
// ADD KEYHOLDER
export const requestAddKeyholderStart = params => {
  return {
    ...params,
    type: "REQUEST_ADD_KEYHOLDER_START"
  };
};
export const requestAddKeyholderSuccess = params => {
  return {
    ...params,
    type: "REQUEST_ADD_KEYHOLDER_SUCCESS"
  };
};
export const requestAddKeyholderFailed = params => {
  return {
    ...params,
    type: "REQUEST_ADD_KEYHOLDER_FAILED"
  };
};
export const requestAddKeyholderCancel = params => {
  return {
    ...params,
    type: "REQUEST_ADD_KEYHOLDER_CANCEL"
  };
};
export const requestAddKeyholderNotified = params => {
  return {
    ...params,
    type: "REQUEST_ADD_KEYHOLDER_NOTIFIED"
  };
};
// REMOVE KEYHOLDER
export const requestRemoveKeyholderStart = params => {
  return {
    ...params,
    type: "REQUEST_REMOVE_KEYHOLDER_START"
  };
};
export const requestRemoveKeyholderSuccess = params => {
  return {
    ...params,
    type: "REQUEST_REMOVE_KEYHOLDER_SUCCESS"
  };
};
export const requestRemoveKeyholderFailed = params => {
  return {
    ...params,
    type: "REQUEST_REMOVE_KEYHOLDER_FAILED"
  };
};
export const requestRemoveKeyholderCancel = params => {
  return {
    ...params,
    type: "REQUEST_REMOVE_KEYHOLDER_CANCEL"
  };
};
export const requestRemoveKeyholderNotified = params => {
  return {
    ...params,
    type: "REQUEST_REMOVE_KEYHOLDER_NOTIFIED"
  };
};
// MOVE KEYHOLDER UP/DOWN
export const requestMoveKeyholderStart = params => {
  return {
    ...params,
    type: "REQUEST_MOVE_KEYHOLDER_START"
  };
};
export const requestMoveKeyholderSuccess = params => {
  return {
    ...params,
    type: "REQUEST_MOVE_KEYHOLDER_SUCCESS"
  };
};
export const requestMoveKeyholderFailed = params => {
  return {
    ...params,
    type: "REQUEST_MOVE_KEYHOLDER_FAILED"
  };
};
export const requestMoveKeyholderCancel = params => {
  return {
    ...params,
    type: "REQUEST_MOVE_KEYHOLDER_CANCEL"
  };
};
