// import React, { useState } from "react";
import React from "react";
import { connect } from "react-redux";

// import { Button, Col, Form, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { withT } from "services/translation/";

// import ErrorBadge from "components/Layout/ErrorBadge";

// import LoadingButton from "components/LoadingButton";

import AuthorisationModal from "components/Common/Cameras/AuthorisationModal";

import {
    requestEagleEyeConnectStart,
    // requestEagleEyeConnectFailed,
    // requestEagleEyeConnectSuccess,
    // requestEagleEyeConnectCancel,
} from "services/redux/actions";

export class VideoLoftConnect extends React.Component {
    onSubmit = e => {
        e.preventDefault();

        this.props.requestEagleEyeConnectStart({
            email: e.target.email.value,
            password: e.target.password.value,
            integration_id: this.props.integration_id,
            site_id: this.props.site_id,
            expired: e.target.expired.value
        });
    }

    state = {
        show: false
    };

    showModal = () => {
        this.setState({
            show: true
        })
    }

    hideModal = () => {
        this.setState({
            show: false,
        })
    }

    render() {

        const { t } = this.props;


        const integration_type = this.props.whitelist_class_name !== null ? 'chubbview' : 'video_loft';
        const integration_type_string = this.props.whitelist_class_name !== null ? 'Chubbview' : 'Videoloft';

        if (this.props.active_on_site && !this.props.camera_integration_site.auth_expired) {
            return <Button disabled>{t("connected")}</Button>;
        }

        // let errorMessage = '';
        // if (Object.keys(this.props.error).length !== 0) {
        //     errorMessage = this.props.error.status === 403 ?
        //         'Username or password invalid. Try again' : this.props.error.message
        // }

        return (
            <>
                {this.props.active_on_site && this.props.camera_integration_site.auth_expired ? (
                    <Button
                        variant="danger"
                        onClick={this.showModal}
                    >
                        <>
                            Reauthorise
                        </>
                    </Button>
                ) : (
                    <Button
                        variant="outline-primary"
                        onClick={this.showModal}
                    >
                        <>
                            {t(`connect_to_${integration_type}`)}
                        </>
                    </Button>
                )}
                {this.state.show &&
                <AuthorisationModal
                    show={this.state.show}
                    hideModal={this.hideModal}
                    properties={this.props}
                    integration_type={integration_type}
                    integration_type_string={integration_type_string}
                />
    }   
                {/* <Modal show={this.state.show} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Col sm={8} className="m-auto">
                                <img className="d-block mx-auto img-fluid w-100" src={this.props.logo} alt={this.props.integration_name} />
                            </Col>
                        </Modal.Title>
                    </Modal.Header>
                    {
                        Object.keys(this.props.error).length !== 0 &&
                        <ErrorBadge message={errorMessage} source={'eec'} />
                    }
                    <Form
                        onSubmit={this.onSubmit}
                    >
                        <Modal.Body>
                            <Form.Group controlId="formVideoLoftUsername">
                                <Form.Label>{t(`${integration_type}_username`)}</Form.Label>
                                <Form.Control type="email" placeholder={`Enter ${integration_type_string} Username`} name="email"></Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formVideoLoftPassword">
                                <Form.Label>{t(`${integration_type}_password`)}</Form.Label>
                                <Form.Control type="password" placeholder={`Enter ${integration_type_string} password`} name="password"></Form.Control>
                                <Form.Control type="hidden" value={this.props.active_on_site && this.props.camera_integration_site.auth_expired ? 'expired' : null} name="expired"></Form.Control>
                            </Form.Group>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" className="mr-2" onClick={this.hideModal}>{t("cancel")}</Button>
                            <LoadingButton
                                className="btn btn-primary float-right"
                                type="submit"
                                loading={this.props.loading}
                            >
                                {t("connect")}
                            </LoadingButton>
                        </Modal.Footer>
                    </Form>
                </Modal> */}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        site_id: state.app.router.params.site_id,
        loading: state.sites.current.integrations.actions.connectEagleEye.loading !== false,
        error: state.sites.current.integrations.actions.connectEagleEye.error,
        success: state.sites.current.integrations.actions.connectEagleEye.success
    };
};

export default withT(connect(mapStateToProps, {
    requestEagleEyeConnectStart,
})(VideoLoftConnect),
    "site_overview.side_pane.manage_integrations.providers.video_loft",
);
