import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getEventMetaForEventPair } from "services/redux/selectors";

import SVGIcon from "components/Icons/SVGIcon";

// import "./EventOverviewWidget.scss";

import { default as T } from "services/i18n/Translate";

import { Card, ProgressBar } from "react-bootstrap";

class EventOverviewWidget extends React.Component {
  render() {
    const {
      name,
      // pair_bundle_id,
      // timed_count,
      total_count,
      id
      // conxtd_event_id
    } = this.props.eventPair;
    const { sites_count, eventMeta } = this.props;

    /*const {
      id,
      caption,
      description,
      emoji,
      colour,
      colour_name,
      icon_url,
      icon
  } = eventMeta;*/
    // const eventMeta = select(this.props.eventMeta, conxtd_event_id);

    const { icon, colour } = eventMeta || {
      icon: "",
      colour: "#000"
    };

    const AlarmIcon =
      eventMeta !== null ? <SVGIcon type={icon} color={colour} /> : null;

    const progress = (total_count / sites_count) * 100;

    // const headerFontSize = name.length > 14 ? "10px" : "16px";

    return (
      <Link
        to={`./bundle/${id}`}
        className="waves-effect card EventOverviewWidget visual-data">
        <Card.Body>
          <div className="conxtd-alarm-icon mb-2">{AlarmIcon}</div>
          <Card.Title
            className="my-2 text-uppercase text-center outstanding-overview-title"
            style={{
              color: colour,
              minHeight: "48px"
              // fontWeight: "bold"
              // fontSize: headerFontSize
            }}>
            <T>{name}</T>
          </Card.Title>
          <div className={`conxtd-alarm-${icon}`}>
            <h4 className="widget-chart text-center" style={{ color: colour }}>
              <div className="conxtd-alarm">
                <div className="conxtd-alarm-counter">{total_count || "0"}</div>
              </div>
            </h4>

            <div className="mt-4 text-center" style={{ color: "#666" }}>
              <ProgressBar style={{ height: "6px" }} now={progress} />
            </div>

            {/* <div
              className="my-2 text-center"
              style={{
                color: colour,
                fontSize: "14px",
                fontWeight: "bold"
              }}>
              <T>Today</T>
            </div>
            <div className="my-1 text-center" style={{ color: "#666" }}>
              <div className="mb-1">
                {total_count || "0"}/{sites_count}
              </div>
              <div className="mb-1">
                <ProgressBar style={{ height: "6px" }} now={progress} />
              </div>
              <div className="mb-1">
                <T>All time</T>
              </div>
            </div> */}
          </div>
        </Card.Body>
      </Link>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    eventMeta: getEventMetaForEventPair(state, props),
    sites_count: state.reports.outstanding.index.data.sites_count
  };
};
export default connect(mapStateToProps)(EventOverviewWidget);
