const FILTER_TAG_COLOURS = {
  mobileAppTagColour: "#8945B9",
  enterpriseTagColour: "#6946AA",
  addressTagColour: "#444798",
  arcMapTagColour: "#28488B",
  installerTagColour: "#28488B",// same as arc map because only one of the two will be used at any one time
  siteRefTagColour: "#2C63A0",
  transmitterRefTagColour: "#307DB6",
  countryTagColour: "#3396CB",
  contractNumberTagColour: "#36B1E1",
  productDescriptionTagColour: "#3ACDF8",
  customTagColours: [
    "#37C8DF",
    "#34C3C8",
    "#31BEAF",
    "#2DB892",
    "#2AB378",
    "#26AD5D"
  ]
}

export default FILTER_TAG_COLOURS;