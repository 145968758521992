import Raphael from "raphael";
import "morris.js/morris.css";
import "morris.js/morris.js";
import React from "react";
import PropTypes from "prop-types";

import ReactResizeDetector from "react-resize-detector";

// This just prevents no-undef errors
const Morris = window.Morris;

/*
const data = [
  { y: "2009", a: 100, b: 90 },
  { y: "2010", a: 75, b: 65 },
  { y: "2011", a: 50, b: 40 },
  { y: "2012", a: 75, b: 65 },
  { y: "2013", a: 50, b: 40 },
  { y: "2014", a: 75, b: 65 },
  { y: "2015", a: 100, b: 90 },
  { y: "2016", a: 90, b: 75 }
];*/
/*
const data = [
  { y: "3AM", early: 3, late: 7, onTime: 5, abnormal: 0 },
  { y: "4AM", early: 3, late: 7, onTime: 5, abnormal: 0 }
];*/

class MorrisChart extends React.Component {
  constructor() {
    super();
    window.Raphael = Raphael;
    this.barStack = null;
  }
  onResize = () => {
    // We need to use a div resize detector to detect when the left nav is collapsed
    // otherwise morris will bug out and won't call it's resize handler (bound to window.resize)
    this.barStack.resizeHandler();
  };
  componentDidMount() {
    const { colors, labels, data, ykeys, xkey } = this.props;
    // console.log(colors);
    // console.log(labels);
    // console.log(data);
    // console.log(ykeys);
    // console.log(xkey);

    //const colors = ["#33B775", "#BE3A31", "#E47E30", "#CCCCCC"];
    //const labels = ["On time", "Late", "Early", "Abnormal"];
    //const ykeys = ["onTime", "late", "early", "abnormal"];
    //const xkey = "y";

    this.barStack = Morris.Bar({
      element: "MorrisChart",
      data: data,
      xkey: xkey,
      ykeys: ykeys,
      labels,
      gridLineColor: "#eef0f2",
      barSizeRatio: 0.4,
      resize: true,
      stacked: true,
      hideHover: "auto",
      barColors: colors,
      yLabelFormat: function(y) {
        return y !== Math.round(y) ? "" : y;
      }
    });
  }

  componentDidUpdate() {
    const { data } = this.props;
    this.barStack.setData(data);
  }
  render() {
    return (
      <div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.onResize}
        />

        <div id="MorrisChart" onClick={this.props.onClick} />
      </div>
    );
  }
}
MorrisChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  ykeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  xkey: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default MorrisChart;
