import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import Alert from "components/Common/Alert";
import Icon from "components/Icons/Icon";
import Select from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';

import { getPinMapEventPairOptions } from "services/redux/selectors/enterpriseManager";

import "./FormElement.scss";

import find from "lodash/find";

const FormElement = ({ darkMode, id, deleteFormElement, eventPairOptions, formElements, setFormElements, disabledButton, mappedData, setInvalid }) => {

  const [pinValue, setPinValue] = useState("");
  const [alarmTypeValue, setAlarmTypeValue] = useState(null);
  const [customTextValue, setCustomTextValue] = useState("");
  const [reverseValue, setReverseValue] = useState(false);
  const [invalidComponent, setInvalidComponent] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");

  useEffect(() => {
    invalidComponent ? setInvalid(true) : setInvalid(false);
  }, [invalidComponent, setInvalid])

  useEffect(() => {
    let foundInState = formElements.filter(element => element.pin === String(pinValue) && element.pin !== "")
    let foundInMappedData = mappedData.find(element => element.pin === Number(pinValue))
    if (foundInMappedData || foundInState.length > 1) {
      setInvalidComponent(true);
    } else {
      setInvalidComponent(false);
    }
    if (foundInMappedData) {
      setFeedbackText("Pin already assigned in database")
    } else if (foundInState) {
      setFeedbackText("Pin already declared in form")
    }
  }, [formElements, mappedData, pinValue])
  
  // sets null properties depending on field input values
  const currentElement = formElements.find(element => element.id === id);
  useEffect(() => {
      setFormElements((prevState) => {
        const foundElement = prevState.find((element) => element.id === id);
        if (foundElement) {
          if (currentElement.pin && currentElement.event_pair_id) {
            foundElement.null = false;
          } else {
            foundElement.null = true;
          }
          return foundElement ? [...prevState] : prevState;
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinValue, alarmTypeValue, customTextValue, reverseValue])

  const deleteButtonRef = useRef(null);
  const handleDelete = () => {
    if (deleteButtonRef.current.classList.contains('disabled')) {
      return
    };
    if (currentElement) {
      if (currentElement.pin || currentElement.event_pair_id || currentElement.customText ) {
        Alert({
          text: `Are you sure you want to delete this pin?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
        }).then(result => {
          if (result.value) {
            deleteFormElement(id);
          } else {
            return
          }
        });
      } else {
        deleteFormElement(id);
      }
    };
  }

  const handleChange = (e, propertyType, selectedEventPairOption) => {
    const foundElement = formElements.find((element) => element.id === id);
    if (foundElement) {
      switch (propertyType) {

        case 'pin':
          foundElement.pin = e.target.value;
          setPinValue(e.target.value);
          break;
      
        case 'alarm':
          foundElement.event_pair_id = selectedEventPairOption.value;
          setAlarmTypeValue( selectedEventPairOption );
          break

        case 'customText':
          foundElement.customText = e.target.value;
          setCustomTextValue(e.target.value)
          break

        case 'reverse':
          foundElement.reverse = e.target.checked;
          setReverseValue(e.target.checked)
          break

        default:
          break;
      }
    }
    return foundElement ? setFormElements((prevState) => [...prevState]) : setFormElements((prevState) => prevState);
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: 'white',
      borderColor: 'transparent',
      backgroundColor: '#13274d',
      '&:hover': {
        backgroundColor: '#b9bfca',
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#13274d',
      borderColor: 'transparent',
      '&:focus-within': {
        borderColor: 'transparent',
        boxShadow: 'none'
      },
      '&:hover': {
        borderColor: 'transparent',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#b9bfca',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: '#13274d',
    }),
  };

  return (
    <>
      <div
        className='container'
        id="form-outer-wrapper"
      >
        <div className='row flex-nowrap'>
          <div className="pl-0 pr-2 col-2">
           <InputGroup hasValidation>
             <Form.Control
              data-intercom-target={"pin-input-field"}
              name="pin"
              component="input"
              type="number"
              min={1}
              max={36}
              placeholder={"Enter pin"}
              required
              isInvalid={invalidComponent}
              className="w-100"
              id="pin-form-element"
              onInput={(e) => {
                if (e.target.value < 1 && e.target.value !== "") {
                  e.target.value = 1;
                } else if (e.target.value > 36 && e.target.value !== "") {
                  e.target.value = 36;
                }
              }}
              onChange={(e) => {
                handleChange(e, "pin")
              }}
            />
            <Form.Control.Feedback type="invalid">
              {feedbackText}
            </Form.Control.Feedback>
          </InputGroup>
        </div>
        <div 
          className="form-element m-0 pl-0 pr-2 col-3"
          id="form-dropdown-menu"
        >
          <Select
            data-intercom-target={"alarm-type-select"}
            name="alarm_type"
            options={eventPairOptions}
            value={alarmTypeValue}
            placeholder="Select alarm type"
            required
            className={`dropdown w-100 ${darkMode ? "darkMode" : ""}`}
            maxMenuHeight={200}
            onChange={(e) => {
              const selectedEventPairOption = e ? find(eventPairOptions, ['value', e.value]) : null;
              handleChange(e, "alarm", selectedEventPairOption);
            }}
            styles={darkMode ? customStyles : ""}
          />
        </div>
        <div className="form-element col-xl-5 col-lg-5 col-md-4 col-sm-4 pl-0">
          <Form.Control
            data-intercom-target={"custom-text-input-field"}
            name="custom_text"
            component="input"
            type="text"
            placeholder={"Enter custom text"}
            className="w-100"
            onChange={(e) => {
              handleChange(e, "customText")
            }}
          />
        </div>
          <div className='container m-0' id="buttons-container">
            <div className="row d-flex justify-content-between m-0 p-0" id="buttons-container-child">
              <div className="form-child-content-element">
                <Form.Control
                  data-intercom-target={"reverse-checkbox"}
                  name="reverse"
                  component="input"
                  type="checkbox"
                  id="reverse"
                  className="w-50"
                  onChange={(e) => {
                    handleChange(e, "reverse")
                  }}
                />
              </div>
              <div className="form-child-content-element">
                <Button
                  data-intercom-target={"delete-pin-row-button"}
                  id="delete-button-custom"
                  ref={deleteButtonRef}
                  onClick={handleDelete}
                  className={`${disabledButton} w-100`}
                >
                  <Icon className="fa fa-times" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    eventPairOptions: getPinMapEventPairOptions(state),
    darkMode: state.app.darkMode,
  };
};

export default connect(mapStateToProps, null)(FormElement);
