import { call } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";

import {
  getUserActionsStart,
  getUserActionsSuccess,
  getUserActionsFailed,
  getUserActionsCancel,
} from "services/redux/actions/enterpriseManager";

// import _ from "lodash";

import conxtdOut from "apis/conxtdOut";

// one-off saga to load user actions

export function* watchGetUserActions() {
  yield actionWatcher({
    actions: {
      start: getUserActionsStart,
      failed: getUserActionsFailed,
      success: getUserActionsSuccess,
      cancel: getUserActionsCancel,
    },
    saga: getUserActionsRe,
  });
}

// https://mars.conxtd.com/out_api/users/user-actions/{{enterprise_id}}
export function* getUserActionsRe(action) {
  try {
    const { enterprise_id } = action;

    if (!enterprise_id) 
      throw new Error(
        "enterprise_id is required for loading user actions"
      );

    const url = `/users/user-actions/${enterprise_id}`;

    const result = yield call(conxtdOut.post, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}