import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { getScheduledFilter } from "services/redux/selectors/reports/scheduled/";
import { getConfig } from "services/redux/selectors/reports/common/";
import { getReportType } from "services/redux/selectors/reports/router";

import { changeCurrentReportFilter } from "services/redux/actions/";

import RemoveFilter from "./RemoveFilter";

class EventTypeFilter extends React.Component {
  render() {
    const { label, filter, config, reportType } = this.props;

    const visible = filter.eventType.show[label];

    const key = label.toLowerCase().replace(" ", "-");
    if (!visible) return null;
    return (
      <span
        key={key}
        className={`${key} text-light`}
        style={{
          backgroundColor:
            config.charts.pie.filter.colors[
              config.charts.pie.filter.keys.indexOf(label)
            ],
          padding: "0.3rem 0.6rem"
        }}>
        {label}{" "}
        <RemoveFilter
          onClick={e => {
            this.props.dispatch(
              changeCurrentReportFilter({
                label,
                reportType,
                filterType: "eventType"
              })
            );
          }}
        />
      </span>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props),
    config: getConfig(state, props),
    filter: getScheduledFilter(state, props)
  };
};
export default withRouter(connect(mapStateToProps)(EventTypeFilter));
