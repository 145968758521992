import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import Loading from "components/Loading";

import moment from "services/locale/momentInit.js";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import { getCurrentSite } from "services/redux/selectors/sites";

import SVGIcon from "components/Icons/SVGIcon";

import TimezoneDate from "components/Common/TimezoneDate";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

class Timeline extends React.Component {
  render() {
    // const { getEventMetaById, id, data, loading, t } = this.props;
    const { getEventMetaById, data, loading, site } = this.props;

    if (loading !== false) {
      return (<Loading center className="mt-2 mb-2" />)
    }

    if (!data) return (
      <div>No Data</div>
    )

    return (
      <div className="row">
        <div className="col-12">
          <VerticalTimeline layout="1-column" className="py-0">
            {data.map(
              (
                {
                  id,
                  acked,
                  conxtd_alarm_id,
                  text,
                  alarm,
                  recvd
                },
                index
              ) => {
                const event = getEventMetaById(conxtd_alarm_id) || {
                  colour: "#707070",
                  icon: "default",
                  caption: "Unknown",
                  description: "",
                };

                const eventMoment = new moment(acked);
                const yesterday = new moment()
                  .startOf("day")
                  .isAfter(eventMoment);

                const beforeYesterday = new moment()
                  .subtract(1, "days")
                  .startOf("day")
                  .isAfter(eventMoment);

                const format = beforeYesterday
                  ? "dddd MMMM Do, YYYY HH:mm"
                  : `[${yesterday ? "Yesterday at" : "Today at"}] HH:mm`;

                return (
                  <VerticalTimelineElement
                    key={index}
                    id={text}
                    className="vertical-timeline-element--work text-white-important py-4 my-2"
                    contentStyle={{
                      borderTop: "3px solid " + event.colour,
                      background: event.colour,
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid " + event.colour,
                    }}
                    // "Today at 8:24 AM"
                    // date={
                    //   <TimezoneDate
                    //     date={eventMoment}
                    //     localFormat={format}
                    //     timezone="Europe/London"
                    //     placement="bottom"
                    //   />
                    // }
                    iconStyle={{ background: event.colour, marginTop: "2em" }}
                    icon={<SVGIcon color={"#ffffff"} type={event.icon} />}
                  >
                    <div className="row">
                      <div className="col-7">
                        <div className="vertical-timeline-element-title text-uppercase font-weight-bold font-16">
                          {event.caption}
                        </div>
                        <div className="vertical-timeline-element-subtitle font-14 mt-2">
                          {event.description}
                        </div>
                      </div>
                      {/* Event field - zone/area/loop/panel/point */}
                      <div className="col-5">
                      </div>
                    </div>
                    <div className="mt-2">{text}</div>
                    <div className="timeline-footer">
                      <div className="timeline-date-footer">
                        <TimezoneDate
                          date={eventMoment}
                          timezone={site.timezone}
                          localFormat={format}
                          placement="bottom"
                        />
                      </div>
                    </div>
                  </VerticalTimelineElement>
                );
              }
            )}
          </VerticalTimeline>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    getEventMetaById: createGetEventMetaById(state, props),
    site: getCurrentSite(state, props)
  }
}

export default withT(connect(mapStateToProps)(Timeline), "site_overview");
