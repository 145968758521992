import React from "react";

class InlineChartWidget extends React.Component {
  render() {
    return (
      <ul className="list-inline widget-chart m-t-20 m-b-15 text-center">
        {this.props.children.map((child, index) => {
          return (
            <li className="list-inline-item" key={index}>
              {child}
            </li>
          );
        })}
      </ul>
    );
  }
}
export default InlineChartWidget;
