import React from "react";
import { connect } from "react-redux";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { getConfig } from "services/redux/selectors/reports/common/";

import { default as T } from "services/i18n/Translate";

import { getTodayEnabled } from "./selectors";
import { getNow } from "services/locale/selectors";

class TodayButton extends React.Component {
  render() {
    const { selectedMoment } = this.props;

    const { dayPicker } = this.props.config;

    const today = getNow();

    const todaySelected =
      selectedMoment.get("month") === today.get("month") &&
      selectedMoment.get("date") === today.get("date");

    const todayEnabled = getTodayEnabled(today, dayPicker);

    const todayLiveBadge = todayEnabled ? (
      <span
        className="badge badge-danger"
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
          padding: "0.2em 0.3em",
          borderRadius: "1px"
        }}>
        <T>Live</T>
      </span>
    ) : null;

    const button = (
      <Button
        className={`btn-today${todayEnabled ? "" : " disabled"}`}
        variant={`${!todaySelected ? "outline-" : ""}primary`}
        onClick={() => todayEnabled && this.props.clickToday()}
        style={{ width: "50%" }}>
        <T>Today</T> {todayLiveBadge}
      </Button>
    );

    return todayEnabled ? (
      button
    ) : (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id={`tooltip-bottom`} style={{ pointerEvents: "none" }}>
            <span>
              <T>Report starts generating at</T> {dayPicker.disableTodayUntil}
            </span>
          </Tooltip>
        }>
        {button}
      </OverlayTrigger>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state, props)
  };
};
export default connect(mapStateToProps)(TodayButton);
