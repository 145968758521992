// LOAD NOTIFICATIONS RULES
export const loadNotificationsRulesStart = params => {
  return { type: "LOAD_NOTIFICATIONS_RULES_START", ...params };
};
export const loadNotificationsRulesSuccess = params => {
  return { type: "LOAD_NOTIFICATIONS_RULES_SUCCESS", ...params };
};
export const loadNotificationsRulesFailed = params => {
  return { type: "LOAD_NOTIFICATIONS_RULES_FAILED", ...params };
};
export const loadNotificationsRulesCancel = params => {
  return { type: "LOAD_NOTIFICATIONS_RULES_CANCEL", ...params };
};
