import { call, take, race, takeLatest } from "redux-saga/effects";
import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

import {
  getGroupStructureSuccess,
  getGroupStructureFailed,
  getGroupStructureRestore,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

export function* watchGetGroupStructure() {
  // Automatically cancels any previous sagas started
  // from here
  yield takeLatest("GET_GROUP_STRUCTURE_START", function* (action) {
    yield race({
      task: call(getGroupStructureStartRe, action),
      cancel: take([
        "GET_GROUP_STRUCTURE_CANCEL",
        "GET_GROUP_STRUCTURE_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

export function* getGroupStructureStartRe(action) {
  const { enterprise_id } = action;

  if (!action.enterprise_id)
    throw new Error(" enterprise_id is required for this api call.");

  const params = {
    successActionParams: {
      enterprise_id,
    },
    reducerKey: "enterpriseManager",
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseStructure/getGroupsForEnterprise/${action.enterprise_id}`
    ),
    successAction: getGroupStructureSuccess,
    failedAction: getGroupStructureFailed,
    restoreAction: getGroupStructureRestore,
    extraParams: { cacheSubKey: action.enterprise_id },
  };

  yield cacheIndexes.restoreOrLoad(params);
}
