import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { withT } from "services/translation/";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute, getGroups } from "services/redux/selectors/groups";

import {
  getSelectedGroupId,
  getRoleIdForCurrentGroup
} from "services/redux/selectors/users";

import { Row, Col, Card } from "react-bootstrap";

import GroupList from "components/Enterprise/GroupManagement/GroupSelector/GroupList";

import Loading from "components/Loading";

import UserList from "../UserList/";
import UserInvite from "../UserInvite/";

import GroupPath from "components/Enterprise/Overview/GroupPath";

import USER_ROLE_IDS from "constants/USER_ROLE_IDS";

import {
  getGroupPathWithIdById,
  getGroupsWithAccess
} from "services/redux/selectors/groups";

import {
  loadUserManagementAccessStart,
  loadUserManagementAccessReset
} from "services/redux/actions/";

/**
 * User Management Overview
 *
 * Loads and displays the enterprise and group structure
 * to allow administrators to manage user access and
 * invite new users.
 *
 * Permission "trickles down" the tree
 *
 */
class Overview extends React.Component {
  state = {
    selectedGroupId: null,
    cacheRefreshCount: 0
  };
  dispatchLoadAccess = props => {
    const { enterprise, group } = props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    this.props.dispatch(
      loadUserManagementAccessStart({
        enterprise_id: enterprise.id,
        group_id
      })
    );
  };
  reloadAccess = props => {
    this.dispatchLoadAccess(props);
  };
  loadAccess = props => {
    const { user } = props;

    // null means access hasn't been loaded for this
    // enterprise
    if (user.access.loading === null) {
      this.dispatchLoadAccess(props);
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      this.loadAccess(nextProps);
    }
  };
  componentDidMount() {
    this.props.dispatch(loadUserManagementAccessReset());
    this.setState({ cacheRefreshCount: 0 });
    this.loadAccess(this.props);
  }
  onSelect = selectedGroupId => {
    const { location, enterprise } = this.props;

    // This is the root group
    const isRootGroup = enterprise.root_group_id === selectedGroupId;

    const path = location.pathname.split("/");
    path[3] = isRootGroup ? "root" : selectedGroupId;

    const redirectTo = path.join("/");

    this.props.history.push(redirectTo);
  };
  render() {
    const {
      enterprise,
      group,
      groups,
      groupsWithAccess,
      selectedGroupId,
      user,
      roleId,
      t
    } = this.props;
    const { cacheRefreshCount } = this.state;

    if (!enterprise || user.access.loading !== false) {
      return <Loading />;
    }

    // if (groups.length === 0) return <div>{t("no_groups_available")}</div>;
    if (groups.length === 0) return <Loading />;

    // Back end is not returning any groups for this enterprise
    if (user.access.groups.length === 0) {
      return <div>{t("no_groups_available")}</div>;
    }

    // We have groups but the cache doesn't match
    // the access we just fetched, flush cache and reload access
    // which will retrigger group loading
    if (groupsWithAccess.unfoundGroup) {
      // console.log("unfoundGroup");
      // Also, in the case that the group appears in the access
      // but the groups cache hasn't updated yet (can take up to 60s),
      // we need to prevent infinite reloading
      if (cacheRefreshCount === 0) {
        //   return (
        //     <div>
        //       Your group data is currently being updated, please try again in a
        //       minute or two.
        //     </div>
        //   );
        // } else {
        this.setState({ cacheRefreshCount: cacheRefreshCount + 1 });
        // Flush only groups for this enterprise
        window.caching.flush(`LOAD_GROUPS_BACKGROUND_SUCCESS/${enterprise.id}`);
        this.reloadAccess(this.props);

        return <Loading />;
      }
    }

    const groupPath = getGroupPathWithIdById(
      groupsWithAccess.groups,
      parseInt(selectedGroupId)
    );

    const showUserManagement =
      roleId === USER_ROLE_IDS.ADMIN ||
      roleId === USER_ROLE_IDS.MANAGER ||
      roleId === USER_ROLE_IDS.MEMBER;

    const showUserInvite =
      roleId === USER_ROLE_IDS.ADMIN || roleId === USER_ROLE_IDS.MANAGER;

    return (
      <>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Card.Subtitle className="text-uppercase text-muted mt-1 mb-3">
                  {t("select_group_title")}
                </Card.Subtitle>
                <div id="sidebar-menu" className="select-groups">
                  <GroupList
                    onSelect={this.onSelect}
                    groups={groupsWithAccess.groups}
                    selectedGroupId={selectedGroupId}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {showUserManagement && (
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Card.Title className="text-uppercase">
                    {group ? group.name : enterprise.name}
                  </Card.Title>
                  <Card.Subtitle className="mb-3 mt-2 font-12">
                    <GroupPath
                      delimiter=" > "
                      path={groupPath}
                      className="text-muted"
                      asLinks={false}
                    />
                  </Card.Subtitle>

                  <hr />
                  <Row>
                    <Col md={12}>
                      {showUserInvite && (
                        <UserInvite
                          group={group || groupsWithAccess.groups[0]}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <UserList
                        group={group || groupsWithAccess.groups[0]}
                        roleId={roleId}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groups: getGroups(state, props),
    groupsWithAccess: getGroupsWithAccess(state, props),
    selectedGroupId: getSelectedGroupId(state, props),
    roleId: getRoleIdForCurrentGroup(state, props)
  };
};
export default withT(
  withRouter(connect(mapStateToProps)(Overview)),
  "user_management"
);
