import Reports from "./Reports/";
import Enterprise from "./Enterprise/";
import Group from "./Group/";

const Dashboards = {
  default: Reports,
  "reports-overview": Reports,
  "enterprise-overview": Enterprise,
  "group-overview": Group
};

export default Dashboards;