// import React, { useState } from "react";
import React from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { Row, Nav, Tab, Col, Button } from "react-bootstrap";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import { withT } from "services/translation/";

import { EngineersMode } from "../Tabs/Modes/";
// import { TestMode } from "../Tabs/Modes/";

import SVGIcon from "components/Icons/SVGIcon";

import Icon from "components/Icons/Icon";

// import { t } from "services/translation/index";
import { getRouterParams } from "services/redux/selectors/app/index";
import "./ViewModes.scss";

const TabOptions = [
    {
        key: "engineers",
        col: 12,
        Component: EngineersMode,
        icon: "engineer",
    },
    //Test filter not yet fully integrated on backend
    // {
    //     key: "test",
    //     col: 6,
    //     Component: TestMode,
    //     icon: "tests",
    // }
];

class ViewModes extends React.Component {
    constructor() {
        super();
        let view = "overview"

        this.state = {
            key: "engineers",
            view,
        };
    }

    onSelect = ({ key }) => {
        this.setState({ key: key, view: key })
    }

    render() {

        const { t, site, onBackToOverview } = this.props;

        const Tabs = (
            <Tab.Container
                activeKey={this.state.key}
                onSelect={(key) => this.onSelect({ key })}
            >
                <Col xs={12}>
                    <Nav variant="pills" className="mode-pills row">
                        {TabOptions.map(({ key, icon, col }, index) => (
                            <Nav.Item className={"col-" + col} key={index}>
                                <Nav.Link eventKey={key} className="text-center">
                                    <SVGIcon source="vm" color={key === this.state.key ? "#ffffff" : "#2c3e50"} type={icon} />
                                    <span style={{ color: key === this.state.key ? "#ffffff" : "#2c3e50", paddingLeft: 5 }}>{t("tabs." + key)}</span>
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
                <div className="mt-3 ml-3" style={{width: "95%"}}>
                    <h6>
                        Introducing Engineers mode filter
                    </h6>
                    <p style={{ fontSize: "14px" }}>
                        When enabled, CONXTD intelligently filters out events which occur during Engineers mode, preventing such events appearing in the CONXTD timeline, sent as notifications or appearing in reports.
                    <br /><br />
                    A summary of engineers mode sessions can be viewed here, including all filtered events.
                    </p>
                </div>

                <div className="mt-3 text-center">
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => window.open('https://intercom.help/conxtd/en/articles/5451781-the-engineer-filter')}
                    >Learn More</Button>
                </div>
                <Row>
                    <Col>
                        <Tab.Content>
                            {TabOptions.map(({ key, Component }, index) => {
                                const active = this.state.key === key;
                                return active ? (
                                    <Tab.Pane key={index} eventKey={key} className="pt-4">
                                        <ErrorBoundary message="Error: Issue">
                                            <Component
                                                site={site}
                                            />
                                        </ErrorBoundary>
                                    </Tab.Pane>
                                ) : null;
                            })}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );

        // const { view } = this.state;

        return (
            <>
                <div className="row px-2 mt-2">
                    <div className="btn btn-default border-none side-pane-back text-dark">
                        <Link to="#" onClick={onBackToOverview}>
                            <span className="back-to-overview"><Icon className="fas fa-chevron-left mr-2" /> {t("back_to_overview")}</span>
                        </Link>
                    </div>
                </div>
                <div className="row px-2">
                    <div className="w-100">{Tabs}</div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        router: {
            params: getRouterParams(state, props),
        },
    };
};

export default withT(
    connect(mapStateToProps)(ViewModes),
    "site_overview.side_pane.mode_view"
);