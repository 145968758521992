import React from "react";
import { connect } from "react-redux";

import { getConfig } from "services/redux/selectors/reports/common/";
import { getReportPath } from "services/redux/selectors/reports/router";

import * as reports from "../Types";

class ReportPage extends React.Component {
  render() {
    const { reportPath } = this.props;

    let ReportComponent = () => null;

    if (reportPath[0] !== "default") ReportComponent = reports[reportPath[0]];

    return <ReportComponent />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportPath: getReportPath(state, props),
    config: getConfig(state, props)
  };
};
export default connect(mapStateToProps)(ReportPage);
