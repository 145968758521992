import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BarChart from "components/Enterprise/Reports/Charts/BarChart";

import { ButtonGroup, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import moment from "services/locale/momentInit.js";

import Icon from "components/Icons/Icon";

import _ from "lodash";

import Loading from "components/Loading";

import { default as T } from "services/i18n/Translate";

import {
  roundTimeToNearest,
  roundTimeToNearestOffset,
  getFormatMethod
} from "services/redux/selectors/reports/scheduled/interval";

import {
  getScheduledEventsFiltered,
  getAlarmTypes,
  getScheduledFilter,
  getAcked
} from "services/redux/selectors/reports/scheduled";

// TODO: translate these labels with t somehow
import { COLORS, YKEYS, LABELS } from "constants/Reports/Scheduled/BarStack";

const XKEY = "timestamp";

// TODO: Greyed out placeholder when empty data is passed

class BarStack extends React.Component {
  onClick = event => {
    const { textContent, className } = event.target;
    // Found AM or PM (and not the longer, "created by Raphael" text)
    if (
      className === "morris-hover-row-label" &&
      (textContent.indexOf("AM") !== -1 || textContent.indexOf("PM") !== -1)
    ) {
      // this.props.changeIntervalFilter(textContent);
      const { roundingMethod, userTimezoneUTCOffset } = this.props.filter;

      const timeText = textContent.substring(0, textContent.length-2);

      const offsetTime = moment();

      let hourValue = 0;
      let minuteValue = 0;
      if (timeText.length === 2) {
        hourValue = parseInt(timeText);
      } else {
        hourValue = parseInt(timeText.substring(0, 2));
        minuteValue = parseInt(timeText.substring(3));
      }
      if (textContent.indexOf("PM") !== -1 && hourValue !== 12) {
        hourValue = hourValue + 12;
      }
      if (textContent.indexOf("AM") !== -1 && hourValue === 12) {
        hourValue = 0;
      }

      offsetTime.hour(hourValue).minute(minuteValue).second(0).millisecond(0);
      if (userTimezoneUTCOffset.sign < 0) {
        offsetTime.add(Math.abs(userTimezoneUTCOffset.hours), "h").add(userTimezoneUTCOffset.minutes, "m");
      } else {
        offsetTime.subtract(userTimezoneUTCOffset.hours, "h").subtract(userTimezoneUTCOffset.minutes, "m");
      }

      const format = getFormatMethod(roundingMethod, userTimezoneUTCOffset);
      if ((roundingMethod === "1 hour" && userTimezoneUTCOffset.minutes !== 0)) {
        this.props.changeIntervalFilter(roundTimeToNearestOffset(offsetTime, roundingMethod).format(format));
      } else {
        this.props.changeIntervalFilter(roundTimeToNearest(offsetTime, roundingMethod).format(format));
      }
    }
  };
  normalizeAlarms = alarms => {
    const { reportType } = this.props;

    const { roundingMethod } = this.props.filter;
    /*

    Normalizes data into:

    {
      "${timestamp_rounded_down_nearest_hour}": {
          "EARLY": 30,
          "...": 0
        }
    }

    */

    // const filter = this.props.filter;
    let events = {};

    _.each(alarms, site => {
      // let skip = false;
      // let open_types = [];
      // // Loop through this site's occurrences
      // _.each(site.occurrences, (occ_val, occ_key) => {
      //   // open_types.push(EVENT_TYPES[reportType][occ_key]);
      //   open_types.push(occ_val.open_type);
      // });

      // skip = true;
      // _.each(filter, (keepMe, filterKey) => {
      //   _.each(open_types, open_type => {
      //     if (open_type === filterKey && keepMe) {
      //       // Hide this site if no occurrence is "visible"
      //       skip = false;
      //     }
      //   });
      // });

      // if (skip) return;

      // _.each(site.occurrences, occurrence => {
      // Only filter based on the first occurrence
      const occurrence = site.occurrences[0];
      // console.log(occurrence);
      // _.each(occurrence_type, occurrence => {

      // Don't count failed sites
      if (occurrence.open_type === "FAILED") return;
      // Found an occurrence - let's record it

      // const timestamp = roundTimeToNearest(
      //   new moment(getAcked(occurrence)),
      //   roundingMethod
      // ).unix();

      const format = getFormatMethod(roundingMethod, null);
      const timeLocal = new moment.parseZone(getAcked(occurrence)).local(true);
      const timeLocalRounded = roundTimeToNearest(timeLocal, roundingMethod);
      const intervalLocal = timeLocalRounded.format(format);

      const timestamp = timeLocalRounded.unix();

      // let eventType = occurrence.open_type;
      const eventType = occurrence.open_type;

      const eventKey = _.get(events, timestamp);
      // This interval hasn't been set yet - initialise
      if (eventKey === undefined) {
        // events[timestamp] = {};
        events[timestamp] = {
          eventTypes: {},
          intervalLocal: "",
        };
        _.each(YKEYS[reportType], key => {
          // events[timestamp][key] = 0;
          events[timestamp].eventTypes[key] = 0;
          events[timestamp].intervalLocal = intervalLocal;
        });
      }
      // Increment event counter
      // events[timestamp][eventType] = events[timestamp][eventType] + 1;
      events[timestamp].eventTypes[eventType] = events[timestamp].eventTypes[eventType] + 1;
      // });
      // });
    });

    // Flatten for graph~?
    // i.e [timestamp]: [...events]
    events = _.map(events, (event, key) => {
      // const format = getFormatMethod(roundingMethod);
      // const timestamp = moment.unix(key).format(format);
      const timestamp = event.intervalLocal;

      // return { timestamp, ...event };
      return { timestamp, ...event.eventTypes };
    });

    return events;
  };

  render() {
    const { events, reportType } = this.props;

    const { roundingMethod } = this.props.filter;

    if (!events) {
      return <Loading />;
    }
    if (events.length === 0) {
      return null;
    }
    const normalizedAlarms = this.normalizeAlarms(events);
    if (normalizedAlarms.length === 0) {
      return null;
    }

    return (
      <>
        <div className="container-full">
          <div className="col">
            <div className="float-right pt-3">
              <ButtonGroup aria-label="Select interval">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom`}
                      style={{ pointerEvents: "none", fontSize: "12px" }}>
                      <T>Display events in 15 minute segments</T>
                    </Tooltip>
                  }>
                  <Button
                    variant={
                      (roundingMethod !== "15 mins" ? "outline-" : "") +
                      "primary"
                    }
                    active={roundingMethod === "15 mins"}
                    onClick={() => {
                      this.props.changeRoundingMethod("15 mins");
                    }}>
                    <Icon className="fas fa-hourglass-half" />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`tooltip-bottom`}
                      style={{ pointerEvents: "none", fontSize: "12px" }}>
                      <T>Display events in 1 hour segments</T>
                    </Tooltip>
                  }>
                  <Button
                    variant={
                      (roundingMethod !== "1 hour" ? "outline-" : "") +
                      "primary"
                    }
                    active={roundingMethod === "1 hour"}
                    onClick={() => {
                      this.props.changeRoundingMethod("1 hour");
                    }}>
                    <Icon className="fas fa-hourglass" />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </div>
          </div>
        </div>

        <BarChart
          data={normalizedAlarms}
          colors={COLORS[reportType]}
          labels={LABELS[reportType]}
          ykeys={YKEYS[reportType]}
          xkey={XKEY}
          onClick={this.onClick}
        />
      </>
    );
  }
}
BarStack.propTypes = {
  ALARM_TYPES: PropTypes.any.isRequired,
  filter: PropTypes.any.isRequired,
  events: PropTypes.any.isRequired,
  changeFilter: PropTypes.any.isRequired,
  // intervalFilter: PropTypes.any.isRequired,
  changeIntervalFilter: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    events: getScheduledEventsFiltered(state, props),
    ALARM_TYPES: getAlarmTypes(state, props),
    filter: getScheduledFilter(state, props).interval
  };
};
export default connect(mapStateToProps)(BarStack);
