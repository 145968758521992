import { call, take, race, takeLatest } from "redux-saga/effects";
import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

import {
  getSiteDetailSuccess,
  getSiteDetailFailed,
  getSiteDetailRestore,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

export function* watchGetSiteDetail() {
  // Automatically cancels any previous sagas started
  // from here
  yield takeLatest("GET_SITE_DETAIL_START", function* (action) {
    yield race({
      task: call(getSiteDetailStartRe, action),
      cancel: take([
        "GET_SITE_DETAIL_CANCEL",
        "GET_SITE_DETAIL_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

export function* getSiteDetailStartRe(action) {
  const { enterprise_site_id } = action;

  if (!action.enterprise_site_id)
    throw new Error(" enterprise_site_id is required for this api call.");

  const params = {
    successActionParams: {
      enterprise_site_id,
    },
    reducerKey: "enterpriseManager",
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseSites/view/${action.enterprise_site_id}`
    ),
    successAction: getSiteDetailSuccess,
    failedAction: getSiteDetailFailed,
    restoreAction: getSiteDetailRestore,
    extraParams: { cacheSubKey: action.enterprise_site_id },
  };

  yield cacheIndexes.restoreOrLoad(params);
}
