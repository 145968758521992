import { call, take, race, takeLatest } from "redux-saga/effects";
import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

import {
  getGroupsDropDownListSuccess,
  getGroupsDropDownListFailed,
  getGroupsDropDownListRestore,
} from "services/redux/actions/enterpriseManager";

import conxtdOut from "apis/conxtdOut";

export function* watchGetGroupsList() {
  // Automatically cancels any previous sagas started
  // from here
  yield takeLatest("GET_GROUPS_DROPDOWN_LIST_START", function* (action) {
    yield race({
      task: call(getGroupsDropDownListStartRe, action),
      cancel: take([
        "GET_GROUPS_DROPDOWN_LIST_CANCEL",
        "GET_GROUPS_DROPDOWN_LIST_FAILED",
        "CANCEL_ALL",
      ]),
    });
  });
}

export function* getGroupsDropDownListStartRe(action) {
  const { enterprise_id } = action;

  if (!action.enterprise_id)
    throw new Error("enterprise_id is required for this api call.");

  const params = {
    successActionParams: {
      enterprise_id,
    },
    reducerKey: "enterpriseManager",
    apiCall: call(
      conxtdOut.get,
      `/EnterpriseGroups/add/${action.enterprise_id}`
    ),
    successAction: getGroupsDropDownListSuccess,
    failedAction: getGroupsDropDownListFailed,
    restoreAction: getGroupsDropDownListRestore,
    extraParams: { cacheSubKey: action.enterprise_id },
  };

  yield cacheIndexes.restoreOrLoad(params);
}
