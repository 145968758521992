/**
 * Debouncing utilities for liveRefresh
 */

// Load report
// Navigate away - cancel is fired
// data is reset
// start loading again - return cached response

let responses = [];
// 30 second debounce offset
const defaultOffset = 1000 * 30;

function shallowEqual(a, b) {
  return Object.keys(a).every(key => a[key] === b[key]);
}

/**
 * Returns true if a call is fresh i.e. newer than the current time
 * minus the offset
 * @param {*} call a call item
 */
function freshCall(call, offset = defaultOffset) {
  const now = parseInt(new Date().getTime());

  // Remove one second from offset to prevent
  // skipping a refresh
  return call.calledAt > now - offset - 1000;
}

/**
 * Returns a cache if valid, otherwise returns null
 * @param {*} action redux action
 */
export function findCache(action, offset) {
  const { payload } = responses.find(
    response =>
      freshCall(response, offset) && shallowEqual(response.action, action)
  ) || { payload: null };

  return payload;
}

export function cacheResponse(action, payload) {
  responses = [
    {
      action,
      payload,
      calledAt: parseInt(new Date().getTime())
    },
    ...responses
  ];

  // Limit action log to 5
  if (responses.length > 5) {
    responses = responses.slice(0, 5);
  }
}
