import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withT } from "services/translation/";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute, getGroups } from "services/redux/selectors/groups";
import { getGroupsWithAccess } from "services/redux/selectors/groups";
import {
  getSelectedGroupId,
  getRoleIdForCurrentGroup,
} from "services/redux/selectors/users";
import { 
  loadUserManagementAccessStart,
  // loadUserManagementAccessReset,
} from "services/redux/actions/";
// import { Row, Col, Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import GroupOptions from "../Navigation/GroupOptions";
import GroupList from "./GroupList";
import Loading from "components/Loading";
import "./Overview.scss";

/**
 * Enterprise Management Overview
 *
 * Loads and displays the enterprise and group structure
 *
 **/
class Overview extends React.Component {
  state = {
    selectedGroupId: null,
    cacheRefreshCount: 0,
  };
  dispatchLoadAccess = (props) => {
    const { enterprise, group } = props;

    const group_id = (group && group.id) || enterprise.root_group_id;

    this.props.dispatch(
      loadUserManagementAccessStart({
        enterprise_id: enterprise.id,
        group_id,
      })
    );
  };

  reloadAccess = (props) => {
    this.dispatchLoadAccess(props);
  };
  loadAccess = (props) => {
    const { user } = props;

    // null means access hasn't been loaded for this
    // enterprise
    if (user.access.loading === null) {
      this.dispatchLoadAccess(props);
    }
  };

  // UNSAFE_componentWillReceiveProps = nextProps => {
  //   if (this.props !== nextProps) {
  //     this.loadAccess(nextProps);
  //   }
  // };
  // componentDidMount() {
  //   this.props.dispatch(loadUserManagementAccessReset());
  //   this.setState({ cacheRefreshCount: 0 });
  //   this.loadAccess(this.props);
  // }

  onSelect = (selectedGroupId) => {
    const { location, enterprise } = this.props;

    // This is the root group
    const isRootGroup = enterprise.root_group_id === selectedGroupId;

    const path = location.pathname.split("/");
    path[3] = isRootGroup ? "root" : selectedGroupId;

    const redirectTo = path.join("/");

    this.props.history.push(redirectTo);
  };

  render() {
    const {
      enterprise,
      groups,
      groupsWithAccess,
      selectedGroupId,
      user,
    } = this.props;
    const { cacheRefreshCount } = this.state;

    if (!enterprise || user.access.loading !== false) {
      return <Loading />;
    }

    // if (groups.length === 0) return <div>{t("no_groups_available")}</div>;
    if (groups.length === 0) return <Loading />;

    // Back end is not returning any groups for this enterprise
    if (user.access.groups.length === 0) {
      return <div>NO GROUPS AVAILABLE</div>;
    }

    // We have groups but the cache doesn't match
    // the access we just fetched, flush cache and reload access
    // which will retrigger group loading
    if (groupsWithAccess.unfoundGroup) {
      // console.log("unfoundGroup");
      // Also, in the case that the group appears in the access
      // but the groups cache hasn't updated yet (can take up to 60s),
      // we need to prevent infinite reloading
      if (cacheRefreshCount === 0) {
        //   return (
        //     <div>
        //       Your group data is currently being updated, please try again in a
        //       minute or two.
        //     </div>
        //   );
        // } else {
        this.setState({ cacheRefreshCount: cacheRefreshCount + 1 });
        // Flush only groups for this enterprise
        window.caching.flush(`LOAD_GROUPS_BACKGROUND_SUCCESS/${enterprise.id}`);
        this.reloadAccess(this.props);

        return <Loading />;
      }
    }

    return (
      <>
        <Row>
          <Col>
            <div className="mt-0 mb-2 float-right">
              <GroupOptions />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-uppercase mt-1 mb-2 card-subtitle h6">
              SELECT GROUP
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <span
                className="d-none d-sm-none d-md-block em-overview-heading"
                style={{
                  marginRight: "40px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                LIVE SITES{" "}
              </span> */}
              <span
                className="d-none d-sm-none d-md-block em-overview-heading"
                style={{
                  marginRight: "35px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                ALL SITES{" "}
              </span>
              <span
                className="d-none d-sm-none d-md-block em-overview-heading"
                style={{
                  // marginRight: "265px",
                  marginRight: "200px",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                REPORTS{" "}
              </span>
            </div>
            <div id="sidebar-menu" className="select-groups">
              <GroupList
                onSelect={this.onSelect}
                groups={groupsWithAccess.groups}
                selectedGroupId={selectedGroupId}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    groups: getGroups(state, props),
    groupsWithAccess: getGroupsWithAccess(state, props),
    selectedGroupId: getSelectedGroupId(state, props),
    roleId: getRoleIdForCurrentGroup(state, props),
  };
};
export default withT(
  withRouter(connect(mapStateToProps)(Overview)),
  "enterprise_manager"
);
