/**
 * 08/21 mixed type chart that can display both line graphs and bar charts 
 * (and potentially other types of data)
 */
import React from "react";

import ChartJSMixed from "./Libraries/ChartJSMixed";

class MixedChart extends React.Component {
  render() {

    // let defaultChartData = {
    //   labels: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'],
    //   datasets: [
    //     {
    //       type: 'line',
    //       label: 'Average',
    //       borderColor: 'rgb(54, 162, 235)',
    //       borderWidth: 2,
    //       fill: false,
    //       data: [1, 2, 3, 4, 6, 7, 7, 8, 10, 9, 9, 8, 4, 3, 2, 1],
    //     },
    //     {
    //       type: 'bar',
    //       label: 'Site Data',
    //       backgroundColor: 'rgb(255, 99, 132)',
    //       data: [1, 2, 4, 4, 4, 6, 8, 9, 10, 11, 10, 7, 5, 3, 2, 1],
    //       borderColor: 'white',
    //       borderWidth: 2,
    //     }
    //   ],
    // };

    // let defaultChartOptions = {
    //   scales: {
    //     yAxes: [{
    //       ticks: {
    //         beginAtZero: true
    //       }
    //     }]
    //   }
    // };

    // return <ChartJSMixed data={defaultChartData} options={defaultChartOptions} />;
    return <ChartJSMixed  {...this.props} />;
  }
}
export default MixedChart;
