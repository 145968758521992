/**
 * 08/21 column containing a button to click to select a site from the list
 * in order to then display a bar chart of the corresponding data
 */
import React from "react";

import Icon from "components/Icons/Icon";
import { Button } from "react-bootstrap";

import "./SelectChartColumn.scss";

export function SelectChartColumn({ columns, onClickChartSite, selectedChartSiteId }) {
  const column = columns["VIEW_CHART"];

  return {
    ...column,
    Cell: row => {
      return (
        <Button 
          className="selectChartIconButton"
          size="sm"
          variant={`${selectedChartSiteId 
            ? row.original.id !== selectedChartSiteId ? "outline-" : ""
            : "outline-"}primary`}
          onClick={() => {
            onClickChartSite(row.original);
          }}>
            {<Icon className="far fa-chart-bar" />}
        </Button>
      );
    }
  };
}
