import { all, takeLeading } from "redux-saga/effects";

import * as sites from "./sites";
import * as keyholders from "./keyholders/";
import * as siteActions from "./actions/";
import * as integrations from "./integrations/";
import * as modes from "./modes/";
import * as eventPairs from "./eventPairs/";

import * as cacheIndexes from "../cacheIndexes/cacheIndexes";

export function* rootSaga() {
  yield takeLeading("LOAD_SITES_BACKGROUND_SUCCESS", cacheIndexes.cacheMe);
  yield all([
    sites.linksLoadStart(),
    sites.watchLoadBackground(),
    sites.watchLoadSiteView(),
    sites.watchLoadNotificationHistory(),
    sites.watchLoadSiteViewLiveRefresh(),
    integrations.rootSaga(),
    keyholders.rootSaga(),
    siteActions.rootSaga(),
    eventPairs.rootSaga(),
    modes.rootSaga()
  ]);
}
