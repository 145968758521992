import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import Icon from "components/Icons/Icon";
import ApiErrorBadge from "components/Layout/ApiErrorBadge";
import Loading from "components/Loading";

import { withT } from "services/translation/";
import { devMode } from "services/redux/selectors/";

import { loadNotificationsRulesStart } from "services/redux/actions";

import Table from "./Table";
/**
 * List of notification rules for the specified site
 *
 * Loads and displays notification rules with option to remove
 *
 *
 * Dispatch request for (all) rules
 *
 * Display list filtered by site id
 *
 * Need router params
 */
function NotificationList({
  rules,
  onChangeView,
  loading,
  loadRulesError,
  loadRules,

  removingRule,
  removeRuleError,

  t
}) {
  useEffect(() => {
    // Not loading and no error
    if (loading !== true && !loadRulesError.status) {
      loadRules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading === true || removingRule) {
    return <Loading center />;
  }

  if (loadRulesError.status || loadRulesError.message) {
    return (
      <ApiErrorBadge
        {...{ loadRulesError }}
        t={t}
        translationKey={`load_error${devMode() ? "_dev" : ""}`}
      />
    );
  }

  return (
    <>
      <ApiErrorBadge
        {...removeRuleError}
        t={t}
        translationKey={`remove_rule.errors.remove_rule${
          devMode() ? "_dev" : ""
        }`}
      />
      <div className="row mt-3">
        <div className="col-md-12">
          <Button onClick={onChangeView} variant="success" block>
            <Icon className="fas fa-plus pr-2" /> {t("add_rule.title")}
          </Button>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="header-title">{t("title")}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Table rules={rules} />
        </div>
      </div>
    </>
  );
}
NotificationList.propTypes = {
  /**
   * @param rules list of rules to display
   */
  rules: PropTypes.array.isRequired,
  /**
   * @param onChangeView back to site overview callback
   */
  onChangeView: PropTypes.func.isRequired,
  /**
   * @param loading api loading status
   */
  loading: PropTypes.any,
  /**
   * @param error api load rules error status
   */
  loadRulesError: PropTypes.object.isRequired,
  /**
   * @param loadRules api rule load dispatch
   */
  loadRules: PropTypes.func.isRequired,
  /**
   * @param t translation function
   */
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  loadRules: loadNotificationsRulesStart
};
const mapStateToProps = state => {
  return {
    rules: state.notifications.rules.data,
    loading: state.notifications.rules.loading,
    loadRulesError: state.notifications.rules.error,
  };
};

export default withT(
  connect(mapStateToProps, mapDispatchToProps)(NotificationList),
  "site_overview.side_pane.manage_notifications"
);
