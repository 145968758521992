import React from "react";

class SiteName extends React.Component {
  render() {
    const { value, custom_ref } = this.props;
    return (
      <>
        <span className="custom-ref bg-white text-dark">
          <span>{custom_ref}</span>
        </span>
        <span className="site-name-text">{value}</span>
      </>
    );
  }
}

export default SiteName;
