import { call, take, race } from "redux-saga/effects";

import * as actions from "services/redux/actions";

import conxtdOut from "apis/conxtdOut";

// import keyholders from "./keyholders.mock.json";

import { liveRefreshFor } from "services/redux/sagas/common";

export function* watchLoadKeyholders() {
  while (true) {
    const action = yield take("LOAD_SITE_KEYHOLDERS_START");
    yield race({
      task: call(loadKeyholders, action),
      cancel: take([
        "LOAD_SITE_KEYHOLDERS_CANCEL",
        "LOAD_SITE_KEYHOLDERS_FAILED",
        "CANCEL_ALL"
      ])
    });
  }
}
export function* loadKeyholders(action) {
  if (!action.id) {
    throw new Error("site id is required for loading keyholders");
  }

  const params = {
    startAction: actions.loadSiteKeyholdersStart,
    startActionParams: action,
    successAction: actions.loadSiteKeyholdersSuccess,
    failedAction: actions.loadSiteKeyholdersFailed,
    // Disable debounce as we expect the data to change
    debounce: false,
    apiCall: call(
      // () => {
      //   return { data: keyholders };
      // }
      conxtdOut.get,
      `/EnterpriseKeyholders/index?enterprise_site_id=${action.id}`
    ),
    interval: 30000,
    key: "keyholders",
    refresh: false
  };

  yield liveRefreshFor(params);
}
