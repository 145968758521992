import React from "react";
import { connect } from "react-redux";

import {
  getConfig,
  getFilteredActive,
} from "services/redux/selectors/reports/common/";
import { getReportPath } from "services/redux/selectors/reports/router";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

import { toggleDataTableFiltered } from "services/redux/actions/";

import RemoveFilter from "./RemoveFilter";

class SuggestedFilters extends React.Component {
  render() {
    const { filteredActive, getEventMetaById } = this.props;
    return filteredActive.map((event_id, key) => {
      const event = getEventMetaById(event_id);
      return (
        <span
          key={key}
          className={`${key} text-light`}
          style={{
            backgroundColor: event.colour,
            padding: "0.3rem 0.6rem",
            fontWeight: "700",
            textDecoration: "underline",
          }}
        >
          {event.caption}{" "}
          <RemoveFilter
            onClick={(e) => {
              this.props.dispatch(
                toggleDataTableFiltered({
                  filter: { id: "result", value: event.caption },
                  reportType: this.props.reportPath[1],
                })
              );
            }}
          />
        </span>
      );
    });
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportPath: getReportPath(state, props),
    config: getConfig(state, props),
    filteredActive: getFilteredActive(state, props),
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default connect(mapStateToProps)(SuggestedFilters);
