import React from "react";
import { connect } from "react-redux";

import { Card } from "react-bootstrap";

import Icon from "components/Icons/Icon";

class PriorityEventsAdPage extends React.Component {

  render() {
    const { messageType } = this.props;

    return (
      <div>
        <Card
          style={{
            padding: "20px",
            margin: "auto",
            marginRight: "32%",
          }}
        >
          <Card.Body>
            {
              messageType === "no-user-access"
                ? <Card.Title>Access Denied <Icon className="fas fa-user-lock	" /></Card.Title>
                : <Card.Title>Feature Not Enabled <Icon className="fas fa-user-lock	" /></Card.Title>
            }
            {
              messageType === "no-user-access"
                ? <Card.Text>
                    Your account does not have access to Priority Events. Please 
                    contact your installer / account admin to make any changes.
                  </Card.Text>
                : <Card.Text>
                    Priority Events is not enabled for this enterprise. Please 
                    contact your installer / account admin to make any changes.
                  </Card.Text>
            }
          </Card.Body>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(PriorityEventsAdPage);