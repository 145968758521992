//alarm-response
import { call, take, takeLatest, takeEvery, race } from "redux-saga/effects";
import { actionWatcher, liveRefreshFor } from "services/redux/sagas/common/";
import * as links from "services/redux/sagas/links";

import {
  loadAlarmsStart,
  loadAlarmsSuccess,
  loadAlarmsFailed,
  loadAlarmsCancel,
  loadAlarmsRefreshStart,
  loadAlarmsRefreshSuccess,
  loadAlarmsRefreshFailed,
} from "services/redux/actions/alarmResponse";
  
import conxtdOut from "apis/conxtdOut";

// one-off saga

export function* watchLoadAlarms() {
  // Link alarms loading to event meta
  yield takeLatest(
    ["LOAD_ALARMS_START"],
    links.toEventMetaBackgroundLoader
  );

  // Link alarms loading to site data
  yield takeLatest(
    ["LOAD_ALARMS_START"],
    links.toSitesBackgroundLoader
  );

  yield actionWatcher({
    actions: {
      start: loadAlarmsStart,
      failed: loadAlarmsFailed,
      success: loadAlarmsSuccess,
      cancel: loadAlarmsCancel,
    },
    saga: loadAlarmsRe,
  });
}

// https://mars.conxtd.com/out_api/EventPairs/inAlarm/252/617
export function* loadAlarmsRe(action) {
  try {
    const { enterprise_id, group_id } = action;

    if (!enterprise_id || !group_id) 
      throw new Error(
        "group_id and enterprise_id are required for loading alarms"
      );

    const url = `/EventPairs/inAlarm/${enterprise_id}/${group_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}


// live refresh saga

export function* watchAlarmsLiveRefresh() {
  // Link alarms loading to event meta
  yield takeLatest(
    ["LOAD_ALARMS_REFRESH_SUCCESS"],
    links.toEventMetaBackgroundLoader
  );

  // Link alarms loading to site data
  yield takeLatest(
    ["LOAD_ALARMS_REFRESH_SUCCESS"],
    links.toSitesBackgroundLoader
  );

  yield takeEvery("LOAD_ALARMS_REFRESH_START", function*(action) {
    yield race({
      task: call(loadRefreshStart, action),
      cancel: take([
        "LOAD_ALARMS_REFRESH_CANCEL",
      ])
    });
  });
}

// https://mars.conxtd.com/out_api/EventPairs/inAlarm/252/617
export function* loadRefreshStart(action) {
  try {
    const { enterprise_id, group_id, interval } = action;

    if (!enterprise_id || !group_id) 
      throw new Error(
        "group_id and enterprise_id are required for loading alarms" 
      );

    const params = {
      startAction: loadAlarmsRefreshStart,
      startActionParams: {
        enterprise_id,
        group_id,
        interval,
      },
      successAction: loadAlarmsRefreshSuccess,
      successActionParams: {
        enterprise_id,
        group_id,
        interval,
      },
      failedAction: loadAlarmsRefreshFailed,
      apiCall: call(
        conxtdOut.get,
        `/EventPairs/inAlarm/${action.enterprise_id}/${group_id}`
      ),
      interval,
      key: "data",
      refresh: true,
    };

    // const result = yield call(conxtdOut.get, url, action.params);
    const result = yield liveRefreshFor(params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}