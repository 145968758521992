/**
 * Constants for reports containing report labels, and keys to access
 * report data.
 *
 * This is due to be deprecated as of the new reports format and currently
 * only applies to "historic" report data, however due to inconsistencies
 * in data in the old format this is still necessary for filtering on
 * live reports
 */

import COLOUR_CODES from "./COLOUR_CODES.json";
import DAYS_AGO_COLORS from "constants/DAYS_AGO_COLORS";
import PEOPLE_COUNT_FILTER_COLORS from "constants/PEOPLE_COUNT_FILTER_COLORS";

const ALARM_TYPES = {
  open: {
    ACCESSOR: {
      SUCCESS: "OK",
      FAILED: "FAILED",
    },
    LABEL: {
      FAILED: "Pending",
    },
    EARLY: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    EARLY_OPEN: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    ON_TIME: {
      label: "On Time",
      colour: COLOUR_CODES["restoreGreen"],
    },
    LATE: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    LATE_OPEN: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    ABNORMAL: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    ABNORMAL_OPEN: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    FAILED: {
      label: "Pending",
      colour: COLOUR_CODES["testGrey"],
    },
    none: {
      colour: COLOUR_CODES["testGrey"],
      label: "None",
    },
  },
  close: {
    ACCESSOR: {
      SUCCESS: "OK",
      FAILED: "FAILED",
    },
    LABEL: {
      FAILED: "Pending",
    },
    EARLY: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    EARLY_CLOSE: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    ON_TIME: {
      label: "On Time",
      colour: COLOUR_CODES["restoreGreen"],
    },
    LATE: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    LATE_CLOSE: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    ABNORMAL: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    ABNORMAL_CLOSE: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    FAILED: {
      label: "Pending",
      colour: COLOUR_CODES["testGrey"],
    },
    none: {
      label: "None",
    },
  },
  fire: {
    ACCESSOR: {
      SUCCESS: "OK",
      FAILED: "FAILED",
    },
    LABEL: {
      FAILED: "Pending",
    },
    EARLY: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    EARLY_CLOSE: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    ON_TIME: {
      label: "On Time",
      colour: COLOUR_CODES["restoreGreen"],
    },
    LATE: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    LATE_CLOSE: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    ABNORMAL: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    ABNORMAL_CLOSE: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    FAILED: {
      label: "Pending",
      colour: COLOUR_CODES["testGrey"],
    },
    none: {
      label: "None",
    },
  },
  //panic
  panic: {
    ACCESSOR: {
      SUCCESS: "OK",
      FAILED: "FAILED",
    },
    LABEL: {
      FAILED: "Pending",
    },
    EARLY: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    EARLY_CLOSE: {
      label: "Early",
      colour: COLOUR_CODES["alertOrange"],
    },
    ON_TIME: {
      label: "On Time",
      colour: COLOUR_CODES["restoreGreen"],
    },
    LATE: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    LATE_CLOSE: {
      label: "Late",
      colour: COLOUR_CODES["failRed"],
    },
    ABNORMAL: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    ABNORMAL_CLOSE: {
      label: "Abnormal",
      colour: COLOUR_CODES["infoPurple"],
    },
    FAILED: {
      label: "Pending",
      colour: COLOUR_CODES["testGrey"],
    },
    none: {
      label: "None",
    },
  },
  overnight: {
    ACCESSOR: {
      SUCCESS: "OK",
      FAILED: "FAILED",
    },
    none: {
      colour: COLOUR_CODES["testGrey"],
      label: "None",
    },
    one_event: {
      label: "1 event",
      colour: DAYS_AGO_COLORS[0],
      index: 0,
    },
    two_to_five_sites: {
      label: "2 to 5",
      colour: DAYS_AGO_COLORS[1],
      index: 1,
    },
    six_to_ten_sites: {
      label: "6 to 10",
      colour: DAYS_AGO_COLORS[2],
      index: 2,
    },
    eleven_to_fifteen_sites: {
      label: "11 to 15",
      colour: DAYS_AGO_COLORS[3],
      index: 3,
    },
    sixteen_plus_sites: {
      label: "16+",
      colour: DAYS_AGO_COLORS[4],
      index: 4,
    },
  },
  peoplecount: {
    ACCESSOR: {
      SUCCESS: "OK",
      FAILED: "FAILED",
    },
    none: {
      colour: COLOUR_CODES["testGrey"],
      label: "None",
    },
    // one_event: {
    //   label: "1 event",
    up_to_fifty_events: {
      label: "Up to 50",
      colour: PEOPLE_COUNT_FILTER_COLORS[0],
      index: 0,
    },
    // two_to_five_sites: {
    //   label: "2 to 5",
    fiftyone_to_onehundred_events: {
      label: "51 to 100",
      colour: PEOPLE_COUNT_FILTER_COLORS[1],
      index: 1,
    },
    // six_to_ten_sites: {
    //   label: "6 to 10",
    onehundredandone_to_onehundredandfifty_events: {
      label: "101 to 150",
      colour: PEOPLE_COUNT_FILTER_COLORS[2],
      index: 2,
    },
    // eleven_to_fifteen_sites: {
    //   label: "11 to 15",
    onehundredandfiftyone_to_twohundred_events: {
      label: "151 to 200",
      colour: PEOPLE_COUNT_FILTER_COLORS[3],
      index: 3,
    },
    // sixteen_plus_sites: {
    //   label: "16+",
    twohundredandone_plus_events: {
      label: "201 or more",
      colour: PEOPLE_COUNT_FILTER_COLORS[4],
      index: 4,
    },
  },
  suspicious: {
    ACCESSOR: {
      SUCCESS: "OK",
      FAILED: "FAILED",
    },
    none: {
      colour: COLOUR_CODES["testGrey"],
      label: "None",
    },
    suspicious_activity: {
      label: "Suspicious activity",
      colour: DAYS_AGO_COLORS[0],
      index: 0,
    },
    sandwich_buffer: {
      label: "Acceptable activity",
      colour: DAYS_AGO_COLORS[1],
      index: 1,
    },
  },
};

export default ALARM_TYPES;