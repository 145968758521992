/**
 * Add/Link location to integration on this site
 *
 */

 import { call } from "redux-saga/effects";

 import {
     requestAddIntegrationLocationSuccess,
     requestAddIntegrationLocationFailed,
     requestAddIntegrationLocationStart,
     requestAddIntegrationLocationCancel,
 } from "services/redux/actions";

 import { actionWatcher } from "services/redux/sagas/common/";

 import conxtdOut from "apis/conxtdOut";

 function* requestAddIntegrationLocation({
     camera_integration_site_id,
     location,
 }) {
    try {
        const result = yield call (
            conxtdOut.post, 
                `/CameraIntegrationSites/add/${camera_integration_site_id}`, location
            );

            return result;
    } catch (e) {
        throw new Error(e);
    }
}

export function* requestAddIntegrationLocationWatcher() {
    yield actionWatcher({
        actions: {
            start: requestAddIntegrationLocationStart,
            failed: requestAddIntegrationLocationFailed,
            success: requestAddIntegrationLocationSuccess,
            cancel: requestAddIntegrationLocationCancel,
        },
        saga: requestAddIntegrationLocation
    });
}