//alarm-response
import React from "react";

import { Button } from "react-bootstrap";

import { EventCell, InnerColourCell, RightColourCell, AssignedUser } from "./Cells/";

import moment from "services/locale/momentInit.js";

import ReactTimeAgo from 'react-time-ago';

import COLOUR_CODES from "constants/COLOUR_CODES.json";

const ChildRowColumns = [
  {
    key: "CUSTOM_ID",
    width: 60,
    content: null
    // className: "text-muted",
    // content: ({ inAlarmSite }) => inAlarmSite.site && inAlarmSite.site.name && getCustomRef(inAlarmSite.site),
  },
  {
    key: "SITE_NAME",
    content: null
    // className: "text-muted",
    // content: ({ inAlarmSite }) => inAlarmSite.enterprise_site_name || NA(),
  },
  {
    key: "SITE_REF",
    width: 80,
    content: null
    // className: "text-muted",
    // content: ({ inAlarmSite }) => inAlarmSite.site_ref,
  },
  {
    key: "GROUP",
    width: 65,
    content: null
  },
  {
    key: "SITE_STATUS", 
    width: 90,
    content: null
    // className: "text-muted, text-center",
    // content: ({ inAlarmSite, t }) => {
    //   return (
    //     <SiteStatus
    //       value={inAlarmSite.open_close_state}
    //       t
    //     />
    //   )
    // },
  },
  {
    key: "PRIORITY_EVENT",
    width: 189,
    className: "alarm-colour-cell",
    content: ({ event, sudo_state }) => {
      return (
        <EventCell
          event={event}
          alarm_text={sudo_state.alarm_text}
          focus={true}
          overrideColor={sudo_state.actioned_complete ? COLOUR_CODES["testGrey"] : null}
        />
      )
    },
  },
  {
    key: "EVENT_TIME",
    width: 70,
    className: "alarm-colour-cell",
    content: ({ event, sudo_state }) => {
      return (
        <InnerColourCell
          backgroundColour={sudo_state.actioned_complete ? COLOUR_CODES["testGrey"] : event.colour}
          displayValue={new moment(sudo_state.recvd).format("HH:mm")}
          focus={true}
        />
      )
    },
  },
  {
    key: "ELAPSED_TIME",
    width: 100,
    className: "alarm-colour-cell",
    content: ({ event, sudo_state }) => {
      return (
        <InnerColourCell
          backgroundColour={sudo_state.actioned_complete ? COLOUR_CODES["testGrey"] : event.colour}
          displayValue={<div><ReactTimeAgo date={new moment(sudo_state.recvd).valueOf()} locale="en-GB" timeStyle="twitter"/><span>&nbsp;ago</span></div>}
          focus={true}
        />
      )
    },
  },
  {
    key: "AREA",
    width: 80,
    className: "alarm-colour-cell",
    content: ({ event, sudo_state }) => {
      return (
        <InnerColourCell
          backgroundColour={sudo_state.actioned_complete ? COLOUR_CODES["testGrey"] : event.colour}
          displayValue={sudo_state.area}
          focus={true}
        />
      )
    },
  },
  {
    key: "ZONE",
    width: 80,
    className: "alarm-colour-cell",
    content: ({ event, sudo_state }) => {
      return (
        <RightColourCell
          backgroundColour={sudo_state.actioned_complete ? COLOUR_CODES["testGrey"] : event.colour}
          displayValue={sudo_state.zone}
          focus={true}
        />
      )
    },
  },
  {
    key: "OTHER_EVENTS",
    width: 165,
    className: "text-muted text-center",
    content: ({ index }) => "#" + (index + 1),
  },
  {
    key: "ASSIGNED",
    width: 80,
    show: ({ showSOP }) => showSOP,
    className: "text-center",
    content: ({ sudo_state }) => {
      return <AssignedUser
        assignedUser={sudo_state.sop_action_assigned_user}
        closed={sudo_state.actioned_complete}
      />
    },
  },
  {
    key: "ACTIONS",
    width: 120,
    show: ({ showSOP }) => showSOP,
    content: ({ inAlarmSite, sudo_state, onClickViewSOP }) => {
      if (sudo_state.actioned_complete) {
        return (
          <Button
            disabled
          >Closed</Button>
        ) 
      } else if (sudo_state.sop_action_assigned_user) {
        return (
          <Button
            variant="warning"
            onClick={() => {
              onClickViewSOP(
                {
                  ...inAlarmSite,
                  sudo_state: [{
                    ...sudo_state,
                  }]
                },
                [{...sudo_state}],
              )
            }}
          >In Progress</Button>
        )
      } else {
        return (
          <Button
            onClick={() => {
              onClickViewSOP(
                {
                  ...inAlarmSite,
                  sudo_state: [{
                    ...sudo_state,
                  }]
                },
                [{...sudo_state}],
              )
            }}
          >View</Button>
        )
      }
    },
  },
  {
    key: "EXPAND",
    content: null
  }
];

export default ChildRowColumns;