import React from "react";
import { connect } from "react-redux";

import { LinkContainer } from "react-router-bootstrap";

import { getEnterpriseFromRoute } from "services/redux/selectors";

import { getCustomRef } from "services/redux/selectors/groups";

import CircleIcon from "components/Icons/CircleIcon";

import InfoButton from "components/Common/Buttons/Info/Button";

import GroupInfo from "./GroupInfo";

import { withT } from "services/translation/";

// const CircleIcon = ({ background, icon }) => {
//   return (
//     <div
//       className="d-inline-block mx-1"
//       style={{
//         background,
//         borderRadius: "100px",
//         width: "48px",
//         height: "48px",
//         fontSize: "22px",
//         padding: "6px"
//       }}>
//       <Icon
//         className={"text-light " + icon}
//         style={{ textShadow: "1px 1px 3px rgba(0,0,0,0.2)" }}
//       />
//     </div>
//   );
// };

const StatColumn = ({ background, icon, className, value, fontSize }) => {
  const greyedOut = value === 0;

  return (
    <div
      className={`${className} text-center px-0 d-flex`}
      style={{
        opacity: greyedOut ? "0.2" : "1.0"
      }}>
      <div className="m-auto d-flex">
        <CircleIcon
          color={background}
          type={icon}
          foregroundColor={"#ffffff"}
          size={48}
        />
        <span
          className="d-inline-block mx-3 my-auto"
          style={{
            fontSize
          }}>
          {value}
        </span>
      </div>
    </div>
  );
};

class GroupCard extends React.Component {
  render() {
    const { id, name, children, enterprise, stats, t } = this.props;

    const custom_ref = getCustomRef(this.props);

    return (
      <div className="col-xs-12 col-sm-6 col-xl-4 d-flex pt-3 pb-3 group-card custom-ref">
        <span className="custom-ref-header text-center text-white-important">
          <span>{custom_ref}</span>
        </span>
        <LinkContainer
          to={`/enterprise/${enterprise.id}/${id}`}
          className="waves-effect">
          <div className="card card-primary bg-white mb-4 mx-auto">
            <div className="card-header text-white text-light-darkmode text-center">
              <span className="site-name">{name}</span>
              <InfoButton
                Content={() => (
                  <GroupInfo
                    group={this.props}
                    noInfoAvailable={
                      <div className="text-center">
                        {t("no_info_available")}
                      </div>
                    }
                  />
                )}
                title={name}
              />
              {/* <span
            class="badge badge-light text-dark float-right"
            style={{
              position: "relative",
              top: "-2px"
            }}>
            {children.length}
          </span> */}
            </div>
            <div className="card-body bg-white text-dark">
              <div className="row mb-4 px-4">
                <StatColumn
                  background="#2D3E4F"
                  className={`col-${children > 0 ? "6" : "12"}`}
                  value={stats.countLiveSites}
                  // icon="fas fa-building"
                  icon="building"
                  fontSize="22px"
                />
                {children > 0 && (
                  <StatColumn
                    background="#2D3E4F"
                    className="col-6"
                    value={children}
                    icon="groups"
                    fontSize="22px"
                  />
                )}
              </div>
              <div className="row pt-3" style={{ borderTop: "1px solid #ccc" }}>
                <StatColumn
                  background="#29498B"
                  className="col-4"
                  value={stats.openClosed.locked}
                  icon="lock"
                  fontSize="18px"
                />
                <StatColumn
                  background="#C03A2B"
                  className="col-4"
                  value={stats.alarmColours.failRed}
                  icon="burglary"
                  fontSize="18px"
                />
                <StatColumn
                  background="#F1C40F"
                  className="col-4"
                  value={stats.countHighAlarms}
                  icon="bell"
                  fontSize="18px"
                />
              </div>
            </div>
          </div>
        </LinkContainer>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props)
  };
};
export default withT(connect(mapStateToProps)(GroupCard), "group_overview");
