import moment from "moment/min/moment-with-locales";
import * as momentTimezone from "moment-timezone";
// TODO: add and configure locales properly (possibly webpack thing)
// Extend moment so we get locales and timezone
// moment.tz = momentTimezone.tz;
// moment.prototype.tz =
//   typeof momentTimezone.prototype !== "undefined" &&
//   momentTimezone.prototype.tz;
// moment.locale("en");

// Note: merging moment and moment timezone
// TODO: consider date-fns instead of moment since it
//  seems to have much better support for locale/is better maintained
export default Object.assign(moment, momentTimezone);
