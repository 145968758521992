/**
 * Notification remove rule reducer
 */

const remove = function(state = {}, action) {
  switch (action.type) {
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_START":
      return {
        ...state,
        loading: true
      };
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_SUCCESS":
      return {
        ...state,
        success: action.id,
        loading: false
      };
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_NOTIFIED":
      return {
        ...state,
        success: null,
        loading: false
      };
    default:
      return state;
  }
}

export default remove;