import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ButtonToolbar } from "react-bootstrap";

import { withT } from "services/translation/";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { contains } from "services/filters/";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute } from "services/redux/selectors/groups";

import { 
  getUsersExcludingConxtd 
} from "services/redux/selectors/users";

import { Badge } from "react-bootstrap";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import USER_ROLE_IDS from "constants/USER_ROLE_IDS";

import { Options } from "./Cells/Options/";
// import { Status } from "./Cells/Status/";

import ExportDropdown from "components/Enterprise/Export/ExportDropdown";

import moment from "services/locale/momentInit.js";

import "./DataTable.scss";

class DataTable extends React.Component {

  getLastLoginText( profile ) {
    const lastLogin = profile.login ? new moment(profile.login).fromNow() : null;

    return profile.login ? (
      lastLogin
    ) : (
      this.props.t("inactive")
    );
  }

  render() {
    const { t, roleId, enterprise, group } = this.props;
    // const { users } = this.props.users.current;
    const { users } = this.props;

    const showEditButtons =
      roleId === USER_ROLE_IDS.ADMIN || roleId === USER_ROLE_IDS.MANAGER;

    const columns = [];

    // Name, status, email, access, features, options
    columns.push({
      Header: t("headers.name"),
      id: "name",
      accessor: ({ first_name, last_name }) =>
        t("user_name_full", first_name, last_name)
    });
    columns.push({
      Header: t("headers.last_login"),
      id: "last_login",
      accessor: "profile",
      // Cell: ({ value }) => <LastLogin value={value} t={t} />,
      Cell: ( { original } ) => {
        return this.getLastLoginText(original.profile);
      },
      sortMethod: ({ login: a }, { login: b }) => {
        return new Date(a).getTime() < new Date(b).getTime() ? 1 : -1;
      }
    });
    columns.push({
      Header: t("headers.email"),
      id: "email",
      accessor: "email"
    });
    columns.push({
      Header: t("headers.access"),
      id: "access",
      accessor: "role"
    });
    columns.push({
      Header: t("headers.features"),
      id: "features",
      // For filtering
      accessor: ({ features }) => {
        return features.map( feature => feature.role ).join(" / ");
      },
      Cell: row => {
        return row.original.features.map(({ role, id }, index) => (
          <span key={index} id={id} className="font-16 d-inline">
            <Badge variant="secondary">{role}</Badge>
          </span>
        ));
      }
    });
    if (showEditButtons) {
      columns.push({
        Header: t("headers.options"),
        id: "options",
        accessor: () => "",
        Cell: props => <Options {...props} />,
        filterable: false
      });
    }

    return (
      <ErrorBoundary message="Error displaying users">
        <ButtonToolbar className="p-2">
        <ExportDropdown
          disabled={users.length === 0}
          reportTitle={`${enterprise.name}${(group && group.name && group.name !== enterprise.name) ? " - " + group.name : ""} - Current Users`}
          dataResolver={() => {
            const data = this.reactTable.getResolvedState().sortedData;

            let result = data.map(row => {
              // console.log(row);
              return [
                row["name"] || "",
                row["last_login"] ? this.getLastLoginText(row["last_login"]) : "",
                row["email"] || "",
                row["access"] || "",
                row["features"] || ""
              ];
            });
            result.unshift([
              "Name",
              "Last Login",
              "Email",
              "Access",
              "Features"
            ]);

            return result;
          }}
        />
        </ButtonToolbar>
        <ReactTable
          ref={r => (this.reactTable = r)}
          className="user-list"
          filterable
          noDataText={t("no_data_available")}
          // filtered={this.state.filtered}
          // onFilteredChange={(filtered, column, value) => {
          //   this.onFilteredChangeCustom(value, column.id || column.accessor);
          // }}
          defaultPageSize={10}
          defaultSorted={[
            {
              id: "access",
              desc: false
            }
          ]}
          defaultFilterMethod={(filter, row) => {
            return contains({
              filter: filter.value,
              value: String(row[filter.id]),
              ignoreCase: true
            });
          }}
          data={users}
          columns={columns}
          // Override row group class for sub rows
          // getTrGroupProps={(state, rowInfo, column) => {
          //   if (!rowInfo) return { className: "empty-row" };

          //   // Sets background to latest alarm id colour
          //   // const { colour } = getEventMetaById(
          //   //   get(rowInfo.original, "alarmLast.conxtdAlarmId")
          //   // ) || { colour: "#007bff" };

          //   const site = currentGroup.sites[rowInfo.index];

          //   const selected = site === selectedSite ? "selected" : "";

          //   const colorKey = rowInfo.original.zoneState.colour;

          //   const color = COLORS[colorKey] || "#666666";

          //   return {
          //     className: [colorKey, selected, "waves-effect"].join(" "),
          //     style: {
          //       background: color,
          //       border: "1px solid " + color
          //     },
          //     onClick: () => {
          //       // const site = currentGroup.sites[rowInfo.index];
          //       this.selectSite(site);
          //       // console.log(rowInfo.index);
          //     }
          //   };
          // }}
        />
      </ErrorBoundary>
    );
  }
}
DataTable.propTypes = {
  users: PropTypes.array.isRequired,
  roleId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    // users: state.users
    users: getUsersExcludingConxtd(state, props),
  };
};
export default withT(
  connect(mapStateToProps)(DataTable),
  "user_management.user_list"
);
