/**
 * Config for session storage cache expiry
 */

const ONE_MIN = 60 * 1000;
// 30 mins
const STANDARD_CACHE_TIME = ONE_MIN * 30;
// 24 hours
const LONG_CACHE_TIME = ONE_MIN * 60 * 24;

const CACHE_CONFIG = {
  LOAD_ENTERPRISES_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
    // timeToStale: ONE_MIN * 1
  },
  LOAD_SITES_BACKGROUND_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
  },
  LOAD_EM_SITES_LIST_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
  },
  LOAD_EVENT_META_BACKGROUND_SUCCESS: {
    timeToStale: LONG_CACHE_TIME,
  },
  LOAD_GROUPS_BACKGROUND_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
  },
  GET_GROUPS_DROPDOWN_LIST_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
  },
  GET_COUNT_FOR_MAIN_PAGE_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
  },
  GET_SITE_DETAIL_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
  },
  GET_GROUP_STRUCTURE_SUCCESS: {
    timeToStale: STANDARD_CACHE_TIME,
  },
};
export default CACHE_CONFIG;
