import React, { Fragment, useState } from "react";
import Message from "./Message";
import Progress from "./Progress";
import conxtdOut from "apis/conxtdOut";
import { Modal } from "react-bootstrap";
// import { useDispatch } from "react-redux";

const Import = (props) => {
  // const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  // eslint-disable-next-line no-unused-vars
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
      let path = "";
      if (props.path) {
        path = `${props.path}${props.enterpriseId ? "/"+props.enterpriseId : ""}${props.groupId ? "/"+props.groupId : ""}`
      } else {
        //default to group structure export
        path = `/EnterpriseGroups/import/${props.enterpriseId}/${props.groupId}`;
      }
      const res = await conxtdOut.post(
        path,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );

            // Clear percentage
            setTimeout(() => setUploadPercentage(0), 3000);
          },
        }
      );

      const { fileName, filePath } = res.data;

      setUploadedFile({ fileName, filePath });
      setMessage("File Uploaded - " + res.data.message);

      await new Promise((resolve) => setTimeout(resolve, 5000));
      setTimeout(function () {
        window.location.reload(1);
      }, 1000);
      setTimeout(1);
      setUploadedFile({});
      setMessage("");
      setFilename("");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Import {props.importName || "Site"} details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Import a spreadsheet to update {props.importDescription || "address details, custom fields and groups"}. Drag and drop file or click browse.
          </p>
          {message ? <Message msg={message} /> : null}
          <form onSubmit={onSubmit}>
            <div className="custom-file mb-4">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={onChange}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {filename}
              </label>
            </div>

            <Progress percentage={uploadPercentage} />

            <input
              type="submit"
              value="Upload"
              className="btn btn-success btn-block mt-4"
            />
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Import;
