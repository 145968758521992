import React from "react";
import { connect } from "react-redux";

import CircleIcon from "components/Icons/CircleIcon";

import { MultiProgressBar } from "components/Common/ProgressBar/";

import { getCurrentGroup } from "services/redux/selectors/groups";

import * as actions from "services/redux/actions";

class StatisticWidget extends React.Component {
  toggleFilter = filter => {
    if (!this.props.clickable) return;

    this.props.dispatch(
      actions.changeGroupOverviewFilter({
        filter: {
          ...filter,

          type: "nonZero",
          toggle: true
        }
      })
    );
  };
  render() {
    const {
      name,
      icon,
      color,
      progressBars,
      total,
      filters,
      clickable
    } = this.props;

    // Get active progress bar filter
    let activeBar =
      (progressBars &&
        progressBars.find(({ filterGroup, key }) => {
          return (
            filters.filter(f => {
              return f.filterGroup + f.key === filterGroup + key;
            }).length > 0
          );
        })) ||
      null;

    const showProgressBars = !progressBars || progressBars.length < 2;

    const ProgressBarsComponent = showProgressBars ? null : (
      <div className="row px-1">
        <div className="col-md-12">
          <MultiProgressBar
            height="15px"
            values={progressBars}
            title={name}
            total={total}
            filters={filters}
            onClick={filter => {
              this.props.dispatch(
                actions.changeGroupOverviewFilter({ filter })
              );
            }}
          />
        </div>
      </div>
    );

    const activeFilterState = activeBar ||
      (progressBars && progressBars.find(p => p.defaultState)) || {
        icon,
        color
      };

    const value = activeFilterState.value || this.props.value;

    const zeroValue = value === 0 ? "zero-value" : "";

    const filterGroupClass =
      (activeFilterState.filterGroup &&
        activeFilterState.filterGroup.replace(".", "-")) ||
      "";

    return (
      <div className={`col-sm-6 px-2 ${filterGroupClass} ${zeroValue}`}>
        <div className="row mx-0">
          <div
            className={`col-sm-12 d-flex py-1 px-sm-0 ${
              clickable ? "stat-filter-button waves-effect" : ""
            } ${activeBar ? "active" : ""}`}
            onClick={() => value > 0 && this.toggleFilter(activeFilterState)}>
            {activeFilterState.icon && (
              <span className="widget-icon col-md-4 px-2 d-block text-center text-md-right float-left my-auto">
                <CircleIcon
                  size="48"
                  type={activeFilterState.icon}
                  color={activeFilterState.color}
                />
              </span>
            )}

            <div className="widget-text col-md-8 px-0 d-inline-block text-center">
              <div className="stat-value">{value}</div>
              <div className="stat-name">{activeFilterState.name || name}</div>
            </div>
          </div>
        </div>
        <div className="row mx-sm-0">
          <div className="col-12 px-0 px-sm-2">{ProgressBarsComponent}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    filters: getCurrentGroup(state, props).filters
  };
};
export default connect(mapStateToProps)(StatisticWidget);
