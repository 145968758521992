import React from "react";
import { connect } from "react-redux";

import { Row, Nav, Tab, Col } from "react-bootstrap";

import ErrorBoundary from "components/Layout/ErrorBoundary";

import { Link } from "react-router-dom";

import Icon from "components/Icons/Icon";

import { withT } from "services/translation/";

import VoiceCallOptions from "./VoiceCallOptions.js";
import CallLogs from "./CallLogs.js";

const TabOptions = [
  {
    key: "make_a_call",
    col: 4,
    Component: VoiceCallOptions,
  },
  {
    key: "call_logs",
    col: 4,
    Component: CallLogs,
  },
];

class VoiceCalls extends React.Component {
  state = {
    key: "make_a_call",
  };

  render() {
    const { t, onBackToOverview, site } = this.props;

    return (
      <>
        <Row className="px-2 mt-2">
            <div className="btn btn-default border-none side-pane-back text-dark">
                <Link to="#" onClick={onBackToOverview}>
                    <span className="back-to-overview"><Icon className="fas fa-chevron-left mr-2" /> {t("back_to_overview")}</span>
                </Link>
            </div>
        </Row>
        <Tab.Container
          activeKey={this.state.key}
          onSelect={(key) => this.setState({ key })}
        >
          <Nav variant="pills" className="row px-2">
            {TabOptions.map(({ key, col }, index) => (
              <Nav.Item className={"col-" + col} key={index}>
                <Nav.Link eventKey={key} className="text-center text-nowrap">
                  {t("tabs." + key)}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Row>
            <Col>
              <Tab.Content>
                {TabOptions.map(({ key, Component }, index) => {
                  const active = this.state.key === key;
                  return active ? (
                    <Tab.Pane key={index} eventKey={key} className="pt-3">
                      <ErrorBoundary message="Error: couldn't load site data">
                        <Component
                          site={site}
                        />
                      </ErrorBoundary>
                    </Tab.Pane>
                  ) : null;
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default withT(
  connect(mapStateToProps)(VoiceCalls),
  "site_overview.side_pane.voice_calls"
);