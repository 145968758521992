import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ErrorBadge from "./ErrorBadge";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    // All errors defined by us should return a type for redux
    if (!_.get(error, "type")) {
      error.type = "UNDEFINED_ERROR";
    }
    this.props.dispatch({ ...error, info: errorInfo });
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.hasError && !_.isEqual(this.props, nextProps)) {
      // Reset error on update
      this.setState({ hasError: false });
    }
  }

  render() {
    const {
      // Default to ErrorBadge
      component = <ErrorBadge message={this.props.message} />
    } = this.props;

    if (this.state.hasError) {
      if (!this.props.message && !component) {
        console.warn("Unhandled error boundary");
        console.trace();
        return null;
      }
      return <div className="pt-4">{component}</div>;
    }

    return this.props.children;
  }
}

const mapStateToProps = state => {
  return { errors: state.errors };
};
export default connect(mapStateToProps)(ErrorBoundary);
