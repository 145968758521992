import React from "react";
import { connect } from "react-redux";
// import EnterpriseManagerMock from "src/services/redux/sagas/userManagement/EnterpriseManager.mock.json";

import Loading from "components/Loading";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import { withT } from "services/translation/";

import { Row, Col } from "react-bootstrap";

import Overview from "./Overview";

/**
 * User Management container
 *
 * Provides data to Overview and UserList
 */
class UserManagement extends React.Component {
  render() {
    const { enterprise, t } = this.props;

    const content = enterprise ? <Overview /> : <Loading />;

    return (
      <>
        <Row>
          <Col>
            <h4 className="mt-0 pb-2 header-title">{t("header_title")}</h4>
          </Col>
        </Row>
        {content}
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
  };
};
export default withT(
  connect(mapStateToProps)(UserManagement),
  "user_management"
);
