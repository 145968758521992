import React from "react";
import { useSelector } from "react-redux";
import Icon from "components/Icons/Icon";

const CopyToClipboard = ({ id, cellText, copyText }) => {
  const darkMode = useSelector((state) => state.app.darkMode)

  return (
    <div className={`d-flex justify-content-start ${darkMode ? "darkMode" : ""}`}>
      <div
        style={{ overflow: "hidden"}}
        className="pr-2"
      >{cellText}</div>
      <div
        className="px-1 detail-clipboard"
        style={{cursor: "pointer"}}
        onClick={() => {
          navigator.clipboard.writeText(cellText ? cellText : copyText);

          const clipboardIcon = document.getElementById(`clipboard-${id}`);

          clipboardIcon.classList.remove("fa-clipboard");
          clipboardIcon.classList.remove("far");
          clipboardIcon.classList.add("fa-clipboard-check");
          clipboardIcon.classList.add("fas");
          
          setTimeout(() => {
            clipboardIcon.classList.remove("fa-clipboard-check");
            clipboardIcon.classList.remove("fas");
            clipboardIcon.classList.add("fa-clipboard");
            clipboardIcon.classList.add("far");
          }, 200);
        }}
      >
        <Icon
          id={`clipboard-${id}`}
          className="far fa-clipboard fa-lg"
          title="Copy to clipboard"
        />
      </div>
    </div>
  )
}

export default CopyToClipboard;