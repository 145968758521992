import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import EnterpriseLogo from "../Logo";

import { Card } from "react-bootstrap";

class EnterpriseCard extends React.Component {
  render() {
    const { id } = this.props;

    return (
      <div key={id} className="col-xs-12 col-sm-6 col-xl-4 d-flex">
        <LinkContainer to={`/enterprise/${id}`} className="waves-effect">
          <Card className="mx-auto">
            <Card.Body>
              <EnterpriseLogo
                className="card-img-top"
                enterprise={this.props}
              />
            </Card.Body>
          </Card>
        </LinkContainer>
      </div>
    );
  }
}
export default EnterpriseCard;
