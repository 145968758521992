import React from "react";
import PropTypes from "prop-types";

import GroupItem from "./GroupItem";

class GroupList extends React.Component {
  render() {
    const { groups, selectedGroupId, onSelect, style } = this.props;

    return (
      <ul style={{ ...style }}>
        {groups.map((group, index) => {
          return (
            <GroupItem
              selectedGroupId={selectedGroupId}
              onSelect={onSelect}
              group={group}
              key={index}
            />
          );
        })}
      </ul>
    );
  }
}
GroupList.propTypes = {
  groups: PropTypes.array.isRequired
};

export default GroupList;
