/**
 * 09/21 - open close by area
 */

import React from "react";
import { connect } from "react-redux";

import { getReportType } from "services/redux/selectors/reports/router";

import PageLayout from "./Index/PageLayout";
import OpenCloseByAreaReportPage from "./Filters/OpenCloseByAreaReportPage";

class OpenCloseByAreaReports extends React.Component {
  render() {
    const { reportType } = this.props;

    switch (reportType) {
      case "index":
        return <PageLayout />;
      case "openclosebyareafull":
        return <OpenCloseByAreaReportPage />;
      case "openclosedareas":
        return <OpenCloseByAreaReportPage />;
      case "closedopenareas":
        return <OpenCloseByAreaReportPage />;
      default:
        return null;
    }
  }
}
const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props)
  };
};
export default connect(mapStateToProps)(OpenCloseByAreaReports);